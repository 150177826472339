import { Injectable } from '@angular/core';
import { TipuriDeServicii, Valabilitate,TipuriDeServiciiCreate } from '../models/nomenclatoare.model';
import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {catchError} from 'rxjs/operators/catchError';
import { Observable, of  } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {TipuriDeServiciiInterface, ValabilitateInterface,TipuriDeServiciiCreateInterface, TipuriDeServiciiCreateFaraValabilitateInterface} from '../interfaces/nomenclatoare.interface';
import { IntervalService } from './interval.service'
import { serverurl } from './server.service';
const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
  providedIn: 'root'
})
export class CatserviciiService {
  public tipuriDeServicii: TipuriDeServicii [] = []
  public tipuriDeServicii2 = []
  public localstorageflag = 0;
  // private server_url="api/catservicii";
  private server_url=serverurl+"/api/v1/tipurideservicii/";
  // private server_url="http://localhost/api/v1/tipurideservicii/";
  public toate_detaliile2: TipuriDeServiciiInterface = new TipuriDeServicii()

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };


  constructor(
    private http: HttpClient,
    public intervalService: IntervalService,
    private localStorageService: LocalStorageService
    ) {
      // console.log("somthing !!! === >>>> ",this.getfromserver())
      let data = this.localStorageService.get("CategorieServicii");
      // console.log("On Initi DAta Local storage => ",data)
      if (data)
      {
        this.localstorageflag = 1;
        this.tipuriDeServicii = data;
        this.tipuriDeServicii.forEach(element=> {
          this.http.post<TipuriDeServicii>(this.server_url, element, cudOptions).pipe(
            catchError(this.handleError)
              )
            });
        }
      else {
        // console.log("PacientList nu este Local")
      }
    }

  getcategoriiservicii(){
    return this.tipuriDeServicii
  }
  deletefromserver(id){
    return this.http.delete(this.server_url+id)
  }
  updatecategoriiservicii(id,categorie){
    return this.http.put(this.server_url+id, categorie)
  }

  getfromserver() {
    // this.http.get<any>(this.server_url).subscribe( data => {
    //   this.tipuriDeServicii2 =  [];
    //   for (let x of data){
    //     this.tipuriDeServicii2.push(x)
    //   }
    //   console.log("from server tipuri 2=>> ", this.tipuriDeServicii2);
    //   return this.tipuriDeServicii2
    // }
    // )
    // console.log("get from service !!")

    return this.http.get<TipuriDeServiciiInterface[]>(this.server_url)
    // this.http.get<TipuriDeServiciiInterface[]>(this.server_url).subscribe((data)=>{
    //   if (data){

    //   }
    // })
  }
  updatefromserver(deupdate: TipuriDeServicii){
    return this.http.put<TipuriDeServiciiInterface>(this.server_url+deupdate.id.toString(),deupdate)
  }

  serchfromserver(id: number) {
    // const options = id ? {
    //   params: new HttpParams().set('',id.toString() )
    // } : {}
    console.log("get from service search from server tipuri de servicii!!")
    return this.http.get<TipuriDeServiciiInterface[]>(this.server_url+id)
  }

  /** POST: add a new hero to the database */
addHeroCuValabilitate(hero: TipuriDeServiciiCreateInterface){
  console.log("intru in add post request")
  console.log("categorie de servicii service -> addhero -> hero => ", hero)
  let toate_detaliile: TipuriDeServiciiInterface = new TipuriDeServicii()
  return(this.http.post<any>(this.server_url,hero,this.httpOptions))


    // .subscribe( data => {
    //   console.log("data put id ==> ",data.id)
    //   console.log("data full object ==> ",Object.keys(data))
    //   console.log("typeof Toate detaliile !! ==> ",typeof(toate_detaliile))
    //   toate_detaliile = data
    //   this.toate_detaliile2 = data
    //   console.log("toate detaliile ==>> !1 =>> ", toate_detaliile)
    //   console.log("toate detaliile2 ==>> !1 =>> ", this.toate_detaliile2)
    //   // toate_detaliile.id = data.id  // commented before
    //   // for (let its of Object.entries(hero)){  // commented before
    //   //   if (its[0] in hero){  // commented before
    //   //     if(its[1]){  // commented before
    //   //       toate_detaliile[its[0]] = its[1]  // commented before
    //   //     }  // commented before
    //   //   }  // commented before
    //   // }  // commented before
    // });
    // console.log("toate detaliile ==>> !2 =>> ", toate_detaliile)
    // console.log("toate detaliile2 ==>> !2 =>> ", this.toate_detaliile2)
    // return(this.toate_detaliile2)


    //   console.log("categorie de servicii service -> addhero -> toate_detaliile => ", toate_detaliile)
    //   toate_detaliile.valabilitate = <ValabilitateInterface>  new Valabilitate()
    //   this.intervalService.serchIntervalfromserver(toate_detaliile.valabilitateid)
    //     // .subscribe();
    //     .subscribe((data)=>{
    //       if (data){
    //         console.log("exista data in serchIntervalfromServer: ", data)
    //         for (let x in data){
    //           console.log("get x in data: ", x)
    //           console.log("get data[x] in data: ", data[x])
    //           toate_detaliile.valabilitate[x] = data[x]
    //         }
    //         console.log("intervalul exista => ", toate_detaliile.valabilitate)
    //         // interval_secundar = data
    //         // console.log("interval secundar exista => ", interval_secundar)
    //       }
    //       // console.log("intervalul exista 2 => ", interval_secundar)
    //       return toate_detaliile.valabilitate
    //     })
    //   console.log("serchIntervalfromserver ==>>",this.intervalService.serchIntervalfromserver(toate_detaliile.valabilitateid))
    //   console.log("categorie de servicii service -> addhero -> toate_detaliile => ", toate_detaliile.valabilitateid)
    //   console.log("categorie de servicii service -> addhero -> search => ", this.intervalService.serchIntervalfromserver(toate_detaliile.valabilitateid))
    //   return data.id
    // }
    //   // catchError(this.handleError('addHero', hero))
    // );
}
addHeroFaraValabilitate(hero: TipuriDeServiciiCreateFaraValabilitateInterface){
  console.log("intru in add post request fara valabilitate")
  console.log("categorie de servicii service -> addhero fara valabilitate -> hero => ", hero)
  return (this.http.post<any>(this.server_url,hero,this.httpOptions))
  // let id: any
  // this.http.post<any>(this.server_url,hero,this.httpOptions)
  //   .subscribe( data => {
  //     console.log("data put id ==> ",data.id)
  //     id = data.id
  //   }
  //   // catchError(this.handleError('addHero', hero))
  //   );
  // return id
}
// addHero(hero: any){
//   console.log("intru in add post request")
//   if(hero.arevalabilitate){
//     console.log("exista valabilitate <<-->>")
//     hero.valabilitate.format_type = "dd.MM.YYYY"
//     hero.valabilitateid=this.intervalService.addIntervalToServer(hero.valabilitate)
//   }
//   var intermediar:TipuriDeServiciiInterface = new TipuriDeServicii()
//   if (hero.cod){
//     intermediar.cod = hero.cod
//   }
//   if (hero.denumire){
//     intermediar.denumire = hero.denumire
//   }
//   if (hero.arevalabilitate){
//     intermediar.arevalabilitate = hero.arevalabilitate
//     var intermediar2 = {
//       start: hero.valabilitate.start,
//       end: hero.valabilitate.end,
//       format_type: "dd.MM.YYYY"
//     }
//     this.intervalService.addIntervalToServer(intermediar2).subscribe(data =>{
//       console.log("interval id => ", data.id)
//       intermediar.valabilitateid = data.id
//       return data.id
//     })
//   }
//   this.http.post<any>(this.server_url,intermediar,this.httpOptions)
//     .subscribe( data => {
//       console.log("data put id ==> ",data.id)
//       return data.id
//     }
//       // catchError(this.handleError('addHero', hero))
//     );
// }
addHero(val){
}

  addcategorieservicii(categorie){
    this.tipuriDeServicii.push(categorie);
    if (this.localstorageflag){
      this.localStorageService.remove("CategorieServicii");
      this.addtolocalStorage(this.tipuriDeServicii);
    }
    else {
      this.addtolocalStorage(this.tipuriDeServicii);
    }
    console.log("Lista pacienti din pacienti service =>", this.tipuriDeServicii);

    return this.http.post<TipuriDeServicii>(this.server_url, categorie, cudOptions).pipe(
      catchError(this.handleError)
    );
  }

  formatdatefields(v: string){
    console.log("v ==> ",v)
  }

  addtolocalStorage(PacientList){
    console.log("addtolocalstorage CategorieServicii: =>", PacientList);
    console.log("Addtolocalstorage",this.localStorageService.set("CategorieServicii", PacientList));
  }
  private handleError(error: Response){
    console.log("Error from Pacient Service: => " + error)
    return Observable.throw("500 internal server error" )
  }
  private log(message: string) {
    // this.messageService.add(`HeroService: ${message}`);
    console.log("this log message =>> ", message)
  }
}
