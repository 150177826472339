import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { MatTableDataSource } from '@angular/material/table';
import { logo_url_base64 } from 'src/app/models/logo-base64.model';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Pacient, SemnaturaRLCreate } from 'src/app/models/pacientdetails.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { FileService } from 'src/app/services/file.service';
import { EvalserviceService } from 'src/app/services/evalservice.service';
import { EvaluareMariInterface } from 'src/app/interfaces/documente.interface';
import { EvaluareMari } from 'src/app/models/documente.models';
import { ConsultatiiService } from 'src/app/services/consultatii.service';
import { PinentryComponent } from 'src/app/pinentry/pinentry.component';
import { ConsultCreate } from 'src/app/models/consult.model';
import { PdfpreviewerComponent } from 'src/app/pdfpreviewer/pdfpreviewer.component';
import { ConsultCreateInterface } from 'src/app/interfaces/consult.interface';
import { FileElementCls } from 'src/app/models/fisier.model';
import { FileElementCreate } from 'src/app/file-manager/model/element';
import { base_img_64 } from 'src/app/models/base-img64.model';
import { SemnaturiRLService } from 'src/app/services/semnaturirl.service';
import { SemnaturiTerapeutiFilesService } from 'src/app/services/semnaturiterapeutifiles.service';
import { SemnaturaRLCreateInterface } from 'src/app/interfaces/pacient.interface';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-evaluare-mari',
  templateUrl: './evaluare-mari.component.html',
  styleUrls: ['./evaluare-mari.component.css']
})
export class EvaluareMariComponent implements OnInit {
  idFisier : any = null
  pacient = new Pacient()
  caz : number = 1

  @Output () fileid = new EventEmitter<string>();
  semnatura_RL : any = null
  semnatura_Terapeut : any = null
  fise_evaluare_mari: EvaluareMariInterface = new EvaluareMari()
  categorii = ['Niciodata', 'Cateodata', 'Frecvent']
  displayedColumns = ['id', 'titlu', 'Slider']
  datasourceComunicareSiLimbaja = new MatTableDataSource(this.fise_evaluare_mari.comunicare_si_limbaj)
  datasourceComportamentMotor = new MatTableDataSource(this.fise_evaluare_mari.comportament_motor)
  datasourceTulburariDeDezvoltare = new MatTableDataSource(this.fise_evaluare_mari.tulburari_de_dezvoltare)
  constructor(
    @Inject(MAT_DIALOG_DATA) private data:any,
    public dialogRef: MatDialogRef<EvaluareMariComponent>,
    private datePipe: DatePipe,
    public consultatiiService: ConsultatiiService,
    public evaluarefieldsService: EvalserviceService ,
    public dialog: MatDialog,
    public semnaturirlService: SemnaturiRLService,
    public semnaturiTerapeutFilesService: SemnaturiTerapeutiFilesService,
    public fileService: FileService
    ) {

    if(data){
      if (data.caz == 1 ){
        this.pacient = data.beneficiar
        this.caz = 1
        console.log("CAZ = 1 ==>> ", data)
        this.getInitialFieldsFromServer()
      }
      else if (data.caz == 2){
        console.log("CAZ = 2 ==>> ", data)
        this.caz = 2
        // this.fisa_evaluare_independenta = data.fieldsData
        this.pacient = data.beneficiar
        this.idFisier = data.fieldsData.idFisier
        this.getSavedDataFromServer(this.idFisier)
      }
    }
   }
   getSavedDataFromServer(idFisier){
    console.log( "GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", idFisier)
    this.consultatiiService.getConsultDataFromServerByPFisierID(idFisier)
    .subscribe( (data) => {
      if (data){
        console.log( "DATA FROM GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", data)
        this.fise_evaluare_mari = data.dataJSON
        this.datasourceComunicareSiLimbaja = new MatTableDataSource(this.fise_evaluare_mari.comunicare_si_limbaj)
        this.datasourceComportamentMotor = new MatTableDataSource(this.fise_evaluare_mari.comportament_motor)
        this.datasourceTulburariDeDezvoltare = new MatTableDataSource(this.fise_evaluare_mari.tulburari_de_dezvoltare)
        this.semnaturirlService.getSemnaturaRLfromServerByFileID(this.idFisier)
        .subscribe( (data) => {
          if (data){
            console.log("DATA FROM GET SEMNATURA RL FROM SERVER ==>> ", data)
            this.semnatura_RL = data.semnatura
          }
        })
        this.semnaturiTerapeutFilesService.getSemnaturaTerapeutFilesfromServerByFileID(this.idFisier)
        .subscribe( (data) => {
          if (data){
            console.log("DATA FROM GET SEMNATURA TERAPEUT FROM SERVER ==>> ", data)
            this.semnatura_Terapeut = data.semnatura
          }
        })
      }
    })
  }

  getInitialFieldsFromServer(){
    this.evaluarefieldsService.getDocFieldsFromServerByID(28)
    .subscribe ( (data) => {
      if (data){
        this.fise_evaluare_mari = data.props
        this.fise_evaluare_mari.nume_pacient = this.pacient.date_personale.nume
        this.fise_evaluare_mari.prenume_pacient = this.pacient.date_personale.prenume
        this.fise_evaluare_mari.data_nasterii_pacient = this.pacient.date_personale.data_nasterii
        this.datasourceComunicareSiLimbaja = new MatTableDataSource(this.fise_evaluare_mari.comunicare_si_limbaj)
        this.datasourceComportamentMotor = new MatTableDataSource(this.fise_evaluare_mari.comportament_motor)
        this.datasourceTulburariDeDezvoltare = new MatTableDataSource(this.fise_evaluare_mari.tulburari_de_dezvoltare)

      }
    })
  }
  changeslider($event:MatSliderChange){
    this.datasourceComunicareSiLimbaja.data = this.datasourceComunicareSiLimbaja.data
    this.datasourceComportamentMotor.data = this.datasourceComportamentMotor.data
    this.datasourceTulburariDeDezvoltare.data = this.datasourceTulburariDeDezvoltare.data
  }
  numberedversion(valoare){
    return Number(valoare)
  }
  refreshDS(){
    this.datasourceComunicareSiLimbaja.data = this.datasourceComunicareSiLimbaja.data
    this.datasourceComportamentMotor.data = this.datasourceComportamentMotor.data
    this.datasourceTulburariDeDezvoltare.data = this.datasourceTulburariDeDezvoltare.data
  }
  ngOnInit(): void {

    if (this.caz == 2){
      this.semnaturirlService.getSemnaturaRLfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA RL FROM SERVER ==>> ", data)
          this.semnatura_RL = data.semnatura
        }
      })
      this.semnaturiTerapeutFilesService.getSemnaturaTerapeutFilesfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA TERAPEUT FROM SERVER ==>> ", data)
          this.semnatura_Terapeut = data.semnatura
        }
      })
    }

// FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI

  // let testone = {
  //   "denumire" : "Evaluare Mari",
  //   "props" : this.fise_evaluare_mari
  // }

  // this.evaluarefieldsService.addToServer(testone )
  // .subscribe( (data) =>{
  //   if (data){
  //     console.log("Data from add sectiunea A to server ==>> ", data)
  //   }
  //   else{
  //     console.log("NO !!! Data from add sectiunea A to server ==>> ")
  //   }
  // })

  // END FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI



    // this.generatePDF()
  }
  rowProc(r){
    var x = [
      [
        "Comunicare si Limbaj",
        "Rezultat"
      ]
    ]
    var y = []
    for (var i of this.fise_evaluare_mari.comunicare_si_limbaj){
      y= [i.id+'. '+i.text,this.categorii[i.selected]]
      x.push(y)
    }
    return x
  }
  rowProc2(r){
    var x = [
      [
        "Comportament Motor",
        "Rezultat"
      ]
    ]
    var y = []
    for (var i of this.fise_evaluare_mari.comportament_motor){
      y= [i.id+'. '+i.text,this.categorii[i.selected]]
      x.push(y)
    }
    return x
  }
  rowProc3(r){
    var x = [
      [
        "Tulburari de dezvoltare",
        "Rezultat"
      ]
    ]
    var y = []
    for (var i of this.fise_evaluare_mari.tulburari_de_dezvoltare){
      y= [i.id+'. '+i.text,this.categorii[i.selected]]
      x.push(y)
    }
    return x
  }

  getsubcategories(cat){
    var x=[]
    for (var i of cat){
      var y = i.denumire + " : " + i.continut
      x.push(y)
    }
    return x
  }
  rowProc4(r){
    var y = [
      {
      text: r.titlu,
      bold:true,
      decoration:'underline',
      margin: [0, 10, 0, 20]
      },
      {
      ul: this.getsubcategories(r.subcategorii)
      }
    ]
  return y
}


semneazaRL(){
  const dialogRef = this.dialog.open(PinentryComponent,{
    width: '650px',
    height: '550px'
  });
  dialogRef.afterClosed().subscribe( res => {
    if (res){
      this.semnatura_RL = res
      console.log("Semnatura RL IMAGINE ESTE ==>> ",this.semnatura_RL)
    }
  })
}
semneazaTerapeut(){
  const dialogRef = this.dialog.open(PinentryComponent,{
    width: '650px',
    height: '550px'
  });
  dialogRef.afterClosed().subscribe( res => {
    if (res){
      this.semnatura_Terapeut = res
      console.log("Semnatura Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
    }
  })
}


addConsultToServer(idFisier){
  let intermediar_consult : ConsultCreateInterface = new ConsultCreate()
  intermediar_consult.idPacient = this.pacient.id
  intermediar_consult.idFisier = idFisier
  intermediar_consult.dataAdaugare = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
  intermediar_consult.idEvaluare = 28
  intermediar_consult.denumire = "Evaluare Mari"
  intermediar_consult.dataJSON = this.returnObjectForAddConsult()
  console.log(" ADD CONSULT TO SERVER ==>> ", intermediar_consult)
  this.consultatiiService.addConsultToServer(intermediar_consult)
  .subscribe( (data) => {
    if (data){
      console.log("DATA DIN ADD CONSULT TO SERVER ==>> ", data)
      let semnatura_noua_RL : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
        semnatura_noua_RL.idFisier = data.idFisier
        semnatura_noua_RL.semnatura = this.semnatura_RL
        this.semnaturirlService.addSemnaturaRLToServer(semnatura_noua_RL)
        .subscribe ( (data) => {
          if (data){
            console.log ("DATA DIN ADD SEMNATURA RL TO SERVER ==>> ", data)
          }
        })
        let semnatura_noua_TerapeutFiles : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
        semnatura_noua_TerapeutFiles.idFisier = data.idFisier
        semnatura_noua_TerapeutFiles.semnatura = this.semnatura_Terapeut
        this.semnaturiTerapeutFilesService.addSemnaturaTerapeutFilesToServer(semnatura_noua_TerapeutFiles)
        .subscribe ( (data) => {
          if (data){
            console.log ("DATA DIN ADD SEMNATURA TERAPEUT FILES TO SERVER ==>> ", data)
          }
        })
    }
  })
}

returnObjectForAddConsult(){
  let intermediar = {
    "dataJSON" : this.fise_evaluare_mari
  }
  return intermediar
}
openPDFPreviewSsaveModificari(){
  let docDefinition = this.generatePDF()
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  pdfDocGenerator.getBase64((data) => {
    // console.log("data din pdfgenerator base 64 ==>> ",data);
    const dialogRef = this.dialog.open(PdfpreviewerComponent, {
      width: '800px',
      height: '100%',
      data: data
    });
    dialogRef.afterClosed().subscribe( async res => {
      if (res){
                  // this.semnatura_Terapeut = res
          // console.log("PDF PREVIEW FROM SAVE MODIFICARI RESULT AFTER CLOSE ==>> ",res)
          let update_file  = {
            'id' : this.idFisier,
            'dateadden' : this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm'),
            'path' : "data:application/pdf;base64,"+data,
            'name' : "Evaluare Mari - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
          }
          this.fileService.updateFileToServer(update_file)
          .subscribe ( (data) => {
            // console.log ( "DATA FROM UPDATE FILE FROM SERVER ==> ", data)
          })
          this.consultatiiService.updateConsultToServerByFisierID(this.idFisier,{'dataJSON' : this.returnObjectForAddConsult()})
          .subscribe( (data) => {
            // console.log ("DATA FROM ADD CONSULT TO SERVICE ==>> ", data)
          })
          this.dialogRef.close(1)
      }
    })
  });
  }
openPDFPreviewComponent(){
  let docDefinition = this.generatePDF()
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  pdfDocGenerator.getBase64((data) => {
    const dialogRef = this.dialog.open(PdfpreviewerComponent, {
      width: '800px',
      height: '100%',
      data: data
    });
    dialogRef.afterClosed().subscribe( async res => {
      if (res){
        let fisier_to_upload : FileElementCreate = new FileElementCls()
        fisier_to_upload.id = this.fileService.generateFileID();
        fisier_to_upload.idPacient = this.pacient.id
        fisier_to_upload.isFolder = false
        fisier_to_upload.parent = 'root'
        fisier_to_upload.dateadden = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
        fisier_to_upload.type = "application/pdf"
        fisier_to_upload.extension = "pdf"
        fisier_to_upload.path = "data:application/pdf;base64,"+data
        fisier_to_upload.status = 'neverificat';
        fisier_to_upload.name = "Evaluare Mari - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
        let intermediar_from_server = await this.fileService.addFileToServerReturnFileID(fisier_to_upload).toPromise()
        this.addConsultToServer(intermediar_from_server)
        this.dialogRef.close(0)
      }
    })
  });
}

  generatePDF(action = 'open') {
    var processedcomunicaresilimbaj = this.rowProc(this.fise_evaluare_mari.comunicare_si_limbaj)
    var processedcomportamentmotor = this.rowProc2(this.fise_evaluare_mari.comportament_motor)
    var processedtulburaridezvoltare = this.rowProc3(this.fise_evaluare_mari.tulburari_de_dezvoltare)
    var processedcapderelaxare = this.rowProc4(this.fise_evaluare_mari.capacitate_de_relaxare)
    var processedrespiratia = this.rowProc4(this.fise_evaluare_mari.respiratia)
    var processedimitatiamiscfaciala = this.rowProc4(this.fise_evaluare_mari.imitatia_miscarilor_faciale)
    var processedmiscarialelimbii = this.rowProc4(this.fise_evaluare_mari.miscari_ale_limbii_buzelor_obrajilor_maxilarelor)
    var processedvocabular = this.rowProc4(this.fise_evaluare_mari.aspectul_semantic.vocabular)
    var processedcomenzi = this.rowProc4(this.fise_evaluare_mari.aspectul_semantic.comenzi)
    var processedintelegeretextauditat = this.rowProc4(this.fise_evaluare_mari.aspectul_semantic.intelegerea_unui_text_audiat)
    var processedclarificari = this.rowProc4(this.fise_evaluare_mari.aspectul_semantic.clasificari)
    var processedsintaxa = this.rowProc4(this.fise_evaluare_mari.aspectul_morfosintactic.sintaxa)
    var processedmorfologia = this.rowProc4(this.fise_evaluare_mari.aspectul_morfosintactic.morfologie)
    var processedcventialitateatemporala = this.rowProc4(this.fise_evaluare_mari.aspectul_morfosintactic.secventialitate_temporala)
    var processedintentionalitatecomunicare = this.rowProc4(this.fise_evaluare_mari.aspect_pragmatic.intentionalitatea_comunicarii)
    var processedmodcomunicare = this.rowProc4(this.fise_evaluare_mari.aspect_pragmatic.modul_de_comunicare)
    var processedfollimbajulpta = this.rowProc4(this.fise_evaluare_mari.aspect_pragmatic.foloseste_limbajul_pentru_a)

    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
        height: 60,
        columns:
          [
            // {
            //   image: logo_url_base64,
            //   width: 50,
            //   alignment: 'center',
            // },
            {
              stack: [
                "Fundația Mihai Neșu Foundation",
                "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
              ],
              margin: 10,
              style: 'textheader'
            }
          ],
        margin: [0, 0, 0, 90]
      },
      content: [
        {
          canvas: [
            {
              type: 'line',
              x1: -100, y1: 0,
              x2: 600, y2: 0,
              lineWidth: 1,
              lineCap: 'square'
            },
            {
              type: 'line',
              x1: -100, y1: 4,
              x2: 600, y2: 4,
              lineWidth: 1,
              lineCap: 'square'
            }
          ]
        },
        {
          image: logo_url_base64,
          width: 200,
          alignment: 'center',
        },
        {
          stack: [
            "Chestionar pentru depistarea deficientelor",
          ],
          bold: true,
          alignment: 'center',
          fontSize: 16,
          margin: [0, 50, 0, 80]
        },
        {
          text: "Nume: " + this.fise_evaluare_mari.nume_pacient + " " + this.fise_evaluare_mari.prenume_pacient,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Data nasterii: " + this.datePipe.transform(new Date(this.fise_evaluare_mari.data_nasterii_pacient),'dd.MM.yyyy') ,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Data completarii: " + this.datePipe.transform(new Date(),'dd.MM.yyyy'),
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['*', "20%" ],
            body: processedcomunicaresilimbaj
          }
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['*', "20%" ],
            body: processedcomportamentmotor
          }
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['*', "20%" ],
            body: processedtulburaridezvoltare
          }
        },
        {
          text: this.fise_evaluare_mari.anatomia_valului_palatin_omusorului_limbii_frenului_dintilor_buzelor.titlu + " " + this.fise_evaluare_mari.anatomia_valului_palatin_omusorului_limbii_frenului_dintilor_buzelor.continut,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
          processedcapderelaxare,
          processedrespiratia,
          processedimitatiamiscfaciala,
          processedmiscarialelimbii,

        {
          text: this.fise_evaluare_mari.aspectul_fonetic_si_fonologic.titlu,
          alignment: 'left',
          fontSize: 14,
          bold:true,
          decoration:'underline',
          margin: [0, 10, 0, 20]
        },
        {
          text: this.fise_evaluare_mari.aspectul_fonetic_si_fonologic.subtitlu + " : " + this.fise_evaluare_mari.aspectul_fonetic_si_fonologic.continut  ,
          alignment: 'left',
          fontSize: 12,
          bold:true,
          margin: [0, 0, 0, 20]
        },
        {
          text: this.fise_evaluare_mari.imitare_de_onomatopee.titlu + " : " + this.fise_evaluare_mari.imitare_de_onomatopee.continut  ,
          alignment: 'left',
          fontSize: 12,
          bold:true,
          margin: [0, 0, 0, 20]
        },
        {
          text: this.fise_evaluare_mari.discriminare_fonologica.titlu + " : " + this.fise_evaluare_mari.discriminare_fonologica.continut  ,
          alignment: 'left',
          fontSize: 12,
          bold:true,
          margin: [0, 0, 0, 20]
        },
        {
          text: this.fise_evaluare_mari.aspectul_semantic.titlu,
          alignment: 'left',
          fontSize: 14,
          bold:true,
          decoration:'underline',
          margin: [0, 10, 0, 20]
        },
        processedvocabular,
        processedcomenzi,
        processedintelegeretextauditat,
        processedclarificari,
        {
          text: this.fise_evaluare_mari.aspectul_morfosintactic.titlu,
          alignment: 'left',
          fontSize: 14,
          bold:true,
          decoration:'underline',
          margin: [0, 10, 0, 20]
        },
        processedsintaxa,
        processedmorfologia,
        processedcventialitateatemporala,
        {
          text: this.fise_evaluare_mari.aspect_pragmatic.titlu,
          alignment: 'left',
          fontSize: 14,
          bold:true,
          decoration:'underline',
          margin: [0, 10, 0, 20]
        },
        processedintentionalitatecomunicare,
        processedmodcomunicare,
        processedfollimbajulpta,
        {
          columns:[
            {
              stack: [
                {
                  text: "Evaluator: " + this.fise_evaluare_mari.evaluator ,
                  bold: true,
                  alignment: 'left',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_Terapeut ? this.semnatura_Terapeut : base_img_64,
                  height: 40,
                  width: 140,
                  margin: [0, 0, 0, 20]
                }
              ]
            },
            {
              stack: [
                {
                  text: "Luat la cunoștință de părinte, ",
                  bold: true,
                  alignment: 'right',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_RL ? this.semnatura_RL : base_img_64,
                  alignment: 'right',
                  height: 40,
                  width: 140,
                  margin: [0, 0, 0, 20]
                }
              ]
            }
          ]
        }
      ],styles: {
        textheader: {
          alignment: 'center',
          bold: true,
          fontSize: 14,
          // lineHeight: 2
        }
      }
    }

    return docDefinition

  }
}
