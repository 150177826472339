<!-- <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
  <ng-container matColumnDef="dataconsult">
    <th mat-header-cell *matHeaderCellDef> Data </th>
    <td mat-cell *matCellDef="let element"> {{element.dataconsult | date:'dd.MM.YYYY - HH:mm' }} </td>
    </ng-container>
    <ng-container matColumnDef="investigatii">
    <th mat-header-cell *matHeaderCellDef> Investigatii </th>
    <td mat-cell *matCellDef="let element"> {{element.investigatii}} </td>
    </ng-container>

    <ng-container matColumnDef="recomandari">
    <th mat-header-cell *matHeaderCellDef> Recomandari </th>
    <td mat-cell *matCellDef="let element"> {{element.recomandari}} </td>
    </ng-container>

    <ng-container matColumnDef="diagnostic">
    <th mat-header-cell *matHeaderCellDef> Diagnostic </th>
    <td mat-cell *matCellDef="let element"> {{element.diagnostic}} </td>
    </ng-container>

    <ng-container matColumnDef="tratament">
    <th mat-header-cell *matHeaderCellDef> Tratament </th>
    <td mat-cell *matCellDef="let element"> {{element.tratament}} </td>
    </ng-container>

    <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef> Data </th>
        <td mat-cell *matCellDef="let element"> {{element.data}} </td>
        </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table> -->

<div style="max-height: 90%; overflow: scroll;" *ngIf="flag_exista_consultatii">

  <ejs-grid
  #grid
  [dataSource]="consultatii"
  (load)='load()'
  [allowFiltering]='true'
  [allowSorting]='true'
  [allowPaging]='true'
  [pageSettings]="pageSettings"
  [allowResizing]="true"
  [allowReordering]="true"
  (rowSelected)='rowSelected($event)'
  >
  <e-columns>
    <!-- <e-column field='id' headerText='ID' textAlign='Right' width=120></e-column> -->
    <!-- <e-column field='denumire' headerText='Denumire' width=150></e-column> -->
    <e-column field='denumire' headerText='Denumire' ></e-column>
    <e-column field='dataAdaugare' headerText='Data Adaugare' ></e-column>

    <e-column *ngFor="let column of editColumnName" [headerText]='column.headerText'>
      <ng-template #template let-data>
        <app-editeazaconsultbutton [consultData]="data" [pacientData]="pacient" (edited)="refreshTableDataAfterEdit($event)"></app-editeazaconsultbutton>
      </ng-template>
    </e-column>

    <e-column *ngFor="let column of vizualizeazaPDFColumnName" [headerText]='column.headerText'>
      <ng-template #template let-data>
        <app-vizualizeazaconsultpdfbutton  [consultData]="data" [pacientData]="pacient" ></app-vizualizeazaconsultpdfbutton>
      </ng-template>
    </e-column>

    <e-column *ngFor="let column of descarcaPDFColumnName" [headerText]='column.headerText'>
      <ng-template #template let-data>
        <app-descarcaconsultpdfbutton [consultData]="data" [pacientData]="pacient"  ></app-descarcaconsultpdfbutton>
      </ng-template>
    </e-column>

    <e-column *ngFor="let column of stergeConsultColumnName" [headerText]='column.headerText'>
      <ng-template #template let-data>
        <app-stergeconsultpdfbutton [consultData]="data" [pacientData]="pacient" (deleted) ="refreshTableDataAfterDelete($event)"  ></app-stergeconsultpdfbutton>
      </ng-template>
    </e-column>

  </e-columns>
  </ejs-grid>
</div>
<div *ngIf="!flag_exista_consultatii">
  <span>Beneficiarul NU are nici o consultatie atribuita</span>
</div>
<br><br>

<button mat-raised-button color="primary" (click)="addconsult()" >Adauga Consultatie</button>
