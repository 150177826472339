import { Component, ChangeDetectionStrategy, ViewChild, OnInit, ElementRef } from '@angular/core';
import { ServiciiService } from 'src/app/services/servicii.service';
import { Pacient, DateContact, DatePersonale, Adresa, programareRecurenta } from '../models/pacientdetails.model';
import { PacientiService } from '../services/pacienti.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { ServiciiInterface, CasierieDateDinModalAdaugaServiciuInterface, CasieriiServiciiInterface, IncasaricreateInterface } from '../interfaces/nomenclatoare.interface';
import { ProgramariService } from '../services/programari.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CasierieserviciiComponent } from '../casierieservicii/casierieservicii.component';
import { CasierieDateDinModalAdaugaServiciu, CasieriiServicii } from '../models/nomenclatoare.model';
import { CasierieService } from '../services/casierie.service';
import { MediciService } from '../services/medici.service';
import { IncasarecasierieComponent } from '../incasarecasierie/incasarecasierie.component';
import { IncasariService } from '../services/incasarecasierie.service'
import { FormControl } from '@angular/forms';
import { PacientInterface } from '../interfaces/pacient.interface';

@Component({
  selector: 'app-casierie',
  templateUrl: './casierie.component.html',
  styleUrls: ['./casierie.component.css']
})
export class CasierieComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginatortwo: MatPaginator;
  servicii: ServiciiInterface[] = [];
  new_service: CasieriiServiciiInterface
  serviciilist: CasieriiServiciiInterface[] = [];
  denumire_servicii_selectate_list: any = []
  serviciu_din_modal: CasierieDateDinModalAdaugaServiciuInterface;
  pacientList: Pacient[];
  selectedPacientId: number;
  pacientdataSource = new MatTableDataSource();
  displayedColumns: string[] = ['id', 'nume', 'prenume', 'CNP'];
  serviciideplata: any;
  serviciiefectuate: any[];
  medicilist: any[]
  casierieflag: boolean = false
  displayedColumnstwo: string[] = ["selectincaseaza",'data','ora', 'terapeut', 'denumire', 'pret', 'discount', 'total', "platit", "sold","achitat"];
  pacientselectatflag: boolean = false

  // De transmis catre modalul de incasare
  //      -> Servicii list = Serviciile din Casierie Marcate pentru incasat
  //      -> Servicii details = Create list selected services
  //      -> Servicii detals = Create list selected services
  //      -> Servicii medicale ids =  Lista cu id-urile serviciilor medicale selectate
  //      -> Denumiri servicii medicale = Lista cu denumirile serviciilor medicale selectate

  marcatepentruincasat: any[] = []
  servicii_medicale_id: any[] = []
  denumiri_servicii_medicale: any[] = []


  // teste filtrare
  idFilter = new FormControl('');
  numeFilter = new FormControl('');
  prenumeFilter = new FormControl('');
  cnpFilter = new FormControl('');
  filterValues = {
    date_personale: {
      nume: '',
      prenume: '',
      cnp: ''
    },
    id: '',
  };


  constructor(
    private datePipe: DatePipe,
    public programariService: ProgramariService,
    public casierieService: CasierieService,
    public pacientiService: PacientiService,
    public serviciiService: ServiciiService,
    public mediciService: MediciService,
    public incasareService: IncasariService,
    public dialog: MatDialog
  ) {
    this.serviciu_din_modal = new CasierieDateDinModalAdaugaServiciu()
    this.serviciiService.getServiciiFromServer()
    .subscribe ( (data) =>{
      if (data){
        this.servicii = data
      }
    })
    this.mediciService.getMediciFromServer()
    .subscribe ( (data) => {
      if (data){
        this.medicilist = data
      }
    })

    this.pacientiService.getPacientifromServer()
    .subscribe((data) => {
        this.pacientList = data
        console.log("Data from server == >>", this.pacientList)
        this.pacientdataSource.data  = this.pacientList
        this.pacientdataSource.filterPredicate = this.createFilter();
        this.pacientdataSource.paginator = this.paginator;
        console.log("Data from pacientdataSource == >>", this.pacientdataSource)
      })

    this.pacientdataSource = new MatTableDataSource(this.pacientList);
    this.pacientdataSource.paginator = this.paginator;
    this.serviciideplata = new MatTableDataSource(this.serviciiefectuate)
   }

  ngOnInit(): void {
    this.numeFilter.valueChanges
    .subscribe(
      nume => {
        this.filterValues.date_personale.nume = nume;
        this.pacientdataSource.filter = JSON.stringify(this.filterValues);
      }
    )
    this.idFilter.valueChanges
    .subscribe(
      id => {
        this.filterValues.id = id
        this.pacientdataSource.filter = JSON.stringify(this.filterValues)
      }
    )
    this.prenumeFilter.valueChanges
    .subscribe(
      prenume => {
        this.filterValues.date_personale.prenume = prenume
        this.pacientdataSource.filter = JSON.stringify(this.filterValues)
      }
    )
    this.cnpFilter.valueChanges
    .subscribe(
      cnp => {
        this.filterValues.date_personale.cnp = cnp
        this.pacientdataSource.filter = JSON.stringify(this.filterValues)
      }
    )
  }

  createFilter(): (data: PacientInterface[], filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      // console.log(" Search Tearms din Create filter ==>> ",searchTerms)
      // console.log(" rezultatul testului data.d_p.nume contine searchTerms.nume ")

      var flag_numeFilter: boolean = false
      var flag_idFilter: boolean = false
      var flag_prenumeFilter: boolean = false
      var flag_cnpFilter: boolean = false

      if (data.id != null){
        // console.log(" data.id ==>> ", data.id.toString())
        // console.log(" searchTerms.id ==>> ", searchTerms.id.toString())
        // console.log(" data.id.toLowerCase() ==>> ", data.id.toString().toLowerCase())
        // console.log(" searchTerms.id.toLowerCase() ==>> ", searchTerms.id.toLowerCase())
        // console.log(" searchTerm.d_p_nume ( ",searchTerms.id.toString().toLowerCase()," ) se afla in Data.id ( ",data.id.toString().toLowerCase()," ) ?? Aflam acum ==>> ", data.id.toString().toLowerCase().indexOf(searchTerms.id.toString().toLowerCase()))
        if(data.id.toString().toLowerCase().indexOf(searchTerms.id.toString().toLowerCase()) !== -1){
          flag_idFilter = true
          // console.log(" idFilter TRUE ==>> flag_idFilter ==>> ", flag_idFilter)
      }
    }
      if (data.date_personale.nume != null){
        // console.log(" data.date_personale.nume ==>> ", data.date_personale.nume)
        // console.log(" searchTerms.date_personale.nume ==>> ", searchTerms.date_personale.nume)
        // console.log(" data.date_personale.nume.toLowerCase() ==>> ", data.date_personale.nume.toLowerCase())
        // console.log(" searchTerms.date_personale.nume.toLowerCase() ==>> ", searchTerms.date_personale.nume.toLowerCase())
        // console.log(" searchTerm.d_p_nume ( ",searchTerms.date_personale.nume.toLowerCase()," ) se afla in Data.d_p.nume ( ",data.date_personale.nume.toLowerCase()," ) ?? Aflam acum ==>> ", data.date_personale.nume.toLowerCase().indexOf(searchTerms.date_personale.nume.toLowerCase()))
        if(data.date_personale.nume.toLowerCase().indexOf(searchTerms.date_personale.nume.toLowerCase()) !== -1){
          flag_numeFilter = true
        }
      }
      if (data.date_personale.prenume != null){
        // console.log(" data.date_personale.nume ==>> ", data.date_personale.prenume)
        // console.log(" searchTerms.date_personale.prenume ==>> ", searchTerms.date_personale.prenume)
        // console.log(" data.date_personale.prenume.toLowerCase() ==>> ", data.date_personale.prenume.toLowerCase())
        // console.log(" searchTerms.date_personale.prenume.toLowerCase() ==>> ", searchTerms.date_personale.prenume.toLowerCase())
        // console.log(" searchTerm.d_p.prenume ( ",searchTerms.date_personale.prenume.toLowerCase()," ) se afla in Data.d_p.prenume ( ",data.date_personale.prenume.toLowerCase()," ) ?? Aflam acum ==>> ", data.date_personale.prenume.toLowerCase().indexOf(searchTerms.date_personale.prenume.toLowerCase()))

        if(data.date_personale.prenume.toLowerCase().indexOf(searchTerms.date_personale.prenume.toLowerCase()) !== -1){
          flag_prenumeFilter = true
        }
      }
      if (data.date_personale.cnp != null){
        // console.log(" data.date_personale.nume ==>> ", data.date_personale.cnp)
        // console.log(" searchTerms.date_personale.cnp ==>> ", searchTerms.date_personale.cnp)
        // console.log(" data.date_personale.cnp.toLowerCase() ==>> ", data.date_personale.cnp.toLowerCase())
        // console.log(" searchTerms.date_personale.cnp.toLowerCase() ==>> ", searchTerms.date_personale.cnp.toLowerCase())
        // console.log(" searchTerm.d_p.cnp ( ",searchTerms.date_personale.cnp.toString().toLowerCase()," ) se afla in Data.d_p.cnp ( ",data.date_personale.cnp.toString().toLowerCase()," ) ?? Aflam acum ==>> ", data.date_personale.cnp.toString().toLowerCase().indexOf(searchTerms.date_personale.cnp.toString().toLowerCase()))

        if(data.date_personale.cnp.toString().toLowerCase().indexOf(searchTerms.date_personale.cnp.toString().toLowerCase()) !== -1){
          flag_cnpFilter = true
        }
      }
      console.log(" flag_cnpFilter ==>> ", flag_cnpFilter )
      console.log(" flag_idFilter ==>> ", flag_idFilter )
      console.log(" flag_numeFilter ==>> ", flag_numeFilter )
      console.log(" flag_prenumeFilter ==>> ", flag_prenumeFilter )
      console.log(" flag_cnpFilter && flag_idFilter && flag_numeFilter && flag_prenumeFilter ==>> ", flag_cnpFilter && flag_idFilter && flag_numeFilter && flag_prenumeFilter )
      return flag_cnpFilter && flag_idFilter && flag_numeFilter && flag_prenumeFilter
    }
    return filterFunction;
  }

  opencasierieservicii(){
    const dialogRef = this.dialog.open(CasierieserviciiComponent,{
      width: '450px',
      height: '350px'
    });
    dialogRef.afterClosed().subscribe( result => {
      if (result) {
        console.log("Dialog inchis")
        console.log("Data from Dialog ==>> ", result)
        this.serviciu_din_modal = result
        console.log("this.serviciu_din_modal from Dialog ==>> ", this.serviciu_din_modal)
        this.new_service = new CasieriiServicii()
        this.new_service = this.serviciu_din_modal
        this.new_service.pacientid = this.selectedPacientId
        console.log("New Service from Dialog ==>> ", this.new_service)
        this.casierieService.addserviciuincasierie(this.new_service)
        .subscribe ( (data) =>{
          if (data){
            console.log("Data din add serviciu in casierie (ar trebui sa fie casierie id)==>> ", data)
            this.new_service.id = data
            this.serviciilist.push(this.new_service)
            console.log("Servicii List from Dialog ==>> ", this.serviciilist)
            this.serviciideplata = this.serviciideplata
            this.serviciideplata.paginator = this.paginatortwo;
          }
        })
      }
      else{
        console.log("Dialog adauga Serviciu din Casierie inchis fara date")
      }
      // this.pacientdataSource = new MatTableDataSource(this.pacientList);
      // this.pacientdataSource.paginator = this.paginator;
    })
  }

  createlistselectedservices(){
    var intermediar: any[]=[]
    for (var i in this.serviciilist){
      for (var j in this.marcatepentruincasat){
        // console.log(" ====>>> j ====>>> ", j," <<<==== marcatepentruincasat [j] ====>>>",this.marcatepentruincasat[j])
        if (this.marcatepentruincasat[j] == this.serviciilist[i].id){
          console.log(" == i == j ==>> ", i, " j ==>> ", j," marcatepentruincasat ==> ", this.marcatepentruincasat[j], " servicii list ==>>> ", this.serviciilist[i])
          intermediar.push(this.serviciilist[i])
          console.log(" Intermediar ==>> ", intermediar)
        }
      }
      // if ( this.serviciilist[i].id == ){}
      // console.log(" ====>>> i ====>>> ", i," <<<==== serv [i] ====>>>",this.serviciilist[i])
    }
    return intermediar
  }


  incaseazaservicii(){
    // this.createlistselectedservices()
    const dialogReftwo = this.dialog.open(IncasarecasierieComponent,{
      width: '1000px',
      height: ' 650px',
      data: {
        'serviciilist': this.servicii_medicale_id,
        'serviciidetails': this.createlistselectedservices(),
        'denumiri_servicii_medicale': this.denumiri_servicii_medicale
      }
    })
  }

  checkachitat(total, platit){
    if ((total - platit) <= 0 ){
      return ('DA')
    }
    return ('NU')
  }

  marcheazaserviciul(casierie_serviciu_obj: CasieriiServiciiInterface){
    console.log("Casierie serviciu object ==>> ", casierie_serviciu_obj)

    // Verifica daca casierie serviciul nu este deja adaugat in lista
    if ( this.marcatepentruincasat.indexOf(casierie_serviciu_obj.id) < 0 ){
          // Daca nu este in lista, adaugam id-ul casierie serviciului in array-ul marcatepentruincasat
          console.log ("Casierie !!! Serviciu id nu este selectat--> ", casierie_serviciu_obj.id," Serviciu id ==>> ", casierie_serviciu_obj)
          this.marcatepentruincasat.push(casierie_serviciu_obj.id)
          // Dupa asta verifica daca id-ul serviciului medical este in array-ul servicii_medicale_id
          if ( this.servicii_medicale_id.indexOf(casierie_serviciu_obj.serviciuid) < 0){
            // Daca nu este id-ul serviciului medical in lista servicii_medicale_id il adaug si adaug si denumirea serviciului in array-ul denumiri_servicii_medicale
            console.log ("Serviciul medical cu id-ul -> ", casierie_serviciu_obj.serviciuid," nu este in lista de servicii !!")
            this.servicii_medicale_id.push(casierie_serviciu_obj.serviciuid)
            this.denumiri_servicii_medicale.push(this.returndenumireserviciu(casierie_serviciu_obj.serviciuid))
            console.log (" Servicii medicale id dupa adaos ==>>", this.servicii_medicale_id)
            console.log (" Denumiri Servicii medicale dupa adaos ==>>", this.denumiri_servicii_medicale)
          }
          else{
            // Id-ul serviciului medical este deja in lista cu servicii medicale selectate
            console.log ("Serviciul medical cu id-ul -> ", casierie_serviciu_obj.serviciuid," !!! ESTE !!! in lista de servicii !!")
          }
        }
      else{
          // Casierie Serviciul este deja selectat
          // Trebuie facut ca in acest caz sa-l sterg din lista
          console.log("Casierie serviciu deja selectat !! ==>> !!")
        }
  }

  // ---->> OLD VERSION <<----
  // marcheazaserviciul(serviciuid){
  //   if (this.marcatepentruincasat.includes(serviciuid)){
  //     console.log("Id-ul serviciului deja selectat !! ")
  //     this.marcatepentruincasat.forEach( (value,index) =>{
  //       if (value == serviciuid) this.marcatepentruincasat.splice(index,1)
  //     })
  //   }
  //   else{
  //     this.marcatepentruincasat.push(serviciuid)
  //   }
  //   console.log("Id-uri de incasat ==>> ",this.marcatepentruincasat)
  // }




  selectPacient(entire_row) {
    console.log("select pacient ID -> ", entire_row.id)
    console.log("select pacient -> ", entire_row)
    this.selectedPacientId = entire_row.id;
    var intermediar_selectedpacient: any[] = []
    intermediar_selectedpacient.push(entire_row)
    this.pacientdataSource = new MatTableDataSource(intermediar_selectedpacient);
    // this.pacientdataSource = new MatTableDataSource(this.pacientList);
    this.pacientselectatflag = true
    this.pacientdataSource.paginator = this.paginator;
  }

  returndenumireserviciu(serviciuid){
    for (var i of this.servicii)
    {
      if ( i.id == serviciuid){
        return (i.denumire)
      }
      // console.log("i = ===>>> ", i)
    }
    return ("")
  }

  returndenumiremedici(terapeutid){
    for (var i of this.medicilist)
    {
      if ( i.id == terapeutid){
        return (i.nume + " " +i.prenume)
      }
      // console.log("i = ===>>> ", i)
    }
    return ("")
  }

  resetPacient(){
    this.pacientselectatflag = false
    this.pacientdataSource = new MatTableDataSource(this.pacientList);
    this.selectedPacientId = undefined
    this.pacientdataSource.paginator = this.paginator;
    this.casierieflag = false
  }

  getcasieriedataforuser(){
    this.casierieflag = true
    this.casierieService.getbybeneficiarid(this.selectedPacientId)
    .subscribe( (data) => {
      if(data){
        console.log("Data din get casierie by beneficiar id ==>> ", this.selectedPacientId," << == data ==>>", data)
        this.serviciilist = data
        this.serviciideplata = new MatTableDataSource(this.serviciilist)
        this.serviciideplata.paginator = this.paginatortwo;

      }
    })
  }

}
