<mat-grid-list cols="6" rowHeight="3:1" gutterSize="10" style="padding: 10px;">
  <mat-grid-tile  rowspan="4" colspan="4"  *ngIf="!flag_pacient_selectat" class="testshadow" style="overflow: scroll;">
    <div style="width: 100%; height:100%;display: flex; flex-direction: column;" >
    <!-- <table    mat-table [dataSource]="pacientdataSource" class="mat-elevation-z8" style="height: 100%;" >
    <ng-container matColumnDef="id" >
      <th mat-header-cell *matHeaderCellDef >
            ID
            <div *ngIf="!flag_pacient_selectat"  >
            <mat-form-field class="filter" floatLabel="never">
              <mat-label>Cauta</mat-label>
              <input matInput [formControl]="idFilter">
            </mat-form-field>
            </div>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="nume">
      <th mat-header-cell *matHeaderCellDef>
            Nume
            <div *ngIf="!flag_pacient_selectat"  >
              <mat-form-field class="filter" floatLabel="never">
                <mat-label>Cauta</mat-label>
                <input matInput [formControl]="numeFilter">
              </mat-form-field>
            </div>
      </th>

      <td mat-cell *matCellDef="let element"> {{element.date_personale.nume}} </td>
    </ng-container>
    <ng-container matColumnDef="prenume">
      <th mat-header-cell *matHeaderCellDef>
            Prenume
            <div *ngIf="!flag_pacient_selectat"  >
              <mat-form-field class="filter" floatLabel="never">
                <mat-label>Cauta</mat-label>
                <input matInput [formControl]="prenumeFilter">
              </mat-form-field>
            </div>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.date_personale.prenume}} </td>
    </ng-container>
    <ng-container matColumnDef="CNP">
      <th mat-header-cell *matHeaderCellDef>
            CNP
            <div *ngIf="!flag_pacient_selectat"  >
              <mat-form-field class="filter" floatLabel="never">
                <mat-label>Cauta</mat-label>
                <input matInput [formControl]="cnpFilter" >
              </mat-form-field>
              </div>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.date_personale.cnp}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.date_contact.email}} </td>
    </ng-container>
    <ng-container matColumnDef="telefon">
      <th mat-header-cell *matHeaderCellDef> Telefon </th>
      <td mat-cell *matCellDef="let element"> {{element.date_contact.telefon}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectPacient(row)"
      [ngClass]="{'selectedpacient': row.id == selectedPacientId  }"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nu am gasit nimic</td>
    </tr>
  </table>
  <mat-paginator   [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
  <ejs-grid
  #grid
  [dataSource]="PacientListtwo"
  (load)='load()'
  [allowFiltering]='true'
  [allowSorting]='true'
  [allowPaging]='true'
  [pageSettings]="pageSettings"
  [allowResizing]="true"
  [allowReordering]="true"
  (rowSelected)='rowSelected($event)'
  >
  <e-columns>
    <e-column field='id' headerText='ID' textAlign='Right' width=120></e-column>
    <e-column field='date_personale.nume' headerText='Nume' width=150></e-column>
    <e-column field='date_personale.prenume' headerText='Prenume' width=150></e-column>
    <e-column field='date_personale.cnp' headerText='CNP' width=150></e-column>
    <e-column field='date_contact.email' headerText='Email' width=150></e-column>
    <e-column field='date_contact.telefon' headerText='Telefon' width=150></e-column>
  </e-columns>
</ejs-grid>
</div>
  <!-- <mat-paginator style="width: 50%;" *ngIf="!flag_pacient_selectat" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->


</mat-grid-tile>
<!-- <mat-grid-tile style="background-color: pink" *ngIf="flag_pacient_selectat">2 == {{selectedPacientId}}</mat-grid-tile> -->
<mat-grid-tile rowspan="3" colspan="3"   *ngIf="flag_pacient_selectat">
  <!-- 2 == {{selectedPacientId}}  -->
  <!-- <mat-grid-list cols="6" rowHeight="2:1" gutterSize="10" style="padding: 10px;">
    <mat-grid-tile style="background-color: pink" *ngIf="flag_pacient_selectat">
      1
    </mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(201, 192, 255)" *ngIf="flag_pacient_selectat">
      2
    </mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(212, 255, 192)" *ngIf="flag_pacient_selectat">
      3
    </mat-grid-tile>

  </mat-grid-list> -->

  <mat-card class="example-card" style="width: 100%;max-height: 350px;text-align: center;" >
    <mat-card-header style="padding-left: 5%;">
      <span mat-card-avatar style="font-size: 45px;    color: #3f51b5;" class="material-icons">
        account_circle
        </span>
      <mat-card-title > {{ selectedPacientDetails.date_personale.nume + ' ' + selectedPacientDetails.date_personale.prenume }} </mat-card-title>
      <mat-card-subtitle> {{ (selectedPacientDetails.date_personale.data_nasterii | date: 'd.MM.YYYY') + " -- "+ varsta_cronologica + " ani" }} </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

      <strong>
        <mat-grid-list  cols="3" rowHeight="50px"  [style.border] = '30' >
          <mat-grid-tile class = "bordurica-mat-grid-list"  >

             {{ (selectedPacientDetails.orar_de_somn && selectedPacientDetails.program_de_somn !== null)?("Interval de somn: " + (selectedPacientDetails.program_de_somn[0].start | date:'H:mm') + " - " + (selectedPacientDetails.program_de_somn[0].end | date:'H:mm') ):"Nu are un program de somn" }}
          </mat-grid-tile>
          <mat-grid-tile class = "bordurica-mat-grid-list">
            {{ (selectedPacientDetails.date_contact.telefon)?("Telefon: " + selectedPacientDetails.date_contact.telefon):' - ' }}
          </mat-grid-tile>
          <mat-grid-tile class = "bordurica-mat-grid-list">
            {{ (selectedPacientDetails.date_contact.email)?("Email: " + selectedPacientDetails.date_contact.email):' - '}}
          </mat-grid-tile>
          <mat-grid-tile class = "bordurica-mat-grid-list">
            {{ (selectedPacientDetails.independent)?("Gradul de dependenta: Independent"):("Gradul de dependenta: Dependent") }}
          </mat-grid-tile>
          <mat-grid-tile class = "bordurica-mat-grid-list">
            {{ (selectedPacientDetails.greutate)?("Greutatea: " + selectedPacientDetails.greutate + " kg"):' - ' }}
          </mat-grid-tile>
          <mat-grid-tile class = "bordurica-mat-grid-list">
            {{ (selectedPacientDetails.inaltime)?("Inaltime: " + selectedPacientDetails.inaltime + " cm"):' - ' }}
          </mat-grid-tile>
        </mat-grid-list>

      </strong>

    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="openPacientDetailsComponent()"  >Vezi detalii</button>
      <button mat-raised-button (click)="resetPacient()" >Selecteaza alt pacient</button>
    </mat-card-actions>
  </mat-card>


</mat-grid-tile>

<mat-grid-tile rowspan="1" colspan="2" #tileterapeut>


  <div class="control-section">
    <div class='content' style="width: 280px;margin: 0 auto; padding-top:40px">
        <ejs-dropdownlist (change)='onChangeTerapeut($event)' id='selectTerapeut' [dataSource]='detaliiMedicPeScurt' [fields]='fields' (filtering)='onFiltering($event)' [filterBarPlaceholder]="filterPlaceholder"
            [popupHeight]='height' [allowFiltering]='true' [placeholder]='watermark'>

            <ng-template #itemTemplate let-data>
              <div > {{data.nume}}  </div>
            </ng-template>
            <ng-template #headerTemplate let-data>
              <div > {{data.nume}}  </div>
            </ng-template>
            <ng-template #valueTemplate let-data>
              <div > {{data.nume}}  </div>
          </ng-template>
          </ejs-dropdownlist>
    </div>
</div>

<div class="control-section">
  <div class='content' style="width: 280px;margin: 0 auto; padding-top:40px">
      <ejs-dropdownlist (change)='onChangeServicii($event)' id='selectServiciu' [dataSource]='serviciiPeScurt' [fields]='fieldsServicii' (filtering)='onFilteringServicii($event)' [filterBarPlaceholder]="filterPlaceholderServicii"
          [popupHeight]='height' [allowFiltering]='true' [placeholder]='placeholderServicii'>

          <ng-template #itemTemplate let-data>
            <div > {{data.denumire}}  </div>
          </ng-template>

          <ng-template #headerTemplate let-data>
            <div > {{data.denumire}}  </div>
          </ng-template>
          <ng-template #valueTemplate let-data>
            <div > {{data.denumire}}  </div>
        </ng-template>
        </ejs-dropdownlist>
  </div>
</div>

  <!-- 3 -->
  <!-- <form class="example-form" *ngIf="flag_content_init">
    <mat-form-field class="example-full-width" appearance="fill" [ngStyle]='{width: terapeut_select_width}'>
      <mat-label>Selecteaza un Terapeut</mat-label>
      <input type="text"
             placeholder="Selecteaza un Terapeut"
             aria-label="Terapeut"
             matInput
             [formControl]="myControl"
             [matAutocomplete]="auto"
             >
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of terapeutiFiltrati " (click)="setTerapeutSelectat(option)" [value]="option.nume + ' ' + option.prenume">
          {{option.nume + ' ' + option.prenume }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form> -->
</mat-grid-tile>

<mat-grid-tile rowspan="1" colspan="2" #tileservicii>
  <!-- 3 -->
  <!-- <form class="example-form" *ngIf="flag_content_init">
    <mat-form-field class="example-full-width" appearance="fill" [ngStyle]='{width: terapeut_select_width}' >
      <mat-label>Selecteaza un Serviciu</mat-label>
      <input type="text"
             placeholder="Selecteaza un Serviciu"
             aria-label="Serviciu"
             matInput
             [formControl]="serviciiFormControl"
             [(ngModel)]="serviciuSelectat.denumire"
             [matAutocomplete]="autotwo" *ngIf="serviciuSelectat">
        <input type="text"
             placeholder="Selecteaza un Serviciu"
             aria-label="Serviciu"
             matInput
             [formControl]="serviciiFormControl"
             [matAutocomplete]="autotwo" *ngIf="!serviciuSelectat">
      <mat-autocomplete autoActiveFirstOption #autotwo="matAutocomplete">
        <mat-option *ngFor="let option of serviciiFiltrate " (click)="setServiciuSelectat(option)" [value]="option.denumire">
          {{option.denumire}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form> -->
</mat-grid-tile>
<!-- <mat-grid-tile style="background-color: pink">4</mat-grid-tile>
  <mat-grid-tile style="background-color: pink">1</mat-grid-tile>
  <mat-grid-tile style="background-color: pink">2</mat-grid-tile>
  <mat-grid-tile style="background-color: pink">3</mat-grid-tile>
  <mat-grid-tile style="background-color: pink">4</mat-grid-tile>
  <mat-grid-tile style="background-color: pink">1</mat-grid-tile>
  <mat-grid-tile style="background-color: pink">2</mat-grid-tile>
  <mat-grid-tile style="background-color: pink">3</mat-grid-tile> -->

</mat-grid-list>

<!-- <div fxFlexAlign="stretch" align="end" style="padding: 2%;"> -->
  <div   style="padding: 2%;">
  <button mat-raised-button color="primary" (click)="openCalendar()"   >Cauta fereastra libera</button>
  <!-- <button mat-raised-button color="primary" (click)="openCalendarFlag = !openCalendarFlag" style="margin-left: 60%;" >Cauta fereastra libera</button> -->

</div>

<!-- <ng-container *ngIf="openCalendarFlag">
  <mwl-demo-component [pacientid]="selectedPacientId" [medic]="terapeutSelectat"
    (dateclicked)="programarenoua($event)"
    >
  </mwl-demo-component>
</ng-container> -->

<div class="control-section">
  <div class="col-lg-9 content-wrapper">
<ejs-schedule

#scheduleObj
[startHour]="startHour"
[endHour]="endHour"
[showWeekend]="showWeekend"
[(selectedDate)]="selectedDate"
[eventSettings]="eventSettings"
(actionBegin)="onActionBegin($event)"
(eventRendered)="onEventRendered($event)"
(popupClose)="onPopupClose()"
[showQuickInfo]="showQuickInfo"
locale='ro'
(popupOpen)='onPopupOpen($event)'
[timeScale]="timeScale"
[cssClass]='cssClass'
>
<!-- cssClass='schedule-cell-dimension' -->
<ng-template #timeScaleMajorSlotTemplate let-data>
  <div class="majorTime">{{getMajorTime(data.date)}}</div>
</ng-template>
<ng-template #timeScaleMinorSlotTemplate let-data>
  <div class="minorTime">{{getMinorTime(data.date)}}</div>
</ng-template>
<e-views>
  <e-view option="Day"></e-view>
  <e-view option="Week"></e-view>
  <!-- <e-view option="WorkWeek"></e-view> -->
  <e-view option="Month"></e-view>
  <e-view option="Year"></e-view>
  <e-view option="Agenda"></e-view>
  <e-view option="MonthAgenda"></e-view>
</e-views>
<ng-template #editorTemplate let-data style = "min-width: 600px">
  <table *ngIf="data != undefined" class="custom-event-editor" width="100%" cellpadding="9" >
    <tbody>
      <tr *ngIf="flag_pacient_selectat">
        <td class="e-textlabel">Beneficiar : </td>
        <td > {{selectedPacientDetails["date_personale"].nume + " " + selectedPacientDetails["date_personale"].prenume }} </td>
        <!-- <td colspan="3"> {{selectedPacientDetails["date_personale"]["nume"] | json}} </td> -->
        <!-- <td colspan="3"> {{selectedPacientDetails.date_personale.nume +" "+ selectedPacientDetails.date_personale.prenume}} </td> -->
        <!-- <td colspan="1">  <input ejs-button type="button" cssClass="e-info" value="Alege alt beneficiar" /> </td> -->
      <!-- </tr> -->
      <ng-container *ngIf="terapeutiList.length > 0" >
        <td  class="e-textlabel">Terapeut Selectat :  </td>
        <td >
          <div class="control-section">
            <div class='content' >
                <ejs-dropdownlist data-name="Terapeut" class="e-field" (change)='onChangeTerapeut($event)' id='selectTerapeut' [dataSource]='detaliiMedicPeScurt' [fields]='fields' (filtering)='onFiltering($event)' [filterBarPlaceholder]="filterPlaceholder"
                    [popupHeight]='height' [allowFiltering]='true' [placeholder]='watermark' [value]="terapeutSelectat">

                    <ng-template #itemTemplate let-data>
                      <div > {{data.nume}}  </div>
                    </ng-template>
                    <ng-template #headerTemplate let-data>
                      <div > {{data.nume}}  </div>
                    </ng-template>
                    <ng-template #valueTemplate let-data>
                      <div > {{data.nume}}  </div>
                  </ng-template>
                  </ejs-dropdownlist>
            </div>
        </div>
        </td>
      </ng-container>

      <ng-container *ngIf="serviciiList.length > 0">
        <td class="e-textlabel">Serviciul Selectat :  </td>
        <td >
          <div class="control-section">
            <div class='content' >
                <ejs-dropdownlist data-name="Serviciu" class="e-field" (change)='onChangeServicii($event)' id='selectServiciu' [dataSource]='serviciiPeScurt' [fields]='fieldsServicii' (filtering)='onFilteringServicii($event)' [filterBarPlaceholder]="filterPlaceholderServicii"
                    [popupHeight]='height' [allowFiltering]='true' [placeholder]='placeholderServicii' [value]="serviciuSelectat">

                    <ng-template #itemTemplate let-data>
                      <div > {{data.denumire}}  </div>
                    </ng-template>

                    <ng-template #headerTemplate let-data>
                      <div > {{data.denumire}}  </div>
                    </ng-template>
                    <ng-template #valueTemplate let-data>
                      <div > {{data.denumire}}  </div>
                  </ng-template>
                  </ejs-dropdownlist>
            </div>
          </div>
        </td>
      </ng-container>
    </tr>

      <!-- <tr>
        <td class="e-textlabel">Summary</td>
        <td colspan="4">
          <input id="Subject" class="e-field e-input" type="text" value="{{data.Subject}}" name="Subject"
            style="width: 100%" />
        </td>
      </tr>
      <tr>
        <td class="e-textlabel">Status</td>
        <td colspan="4">
          <ejs-dropdownlist id='EventType' class="e-field" data-name="EventType" placeholder='Choose Status'
            [dataSource]='statusData' value="{{data.EventType}}">
          </ejs-dropdownlist>
        </td>
      </tr> -->
      <tr>
        <td  class="e-textlabel">De la : </td>
        <td >
          <ejs-datetimepicker data-name="StartTime" id="StartTime" class="e-field" data-name="StartTime" format="dd/MM/yy HH:mm"
            (change)="onDateChange($event)" [value]="startDateParser(data.startTime || data.StartTime)">
          </ejs-datetimepicker>
        </td>

        <td  class="e-textlabel">Pana la : </td>
        <td >
          <ejs-datetimepicker data-name="EndTime" id="EndTime" class="e-field" data-name="EndTime" format="dd/MM/yy HH:mm"
            (change)="onDateChange($event)" [value]="endDateParser(data.startTime || data.StartTime)">
          </ejs-datetimepicker>
        </td>

        <td  class="e-textlabel">Durata : </td>
        <td  > {{getDurataServiciu(serviciuSelectat)}} minute
        </td>
      </tr>
      <tr>
        <td>

          <div id="RecurrenceEditor">
            <ejs-recurrenceeditor (change)="onChangeRecurrence($event)" locale='ro'></ejs-recurrenceeditor>
          </div>
        </td>
      </tr>
      <!-- <td colspan="4">
        <div id='RecurrenceEditor'></div>
    </td> -->
    <!-- <tr>
      <td class="e-textlabel">Reason</td>
      <td colspan="4">
        <textarea id="Description" class="e-field e-input" name="Description" rows="3" cols="50" style="width: 100%; height: 60px !important; resize: vertical"></textarea>
      </td>
    </tr> -->
    </tbody>
  </table>
</ng-template>
</ejs-schedule>`
</div>
<!-- <div class="col-lg-3 property-section">
  <div class="property-panel-section">
    <div class="property-panel-header">Properties</div>
    <div class="property-panel-content">
      <div id="property" class="property-panel-table" title="Properties">
        <table id="property" title="Properties" style="width: 100%">
          <tbody>
            <tr style="height: 50px">
              <td style="width: 100%;">
                <div>
                  <ejs-datepicker #datepicker [(value)]='selectedDate' placeholder="Current Date"
                    floatLabelType="Always" [showClearButton]=false></ejs-datepicker>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div> -->
</div>
