<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> FISA DE EXAMINARE LOGOPEDICA</h2></span>
<br>

<!-- <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Nume examinator: {{ ((fisa_examinare_logopedica.nume_examinator != "") && (fisa_examinare_logopedica.prenume_examinator!= "")) ? (fisa_examinare_logopedica.nume_examinator + fisa_examinare_logopedica.prenume_examinator): "Nespecificat" }} </span>
<br> -->
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Data examinarii: {{ (fisa_examinare_logopedica.data_examinarii != "") ? fisa_examinare_logopedica.data_examinarii : "Nespecificat" }} </span>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> 1. Date Generale</h2></span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 1.1 Personale</h3></span>
<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.nume_de_familie!=""'>

    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Numele de familie: {{fisa_examinare_logopedica.date_generale.personale.nume_de_familie }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.nume_de_familie ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Numele de familie: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.personale.nume_de_familie" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.prenumele!=""'>

    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Prenumele: {{fisa_examinare_logopedica.date_generale.personale.prenumele }} </span>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.prenumele ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Prenumele: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.personale.prenumele" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.nascut_in_anul!=""'>

    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Nascut in anul: {{fisa_examinare_logopedica.date_generale.personale.nascut_in_anul }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.nascut_in_anul ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Nascut in anul: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.personale.nascut_in_anul" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.nascut_in_luna!=""'>

    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> luna: {{fisa_examinare_logopedica.date_generale.personale.nascut_in_luna }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.nascut_in_luna ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Nascut in luna: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.personale.nascut_in_luna" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.nascut_in_ziua!=""'>

    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> ziua: {{fisa_examinare_logopedica.date_generale.personale.nascut_in_ziua }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.nascut_in_ziua ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Nascut in ziua: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.personale.nascut_in_ziua" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.domiciliu!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Domiciliu: {{fisa_examinare_logopedica.date_generale.personale.domiciliu }} </span>
</ng-container> -->
<ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Domiciliu: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.personale.domiciliu" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.personale.institutia!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Institutia: {{fisa_examinare_logopedica.date_generale.personale.institutia }} </span>
</ng-container> -->
<ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Institutia: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.personale.institutia" ></textarea>
    </mat-form-field>
</ng-container>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 1.2 Familiale</h3></span>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.nume_tata!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Nume Tata: {{fisa_examinare_logopedica.date_generale.familiale.nume_tata }} </span>
</ng-container> -->
<ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Nume Tata: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.nume_tata" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.prenume_tata!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Prenume Tata: {{fisa_examinare_logopedica.date_generale.familiale.prenume_tata }} </span>
</ng-container> -->
<ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Prenume Tata: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.prenume_tata" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.varsta_tata!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Varsta Tata: {{fisa_examinare_logopedica.date_generale.familiale.varsta_tata }} </span>
</ng-container> -->
<ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Varsta Tata: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.varsta_tata" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.nationalitate_tata!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Nationalitate Tata: {{fisa_examinare_logopedica.date_generale.familiale.nationalitate_tata }} </span>
</ng-container> -->
<ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Nationalitate Tata: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.nationalitate_tata" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.studii_tata!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Studii Tata: {{fisa_examinare_logopedica.date_generale.familiale.studii_tata }} </span>
</ng-container> -->
<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.studii_tata ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Studii Tata: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.ocupatia_tata" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.ocupatia_tata!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Ocupatie Tata: {{fisa_examinare_logopedica.date_generale.familiale.ocupatia_tata }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.ocupatia_tata ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Ocupatie Tata: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.ocupatia_tata" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.locul_de_munca_tata!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Loc de munca Tata: {{fisa_examinare_logopedica.date_generale.familiale.locul_de_munca_tata }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.locul_de_munca_tata ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Loc de munca Tata: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.locul_de_munca_tata" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.nume_mama!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Nume Mama: {{fisa_examinare_logopedica.date_generale.familiale.nume_mama }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.nume_mama ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Nume Mama: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.nume_mama" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.prenume_mama!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Prenume Mama: {{fisa_examinare_logopedica.date_generale.familiale.prenume_mama }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.prenume_mama ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Prenume Mama: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.prenume_mama" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.nationalitate_mama!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Nationalitate Mama: {{fisa_examinare_logopedica.date_generale.familiale.nationalitate_mama }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.nationalitate_mama ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Nationalitate Mama: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.nationalitate_mama" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.studii_mama!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Studii Mama: {{fisa_examinare_logopedica.date_generale.familiale.studii_mama }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.studii_mama ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Studii Mama: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.studii_mama" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.ocupatia_mama!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Ocupatie Mama: {{fisa_examinare_logopedica.date_generale.familiale.ocupatia_mama }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.ocupatia_mama ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Ocupatie Mama: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.ocupatia_mama" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.locul_de_munca_mama!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Loc de munca Mama: {{fisa_examinare_logopedica.date_generale.familiale.locul_de_munca_mama }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.familiale.locul_de_munca_mama ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Loc de munca Mama: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.familiale.locul_de_munca_mama" ></textarea>
    </mat-form-field>
</ng-container>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 1.3 Motivul examinarii</h3></span>
<br>
<!-- <ng-container *ngIf='fisa_examinare_logopedica.date_generale.motivul_examinarii.cu_ce_acuze_este_trimis!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Cu ce acuze este trimis: {{fisa_examinare_logopedica.date_generale.motivul_examinarii.cu_ce_acuze_este_trimis }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.date_generale.motivul_examinarii.cu_ce_acuze_este_trimis ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Cu ce acuze este trimis: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.date_generale.motivul_examinarii.cu_ce_acuze_este_trimis" ></textarea>
    </mat-form-field>
</ng-container>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> 2 Anameza</h2></span>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 2.1 Familiala</h3></span>
<br>
<!-- <ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.structura_familiei!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Structura familiei: {{fisa_examinare_logopedica.anameza.familiala.structura_familiei }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.structura_familiei ===""'> -->
  <ng-container >

    <mat-form-field style="width: 100%;" >
        <mat-label>Structura familiei: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.familiala.structura_familiei" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.al_catelea_copil_este!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Al catelea copil este: {{fisa_examinare_logopedica.anameza.familiala.al_catelea_copil_este }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.al_catelea_copil_este ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Al catelea copil este: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.familiala.al_catelea_copil_este" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.starea_de_sanatate_a_membrilor_familiei!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Starea de sanatate a membrilor familiei: {{fisa_examinare_logopedica.anameza.familiala.starea_de_sanatate_a_membrilor_familiei }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.starea_de_sanatate_a_membrilor_familiei ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Starea de sanatate a membrilor familiei: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.familiala.starea_de_sanatate_a_membrilor_familiei" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.persoane_cu_tulburari_de_libaj_in_familie!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Persoane cu tulburari de limbaj in familie: {{fisa_examinare_logopedica.anameza.familiala.persoane_cu_tulburari_de_libaj_in_familie }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.persoane_cu_tulburari_de_libaj_in_familie ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Persoane cu tulburari de limbaj in familie: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.familiala.persoane_cu_tulburari_de_libaj_in_familie" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Relatiile in familie</span>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.intre_parinti!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> a. Intre parinti: {{fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.intre_parinti }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.intre_parinti ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>a. Intre parinti </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.intre_parinti" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.fata_de_copil!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> b. Fata de copil: {{fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.fata_de_copil }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.fata_de_copil ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>b. Fata de copil: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.fata_de_copil" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.atitudine_fata_de_defectul_de_pronuntie_al_copilului!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> c. Atitudinea fata de defectul de pronuntie al copilului: {{fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.atitudine_fata_de_defectul_de_pronuntie_al_copilului }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.atitudine_fata_de_defectul_de_pronuntie_al_copilului ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>c. Atitudinea fata de defectul de pronuntie al copilului: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.atitudine_fata_de_defectul_de_pronuntie_al_copilului" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.daca_sunt_stangaci_sau_ambidextri_in_familie!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Daca sunt stangaci sau ambidextri in familie: {{fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.daca_sunt_stangaci_sau_ambidextri_in_familie }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.daca_sunt_stangaci_sau_ambidextri_in_familie ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Daca sunt stangaci sau ambidextri in familie: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.daca_sunt_stangaci_sau_ambidextri_in_familie" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.conditiile_materiale_igienice_educative_ale_familiei!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Conditii materiale, igienice, educative ale familiei: {{fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.conditiile_materiale_igienice_educative_ale_familiei }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.conditiile_materiale_igienice_educative_ale_familiei ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Conditii materiale, igienice, educative ale familiei: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.familiala.relatiile_in_familie.conditiile_materiale_igienice_educative_ale_familiei" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 2.2 Personala</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.personala.nasterea!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Nasterea: {{fisa_examinare_logopedica.anameza.personala.nasterea }} </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.personala.nasterea ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Nasterea: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.personala.nasterea" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.personala.nastere_in_luna!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Nasterea la: {{fisa_examinare_logopedica.anameza.personala.nastere_in_luna }} luni </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.personala.nastere_in_luna ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Nasterea in luna: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.personala.nastere_in_luna" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.personala.greutate_la_nastere!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Greutate la nastere: {{fisa_examinare_logopedica.anameza.personala.greutate_la_nastere }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.personala.greutate_la_nastere ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Greutate la nastere: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.personala.greutate_la_nastere" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.personala.tulburari_psihice!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Tulburari psihice: {{fisa_examinare_logopedica.anameza.personala.tulburari_psihice }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.personala.tulburari_psihice ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Tulburari psihice: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.personala.tulburari_psihice" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 2.3 Date asupra auzului si limbajului</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.ganguritul!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Ganguritul: {{fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.ganguritul }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.ganguritul ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Ganguritul: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.ganguritul" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.ritmul_vorbirii!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Ritmul Vorbirii: {{fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.ritmul_vorbirii }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.ritmul_vorbirii ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Ritmul Vorbirii: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.ritmul_vorbirii" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.limba_materna_si_cea_a_mediului_inconjurator!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Limba materna si cea a mediului inconjurator: {{fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.limba_materna_si_cea_a_mediului_inconjurator }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.limba_materna_si_cea_a_mediului_inconjurator ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Limba materna si cea a mediului inconjurator: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.limba_materna_si_cea_a_mediului_inconjurator" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.intelegerea_vorbirii_persoanelor_din_anturaj!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Intelegerea vorbirii persoanelor din anturaj: {{fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.intelegerea_vorbirii_persoanelor_din_anturaj }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.intelegerea_vorbirii_persoanelor_din_anturaj ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Intelegerea vorbirii persoanelor din anturaj: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.intelegerea_vorbirii_persoanelor_din_anturaj" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.insusirea_unei_limbi_straine!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Insusirea unei limbi straine: {{fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.insusirea_unei_limbi_straine }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.insusirea_unei_limbi_straine ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Insusirea unei limbi straine: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.insusirea_unei_limbi_straine" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.insusirea_scrierii_si_a_citirii!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Insusirea scrierii si a citirii: {{fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.insusirea_scrierii_si_a_citirii }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.insusirea_scrierii_si_a_citirii ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Insusirea scrierii si a citirii: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.insusirea_scrierii_si_a_citirii" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.cand_a_aparut_tulburarea_de_limbaj!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Cand a aparut tulburarea de limbaj: {{fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.cand_a_aparut_tulburarea_de_limbaj }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.cand_a_aparut_tulburarea_de_limbaj ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Cand a aparut tulburarea de limbaj: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.cand_a_aparut_tulburarea_de_limbaj" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.cauza_reala_sau_presupusa!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Cauza reala sau presupusa: {{fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.cauza_reala_sau_presupusa }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.cauza_reala_sau_presupusa ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Cauza reala sau presupusa: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.cauza_reala_sau_presupusa" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.daca_a_beneficiat_de_tratament_logopedic!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Daca a beneficiat de tratament logopedic: {{fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.daca_a_beneficiat_de_tratament_logopedic }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.daca_a_beneficiat_de_tratament_logopedic ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Daca a beneficiat de tratament logopedic: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.daca_a_beneficiat_de_tratament_logopedic" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.daca_a_beneficiat_de_tratament_logopedic!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Daca a beneficiat de tratament logopedic: {{fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.daca_a_beneficiat_de_tratament_logopedic }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.daca_a_beneficiat_de_tratament_logopedic ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Daca a beneficiat de tratament logopedic: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.anameza.date_asupra_auzului_si_limbajului.daca_a_beneficiat_de_tratament_logopedic" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> 3 Examenul somatic si functional</h2></span>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 3.1 Aspecte somatice</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.vorbirea!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Vorbirea: {{fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.vorbirea }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.vorbirea ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Vorbirea: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.vorbirea" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.cavitate_bucala!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Cavitate Bucala: {{fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.cavitate_bucala }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.cavitate_bucala ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Cavitate Bucala: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.cavitate_bucala" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.valul_palatin!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Valul palatin: {{fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.valul_palatin }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.valul_palatin ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Valul palatin: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.valul_palatin" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.limba!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Limba: {{fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.limba }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.limba ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Limba: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.limba" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.buzele!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Buzele: {{fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.buzele }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.buzele ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Buzele: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.buzele" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.maxilarele!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Maxilarele: {{fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.maxilarele }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.maxilarele ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Maxilarele: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.maxilarele" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.dantura!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Dantura: {{fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.dantura }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.dantura ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Dantura: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.aspecte_somatice.dantura" ></textarea>
    </mat-form-field>
</ng-container>


<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 3.2 Motricitatea</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.mersul!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Marsul: {{fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.mersul }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.mersul ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Marsul: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.mersul" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.coordonare_generala_a_miscarilor!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Coordonarea generala a miscarilor: {{fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.coordonare_generala_a_miscarilor }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.coordonare_generala_a_miscarilor ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Coordonarea generala a miscarilor: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.coordonare_generala_a_miscarilor" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.lateralitatea!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Lateralitatea: {{fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.lateralitatea }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.lateralitatea ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Lateralitatea: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.lateralitatea" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_limbii!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Motricitatea limbii: {{fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_limbii }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_limbii ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Motricitatea limbii: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_limbii" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_mimico_faciala!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Motricitatea mimico-faciala: {{fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_mimico_faciala }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_mimico_faciala ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Motricitatea mimico-faciala: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_mimico_faciala" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_buzelor!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Motricitatea buzelor: {{fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_buzelor }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_buzelor ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Motricitatea buzelor: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.motricitatea.motricitatea_buzelor" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 3.3 Functia auditiva</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.atentia_auditiva!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Atentia auditiva: {{fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.atentia_auditiva }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.atentia_auditiva ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Atentia auditiva: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.atentia_auditiva" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.memoria_auditiva!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Memoria auditiva: {{fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.memoria_auditiva }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.memoria_auditiva ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Memoria auditiva: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.memoria_auditiva" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.capacitate_de_diferentiere_fonematica!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Capacitate de diferentiere fonematica: {{fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.capacitate_de_diferentiere_fonematica }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.capacitate_de_diferentiere_fonematica ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Capacitate de diferentiere fonematica: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.capacitate_de_diferentiere_fonematica" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.auzul_muzical!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Auzul muzical: {{fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.auzul_muzical }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.auzul_muzical ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Auzul muzical: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.functia_auditiva.auzul_muzical" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 3.4 Functia fonatorie</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functie_fonatorie.intensitatea_vocii!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Intensitatea vocii: {{fisa_examinare_logopedica.examenul_somatic_si_functional.functie_fonatorie.intensitatea_vocii }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functie_fonatorie.intensitatea_vocii ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Intensitatea vocii: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.functie_fonatorie.intensitatea_vocii" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functie_fonatorie.calitatea_vocii!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Calitatea vocii: {{fisa_examinare_logopedica.examenul_somatic_si_functional.functie_fonatorie.calitatea_vocii }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.functie_fonatorie.calitatea_vocii ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Calitatea vocii: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.functie_fonatorie.calitatea_vocii" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 3.5 Structuri perceptiv-motrice</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.schema_corporala!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Schema corporala: {{fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.schema_corporala }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.schema_corporala ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Schema corporala: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.schema_corporala" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_spatiala!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Orientare spatiala: {{fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_spatiala }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_spatiala ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Orientare spatiala: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_spatiala" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Orientare temporale:</span>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.momentele_zilei!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Momentele zilei: {{fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.momentele_zilei }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.momentele_zilei ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Momentele zilei: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.momentele_zilei" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.ceasul!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Ceasul: {{fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.ceasul }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.ceasul ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Ceasul: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.ceasul" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.zilele_saptamanii!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Zilele Saptamanii: {{fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.zilele_saptamanii }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.zilele_saptamanii ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Zilele Saptamanii: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.zilele_saptamanii" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.lunile_anului!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Lunile anului: {{fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.lunile_anului }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.lunile_anului ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Lunile anului: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.lunile_anului" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.anotimpurile!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Anotimpurile: {{fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.anotimpurile }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.anotimpurile ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Anotimpurile: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.orientare_temporala.anotimpurile" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.culori!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Culori: {{fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.culori }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.culori ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Culori: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.culori" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.marimi!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Marimi: {{fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.marimi }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.marimi ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Marimi: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.marimi" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.cantitati!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Cantitati: {{fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.cantitati }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.cantitati ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Cantitati: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.cantitati" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.forme_geometrice!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Forme geometrice: {{fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.forme_geometrice }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.forme_geometrice ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Forme geometrice: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.examenul_somatic_si_functional.structuri_perceptiv_motrice.forme_geometrice" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> 4 Limbajul oral si scris</h2></span>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 4.1 Prenuntia sunetelor si a cuvintelor</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.reproducerea_fenomenelor_pe_baza_de_auz!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Reproducerea fenomenelor pe baza de auz: {{fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.reproducerea_fenomenelor_pe_baza_de_auz }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.reproducerea_fenomenelor_pe_baza_de_auz ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Reproducerea fenomenelor pe baza de auz: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.reproducerea_fenomenelor_pe_baza_de_auz" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.reproducerea_fenomenelor_pe_baza_de_citire!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Reproducerea fenomenelor pe baza de citire: {{fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.reproducerea_fenomenelor_pe_baza_de_citire }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.reproducerea_fenomenelor_pe_baza_de_citire ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Reproducerea fenomenelor pe baza de citire: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.reproducerea_fenomenelor_pe_baza_de_citire" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.miscari_articulatorii_defectuase!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Miscari articulatorii defectuoase: {{fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.miscari_articulatorii_defectuase }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.miscari_articulatorii_defectuase ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Miscari articulatorii defectuoase: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.miscari_articulatorii_defectuase" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.miscari_asociate!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Miscari asociate: {{fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.miscari_asociate }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.miscari_asociate ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Miscari asociate: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.miscari_asociate" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.omisiuni_de_sunete!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Omisiuni de sunete: {{fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.omisiuni_de_sunete }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.omisiuni_de_sunete ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Omisiuni de sunete: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.omisiuni_de_sunete" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.confundarea_sunetelor!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Confundarea sunetelor: {{fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.confundarea_sunetelor }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.confundarea_sunetelor ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Confundarea sunetelor: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.confundarea_sunetelor" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.estomparea_terminatiei_cuvintelor!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Estomparea terminatiei cuvintelor: {{fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.estomparea_terminatiei_cuvintelor }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.estomparea_terminatiei_cuvintelor ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Estomparea terminatiei cuvintelor: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.pronuntia_sunetelor_si_a_cuvintelor.estomparea_terminatiei_cuvintelor" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 4.2 Ritmul si fluenta vorbirii</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.fluenta!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Fluenta: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.fluenta }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.fluenta ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Fluenta: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.fluenta" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.ritmul_vorbirii!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Ritmul vorbirii: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.ritmul_vorbirii }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.ritmul_vorbirii ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Ritmul vorbirii: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.ritmul_vorbirii" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_sunete!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Poticniri la sunete: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_sunete }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_sunete ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Poticniri la sunete: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_sunete" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_silabe!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Poticniri la silabe: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_silabe }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_silabe ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Poticniri la silabe: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_silabe" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_cuvinte!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Poticniri la cuvinte: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_cuvinte }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_cuvinte ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Poticniri la cuvinte: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.poticniri_la_cuvinte" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_sunete!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Repetari de sunete: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_sunete }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_sunete ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Repetari de sunete: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_sunete" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_silabe!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Repetari de silabe: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_silabe }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_silabe ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Repetari de silabe: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_silabe" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_cuvinte!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Repetari de cuvinte: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_cuvinte }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_cuvinte ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Repetari de cuvinte: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.repetari_de_cuvinte" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.spasme_articulatorii_si_muschii_afectati!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Spasme articulatorii si muschii afectati: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.spasme_articulatorii_si_muschii_afectati }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.spasme_articulatorii_si_muschii_afectati ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Spasme articulatorii si muschii afectati: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.spasme_articulatorii_si_muschii_afectati" ></textarea>
    </mat-form-field>
</ng-container>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Calitatea vorbirii: </span>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_vorbirii.independenta!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Independenta: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_vorbirii.independenta }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_vorbirii.independenta ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Independenta: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_vorbirii.independenta" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_vorbirii.reflectata!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Reflectata: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_vorbirii.reflectata }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_vorbirii.reflectata ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Reflectata: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_vorbirii.reflectata" ></textarea>
    </mat-form-field>
</ng-container>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Calitatea citirii: </span>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_citirii.independenta!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Independenta: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_citirii.independenta }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_citirii.independenta ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Independenta: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_citirii.independenta" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_citirii.reflectata!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Reflectata: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_citirii.reflectata }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_citirii.reflectata ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Reflectata: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.calitatea_citirii.reflectata" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.diverse_fobii!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Diverse Fobii: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.diverse_fobii }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.diverse_fobii ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Diverse Fobii: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.diverse_fobii" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.miscari_concomitente!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Miscari Concomitente: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.miscari_concomitente }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.miscari_concomitente ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Miscari Concomitente: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.miscari_concomitente" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.vorbirea_in_diferite_situatii!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Vorbirea in diferite situatii: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.vorbirea_in_diferite_situatii }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.vorbirea_in_diferite_situatii ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Vorbirea in diferite situatii: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.vorbirea_in_diferite_situatii" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.balbaiala_in_cant_si_la_instrumente!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Balbaiala in cant si la instrumente: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.balbaiala_in_cant_si_la_instrumente }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.balbaiala_in_cant_si_la_instrumente ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Balbaiala in cant si la instrumente: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.balbaiala_in_cant_si_la_instrumente" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.balbaiala_in_scris!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Balbaiala in scris: {{fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.balbaiala_in_scris }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.balbaiala_in_scris ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Balbaiala in scris: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.ritmul_si_fluenta_vorbirii.balbaiala_in_scris" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 4.3 Scrierea si citirea</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.copierea!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Copiere: {{fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.copierea }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.copierea ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Copiere: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.copierea" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.dictarea!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Dictare: {{fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.dictarea }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.dictarea ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Dictare: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.dictarea" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.compunerea!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Compunere: {{fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.compunerea }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.compunerea ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Compunere: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.compunerea" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.structura_grafica!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Structura grafica: {{fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.structura_grafica }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.structura_grafica ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Structura grafica: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.structura_grafica" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.structura_logica!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Structura logica: {{fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.structura_logica }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.structura_logica ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Structura logica: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.structura_logica" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.citire!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Citire: {{fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.citire }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.citire ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Citire: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.scrierea_si_citirea.citire" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 4.4 Domeniul socio-afectiv</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.temperament!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Temperament (introvert, extravert): {{fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.temperament }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.temperament ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Temperament (introvert, extravert): </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.temperament" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.comportament!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Comportament (sociabil, agresiv, opozant): {{fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.comportament }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.comportament ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Comportament (sociabil, agresiv, opozant): </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.comportament" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.activitati_preferate!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Activitati preferate (ii place sa): {{fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.activitati_preferate }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.activitati_preferate ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Activitati preferate (ii place sa): </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.activitati_preferate" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.activitati_evitate!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Activitati evitate (nu-i place sa): {{fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.activitati_evitate }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.activitati_evitate ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Activitati evitate (nu-i place sa): </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.activitati_evitate" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.jocuri_preferate!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Jocuri / jucarii preferate: {{fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.jocuri_preferate }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.jocuri_preferate ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Jocuri / jucarii preferate: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.jocuri_preferate" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.personaje_indragite_din_desene_animate!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Personaje indragite din desene animate: {{fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.personaje_indragite_din_desene_animate }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.personaje_indragite_din_desene_animate ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Personaje indragite din desene animate: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.limbajul_oral_si_scris.domeniu_socio_afectiv.personaje_indragite_din_desene_animate" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> 5. Diagnosticul tulburarilor de limbaj</h2></span>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 5.1 Caracterizarea limbajului si a persoanei examinate</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.vorbirea!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Vorbirea: {{fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.vorbirea }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.vorbirea ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Vorbirea: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.vorbirea" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.scrisul!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Scrisul: {{fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.scrisul }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.scrisul ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Scrisul: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.scrisul" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.cititul!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Cititul: {{fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.cititul }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.cititul ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Cititul: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.cititul" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.succesul_scolar!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Succesul scolar: {{fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.succesul_scolar }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.succesul_scolar ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Succesul scolar: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.succesul_scolar" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.conduita!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Conduita: {{fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.conduita }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.conduita ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Conduita: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.caracterizarea_limbajului_si_a_persoanei_examinate.conduita" ></textarea>
    </mat-form-field>
</ng-container>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 5.2 Cauzele si diagnosticul</h3></span>

<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.cauze_presupuse!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Cauze presupuse: {{fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.cauze_presupuse }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.cauze_presupuse ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Cauze presupuse: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.cauze_presupuse" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.cauze_confirmate!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Cauze confirmate: {{fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.cauze_confirmate }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.cauze_confirmate ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Cauze confirmate: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.cauze_confirmate" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.diagnosticul_initial!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Diagnosticul initial: {{fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.diagnosticul_initial }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.diagnosticul_initial ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Diagnosticul initial: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.diagnosticul_initial" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.evolutia_diagnosticului!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Evolutia diagnosticului: {{fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.evolutia_diagnosticului }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.evolutia_diagnosticului ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Evolutia diagnosticului: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.evolutia_diagnosticului" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.diagnosticul_final!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Diagnosticul final: {{fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.diagnosticul_final }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.diagnosticul_final ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Diagnosticul final: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.diagnosticul_final" ></textarea>
    </mat-form-field>
</ng-container>

<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.alte_mentiuni!=""'>
    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Alte mentiuni: {{fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.alte_mentiuni }}  </span>
</ng-container>
<ng-container *ngIf='fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.alte_mentiuni ===""'>
    <mat-form-field style="width: 100%;" >
        <mat-label>Alte mentiuni: </mat-label>
        <textarea matInput [(ngModel)]="fisa_examinare_logopedica.diagnosticul_tulburarilor_de_limbaj.cauzele_si_diagnosticul.alte_mentiuni" ></textarea>
    </mat-form-field>
</ng-container>

<button mat-raised-button (click)="generatePDF()"> Printeaza</button>
