import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { map, tap, toArray, take } from 'rxjs/operators';
import { catchError } from 'rxjs/operators/catchError';
import { InitPacientDetailsInterface } from '../interfaces/initpacientdetails.interface';
import { AdresaInterface, DateContactEmailTelefonInterface, DateContactInterface, DatePersonaleCreateInterface, DatePersonaleInterface, PacientCreateCreatePacientInterface, PacientCreateInterface, PacientInterface, PacientNakedInterface, PacientTwoServerInterface } from '../interfaces/pacient.interface';
import { InitPacientDetails } from '../models/initpacientdetails.mode';
import { Adresa, DateContact, DateContactEmailTelefon, DatePersonale, DatePersonaleCreate, Pacient, PacientNaked, PacientTwoServer } from '../models/pacientdetails.model';
import { AdresaService } from './adresa.service';
import { DateDeContactService } from './datedecontact.service';
import { DatePersonaleService } from './datepersonale.service';
import { IntervalService } from './interval.service';
import { LocalStorageService } from './local-storage.service';
import { serverurl } from './server.service';
import { asocPacientReprezentantLegalService } from '../services/asocpacientreprezentantlegal.service';
import { asocPacientPersoanaContactService } from '../services/asocpacientpersoanacontact.service';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
  providedIn: 'root'
})
export class PacientiService{
  public pacients: Pacient[] = []
  public pacient_selectat: PacientTwoServerInterface = new PacientTwoServer()
  private server_url=serverurl+"/api/v1/pacient/"
  // private server_url="http://optimabk.ztools.ro/api/v1/pacient/"
  // private server_url="api/pacienti"
  public localstorageflag = 0;
  localStorageChanges$ = this.localStorageService.changes$;
  public init_pacientDetails : InitPacientDetailsInterface = new InitPacientDetails()

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    public datepersonaleService: DatePersonaleService,
    public datecontactService: DateDeContactService,
    public adresaService: AdresaService,
    public intervalService: IntervalService,
    public asocPacientReprezentantLegalService: asocPacientReprezentantLegalService,
    public asocPacientPersoanaContactService: asocPacientPersoanaContactService,


  ) {
  }

  // TEST AREA

  getPacientifromServertest(){
    // return this.http.get<PacientInterface[]>(this.server_url).pipe(
    //   toArray()
    // )
    return this.http.get<PacientTwoServerInterface[]>(this.server_url)
  }

  // END TEST AREA

getPacientifromServerTwoById(id){
  return this.http.get<any>(this.server_url+"two/getdetailsbyid/"+id)
}


setPacientSelectat(pacient_in, extra_text : string = "not_set" ){
  this.pacient_selectat = pacient_in
  // return (this.pacient_selectat)
  console.log( "Pacient selectat din service == >> ", this.pacient_selectat)
  console.log( "extra text Pacient selectat din service == >> ", extra_text)
}

// set pacient selectat initial. Returns:
// statusul Orarului de somn - daca intervale indisponibilitate == null return false
setPacientSelectatTwo(pacient_in, extra_text : string = "not_set" ){
  console.log( "extra text Pacient selectat din service == >> ", extra_text)
  this.pacient_selectat = pacient_in
  this.getStatusOrarIndisponibilitate()

  // return (this.pacient_selectat)
  console.log( "Pacient selectat din service - status orar de somn == >> ", this.init_pacientDetails.flag_orar_de_somn_status)
}

getStatusCI(){
  if (this.pacient_selectat?.date_personale?.numar_carte_identitate != null){
    return true
  }
  return false
}

getStatusIndependenta(){
  if(this.pacient_selectat.independent){
    return true
  }
  return false
}

getStatusOrarIndisponibilitate(){
  if(this.pacient_selectat.intervale_indisponibilitate){
    if (this.pacient_selectat.intervale_indisponibilitate.length == 0){
      this.init_pacientDetails.flag_adauga_primul_interval = true
    }
    else{
      if (this.pacient_selectat.intervale_indisponibilitate.length){
        this.init_pacientDetails.flag_orar_de_somn_status = true
        this.init_pacientDetails.flag_adauga_interval_nou = true
      }
    }
  }
}

checkDacaBeneficiarAveaTelefon(){
  if (this.pacient_selectat.date_contact.telefon != ''){
    return true
  }
  return false
}
checkDacaOldTelefonBeneficiarLaFel(telefon){
  if (this.pacient_selectat.date_contact.telefon === telefon){
    return true
  }
  return false
}

checkDacaBeneficiarAveaEmail(){
  if (this.pacient_selectat.date_contact.email){
    return true
  }
  return false
}

checkDacaOldEmailBeneficiarLaFel(email){
  if (this.pacient_selectat.date_contact.email === email){
    return true
  }
  return false
}




deleteAdresaFromDateContact(date_contact: DateContactInterface){
  // console.log("Delete Adresa from Date Contact ==>> ", date_contact)
  var intermediar_adresa_id = date_contact.adresaid
  this.datecontactService.updateDateContactStergeAdresa(date_contact.id)
  .subscribe ( (data) =>{
    if (data){
      // console.log("New Date COntact Sterge Adresa == >> ", intermediar_adresa_id)
      this.adresaService.stergeAdresaFromServer(intermediar_adresa_id)
      .subscribe ( (data) => {
        if (data){
          // console.log(" Deleted adresa ==>> ", data)
          this.pacient_selectat.date_contact.adresaid = undefined
          this.pacient_selectat.date_contact.adresa = undefined
        }
        else{
          console.log("NO DATA from Deleted adresa << == ")
        }
      })
    }
    else{
      console.log("NO New Date COntact Sterge Adresa << == ")
    }
  })
}


saveTelefonEmailBeneficiarDemo(datecontact_beneficiar: DateContactInterface){
  var telefon: string = this.pacient_selectat.date_contact.telefon
  var email: string = this.pacient_selectat.date_contact.email
  // OLD :
  // Caz 1 -> OLD = Gol =>
  //  Caz 1 . 1 -> Nou = Gol == >> Actiune = Nimic
  //  Caz 1 . 2 -> NOU = NU Gol == >> Actiune = Vechi = Nou
  // Caz 2 -> OLD = NU Gol =>
  //  Caz 2 . 1 -> Nou = GOL == >> Actiune = STERG OLD
  //  Caz 2 . 2 -> Nou = NU Gol =>
  //      Caz 2 . 2 . 1 -> OLD == NOU == >> Actiune = Nimic
  //      Caz 2 . 2 . 2 -> OLD != NOU == >> Actiune = OLD = NOU
  if (!this.checkDacaBeneficiarAveaTelefon()){
    // NU Exista un Numar De Telefon Anterior
    // CAZ 1
    if (datecontact_beneficiar.telefon != ''){
      // TELEFON NOU NU GOL
      // CAZ 1.2
      telefon = datecontact_beneficiar.telefon
    }
  }
  else{
    // EXISTA TELEFON ANTERIOR
    // CAZ 2
    console.log("Beneficiar Avea deja un Telefon")

    if (datecontact_beneficiar.telefon == ''){
      // TELEFON NOU GOL
      // CAZ 2.1
      // STERG TEL VECHI
      telefon = datecontact_beneficiar.telefon
    }
    else{
      // TELEFON NOU NU GOL
      // CAZ 2.2
      // VERIFIC DACA SUNT DIFERITE
      if (!this.checkDacaOldTelefonBeneficiarLaFel(datecontact_beneficiar.telefon)){
        // TELEFON NOU DIFERIT DE TELEFON VECHI
        // CAZ 2.2.2
        // VECHI = NOU
        telefon = datecontact_beneficiar.telefon
      }
    }
  }
  if (!this.checkDacaBeneficiarAveaEmail()){
    // NU Exista un EMAIL Anterior
    // CAZ 1
    if (datecontact_beneficiar.email != ''){
      // EMAIL NOU NU GOL
      // CAZ 1.2
      email = datecontact_beneficiar.email
    }
    else{
      // EXISTA EMAIL ANTERIOR
      // CAZ 2
      console.log("Beneficiar Avea deja un EMAIL")
      if (datecontact_beneficiar.email == ''){
        // EMAIL NOU GOL
        // CAZ 2.1
        // STERG EMAIL VECHI
        email = datecontact_beneficiar.email
      }
      else{
        // EMAIL NOU NU GOL
        // CAZ 2.2
        // VERIFIC DACA SUNT DIFERITE
        if (!this.checkDacaOldEmailBeneficiarLaFel(datecontact_beneficiar.email)){
          // EMAIL NOU DIFERIT DE EMAIL VECHI
          // CAZ 2.2.2
          // VECHI = NOU
          email = datecontact_beneficiar.email
        }
      }
    }
  }
}
addReprLegalToUser(reprezentant_legal){
  console.log("Reprezentant legal in Service ==>> ", reprezentant_legal)
  return (this.http.post<any>(serverurl+"/api/v1/pacientaddreprelegal/",reprezentant_legal,this.httpOptions))
}

deleteRLfromUser(ids: Number[]){
  return this.http.post<any>(serverurl+"/api/v1/pacient/two/deleterlbyid/",ids,this.httpOptions)
}

deletePCfromUser(ids: Number[]){
  return this.http.post<any>(serverurl+"/api/v1/pacient/two/deletepcbyid/",ids,this.httpOptions)
}

addPacientToServer(pacientnou: PacientCreateInterface){
  console.log("Add pacient to server ==>> ")
  console.log("Pacient = ", pacientnou)
  return (this.http.post<any>(this.server_url, pacientnou, this.httpOptions))
}
addPacientToServerTwo(body){
  console.log("Add pacient to server ==>> ")
  console.log("Pacient two = ", body)
  return (this.http.post(this.server_url+"verstwo/",body, this.httpOptions))
}


  // getPacienti(): Observable<any> {
    // return this.http.get(this.server_url).pipe(map(this.extractData))
    // .do(data => console.log ("Pacient Service = " + JSON.stringify(data)))
    // // .do(data => JSON.stringify(data))
    // .catch(this.handleError);
    // }

    // private extractData(response: any){
    //   let body = response;
    //   console.log("body din Pacient Service: =>", body);
    //   this.pacients = body;
    //   return body || {};
    // }

  getPacienti():Pacient[] {
    return this.pacients;
  }


  returnPacientFromServerById(id){
    this.getPacientifromServerTwoById(id)
    .subscribe ( ( data ) => {
      if (data){
        return data
      }
      else{
        return -1
      }
    })
  }

  getPacientifromServer(){
    return this.http.get<PacientInterface[]>(this.server_url)
  }

  getPacientifromServerbyId(id){
    return this.http.get<PacientInterface>(this.server_url+id)
  }

  getPacientById(id:number):Pacient{
    for (var i of this.pacients)
    {
      if (i.id === id){
        return i
      }
    }
    return undefined
  }



  addPacient(pacientNou : Pacient) {
    pacientNou.id = this.pacients.length + 1;
    // let newpacientkeys = Object.keys(pacientNou);
    // // console.log("newpacientkeys: => ", newpacientkeys)
    // newpacientkeys.forEach(element =>{
    //   if (pacientNou[element] === undefined){
    //     if ( typeof pacientNou[element] === 'string' )
    //     {
    //       pacientNou[element] = '-';
    //     }
    //   }
    // })
    this.pacients.push(pacientNou);
    if (this.localstorageflag){
      this.localStorageService.remove("PacientList");
      this.addtolocalStorage(this.pacients);
    }
    else {
      this.addtolocalStorage(this.pacients);
    }
    // console.log("Lista pacienti din pacienti service =>", this.pacients);

    return this.http.post<Pacient>(this.server_url, pacientNou, cudOptions).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: Response){
    console.log("Error from Pacient Service: => " + error)
    return Observable.throw("500 internal server error" )
  }

  addtolocalStorage(PacientList){
    // console.log("addtolocalstorage PacientList: =>", PacientList);
    // console.log("Addtolocalstorage",this.localStorageService.set("PacientList", PacientList));
    this.localStorageService.set("PacientList", PacientList)
  }

  checkObjectHasAnyValueDefined(object_to_check){
    for (var i of Object.keys(object_to_check)){
      if (object_to_check[i] != undefined){
        // console.log("Obiectu are ceva defined")
        return true
      }
    }
    // console.log("Tot obiectu undefined")
    return false
  }

  checkIfSameObj(obiect_unu, obiect_doi){
    return (!(JSON.stringify(obiect_unu) == JSON.stringify(obiect_doi) ))
  }

  updateInfo(pacientselectat:Pacient){
    this.pacients.forEach(element => {
      if (element.id == pacientselectat.id){
        // console.log("UpdatePacientInfo / OldValue => ", element);

        element = pacientselectat;
      }
    });
  }

createPacientNakedModel(pac){
  let intermediar : PacientNakedInterface = new PacientNaked()
  for (var i of Object.keys(intermediar)){
    if (Object.keys(pac).indexOf(i) >= 0){
      if (pac[i] == undefined){
      }
      else{
        intermediar[i] = pac[i]
      }
    }
  }
  return intermediar
}

checkChangesPacientNaked(date_init, date_noi){
  // console.log("Key date init ==>> ", Object.keys(date_init))
  // console.log("Key date noi ==>> ", Object.keys(date_noi))
  for (let i of Object.keys(date_init)){
    // console.log("I din date init ==>> ", i)
    if (Object.keys(date_noi).indexOf(i) < 0){
      // console.log("Object Keys < 0")
      return true
    }
    else{
      // console.log("date_init[i] ==>> ", date_init[i])
      // console.log("date_noi[i] ==>> ", date_noi[i])
      if (date_init[i] != date_noi[i]){
        // console.log("Object Keys DIFERITE")
        return true
      }
    }
  }
  return false
}

updatePacientNakedDetailsOnServer(id, date_noi){
  // console.log(" id == >> ", id," date - noi == >> ",date_noi)
  return this.http.put<any>(this.server_url+id,date_noi,this.httpOptions)
  // return new Observable( () => {true})
}

async checkPacientInfoChanged(date_initiale, date_noi, RL_este_PC){
  let intermediar_date_initiale = this.createPacientNakedModel(date_initiale)
  let intermediar_date_noi = this.createPacientNakedModel(date_noi)
  intermediar_date_noi.reprezentant_legal_persoana_de_contact = RL_este_PC
  let def_return : any
  // console.log("Date PACIENT INITIALE",date_initiale)
  // console.log("Date NEW PACIENT",date_noi)
  // console.log("Date PACIENT NAKED",intermediar_date_initiale)
  // console.log("Date NEW PACIENT NAKED",intermediar_date_noi)
  // console.log("DATELE SUNT LA FEL == >> ",!(this.checkChangesPacientNaked(intermediar_date_initiale, intermediar_date_noi)))
  if (this.checkChangesPacientNaked(intermediar_date_initiale, intermediar_date_noi)){
    def_return = await this.updatePacientNakedDetailsOnServer(date_initiale.id, intermediar_date_noi)
    .toPromise()
    // console.log("def_ return ==>> ", def_return)
    return true
  }
  else {
    return false
  }
  // return (this.checkChangesPacientNaked(intermediar_date_initiale, intermediar_date_noi))
}

createDatePersonaleModel(pac){
  let intermediar : DatePersonaleCreateInterface = new DatePersonaleCreate()
  for (var i of Object.keys(intermediar)){
    if (Object.keys(pac).indexOf(i) >= 0){
      if (pac[i] == undefined){
      }
      else{
        intermediar[i] = pac[i]
      }
    }
  }
  return intermediar
}

checkCarteIdentitateChange(date_noi, carte_identitate_check){
  if(carte_identitate_check){
    // console.log("Carte IDENTITATE BIFA CHECK")
  }
  else{
    // console.log("NU !!! Carte IDENTITATE BIFA CHECK")
    if(date_noi.serie_carte_identitate != null){
      // console.log("Serie nu este null", date_noi.serie_carte_identitate)
      date_noi.serie_carte_identitate = null
    }
    if(date_noi.numar_carte_identitate != null){
      // console.log("Numar nu este null", date_noi.numar_carte_identitate)
      date_noi.numar_carte_identitate = null
      // console.log("Numar nu este null", date_noi.numar_carte_identitate)
    }
  }
  return date_noi
}

checkDatePersonaleChanges(date_initiale, date_noi){
  let intermediar_date_initiale = this.createDatePersonaleModel(date_initiale.date_personale)
  let intermediar_date_noi = this.createDatePersonaleModel(date_noi)
  intermediar_date_noi = this.checkCarteIdentitateChange(intermediar_date_noi, date_noi.carte_identitate_check)
  // console.log("DATE PERSONALE INITIALE == >>", intermediar_date_initiale)
  // console.log("DATE PERSONALE NOI == >>", intermediar_date_noi)
  // console.log(" Sunt diferente intre cele doua? ==>> ",this.checkChangesPacientNaked(intermediar_date_initiale,intermediar_date_noi))
  if (this.checkChangesPacientNaked(intermediar_date_initiale,intermediar_date_noi)){
    this.datepersonaleService.updateDatePersonaleToServer(intermediar_date_noi,date_initiale.date_personale.id).toPromise()
    return true
  }
  else{
    return false
  }
}

creazaListaIDuri(lista_initiala){
  let intermediar_ids_intervale_indisponibilitate_de_sters = []
  for ( let j of lista_initiala){
    // console.log("J == >> ",j)
    intermediar_ids_intervale_indisponibilitate_de_sters.push(j.id)
  }
  return intermediar_ids_intervale_indisponibilitate_de_sters
}

stergeOrareIndisponibilitateLaSave(ids){
  let intermediar_var = this.intervalService.stergeIntervalIndisponibilitateMultiple(ids).toPromise()
  // console.log("INTERMEDIAR VAR ==>> ", intermediar_var)
}

chackCazStergereOrareIndisponibilitate(date_noi){
  if(date_noi.intervale_indisponibilitate.data.length > 0){
    let intermediar_ids_intervale_indisponibilitate_de_sters = this.creazaListaIDuri(date_noi.intervale_indisponibilitate.data)
    // console.log(" INtermediar IDS ==>> ", intermediar_ids_intervale_indisponibilitate_de_sters)
    this.stergeOrareIndisponibilitateLaSave(intermediar_ids_intervale_indisponibilitate_de_sters)
  }

}

async checkOrareIndisponibilitateStatus(date_initiale, date_noi){
  if (date_initiale.orar_de_somn){
    // console.log("Date INITIALE ORAR DE SOMN ESTE TRUE")
    if(!date_noi.orar_de_somn){
      // console.log("Date NOI ORAR DE SOMN ESTE FALSE")
      this.chackCazStergereOrareIndisponibilitate(date_noi)
    }
  }
  else{
    // console.log("Date INITIALE ORAR DE SOMN ESTE FALSE")
    if(!date_noi.orar_de_somn){
      // console.log("Date NOI ORAR DE SOMN ESTE FALSE")
      this.chackCazStergereOrareIndisponibilitate(date_noi)
    }
  }
}


saveTelefonEmail(datecontact: DateContactInterface){
  this.datecontactService.updateDateContactTelefonEmail(datecontact)
  .subscribe ( (data) =>{
    if (data){
      console.log("New Date COntact Telefon Email == >> ", data)
    }
    else{
      console.log("NO New Date COntact Telefon Email == >> ")
    }
  })
}

createDateContactTelefonEmailModel(pac){
  var intermediar : DateContactEmailTelefonInterface = new DateContactEmailTelefon()
  for (var i of Object.keys(intermediar)){
    if (Object.keys(pac).indexOf(i) >= 0){
      if (pac[i] == undefined){
      }
      else{
        intermediar[i] = pac[i]
      }
    }
  }
  return intermediar
}

async checkTelefonEmailChange(date_initiale, date_noi){
  let intermediar_date_initiale = this.createDateContactTelefonEmailModel(date_initiale.date_contact)
  let intermediar_date_noi = this.createDateContactTelefonEmailModel(date_noi)
  // console.log("DATE CONTACT INITIALE == >>", intermediar_date_initiale)
  // console.log("DATE CONTACT NOI == >>", intermediar_date_noi)
  intermediar_date_noi.id = intermediar_date_initiale.id
  // console.log("DATE CONTACT NOI == >>", intermediar_date_noi)
  // console.log("SUNT DIFERENTE INTRE ASTEA? ==>> ", this.checkChangesPacientNaked(intermediar_date_initiale,intermediar_date_noi))
  if (this.checkChangesPacientNaked(intermediar_date_initiale,intermediar_date_noi)){
    // console.log("DATELE SUNT DIFERITE !!")
    this.datecontactService.updateDateContactTelefonEmailTwo(intermediar_date_noi).toPromise()
    // console.log("ADAUGAT TELEFON EMAIL PE SERVER !!")
  }
}

checkAdresaInitial(date_initiale){
  if (date_initiale.date_contact.adresaid == null){
    return false
  }
  return true
}
checkAdresaSaved(date_noi){
  if (date_noi.adresa == undefined){
    return false
  }
  return true
}

async checkDateContactChange(date_initiale, date_noi){
  this.checkTelefonEmailChange(date_initiale, date_noi)
  // console.log("Exista adresa initiala? ==>> ", this.checkAdresaInitial(date_initiale))
  // console.log("Exista adresa Salvata ? ==>> ", this.checkAdresaSaved(date_noi))
  if (this.checkAdresaInitial(date_initiale)){
    if (this.checkAdresaSaved(date_noi)){
      // console.log("DRESA SALVATA ==>> ", this.pacient_selectat.date_contact.adresa)
      // console.log("Exista adresa SALVATA SI EXISTA ADRESA NOUA ==>> ")
      date_noi.adresa.id = date_initiale.date_contact.adresa.id
      // console.log("Exista adresa SALVATA  ==>> ", date_initiale.date_contact.adresa)
      // console.log("EXISTA ADRESA NOUA ==>> ", date_noi.adresa)
      // console.log("NOUA SI ADRESA SALVATA SUNT LA FEL? ==>> ", this.checkChangesPacientNaked(date_initiale.date_contact.adresa,date_noi.adresa))
      if(this.checkChangesPacientNaked(date_initiale.date_contact.adresa,date_noi.adresa)){
        // console.log("SUNT DIFERITE")
        this.adresaService.updateAdresaToServer(date_noi.adresa, date_noi.adresa.id).toPromise()
        // console.log("Updated Adresa TO SERVER !! ")
      }
      else{
        // console.log("SUNT IDENTICE !!")
      }
    }
    else{
      // console.log("Exista adresa SALVATA SI NU EXISTA ADRESA NOUA ==>> ")
      this.datecontactService.updateDateContactStergeAdresa(date_initiale.date_contact.id)
      .subscribe( (data) => {
        if (data){
          // console.log("DATA DIN STERGE ADRESA DIN DATE CONTACT ==>> ", data)
          this.adresaService.stergeAdresaFromServer(date_initiale.date_contact.adresaid)
          .subscribe( (data) => {
            if (data){
              // console.log("DATA DIN STERGE ADRESA FROM SERVER ==>> ", data)
            }
          })
        }
      })
    }
  }
  else{
    // console.log("NU Exista adresa SALVATA")
    if (this.checkAdresaSaved(date_noi)){
      // console.log("Exista adresa NOUA")
      this.adresaService.addAdresaToServer(date_noi.adresa)
      .subscribe( (data) => {
        if (data){
          // console.log("DATA FROM ADD ADRESA TO SERVER XX ==>> ", data)
          this.datecontactService.updateDateContact({'id': date_initiale.date_contact.id, 'adresaid': data.id})
          .subscribe ( (data) => {
            if(data){
              // console.log("DATA FROM UPDATE DATE CONTACT CU ADRESA ID NOUA ==>> ", data)
            }
          })
        }
      })
    }
    else{
      // console.log("NU Exista adresa NOUA")
    }
  }
}

async updatePacientInfo(date_initiale,date_initiale_two, date_personale, date_contact, RL_este_PC){
  // console.log("DATE INITIALE == >> ", date_initiale)
  // console.log("DATE INITIALE TWO == >> ", date_initiale_two)
  // console.log("DATE PERSONALE INITIAL == >> ", date_initiale)
  // console.log("DATE PERSONALE NEW == >> ", date_personale)
  // console.log("DATE CONTACT NEW == >> ", date_contact)
  // console.log("Datele sunt modificate si au fost adaugate pe server? ==>> ", await this.checkPacientInfoChanged(date_initiale, date_personale))
  var naked_pacient_info_updated = await this.checkPacientInfoChanged(date_initiale, date_personale, RL_este_PC)
  // console.log("Datele PERSONALE sunt modificate si au fost adaugate pe server? ==>> ", await this.checkDatePersonaleChanges(date_initiale, date_personale))
  var date_personale_info_updated = await this.checkDatePersonaleChanges(date_initiale, date_personale)
  // console.log("Date NEW PACIENT NAKED",this.createPacientNakedModel(date_personale))
  this.checkOrareIndisponibilitateStatus(date_initiale, date_personale)
  this.checkDateContactChange(date_initiale,date_contact)
}

updateRLInfox(date_initiale, date_noi){
  let intermediart : any[] = []
  intermediart.push(date_initiale)
  let initiale_observable$ = from (intermediart)
  .pipe(
    tap( data => {
      let intermediar_date_initiale = this.createPacientNakedModel(date_noi)
      console.log(" intermediar _ date_ initiale ==>> ", intermediar_date_initiale)
    }),
    map(
      data => {
        console.log(" DATA DIN MAP SUBSCRIBE ARRAY ==>> ", data)
      })
  ) .subscribe()
  // this.datepersonaleService.updateDatePersonaleToServer(date_noi.datepersonale,date_noi.datepersonale.id)
  // .pipe(
  //   map(
  //     data => {
  //       console.log(" DATA DIN MAP ==>> ", data)
  //     })
  // ) .subscribe()
}


async updateRLInfo (date_initiale, date_noi){
  let observables: Observable<any>[];
  console.log("RL ==>> DATE INITIAL == >> ", date_initiale)
  console.log("RL ==>> DATE NOI == >> ", date_noi)
  // this.updateRLInfox(date_initiale, date_noi)
  // console.log(" DATE PERSONALE INITIALE ==>> ", date_initiale.datepersonale)
  // console.log(" DATE PERSONALE NOI ==>> ", date_noi.datepersonale)
  // console.log("DATELE PERSONALE SUNT LA FEL ==>> ",!this.checkChangesPacientNaked(date_initiale.datepersonale,date_noi.datepersonale))
  if (this.checkChangesPacientNaked(date_initiale.datepersonale,date_noi.datepersonale)){
    this.datepersonaleService.updateDatePersonaleToServer(date_noi.datepersonale,date_noi.datepersonale.id).toPromise()
    // return true
    // console.log("UPDATED DATE PERSONALE PT CA DIFERA")
  }
  // else{
  //   console.log("DATE PERSONALE LA FEL")
  //   // return false
  // }
  if(date_initiale.calitateid != date_noi.calitateid){
    // console.log("CALITATE DIFERA")
    this.asocPacientReprezentantLegalService.updateAsocToServer(date_noi)
    .subscribe ( (data) => {
      if (data){
        console.log("DATA FROM REPRE LEGAL UPDATE CALITATE ID ==>> ", data)
      }
    })
  }

  let intermediar_date_contact_email_telefon_initiale = this.createDateContactTelefonEmailModel(date_initiale.datecontact)
  let intermediar_date_contact_email_telefon_noi = this.createDateContactTelefonEmailModel(date_noi.datecontact)
  // console.log(" DATE CONTACT INITIALE ==>> ", intermediar_date_contact_email_telefon_initiale)
  // console.log(" DATE CONTACT NOI ==>> ", intermediar_date_contact_email_telefon_noi)
  if (!this.checkChangesPacientNaked(intermediar_date_contact_email_telefon_initiale,intermediar_date_contact_email_telefon_noi)){
    // console.log("DATE CONTACT ==>> TELEFON / EMAIL ==>> RL EDITAT LA FEL")
  }
  else{
    //  console.log("DATE CONTACT ==>> TELEFON / EMAIL ==>> RL EDITAT DIFERA")
     this.datecontactService.updateDateContactTelefonEmailTwo(intermediar_date_contact_email_telefon_noi).toPromise()
    //  console.log("DATE CONTACT ==>> TELEFON / EMAIL ==>> ACTUALIZAT")
   }

   if (date_initiale.datecontact.adresaid != null){
     console.log("Date contact adresa id initial NU ESTE NULL")
    // if(date_noi.datecontact.adresa != undefined){
      if (date_noi.datecontact.adresa != null){

         console.log("Date contact adresa id NOU NU ESTE NULL")
         if(this.checkChangesPacientNaked(date_initiale.datecontact.adresa,date_noi.datecontact.adresa)){
           console.log("ADRESELE DIFERA")
           this.adresaService.updateAdresaToServer(date_noi.datecontact.adresa, date_noi.datecontact.adresa.id).toPromise()
           console.log("ADRESA A FOST ACTUALIZATA")

          }
          else{
           console.log("ADRESELE SUNT IDENTICE")
         }
       }
       else{
         console.log("Date contact adresa id NOU ESTE NULL ==>> sterg ADRESA SALVATA")
         this.datecontactService.updateDateContactStergeAdresa(date_noi.datecontact.id)
         .subscribe( (data) => {
           if (data){
             console.log("RL ==>> DATA DIN STERGE ADRESA DIN DATE CONTACT ==>> ", data)
             this.adresaService.stergeAdresaFromServer(date_initiale.datecontact.adresaid)
             .subscribe( (data) => {
               if (data){
                 console.log("RL ==>> DATA DIN STERGE ADRESA FROM SERVER ==>> ", data)
               }
             })
           }
         })
       }
    // }
    // else{
    //   console.log("RL ADRESA NOUA => UNDEFINED")
    // }
   }
   else{
    console.log("ADRESA INITIALA ESTE NULL")
    if (date_noi.datecontact.adresa != undefined){
      console.log("ADRESA NOU NU ESTE NULL ==>> ADD ADRESA TO SERVER ")
      this.adresaService.addAdresaToServer(date_noi.datecontact.adresa)
      .subscribe( (data) => {
        if (data){
          console.log("RL ==>> DATA FROM ADD ADRESA TO SERVER XX ==>> ", data)
          this.datecontactService.updateDateContact({'id': date_initiale.datecontactid, 'adresaid': data.id})
          .subscribe ( (data) => {
            if(data){
              console.log("RL ==>> DATA FROM UPDATE DATE CONTACT CU ADRESA ID NOUA ==>> ", data)
            }
          })
        }
      })
    }
    else{
      console.log("Date contact adresa id NOU ESTE NULL")
    }
   }
   return this.asocPacientReprezentantLegalService.getRLFromServer(date_initiale.id)
}

async updatePCInfo (date_initiale, date_noi){
  console.log("PC ==>> DATE INITIAL == >> ", date_initiale)
  console.log("PC ==>> DATE NOI == >> ", date_noi)
  let intermediar_date_initiale = this.createPacientNakedModel(date_initiale)
  let intermediar_date_noi = this.createPacientNakedModel(date_noi)

  if (this.checkChangesPacientNaked(date_initiale.datepersonale,date_noi.datepersonale)){
    this.datepersonaleService.updateDatePersonaleToServer(date_noi.datepersonale,date_noi.datepersonale.id).toPromise()
  }
  if(date_initiale.calitateid != date_noi.calitateid){
    this.asocPacientPersoanaContactService.updateAsocToServer(date_noi)
    .subscribe ( (data) => {
      if (data){
        console.log("DATA FROM PERS CONTACT UPDATE CALITATE ID ==>> ", data)
      }
    })
  }
  let intermediar_date_contact_email_telefon_initiale = this.createDateContactTelefonEmailModel(date_initiale.datecontact)
  let intermediar_date_contact_email_telefon_noi = this.createDateContactTelefonEmailModel(date_noi.datecontact)
  if (!this.checkChangesPacientNaked(intermediar_date_contact_email_telefon_initiale,intermediar_date_contact_email_telefon_noi)){
  }
  else{
     this.datecontactService.updateDateContactTelefonEmailTwo(intermediar_date_contact_email_telefon_noi).toPromise()
   }
   if (date_initiale.datecontact.adresaid != null){
      if (date_noi.datecontact.adresa != null){
         if(this.checkChangesPacientNaked(date_initiale.datecontact.adresa,date_noi.datecontact.adresa)){
           this.adresaService.updateAdresaToServer(date_noi.datecontact.adresa, date_noi.datecontact.adresa.id).toPromise()
          }
          else{
         }
       }
       else{
         this.datecontactService.updateDateContactStergeAdresa(date_noi.datecontact.id)
         .subscribe( (data) => {
           if (data){
             this.adresaService.stergeAdresaFromServer(date_initiale.datecontact.adresaid)
             .subscribe( (data) => {
               if (data){
               }
             })
           }
         })
       }
   }
   else{
    if (date_noi.datecontact.adresa != undefined){
      this.adresaService.addAdresaToServer(date_noi.datecontact.adresa)
      .subscribe( (data) => {
        if (data){
          this.datecontactService.updateDateContact({'id': date_initiale.datecontactid, 'adresaid': data.id})
          .subscribe ( (data) => {
            if(data){
            }
          })
        }
      })
    }
    else{
    }
   }
}

 orderPacientsListById(lista_beneficiari){
  //  let intermediar = lista_beneficiari
   lista_beneficiari.sort( (a,b) => {
     if ( b.id < a.id ) return 1
     if ( b.id > a.id ) return -1
     return 0
   } )
  //  console.log( "INTERMEDIAR SORTAT ==>> ", lista_beneficiari)
   return lista_beneficiari
 }

}
