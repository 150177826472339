 import { Injectable } from '@angular/core';
import { Departament } from '../models/nomenclatoare.model';
import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError} from 'rxjs/operators/catchError';
import { Observable } from 'rxjs';
import { serverurl } from './server.service';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};


@Injectable({
  providedIn: 'root'
})

export class DepartamentService {
  public departament: Departament [] = []
  public localstorageflag = 0;
  private server_url=serverurl+"/api/v1/departament/";
  // private server_url="http://localhost/api/v1/departament/";
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    let data = this.localStorageService.get("Departament");
    // console.log("On Initi DAta Local storage => ",data)
    if (data)
    {
      this.localstorageflag = 1;
      this.departament = data;
      this.departament.forEach(element=> {
        this.http.post<Departament>(this.server_url, element, cudOptions).pipe(
          catchError(this.handleError)
            )
          });
      }
    else {
      // console.log("PacientList nu este Local")
    }

   }

  addDepartamentToServer(departament){
    return(this.http.post<any>(this.server_url,departament,this.httpOptions))

  }
  getDepartamentFromServer(){
    return this.http.get<Departament[]>(this.server_url)
  }
  getDepartament(){
    return this.departament;
  }

  addDepartament(newdepartament){
    this.departament.push(newdepartament);
    console.log("lista departament: => ", newdepartament);
    if (this.localstorageflag){
      this.localStorageService.remove("Departament");
      this.addtolocalStorage(this.departament);
    }
    else {
      this.addtolocalStorage(this.departament);
    }
    console.log("Lista pacienti din pacienti service =>", this.departament);

    return this.http.post<Departament>(this.server_url, newdepartament, cudOptions).pipe(
      catchError(this.handleError)
    );
  }
  addtolocalStorage(PacientList){
    console.log("addtolocalstorage CategorieServicii: =>", PacientList);
    console.log("Addtolocalstorage",this.localStorageService.set("Departament", PacientList));
  }
  private handleError(error: Response){
    console.log("Error from Pacient Service: => " + error)
    return Observable.throw("500 internal server error" )
  }
}
