import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddconsultComponent } from '../addconsult/addconsult.component';
import { PdfpreviewerComponent } from '../pdfpreviewer/pdfpreviewer.component';
import { FileService } from '../services/file.service';
import { PDFViewerDialogService } from '../services/pdfviewerdialog.service';

@Component({
  selector: 'app-vizualizeazaconsultpdfbutton',
  templateUrl: './vizualizeazaconsultpdfbutton.component.html',
  styleUrls: ['./vizualizeazaconsultpdfbutton.component.css']
})
export class VizualizeazaconsultpdfbuttonComponent implements OnInit {
  @Input() consultData: any;
  @Input() pacientData: any;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddconsultComponent>,
    public fileService : FileService,
    public pdfViewerFromTabelService : PDFViewerDialogService
  ) { }

  ngOnInit(): void {
  }

  processBase64(input_string){
    let intermediar_index = input_string.indexOf(',')
    // console.log("INTERMEDIAR INDEX ==>> ", intermediar_index)
    // console.log("INTERMEDIAR INPUT -- INDEX ==>> ", input_string.substring(intermediar_index+1))
    return input_string.substring(input_string.indexOf(',')+1)
    // return input_string
  }

  viewPDFBtnClick(){
    // console.log("VIEW BUTTON CLICK ==>> CONSULT DATA ==>> ", this.consultData)
    this.pdfViewerFromTabelService.openViewerFromTabel()
    this.fileService.getFileDataFromServerByFisierID(this.consultData.idFisier)
    .subscribe ( (file_base64) => {
      if (file_base64){
        // console.log(" DATA DIN GET BASE64 FROM SERVER BY FILE ID -- VIEW PDF ==>> ", file_base64)
        const dialogRef = this.dialog.open(PdfpreviewerComponent, {
          width: '800px',
          height: '90%',
          data: this.processBase64(file_base64),

        });
        dialogRef.afterClosed().subscribe( async res => {
          this.pdfViewerFromTabelService.closeViewerFromTabel()
        })
      }
    })
  }

}
