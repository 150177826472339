import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { dynamic_menu } from 'src/app/interfaces/dynamic-menu.interface';


@Component({
  selector: 'app-dynamic-menu',
  templateUrl: './dynamic-menu.component.html',
  styleUrls: ['./dynamic-menu.component.css']
})
export class DynamicMenuComponent implements OnInit {
  @Input() dmenu: dynamic_menu;
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  selected:string;

  constructor() { }

  ngOnInit(): void {
  }

  getChildTitle(selection: string): void{
    this.notify.emit(selection);
  }
}