<mat-form-field>
    <input matInput [(ngModel)]="newDepartament.cod" placeholder="Cod Departament"> 
 </mat-form-field>
 <mat-form-field>
     <input matInput [(ngModel)]="newDepartament.denumire" placeholder="Denumire Departament"> 
  </mat-form-field>
  <br>
<mat-form-field>
    <mat-label>Tip Departament</mat-label>
    <mat-select [(value)] = newDepartament.tipDepartement>
        <mat-option *ngFor="let tipdepartament of tipdepartamente" [value]="tipdepartament">
            {{tipdepartament.denumire}}
        </mat-option>
    </mat-select>
</mat-form-field>
  <br>
  <!-- <mat-form-field>
      <mat-label>Servicii Departament</mat-label>
      <mat-select [(value)] = newDepartament.servicii multiple>
          <mat-option *ngFor="let serviciu of servicii" [value]="serviciu">
              {{serviciu.denumire}}
          </mat-option>
      </mat-select>
  </mat-form-field> -->

  <mat-form-field>
    <mat-label>Servicii Departament</mat-label>
    <mat-select [(value)] = newDepartament.servicii>
        <mat-option *ngFor="let serviciu of servicii" [value]="serviciu">
            {{serviciu.denumire}}
        </mat-option>
    </mat-select>
</mat-form-field>

    <br>
  <!-- <mat-checkbox [(ngModel)]="newDepartament.arevalabilitate"> Valabilitate: </mat-checkbox> -->
 
  <!-- <mat-form-field >
     <mat-label>De la</mat-label>
     <input matInput [matDatepicker]="picker" [(ngModel)]= "newDepartament.valabilitate.start" [disabled]="!newDepartament.arevalabilitate">
     <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
     <mat-datepicker #picker></mat-datepicker>
   </mat-form-field>
   <mat-form-field >
     <mat-label>Pana la</mat-label>
     <input matInput [matDatepicker]="picker2" [(ngModel)]= "newDepartament.valabilitate.end" [disabled]="!newDepartament.arevalabilitate">
     <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
     <mat-datepicker #picker2></mat-datepicker>
   </mat-form-field> -->
 
 <button mat-raised-button (click)="AddDepartamentToServer()"> Adauga</button>
 
 <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
     
     <!-- ID Column -->
     <ng-container matColumnDef="cod">
     <th mat-header-cell *matHeaderCellDef> Cod </th>
     <td mat-cell *matCellDef="let element"> {{element.cod}} </td>
     </ng-container>
 
     <ng-container matColumnDef="denumire">
         <th mat-header-cell *matHeaderCellDef> Denumire </th>
         <td mat-cell *matCellDef="let element"> {{element.denumire}} </td>
     </ng-container>

     <ng-container matColumnDef="tip">
        <th mat-header-cell *matHeaderCellDef> Tip </th>
        <td mat-cell *matCellDef="let element"> {{element.tipDepartement}} </td>
    </ng-container>

    <ng-container matColumnDef="servicii">
        <th mat-header-cell *matHeaderCellDef> Servicii </th>
        <td mat-cell *matCellDef="let element"> {{element.servicii}} </td>
    </ng-container>

     <ng-container matColumnDef="valabildela">
         <th mat-header-cell *matHeaderCellDef> Valabil de la </th>
         <td mat-cell *matCellDef="let element"> {{element.valabilitate.start | date:'shortDate'}} </td>
     </ng-container>
 
     <ng-container matColumnDef="valabilpanala">
         <th mat-header-cell *matHeaderCellDef> Valabil de la </th>
         <td mat-cell *matCellDef="let element"> {{element.valabilitate.end | date:'shortDate'}} </td>
     </ng-container>
 
     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
     <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
 
 </table>