<span style="
font-size: 14px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> Plan de intervenție personalizat </h2></span>

<span style="
font-size: 14px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 1. Informatii de baza </h3></span>

<span style="
font-size: 14px;
display: flex;
padding: 10px;
left: 5px;
"> Numele si prenumele copilului: {{ ( (plan_de_interventie_personalizat.nume_pacient != "") ? plan_de_interventie_personalizat.nume_pacient : "Nespecificat" ) +' '+ ( (plan_de_interventie_personalizat.prenume_pacient != "") ? plan_de_interventie_personalizat.prenume_pacient : "Nespecificat" )  }} </span>

<span style="
font-size: 14px;
display: flex;
padding: 10px;
left: 5px;
"> Data nasterii: {{ ( (plan_de_interventie_personalizat.data_nastere_pacient != "") ? plan_de_interventie_personalizat.data_nastere_pacient : "Nespecificat" ) }} </span>

<ng-container *ngIf='flag_adresa'>
<span style="
font-size: 14px;
display: flex;
padding: 10px;
left: 5px;
"> Domiciliu: {{ ( (plan_de_interventie_personalizat.domiciliu_pacient != "") ? plan_de_interventie_personalizat.domiciliu_pacient : "Nespecificat" ) }} </span>
</ng-container>
<ng-container *ngIf='!flag_adresa'>
  <mat-form-field style="width: 100%;" >
    <mat-label>Domiciliu: </mat-label>
    <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.domiciliu_pacient" ></textarea>
  </mat-form-field>
</ng-container>
<!-- <span style="
font-size: 14px;
display: flex;
padding: 10px;
left: 5px;
"> Diagnosticul copilului: {{ ( (plan_de_interventie_personalizat.diagnostic_pacient != "") ? plan_de_interventie_personalizat.diagnostic_pacient : "Nespecificat" ) }} </span> -->

<mat-form-field style="width: 100%;" >
  <mat-label>Diagnosticul copilului : </mat-label>
  <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.diagnostic_pacient" ></textarea>
</mat-form-field>

<span style="
font-size: 14px;
display: flex;
padding: 10px;
left: 5px;
"> Starea medicala: </span>

<!-- <ng-container *ngIf='plan_de_interventie_personalizat.stare_medicala.convulsii.continut!=""'>
    <span style="
    font-size: 14px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Convulsii: {{plan_de_interventie_personalizat.stare_medicala.convulsii.continut }} </span>
</ng-container> -->
<!-- <ng-container *ngIf='plan_de_interventie_personalizat.stare_medicala.convulsii.continut ===""'> -->

    <mat-form-field style="width: 100%;" >
        <mat-label>Convulsii: </mat-label>
        <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.stare_medicala.convulsii.continut" ></textarea>
    </mat-form-field>

<!-- </ng-container> -->


    <mat-form-field style="width: 100%;" >
        <mat-label>Deficiente fizice: </mat-label>
        <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.stare_medicala.deficiente_fizice.continut" ></textarea>
    </mat-form-field>


    <mat-form-field style="width: 100%;" >
        <mat-label>Probleme pulmonare: </mat-label>
        <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.stare_medicala.probleme_pulmonare.continut" ></textarea>
    </mat-form-field>


    <mat-form-field style="width: 100%;" >
        <mat-label>Probleme cardiace: </mat-label>
        <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.stare_medicala.probleme_cardiace.continut" ></textarea>
    </mat-form-field>


    <!-- <ng-container *ngIf='!flag_date_tata'> -->
      <mat-form-field style="width: 100%;" >
        <mat-label>Numele Tatalui: </mat-label>
        <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.tata_pacient.nume_tata_pacient" ></textarea>
      </mat-form-field>
      <mat-form-field style="width: 100%;" >
        <mat-label>Prenumele Tatalui: </mat-label>
        <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.tata_pacient.prenume_tata_pacient" ></textarea>
      </mat-form-field>
    <!-- </ng-container> -->
    <!-- <ng-container *ngIf='flag_date_tata'>
    <span style="
    font-size: 14px;
    display: flex;
    padding: 10px;
    left: 5px;
    "> Tata: {{ plan_de_interventie_personalizat.tata_pacient.nume_tata_pacient  +' '+ plan_de_interventie_personalizat.tata_pacient.prenume_tata_pacient   }} </span>
    </ng-container> -->

    <!-- <ng-container *ngIf='!flag_date_mama'> -->
      <mat-form-field style="width: 100%;" >
        <mat-label>Numele Mamei: </mat-label>
        <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.mama_pacient.nume_mama_pacient" ></textarea>
      </mat-form-field>
      <mat-form-field style="width: 100%;" >
        <mat-label>Prenumele Mamei: </mat-label>
        <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.mama_pacient.prenume_mama_pacient" ></textarea>
      </mat-form-field>
    <!-- </ng-container>
    <ng-container *ngIf='flag_date_mama'>
      <span style="
      font-size: 14px;
      display: flex;
      padding: 10px;
      left: 5px;
      "> Mama: {{ plan_de_interventie_personalizat.mama_pacient.nume_mama_pacient  +' '+ plan_de_interventie_personalizat.mama_pacient.prenume_mama_pacient  }} </span>
    </ng-container> -->

<span style="
font-size: 14px;
display: flex;
padding: 10px;
left: 5px;
"> Institutia: Fundatia Mihai Nesu  </span>

<span style="
font-size: 14px;
display: flex;
padding: 10px;
left: 5px;
"> Data evaluarii planului: {{ ( (plan_de_interventie_personalizat.data_elaborarii_planului != "") ? plan_de_interventie_personalizat.data_elaborarii_planului : "Nespecificat" ) }} </span>
<mat-form-field style="width: 100%;" >
  <mat-label>Echipa de interventie: </mat-label>
  <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.echipa_de_interventie" ></textarea>
</mat-form-field>
<span style="
font-size: 14px;
display: flex;
padding: 10px;
left: 5px;
"> Perioada de interventie: </span>


    <mat-form-field style="width: 100%;" >
        <mat-label>Probleme cu care se confruntă copilul: </mat-label>
        <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.probleme_cu_care_se_confrunta_copilul" ></textarea>
    </mat-form-field>


    <mat-form-field style="width: 100%;" >
        <mat-label>Evaluarea inițială: </mat-label>
        <textarea matInput [(ngModel)]="plan_de_interventie_personalizat.evaluare_initiala" ></textarea>
    </mat-form-field>
<span style="
font-size: 14px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> 2. Structura programului de intervenție personalizat </h3></span>


<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width:100%">

    <!-- ID Column -->
    <ng-container matColumnDef="Evaluare">
    <th mat-header-cell *matHeaderCellDef> Evaluare </th>
    <td mat-cell *matCellDef="let element"> {{element.evaluare}} </td>
    </ng-container>

     <!-- ID Column -->
     <ng-container matColumnDef="Sesiune">
     <th mat-header-cell *matHeaderCellDef> Sesiune </th>
     <td mat-cell *matCellDef="let element"> {{ (element.sesiune.start === element.sesiune.end)?(element.sesiune.start | date:'d.MM.YYYY'):((element.sesiune.start | date:'d.MM.YYYY') + ' - ' + (element.sesiune.end | date:'d.MM.YYYY')) }} </td>
     </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="Obiective">
    <th mat-header-cell *matHeaderCellDef> Obiective </th>
    <td mat-cell *matCellDef="let element"> {{element.obiective}} </td>
    </ng-container>

          <!-- ID Column -->
    <ng-container matColumnDef="Metode si mijloace de realizare">
    <th mat-header-cell *matHeaderCellDef> Metode si mijloace de realizare </th>
    <td mat-cell *matCellDef="let element"> {{element.metode_si_mijloace}} </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="Recomandari">
    <th mat-header-cell *matHeaderCellDef> Recomandari </th>
    <td mat-cell *matCellDef="let element"> {{element.metode_si_mijloace}} </td>
    </ng-container>

     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
     <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

 </table>

 <span style="
 font-size: 14px;
 display: flex;
 padding: 10px;
 left: 5px;
 "> Sesiunea: </span>

<mat-form-field appearance="fill">
    <mat-label>Adauga un interval</mat-label>
    <mat-date-range-input  [rangePicker]="picker">
      <input [(ngModel)]="structura.sesiune.start" matStartDate placeholder="Start date">
      <input [(ngModel)]="structura.sesiune.end" matEndDate placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

<button mat-raised-button color="primary" >Alege o programare</button>

<button mat-raised-button color="primary" >Alege o programare recurenta</button>



 <mat-form-field style="width: 100%;" >
    <mat-label>Evaluare : </mat-label>
    <textarea matInput [(ngModel)]="structura.evaluare" ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>Obiective : </mat-label>
    <textarea matInput [(ngModel)]="structura.obiective" ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>Metode și mijloace de realizare : </mat-label>
    <textarea matInput [(ngModel)]="structura.metode_si_mijloace" ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>Recomandari : </mat-label>
    <textarea matInput [(ngModel)]="structura.recomandari" ></textarea>
</mat-form-field>

<button mat-raised-button color="primary" (click)="add()">Adauga</button>
<ng-container *ngIf="caz == 1">
  <button mat-raised-button (click)="semneazaRL()" > Semnatura Reprezentant Legal</button>
  <button mat-raised-button (click)="semneazaTerapeut()"  > Semnatura Terapeut</button>
  <button mat-raised-button (click)="openPDFPreviewComponent()" > Previzualizeaza </button>
</ng-container>
<ng-container *ngIf="caz == 2">
  <!-- <button mat-raised-button (click)="openPDFPreviewSsaveModificari()"  > Genereaza Document </button> -->
  <button mat-raised-button color="warn"  mat-dialog-close > Inchide </button>
</ng-container>
