import { Component, OnInit, ViewChild } from '@angular/core';
import { TipDiagnostic, TipDiagnosticCreate, TipDiagnosticCreateFaraValabilitate, Valabilitate, ValabilitateCreate } from 'src/app/models/nomenclatoare.model';
import { TipDiagnosticService } from 'src/app/services/tipdiagnostic.service';
import { MatTableDataSource } from '@angular/material/table';
import { ValabilitateInterface, ValabilitateCreateInterface, TipDiagnosticInterface, TipDiagnosticCreateInterface, TipDiagnosticCreateFaraValabilitateInterface} from 'src/app/interfaces/nomenclatoare.interface';
import { IntervalService } from '../../services/interval.service'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { DatePipe } from '@angular/common'
import { MatInput } from '@angular/material/input';
// import { type } from 'os';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TipdiagnosticeditComponent } from '../tipdiagnosticedit/tipdiagnosticedit.component';


export const MY_FORMATS = {
  parse: {
    dateInput: 'd',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-tipdiagnostic',
  templateUrl: './tipdiagnostic.component.html',
  styleUrls: ['./tipdiagnostic.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class TipdiagnosticComponent implements OnInit {
  tipdiagnostice: TipDiagnostic[];
  public tipDiagnostic: any[];
  displayedColumns: string[] = ['id','cod', 'denumire','edit','deleteselect'];
  dataSource: any
  newtipdiagnostic = new TipDiagnosticCreate();
  interval:ValabilitateInterface
  intervalcreate: ValabilitateCreateInterface
  listaintervale:ValabilitateInterface[] = []
  start: Date
  end: Date
  startformated: string
  todelete: any[] = []
  endformated: string
  public IntervalList: ValabilitateInterface [] = []
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialog: MatDialog,public tipDiagnosticService: TipDiagnosticService, public intervalService: IntervalService,public datepipe: DatePipe) { }


  ngOnInit(): void {
  //  this.tipdiagnostice = this.tipDiagnosticService.getTipDiagnostic();
  //  this.dataSource = new MatTableDataSource(this.tipdiagnostice);
  this.tipDiagnosticService.getfromserver()
  .subscribe( (data:TipDiagnosticInterface[]) => {
    if (data){
      this.tipDiagnostic = data
      this.dataSource  =  new MatTableDataSource(this.tipDiagnostic);
          this.dataSource.paginator = this.paginator;
          console.log("tipDiagnostic  ==>> ",this.tipDiagnostic);
    }
  })
}
openTipDiagnosticEdit(diagnostic):void{
  const dialogRef = this.dialog.open(TipdiagnosticeditComponent, {
    width: '850px',
    height: '550px',
    data: diagnostic
  });
  dialogRef.afterClosed().subscribe( result =>{
    this.updatedata()
  })
}
marktodelete(deleteid){
  if (this.todelete.includes(deleteid)){
    console.log("Id-ul deja selectat !! ")
    this.todelete.forEach( (value,index) =>{
      if (value == deleteid) this.todelete.splice(index,1)
    })
  }
  else{
    this.todelete.push(deleteid)
  }
  console.log("Id-uri de sers ==>> ",this.todelete)
}
deleteitems(){
  this.todelete.forEach( value =>{
    this.tipDiagnosticService.deletefromserver(value).subscribe( (data) =>{
      if (data){
        console.log("Data from delete categorie de servicii ==>> ", data)
        this.updatedata()
      }
    })
  })
}
updatedata(){
  this.tipDiagnosticService.getfromserver()
  .subscribe((data:TipDiagnosticInterface[]) => {
    if (data){
      console.log("data din cat service subscribe ==>> ", data)
      this.tipDiagnostic = data
      this.dataSource = new MatTableDataSource(this.tipDiagnostic);
      this.dataSource.paginator = this.paginator;
    }
  });
}
  AddTipDiagnostic(){
    this.tipDiagnosticService.addtipdiagnognostic(this.newtipdiagnostic);
    this.tipdiagnostice = this.tipDiagnosticService.getTipDiagnostic();
    this.dataSource = new MatTableDataSource(this.tipdiagnostice);
          this.dataSource.paginator = this.paginator;
          this.newtipdiagnostic = new TipDiagnostic();

  }
  valabilitatestatuschange(v){
    console.log("status v =>", v)
    this.newtipdiagnostic.arevalabilitate = v
  }
  startdateset(v) {
    let vd2 = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    let intermediar = this.datepipe.transform(new Date(vd2),"MM.dd.YYYY")
    this.start = new Date(intermediar)
  }
  enddateset(v) {
    let vd = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    let intermediar = this.datepipe.transform(new Date(vd),"MM.dd.YYYY")
    this.end = new Date(intermediar)
    return vd
  }
  addnewtipdiagnostic(){
    console.log ("categoriiservicii component -> addnewcategorie() -> newcat inainte de orice  => ", this.newtipdiagnostic)
    if (this.newtipdiagnostic.arevalabilitate){ // check daca este bifata valabilitatea
      console.log ("categoriiservicii component -> addnewcategorie() -> are valabilitate TRUE  => ", this.newtipdiagnostic.arevalabilitate)
      this.intervalcreate = new ValabilitateCreate()
      this.intervalcreate.format_type = "MM.dd.YYYY"
      this.intervalcreate.start = this.start.toString()
      this.intervalcreate.end = this.end.toString()
      console.log("categoriiservicii component -> addnewcategorie() -> this.intervalcreate  => ",this.intervalcreate)
      console.log("categorii array 1 => -- !! -- ==> ",this.tipDiagnostic)      //commented before
      this.intervalService.addIntervalToServer(this.intervalcreate)
        .subscribe((data)=>{
          if (data){
            console.log("data from add interval to server => ", data)
            this.newtipdiagnostic.valabilitateid = data
            console.log("newcat.valabilitateid from add interval to server from subscribe => ",this.newtipdiagnostic.valabilitateid)
            // console.log("type of newcat.valabilitateid from add interval to server from subscribe => ",typeof(this.newtipdiagnostic.valabilitateid))
            this.tipDiagnosticService.addTipDiagnosticCuValabilitate(this.newtipdiagnostic)
                .subscribe( data => {
                  console.log("data put id ==> ",data.id)
                  console.log("data keys ==> ",Object.keys(data))
                  console.log("data full object ==> ",data)
                  this.tipDiagnostic.push(data)
                  console.log("categorii array => -- !! -- ==> ",this.tipDiagnostic)      //commented before
                  this.refreshformafteradd()
                });
          }
        })
      console.log("categorii array 2 => -- !!!! -- ==> ",this.tipDiagnostic)      //commented before
      console.log("newcat full object from add interval to server => ",this.newtipdiagnostic)
    }
    else{
      console.log ("categoriiservicii component -> addnewcategorie() -> are valabilitate FALSE  => ", this.newtipdiagnostic.arevalabilitate)
      console.log ("this.newtipdiagnostic full object daca nu are valabilitate  => ", this.newtipdiagnostic)
      this.tipDiagnosticService.addTipDiagnosticFaraValabilitate(this.newtipdiagnostic)
        .subscribe(data =>{
          if (data){
            console.log("data din addHeroFaraValabilitate ==>> ",data)
            this.tipDiagnostic.push(data)
            this.refreshformafteradd()
          }
        })
    }
  }

  @ViewChild('startDate',{
    read:MatInput
  }) startDate:MatInput;
  @ViewChild('endDate',{
    read:MatInput
  }) endDate:MatInput;

  refreshformafteradd(){
    this.newtipdiagnostic = new TipDiagnosticCreate();
    this.startDate.value='';
    this.endDate.value='';
    this.dataSource = new MatTableDataSource(this.tipDiagnostic);
          this.dataSource.paginator = this.paginator;
          console.log("===>> REFRESH FORMAT <<===")
  }
}
