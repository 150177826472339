import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { v4 } from 'uuid';
import { FileElement } from '../file-manager/model/element';
import { BehaviorSubject,Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {catchError} from 'rxjs/operators/catchError';
import { serverurl } from './server.service';
import { SafeResourceUrl } from '@angular/platform-browser';
class FisiereIDsiPath{
  constructor(
    public id: string,
    public path: SafeResourceUrl
  ){}
}

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

export interface IFileService {
  add(fileElement: FileElement);
  delete(id: string);
  // update(id: string, update: Partial<FileElement>);
  queryInFolder(folderId: string): Observable<FileElement[]>;
  get(id: string): FileElement;
}

@Injectable()
export class FileService implements IFileService {
  public files: FileElement[] = [];
  public fileindex: number = -1;

  public filespaths: any[] = []
  private map = new Map<string, FileElement>();
  public localstorageflag = 0;
  private server_url=serverurl+"/api/v1/fisier/";

  localStorageChanges$ = this.localStorageService.changes$;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient,private localStorageService: LocalStorageService) {

    let data = this.localStorageService.get("Files");
    if (data)
    {
      this.localstorageflag = 1;
      this.files = data;
      this.files.forEach(element=> {
        this.http.post<FileElement>(this.server_url, element, cudOptions).pipe(
          catchError(this.handleError)
            )
          });
      console.log("PacientList este local")
      }
    else {
      console.log("PacientList nu este Local")
    }
  }
  private handleError(error: Response){
    console.log("Error from Pacient Service: => " + error)
    return Observable.throw("500 internal server error" )
  }

  generateFileID(){
    return (v4());
  }

  addFileToServerReturnFileID(fisiernou: FileElement){
    // fisiernou.id = v4();
    // console.log(" FISIER DIN SERVICE ==>> ", fisiernou)
    return (this.http.post<any>(this.server_url+"returnfileid/", fisiernou, this.httpOptions))
  }
  addFileToServer(fisiernou: FileElement){
    // fisiernou.id = v4();
    console.log(" FISIER DIN SERVICE ==>> ", fisiernou)
    return (this.http.post<any>(this.server_url, fisiernou, this.httpOptions))
  }
  updateFileToServer(fisier){
    return (this.http.put<any>(this.server_url+fisier.id, fisier))
  }
  deleteFromServer(id:string){
    return (this.http.delete<any>(this.server_url+id))
  }
  getPacientFilesFromServer(idPacient:number, parent:string){
    return (this.http.get<any>(this.server_url+idPacient+"?parent=" + parent))
  }
  getPacientFileFromServerByFisierID(idFisier:string){
    return (this.http.get<any>(this.server_url+"byfisierid/"+idFisier))
  }
  getFileDataFromServerByFisierID(idFisier:string){
    return (this.http.get<any>(this.server_url+"downloadbyfisierid/"+idFisier))
  }
  addfilepath(id,path){
    let intermediar = new FisiereIDsiPath(id= id, path = path)
    this.filespaths.push(intermediar)
    // if (this.filespaths.length == this.fileindex - 1){
      // console.log("Toate Fisierele au path == >> ", this.filespaths)
    // }
    // console.log("FilePaths from File Service == >> ", this.filespaths)
    // console.log("Path de I ==>> ", this.filespaths.length)
  }
  returnFile(id){
    return this.filespaths.find ( obj => {
      return obj.id === id
    }).path
  }

  add(fileElement: FileElement) {
    fileElement.id = v4();
    this.map.set(fileElement.id, this.clone(fileElement));
    this.files.push(fileElement);
    if (this.localstorageflag){
      this.localStorageService.remove("Files")
      this.addtolocalStorage(this.files);
    }
    else{
      this.addtolocalStorage(this.files);
    }
    return fileElement;
  }

  addtolocalStorage(Files){
    console.log("addtolocalstorage Files: =>", Files);
    console.log("Addtolocalstorage",this.localStorageService.set("Files", Files));
  }

  delete(id: string) {
    this.map.delete(id);
  }

  // update(id: string, update: Partial<FileElement>) {
  //   let element = this.map.get(id);
  //   element = Object.assign(element, update);
  //   this.map.set(element.id, element);
  // }

  update(fileid: string, targetname: string){
    // this.files[fileid].parent = targetname;
    this.files.forEach(file=>{
      if(file.id === fileid){
        file.parent = targetname;
      }
    });
    // console.log("files after update: =>", this.files);
    if (this.localstorageflag){
      this.localStorageService.remove("Files");
      this.addtolocalStorage(this.files);
    }
    else {
      this.addtolocalStorage(this.files);
    }
  }
  updatestatus(fileid: string, status: string){
    // this.files[fileid].parent = targetname;
    this.files.forEach(file=>{
      if(file.id === fileid){
        file.status = status;
      }
    });
    // console.log("files after update: =>", this.files);
    if (this.localstorageflag){
      this.localStorageService.remove("Files");
      this.addtolocalStorage(this.files);
    }
    else {
      this.addtolocalStorage(this.files);
    }
  }

  private querySubject: BehaviorSubject<FileElement[]>;

  queryInFolder(folderId: string) {
    const result: FileElement[] = [];
    console.log("querry in folder folderID: =>", this.map);
    this.map.forEach(element => {
      console.log("querry in folder element: =>", element);
      if (element.parent === folderId) {
        result.push(this.clone(element));
      }
    });
    if (!this.querySubject) {
      this.querySubject = new BehaviorSubject(result);
    } else {
      this.querySubject.next(result);
    }
    return this.querySubject.asObservable();
  }

  getfilesfromfolder(foldername: string){
    let folderfiles: FileElement[] = []
    this.files.forEach(file=>{
      if(file.parent === foldername){
        folderfiles.push(file);
        // console.log ("user file is: => ", file);
      }
    });
    console.log("folderFiles: => ", folderfiles);
  }

  get(id: string) {
    return this.map.get(id);
  }

  getuserfiles(userID: number, parent: string){
    let userfiles: FileElement[] = []
    this.files.forEach(file=>{
      if(file.idPacient === userID && file.parent === parent){
        userfiles.push(file);
        // console.log ("user file is: => ", file);
      }
    });
    // if (userfiles.length === 0){
    //   console.log("User has no files");
    // }
    return userfiles;
  }
  getalluserfiles(userID: number){
    let userfiles: FileElement[] = []
    this.files.forEach(file=>{
      if(file.idPacient === userID){
        userfiles.push(file);
        // console.log ("user file is: => ", file);
      }
    });
    // if (userfiles.length === 0){
    //   console.log("User has no files");
    // }
    return userfiles;
  }

  clone(element: FileElement) {
    return JSON.parse(JSON.stringify(element));
  }
}
