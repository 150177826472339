import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { logo_url_base64 } from 'src/app/models/logo-base64.model';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { DatePipe } from '@angular/common';
import { FileService } from 'src/app/services/file.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pacient, SemnaturaRLCreate } from 'src/app/models/pacientdetails.model';
import { ContractMasinaInterface } from 'src/app/interfaces/documente.interface';
import { ContractMasina } from 'src/app/models/documente.models';
import { EvalserviceService } from 'src/app/services/evalservice.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { PdfpreviewerComponent } from 'src/app/pdfpreviewer/pdfpreviewer.component';
import { FileElementCreate } from 'src/app/file-manager/model/element';
import { FileElementCls } from 'src/app/models/fisier.model';
import { ContracteService } from 'src/app/services/contracte.service';
import { ConsultCreateInterface } from 'src/app/interfaces/consult.interface';
import { ConsultCreate } from 'src/app/models/consult.model';
import { SemnaturaRLCreateInterface } from 'src/app/interfaces/pacient.interface';
import { SemnaturiRLService } from 'src/app/services/semnaturirl.service';
import { SemnaturiTerapeutiFilesService } from 'src/app/services/semnaturiterapeutifiles.service';
import { PinentryComponent } from 'src/app/pinentry/pinentry.component';

export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
};
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-contract-masina',
  templateUrl: './contract-masina.component.html',
  styleUrls: ['./contract-masina.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class ContractMasinaComponent implements OnInit {
  @Output () fileid = new EventEmitter<string>();
  pacient = new Pacient();
  caz : number = 1
  idFisier : any = null
  intermediar_data = new Date()
  semnatura_RL : any = null
  semnatura_Terapeut : any = null
  contract_masina: ContractMasinaInterface = new ContractMasina()

  constructor(
    public evaluarefieldsService: EvalserviceService ,
    public fileService: FileService,
    public dialog: MatDialog,
    public contracteService: ContracteService,
    public semnaturirlService: SemnaturiRLService,
    public semnaturiTerapeutFilesService: SemnaturiTerapeutiFilesService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<ContractMasinaComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
      if (data){
        if (data.caz == 1){
          this.pacient = data.beneficiar
          this.caz = 1
          this.getInitialFieldsFromServer()
        }
      }
     }
     initDataCazEvaluareNoua(beneficiar){
      console.log("Init Data Caz Evaluare Noua ==>> ", beneficiar)
      if (beneficiar.date_personale.nume){
        this.contract_masina.nume_beneficiar = beneficiar.date_personale.nume
      }
      if (beneficiar.date_personale.prenume){
        this.contract_masina.prenume_beneficiar = beneficiar.date_personale.prenume
      }
      if (beneficiar.date_personale.cnp){
        this.contract_masina.cnp_beneficiar = beneficiar.date_personale.cnp
      }
      if (beneficiar.date_personale.numar_carte_identitate){
        this.contract_masina.numar_ci_beneficiar = beneficiar.date_personale.numar_carte_identitate
      }
      if (beneficiar.date_personale.serie_carte_identitate){
        this.contract_masina.serie_ci_beneficiar = beneficiar.date_personale.serie_carte_identitate
      }
      // intermediar_data = new Date()
      this.contract_masina.data_contract = new Date().toString()
     }
     getInitialFieldsFromServer(){
      this.evaluarefieldsService.getDocFieldsFromServerByID(34)
      .subscribe ( (data) => {
        if (data){
          console.log("DATA FROM EVALUAREA FUNCTIEI MOTORII GROSIERE -->> Initial Props ==>> ", data)
          // this.originalData = data
          this.contract_masina = data.props
          this.initDataCazEvaluareNoua(this.pacient)
        }
      })
     }

  ngOnInit(): void {

  //     FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI
  // let intermediar:ContractMasinaInterface = new ContractMasina()
  // let testone = {
  //   "denumire" : "Contract Masina",
  //   "props" : intermediar
  // }

  // this.evaluarefieldsService.addToServer(testone )
  // .subscribe( (data) =>{
  //   if (data){
  //     console.log("Data from add sectiunea A to server ==>> ", data)
  //   }
  //   else{
  //     console.log("NO !!! Data from add sectiunea A to server ==>> ")
  //   }
  // })

  // END FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI
  }
  semneazaRL(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_RL = res
        console.log("Semnatura RL IMAGINE ESTE ==>> ",this.semnatura_RL)
      }
    })
  }
  semneazaTerapeut(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_Terapeut = res
        console.log("Semnatura Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
      }
    })
  }
  openPDFPreviewSsaveModificari(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      // console.log("data din pdfgenerator base 64 ==>> ",data);
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
      dialogRef.afterClosed().subscribe( async res => {
        if (res){
          let update_file  = {
            'id' : this.idFisier,
            'dateadden' : this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm'),
            'path' : "data:application/pdf;base64,"+data,
            'name' : "Contract Masina -  "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
          }
          this.fileService.updateFileToServer(update_file)
          .subscribe ( (data) => {
            // console.log ( "DATA FROM UPDATE FILE FROM SERVER ==> ", data)
          })
          this.contracteService.updateContractToServerByFisierID(this.idFisier,{'dataJSON' : this.returnObjectForAddConsult()})
          .subscribe( (data) => {
            // console.log ("DATA FROM ADD CONSULT TO SERVICE ==>> ", data)
          })
          this.dialogRef.close(1)
        }
      })
    })
  }
  openPDFPreviewComponent(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      // console.log("data din pdfgenerator base 64 ==>> ",data);
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
      dialogRef.afterClosed().subscribe( async res => {
        if (res){
          let fisier_to_upload : FileElementCreate = new FileElementCls()
          fisier_to_upload.id = this.fileService.generateFileID();
          fisier_to_upload.idPacient = this.pacient.id,
          fisier_to_upload.isFolder = false
          fisier_to_upload.parent = 'root'
          fisier_to_upload.dateadden = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
          fisier_to_upload.type = "application/pdf"
          fisier_to_upload.extension = "pdf"
          fisier_to_upload.path = "data:application/pdf;base64,"+data
          fisier_to_upload.status = 'neverificat';
          fisier_to_upload.name = " Contract Masina - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm');
          let intermediar_from_server = await this.fileService.addFileToServerReturnFileID(fisier_to_upload).toPromise()
          this.addContractToServer(intermediar_from_server)
          this.dialogRef.close(0)
        }
      })

    })
  }
  returnObjectForAddConsult(){
    let intermediar = {
      "dataJSON" : this.contract_masina
    }
    return intermediar
  }

  addContractToServer(idFisier){
    let intermediar_consult : ConsultCreateInterface = new ConsultCreate()
    intermediar_consult.idPacient = this.pacient.id
    intermediar_consult.idFisier = idFisier
    intermediar_consult.dataAdaugare = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
    intermediar_consult.idEvaluare = 36
    intermediar_consult.denumire = "Chestionar Alergii"
    intermediar_consult.dataJSON = this.returnObjectForAddConsult()
    this.contracteService.addConstractToServer(intermediar_consult)
    .subscribe( (data) => {
      if (data){
        console.log("DATA DIN ADD CONSULT TO SERVER ==>> ", data)
      }
      let semnatura_noua_RL : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
      semnatura_noua_RL.idFisier = idFisier
      semnatura_noua_RL.semnatura = this.semnatura_RL
      this.semnaturirlService.addSemnaturaRLToServer(semnatura_noua_RL)
      .subscribe ( (data) => {
        if (data){
          console.log ("DATA DIN ADD SEMNATURA RL TO SERVER ==>> ", data)
        }
      })
      let semnatura_noua_TerapeutFiles : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
      semnatura_noua_TerapeutFiles.idFisier = idFisier
      semnatura_noua_TerapeutFiles.semnatura = this.semnatura_Terapeut
      this.semnaturiTerapeutFilesService.addSemnaturaTerapeutFilesToServer(semnatura_noua_TerapeutFiles)
      .subscribe ( (data) => {
        if (data){
          console.log ("DATA DIN ADD SEMNATURA TERAPEUT FILES TO SERVER ==>> ", data)
        }
      })
    })
  }
  returnDashIfEmpty(variabila){
    if (variabila === ""){
      return "-"
    }
    return variabila
  }
  returnDashIfUndefined(variabila){
    if (variabila === undefined){
      return "-"
    }
    return variabila
  }
  generatePDF() {
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
      height: 60,
      columns:
        [
          // {
          //   image: logo_url_base64,
          //   width: 50,
          //   alignment: 'center',
          // },
          {
            stack: [
              "Fundația Mihai Neșu Foundation",
              "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
            ],
            margin: 10,
            style: 'textheader'
          }
        ],
      margin: [0, 0, 0, 90]
    },
    content: [
      {
        canvas: [
          {
            type: 'line',
            x1: -100, y1: 0,
            x2: 600, y2: 0,
            lineWidth: 1,
            lineCap: 'square'
          },
          {
            type: 'line',
            x1: -100, y1: 4,
            x2: 600, y2: 4,
            lineWidth: 1,
            lineCap: 'square'
          }
        ]
      },
      {
        image: logo_url_base64,
        width: 200,
        alignment: 'center',
      },
      {
        stack: [
          "CONTRACT",
          "Nr. " +  this.returnDashIfUndefined( this.contract_masina.nr_contract ) + " din " + this.datePipe.transform(this.contract_masina.data_contract,'d.MM.yyyy'),
          "Contact sofer: 0786.733.344",
          "Contact beneficiar: " + this.contract_masina.contact_beneficiar
        ],
        bold: true,
        alignment: 'center',
        fontSize: 16,
        margin: [0, 50, 0, 80]
      },
      {
        text: "I. Părțile contractului:",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "Art.1.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "1. Fundația Mihai Neșu Foundation cu sediul social în Oradea, str. Emil Palade, nr.55, jud. Bihor, având Cod Fiscal 32896090, Cont Iban: RO63BTRLRONCRT0246793101 deschis la Banca Transilvania, sucursala Oradea și Cont Euro: RO13BTRLEURCRT0246793101, reprezentată prin director executiv Maria- Iulia Băcăran ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "2. "+ this.contract_masina.nume_beneficiar + " " + this.contract_masina.prenume_beneficiar + " identificat cu CNP:" + this.returnDashIfUndefined(this.contract_masina.cnp_beneficiar) +", seria CI:"+this.returnDashIfUndefined(this.contract_masina.serie_ci_beneficiar) + " și nr CI: "+this.returnDashIfUndefined(this.contract_masina.numar_ci_beneficiar) +  " cu domiciliul in: " + this.returnDashIfUndefined(this.contract_masina.domiciliu) + "reprezentat prin insotitor: " +
        this.returnDashIfUndefined(this.contract_masina.insotitor_beneficiar) + " identificat/ă cu seria: " + this.returnDashIfUndefined(this.contract_masina.serie_ci_insotitor) + " si nr: " + this.returnDashIfUndefined(this.contract_masina.numar_ci_insotitor),
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "II. Obiectul contractului:",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "Art.2.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Obiectul contractului îl reprezintă dreptul copilului/adultului cu dizabilități neuromotorii de a fi transportat cu Mașina Fundației Mihai Neșu în Oradea și pe o rază de 15 km de jur împrejurul Oradei.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "III. Termenul contractului:",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "Art.3.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Contractul se încheie pe o perioadă nedeterminată.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "IV. Obligațiile părților:",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "Art.4.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Obligațiile Fundației Mihai Neșu Foundation:",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "1.  de a asigura fiecărei persoane cu dizabilități, care este încadrată ca beneficiar al Fundației Mihai Neșu posibilitatea de a fi transportată cu mașina adaptată a Fundației Mihai Neșu în condiții de siguranță și de coborî/urca persoana de/ la.etaj la preluare sau la finalizarea cursei cu liftul electric special al Fundației Mihai Neșu. Transportul se face doar la solicitarea expresă a beneficiarului sau a însoțitorului acestuia și cu o programare cu 24 h înainte de ziua transportului. Programările se fac telefonic, în intervalul orar 13.00-14.00. ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "2.   de a controla bunul mers al serviciului de transport",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "3. de a coopera cu beneficiarii în vederea soluționării oricăror nemulțumiri, neregularități, probleme ivite pe parcursul derulării prezentului contract.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "4. de a oferi chitanță pentru fiecare donație făcută de beneficiari pentru serviciul de transport adaptat .",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "5. de a fi punctuali la programarea stabilită.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "6. de a anunța telefonic beneficiarul în momentul în care a ajuns la locație, precum și de a-l anunța că trebuie să plece dupa 5 minute dacă acesta nu a sosit. Șoferul are obligația de a aștepta beneficiarul mai mult de 5 minute de la ora stabilită doar dacă beneficiarul anunță întârzierea, specifică timpul cât va întârzia și șoferul nu are programată o altă cursă după, la care ar putea întârzia. În cazul în care șoferul are o altă cursă și nu mai poate aștepta, are obligața de a comunica beneficiarului dacă are o fereastră în care l-ar putea lua mai târziu, în caz contrar beneficiarul poate reprograma cursa pentru o altă zi tot cu 24 h înainte.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7. Șoferul are obligația de a nu vorbi la telefon în timp ce conduce, timpul rezervat programărilor și conversației cu beneficiarii are loc doar când mașina este în repaus . ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "8. Șoferul are obligația de a nu transporta nicio persoană care nu a fost în prealabil înscrisă ca beneficiar al Fundației Mihai Neșu sau care nu este însoțitor al persoanei cu dizabilități neuromotorii , angajat al fundației sau voluntar cu contract.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Art.5.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Obligațiile părinților:",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "1. de a pune la dispoziția Fundației Mihai Neșu  toate documentele solicitate în vederea înscrierii precum și cele care vor fi solicitate pe parcursul derulării contractului de către diferitele instituții ale statului, pentru buna desfășurare a programului. Pentru înscriere documentele necesare în copie sunt : certificat de handicap, buletin/certificat de naștere persoană cu dizabilități, buletin însoțitor persoană cu dizabilități . Beneficiarul va răspunde la toate întrebările șoferului, necesare pentru desfășurarea cursei în condiții normale (nume, locația preluării, ora preluării, tipul de scaun, confirmarea înscrierii, locația la care dorește să ajungă, numele însoțitorului) ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "2.  de respecta regulamentul de ordine interioară al Fundația Mihai Neșu Foundation prevăzut în acest contract.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "3.  de a folosi un vocabular moral, decent pe perioada cât se află în cadrul serviciului de transport și în toate conversațiile și interacțiunile pe care le are cu angajații Fundației Mihai Neșu sau cu alți beneficiari ai serviciului. Obligația de a se abține în a folosi un vocabular agresiv, cuvinte obscene, injurii sau de a avea un comportament violent, sexual.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "4. de a fi punctual și a respecta ora întâlnirii stabilită cu șoferul.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "5. de a anunța orice întârziere și de a solicita așteptarea din partea șoferului, având în vedere că șoferul poate aștepta mai mult de 5 minute doar dacă nu trebuie să ajungă la cursa următoare sau dacă întârzierea nu afectează cursa următoare.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "6. de a anunța întotdeauna numele însoțitorului, care va avea întotdeauna un comportament adecvat, fără cuvinte obscene sau acte de agresiune față de șofer sau orice altă persoană în proximitatea serviciului de transport și pe durata efectuării acestuia",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7. de a accepta toate măsurile de siguranță impuse de șofer (centuri de siguranță) și de a discuta cu acesta orice neregularitate sau nemulțumire tehnică , de situație sau comportamentala în vederea soluționării ei.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "8. de a accepta faptul că în mașină se pot afla și alți beneficiari simultan pentru care mașina se va opri ca să îi lase la destinația cerută, fără ca acest lucru să întârzie cursa lor. Pentru îndeplinirea acestei condiții beneficiarul trebuie să îi comunice din timp șoferului dacă are vreo programare fixă, o oră anume la care trebuie să ajungă la destinație",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.  de a comunica tipul scaunului și greutatea acestuia, având în vedere că liftul electric transportă până la 130 kg pe scări și că liftul electric nu poate fi folosit pentru scări în spirală",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "10. de a nu apăsa pe niciunul din butonale mașinii, de a nu porni/opri mașina fundației Mihai Nesu și de a cere voie înainte dacă este nevoie ca persoana să transporte un bagaj, având în vedere că mașina are mai multe locuri și este posibil să fie și alte persoane în mașină .",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "11. însoțitorul are obligația de a supraveghea copilul/persoana cu dizabilități pe perioada cât se află în deplasare cu Masina Fundatiei Mihai Neșu, de a-l susține, de a-l însoți pe rampa de deplasare, înțelegând că sarcina șoferului se încheie după ce a lăsat persoana la locația dorită",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "12.  beneficiarul trebuie să aibă o atitudine înțelegătoare cu privință la locurile de parcare, astfel că șoferul trebuie să parcheze în locurile prevăzute de lege.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "13. de a nu intra în discuții care să instige la violență verbală și comportamentală cu ceilalți utilizatori ai mașinii.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "14. de a aduce la cunoștința conducerii orice neregularitate, nemulțumire  apărută pe parcursul derulării contractului, indiferent de natura acesteia, dacă situația nu a putut fi remediată cu șoferul Fundației Mihai Neșu.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "15.  de a anunța în timp util șoferul Fundației despre ivirea oricărui eveniment care ar putea pune beneficiarul în imposibilitate de a se prezenta la cursa stabilită.   ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "16. Daca datorită unor motive temeinice beneficiarul nu mai poate onora cursa programată acesta are obligația de a anunța șoferul de îndată ce află că nu se mai poate prezenta la destinație. Această situație nu va duce la anularea contractului dacă se întâmplă de maxim 3 ori într-o lună. La mai mult de 3 situații similare de anulare a cursei într-o lună calendaristică, Fundația Mihai Neșu își rezervă dreptul de a scoate persoana din lista beneficiarilor oferind acest drept unor persoane care au nevoie de acest serviciu și înțeleg necesitatea unei bune organizări astfel ca fundația să poată deservi persoanele care au nevoie de serviciu și să nu piardă programări.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "17. Beneficiarul va lua la cunoștință faptul că valoarea unei curse cu transport adaptat depășește suma de 10 lei, sumă în care este inclus salariul șoferului, combustibilul și taxele aferente funcționării serviciului, ajungând uneori chiar și la suma de 40 lei însă beneficiarul va contribui pentru fiecare cursă cu o donație de 10 lei. În această sumă este inclus și transportul însoțitorului persoanei cu dizabilități.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "18. Fundația Mihai Neșu își asumă răspuderea de a păstra funcționarea serviciului cu solicitarea acestei donații de 10 lei /cursă din partea beneficiarului până în luna noiembrie a anului 2019, perioadă în care serviciul beneficiază și de susținere din partea Fundației Vodafone România. După această perioadă serviciul ar trebui să devină sustenabil. În cazul în care nu sunt suficiente curse lunare, Fundația Mihai Neșu poate schimba suma contribuției/cursă pentru a putea menține serviciul .",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "19. Contribuția beneficiarului de 10 lei/cursă va fi achitată exclusiv în numerar șoferului, înainte de a începe cursa. ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "20. În cazul în care un beneficiar al Fundației Mihai Neșu Foundation beneficiază de sponsorizare pentru achitarea curselor din partea unei terțe părți, Fundația Mihai Neșu acceptă sponsorizarea pe baza unui contract de sponsorizare între Fundația Mihai Neșu și sponsor, în care este specificat clar numele beneficiarului și perioada de timp în care poate beneficia de aceasta. Termenul de plată în cazul sponsorizării trebuie să fie de maxim 30 de zile de la încheierea contractului. ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "21. obligația beneficiarilor de a cere acordul scris al conducerii Fundației Mihai Neșu Foundation pentru orice menționare publică a numelui fundației precum și obligația de a nu contribui negativ la imaginea fundației prin postări sau cuvinte care să o denigreze.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "22.obligația beneficiarilor de a cere acordul scris al oricărui angajat din cadrul Fundaței Mihai Neșu pentru a-l fotografia sau filma, indiferent cu ce scop se dorește acest lucru, respectând astfel art. 74 lit. c) din Noul Cod Civil.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "V. Drepturile părților:",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "Art.6.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Drepturile  Fundației Mihai Neșu:",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "1. dreptul de a verifica îndeplinirea obligațiilor de către beneficiarii și însoțitorii persoanelor  cu dizabilități înscriși la Centrul de Recuperare.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "2. dreptul de a modifica contributia curselor în funcție de evoluția sponzorizărilor, modificare care va fi adusă la cunoștința beneficiarilor cu cel puțin 30 de zile înainte de a avea loc. ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "3. dreptul de a denunța unilateral contractul în cazul neîndeplinirii obligațiilor prevăzute în contract de către părinți.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "4. dreptul de a solicita daune interese în cazul săvârșirii de către beneficiari sau însoțitorii acestora înscriși în Cadrul Fundației Mihai Neșu a unor fapte prin care s-ar aduce atingere gravă imaginii Fundației Mihai Neșu sau a oricăror fapte prin care s-ar produce daune materiale fundației.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Art.7.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Drepturile  beneficiarilor:",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "1. dreptul de a specifica ora și locația la care doresc să fie luați/duși cu un termen de 24 h înainte. Pe perioada concediilor angajaților, nu se vor face programări. ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "2. dreptul de a beneficia de curse cu mașina adaptată a Fundației Mihai Neșu pe baza acestui contract și a unei programări stabilite împreună cu șoferul fundației conducerii Fundației, daca beneficiarul se încadrează din punct de vedere medical în categoria pentru care a fost creat serviciul ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "3.  dreptul de a denunța unilateral contractul în cazul neîndeplinirii obligațiilor de către Fundație pe baza unei notificări adresate cu cel putin 15 zile înainte.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "VI. Declarații :",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "Art.8.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Conducerea Fundației Mihai Neșu declară că își asumă responsabilitatea strict pentru modul de desfășurare a serviciului, neavând angajat un medic specialist în caz de urgențe. Orice eveniment medical intervenit cum ar fi de exemplu crizele de epilepsie vor fi anunțate la numărul de urgențe 112.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Art.9.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Beneficiarii Fundației Mihai Neșu declară că înțeleg că accesul la mașina adaptată este un serviciu caritabil, care are nevoie de promovare și susținere financiară din exterior și sunt de acord cu publicarea pe facebook, pe siteul Fundației, pe newsletterul (buletin informativ) Fundației precum și la televiziune/media a pozelor, filmulețelor care promovează acest serviciu, în aceste poze/filme apărând și ei în calitate de beneficiari . Acestea vor fi publicate sau/și televizate strict pentru a susține evenimentele de strângere de fonduri sau pentru transparenta activitatilor pentru terte parti (sponsori, donator, investitori, parteneri ai Fundatiei Mihai Nesu). Imaginile, filmele video pot fi folosite si de terte parti cu acordul Fundatiei Mihai Nesu, cu scopul de a promova activitatea caritabila a Fundatiei Mihai Nesu. ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "VII. Modificarea contractului:",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "Art.10.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: " Orice modificare a clauzelor prezentului contract se va face cu acordul părţilor printr-un act adiţional la contract.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "VIII. Litigii: ",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "Art.11.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Litigiile izvorâte din interpretarea sau executarea prezentului contract vor fi soluţionate, pe cât posibil pe cale amiabilă, în caz contrar de către instanţele judecătoreşti de la sediul Fundației Mihai Neșu.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "IX. Protecția datelor: ",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "Art.12.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Părțile își asumă obligația de a păstra confidențialitatea datelor din prezentul contract.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "X. Alte clauze: ",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "Art.13.",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Rolul Fundației Mihai Neșu este de a susține copiii și adulții cu dizabilități neuromotorii să aibă o viață normală și  sănătoasă prin înființarea de servicii sociale de recuperare medical, de recreere sau servicii adaptate care să le faciliteze accesul în instituții și libertate de mișcare. ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Prezentul contract a fost încheiat azi data de " + this.contract_masina.incheiat_de + " la sediul Fundației Mihai Neșu din Strada Dimitrie Cantemir, nr 39,  în 2 exemplare originale, unul pentru Fundația Mihai Neșu Foundation, unul pentru beneficiarul serviciului Transport adaptat pentru persoane cu dizabilități.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Subsemnatul/ Subsemnata" + this.contract_masina.insotitor_beneficiar + ", în calitate de însoțitor al copilului/adultului " + this.contract_masina.nume_beneficiar + " " + this.contract_masina.prenume_beneficiar + " declarăm că am luat cunoștință, am înțeles și suntem de acord cu toți termenii contractuali și că nerespectarea clauzelor contractului poate duce la suspendarea lui.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
    ],styles: {
      textheader: {
        alignment: 'center',
        bold: true,
        fontSize: 14,
        // lineHeight: 2
      }
    }
    }
    return docDefinition
  }
}
