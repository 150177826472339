<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> CHESTIONAR DE ALERGII</h2></span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  Nume:
</span>


<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> 1.	Are copilul dvs istoric de alergii?     &nbsp;
            <mat-checkbox [checked]="chestionar_alergii.istoric_de_alergii" (change)="chestionar_alergii.istoric_de_alergii = !chestionar_alergii.istoric_de_alergii"> Da  </mat-checkbox>
            &nbsp;
            <mat-checkbox [checked]="!chestionar_alergii.istoric_de_alergii" (change)="chestionar_alergii.istoric_de_alergii = !chestionar_alergii.istoric_de_alergii"> Nu  </mat-checkbox>
</span>

<mat-form-field style="width: 100%;" >
    <mat-label>Dacă da, vă rugăm să specificați </mat-label>
    <textarea matInput [(ngModel)]="chestionar_alergii.istoric_de_alergii_detalii"></textarea>
</mat-form-field>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> 2. Are copilul dvs astm, febra fânului, eczemă, sau erupții cutanate?   &nbsp;
            <mat-checkbox [checked]="chestionar_alergii.astm_febra_fanului" (change)="chestionar_alergii.astm_febra_fanului = !chestionar_alergii.astm_febra_fanului"> Da  </mat-checkbox>
            &nbsp;
            <mat-checkbox [checked]="!chestionar_alergii.astm_febra_fanului" (change)="chestionar_alergii.astm_febra_fanului = !chestionar_alergii.astm_febra_fanului"> Nu  </mat-checkbox>
</span>

<mat-form-field style="width: 100%;" >
    <mat-label>Dacă da, vă rugăm să specificați </mat-label>
    <textarea matInput [(ngModel)]="chestionar_alergii.astm_febra_fanului_detalii" ></textarea>
</mat-form-field>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> 5.  Are copilul dvs sensibilitate la alimente? &nbsp;
            <mat-checkbox [checked]="chestionar_alergii.sensibilitate_la_alimente" (change)="chestionar_alergii.sensibilitate_la_alimente = !chestionar_alergii.sensibilitate_la_alimente"> Da  </mat-checkbox>
            &nbsp;
            <mat-checkbox [checked]="!chestionar_alergii.sensibilitate_la_alimente" (change)="chestionar_alergii.sensibilitate_la_alimente = !chestionar_alergii.sensibilitate_la_alimente"> Nu  </mat-checkbox>
</span>

<mat-form-field style="width: 100%;" >
    <mat-label>Dacă da, vă rugăm să specificați </mat-label>
    <textarea matInput [(ngModel)]="chestionar_alergii.sensibilitate_la_alimente_detalii"></textarea>
</mat-form-field>


<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  6. A avut copilul dvs. vreodată prurit, urticarie, umflături sau simptome ca nas înfundat, respirație șuierătoare, iritația ochilor sau dificultăți respiratorii după:
</span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> &nbsp;&nbsp;&nbsp;&nbsp;a. examen și proceduri dentare
&nbsp;
            <mat-checkbox [checked]="chestionar_alergii.intrebarea_6.proceduri_dentare" (change)="chestionar_alergii.intrebarea_6.proceduri_dentare = !chestionar_alergii.intrebarea_6.proceduri_dentare"> Da  </mat-checkbox>
            &nbsp;
            <mat-checkbox [checked]="!chestionar_alergii.intrebarea_6.proceduri_dentare" (change)="chestionar_alergii.intrebarea_6.proceduri_dentare = !chestionar_alergii.intrebarea_6.proceduri_dentare"> Nu  </mat-checkbox>
</span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">&nbsp;&nbsp;&nbsp;&nbsp; b. contactul cu baloane
&nbsp;
            <mat-checkbox [checked]="chestionar_alergii.intrebarea_6.contactul_cu_baloane" (change)="chestionar_alergii.intrebarea_6.contactul_cu_baloane = !chestionar_alergii.intrebarea_6.contactul_cu_baloane"> Da  </mat-checkbox>
            &nbsp;
            <mat-checkbox [checked]="!chestionar_alergii.intrebarea_6.contactul_cu_baloane" (change)="chestionar_alergii.intrebarea_6.contactul_cu_baloane = !chestionar_alergii.intrebarea_6.contactul_cu_baloane"> Nu  </mat-checkbox>
</span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> &nbsp;&nbsp;&nbsp;&nbsp;c. o examinare de către cineva care purta
&nbsp;
            <mat-checkbox [checked]="chestionar_alergii.intrebarea_6.examinare_cu_manusi" (change)="chestionar_alergii.intrebarea_6.examinare_cu_manusi = !chestionar_alergii.intrebarea_6.examinare_cu_manusi"> Da  </mat-checkbox>
            &nbsp;
            <mat-checkbox [checked]="!chestionar_alergii.intrebarea_6.examinare_cu_manusi" (change)="chestionar_alergii.intrebarea_6.examinare_cu_manusi = !chestionar_alergii.intrebarea_6.examinare_cu_manusi"> Nu  </mat-checkbox>
</span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> 7.  Ați fost vreodată informată că are copilul dvs alergie la latex sau la cauciuc?
&nbsp;
            <mat-checkbox [checked]="chestionar_alergii.alergie_la_cauciuc" (change)="chestionar_alergii.alergie_la_cauciuc = !chestionar_alergii.alergie_la_cauciuc"> Da  </mat-checkbox>
            &nbsp;
            <mat-checkbox [checked]="!chestionar_alergii.alergie_la_cauciuc" (change)="chestionar_alergii.alergie_la_cauciuc = !chestionar_alergii.alergie_la_cauciuc"> Nu  </mat-checkbox>
</span>

<ng-container *ngIf="caz == 1">
  <button mat-raised-button (click)="semneazaRL()" > Semnatura Reprezentant Legal</button>
  <button mat-raised-button (click)="semneazaTerapeut()"  > Semnatura Terapeut</button>
  <button mat-raised-button (click)="openPDFPreviewComponent()" > Previzualizeaza </button>
</ng-container>
<ng-container *ngIf="caz == 2">
  <button mat-raised-button (click)="openPDFPreviewSsaveModificari()"  > Genereaza Document </button>
  <button mat-raised-button color="warn"  mat-dialog-close > Inchide </button>
</ng-container>
