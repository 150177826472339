import { Pacient, DateContact, DatePersonale, Adresa } from '../models/pacientdetails.model';
import { Injectable, OnInit } from '@angular/core';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError} from 'rxjs/operators/catchError';
import { LocalStorageService } from './local-storage.service';
import { CalendarDateFormatter,
  CalendarEvent,
  CalendarView,
  DAYS_OF_WEEK } from 'angular-calendar';
  import {
    startOfDay,
    endOfDay,
    subDays,
    addDays,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours,
  } from 'date-fns';
  import { serverurl } from './server.service';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
  providedIn: 'root'
})
export class ProgramariService{
  public programari: CalendarEvent[] = []
  // private server_url="api/programari"
  private server_url=serverurl+"/api/v1/programare/";

  public localstorageflag = 0;
  localStorageChanges$ = this.localStorageService.changes$;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    let data = this.localStorageService.get("ProgramariList");
    // console.log("On Initi DAta Local storage => ",data)

    if (data)
    {
      this.localstorageflag = 1;
      this.programari = data;
      // this.programari.forEach(element=> {
      //   this.http.post<CalendarEvent>(this.server_url, element, cudOptions).pipe(
      //     catchError(this.handleError)
      //       )
      //     });
      }
    else {
      // console.log("PacientList nu este Local")
    }
  }

  getProgramariFromServer(){
    return this.http.get(this.server_url)
  }

  getProgramariFromServerByMedicId(medicid: number){
    return this.http.get(this.server_url+"medic/"+medicid)
  }
  getProgramariFromServerByMedicIdInIntervalWeek(medicid:number, start: any, end: any){
    return this.http.get(this.server_url+"medicintervalweek/"+medicid+"/"+start+"/"+end)
  }

  getProgramari():CalendarEvent[] {
    return this.programari;
  }

  addProgramareToServer(programare_noua){
    return (this.http.post<any>(this.server_url,programare_noua,this.httpOptions))
  }

  getProgramaribyId(id:number):CalendarEvent {
    for (var i of this.programari){
      if(i.id === id)
      {
        return i
      }
    }
    return undefined;
  }
  getProgramaribyRecId(id:number):number[] {
    var x: number[] = []
    for (var i of this.programari){
      if(i.recurent)
      {
        if (i.recurenta_id === id)
        {
          x.push(i.id)
        }
      }
    }
    return x;
  }
  getProgramaribyUserId(id:number):CalendarEvent[] {
    var x: CalendarEvent[] = []
    for (var i of this.programari){
      if(i.pacientid === id)
      {
        x.push(i)
      }
    }
    return x;
  }

  getProgramaribyMedicId(id:number):CalendarEvent[] {
    var x: CalendarEvent[] = []
    for (var i of this.programari){
      if(i.medicid === id)
      {
        x.push(i)
      }
    }
    return x;
  }

  updateInfo(programareselectata:CalendarEvent){
    this.programari.forEach(element => {
      if (element.id == programareselectata.id){
        // console.log("UpdatePacientInfo / OldValue => ", element);
        element = programareselectata;
      }
    });
    // console.log("UpdatePacientInfo / NewValue => ", pacientselectat);
    if (this.localstorageflag){
      this.localStorageService.remove("ProgramariList");
      this.addtolocalStorage(this.programari);
    }
    else {
      this.addtolocalStorage(this.programari);
    }
  }


  addProgramare(programareNoua : CalendarEvent) {
    programareNoua.id = this.programari.length + 1;
    this.programari.push(programareNoua);
    if (this.localstorageflag){
      this.localStorageService.remove("ProgramariList");
      this.addtolocalStorage(this.programari);
    }
    else {
      this.addtolocalStorage(this.programari);
    }
    // console.log("Lista pacienti din pacienti service =>", this.programari);

    return this.http.post<Pacient>(this.server_url, programareNoua, cudOptions).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: Response){
    console.log("Error from Pacient Service: => " + error)
    return Observable.throw("500 internal server error" )
  }

  addtolocalStorage(PacientList){
    // console.log("addtolocalstorage PacientList: =>", PacientList);
    // console.log("Addtolocalstorage",this.localStorageService.set("ProgramariList", PacientList));
    this.localStorageService.set("ProgramariList", PacientList);
  }
}
