import { Component, ChangeDetectionStrategy, ViewChild, OnInit, ElementRef } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-istoric-programari',
  templateUrl: './istoric-programari.component.html',
  styleUrls: ['./istoric-programari.component.css']
})
export class IstoricProgramariComponent implements OnInit {

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

  }

  openpininput(){
    const dialogRef = this.dialog.open(CountdownComponent,{
      width: '450px',
      height: '350px'
    });
    dialogRef.afterClosed().subscribe( result => {})

  }

}
