import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TipuriDeServicii, Valabilitate, ValabilitateCreate } from 'src/app/models/nomenclatoare.model';
import { DatePipe } from '@angular/common'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import { MatInput } from '@angular/material/input';
import { IntervalService } from '../../services/interval.service'
import { TipuriDeServiciiCreate, TipuriDeServiciiFaraValabilitateCreate } from 'src/app/models/nomenclatoare.model';
import {CatserviciiService} from '../../services/categoriedeservicii.service';
import {MatSnackBar} from '@angular/material/snack-bar';

export const MY_FORMATS = {
  parse: {
    dateInput: 'd',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-categoriiserviciiedit',
  templateUrl: './categoriiserviciiedit.component.html',
  styleUrls: ['./categoriiserviciiedit.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class CategoriiserviciieditComponent implements OnInit {

  categorieserviciu = new TipuriDeServicii()
  start: Date
  end: Date
  constructor(private _snackBar: MatSnackBar,public catService: CatserviciiService,public intervalService: IntervalService,public datepipe: DatePipe,public dialogRef: MatDialogRef<CategoriiserviciieditComponent>, public dialg: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data:any ) {
      if(data){
          this.categorieserviciu = data;
          if (this.categorieserviciu.arevalabilitate){
            this.start = new Date( this.datepipe.transform(new Date(this.categorieserviciu.valabilitate.start),"MM.dd.YYYY") )
            this.end = new Date( this.datepipe.transform(new Date(this.categorieserviciu.valabilitate.end),"MM.dd.YYYY") )
          }
        }

        console.log("Categorie de servicii selectata ==>> ",this.categorieserviciu);
      }

  ngOnInit(): void {
  }

  valabilitatestatuschange(v){
    console.log("status v =>", v)
    this.categorieserviciu.arevalabilitate = v
  }

  startdateset(v) {
    let vd2 = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    let intermediar = this.datepipe.transform(new Date(vd2),"MM.dd.YYYY")
    this.start = new Date(intermediar)
  }

  enddateset(v) {
    let vd = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    let intermediar = this.datepipe.transform(new Date(vd),"MM.dd.YYYY")
    this.end = new Date(intermediar)
    return vd
  }
  @ViewChild('startDate',{
    read:MatInput
  }) startDate:MatInput;
  @ViewChild('endDate',{
    read:MatInput
  }) endDate:MatInput;
  updatevalues(){
    if(this.categorieserviciu.arevalabilitate){
      let interval = new ValabilitateCreate()
      // interval.id = this.categorieserviciu.valabilitateid
      interval.format_type = this.categorieserviciu.valabilitate.format_type
      interval.start = this.start.toString()
      interval.end = this.end.toString()
      this.intervalService.updateInterval(this.categorieserviciu.valabilitateid, interval)
      .subscribe( (data) => {
        if (data){
          console.log("Data from update Interval ==>> ", data)
        }
      })
    }
    let newCategorieServiciu = new TipuriDeServiciiCreate()
    for ( let key in newCategorieServiciu){
      newCategorieServiciu[key] = this.categorieserviciu[key]
    }
    console.log("New Categorie Servicii ==>> ", newCategorieServiciu)
    this.catService.updatecategoriiservicii(this.categorieserviciu.id, newCategorieServiciu)
    .subscribe( (data) =>{
      if(data){
        console.log("Data from update Categorie Serviciu ==>> ", data)
      }
    })
    this._snackBar.open("Categorie de servicii actualizata","Inchide")
  }
}
