import { Component, OnInit, ViewChild,AfterViewInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AddpacientComponent } from '../addpacient/addpacient.component';
import { PacientdetailsComponent } from '../pacientdetails/pacientdetails.component';
import { PacientiService } from '../services/pacienti.service';
import { Pacient, DateContact, DatePersonale, Adresa } from '../models/pacientdetails.model';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import { RowSelectEventArgs, QueryCellInfoEventArgs, GridComponent, SortService, FilterService, PageService,ToolbarService, EditService} from '@syncfusion/ej2-angular-grids';
import { PacientCreateInterface } from '../interfaces/pacient.interface';
import {  ReorderService, ResizeService } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-pacients',
  templateUrl: './pacients.component.html',
  providers: [FilterService, PageService,ToolbarService, EditService, SortService,ReorderService,ResizeService],
  styleUrls: ['./pacients.component.css']
})

export class PacientsComponent implements OnInit{
  @ViewChild('grid') public Grid: GridComponent;
  screenWidth: number;
  screenHeight: number;
  screnwidthstring: string
  dialogMaxWidth: string
  public pageSettings: object;

  constructor(
    public dialog: MatDialog,
    public pacientiService: PacientiService
    ) {

    this.screenWidth = window.innerWidth;

    if (this.screenWidth < 1200){
      this.screnwidthstring = this.dialogMaxWidth = this.screenWidth.toString()+'px'
    }
    else{
      if(this.screenWidth * 0.9 < 1200){
        this.dialogMaxWidth = "90%"
        this.screnwidthstring = "100%"

      }
      else{
        if(this.screenWidth * 0.9 > 1200) {
          this.dialogMaxWidth = "1200px"
          this.screnwidthstring = "100%"
        }
      }
    }

    this.screenHeight = window.innerHeight;
    // if (this.screenWidth * 0.8)
  }
  PacientList: Pacient[]
  PacientListtwo: Pacient[]
  dataSource: any
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    // console.log ( " screen width == >> ", this.screenWidth)
    // console.log ( " screen width  str == >> ", this.screnwidthstring)
    // console.log ( " screen Height == >> ", this.screenHeight)
    this.pacientiService.getPacientifromServer()
    .subscribe((data) => {
        this.PacientList = data
        this.PacientListtwo = this.pacientiService.orderPacientsListById(this.PacientList)
        this.load()
        this.dataSource  = new MatTableDataSource(this.PacientList);
        this.dataSource.paginator = this.paginator;
      })
      this.pageSettings = { pageSizes: true, pageSize: 5 };
    // console.log("Pacient List | Pacient Component | OnInit => ", this.PacientList);
    // .pipe(map((pacienti:Array<any>) => {
    //   let result:Array<Pacient> = [];
    //   if (pacienti){
    //     console.log("Lista pacienti din pacienti component: =>", pacienti);
    //     pacienti.forEach((erg) => {
    //     this.PacientList.push(new Pacient(erg.id))
    //     });
    //   }
    //   return result;
    // }))
    // .subscribe(pacienti => this.PacientList = pacienti);

    // this.dataSource = new MatTableDataSource(this.PacientList);

    // this.dataSource.filterPredicate = function(data, filter):boolean
    // {
    //   // console.log("data din filtru: =>",data);
    //   return data.id.toString().includes(filter) || data.date_personale.nume.toLowerCase().includes(filter) || data.date_personale.prenume.toLowerCase().includes(filter)
    //   || data.date_personale.cnp.toString().includes(filter) || data.date_contact.email.includes(filter) || data.date_contact.telefon.toLowerCase().includes(filter)
    // }
  }
  load() {
    console.log("PACIENT LIST : => ",this.PacientListtwo)
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    // const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageSize: number = 10;   // initial page size
    // const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    // this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
}
  displayedColumns: string[] = ['id', 'nume', 'prenume', 'CNP',"email", "telefon"];

  applyFilter(event: Event) {
    console.log("Event: => ",event)
    const filterValue = (event.target as HTMLInputElement).value;
    console.log("filter value: => ",filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  rowSelected(args: RowSelectEventArgs) {
    // this.flag_dialog_pacient_open = true
    const selectedrecords: object[] = this.Grid.getSelectedRecords();  // Get the selected records.
    console.log( " SELECTED ROW RECORDS == >> ", selectedrecords)

    let intermediar_beneficiar = selectedrecords[0] as PacientCreateInterface
    this.getRecord(intermediar_beneficiar)
  }
  getRecord(rowz: any)
  {
    console.log("Pacient selectat: =>", rowz);
    const dialogRef = this.dialog.open(PacientdetailsComponent, {
      // width: this.screnwidthstring,
      // maxWidth:this.dialogMaxWidth,
      width: "95%",
      height: "95%",
      data: rowz.id,

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null){
        console.log("afterclosed pd comp",result);
        this.pacientiService.getPacientifromServer()
        .subscribe((data) => {
            this.PacientList = data
            this.dataSource  = new MatTableDataSource(this.PacientList);
          })
        console.log("Pacient List => ", this.PacientList);
          // .pipe(map((pacienti:Array<any>) => {
          //   let result:Array<Pacient> = [];
          //   if (pacienti){
          //     console.log("Lista pacienti din pacienti component: =>", pacienti);
          //     pacienti.forEach((erg) => {
          //     this.PacientList.push(new Pacient(erg.id))
          //     });
          //   }
          //   return result;
          // }))
          // .subscribe(pacienti => this.PacientList = pacienti);
        this.dataSource = new MatTableDataSource(this.PacientList);
      }
    });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(AddpacientComponent, {
      maxWidth:this.dialogMaxWidth,
      width: this.dialogMaxWidth,
      height: "95%",
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.pacientiService.getPacientifromServer()
      .subscribe((data) => {
          this.PacientList = data
          this.dataSource  = new MatTableDataSource(this.PacientList);
          console.log("sadf  ==> ",this.dataSource);
        })
          // .pipe(map((pacienti:Array<Pacient>) => {
          //   let result:Array<Pacient> = [];
          //   if (pacienti){
          //     console.log("Lista pacienti din pacienti component: =>", pacienti);
          //     pacienti.forEach((erg) => {
          //     console.log("Lista ERG pacienti component: =>", erg);
          //     this.PacientList.push(new Pacient(erg.id))
          //     });
          //   }
          //   return result;
          // }))
          // .subscribe(pacienti => this.PacientList = pacienti);
          // console.log("PacientList din pacienti component: =>", this.PacientList);

        // this.dataSource = new MatTableDataSource(this.PacientList);
    });
  }

}
