<mat-form-field>
  <mat-label>Servicii</mat-label>
  <mat-select [(value)] = selectedservicii (selectionChange)="selecteazaserviciu($event)">
      <mat-option *ngFor="let serviciu of serviciilist" [value]="serviciu">
          {{serviciu.denumire}}
      </mat-option>
  </mat-select>
  </mat-form-field>

  <br>

  <div *ngIf="selectedservicii">

    <mat-form-field >
      <input matInput [(ngModel)]="pret" value = {{selectedservicii.pret}} placeholder="Pret">
      <span matSuffix> RON</span>
    </mat-form-field>

    <br>
    <mat-checkbox (change)="schimbadiscount()"> Se aplica discount</mat-checkbox>
    <div *ngIf="are_discount">
      <mat-form-field >
        <input matInput type="number" max="100" [formControl]="discount" placeholder="Discount" (change)="calculeazatotalul()">
        <span matSuffix>%</span>
        <mat-error *ngIf="discount.invalid">Valoarea reducerii poate fi cuprinsa intre <b> 0% - 100% </b> </mat-error>
      </mat-form-field>
    </div>

  </div>

<mat-form-field>
  <mat-label>Terapeut</mat-label>
  <mat-select [(value)] = selectedmedic (selectionChange)="selecteazamedic($event)">
      <mat-option *ngFor="let medic of medicilist" [value]="medic">
          {{medic.nume + " " + medic.prenume}}
      </mat-option>
  </mat-select>
  </mat-form-field>

<br>

<!-- <mat-form-field *ngIf="flag_selected_serviciu && flag_selected_terapeut && flag_init_data_efectuarii"> -->
<mat-form-field *ngIf="flag_selected_serviciu && flag_selected_terapeut && flag_init_data_efectuarii">
  <mat-label>Data</mat-label>
  <input matInput [matDatepicker]="picker" [matDatepickerFilter]="weekendsDatesFilter" (dateChange)="schimbadata($event)">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<br>
<div *ngIf="flag_selected_date">
  Ora -
  <ngx-mat-timepicker
  [(ngModel)]="dataefectuarii"
  [stepMinute]= "stepMinute"
  [showSpinners]="true">
</ngx-mat-timepicker>
</div>

<br>

<div *ngIf="total">
  <b>Total de plata: {{total}} RON </b>
</div>


<button mat-raised-button  style="padding-left: 20px;" (click)="confirm()"> Adauga </button>
