import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CalitatePersoana } from '../models/calitate-persoana.model';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { serverurl } from './server.service';

import { Observable } from 'rxjs';
import { CalitateInterface } from '../interfaces/pacient.interface';


@Injectable({
  providedIn: 'root'
})
export class CalitatePersoanaService {

  public CalitatiPersoane: CalitatePersoana[] = []
  // private server_url="http://127.0.0.1:8000/calitate/"
  // private server_url="api/calitate"
  private server_url=serverurl+"/api/v1/calitate/"

  constructor(
    private http: HttpClient
  ) {  }

//  async getCalitatePersoana(){
//   var lista = []
//   let el = await this.http.get(this.server_url)
//   .toPromise().then(function(response){
//     for (let calitate in response ){
//     // console.log("response= ",response[calitate])
//     let intermediar : CalitatePersoana = { id : response[calitate].id, calitate:response[calitate].calitate}
//     // console.log("inter= ", intermediar)
//     lista.push(intermediar)
//     // this.CalitatiPersoane
//     // console.log("Calpers= ", lista)
//     }
//   });
//   // console.log("lista inainte de return= ", lista)
//   return (lista)
//   }

getCalitatePersoana(): Observable<any> {
  return this.http.get(this.server_url).pipe(map(this.extractData))
  // .do(data => console.log ("Calitati from service = " + JSON.stringify(data)))
  .do(data => JSON.stringify(data))
  .catch(this.handleError);
  }

getCalitatefromServer(){
  return this.http.get(this.server_url)
}

  private extractData(response: any){
    let body = response;
    // console.log("body din serv calitate persoana: =>", body);
    return body || {};
  }

  private handleError(error: Response){
    // console.log("Error from calitate persoana service: => " + error)
    return Observable.throw("500 internal server error" )
  }

}

