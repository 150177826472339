import { isThursday } from "date-fns";
import { IntervaleZilniceInterface, IntervaleZilniceCreateInterface,IntervalInterface,IntervalCreateInterface,IntervalnewCreateInterface,IntervalnewInterface ,TipplataInterface, IncasaricreateInterface, CasierieDateDinModalAdaugaServiciuInterface, ServiciiInterface, TipDepartamentInterface, TipDepartamentCreateInterface, TipDepartamentCreateFaraValabilitateInterface , TipuriDeServiciiInterface,TipuriDeServiciiCreateInterface,TipuriDeServiciiCreateFaraValabilitateInterface,specialitateMediciCreateInterface, specialitateMediciInterface, specialitateMediciCreateFaraValabilitateInterface, TipDiagnosticCreateFaraValabilitateInterface, TipDiagnosticCreateInterface, TipDiagnosticInterface, DiagnosticCreateFaraValabilitateInterface, DiagnosticCreateInterface, DiagnosticInterface, DiagnosticCreateFaraTipDiagnosticInterface, CasieriiServiciiInterface, MediciDetaliiInterface, MediciDetaliiPeScurtInterface, ServiciiiPeScurtInterface    } from '../interfaces/nomenclatoare.interface';
import { EventAction } from 'calendar-utils'
import { IntervalIndisponibilitateInterfaceCreate } from "../interfaces/pacient.interface";



export class Interval implements IntervalInterface{
  constructor(
    public id ?: number,
    public start ?: string,
    public end ?: string,
    public format_type ?: string
  ){}
}
export class IntervalCreate implements IntervalCreateInterface{
  constructor (
    public start ?: string,
    public end ?: string,
    format_type ?: string
  ){}
}

export class IntervalnewCreate implements IntervalnewCreateInterface{
  constructor(
    public zi ?: string,
    public luna ?: string,
    public an ?: string,
    public ora ?: string,
    public minut ?: string,
  ){}
}

export class Intervalnew implements IntervalnewInterface{
  constructor(
    public id ?: number,
    public luna ?: string,
    public an ?: string,
    public ora ?: string,
    public minut ?: string
  ){}
}

export class Tipplata implements TipplataInterface{
  constructor(
    public id ?: number,
    public denumire ?: string
  ){}
}

export class Incasaricreate implements IncasaricreateInterface{
  constructor(
    public id ?: number,
    public casierieserviciuid ?: number,
    public plata_partiala ?: boolean,
    public plata_integrala ?: boolean,
    public dataefectuarii ?: string,
    public tip_incasareid ?: number,
    public platit ?: number,
    public achitatflag ?: boolean,
    public plata_partiala_completaid ?: number
  ){}
}

export class Servicii implements ServiciiInterface{
    constructor(
    public id ?: number,
    public cod ?: string,
    public denumire ?: string,
    public tipServiciu ?: TipuriDeServicii,
    public specialitate ?: specialitateMedici,
    public pret ?: number,
    public tva : boolean = false,
    public valoare_tva ?: number,
    public timp?: number,
    public arevalabilitate: boolean = false,
    ){}
}

export class CasierieDateDinModalAdaugaServiciu implements CasierieDateDinModalAdaugaServiciuInterface {
  constructor(
    public id ?: number,
    public serviciu_id?: number,
    public medic_id?: number,
    public pret?: number,
    public discount ?: number,
    public total?: number,
    public dataefectuarii ?: string,
    public are_discount ?: boolean,
    public sold ?: number,
    public platit ?: number
  ){
    are_discount = false,
    discount = 0
    platit = 0
    sold = total
  }
}

export class CasieriiServicii implements CasieriiServiciiInterface{
  constructor(
    public id ?: number,
    public pacientid ?: number,
    public serviciuid ?: number,
    public medicid ?: number,
    public pret ?: number,
    public discount ?: number,
    public total ?: number,
    public dataefectuarii ?: string,
    public are_discount ?: boolean,
    public sold ?: number,
    public platit ?: number
  ){}
}

export class Valabilitate{
    constructor(
        public id ?: number,
        public start ?: string,
        public end ?: string,
        public format_type ?: string
    ){}
}

export class ValabilitateCreate{
    constructor(
        public start ?: string,
        public end ?: string,
        public format_type ?: string
    ){}
}

export class TipuriDeServicii implements TipuriDeServiciiInterface{
    constructor(
    public id ?: number,
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitate ?: Valabilitate,
    public valabilitateid ?: number
    ){}
    }
export class TipuriDeServiciiCreate implements TipuriDeServiciiCreateInterface{
    constructor(
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitateid ?: number
){}
}

export class TipuriDeServiciiFaraValabilitateCreate implements TipuriDeServiciiCreateFaraValabilitateInterface{
    constructor(
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false
){}
}

export class specialitateMedici implements specialitateMediciInterface {
    constructor(
    public id ?: number,
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitateid ?: number,
    public valabilitate ?: Valabilitate,
    ){}
}

export class specialitateMediciCreate implements specialitateMediciCreateInterface {
    constructor(
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitateid ?: number
    ){}
}

export class specialitateMediciCreateFaraValabilitate implements specialitateMediciCreateFaraValabilitateInterface{
    constructor(
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false
){}
}

export class TipDepartament implements TipDepartamentInterface  {
    constructor(
    public id ?: number,
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitateid ?: number,
    public valabilitate ?: Valabilitate,
    ){}
}

export class TipDepartamentCreate implements TipDepartamentCreateInterface  {
    constructor(
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitateid ?: number,
    ){}
}

export class TipDepartamentCreateFaraValabilitate implements TipDepartamentCreateFaraValabilitateInterface  {
    constructor(
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false
    ){}
}

export class TipDiagnostic implements TipDiagnosticInterface {
    constructor(
    public id ?: number,
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitateid ?: number,
    public valabilitate ?: Valabilitate,
    ){}
}

export class TipDiagnosticCreate implements TipDiagnosticCreateInterface {
    constructor(
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitateid ?: number,
    ){}
}

export class TipDiagnosticCreateFaraValabilitate implements TipDiagnosticCreateFaraValabilitateInterface {
    constructor(
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    ){}
}

export class Departament{
    constructor(
    public id ?: number,
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitateid ?: number,
    public valabilitate ?: Valabilitate,
    public tipDepartementid ?: number,
    public tipDepartement ?: TipDepartament,
    public servicii ?: Servicii[],
    public serviciuid ?: number,
    ){}
}

export class Diagnostic implements DiagnosticInterface {
    constructor(
    public id ?: number,
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitateid ?: number,
    public valabilitate ?: Valabilitate,
    public tipdiagnostic_ids ?: [],
    public tipDiagnostic ?: TipDiagnostic
    ){}
}

export class DiagnosticCreate implements DiagnosticCreateInterface {
    constructor(
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitateid ?: number,
    public tipdiagnostic_ids ?: [],
    ){}
}

export class DiagnosticCreateFaraTipDiagnostic implements DiagnosticCreateFaraTipDiagnosticInterface {
    constructor(
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public valabilitateid ?: number
    ){}
}

export class DiagnosticCreateFaraValabilitate implements DiagnosticCreateFaraValabilitateInterface {
    constructor(
    public cod ?: string,
    public denumire ?: string,
    public arevalabilitate: boolean = false,
    public tipdiagnostic_ids ?: [],
    ){}
}
// export class DepartamentCreate{
//     constructor(
//     public cod ?: string,
//     public denumire ?: string,
//     public arevalabilitate: boolean = false,
//     public valabilitate ?: Valabilitate,
//     public tipDepartement ?: TipDepartament,
//     public servicii ?: Servicii[],
//     ){
//         this.valabilitate = new Valabilitate();
//         this.tipDepartement = new TipDepartament();
//         this.servicii = [];
//     }
// }
export class ContractCNAS{
    constructor(
        public numar ?: string,
        public arevalabilitate: boolean = false,
        public valabilitate ?: Valabilitate
        ){
            this.valabilitate = new Valabilitate()
        }
    }

export class IntervaleZilniceCreate implements IntervaleZilniceCreateInterface{
  constructor(
    public start ?: string,
    public end ?: string
  )
  {}
}

export class MediciCreate{
  constructor(
      public codParafa ?: string,
      public certificatDigital ?: string,
      public mail ?: string,
      public telefon ?: string,
      public cnp ?: string,
      public nume?:string,
      public prenume?: string,
      public specialitate ?: specialitateMedici[],
      public servicii ?: Servicii[],
      public departament ?: Departament[],
      public departament_id ?: number,
      public servicii_id ?: number,
      public specialitatea_id ?: number,
      public contract_CNAS ?: ContractCNAS,
      public greutate?: number,
      public sex ?: string,
      public intervale_zilnice ?: IntervaleZilniceCreate[],
      public program?: {
          luni?:{
              OraStart?: Date,
              OraEnd?: Date
          },
          marti?:{
              OraStart?: Date,
              OraEnd?: Date
          },
          miercuri?:{
              OraStart?: Date,
              OraEnd?: Date
          },
          joi?:{
              OraStart?: Date,
              OraEnd?: Date
          },
          vineri?:{
              OraStart?: Date,
              OraEnd?: Date
          }
      }
  ){
      this.specialitate = []
      this.departament = []
      this.servicii = []
      this.intervale_zilnice = []
      this.contract_CNAS = new ContractCNAS()
      this.program = {
          luni: {
              OraStart: new Date(new Date().setHours(10,0,0)),
              OraEnd: new Date(new Date().setHours(18,0,0))
          },
          marti: {
              OraStart: new Date(new Date().setHours(10,0,0)),
              OraEnd: new Date(new Date().setHours(18,0,0))
          },
          miercuri: {
              OraStart: new Date(new Date().setHours(10,0,0)),
              OraEnd: new Date(new Date().setHours(18,0,0))
          },
          joi: {
              OraStart: new Date(new Date().setHours(10,0,0)),
              OraEnd: new Date(new Date().setHours(18,0,0))
          },
          vineri: {
              OraStart: new Date(new Date().setHours(10,0,0)),
              OraEnd: new Date(new Date().setHours(18,0,0))
          }
      }
  }
}

export class ServiciiPeScurt implements ServiciiiPeScurtInterface{
  constructor(
    public denumire ?: string,
    public id ?: number
  )  { }
}
export class MediciDetaliiPeScurt implements MediciDetaliiPeScurtInterface{
  constructor(
    public nume ?: string,
    public id ?: number,
    public servicii_id ?: number,
  )  { }
}

export class MediciDetalii implements MediciDetaliiInterface{
  constructor(
    public nume ?: string,
    public prenume ?: string,
    public telefon ?: string,
    public mail ?: string,
    public cnp ?: string,
    public sex ?: string,
    public greutate ?: number,
    public servicii_id ?: number,
    public specialitatea_id ?: number,
  )  { }
}


export class Medici{
    constructor(
        public id?:number,
        public codParafa ?: string,
        public certificatDigital ?: string,
        public mail ?: string,
        public telefon ?: string,
        public cnp ?: string,
        public nume?:string,
        public prenume?: string,
        public specialitate ?: specialitateMedici[],
        public servicii ?: Servicii[],
        public departament ?: Departament[],
        public departament_id ?: number,
        public servicii_id ?: number,
        public specialitatea_id ?: number,
        public contract_CNAS ?: ContractCNAS,
        public greutate?: number,
        public sex ?: string,
        public intervale_zilnice ?: IntervaleZilniceInterface[],
        public program?: {
            luni?:{
                OraStart?: Date,
                OraEnd?: Date
            },
            marti?:{
                OraStart?: Date,
                OraEnd?: Date
            },
            miercuri?:{
                OraStart?: Date,
                OraEnd?: Date
            },
            joi?:{
                OraStart?: Date,
                OraEnd?: Date
            },
            vineri?:{
                OraStart?: Date,
                OraEnd?: Date
            }
        }
    ){
        this.specialitate = []
        this.departament = []
        this.servicii = []
        this.intervale_zilnice = []
        this.contract_CNAS = new ContractCNAS()
        this.program = {
            luni: {
                OraStart: new Date(new Date().setHours(10,0,0)),
                OraEnd: new Date(new Date().setHours(18,0,0))
            },
            marti: {
                OraStart: new Date(new Date().setHours(10,0,0)),
                OraEnd: new Date(new Date().setHours(18,0,0))
            },
            miercuri: {
                OraStart: new Date(new Date().setHours(10,0,0)),
                OraEnd: new Date(new Date().setHours(18,0,0))
            },
            joi: {
                OraStart: new Date(new Date().setHours(10,0,0)),
                OraEnd: new Date(new Date().setHours(18,0,0))
            },
            vineri: {
                OraStart: new Date(new Date().setHours(10,0,0)),
                OraEnd: new Date(new Date().setHours(18,0,0))
            }
        }
    }
}









