import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'app-pinentry',
  templateUrl: './pinentry.component.html',
  styleUrls: ['./pinentry.component.css']
})
export class PinentryComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    minWidth: 0.5,
    // dotSize:1,
    canvasWidth: 600,
    canvasHeight: 300,
    background: '#ffffff'
  };

  constructor(public matDialogRef: MatDialogRef<PinentryComponent>) { }

  ngOnInit(): void {
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
    console.log('end drawing');
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    // this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  getAlpha = function(data,imgWidth,x, y) {
    return data[(imgWidth*y + x) * 4 + 3]
}
scanYFromTop(data,imgWidth,imgHeight){
  for (var y = 0; y<imgHeight;y++){
    for( var x = 0; x<imgWidth;x++){
      if(this.getAlpha(data,imgWidth,x,y)){
        return y
      }
    }
  }
  return null
}
scanYFromBottom(data,imgWidth,imgHeight){
  for (var y = imgHeight-1; y>-1;y--){
    for( var x = 0; x<imgWidth;x++){
      if(this.getAlpha(data,imgWidth,x,y)){
        return y
      }
    }
  }
  return null
}

scanXFromLeft (data,imgWidth,imgHeight){
  for( var x = 0; x<imgWidth;x++){
      for (var y = 0; y<imgHeight;y++){
      if(this.getAlpha(data,imgWidth,x,y)){
        return x
      }
    }
  }
  return null
}
scanXFromRight (data,imgWidth,imgHeight){
  for( var x = imgWidth-1; x>-1;x--){
      for (var y = 0; y<imgHeight;y++){
      if(this.getAlpha(data,imgWidth,x,y)){
        return x
      }
    }
  }
  return null
}
  returnSemnatura(){
    // let img_intermediar = this.signaturePad.toDataURL();
    // let img_intermediar = this.signaturePad.toData();
    let intermediar_width = this.signaturePad["signaturePad"]._ctx.canvas.width;
    // console.log("Signature Pad Width ==>> ", intermediar_width)
    let intermediar_height = this.signaturePad["signaturePad"]._ctx.canvas.height;
    // console.log("Signature Pad Height ==>> ", intermediar_height)
    let img_intermediar = this.signaturePad["signaturePad"]._ctx.getImageData(0, 0, intermediar_width, intermediar_height);
    // console.log("Signature img_intermediar ==>> ", img_intermediar.data)
    let intermediar_img_data = img_intermediar.data
    // console.log("Signature img_intermediar ==>> ", this.getAlpha(intermediar_img_data,intermediar_width,50,50))
    let crop_top_y = this.scanYFromTop(intermediar_img_data,intermediar_width,intermediar_height)
    let crop_bottom_y : number
    let crop_left_x : number
    let crop_right_x : number
    let relevantData : any
    if (crop_top_y){
      // console.log("Crop TOP Y ==>> ", crop_top_y)
      crop_bottom_y = this.scanYFromBottom(intermediar_img_data,intermediar_width,intermediar_height)
      // console.log("Crop BOTTOM Y ==>> ", crop_bottom_y)
      crop_left_x = this.scanXFromLeft(intermediar_img_data,intermediar_width,intermediar_height)
      // console.log("Crop LEFT X ==>> ", crop_left_x)
      crop_right_x = this.scanXFromRight(intermediar_img_data,intermediar_width,intermediar_height)
      // console.log("Crop RIGHT X ==>> ", crop_right_x)
      relevantData = this.signaturePad["signaturePad"]._ctx.getImageData(crop_left_x, crop_top_y, crop_right_x-crop_left_x, crop_bottom_y-crop_top_y);
      this.signaturePad["signaturePad"]._canvas.width = crop_right_x-crop_left_x
      this.signaturePad["signaturePad"]._canvas.height = crop_bottom_y-crop_top_y
      this.signaturePad["signaturePad"]._ctx.clearRect(0, 0,crop_right_x-crop_left_x, crop_bottom_y-crop_top_y)
      this.signaturePad["signaturePad"]._ctx.putImageData(relevantData, 0, 0);
      // console.log("FINAL  ==>> ", this.signaturePad.toDataURL())
      this.matDialogRef.close(this.signaturePad.toDataURL());
    }
    else{
      console.log("Crop TOP Y ==>> GOL !!! ")
    }
  }

}
