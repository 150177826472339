import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmationdialog',
  templateUrl: './confirmationdialog.component.html',
  styleUrls: ['./confirmationdialog.component.css']
})
export class ConfirmationdialogComponent implements OnInit {
  animal: string;
  name: string;
  mesaj: string = ''
    constructor(
      @Inject(MAT_DIALOG_DATA) private data:any
    ) {
      if (data){
        console.log("DATA DIN CONFIRMATION DIALOG ==>> ", data)
        this.mesaj = data.mesaj
      }
    }

  ngOnInit(): void {
  }

}
