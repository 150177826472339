<mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]='false' hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
              Monitorizari / Planuri de interventie
            </mat-panel-title>
            <mat-panel-description>
              Fisa monitorizare PPI si Fisa pentru Plan de interventie personalizat
            </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-selection-list>
            <mat-list-option role="listitem" (click)="openPlanDeInterventiePersonalizatComponent()" >Plan de interventie personalizat</mat-list-option>
            <mat-list-option role="listitem" (click)="openFisaMonitorizarePpiComponent()" >Fisa monitorizare PPI</mat-list-option>
        </mat-selection-list>
      </mat-expansion-panel>
</mat-accordion>

<div style="max-height: 90%; overflow: scroll;" *ngIf="flag_exista_consultatii">

  <ejs-grid
  #grid
  [dataSource]="consultatii"
  (load)='load()'
  [allowFiltering]='true'
  [allowSorting]='true'
  [allowPaging]='true'
  [pageSettings]="pageSettings"
  [allowResizing]="true"
  [allowReordering]="true"
  (rowSelected)='rowSelected($event)'
  >
  <e-columns>
    <!-- <e-column field='id' headerText='ID' textAlign='Right' width=120></e-column> -->
    <!-- <e-column field='denumire' headerText='Denumire' width=150></e-column> -->
    <e-column field='denumire' headerText='Denumire' ></e-column>
    <e-column field='dataAdaugare' headerText='Data Adaugare' ></e-column>

    <e-column *ngFor="let column of editColumnName" [headerText]='column.headerText'>
      <ng-template #template let-data>
        <app-editeazaconsultbutton [consultData]="data" [pacientData]="pacient" (edited)="refreshTableDataAfterEdit($event)"></app-editeazaconsultbutton>
      </ng-template>
    </e-column>

    <e-column *ngFor="let column of vizualizeazaPDFColumnName" [headerText]='column.headerText'>
      <ng-template #template let-data>
        <app-vizualizeazaconsultpdfbutton  [consultData]="data" [pacientData]="pacient" ></app-vizualizeazaconsultpdfbutton>
      </ng-template>
    </e-column>

    <e-column *ngFor="let column of descarcaPDFColumnName" [headerText]='column.headerText'>
      <ng-template #template let-data>
        <app-descarcaconsultpdfbutton [consultData]="data" [pacientData]="pacient"  ></app-descarcaconsultpdfbutton>
      </ng-template>
    </e-column>

    <e-column *ngFor="let column of stergeConsultColumnName" [headerText]='column.headerText'>
      <ng-template #template let-data>
        <app-stergeconsultpdfbutton [consultData]="data" [pacientData]="pacient" (deleted) ="refreshTableDataAfterDelete($event)"  ></app-stergeconsultpdfbutton>
      </ng-template>
    </e-column>

  </e-columns>
  </ejs-grid>
</div>
<div *ngIf="!flag_exista_consultatii">
  <span>Beneficiarul NU are nici o consultatie atribuita</span>
</div>
