import { Component, Inject, OnInit,ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DosarelectronicComponent } from '../pacient/dosarelectronic/dosarelectronic.component';
import {ConsultComponent } from '../consult/consult.component';
import { CalitatePersoanaService } from '../services/calitate-persoana.service';
import { SmssenderService } from '../services/smssender.service';
import { CalitatePersoana } from '../models/calitate-persoana.model';
import { Pacient, DateContact, DatePersonale, Adresa, DatePersonaleCreate, DateContactCreate, AdresaCreate, ReprezentantLegal, ReprezentantLegalCreate, PacientTwoServer, IntervalIndisponibilitateCreate, IntervalIndisponibilitateCreateFromPacient, IntervalIndisponibilitate, RLCreateFromBeneficiar, DateContactCreatePacientCreate, ReprezentantLegalComplet, ReprezentantLegalUpdate, DatePersonaleDialogCard, DateContactDialogCard, RLDialogCard, PCDialogCard } from '../models/pacientdetails.model';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { PacientiService } from '../services/pacienti.service';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { Observable, Subject } from 'rxjs';
import { PlandeinterventieComponent } from '../plandeinterventie/plandeinterventie.component';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { IstoricprogramariComponent } from '../istoricprogramari/istoricprogramari.component';
import { ContractePacientComponent } from '../contracte-pacient/contracte-pacient.component';
import { DateDeContactService } from '../services/datedecontact.service';
import { AdresaService } from '../services/adresa.service';
import { AdresaCreateInterface, AdresaInterface, DateContactDialogCardnterface, DateContactInterface, DatePersonaleDialogCardnterface, IntervalIndisponibilitateCreateFromPacientInterface, IntervalIndisponibilitateInterface, IntervalIndisponibilitateInterfaceCreate, PCDialogCardInterface, ReprezentantLegalCompletInterface, ReprezentantLegalInterface, ReprezentantLegalUpdateInterface, RLCreateFromBeneficiarInterface, RLDialogCardInterface } from '../interfaces/pacient.interface';
import { DatePersonaleService } from '../services/datepersonale.service';
import { asocPacientReprezentantLegalService } from '../services/asocpacientreprezentantlegal.service';
import { asocPacientPersoanaContactService } from '../services/asocpacientpersoanacontact.service';
import { IntervalService } from '../services/interval.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';
import { InitPacientDetailsInterface } from '../interfaces/initpacientdetails.interface';
import { InitPacientDetails } from '../models/initpacientdetails.mode';
import { filter, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pacientdetails',
  templateUrl: './pacientdetails.component.html',
  styleUrls: ['./pacientdetails.component.css']
})
export class PacientdetailsComponent implements OnInit {
  pacient = new Pacient();

  pacienttwo = new PacientTwoServer();

  adresa_beneficiar = new FormControl('',[Validators.email])
  email_beneficiar = new FormControl('',[Validators.email])
  telefon_beneficiar = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])

  displayedColumnIntervaleIndisponibilitate: string[] = ['ora_start', 'ora_end', 'detalii','edit', 'deleteselect']

  intervaleindisponibilitatetodelete: any[] = []

  carte_identitate_check : boolean = false

  interval_start = new Date()
  interval_end = new Date()

  intermediar_poztitie_minim: number = -1
  intermediar_interval_edit_detalii: string = ''
  intermediar_index_interval_editat: number = -1

  interval_indisponibilitate_nou = new IntervalIndisponibilitateCreate()
  intervale_indisponibilitate_dataSource: any;


  // flags

  flag_adauga_primul_interval: boolean = false // mapat in init pacient model
  flag_adauga_interval_nou: boolean = false // mapat in init pacient model
  flag_salveaza_interval_nou: boolean = false // mapat in init pacient model
  flag_interval_in_editare: boolean = false // mapat in init pacient model
  flag_show_detalii_interval: boolean = false // mapat in init pacient model

  flag_camp_detalii_activ: boolean = false
  flag_sunt_orare_indisponibilitate: boolean = false
  flag_salveaza_interval_editat: boolean = false

  flag_telefon_email_saved:boolean = false

  flag_telefon_beneficiar_valid:boolean = false
  flag_email_beneficiar_valid:boolean = false
  flag_adresa_beneficiar_add:boolean = false


  flag_telefon_beneficiar_set:boolean = false
  flag_email_beneficiar_set:boolean = false

  flag_adresa_beneficiar_set:boolean = false
  flag_beneficiar_fara_adresa:boolean = false

  flag_RL_gol:boolean = true
  flag_PC_gol:boolean = true
  flag_RL_in_editare:boolean = false
  flag_PC_in_editare:boolean = false
  flag_adresa_RL_add:boolean = false
  flag_adresa_PC_add:boolean = false
  flag_telefon_email_RL_saved:boolean = false
  flag_telefon_email_PC_saved :boolean = false
  flag_adresa_RL_set:boolean = false
  flag_adresa_PC_set :boolean = false
  flag_reprezentant_legal_ci:boolean = false
  flag_persoana_contact_ci :boolean = false
  flag_RL_fara_adresa:boolean = false
  flag_PC_fara_adresa :boolean = false
  displayedColumns: string[] = ['id','nume', 'prenume', 'CNP',"email", "telefon"];

  dataSourceRL: any; // reprezentanti legali
  dataSourcePC: any; // persoane de contact

  todeleteRL = []

  todeletePC = []

  selectedTab: number = 0
  selectedTabRL: number = 0
  selectedTabPC: number = 0

  reprezentant_legal: RLCreateFromBeneficiarInterface = new RLCreateFromBeneficiar()
  persoana_contact: RLCreateFromBeneficiarInterface = new RLCreateFromBeneficiar()

  adresaRL : AdresaInterface = new Adresa()
  telefon_RL = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
  email_RL= new FormControl('',[Validators.email])

  adresaPC : AdresaInterface = new Adresa()
  telefon_PC = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
  email_PC = new FormControl('',[Validators.email])

  old_telefon_RL : any
  old_telefon_PC : any
  old_RL_element : any
  old_PC_element : any

  calitatepersoana: CalitatePersoana[] = []
  dataSource: any;
  selection = new SelectionModel(true, );
  PacientList : Pacient[]
  exista_orar_de_somn : boolean = false
  orar_de_somn_start : Date
  orar_de_somn_end : Date
  calitate : any [] = []
  lista:any[] = []
  listacontact:any[] = []
  displayedColumnstwo: string[] = ['nume', 'prenume', 'calitate',"telefon"];
  displayedColumnsRL: string[] = ['nume', 'prenume', 'calitate',"telefon","email","edit","deleteselect"]; // coloane tabel reprezentanti legali
  displayedColumnsPC: string[] = ['nume', 'prenume', 'calitate',"telefon","email","edit","deleteselect"]; // coloane tabel reprezentanti legali

  displayedColumnsthree: string[] = ['nume', 'prenume', 'calitate',"telefon"];
  dataSourcetwo: any;
  dataSourcethree: any;
  // persoana_contact_calitate
  addnewperson:number = 0
  addnewcontactperson:number = 0
  reprezentant_legal_nonuser_date_personale : DatePersonaleCreate
  reprezentant_legal_nonuser_date_contact : DateContactCreate
  persoana_contact_nonuser_date_personale : DatePersonaleCreate
  persoana_contact_nonuser_date_contact : DateContactCreate
  public init_pacientDetails : InitPacientDetailsInterface = new InitPacientDetails()
  public init_bk_pacientDetails : InitPacientDetailsInterface = new InitPacientDetails()
  public datePersonaleDialogCardValues: DatePersonaleDialogCardnterface = new DatePersonaleDialogCard()
  public dateContactDialogCardValues: DateContactDialogCardnterface = new DateContactDialogCard()
  public dateContactAdresaCardValues: AdresaInterface
  private ngUnsubscribe = new Subject();
  // public dateRLCardValues: RLDialogCardnterface
  public dateRLCardValues: RLDialogCardInterface = new RLDialogCard()
  public datePCCardValues: PCDialogCardInterface = new PCDialogCard()

  constructor(
    public asocPacientReprezentantLegalService: asocPacientReprezentantLegalService,
    public asocPacientPersoanaContactService: asocPacientPersoanaContactService,
    public datepersonaleService: DatePersonaleService,
    public adresaService: AdresaService,
    public datecontactService: DateDeContactService,
    public pacientiService: PacientiService,
    private _calitateservice:CalitatePersoanaService,
    public dialogRef: MatDialogRef<PacientdetailsComponent>,
    public calitateService: CalitatePersoanaService,
    public smsservice: SmssenderService,
    public intervalService: IntervalService,
    private _snackBar: MatSnackBar,
    public dialg: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data:any
    ) {
      this.getInitializeCalitati()
    if(data){
      console.log("Data LA INIT PACIENT ==>> ", data)
      this.setPacitiFromServerByIDonInit(data)

    //   if (data.rowz){
    //     console.log("Data ROWZ !! LA INIT PACIENT ==>> ", data.rowz)
    //     this.pacient = data.rowz;
    //     console.log("INIT PACIENT DETAILS ==>> interval DE SOMN ==>> ", data.rowz.intervale_indisponibilitate)
    //     // check if pacient are orar de somn
    //     console.log("INIT PACIENT DETAILS ==>> ORAR DE SOMN ==>> ", this.pacient.orar_de_somn)
    //     if (this.pacient.orar_de_somn){
    //       console.log("DUPA INIT PACIENT DETAILS !! ")
    //     }
    //   }
    //   else{
    //     this.pacient = data
    //   }
    //   console.log(this.pacient);

    //   if (!this.pacient.reprezentant_legal_persoana_de_contact){
    //     if(this.pacient.persoana_contact_nonusers){
    //       this.listacontact = this.pacient.persoana_contact_nonusers
    //       this.dataSourcethree = new MatTableDataSource(this.listacontact);
    //       this.addnewcontactperson = 1
    //     }
    //   }
    // }
    // this.calitateService.getCalitatefromServer()
    // .subscribe((data) =>{
    //   if (data){
    //     this.calitate.push(data)
    //     console.log("Calitate din service ==>> ", this.calitate)
    //   }})
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  changedStatusCont(){

  }
  ngAfterViewInit() {}
  public initDatePersonalePC(){
    this.persoana_contact.datepersonale = new DatePersonaleCreate();
  }
  public initDateContactPC(){
    this.persoana_contact.datecontact = new DateContactCreate();
    // console.log("reprezentant legal date contact INIT ==>> ",this.reprezentant_legal.datecontact)
  }

  public initDatePersonaleRL(){
    this.reprezentant_legal.datepersonale = new DatePersonaleCreate();
  }
  public initDateContactRL(){
    this.reprezentant_legal.datecontact = new DateContactCreate();
    // console.log("reprezentant legal date contact INIT ==>> ",this.reprezentant_legal.datecontact)
  }
  public initRL(){
    this.reprezentant_legal = new RLCreateFromBeneficiar()
    this.initDatePersonaleRL()
    this.initDateContactRL()
  }
  public initPC(){
    this.persoana_contact = new RLCreateFromBeneficiar()
    this.initDatePersonalePC()
    this.initDateContactPC()
  }
  public adaugaPrimulRL(){
    this.flag_RL_gol = false
    this.initRL();
  }
  public adaugaPrimulPC(){
    this.flag_PC_gol = false
    this.initPC();
  }
  public initDatePersonaleCardDetails(pac){
    console.log( " INTRU IN init Date Personale Card Details ")
    console.log( " PAC ==>> ", pac)
    console.log( " datePersonaleDialogCardValues ==>> ", this.datePersonaleDialogCardValues)
    if (pac.aprobat != undefined){
      this.datePersonaleDialogCardValues.aprobat = pac.aprobat
    }
    this.datePersonaleDialogCardValues.orar_de_somn = false
    this.datePersonaleDialogCardValues.intervale_indisponibilitate = new MatTableDataSource ()
    if (this.checkIfIntervaleIndisponibilitateExista(pac)){
      if (this.checkIntervaleIndisponibilitateLengthAndDecide(pac) > 0){
        this.datePersonaleDialogCardValues.orar_de_somn = true
        this.pacient.orar_de_somn = true
        this.datePersonaleDialogCardValues.intervale_indisponibilitate = new MatTableDataSource ( pac.intervale_indisponibilitate )
      }
    }

    this.datePersonaleDialogCardValues.independent = false

    if (pac.independent){
      this.datePersonaleDialogCardValues.independent = pac.independent
    }

    if (pac.inaltime){
      this.datePersonaleDialogCardValues.inaltime = pac.inaltime
    }

    if (pac.greutate){
      this.datePersonaleDialogCardValues.greutate = pac.greutate
    }
    if (pac.date_personale != undefined){
      this.datePersonaleDialogCardValues = this.copyFromTwoObjects(this.datePersonaleDialogCardValues,pac.date_personale)
      this.datePersonaleDialogCardValues.carte_identitate_check = true
      if (this.datePersonaleDialogCardValues.numar_carte_identitate == undefined && this.datePersonaleDialogCardValues.serie_carte_identitate == undefined){
        this.datePersonaleDialogCardValues.carte_identitate_check = false
      }
    }
    console.log( " datePersonaleDialogCardValues LA FINAL !!! ==>> ", this.datePersonaleDialogCardValues)
  }
  public initDateContactCardDetails(pac){
    console.log( " INTRU IN init Date CONTACT Card Details ")
    console.log( " PAC ==>> ", pac)
    console.log( " dateCONTACTDialogCardValues ==>> ", this.dateContactDialogCardValues)
    if (pac.date_contact){
      if (pac.date_contact.telefon)
      {
        this.telefon_beneficiar.setValue(pac.date_contact.telefon)
        this.dateContactDialogCardValues.telefon = pac.date_contact.telefon
        this.flag_telefon_beneficiar_set = true
      }
      if (pac.date_contact.email)
      {
        this.email_beneficiar.setValue(pac.date_contact.email)
        this.dateContactDialogCardValues.email = pac.date_contact.email
        this.flag_email_beneficiar_set = true
      }
      if (this.flag_email_beneficiar_set || this.flag_telefon_beneficiar_set){
        this.flag_telefon_email_saved = true
        this.telefon_beneficiar.disable()
        this.email_beneficiar.disable()
      }
      if ((pac.date_contact.adresaid != undefined) && pac.date_contact.adresa){
        this.initAdresaBeneficiar(pac.date_contact.adresa)
      }
      else{
        // this.beneficiarFaraAdresa()
      }
    }
    console.log( " dateCONTACTDialogCardValues  FINAL  ==>> ", this.dateContactDialogCardValues)

  }
  public initRLCardDetails(pac){
    if (pac.reprezentanti_legali != undefined){
      if (pac.reprezentanti_legali.length > 0){
        this.dateRLCardValues.reprezentanti_legali = pac.reprezentanti_legali
        this.addnewperson = 1
        console.log("Pacient Are Reprezentanti Legali")
        this.flag_RL_gol = false
      }
      else{
        console.log("Pacient NU ARE Reprezentanti Legali")
      }
    }
    this.dataSourceRL = new MatTableDataSource(this.dateRLCardValues.reprezentanti_legali)
  }

  async changePCegalRLslide(){
    console.log("PC EGAL RL SLIDER ==>> ", this.datePCCardValues.reprezentant_legal_persoana_de_contact)
    console.log("PC EGAL RL SLIDER ==>> SE EXECUTA IF-UL ?==>> ", !this.datePCCardValues.reprezentant_legal_persoana_de_contact)
    // console.log("PC SLIDER ==>> ", this.pacient)
    // console.log("PC SLIDER PACIENT TWO ==>> ", this.pacienttwo)
    if (!this.datePCCardValues.reprezentant_legal_persoana_de_contact){
      console.log("PERSOANA DE CONTACT == EGAL == REPREZENTANT LEGAL A DEVENIT FALSE")
      if (this.pacienttwo.persoane_contact != undefined){
        console.log("PCRL A DEVENIT FALSE ==>> PACIENT TWO PERSOANE CONTACT ==>> ", this.pacienttwo.persoane_contact)
        if (this.pacienttwo.persoane_contact.length > 0){
          console.log("PACIENT TWO PERSOANE CONTACT length > 0 ==>> ")
          this.datePCCardValues.persoane_contact = this.pacienttwo.persoane_contact
          console.log("this.datePCCardValues.persoane_contact ==>> ", this.datePCCardValues.persoane_contact)
          this.addnewcontactperson = 1
          console.log("Pacient Are PERSOANE DE CONTACT")
          this.flag_PC_gol = false
        }
        else{
          console.log("Pacient NU ARE PERSOANE DE CONTACT")
          this.pacienttwo.persoane_contact = []
          this.flag_PC_gol = true
          this.addnewcontactperson = 0
        }
      }
    }
    else{
      console.log("PERSOANA DE CONTACT == EGAL == REPREZENTANT LEGAL A DEVENIT TRUE 2222 ")
      this.flag_PC_in_editare = false
      this.todeletePC = []
    }
    this.dataSourcePC = new MatTableDataSource(this.datePCCardValues.persoane_contact)
    // console.log("NAKED MODEL INITIAL == >> ", this.pacientiService.createPacientNakedModel(this.pacienttwo))
    this.pacienttwo.reprezentant_legal_persoana_de_contact = this.datePCCardValues.reprezentant_legal_persoana_de_contact
    // console.log("NAKED MODEL INITIAL 22 == >> ", this.pacientiService.createPacientNakedModel(this.pacienttwo))
    let def_return = await this.pacientiService.updatePacientNakedDetailsOnServer(this.pacient.id,this.pacientiService.createPacientNakedModel(this.pacienttwo)).toPromise()
    console.log("UPDATED PACIENT REPREZENTANT LEGAL PERSOANA DE CONTACT ==>> ", def_return)
  }

  public initPCCardDetails(pac){
    // console.log("PERSOANA DE CONTACT USER? ==>> ", pac.reprezentant_legal_persoana_de_contact)
    this.datePCCardValues.reprezentant_legal_persoana_de_contact = pac.reprezentant_legal_persoana_de_contact
    console.log("PERSOANA DE CONTACT USER? ==>> DATE PC CARD VALUES ==>> ", this.datePCCardValues.reprezentant_legal_persoana_de_contact)
    if(!this.datePCCardValues.reprezentant_legal_persoana_de_contact){
      if (pac.persoane_contact != undefined){
        if (pac.persoane_contact.length > 0){
          this.datePCCardValues.persoane_contact = pac.persoane_contact
          this.addnewcontactperson = 1
          console.log("Pacient Are PERSOANE DE CONTACT")
          this.flag_PC_gol = false
        }
        else{
          console.log("Pacient NU ARE PERSOANE DE CONTACT")
          this.flag_PC_gol = true
          this.addnewcontactperson = 0
        }
      }
      this.dataSourcePC = new MatTableDataSource(this.datePCCardValues.persoane_contact)
    }
    else{
      // NIMIC INCA
    }
  }
  public initPacientDetails(pac){
    this.initDatePersonaleCardDetails(pac)
    this.initDateContactCardDetails(pac)
    this.initRLCardDetails(pac)
    this.initPCCardDetails(pac)
  }

  public async getInitializeCalitati(){
    this.calitate = await this.calitateService.getCalitatePersoana().toPromise()
    console.log("INTERMEDIAR CALITATI ==>> ", this.calitate)
  }
  setPacitiFromServerByIDonInit(id_beneficiar) {
  //  this.pacient = await this.pacientiService.getPacientifromServerTwoById(id_beneficiar)
  //  .toPromise();
   this.pacientiService.getPacientifromServerTwoById(id_beneficiar)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (data) => {
      console.log("Data prom get Pacient from server by id ==>> ", data)
      this.pacient = data
      this.pacienttwo = this.pacient
      console.log("Data prom get Pacient from server by id pacienttwo ==>> ", this.pacienttwo)
      this.initPacientDetails(data)
    })
  //  console.log( " INTERMEDIAR PACIENT FROM SERVER ==>> ", this.pacient)
  //  this.pacientiService.setPacientSelectatTwo(this.pacient,"linia 201 - pacient constructor - pacient devine return from server")
  //  console.log( " INTERMEDIAR PACIENT DETAILS FROM SERVER ==>> ", this.pacientiService.init_pacientDetails)
  //  this.pacienttwo = this.pacient
  //  this.init_pacientDetails = this.pacientiService.init_pacientDetails
  //  console.log( " ----- ZZZZ ---- INTERMEDIAR PACIENT DETAILS FROM SERVER ==>> ", this.init_pacientDetails)
  //  this.setLocalVariables()



  if (this.pacienttwo?.persoane_contact?.length){
    console.log("Pacient Are Persoane de Contact")
  }
  else{
    // this.reInitRL()
    this.pacienttwo.persoane_contact = []
    console.log("Pacient NU ARE Persoane de Contact")
  }
  this.pacientiService.setPacientSelectatTwo(this.pacienttwo,"linia 232 - pacient details two - first call set pacient selectat")

  // this.pacientiService.setPacientSelectat(this.pacienttwo, "linia 232 - pacient detailf - first call set pacient selectat")
  // this.carte_identitate_check = this.pacientiService.getStatusCI()
  // this.checkAndSetInitialIntervaleFlags()
  // this.initDateContact()
  // this.adresa_init
  //  this.checkAndSetInitialIntervaleFlags()
 }
 checkIfFlagOrarDataIsInitializedOnSaveAndInitializeIfNot(){
  if (!this.pacientiService.init_pacientDetails.pacientDetailInitialized){
    this.pacientiService.init_pacientDetails.pacientDetailInitialized = true
  }
 }
 //return true if pacient detail initialized este false

 // Flag Orar Data is Initial -> initialize local data with initial pacient data
 //   Daca Flag Orar Data este initial -> initializam in local flag-urile (si pacient two)
 //     Verific daca Interval exista in pacient ->
 checkIfFlagOrarDataIsInitial(){
   return !this.pacientiService.init_pacientDetails.pacientDetailInitialized
  }
  checkIfIntervaleIndisponibilitateExista(variabila){
    console.log("Check == VARIABILA ==>> ",variabila)
    if (variabila.intervale_indisponibilitate != undefined){
      console.log("Check == TRUE")
      return true
    }
    console.log("Check == FALSE")
    return false
  }
  rule1IntervalIndisponibilitateVSChefIfIntervaleIntisponibilitateExista(variabila : PacientTwoServer){
    if (!this.checkIfIntervaleIndisponibilitateExista(variabila)){
      console.log("Intervale Indisponibilitate Trebuie sa existe! -- >> OK")
    }
    else{
      console.log("Intervale Indisponibilitate Trebuie sa existe! -- >> NOT OK - NU AR TREBUI SA SE APELEZE ASA - Intervale indisponibilitate trebuie initiate")
    }
  }
  rule2DataInitialVSChefIfIntervaleIntisponibilitateExista(variabila : PacientTwoServer){
    if (!this.checkIfIntervaleIndisponibilitateExista(variabila)){
      console.log("Intervale Indisponibilitate Trebuie sa existe! -- >> OK")
    }
    else{
      console.log("Intervale Indisponibilitate Trebuie sa existe! -- >> NOT OK - NU AR TREBUI SA SE APELEZE ASA - Intervale indisponibilitate trebuie initiate")
    }
  }
  checkIntervaleIndisponibilitateLengthAndDecide(variabila : PacientTwoServer){
    this.rule1IntervalIndisponibilitateVSChefIfIntervaleIntisponibilitateExista(variabila)
    if (variabila.intervale_indisponibilitate.length > 0 ){
      this.flag_sunt_orare_indisponibilitate = true
      this.flag_adauga_interval_nou = true
      this.intervale_indisponibilitate_dataSource = new MatTableDataSource(variabila.intervale_indisponibilitate)
      return 1
    }
    else {
      if (this.pacienttwo.intervale_indisponibilitate.length == 0 ){
        this.flag_adauga_primul_interval = true
        this.intervale_indisponibilitate_dataSource = new MatTableDataSource()
        return 0
      }
      else{
        return -1
      }
     }
  }
 changeFlagOrarDeSomnStatusToTrue(){
  //  if(this.checkIfFlagOrarDataIsInitial()){

  //  }
  console.log("AICI")
   if (this.checkIfIntervaleIndisponibilitateExista(this.datePersonaleDialogCardValues)){
     if (this.datePersonaleDialogCardValues.intervale_indisponibilitate.data.length > 0 ){
       if (this.flag_adauga_primul_interval){
         this.flag_adauga_primul_interval = false
       }
       this.flag_sunt_orare_indisponibilitate = true
       this.flag_adauga_interval_nou = true
      //  this.intervale_indisponibilitate_dataSource = new MatTableDataSource(this.pacienttwo.intervale_indisponibilitate)
     }
     else {
      if (this.datePersonaleDialogCardValues.intervale_indisponibilitate.data.length == 0 ){
        console.log( " INTERVALE INDISPONIBILITATE = 0 ==>> ", this.flag_adauga_interval_nou)
        this.flag_adauga_primul_interval = false
        this.flag_adauga_interval_nou = true
        // this.intervale_indisponibilitate_dataSource = new MatTableDataSource()
      }
     }
   }
   else{
     console.log(" ELSE !! == >> ")
     console.log( " INTERVALE INDISPONIBILITATE NU EXISTA ==>> ", this.datePersonaleDialogCardValues)
     this.datePersonaleDialogCardValues.intervale_indisponibilitate = new MatTableDataSource ()
     this.flag_adauga_primul_interval = false
     this.flag_adauga_interval_nou = true
   }
   console.log( " FLAG ADAUGA INTERVAL NOU ==>> ", this.flag_adauga_interval_nou)
 }

 setFlagOrarDeSomnStatusToInitialState(){

   if (this.checkIfIntervaleIndisponibilitateExista(this.pacient)){
     if (this.pacienttwo.intervale_indisponibilitate.length > 0 ){
       this.flag_sunt_orare_indisponibilitate = true
       this.flag_adauga_interval_nou = true
       this.intervale_indisponibilitate_dataSource = new MatTableDataSource(this.pacienttwo.intervale_indisponibilitate)
        if (!this.pacienttwo.orar_de_somn){
          this.pacienttwo.orar_de_somn = true
        }
      }
     else {
      // if (this.pacienttwo.intervale_indisponibilitate.length == 0 ){
        this.flag_adauga_primul_interval = true
        this.intervale_indisponibilitate_dataSource = new MatTableDataSource()
      // }
     }
   }
 }
 setLocalVariables(){
   console.log("Init orar de somn status ==>> ", this.init_pacientDetails.flag_orar_de_somn_status)
   if (this.checkIfIntervaleIndisponibilitateExista(this.pacient)){

     this.setFlagOrarDeSomnStatusToInitialState()
   }
  //  this.initDateContact()
 }
  async  ngOnInit() {
    // this.calitateService.getCalitatefromServer()
    // .subscribe((data) =>{
    //   if (data){
    //     this.calitate.push(data)
    //     console.log("Calitate din service ==>> ", this.calitate)
    //   }
    // })
    // this.pacienttwo = await this.pacientiService.getPacientifromServerTwoById(this.pacient.id)
    // .toPromise();




    // this._calitateservice.getCalitatePersoana().map(items => {
    //   console.log("getCalitatePersoana | from pacientdetails: => ",items);
    //   this.calitatepersoana = items;

    // });


      // DE STERS L8ER

      this.pacient = new Pacient()
      this.pacient.date_contact = new DateContact()
      this.pacient.date_contact.adresa = new Adresa()


      this.reprezentant_legal_nonuser_date_personale = new DatePersonaleCreate();
      this.reprezentant_legal_nonuser_date_contact = new DateContactCreate()
      this.reprezentant_legal_nonuser_date_contact.adresa = new AdresaCreate()
      this.persoana_contact_nonuser_date_personale = new DatePersonaleCreate()
      this.persoana_contact_nonuser_date_contact = new DateContactCreate()
      this.persoana_contact_nonuser_date_contact.adresa = new AdresaCreate()




      // this.pacientiService.getPacientifromServerTwoById(this.pacient.id)
      // .subscribe( (data) => {
      //   if (data){
      //     this.pacienttwo = data
      //     // console.log ("Data din get pacient info 2 ==>> ", this.pacienttwo)
      //     this.pacientiService.setPacientSelectat(this.pacienttwo)
      //     console.log ( " Get status CI pacient Service => ", this.pacientiService.getStatusCI())
      //   }
      // })



      this.pacientiService.getPacientifromServer()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          if (data){
            this.PacientList = data
            this.dataSource = new MatTableDataSource(this.PacientList);
            this.dataSource.paginator = this.paginator;
          }
        })
      console.log("calitateaPersoana local in Pacient Details: => ", this.calitatepersoana)
  }

  textOrDash(element){
    if (element){
      return element
    }
    return "-"
  }

  saveDatePersonaleRL(){

  }

  goToNextTabRL(){
    var complet:boolean = true
    var mesaj=" Toate campurile sunt obligatorii pentru a continua. Urmatoarele campuri sunt necompletate : "
    if (this.reprezentant_legal.datepersonale.nume == undefined){
      mesaj = mesaj + " Nume, "
      complet = false
    }
    if (this.reprezentant_legal.datepersonale.prenume == undefined){
      mesaj = mesaj + " Prenume, "
      complet = false
    }
    if (this.reprezentant_legal.datepersonale.cnp == undefined){
      mesaj = mesaj + " CNP, "
      complet = false
    }
    if (this.reprezentant_legal.datepersonale.sex == undefined){
      mesaj = mesaj + " Sex, "
      complet = false
    }
    if (!this.reprezentant_legal.calitateid){
      mesaj = mesaj + " Calitatea, "
      complet = false
    }
    if (this.reprezentant_legal.datepersonale.data_nasterii == undefined){
      mesaj = mesaj + " Data Nasterii "
      complet = false
    }
    if(!complet){
      this._snackBar.open(mesaj,"Am inteles")
    }
    if (this.selectedTabRL < 1 && complet){
      this.selectedTabRL = this.selectedTabRL + 1
    }
  }

  goToNextTabPC(){
    var complet:boolean = true
    var mesaj=" Toate campurile sunt obligatorii pentru a continua. Urmatoarele campuri sunt necompletate : "
    if (this.persoana_contact.datepersonale.nume == undefined){
      mesaj = mesaj + " Nume, "
      complet = false
    }
    if (this.persoana_contact.datepersonale.prenume == undefined){
      mesaj = mesaj + " Prenume, "
      complet = false
    }
    if (this.persoana_contact.datepersonale.cnp == undefined){
      mesaj = mesaj + " CNP, "
      complet = false
    }
    if (this.persoana_contact.datepersonale.sex == undefined){
      mesaj = mesaj + " Sex, "
      complet = false
    }
    if (!this.persoana_contact.calitateid){
      mesaj = mesaj + " Calitatea, "
      complet = false
    }
    if (this.persoana_contact.datepersonale.data_nasterii == undefined){
      mesaj = mesaj + " Data Nasterii "
      complet = false
    }
    if(!complet){
      this._snackBar.open(mesaj,"Am inteles")
    }
    if (this.selectedTabPC < 1 && complet){
      this.selectedTabPC = this.selectedTabPC + 1
    }
  }

  // async salveazaRLEditat(){
  //   // this.addnewperson = 1
  //   // this.flag_RL_in_editare = false
  //   console.log("RL EDITAT ==>> ", this.reprezentant_legal)
  //   console.log("RL EDITAT ==>> reprezentanti_legali == >> ", this.dateRLCardValues.reprezentanti_legali)
  //   var intermediar_RL : ReprezentantLegalUpdateInterface = new ReprezentantLegalUpdate()
  //   intermediar_RL.id = this.reprezentant_legal.id
  //   intermediar_RL.calitateid = this.reprezentant_legal.calitateid
  //   intermediar_RL.datepersonaleid = this.old_RL_element.datapersonaleid
  //   intermediar_RL.datecontactid = this.old_RL_element.datecontactid
  //   intermediar_RL.pacientid = this.pacienttwo.id

  //   var intermediar_RL_two : ReprezentantLegalCompletInterface = new ReprezentantLegalComplet()
  //   intermediar_RL_two.are_cont = false
  //   intermediar_RL_two.datecontact = new DateContactCreatePacientCreate()
  //   intermediar_RL_two.datecontact.adresa = new Adresa()
  //   intermediar_RL_two.datepersonale = new DatePersonaleCreate()
  //   intermediar_RL_two.id = intermediar_RL.id
  //   intermediar_RL_two.calitateid = intermediar_RL.calitateid
  //   intermediar_RL_two.datepersonaleid = intermediar_RL.datepersonaleid
  //   intermediar_RL_two.datecontactid = intermediar_RL.datecontactid
  //   intermediar_RL_two.pacientid = this.pacienttwo.id
  //   intermediar_RL_two.datecontact.id = intermediar_RL_two.datecontactid
  //   intermediar_RL_two.datecontact.email = this.reprezentant_legal.datecontact.email
  //   intermediar_RL_two.datecontact.telefon = this.reprezentant_legal.datecontact.telefon
  //   if (this.reprezentant_legal.calitateid != this.old_RL_element.calitateid){
  //     console.log("S-A MODIFICAT CALITATEA !!")
  //     var x = await this.asocPacientPersoanaContactService.updateAsocToServer(intermediar_RL).toPromise()
  //   }
  //   //verific daca s-au modificat datele personale
  //    if (this.pacientiService.checkIfSameObj(this.reprezentant_legal.datepersonale,this.old_RL_element.datepersonale)){
  //      console.log("Datele Personale au fost modificate")
  //      intermediar_RL_two.datepersonale = await ( this.datepersonaleService.updateDatePersonaleToServer(this.reprezentant_legal.datepersonale, this.old_RL_element.datepersonaleid))
  //       .toPromise()
  //       console.log(" Am primit raspunsul din server UPDATE DATE PERSONALE ==>> ", intermediar_RL_two)
  //       console.log(" Am primit raspunsul din server UPDATE DATE PERSONALE PACIENT 2 ==>> ", this.pacienttwo)
  //     //  this.pacientiService.setPacientSelectat(this.pacienttwo)
  //     }
  //     else {
  //       intermediar_RL_two.datepersonale.id = intermediar_RL_two.datepersonaleid
  //       intermediar_RL_two.datepersonale = this.reprezentant_legal.datepersonale
  //     }
  //     var intermediar_RL_datecontact : DateContact = new DateContact()
  //     intermediar_RL_datecontact.id = intermediar_RL_two.datecontactid
  //     //verific daca s-au modificat datele de contact
  //     if (this.pacientiService.checkIfSameObj(this.reprezentant_legal.datecontact,this.old_RL_element.datecontact)){
  //       console.log("Datele Personale au fost modificate")
  //       // verific daca s-a modificat adresa
  //      if (this.pacientiService.checkIfSameObj(this.old_RL_element.datecontact.adresa,this.reprezentant_legal.datecontact.adresa)){
  //        console.log("Adresa s-a modificat")
  //        intermediar_RL_datecontact.adresa = await this.adresaService.updateAdresaToServer(this.reprezentant_legal.datecontact.adresa,this.old_RL_element.datecontact.adresaid)
  //        .toPromise()

  //        intermediar_RL_datecontact.adresaid = intermediar_RL_datecontact.adresa.id

  //        console.log(" Am primit raspunsul din server UPDATE ADRESA ==>> ", intermediar_RL_datecontact)
  //        console.log(" Am primit raspunsul din server UPDATE ADRESA PACIENT 2 ==>> ", this.pacienttwo)
  //      }
  //      else{
  //       intermediar_RL_datecontact.adresa = this.reprezentant_legal.datecontact.adresa
  //      }
  //       if (this.reprezentant_legal.datecontact.email != this.old_RL_element.datecontact.email || this.reprezentant_legal.datecontact.telefon != this.old_RL_element.datecontact.telefon){
  //         console.log (" S-a modificat mail-ul sau telefonul !! ")
  //         var intermediar : DateContactInterface = new DateContact()
  //         intermediar.id = this.old_RL_element.datecontact.id
  //         intermediar.adresaid = this.old_RL_element.datecontact.adresaid
  //         intermediar.telefon = this.reprezentant_legal.datecontact.telefon
  //         intermediar.email = this.reprezentant_legal.datecontact.email
  //         var x = await this.datecontactService.updateDateContactTelefonEmail(intermediar)
  //         .toPromise()
  //         console.log(" Am primit raspunsul din server UPDATE datecontact ==>> ", x)
  //         console.log(" Am primit raspunsul din server UPDATE datecontact PACIENT 2 ==>> ", this.pacienttwo)
  //       }
  //   }
  //   console.log("INTERMEDIAR RL FINAL ====>>>> ", intermediar_RL_two)
  //   this.reprezentant_legal = intermediar_RL_two
  //   console.log("REPREZENTANT FINAL FINAL FINAL ====>>>> ", this.reprezentant_legal)

  // }

  getRLOriginalValue(id){
    for (let i of this.dateRLCardValues.reprezentanti_legali){
      if (i.id == id){
        return i
      }
    }
    return -1
  }
  getPCOriginalValue(id){
    for (let i of this.datePCCardValues.persoane_contact){
      if (i.id == id){
        return i
      }
    }
    return -1
  }

  changeRLEditatValueaOnDataSourceAfterSave(rl_editat){
    for (let i in this.dateRLCardValues.reprezentanti_legali){
      if ( this.dateRLCardValues.reprezentanti_legali[i].id == rl_editat.id){
        this.dateRLCardValues.reprezentanti_legali[i] = rl_editat
        this.dataSourceRL = new MatTableDataSource(this.dateRLCardValues.reprezentanti_legali)
      }
    }
  }

  changePCEditatValueaOnDataSourceAfterSave(pc_editat){
    for (let i in this.datePCCardValues.persoane_contact){
      if ( this.datePCCardValues.persoane_contact[i].id == pc_editat.id){
        this.datePCCardValues.persoane_contact[i] = pc_editat
        this.dataSourcePC = new MatTableDataSource(this.datePCCardValues.persoane_contact)
      }
    }
  }

  async salveazaRLEditat(){
    let x = (await this.pacientiService.updateRLInfo(this.getRLOriginalValue(this.reprezentant_legal.id), this.reprezentant_legal)).toPromise()
    console.log("XXX === >>> ", x)
    this.flag_RL_in_editare = false
    this.flag_adresa_RL_set = true
    this.flag_adresa_RL_add = true
    this.addnewperson = 1
    setTimeout( () => {
      this.asocPacientReprezentantLegalService.getRLFromServer(this.reprezentant_legal.id)
      .subscribe ( (data) => {
        if (data){
          console.log("DATA DIN ADD RL EDITAT TO SERVER ==>> ", data)
          this.changeRLEditatValueaOnDataSourceAfterSave(data)
        }
      })
    },1500)
  }

  async salveazaPCEditat(){
    console.log("SALVEAZA PC EDITAT")
    this.pacientiService.updatePCInfo(this.getPCOriginalValue(this.persoana_contact.id), this.persoana_contact)
    this.flag_PC_in_editare = false
    this.flag_adresa_PC_set = true
    this.flag_adresa_PC_add = true
    this.addnewcontactperson = 1
    setTimeout( () => {
      this.asocPacientPersoanaContactService.getPCFromServer(this.persoana_contact.id)
      .subscribe ( (data) => {
        if (data){
          console.log("DATA DIN ADD RL EDITAT TO SERVER ==>> ", data)
          this.changeRLEditatValueaOnDataSourceAfterSave(data)
        }
      })
    },1500)
  }

  anuleazaRLEditat(){
    this.flag_RL_in_editare = false
    this.flag_adresa_RL_set = true
    this.flag_adresa_RL_add = true
    this.addnewperson = 1
    // this.reInitRL()
  }

  anuleazaPCEditat(){
    this.flag_PC_in_editare = false
    this.flag_adresa_PC_set = true
    this.flag_adresa_PC_add = true
    this.addnewcontactperson = 1
    // this.reInitRL()
  }

  cancelPC(){
    this.flag_PC_in_editare = false
    this.flag_adresa_PC_set = true
    this.flag_adresa_PC_add = true
    this.addnewcontactperson = 1
  }

  cancelRL(){
    this.flag_RL_in_editare = false
    this.flag_adresa_RL_set = true
    this.flag_adresa_RL_add = true
    this.addnewperson = 1
  }
  reInitPC(){
    this.addnewcontactperson = 0
    this.adresaPC = new Adresa()
    this.telefon_PC = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
    this.email_PC= new FormControl('',[Validators.email])
    this.persoana_contact = new RLCreateFromBeneficiar()
    this.persoana_contact.datepersonale = new DatePersonaleCreate();
    this.persoana_contact.datecontact = new DateContactCreatePacientCreate()
    this.persoana_contact.datecontact.adresa = new Adresa()
    this.persoana_contact.pacientid = this.pacienttwo.id
    this.flag_adresa_PC_add = false;
    this.flag_telefon_email_PC_saved = false;
    this.selectedTabPC = 0
    this.flag_adresa_PC_set = false
    this.flag_reprezentant_legal_ci = false

  }

  reInitRL(){
    this.addnewperson = 0
    this.adresaRL = new Adresa()
    this.telefon_RL = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
    this.email_RL= new FormControl('',[Validators.email])
    this.reprezentant_legal = new RLCreateFromBeneficiar()
    this.reprezentant_legal.datepersonale = new DatePersonaleCreate();
    this.reprezentant_legal.datecontact = new DateContactCreatePacientCreate()
    this.reprezentant_legal.datecontact.adresa = new Adresa()
    this.reprezentant_legal.pacientid = this.pacienttwo.id
    this.flag_adresa_RL_add = false;
    this.flag_telefon_email_RL_saved = false;
    this.selectedTabRL = 0
    this.flag_adresa_RL_set = false
    this.flag_reprezentant_legal_ci = false
  }

  deletePC(){
    this.pacientiService.deletePCfromUser(this.todeletePC)
    .subscribe( (data) => {
      let intermediar = []
      for (let i of this.datePCCardValues.persoane_contact){
        console.log(" DELETE PC ALSO FROM PERSOANE_CONTACT LIST ==>> ", i)
        if (data.indexOf(i.id)<0){
          intermediar.push (i)
        }
      }
      // console.log(" INTERMEDIAR DELETE RL ==>> ",intermediar)
      this.datePCCardValues.persoane_contact = intermediar
      this.dataSourcePC = new MatTableDataSource(this.datePCCardValues.persoane_contact)
      this.todeletePC = []
      console.log(" DATA FROM DELETE PC  add new person ==>> ", this.addnewcontactperson)
      if (this.datePCCardValues.persoane_contact.length == 0){
        this.addnewcontactperson = 0
        this.flag_PC_gol = true
      }
    })
  }

  deleteRL(){
    this.pacientiService.deleteRLfromUser(this.todeleteRL)
    .subscribe( (data) => {
      let intermediar = []
      for (let i of this.dateRLCardValues.reprezentanti_legali){
        console.log(" DELETE RL ALSO FROM REPREZENTANTI_LEGALI LIST ==>> ", i)
        if (data.indexOf(i.id)<0){
          intermediar.push (i)
        }
      }
      // console.log(" INTERMEDIAR DELETE RL ==>> ",intermediar)
      this.dateRLCardValues.reprezentanti_legali = intermediar
      this.dataSourceRL = new MatTableDataSource(this.dateRLCardValues.reprezentanti_legali)
      this.todeleteRL = []
      console.log(" DATA FROM DELETE RL  add new person ==>> ", this.addnewperson)
      if (this.dateRLCardValues.reprezentanti_legali.length == 0){
        this.addnewperson = 0
        this.flag_RL_gol = true
      }
    })
  }

  marktodeletePC(element){
    var index = this.todeletePC.indexOf(element.id)
    if ( index >= 0){
      this.todeletePC.splice (index,1)
    }
    else{
      this.todeletePC.push(element.id)
    }
    console.log(" PC to delete ==>> ",this.todeletePC)
  }

  marktodeleteRL(element){
    var index = this.todeleteRL.indexOf(element.id)
    if ( index >= 0){
      this.todeleteRL.splice (index,1)
    }
    else{
      this.todeleteRL.push(element.id)
    }
    console.log(" RL to delete ==>> ",this.todeleteRL)
  }

  checkPCinListaDeSters (element){
    var index = this.todeletePC.indexOf(element.id)
    if ( index >= 0){
      return true
    }
    return false
  }

  checkRLinListaDeSters (element){
    var index = this.todeleteRL.indexOf(element.id)
    if ( index >= 0){
      return true
    }
    return false
  }

  editeazaPersoanaContact(element){
    this.flag_PC_in_editare = true
    this.old_PC_element = JSON.parse(JSON.stringify(element))
    this.reInitPC()
    this.persoana_contact.datepersonale = JSON.parse(JSON.stringify(element.datepersonale))
    this.persoana_contact.datecontact = JSON.parse(JSON.stringify(element.datecontact))
    this.persoana_contact.calitateid = JSON.parse(JSON.stringify(element.calitateid))
    this.persoana_contact.id = element.id
    this.flag_adresa_PC_set = false
    this.flag_adresa_PC_add = false
    if(this.persoana_contact.datepersonale.numar_carte_identitate != undefined || this.persoana_contact.datepersonale.serie_carte_identitate != undefined){
      this.flag_persoana_contact_ci = true
    }

    if(this.persoana_contact.datecontact.telefon != undefined){
      this.telefon_PC = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
      this.telefon_PC.setValue(this.persoana_contact.datecontact.telefon)
      this.old_telefon_PC = this.persoana_contact.datecontact.telefon
      // this.old_RL_element = element
    }
    if(this.persoana_contact.datecontact.email != undefined ){
      this.email_PC= new FormControl('',[Validators.email])
      this.email_PC.setValue(this.persoana_contact.datecontact.email)
      if (this.flag_adresa_PC_set == false){
        this.flag_adresa_PC_set = true
        if (!this.flag_telefon_email_PC_saved){
          this.flag_telefon_email_PC_saved = true
          this.telefon_PC.disable()
          this.email_PC.disable()
        }
      }
      if (this.persoana_contact.datecontact.email == undefined && this.persoana_contact.datecontact.telefon == undefined){
        console.log("STATEMENT TRUE !!")
        this.email_PC= new FormControl('',[Validators.email])
        this.telefon_PC = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
        if (this.flag_telefon_email_PC_saved){
          this.flag_telefon_email_PC_saved = false
        }
        this.flag_PC_in_editare = false
        this.email_PC.enable()
        this.telefon_PC.enable()
      }
    }
    if(this.persoana_contact.datecontact.adresa != undefined && this.pacientiService.checkObjectHasAnyValueDefined(this.persoana_contact.datecontact.adresa)){
      this.flag_adresa_PC_set = true
      this.flag_adresa_PC_add = true
      console.log(Object.keys(this.persoana_contact.datecontact.adresa))
      console.log(this.persoana_contact.datecontact.adresa)
      console.log(Object.keys(this.adresaPC))
      console.log(this.adresaPC)
      for (var q of Object.keys(this.persoana_contact.datecontact.adresa)){
        if (this.persoana_contact.datecontact.adresa[q] != undefined){
          this.adresaPC[q] = this.persoana_contact.datecontact.adresa[q]
        }
      }
    }

  }
  editeazaReprezentantLegal(element){
    this.flag_RL_in_editare = true
    this.old_RL_element = JSON.parse(JSON.stringify(element))
    // this.old_RL_element = JSON.stringify(element)
    // console.log("NEW ==>> RL == >> ", element)
    // console.log("OLD ==>> RL == >> ", this.old_RL_element)

    this.reInitRL()

    this.reprezentant_legal.datepersonale = JSON.parse(JSON.stringify(element.datepersonale))
    this.reprezentant_legal.datecontact = JSON.parse(JSON.stringify(element.datecontact))
    this.reprezentant_legal.calitateid = JSON.parse(JSON.stringify(element.calitateid))
    this.reprezentant_legal.id = element.id
    this.flag_adresa_RL_set = false
    this.flag_adresa_RL_add = false
    if(this.reprezentant_legal.datepersonale.numar_carte_identitate != undefined || this.reprezentant_legal.datepersonale.serie_carte_identitate != undefined){
      this.flag_reprezentant_legal_ci = true
    }
    if(this.reprezentant_legal.datecontact.telefon != undefined){
      this.telefon_RL = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
      this.telefon_RL.setValue(this.reprezentant_legal.datecontact.telefon)
      this.old_telefon_RL = this.reprezentant_legal.datecontact.telefon
      // this.old_RL_element = element
    }
    if(this.reprezentant_legal.datecontact.email != undefined ){
      this.email_RL= new FormControl('',[Validators.email])
      this.email_RL.setValue(this.reprezentant_legal.datecontact.email)
      if (this.flag_adresa_RL_set == false){
        this.flag_adresa_RL_set = true
        if (!this.flag_telefon_email_RL_saved){
          this.flag_telefon_email_RL_saved = true
          this.telefon_RL.disable()
          this.email_RL.disable()
        }
      }
      if (this.reprezentant_legal.datecontact.email == undefined && this.reprezentant_legal.datecontact.telefon == undefined){
        console.log("STATEMENT TRUE !!")
        this.email_RL= new FormControl('',[Validators.email])
        this.telefon_RL = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
        if (this.flag_telefon_email_RL_saved){
          this.flag_telefon_email_RL_saved = false
        }
        this.flag_RL_in_editare = false
        this.email_RL.enable()
        this.telefon_RL.enable()
      }
    }

    if(this.reprezentant_legal.datecontact.adresa != undefined && this.pacientiService.checkObjectHasAnyValueDefined(this.reprezentant_legal.datecontact.adresa)){
      this.flag_adresa_RL_set = true
      this.flag_adresa_RL_add = true
      console.log(Object.keys(this.reprezentant_legal.datecontact.adresa))
      console.log(this.reprezentant_legal.datecontact.adresa)
      console.log(Object.keys(this.adresaRL))
      console.log(this.adresaRL)
      for (var q of Object.keys(this.reprezentant_legal.datecontact.adresa)){
        if (this.reprezentant_legal.datecontact.adresa[q] != undefined){
          this.adresaRL[q] = this.reprezentant_legal.datecontact.adresa[q]
        }
      }
    }
  }

  getStatusCIReprezentantLegal(){
    if(this.flag_reprezentant_legal_ci){
      return true
    }
    return false
  }

  getStatusCIPersoanaContact(){
    if(this.flag_persoana_contact_ci){
      return true
    }
    return false
  }
  RLFaraAdresa(){
    this.flag_RL_fara_adresa = !this.flag_RL_fara_adresa
  }

  PCFaraAdresa(){
    this.flag_PC_fara_adresa = !this.flag_PC_fara_adresa
  }


  copiazaAdresaBeneficiarRL(){
    this.adresaRL = this.dateContactDialogCardValues.adresa
    this.salveazaAdresaRL()
    this.flag_adresa_RL_add = true
    this.flag_adresa_RL_set = true
  }

  copiazaAdresaBeneficiarPC(){
    this.adresaPC = this.dateContactDialogCardValues.adresa
    this.salveazaAdresaPC()
    this.flag_adresa_PC_add = true
    this.flag_adresa_PC_set = true
  }

  stergeAdresaRL(){
    this.adresaRL = new Adresa()
    this.reprezentant_legal.datecontact.adresa = null
    // this.reprezentant_legal.datecontact.adresaid = null
    this.flag_adresa_RL_add = false
    this.flag_adresa_RL_set = false
  }

  stergeAdresaPC(){
    this.adresaPC = new Adresa()
    this.persoana_contact.datecontact.adresa = null
    // this.reprezentant_legal.datecontact.adresaid = null
    this.flag_adresa_PC_add = false
    this.flag_adresa_PC_set = false
  }

  anuleazaAdresaRL(){
    this.flag_adresa_RL_add = false
  }

  anuleazaAdresaPC(){
    this.flag_adresa_PC_add = false
  }

  editeazaAdresaRL(){
    this.flag_adresa_RL_set = false
  }

  editeazaAdresaPC(){
    this.flag_adresa_PC_set = false
  }

  salveazaAdresaPC(){
    if (this.persoana_contact.datecontact.adresa == undefined){
      console.log("Persoana CONTACT fara adresa !! ")
      this.persoana_contact.datecontact.adresa = new AdresaCreate();
    }
    if (this.adresaPC.judet == undefined || this.adresaPC.localitate == undefined || this.adresaPC.strada == undefined ){
      this._snackBar.open("Completeaza Judetul, Localitatea si Strada pentru a putea salva","OK")
    }
    else{
      if (this.adresaPC.judet != undefined){
        this.persoana_contact.datecontact.adresa.judet = this.adresaPC.judet
      }
      if (this.adresaPC.localitate != undefined){
        this.persoana_contact.datecontact.adresa.localitate = this.adresaPC.localitate
      }
      if (this.adresaPC.strada != undefined){
        this.persoana_contact.datecontact.adresa.strada = this.adresaPC.strada
      }
      if (this.adresaPC.numar != undefined){
        this.persoana_contact.datecontact.adresa.numar = this.adresaPC.numar
      }
      if (this.adresaPC.bloc != undefined){
        this.persoana_contact.datecontact.adresa.bloc = this.adresaPC.bloc
      }
      if (this.adresaPC.etaj != undefined){
        this.persoana_contact.datecontact.adresa.etaj = this.adresaPC.etaj
      }
      if (this.adresaPC.ap != undefined){
        this.persoana_contact.datecontact.adresa.ap = this.adresaPC.ap
      }
      this.flag_adresa_PC_set = true
    }
    // console.log("Save tel 1 == ",this.pacienttwo.reprezentanti_legali.find( x => x.id == this.old_RL_element.id))
    // console.log("Salveaza Adresa RL == reprezentant legal ==>> ",this.persoana_contact.datecontact)
  }

  salveazaAdresaRL(){
    // console.log("Salveaza Adresa RL ==>> ",this.adresaRL)
    // console.log("Salveaza OLD Adresa RL  ==>> ",this.old_RL_element)
    // console.log("Salveaza Adresa -> pacient service   ==>> ",this.pacientiService.pacient_selectat)
    if (this.reprezentant_legal.datecontact.adresa == undefined){
      console.log("Reprezentant Legal fara adresa !! ")
      this.reprezentant_legal.datecontact.adresa = new AdresaCreate();
    }
    if (this.adresaRL.judet == undefined || this.adresaRL.localitate == undefined || this.adresaRL.strada == undefined ){
      this._snackBar.open("Completeaza Judetul, Localitatea si Strada pentru a putea salva","OK")
    }
    else{
      if (this.adresaRL.judet != undefined){
        this.reprezentant_legal.datecontact.adresa.judet = this.adresaRL.judet
      }
      if (this.adresaRL.localitate != undefined){
        this.reprezentant_legal.datecontact.adresa.localitate = this.adresaRL.localitate
      }
      if (this.adresaRL.strada != undefined){
        this.reprezentant_legal.datecontact.adresa.strada = this.adresaRL.strada
      }
      if (this.adresaRL.numar != undefined){
        this.reprezentant_legal.datecontact.adresa.numar = this.adresaRL.numar
      }
      if (this.adresaRL.bloc != undefined){
        this.reprezentant_legal.datecontact.adresa.bloc = this.adresaRL.bloc
      }
      if (this.adresaRL.etaj != undefined){
        this.reprezentant_legal.datecontact.adresa.etaj = this.adresaRL.etaj
      }
      if (this.adresaRL.ap != undefined){
        this.reprezentant_legal.datecontact.adresa.ap = this.adresaRL.ap
      }
      this.flag_adresa_RL_set = true
    }
    // console.log("Save tel 1 == ",this.pacienttwo.reprezentanti_legali.find( x => x.id == this.old_RL_element.id))
    // console.log("Salveaza Adresa RL == reprezentant legal ==>> ",this.reprezentant_legal.datecontact)
  }

  editeazaTelefonEmailPC(){
    this.flag_telefon_email_PC_saved = false
    this.email_PC.enable()
    this.telefon_PC.enable()
  }

 editeazaTelefonEmailRL(){
    this.flag_telefon_email_RL_saved = false
    this.email_RL.enable()
    this.telefon_RL.enable()
  }

  checkRLTelefonOldValue(value){
    if (value == this.old_telefon_RL){
      return true
    }
    return false
  }

  checkPCTelefonOldValue(value){
    if (value == this.old_telefon_PC){
      return true
    }
    return false
  }

  checkEmailOldValue(value){
    return this.old_RL_element.datecontact.email == this.email_RL.value
  }
  checkPCEmailOldValue(value){
    return this.old_PC_element.datecontact.email == this.email_PC.value
  }

  saveTelefonEmailPCEditat(){
    this.email_PC.disable()
    this.telefon_PC.disable()

    if (!this.checkPCTelefonOldValue(this.telefon_PC.value)){
      this.persoana_contact.datecontact.telefon = this.telefon_PC.value
    }

    if(!this.checkPCEmailOldValue(this.email_PC.value)){
      this.persoana_contact.datecontact.email = this.email_PC.value
      // console.log("Save tel 1 == ",this.pacienttwo.reprezentanti_legali.find( x => x.id == this.old_PC_element.id))

      // this.pacienttwo.reprezentanti_legali[this.pacienttwo.reprezentanti_legali.indexOf(this.old_PC_element)].datecontact.email = this.reprezentant_legal.datecontact.email
    }
    this.flag_telefon_email_PC_saved = true
  }

  saveTelefonEmailRLEditat(){
    this.email_RL.disable()
    this.telefon_RL.disable()

    if (!this.checkRLTelefonOldValue(this.telefon_RL.value)){
      this.reprezentant_legal.datecontact.telefon = this.telefon_RL.value
    }

    if(!this.checkEmailOldValue(this.email_RL.value)){
      this.reprezentant_legal.datecontact.email = this.email_RL.value
      // console.log("Save tel 1 == ",this.pacienttwo.reprezentanti_legali.find( x => x.id == this.old_RL_element.id))

      // this.pacienttwo.reprezentanti_legali[this.pacienttwo.reprezentanti_legali.indexOf(this.old_RL_element)].datecontact.email = this.reprezentant_legal.datecontact.email
    }
    this.flag_telefon_email_RL_saved = true
  }

  saveTelefonEmailPC(){
    this.email_PC.disable()
    this.telefon_PC.disable()

    if (this.telefon_PC.value != '') {

      this.persoana_contact.datecontact.telefon = this.telefon_PC.value

    }
    if (this.email_PC.value != ''){
      this.persoana_contact.datecontact.email = this.email_PC.value
    }
    this.flag_telefon_email_PC_saved = true
  }

  saveTelefonEmailRL(){
    this.email_RL.disable()
    this.telefon_RL.disable()

    if (this.telefon_RL.value != '') {

      this.reprezentant_legal.datecontact.telefon = this.telefon_RL.value

    }
    if (this.email_RL.value != ''){
      this.reprezentant_legal.datecontact.email = this.email_RL.value
    }
    this.flag_telefon_email_RL_saved = true
  }

  adaugaAdresaRL(){
    this.flag_adresa_RL_add = true
    if (!this.adresaRL){
      this.adresaRL = new AdresaCreate()
    }
    if (this.flag_adresa_RL_set){
      this.flag_adresa_RL_set = false
    }
  }

  adaugaAdresaPC(){
    this.flag_adresa_PC_add = true
    if (!this.adresaPC){
      this.adresaPC = new AdresaCreate()
    }
    if (this.flag_adresa_PC_set){
      this.flag_adresa_PC_set = false
    }
  }

changedCIRL(){
  if (!this.flag_reprezentant_legal_ci && ((this.reprezentant_legal.datepersonale.serie_carte_identitate) || (this.reprezentant_legal.datepersonale.numar_carte_identitate))){
    if(this.reprezentant_legal.datepersonale.serie_carte_identitate){
      this.reprezentant_legal.datepersonale.serie_carte_identitate = null
    }
    if(this.reprezentant_legal.datepersonale.numar_carte_identitate){
      this.reprezentant_legal.datepersonale.numar_carte_identitate = null
    }
  }
}
changedCIPC(){
  if (!this.flag_persoana_contact_ci && ((this.persoana_contact.datepersonale.serie_carte_identitate) || (this.persoana_contact.datepersonale.numar_carte_identitate))){
    if(this.persoana_contact.datepersonale.serie_carte_identitate){
      this.persoana_contact.datepersonale.serie_carte_identitate = null
    }
    if(this.persoana_contact.datepersonale.numar_carte_identitate){
      this.persoana_contact.datepersonale.numar_carte_identitate = null
    }
  }
}
  initDateContact(){
    var telefon_email = false
    if (this.pacienttwo.date_contact.telefon)
    {
      this.telefon_beneficiar.setValue(this.pacienttwo.date_contact.telefon)
      telefon_email = !telefon_email ? true : telefon_email
    }
    if (this.pacienttwo.date_contact.email)
    {
      this.email_beneficiar.setValue(this.pacienttwo.date_contact.email)
      telefon_email = !telefon_email ? true : telefon_email
    }
    if (telefon_email){
      this.flag_telefon_email_saved = true
      this.telefon_beneficiar.disable()
      this.email_beneficiar.disable()
    }
  }
  returnCaractereTelefon(value){
    return(value.toString().length)
  }

  saveTelefonEmailBeneficiar(){
    this.email_beneficiar.disable()
    this.telefon_beneficiar.disable()
    // if (this.email_beneficiar.value != ''){
      this.dateContactDialogCardValues.email = this.email_beneficiar.value
    // }
    // if (this.telefon_beneficiar.value != ''){
      this.dateContactDialogCardValues.telefon = this.telefon_beneficiar.value
    // }
    this.flag_telefon_email_saved = true

    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // DE REVENIT AICI
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!

    // Trebuie verificat la sfarsit daca Telefon / Email s-a modificat si salvat atunci

    // this.pacientiService.saveTelefonEmail(this.pacienttwo.date_contact)
  }

  editeazaTelefonEmailBeneficiar(){
    this.flag_telefon_email_saved = false
    this.email_beneficiar.enable()
    this.telefon_beneficiar.enable()
  }

  checkAndSetInitialIntervaleFlags(){
    // console.log("CHECK SET INTERVAL INITIAL ==>> ",this.pacienttwo)
    // console.log("CHECK SET INTERVAL INITIAL flag_orar_de_somn_status ==>> ",this.init_pacientDetails.flag_orar_de_somn_status)

    this.flag_adauga_primul_interval = this.pacientiService.init_pacientDetails.flag_adauga_primul_interval
    this.flag_sunt_orare_indisponibilitate = this.pacientiService.init_pacientDetails.flag_orar_de_somn_status
    this.intervale_indisponibilitate_dataSource = new MatTableDataSource(this.pacienttwo.intervale_indisponibilitate)


  }

  changed(){

    // console.log ("CHANGE CARDE IDENTITATE ==>> ",this.datePersonaleDialogCardValues.carte_identitate_check)
    // if(!this.datePersonaleDialogCardValues.carte_identitate_check){
    //   if (this.datePersonaleDialogCardValues.numar_carte_identitate){
    //     console.log("NR CARTE IDENTITATE ==>> ", this.datePersonaleDialogCardValues.numar_carte_identitate)
    //     this.datePersonaleDialogCardValues.numar_carte_identitate = null
    //     console.log("NR CARTE IDENTITATE ==>> ", this.datePersonaleDialogCardValues.numar_carte_identitate)
    //   }
    //   if (this.datePersonaleDialogCardValues.serie_carte_identitate){
    //     console.log("SERIE CARTE IDENTITATE ==>> ", this.datePersonaleDialogCardValues.serie_carte_identitate)
    //     this.datePersonaleDialogCardValues.serie_carte_identitate = null
    //     console.log("SERIE CARTE IDENTITATE ==>> ", this.datePersonaleDialogCardValues.serie_carte_identitate)
    //   }
    // }
  }

  changed_dependenta(){

  }

  changed_OrarIndisponibilitate(){
    console.log(" ORARE DE SOMN VALUE ==>> ", this.datePersonaleDialogCardValues.orar_de_somn)
    if(this.datePersonaleDialogCardValues.orar_de_somn){
      console.log(" ORAR BECOME TRUE ")
      this.changeFlagOrarDeSomnStatusToTrue()
    }
    else{
      console.log(" ORAR BECOME FALSE ")

    }
    if (this.pacienttwo.orar_de_somn){
      console.log (" orar_de_somn === >>> === !!! -- ",this.pacient.orar_de_somn)
      // changed from false --> check if flag intervale_indisponibilitate exista
      if (this.pacienttwo.intervale_indisponibilitate){
        if(this.pacienttwo.intervale_indisponibilitate.length == 0){
          // this.flag_adauga_interval_nou = true

          // this.flag_show_detalii_interval = true
        }
        else{
          if (this.pacienttwo.intervale_indisponibilitate.length > 0){
            // this.flag_adauga_primul_interval = false
            // this.flag_adauga_interval_nou = true
          }
        }
      }
      else{
        // this.pacienttwo.intervale_indisponibilitate = []
        // this.flag_adauga_primul_interval = false
        // this.flag_adauga_interval_nou = true
      }
    }
    // else{
    //   if (this.flag_adauga_primul_interval){
    //     this.flag_adauga_primul_interval = false
    //   }
    //   if (this.flag_adauga_interval_nou){
    //     this.flag_adauga_interval_nou = false
    //   }
    //   if (this.flag_salveaza_interval_nou){
    //     this.flag_salveaza_interval_nou = false
    //   }
    //   if (this.flag_show_detalii_interval){
    //     this.flag_show_detalii_interval = false
    //   }
    //   if (this.flag_interval_in_editare){
    //     this.flag_interval_in_editare = false
    //   }
    //   if (this.flag_sunt_orare_indisponibilitate){
    //     this.flag_sunt_orare_indisponibilitate = false
    //   }
    //   if(this.pacienttwo.intervale_indisponibilitate){
    //     if(this.pacienttwo.intervale_indisponibilitate.length > 0){
    //       this.pacienttwo.intervale_indisponibilitate = []
    //     }
    //   }
    // }

  }

  checkOreIntervalIndisponibilitate(start,end){
    if (start === end){
      console.log( " Interval start egal cu end !! ")
      return 0
    }
    else {
      if (start > end){
        return -1
      }
      else{
        if(this.datePersonaleDialogCardValues.intervale_indisponibilitate.data.length > 0){
          if ( this.verificaSuprapunereInterval(start,end) ) {
            return -2
          }
        }
      }
    }
    return 1
  }
  returnSuntEroriIntervalIndisponibilitate(start,end){
    var checkOre = this.checkOreIntervalIndisponibilitate(start,end)
    if ( checkOre == 0){
      console.log( " Interval start egal cu end !! ")
      this._snackBar.open("Intervalul este gol. Ora de inceput este egala cu ora de final. ","Editeaza Intervalul")
      return 0
    }
    else{
      if ( checkOre == -1 ){
        this._snackBar.open("Sfarsitul intervalului este inaintea orei de start ","Editeaza Intervalul")
        return 0
      }
      else {
        if ( checkOre == -2 ){
          this._snackBar.open("Intervalul se suprapune cu celelalte intervale salvate ","Editeaza Intervalul")
          return 0
        }
      }
    }
    return 1
  }

  copyFromTwoObjects(copy_into, copy_from){
    for (var q of Object.keys(copy_into)){
      if ( Object.keys(copy_from).indexOf(q) >= 0 ) {
        if (copy_from[q]){
          copy_into[q] = copy_from[q]
        }
      }
    }
    return copy_into
  }

  addIntervaleIndisponibilitateToPacientList(intervale_indisponibilitate, id, lista_de_adaugat){
    console.log("Add Intervale IndisponibilitateToPacientList id ==>> ", id)
    console.log("Add Intervale IndisponibilitateToPacientList intervale_indisponibilitate ==>> ", intervale_indisponibilitate)
    var intermediar_interval : IntervalIndisponibilitateInterface = new IntervalIndisponibilitate()
    intermediar_interval.id = id
    for (var q of Object.keys(intermediar_interval)){
      if ( Object.keys(intervale_indisponibilitate).indexOf(q) >= 0 ) {
        if (intervale_indisponibilitate[q]){
          intermediar_interval[q] = intervale_indisponibilitate[q]
        }
      }
    }
    lista_de_adaugat.push(intermediar_interval)
    this.datePersonaleDialogCardValues.intervale_indisponibilitate = new MatTableDataSource( lista_de_adaugat)
  }

  adaugaIntervaleIndisponibilitate(){
    var intermediar_interval_ora_start = this.intervalService.returnStringDate(this.interval_start)
    var intermediar_interval_ora_end = this.intervalService.returnStringDate(this.interval_end)
    var checkEroriOre = this.returnSuntEroriIntervalIndisponibilitate(intermediar_interval_ora_start,intermediar_interval_ora_end)

    if (checkEroriOre){
      var intermediar_interval_indisponibilitate : IntervalIndisponibilitateCreateFromPacientInterface = new IntervalIndisponibilitateCreateFromPacient()
      intermediar_interval_indisponibilitate.detalii = this.interval_indisponibilitate_nou.detalii
      intermediar_interval_indisponibilitate.id_pacient = this.pacienttwo.id
      intermediar_interval_indisponibilitate.ora_start = intermediar_interval_ora_start
      intermediar_interval_indisponibilitate.ora_end = intermediar_interval_ora_end
      console.log("Intermediar Interval Indisponibilitate ==>> ", intermediar_interval_indisponibilitate)
      this.intervalService.adaugaIntervalIndisponibilitate(intermediar_interval_indisponibilitate)
      .subscribe ( ( data ) => {
        if (data){
          console.log ("Interval from server == >> ", data)
          this.addIntervaleIndisponibilitateToPacientList(intermediar_interval_indisponibilitate,data, this.datePersonaleDialogCardValues.intervale_indisponibilitate.data)
          this.interval_start = new Date()
          this.interval_end = new Date()
          if (this.flag_camp_detalii_activ){
            this.flag_camp_detalii_activ = false
          }
          if (this.flag_adauga_primul_interval ){
            this.flag_adauga_primul_interval = false
          }
          if (!this.flag_adauga_interval_nou){
            this.flag_adauga_interval_nou = true
          }
          if(this.flag_salveaza_interval_nou){
            this.flag_salveaza_interval_nou = false
          }
          this.flag_show_detalii_interval = false
          // this.flag_salveaza_interval_nou = false
          if (!this.flag_sunt_orare_indisponibilitate && this.datePersonaleDialogCardValues.intervale_indisponibilitate.data.length>0){
            this.flag_sunt_orare_indisponibilitate = true
          }
        }
      })
    }
  }

  verificaSuprapunereInterval(ora_start, ora_end){
    var pozitie_minim = -1
    for(var i = 0; i < this.datePersonaleDialogCardValues.intervale_indisponibilitate.data.length;i++ ){
      console.log("Pacienttwo.intervale orare de I ==>> ", i, " == >> ", this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i])
      if (ora_start >= this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_start && ora_start < this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_end){
        console.log ( " Suprapunere Caz 1 - Start mai mare sau egal cu start si mai mic decat end ")
        // this._snackBar.open("Intervalele se suprapun. Intervalul: "+this.interval_start.getHours()+":"+this.interval_start.getMinutes()+" <=> "+this.interval_end.getHours()+" : "+this.interval_end.getMinutes()+" Se suprapune cu intervalul: "+this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_start+" <=> "+this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_end+" !!","Editeaza Intervalul")
        if (ora_end <= this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_end){
          this._snackBar.open("Intervalele se suprapun. Intervalul: "+ora_start+" <=> "+ora_end+" Se suprapune cu intervalul: "+this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_start+" <=> "+this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_end+". Intervalul setat este inclus intr-un interval deja setat. Editeaza intervalul salvat cu detalii despre acest nou interval.","Editeaza Intervalul")
          return 1
        }
        if(ora_end > this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_end){
          this._snackBar.open("Intervalele se suprapun. Intervalul: "+ora_start+" <=> "+ora_end+" Se suprapune cu intervalul: "+this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_start+" <=> "+this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_end+". Editeaza intervalul deja salvat: "+this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_start+" <=> "+this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_end+" prelungind pana la "+ora_end,"Editeaza Intervalul")
          return 1
        }
      }
      else{
        if(ora_end > this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_start && ora_end <= this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_end ){
          console.log ( " Suprapunere Caz 2 - End mai mare decat start si mai mic sau egal cu end ")
          return 2
        }
      }
      if ( this.datePersonaleDialogCardValues.intervale_indisponibilitate.data[i].ora_start > ora_start ){
        // minim = this.pacient.intervale_indisponibilitate[i].ora_start
        if (pozitie_minim < 0){
          pozitie_minim = i
          console.log ( " Pozitie minim din if = ", pozitie_minim)
        }
      }
    }
    console.log ( " Pozitie minim final = ", pozitie_minim)
    this.intermediar_poztitie_minim = pozitie_minim
    return 0
  }

  adaugaIntervalNou(){
    this.flag_show_detalii_interval = true
    this.flag_adauga_interval_nou = false
    this.flag_salveaza_interval_nou = true
    this.exista_orar_de_somn = true
  }

  salveazaIntervalNou(){
    this.adaugaIntervaleIndisponibilitate()
  }

  salveazaIntervalulEditat(){
    var intermediar : IntervalIndisponibilitateInterface = new IntervalIndisponibilitate()
    intermediar.id = this.pacienttwo.intervale_indisponibilitate[this.intermediar_index_interval_editat].id
    intermediar.id_pacient = this.pacienttwo.intervale_indisponibilitate[this.intermediar_index_interval_editat].id_pacient
    var modificari = false
    if (this.pacienttwo.intervale_indisponibilitate[this.intermediar_index_interval_editat].ora_start != this.intervalService.returnStringDate(this.interval_start)){
      intermediar.ora_start = this.intervalService.returnStringDate(this.interval_start)
      if (!modificari){
        modificari = true
      }
    }
    else{
      intermediar.ora_start = this.pacienttwo.intervale_indisponibilitate[this.intermediar_index_interval_editat].ora_start
    }
    if (this.pacienttwo.intervale_indisponibilitate[this.intermediar_index_interval_editat].ora_end != this.intervalService.returnStringDate(this.interval_end)){
      intermediar.ora_end = this.intervalService.returnStringDate(this.interval_end)
      if (!modificari){
        modificari = true
      }
    }
    else{
      intermediar.ora_end = this.pacienttwo.intervale_indisponibilitate[this.intermediar_index_interval_editat].ora_end
    }
    if (this.pacienttwo.intervale_indisponibilitate[this.intermediar_index_interval_editat].detalii != this.intermediar_interval_edit_detalii){
      intermediar.detalii = this.intermediar_interval_edit_detalii
      if (!modificari){
        modificari = true
      }
    }
    else{
      intermediar.detalii = this.pacienttwo.intervale_indisponibilitate[this.intermediar_index_interval_editat].detalii
    }
    if (modificari){
      this.pacienttwo.intervale_indisponibilitate[this.intermediar_index_interval_editat].ora_start = intermediar.ora_start
      this.pacienttwo.intervale_indisponibilitate[this.intermediar_index_interval_editat].ora_end = intermediar.ora_end
      this.pacienttwo.intervale_indisponibilitate[this.intermediar_index_interval_editat].detalii = intermediar.detalii
      this.intervalService.updateIntervalIndisponibilitate(intermediar)
      .subscribe ( (data) => {
        if (data){
          console.log("data din Update Interval ==>> ", data)
        }
      })
      this.intervale_indisponibilitate_dataSource = new MatTableDataSource( this.pacienttwo.intervale_indisponibilitate )
      console.log(" Interval Editat Salvat ==>> ", modificari)
    }
    else {
      console.log(" Nu sunt modificari de Salvat ==>> ")
    }
    this.flag_interval_in_editare = false
    this.flag_show_detalii_interval = false
    this.flag_adauga_interval_nou = true
    this.intermediar_index_interval_editat = -1
    this.intermediar_interval_edit_detalii = ''
    this.reInitIntervalStartEnd()
    if (this.flag_camp_detalii_activ){
      this.flag_camp_detalii_activ = !this.flag_camp_detalii_activ
    }
    this._snackBar.open("Intervalul a fost salvat cu succes", "Inchide")
  }

  anuleazaAddIntervaleIndisponibilitate(){
    this.flag_show_detalii_interval = false
    if(this.flag_interval_in_editare){
      this.flag_interval_in_editare = false
      this.flag_salveaza_interval_editat = false
      this.flag_adauga_interval_nou = true
    }
    else{
      if(this.pacienttwo.intervale_indisponibilitate.length>0){
        this.flag_adauga_interval_nou = true
        this.flag_salveaza_interval_nou = false
      }
      else{
        if(this.pacienttwo.intervale_indisponibilitate.length == 0 ){
          this.flag_adauga_primul_interval = true
          this.flag_salveaza_interval_nou = false
        }
      }
    }
  }

  adaugaDetaliiIntervalIndisponibilitate(){
    if (!this.flag_camp_detalii_activ){
      this.flag_camp_detalii_activ = true
    }
  }
  reInitIntervalStartEnd(ora_start?:string, ora_end?:string){
    if(ora_start){
      this.interval_start = this.intervalService.returnDateString(ora_start)
      if(ora_end){
        this.interval_end = this.intervalService.returnDateString(ora_end)
      }
      else{
        this.interval_end = new Date()
        this.interval_end.setMinutes(this.interval_start.getMinutes() + 30)
      }
    }
    else{
      this.interval_start = new Date(new Date().setHours(8,0,0))
      this.interval_end = new Date(new Date().setHours(8,30,0))
    }
  }

  editeazaIntervalIndisponibilitate(element){
    this.flag_interval_in_editare = true
    this.flag_adauga_interval_nou = false

    if (this.intermediar_index_interval_editat < 0){
      this.intermediar_index_interval_editat = this.pacienttwo.intervale_indisponibilitate.indexOf(element)
      this.flag_show_detalii_interval = true
      this.reInitIntervalStartEnd(element.ora_start, element.ora_end)
    }

    else{
      console.log (" Eroare -> Intermediar index interval editat nu este -1 ")
    }

    if (element.detalii != undefined){
      this.intermediar_interval_edit_detalii = element.detalii
      this.flag_camp_detalii_activ = true
    }
    if (!this.exista_orar_de_somn){
      this.exista_orar_de_somn = true
    }
  }

  markintervaltodelete(element){
    var index = this.intervaleindisponibilitatetodelete.indexOf(element.id)
    if ( index >= 0){
      this.intervaleindisponibilitatetodelete.splice (index,1)
    }
    else{
      this.intervaleindisponibilitatetodelete.push(element.id)
    }
    console.log(" Intervale to delete ==>> ",this.intervaleindisponibilitatetodelete)
  }

  returnStatusIntervalMarcat(element){
    var index = this.intervaleindisponibilitatetodelete.indexOf(element.id)
    if ( index >= 0){
      return true
    }
    return false
  }

  checkValueInArray(value,arr){
    for (let i in arr){
      if (arr[i] == value){
        return false
      }
    }
    return true
  }

  removeToDeleteAlsoIntervaleIndisponibilitate(toDelteList,  fromWhereList){
    console.log("TO DELETE LIST ==>> ",toDelteList)
    console.log("TO fromWhereList LIST ==>> ",fromWhereList)
    let intermediar: IntervalIndisponibilitateInterface[] = []
    for (let i of fromWhereList){
      if (this.checkValueInArray(i.id,toDelteList)){
        intermediar.push(i)
      }
    }
    return new MatTableDataSource(intermediar)
  }

  deleteInterval(){
    this.intervalService.stergeIntervalIndisponibilitateMultiple(this.intervaleindisponibilitatetodelete)
    .subscribe ( (data) => {
        if (data){
          console.log("Data din delete multiple ==>> ", data)
          if (data == true){
            console.log (" S-au sters ok")
            this.datePersonaleDialogCardValues.intervale_indisponibilitate = this.removeToDeleteAlsoIntervaleIndisponibilitate(this.intervaleindisponibilitatetodelete,this.datePersonaleDialogCardValues.intervale_indisponibilitate.data)
            // this.deleteDinPacientTwoLocal()
            // var intermediar = []
            // console.log (" DELETE INTERVAL this.datePersonaleDialogCardValues.intervale_indisponibilitate -->> ",this.datePersonaleDialogCardValues.intervale_indisponibilitate.data)
            // for (var z in this.datePersonaleDialogCardValues.intervale_indisponibilitate){
              // console.log (" DELETE INTERVAL Z -->> ",z)
              // console.log (" DELETE INTERVAL this.datePersonaleDialogCardValues.intervale_indisponibilitate[z] -->> ",this.datePersonaleDialogCardValues.intervale_indisponibilitate[z])
              // if (this.intervaleindisponibilitatetodelete.indexOf(this.datePersonaleDialogCardValues.intervale_indisponibilitate[z].id) < 0 ){
                // intermediar.push(this.datePersonaleDialogCardValues.intervale_indisponibilitate[z])
              // }
            }
            // if ( intermediar.length < this.datePersonaleDialogCardValues.intervale_indisponibilitate.data.length){
            //   this.intervaleindisponibilitatetodelete = []
            //   this.datePersonaleDialogCardValues.intervale_indisponibilitate = new MatTableDataSource(intermediar)
            //   this.intervale_indisponibilitate_dataSource =  this.datePersonaleDialogCardValues.intervale_indisponibilitate
            // }
          }
          else{
            console.log("Au ramas nesterse pentru ca nu s-au gasit ==>> ",data)
          }
        }
    )
  }

  initAdresaBeneficiar(adresa_init){
    // console.log("ADRESA INIT ==>> ", adresa_init)
    this.dateContactAdresaCardValues = new Adresa()
    this.dateContactDialogCardValues.adresa = new Adresa()
    this.dateContactAdresaCardValues = adresa_init
    this.dateContactDialogCardValues.adresa = JSON.parse(JSON.stringify(adresa_init))
    this.flag_adresa_beneficiar_add = true
    this.flag_adresa_beneficiar_set = true
  }

  adaugaAdresaBeneficiar(){
    this.dateContactDialogCardValues.adresa = new Adresa()
    this.flag_adresa_beneficiar_add = true
  }
  beneficiarFaraAdresa(){
    this.flag_beneficiar_fara_adresa = !this.flag_beneficiar_fara_adresa
  }

  async salveazaAdresaBeneficiar(){
    this.flag_adresa_beneficiar_set = true
    // console.log("SALVEAZA ADRESA BENEFICIAR ==>> ", this.dateContactDialogCardValues)
    // console.log("SALVEAZA ADRESA BENEFICIAR 2 ==>> ", this.pacient.date_contact)
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // DE REVENIT AICI
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!

    // Trebuie verificat la sfarsit daca adresa s-a modificat si salvata atunci

    // this.dateContactDialogCardValues.adresa = await this.adresaService.addAdresaToServer(this.dateContactDialogCardValues.adresa)
    // .toPromise()

    // this.dateContactDialogCardValues.adresa.id = this.dateContactDialogCardValues.adresa.id
    // console.log ( " ADRESA INTERMEDIAR == >> ", this.pacienttwo.date_contact)

    // var intermediar : DateContactInterface = new DateContactCreate()
    // intermediar = await this.datecontactService.updateDateContact(this.dateContactDialogCardValues)
    // .toPromise()

    // console.log ( " intermediar dupa update date_contact == >> ", intermediar)

    // this.dateContactDialogCardValues = await this.datecontactService.updateDateContact(this.dateContactDialogCardValues)
    // .toPromise()
    // console.log ( " Pacient TWO dupa update date_contact == >> ", this.pacienttwo)


  }


  editeazaAdresaBeneficiar(){
    this.flag_adresa_beneficiar_set = false
  }

  anuleazaAdresaBeneficiar(){
    this.flag_adresa_beneficiar_add = false
    this.dateContactDialogCardValues.adresa = null
    console.log("DATE CONTACT VALUE ON ADRESA ANULEAZA == >> ",this.dateContactDialogCardValues)
  }

  stergeAdresaBeneficiar(){
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // DE REVENIT AICI
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!

    // Trebuie verificat la sfarsit daca adresa s-a modificat si STEARSA atunci

    // this.pacientiService.deleteAdresaFromDateContact(this.pacienttwo.date_contact)
    this.flag_adresa_beneficiar_add = false
    this.flag_adresa_beneficiar_set = false
    // this.pacienttwo.date_contact.adresaid = undefined
    console.log( "ADRESA CONTACT BEFORE DELETE ==>> ", this.dateContactDialogCardValues)
    this.dateContactDialogCardValues.adresa = null
    console.log( "ADRESA CONTACT ON DELETE ==>> ", this.dateContactDialogCardValues)
  }

  async getpacientdata(pacientvals){

  }
  getCalitateKey(calitateid){
    for (let i of this.calitate){
      if (i.id == calitateid.calitateid){
        return (i.calitate)
      }
    }
    return ("N/A")
  }

  dosar(){
    const dialogRef =this.dialg.open(DosarelectronicComponent,{
      width: '750px',
      height: '750px',
      data: this.pacienttwo.id
    });
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  aprobapacient(){
    this.pacient.aprobat = true;
  }

  consultatii(){
    const dialogRef =this.dialg.open(ConsultComponent,{
      width: "90%",
      height: "90%",
      data: this.pacienttwo
    });
  }
  async setPersoaneContactList(){
    console.log(" PERSOANA DE CONTACT == >> ",this.persoana_contact)
    this.persoana_contact.pacientid = this.pacienttwo.id
    console.log(" PERSOANA DE CONTACT == >> ",this.persoana_contact)
    this.asocPacientPersoanaContactService.addAsocPCToServerFromBeneficiar(this.persoana_contact)
    .subscribe ( async (data) => {
      if (data) {
        console.log(" DATA FROM ASOCIERE PC LIST ==>> ", data)
        console.log(" DATA FROM ASOCIERE PC LIST ==>> this.datePCCardValues ==>> ma intereseaza daca reprezentant_legal_persoana de contact este FALSE ==>> ", this.datePCCardValues)
        console.log(" DATA FROM ASOCIERE PC LIST ==>> this.pacient ==>> ma intereseaza daca reprezentant_legal_persoana de contact este TRUE ==>> ", this.pacienttwo)
        this.datePCCardValues.persoane_contact.push(data)
        this.dataSourcePC = new MatTableDataSource(this.datePCCardValues.persoane_contact)
        if (this.addnewcontactperson == 0){
          this.addnewcontactperson = 1
        }
        if(this.flag_PC_gol){
          this.flag_PC_gol = false
        }
        if( this.pacienttwo.reprezentant_legal_persoana_de_contact = !this.datePCCardValues.reprezentant_legal_persoana_de_contact){
          this.pacienttwo.reprezentant_legal_persoana_de_contact = this.datePCCardValues.reprezentant_legal_persoana_de_contact
          let def_return = await this.pacientiService.updatePacientNakedDetailsOnServer(this.pacient.id,this.pacientiService.createPacientNakedModel(this.pacienttwo)).toPromise()
          console.log("UPDATED PACIENT REPREZENTANT LEGAL PERSOANA DE CONTACT ==>> ", this.pacienttwo.reprezentant_legal_persoana_de_contact)
        }
      }
    })
  }

  async setReprezentantiLegalList(){
    console.log(" REPREZENTANT LEGAL == >> ",this.reprezentant_legal)
    this.reprezentant_legal.pacientid = this.pacient.id
    console.log(" REPREZENTANT LEGAL 2 == >> ",this.reprezentant_legal)
    this.asocPacientReprezentantLegalService.addAsocToServerFromBeneficiar(this.reprezentant_legal)
    .subscribe ( (data) => {
      if (data){
        console.log(" DATA FROM ASOCIERE RL LIST ==>> ", data)
        this.dateRLCardValues.reprezentanti_legali.push(data)
        this.dataSourceRL = new MatTableDataSource(this.dateRLCardValues.reprezentanti_legali)
        if (this.addnewperson == 0){
          this.addnewperson = 1
        }
        if(this.flag_RL_gol){
          this.flag_RL_gol = false
        }
      }
    })
    // if ()
    // var intermediar = await this.asocPacientReprezentantLegalService.addAsocToServerFromBeneficiar(this.reprezentant_legal)
    // .toPromise()
    // console.log(" REPREZENTANT LEGAL == >> intermediar == >> ",intermediar)
    // this.pacienttwo.reprezentanti_legali.push(intermediar)
    // this.pacientiService.setPacientSelectat(this.pacienttwo,"linia 1189 - pacient details - set reprezentanti legali list ")
    // this.dataSourceRL  = new MatTableDataSource(this.pacienttwo.reprezentanti_legali);
    // this.addnewperson = 1
    // delete this.reprezentant_legal.calitateid
  }
  sendsms(){

    this.smsservice.sendsms(this.pacient.date_contact.telefon)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data) => {
      console.log("Data from send sms ==>> ", data)
    })
  }

  setPersoana_contact_id(checkedUserID: number)
  {
    this.pacient.persoana_contact_user_id = checkedUserID;
  }

  setReprezentantLegalCalitatea(calitateid){
    this.pacient.reprezentant_legal_calitateid = calitateid
  }
  setPersoanaDeContactCalitatea(calitateid){
    this.pacient.persoana_contact_calitateid = calitateid
  }

  setReprezentant_Legal_id(checkedUserID:number)
  {
    this.pacient.reprezentant_legal_user_id = checkedUserID;
  }

  openppi()
  {
    const dialogRef =this.dialg.open(PlandeinterventieComponent,{
      width: '90%',
      height: '90%',
      data: this.pacienttwo
    });
  }

  opencontracte()
  {
    const dialogRef =this.dialg.open(ContractePacientComponent,{
      width: '90%',
      height: '90%',
      data: this.pacienttwo
    });
  }
  opencistoricprogramari()
  {
      const dialogRef =this.dialg.open(IstoricprogramariComponent,{
      width: '750px',
      height: '750px',
      data: this.pacienttwo
    });
  }
  openIstoricProgramariComponent(){
    const dialogRef =this.dialg.open(IstoricprogramariComponent,{
      width: '750px',
      height: '750px',
      data: this.pacienttwo.id
    });
  }

  ngOnDestroy():void {
    console.log("On DESTROI PACIENT DETAILS")
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  sendvalues(){
    this.pacientiService.updatePacientInfo(this.pacient,this.pacienttwo,this.datePersonaleDialogCardValues,this.dateContactDialogCardValues, this.datePCCardValues.reprezentant_legal_persoana_de_contact);

    // this.dialogRef.close()
  }

}
