<!-- <button mat-raised-button color="primary" (click)="adauganomenclator()">Adauga nomenclator </button>
<mat-divider></mat-divider> -->

<mat-accordion class="example-headers-align">

  <mat-expansion-panel [expanded]='false' hideToggle>
    <mat-expansion-panel-header>
        <mat-panel-title>
          Evaluari
        </mat-panel-title>
        <mat-panel-description>
          Documente de evaluare
          <!-- <mat-icon>account_circle</mat-icon> -->
        </mat-panel-description>
    </mat-expansion-panel-header>


    <mat-selection-list>
        <mat-list-option role="listitem" (click)="openEvaluareaFunctieiMotoriiGrosiereComponent()" >Evaluarea functiei motorii grosiere</mat-list-option>
        <mat-list-option role="listitem" (click)="openEvaluareaReflexelorPrimitiveComponent()" >Evaluarea reflexelor primitive</mat-list-option>
        <mat-list-option role="listitem" (click)="openFisaExaminareLogopedicaComponent()" >fisa de examinare logopedica </mat-list-option>
        <mat-list-option role="listitem" (click)="openFisaEvaluareInfsParintiComponent()" >Fisă evaluare INFS părinți </mat-list-option>
        <mat-list-option role="listitem" (click)="openEvaluareMersComponent()" >Evaluare mers </mat-list-option>
        <mat-list-option role="listitem" (click)="openEvaluareIndependentaMnfComponent()" >Evaluare independenta mnf</mat-list-option>
        <mat-list-option role="listitem" (click)="openEvaluareMariComponent()" >Evaluare Mari</mat-list-option>
    </mat-selection-list>

    <!-- <mat-form-field>
        <mat-label>First name</mat-label>
        <input matInput>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Age</mat-label>
        <input matInput type="number" min="1">
    </mat-form-field> -->

    <!-- #docregion action-bar -->
    <!-- <mat-action-row>
        <button mat-button color="primary" (click)="nextStep()">Next</button>
    </mat-action-row> -->
    <!-- #enddocregion action-bar -->
    
</mat-expansion-panel>

<mat-expansion-panel [expanded]='false' hideToggle>
  <mat-expansion-panel-header>
      <mat-panel-title>
        Monitorizari / Planuri de interventie
      </mat-panel-title>
      <mat-panel-description>
        Fisa monitorizare PPI si Fisa pentru Plan de interventie personalizat
      </mat-panel-description>
  </mat-expansion-panel-header>

  <mat-selection-list>
      <mat-list-option role="listitem" (click)="openFisaMonitorizarePpiComponent()" >Fisa monitorizare PPI</mat-list-option>
      <mat-list-option role="listitem" (click)="openPlanDeInterventiePersonalizatComponent()" >Fisa Plan de interventie personalizat</mat-list-option>
  </mat-selection-list>
</mat-expansion-panel>

<mat-expansion-panel [expanded]='false' hideToggle>
    <mat-expansion-panel-header>
        <mat-panel-title>
          Chestionare
        </mat-panel-title>
        <mat-panel-description>
          Diferite chestionare
        </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-selection-list>
        <mat-list-option role="listitem" (click)="openChestionarDeAlergiiComponent()" >Chestionar de alergii</mat-list-option>
    </mat-selection-list>
</mat-expansion-panel>

<mat-expansion-panel [expanded]='false' hideToggle>
    <mat-expansion-panel-header>
        <mat-panel-title>
          Contracte
        </mat-panel-title>
        <mat-panel-description>
          Diferite Contracte
        </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-selection-list>
        <mat-list-option role="listitem" (click)="openContractFurnizorServiciiSocialeComponent()" >Contract furnizor servicii sociale</mat-list-option>
        <mat-list-option role="listitem" (click)="openContractMasinaComponent()" >Contract masina</mat-list-option>
        <mat-list-option role="listitem" (click)="openContractInchiriereComponent()" >Contract Inchiriere</mat-list-option>
    </mat-selection-list>
</mat-expansion-panel>


</mat-accordion>

<!-- <mat-selection-list>
    <mat-list-option role="listitem" >Categorii de servicii</mat-list-option>
    <mat-list-option role="listitem" >Servicii</mat-list-option>
    <mat-list-option role="listitem" >Medici</mat-list-option>
    <mat-list-option role="listitem" >Specialitati</mat-list-option>
    <mat-list-option role="listitem" >Tip Departament</mat-list-option>
    <mat-list-option role="listitem" >Departament</mat-list-option>
    <mat-list-option role="listitem" >Tip Diagnostic</mat-list-option>
    <mat-list-option role="listitem" >Diagnostic</mat-list-option>
    <mat-list-option role="listitem" >Furnizor Servicii Sociale</mat-list-option>
    <mat-list-option role="listitem" >Case de Asigurari</mat-list-option>
</mat-selection-list> -->
