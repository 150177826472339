<mat-form-field floatLabel="never" color="accent">
    <b><input matInput (keyup)="applyFilter($event)" #input color="warn" placeholder="Cauta Pacient" ></b>
</mat-form-field>

<div>
    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width:100%;height:100%">
        
          <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="checkedUserId(row)"
                        [checked]="row.id === checkedID"
                        >
            </mat-checkbox>
            </td>
        </ng-container>

                <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Nume Column -->
        <ng-container matColumnDef="nume"> 
        <th mat-header-cell *matHeaderCellDef> Nume </th>
        <td mat-cell *matCellDef="let element"> {{element.date_personale.nume}} </td>
        </ng-container>
    
        <!-- Prenume Column -->
        <ng-container matColumnDef="prenume">
        <th mat-header-cell *matHeaderCellDef> Prenume </th>
        <td mat-cell *matCellDef="let element"> {{element.date_personale.prenume}} </td>
        </ng-container>
    
        <!-- CNP Column -->
        <ng-container matColumnDef="CNP">
        <th mat-header-cell *matHeaderCellDef> CNP </th>
        <td mat-cell *matCellDef="let element"> {{element.date_personale.cnp}} </td>
        </ng-container>
            
        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.date_contact.email}} </td>
        </ng-container>
            
        <!-- Telefon Column -->
        <ng-container matColumnDef="telefon">
            <th mat-header-cell *matHeaderCellDef> Telefon </th>
            <td mat-cell *matCellDef="let element"> {{element.date_contact.telefon}} </td>
        </ng-container>

        <ng-container matColumnDef="calitate">
            <th mat-header-cell *matHeaderCellDef> Calitate </th>
            <td mat-cell *matCellDef="let element"> 
        <mat-form-field appearance="fill">
            <mat-label>Calitate</mat-label>
            <select matNativeControl [(value)]="element.persoana_contact_calitate" >
              <option value="na">N/A</option>
              <option value="mama">Mama</option>
              <option value="tata">Tata</option>
              <option value="ruda">Alta calitate</option>
            </select>
          </mat-form-field>
        </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>