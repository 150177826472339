import { Injectable } from '@angular/core';
import { ValabilitateInterface,ValabilitateCreateInterface } from '../interfaces/nomenclatoare.interface'
import { Valabilitate } from '../models/nomenclatoare.model'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {catchError} from 'rxjs/operators/catchError';
import { Observable, of  } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { serverurl } from './server.service';
import { NativeDateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface Consultatie{
  investigatii: string;
  recomandari: string;
  diagnostic: string;
  tratament: string;
}

var consultatiilist:Consultatie [] = []

@Injectable({
  providedIn: 'root'
})

export class IntervalService {
  public IntervalList: ValabilitateInterface [] = []

  private server_url=serverurl+"/api/v1/interval/";
  private server_url_indisponibilitate=serverurl+"/api/v1/intervaleindisponibilitate/";

  // private server_url="http://localhost/api/v1/interval/";


  constructor(private http: HttpClient,public datepipe: DatePipe) { }

  adaugaIntervalIndisponibilitate( interval_de_adaugat ){
   return this.http.post<any>(this.server_url_indisponibilitate,interval_de_adaugat, httpOptions)
  }
  
  stergeIntervalIndisponibilitateMultiple( ids ){
    return this.http.post<any>(this.server_url_indisponibilitate+"multiple/" ,ids, httpOptions)
  }

  stergeIntervalIndisponibilitate( id ){
    return this.http.delete<any>(this.server_url_indisponibilitate+id, httpOptions)
  }

  updateIntervalIndisponibilitate( interval ){
    return this.http.put<any>(this.server_url_indisponibilitate,interval, httpOptions)
  }


  updateInterval(id,interval){
    return this.http.put(this.server_url+id,interval)
  }
  getIntervalFromServer(){
    return(this.http.get<ValabilitateInterface[]>(this.server_url))
    // let intermediar = this.http.get<ValabilitateInterface[]>(this.server_url)
    // .subscribe( (data:ValabilitateInterface[]) =>{
    //   for (let x in data){
    //     console.log("x in data ==>> ",x)
    //     let interval:ValabilitateInterface = new Valabilitate()
    //     for(let y of Object.entries(data[x])){
    //       // interval[y]
    //       console.log(" get interval from server -> y => ", y)
    //       console.log(" get interval from server -> y => ", y, " y[0] = ", y[0]," interval."+y[0]+" = "+y[1])
    //       console.log("keys of interval => ", Object.keys(interval))
    //       console.log("y[0] in keys of interval => ", (y[0] in Object.keys(interval)))
    //       for (let q in Object.keys(interval)){
    //         console.log("q => ", Object.keys(interval)[q])
    //         if (y[0] === Object.keys(interval)[q]){
    //           console.log("BINGO!! ", y[0], " si ", Object.keys(interval)[q] )
    //         }
    //       }

    //       if (y[0] in Object.keys(interval) && y[1]){
    //         console.log("y[0] "+ y[0] +" este o cheie comuna si pt interval si pentru y")
    //         console.log("interval."+ y[0] +" = " + y[1] )
    //         interval[y[0]] = y[1]
    //         console.log("interval din for object keys =>", interval)

    //       }
    //       console.log("y din for object keys =>", interval)
    //     }
    //     if (data[x]["id"]){
    //       interval.id = data[x]["id"]
    //     }
    //     if (data[x]["start"]){
    //       interval.start = data[x]["start"]
    //     }
    //     if (data[x]["end"]){
    //       interval.end = data[x]["end"]
    //     }
    //     if (data[x]["format_type"]){
    //       interval.format_type = data[x]["format_type"]
    //     }
    //     console.log("Interval intermediar ==>> ",interval)
    //     // console.log("Intermediar de x: ",x," ==>> ",data[x])
    //     this.IntervalList.push(interval)

    //   }
    //   console.log("IntervalList intermediar ==>> ",this.IntervalList)
    //   return this.IntervalList
    // })
  }

returnStringDate(ora_in_date_format: Date){
  return (this.datepipe.transform(ora_in_date_format,'HH:mm'))
}
getHours(ora_in_string_format: string){
  return (Number(ora_in_string_format.substring(0,ora_in_string_format.indexOf(':'))))
}
getMinutes(ora_in_string_format: string){
  return (Number(ora_in_string_format.substring(ora_in_string_format.indexOf(":")+1,ora_in_string_format.length)))
}
returnDateString(ora_in_string_format: string):Date{
  var intermediar_ora = this.getHours(ora_in_string_format)
  var intermediar_minute = this.getMinutes(ora_in_string_format)
  console.log("Get Hours ==>> ", intermediar_ora)
  console.log("Get minutes ==>> ", intermediar_minute )
  var intermediar_data = new Date()
  intermediar_data.setHours(intermediar_ora,intermediar_minute)
  console.log("intermediar_data ==>> ", intermediar_data)
  return intermediar_data
}
serchIntervalfromserver(id: number) {
    // const options = id ? {
    //   params: new HttpParams().set('',id.toString() )
    // } : {}
    console.log("get from search service !!")
    let interval_secundar: ValabilitateInterface = new Valabilitate()
    // this.http.get<ValabilitateInterface>(this.server_url+id).subscribe((data)=>{
    //   if (data){
    //     for (let x in data){
    //       console.log("get x in data: ", x)
    //       console.log("get data[x] in data: ", data[x])
    //       interval_secundar[x] = data[x]
    //     }
    //     console.log("intervalul exista => ", interval_secundar)
    //     // interval_secundar = data
    //     // console.log("interval secundar exista => ", interval_secundar)
    //   }
    //   console.log("intervalul exista 2 => ", interval_secundar)
    //   return interval_secundar
    // })
    console.log("intervalul secundar nu exista -> intervalul nu exista -> este initializat !! => ", interval_secundar)
    // return null

    // return (    this.http.get<ValabilitateInterface>(this.server_url+id).subscribe((data)=>{
    //   if (data){
    //     for (let x in data){
    //       console.log("get x in data: ", x)
    //       console.log("get data[x] in data: ", data[x])
    //       interval_secundar[x] = data[x]
    //     }
    //     console.log("intervalul exista => ", interval_secundar)
    //     // interval_secundar = data
    //     // console.log("interval secundar exista => ", interval_secundar)
    //   }
    //   console.log("intervalul exista 2 => ", interval_secundar)
    //   return interval_secundar
    // }))

    return this.http.get<ValabilitateInterface>(this.server_url+id)

  }
addIntervalToServer(interval: ValabilitateCreateInterface){
    console.log("POST request la interval => ", interval)
    // var intermediar = {
    //   start:interval.start,
    //   end:interval.end,
    //   format_type:interval.format_type
    // }
   return this.http.post<any>(this.server_url,interval,cudOptions)
  }

  getConsult(){
    return consultatiilist;
  }

  addConsult(consultatie){
    consultatiilist.push(consultatie);
  }

}

export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek()  {
    return 1;
   }


}

export const ZILELE_SAPTAMANII=["luni","marti","miercuri","joi","vineri","sambata","duminica"]
