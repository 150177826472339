import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatePipe } from '@angular/common'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import { MatInput } from '@angular/material/input';
import { IntervalService } from '../../services/interval.service'
import {MatSnackBar} from '@angular/material/snack-bar';
import { Diagnostic, DiagnosticCreate } from 'src/app/models/nomenclatoare.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'd',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-diagnosticedit',
  templateUrl: './diagnosticedit.component.html',
  styleUrls: ['./diagnosticedit.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DiagnosticeditComponent implements OnInit {
  diagnostic = new Diagnostic()
  tipDiagnostic: any[] = []

  constructor(public diagnosticService: DiagnosticService,private _snackBar: MatSnackBar, public datepipe: DatePipe,public dialogRef: MatDialogRef<DiagnosticeditComponent>, public dialg: MatDialog,@Inject(MAT_DIALOG_DATA) private data:any) {
    if (data){
      if (data.diagnostic){
        this.diagnostic = data.diagnostic
      }
      if (data.tipDiagnostic){
        this.tipDiagnostic = data.tipDiagnostic
      }
    }
   }

  ngOnInit(): void {
  }
  updatevalues(){

    let newdiagnostic = new DiagnosticCreate()
    for ( let key in newdiagnostic){
      newdiagnostic[key] = this.diagnostic[key]
    }
    this.diagnosticService.updatetoserver(this.diagnostic.id, newdiagnostic)
    .subscribe( (data) => {
      if (data){
        console.log("Data from update Tip Departament ==>> ", data)
      }
    })
    this._snackBar.open("Diagnostic actualizat","Inchide")
  }

}
