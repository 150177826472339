import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject,Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {catchError} from 'rxjs/operators/catchError';
import { serverurl } from './server.service';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};


@Injectable({
  providedIn: 'root'
})
export class EvalserviceService {
  private server_url= serverurl+"/api/v1/evaluarefmgfields/";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  addToServer(evaluarenouafields){
    // let test = {
    //   "sectiunea_a": evaluarenouafields
    // }
    // let test2 ={
    //   "sectiunea_a": test
    // }
    // console.log("Test 2 din service ==>> ", test2)
    // console.log("JSON Test 2 din service ==>> ", JSON.stringify(test2))
    return (this.http.post<any>(this.server_url,evaluarenouafields, this.httpOptions))
  }

  getDocFieldsFromServerByID(id){
    return (this.http.get<any>(this.server_url+id))
  }

}
