import { Component,Inject, OnInit, ViewChild } from '@angular/core';
import { FisaMonitorizarePpiComponent } from '../templatedocumente/componente-monitorizari-plan-interventie/fisa-monitorizare-ppi/fisa-monitorizare-ppi.component';
import { PlanDeInterventiePersonalizatComponent } from '../templatedocumente/componente-monitorizari-plan-interventie/plan-de-interventie-personalizat/plan-de-interventie-personalizat.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pacient, DateContact, DatePersonale, Adresa } from '../models/pacientdetails.model';
import { RowSelectEventArgs, QueryCellInfoEventArgs, GridComponent, SortService, FilterService, PageService,ToolbarService, EditService} from '@syncfusion/ej2-angular-grids';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConsultatiiService } from '../services/consultatii.service';
import { ConsultLightInterface } from '../interfaces/consult.interface';

@Component({
  selector: 'app-plandeinterventie',
  templateUrl: './plandeinterventie.component.html',
  styleUrls: ['./plandeinterventie.component.css'],
  providers: [ToolbarService, EditService, PageService]
})
export class PlandeinterventieComponent implements OnInit {
  public pageSettings: object;
  @ViewChild('grid') public Grid: GridComponent;

  public editColumnName:any;
    public consultservice: ConsultatiiService;
    public vizualizeazaPDFColumnName:any;
  public descarcaPDFColumnName:any;
    private _snackBar: MatSnackBar;
    public stergeConsultColumnName:any;
  public flag_exista_consultatii: boolean = true
  pacient = new Pacient();
  pacientid: number
  consultatii: any

  constructor(public dialog: MatDialog,
  @Inject(MAT_DIALOG_DATA) private data:any ) {
    if(data){
      // if (data.rowz){
      //   this.pacient = data.rowz;
      // }
      // else{
      //   this.pacient = data
      // }
      console.log("Data from component ==>> ",data);
      this.pacient = data
    }
  }
  ngOnInit(): void {
  }
  refreshTableDataAfterEdit (edited_flag : boolean){
    if (edited_flag){
      // console.log("DELETED FLAG TRUE")
      this.getConsultListOfPacient()
      this.openSnackBar("Consultatia a fost editata cu succes","Inchide")
    }
    else {
      // console.log("DELETED FLAG FALSE")
    }
  }
  openSnackBar(message: string, action: string){
    this._snackBar.open(message,action)
  }
  refreshTableDataAfterDelete (deleted_flag : boolean){
    if (deleted_flag){
      // console.log("DELETED FLAG TRUE")
      this.getConsultListOfPacient()
      this.openSnackBar("Consultatia a fost stearsa cu succes","Inchide")
    }
    else {
      // console.log("DELETED FLAG FALSE")
    }
  }
  getConsultListOfPacient(){
    this.consultservice.getConsultFromServerByPacientID(this.pacientid)
    .subscribe( (data) => {
      if (data){
        console.log( "DATA FROM GET CONSULT BY PACIENT ID ==>> ", data)
        this.consultatii = data
        if(data.length == 0){
          console.log("Data gol")
          if (this.flag_exista_consultatii){
            this.flag_exista_consultatii = false
          }
        }
        else{
          if(!this.flag_exista_consultatii){
            this.flag_exista_consultatii = true
          }
        }
      }
    })
  }
  openFisaMonitorizarePpiComponent() {
    const dialogRef = this.dialog.open(FisaMonitorizarePpiComponent, {
      width: '80%',
      height: '100%',
      data: {
        'caz' : 1,
        'beneficiar' : this.pacient
      }
    });
  }
  load() {
    console.log("CONSULTATII LIST : => ",this.consultatii)
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = 10;   // initial page size
}
  rowSelected(args: RowSelectEventArgs) {
    // this.flag_dialog_pacient_open = true
    const selectedrecords: object[] = this.Grid.getSelectedRecords();  // Get the selected records.
    let intermediar_consult = selectedrecords[0] as ConsultLightInterface
  }
  openPlanDeInterventiePersonalizatComponent() {
    const dialogRef = this.dialog.open(PlanDeInterventiePersonalizatComponent, {
      width: '80%',
      height: '100%',
      data: this.pacient
    });
  }

}
