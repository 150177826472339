import { Injectable } from '@angular/core';
import { CalitatePersoana } from '../models/calitate-persoana.model';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import { RequestInfo } from 'angular-in-memory-web-api/interfaces';
import { Pacient, DateContact, DatePersonale, Adresa } from '../models/pacientdetails.model';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MyInMemoryCalitatePersoanaService implements InMemoryDbService {

  localStorageChanges$ = this.localStorageService.changes$;

  constructor(private localStorageService: LocalStorageService){}

  createDb() {
    const calitate:CalitatePersoana []  = [
      { id: 1, calitate: 'SOT/SOTIE' },
      { id: 2, calitate: 'FIU/FIICA' },
      { id: 3, calitate: 'RUDA' },
      { id: 4, calitate: 'Alta calitate' },
      // add as many data you need
    ]

    const date_personale:DatePersonale[] = []

    const date_contact: DateContact[] = []

    const adresa: Adresa[] =[]

    const pacienti:Pacient []  = []


    return { calitate, pacienti } // add as many end-points you want
  }
}