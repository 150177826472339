import { SafeResourceUrl } from "@angular/platform-browser";
import { FileElement } from "../file-manager/model/element";

export class FileElementCls implements FileElement {
    constructor(
     public id?: string,
     public isFolder: boolean = false,
     public  name: string ='',
     public  parent: string ='',
     public  path?: SafeResourceUrl,
     public idPacient: number = -1,
     public type?: string,
     public dateadden?: string,
     public uploader?: string,
     public status?: string,
     public extension?: string
    ){}
  }
  

  export class FileFolderCls {
    constructor(
     public id?: string,
     public isFolder: boolean = false,
     public  name: string ='',
     public  parent: string ='',
     public idPacient: number = -1,
     public dateadden?: string,
     public status?: string,
    ){}
  }