<div style="text-align: center;" class="fixActionRow">

<h1 mat-dialog-title>Dosar electronic</h1>
<mat-dialog-content>
<div style="box-sizing: border-box; max-height: 50%; overflow: scroll;">
  <app-fileupload [idPacient] = "idPacient" [folder]="currentPath" (uploaded)="updateFileElementQuery()" style="    display: flex;
  flex-direction: row;
  width: 100%;"></app-fileupload>
</div>
<div style="height: 80%; box-sizing: border-box;">
    <mat-card style="height: 100%; box-sizing: border-box; padding:0">
      <app-file-manager 
      [fileElements]="pacientFiles" 
      [pacientFilesList]="pacientFilesList" 
      [path]="currentPath" 
      [canNavigateUp]="canNavigateUp" 
      (folderAdded)="addFolder($event)"
      (elementRemoved)="removeElement($event)"
      (navigatedDown)="navigateToFolder($event)" 
      (downloadfile)="downloadfile($event)" 
      (navigatedUp)="navigateUp()" 
      (elementRenamed)="renameElement($event)"
      (elementMoved)="moveElement($event)"
      (statuschanged)="statuschanged($event)" 
      ></app-file-manager>
    </mat-card>
 </div>

</mat-dialog-content>
</div>
<div class="spacer"></div>
<div mat-dialog-actions style="display:flex; align-self: stretch;">

<!-- <mat-dialog-actions align="end" > -->
    <button mat-raised-button color="warn" mat-dialog-close >Inchide</button>
    <!-- <button mat-raised-button color="primary" cdkFocusInitial>Ok</button> -->
    <button mat-raised-button color="primary" (click)="openDashboardDialog()">Dashboard</button>
<!-- </mat-dialog-actions> -->
</div>
