import { Component, OnInit,Inject, EventEmitter, Output } from '@angular/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { logo_url_base64 } from 'src/app/models/logo-base64.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Pacient, SemnaturaRLCreate } from 'src/app/models/pacientdetails.model';
import { FileService } from 'src/app/services/file.service';
import { DatePipe } from '@angular/common';
import { EvaluareaReflexelorPrimitiveInterface } from 'src/app/interfaces/documente.interface';
import { EvaluareaReflexelorPrimitive } from 'src/app/models/documente.models';
import { EvalserviceService } from 'src/app/services/evalservice.service';
import { PinentryComponent } from 'src/app/pinentry/pinentry.component';
import { base_img_64 } from 'src/app/models/base-img64.model';
import { PdfpreviewerComponent } from 'src/app/pdfpreviewer/pdfpreviewer.component';
import { FileElementCls } from 'src/app/models/fisier.model';
import { FileElementCreate } from 'src/app/file-manager/model/element';
import { ConsultCreate } from 'src/app/models/consult.model';
import { ConsultCreateInterface } from 'src/app/interfaces/consult.interface';
import { ConsultatiiService } from 'src/app/services/consultatii.service';
import { SemnaturiRLService } from 'src/app/services/semnaturirl.service';
import { SemnaturiTerapeutiFilesService } from 'src/app/services/semnaturiterapeutifiles.service';
import { SemnaturaRLCreateInterface } from 'src/app/interfaces/pacient.interface';

@Component({
  selector: 'app-evaluarea-reflexelor-primitive',
  templateUrl: './evaluarea-reflexelor-primitive.component.html',
  styleUrls: ['./evaluarea-reflexelor-primitive.component.css']
})
export class EvaluareaReflexelorPrimitiveComponent implements OnInit {
  @Output () fileid = new EventEmitter<string>();
  pacientid: number
  pacient = new Pacient()
  evaluare_reflexe_primitive: EvaluareaReflexelorPrimitiveInterface = new EvaluareaReflexelorPrimitive ()
  semnatura_RL : any = null
  semnatura_Terapeut : any = null
  bkdataadaugare: any;
  caz : number = 1
  idFisier : any = null

  constructor(
    @Inject(MAT_DIALOG_DATA) private data:any,
    public dialogRef: MatDialogRef<EvaluareaReflexelorPrimitiveComponent>,
    public consultatiiService: ConsultatiiService,
    private datePipe: DatePipe,
    public fileService: FileService,
    public evaluarefieldsService: EvalserviceService ,
    public dialog: MatDialog,
    public semnaturirlService: SemnaturiRLService,
    public semnaturiTerapeutFilesService: SemnaturiTerapeutiFilesService,

    ) {
    this.getInitialFieldsFromServer()
    if(data){
      // console.log("DATA LA INCARCAREA REFLEXELOR PRIMITIVE ==>> ", data)
      if(data.caz == 1){
        console.log("Evaluare REFLEXE PRIMITIVE NEW")
        this.caz = 1
        this.pacient = data.beneficiar
        this.pacientid = this.pacient.id
        this.evaluare_reflexe_primitive.pacientid = this.pacientid

      }
      else {
        if(data.caz == 2){
          console.log("Evaluare REFLEXE PRIMITIVE EDITARE")
          this.caz = 2
          this.pacient = data.beneficiar
          this.pacientid = this.pacient.id
          console.log(" << == !! FIELDS DATA !! ==>> ", data.fieldsData)
          this.idFisier = data.fieldsData.idFisier
          this.getSavedDataFromServer(this.idFisier)
        }
      }
    }
  }
  getInitialFieldsFromServer(){
    this.evaluarefieldsService.getDocFieldsFromServerByID(21)
    .subscribe ( (data) => {
      if (data){
        console.log("DATA FROM EVALUAREA FUNCTIEI MOTORII GROSIERE -->> Initial Props ==>> ", data)
        // this.originalData = data
        this.evaluare_reflexe_primitive = data.props
      }
    })
  }
  getSavedDataFromServer(idFisier){
    console.log( "GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", idFisier)
    this.consultatiiService.getConsultDataFromServerByPFisierID(idFisier)
    .subscribe( (data) => {
      if (data){
        console.log( "DATA FROM GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", data)
        this.evaluare_reflexe_primitive = data.dataJSON
      }
    })
  }
  changestate(variab,selectat){
    // console.log("variab = ", variab, " <<== selectat = ", selectat)
    // console.log("variab = ", variab, "  selectat = ", selectat)
    // console.log("auditor = ", this.evaluare_reflexe_primitive.sistem_senzorial.auditor )
    // console.log("Kinestezic = ", this.evaluare_reflexe_primitive.sistem_senzorial.kinestezic )
    // console.log("Vestibular = ", this.evaluare_reflexe_primitive.sistem_senzorial.vestibular )
    variab[selectat] = !variab[selectat]
    if (variab[selectat]){
      for (let i in variab){
        if ( i != selectat){
          // console.log (" I == >> ", i)
          if (variab[i]){
            variab[i] = false
          }
        }
      }
    }
    // console.log("auditor = ", this.evaluare_reflexe_primitive.sistem_senzorial.auditor )
  }

  ngOnInit(): void {

    if (this.caz == 2){
      this.semnaturirlService.getSemnaturaRLfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA RL FROM SERVER ==>> ", data)
          this.semnatura_RL = data.semnatura
        }
      })
      this.semnaturiTerapeutFilesService.getSemnaturaTerapeutFilesfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA TERAPEUT FROM SERVER ==>> ", data)
          this.semnatura_Terapeut = data.semnatura
        }
      })
    }
    // FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI
    // let testone = {
    //   "denumire" : "Evaluarea reflexelor primitive",
    //   "props" : this.evaluare_reflexe_primitive
    // }
    // this.evaluarefieldsService.addToServer(testone )
    // .subscribe( (data) =>{
    //   if (data){
    //     console.log("Data from add sectiunea A to server ==>> ", data)
    //   }
    //   else{
    //     console.log("NO !!! Data from add sectiunea A to server ==>> ")
    //   }
    // })
    // END FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI
  }

  semneazaRL(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_RL = res
        // console.log("Semnatura RL IMAGINE ESTE ==>> ",this.semnatura_RL)
      }
    })
  }
  semneazaTerapeut(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_Terapeut = res
        // console.log("Semnaturas Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
      }
    })
  }
  addConsultToServer(idFisier){
    let intermediar_consult : ConsultCreateInterface = new ConsultCreate()
    intermediar_consult.idPacient = this.pacientid
    intermediar_consult.idFisier = idFisier
    intermediar_consult.dataAdaugare = this.bkdataadaugare
    intermediar_consult.idEvaluare = 21
    intermediar_consult.denumire = "Evaluarea reflexelor primitive"
    intermediar_consult.dataJSON = this.returnObjectForAddConsult()
    console.log(" ADD CONSULT TO SERVER ==>> ", intermediar_consult)
    this.consultatiiService.addConsultToServer(intermediar_consult)
    .subscribe( (data) => {
      if (data){
        console.log("DATA DIN ADD CONSULT TO SERVER ==>> ", data)
        let semnatura_noua_RL : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
        semnatura_noua_RL.idFisier = data.idFisier
        semnatura_noua_RL.semnatura = this.semnatura_RL
        this.semnaturirlService.addSemnaturaRLToServer(semnatura_noua_RL)
        .subscribe ( (data) => {
          if (data){
            console.log ("DATA DIN ADD SEMNATURA RL TO SERVER ==>> ", data)
          }
        })
        let semnatura_noua_TerapeutFiles : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
        semnatura_noua_TerapeutFiles.idFisier = data.idFisier
        semnatura_noua_TerapeutFiles.semnatura = this.semnatura_Terapeut
        this.semnaturiTerapeutFilesService.addSemnaturaTerapeutFilesToServer(semnatura_noua_TerapeutFiles)
        .subscribe ( (data) => {
          if (data){
            console.log ("DATA DIN ADD SEMNATURA TERAPEUT FILES TO SERVER ==>> ", data)
          }
        })
      }
    })
  }
  returnObjectForAddConsult(){
    let intermediar = {
      "dataJSON" : this.evaluare_reflexe_primitive
    }
    return intermediar
  }
  openPDFPreviewSsaveModificari(){
  let docDefinition = this.generatePDF()
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  pdfDocGenerator.getBase64((data) => {
    // console.log("data din pdfgenerator base 64 ==>> ",data);
    const dialogRef = this.dialog.open(PdfpreviewerComponent, {
      width: '800px',
      height: '100%',
      data: data
    });
    dialogRef.afterClosed().subscribe( async res => {
      if (res){
                  // this.semnatura_Terapeut = res
          // console.log("PDF PREVIEW FROM SAVE MODIFICARI RESULT AFTER CLOSE ==>> ",res)
          let update_file  = {
            'id' : this.idFisier,
            'dateadden' : this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm'),
            'path' : "data:application/pdf;base64,"+data,
            'name' : "Evaluarea reflexelor primitive - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
          }
          this.fileService.updateFileToServer(update_file)
          .subscribe ( (data) => {
            // console.log ( "DATA FROM UPDATE FILE FROM SERVER ==> ", data)
          })
          this.consultatiiService.updateConsultToServerByFisierID(this.idFisier,{'dataJSON' : this.returnObjectForAddConsult()})
          .subscribe( (data) => {
            // console.log ("DATA FROM ADD CONSULT TO SERVICE ==>> ", data)
          })
          this.dialogRef.close(1)
      }
    })
  });
  }

  openPDFPreviewComponent(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
      dialogRef.afterClosed().subscribe( async res => {
        if (res){
          // this.semnatura_Terapeut = res
          console.log("PDF PREVIEW RESULT AFTER CLOSE ==>> ",res)
          let fisier_to_upload : FileElementCreate = new FileElementCls()
          fisier_to_upload.id = this.fileService.generateFileID();
          fisier_to_upload.idPacient = this.pacientid
          fisier_to_upload.isFolder = false
          fisier_to_upload.parent = 'root'
          fisier_to_upload.dateadden = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
          this.bkdataadaugare = fisier_to_upload.dateadden
          fisier_to_upload.type = "application/pdf"
          fisier_to_upload.extension = "pdf"
          fisier_to_upload.path = "data:application/pdf;base64,"+data
          fisier_to_upload.status = 'neverificat';
          fisier_to_upload.name = "Evaluarea reflexelor primitive - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')

          // console.log("fisier_to_upload ==>> ",fisier_to_upload)
          let intermediar_from_server = await this.fileService.addFileToServerReturnFileID(fisier_to_upload).toPromise()
          // console.log("RASPUNS SERVER ADD FISIER ==>> ", intermediar_from_server)
          this.addConsultToServer(intermediar_from_server)
          this.dialogRef.close(0)
        }
      })
    })
  }
  generatePDF(action = 'open') {

    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
        height: 60,
        columns:
          [
            // {
            //   image: logo_url_base64,
            //   width: 50,
            //   alignment: 'center',
            // },
            {
              stack: [
                "Fundația Mihai Neșu Foundation",
                "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
              ],
              margin: 10,
              style: 'textheader'
            }
          ],
        margin: [0, 0, 0, 90]
      },
      content: [
        {
          canvas: [
            {
              type: 'line',
              x1: -100, y1: 0,
              x2: 600, y2: 0,
              lineWidth: 1,
              lineCap: 'square'
            },
            {
              type: 'line',
              x1: -100, y1: 4,
              x2: 600, y2: 4,
              lineWidth: 1,
              lineCap: 'square'
            }
          ]
        },
        {
          image: logo_url_base64,
          width: 200,
          alignment: 'center',
        },
        {
          text:"Evaluarea reflexelor primitive",
          bold: true,
          alignment: 'center',
          fontSize: 18,
          margin: [0, 50, 0, 80]
        },
        {
          text: "Data evaluarii : " + this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm'),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "1.	Reflexul MORO:",
          bold: true,
          alignment: 'left',
          decoration: 'underline',
          fontSize: 18,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Mers cu MI în Rotație ext/ int. : " + ((this.evaluare_reflexe_primitive.mers_cu_mi_in_rotatie!="") ? this.evaluare_reflexe_primitive.mers_cu_mi_in_rotatie : " - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "DD- auditor : " + ((this.evaluare_reflexe_primitive.dd_auditor!="")?this.evaluare_reflexe_primitive.dd_auditor:" - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "DD- kinestezic (mișcările corpului) : " + ((this.evaluare_reflexe_primitive.dd_kinestezic!="")?this.evaluare_reflexe_primitive.dd_kinestezic:" - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Așezat/ Stând- vestibular : " + ((this.evaluare_reflexe_primitive.asezat_stand_vestibular!="")?this.evaluare_reflexe_primitive.asezat_stand_vestibular:" - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Sist. senzorial ",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 30]
        },
        {
          text: "Auditor : " + ((this.evaluare_reflexe_primitive.sistem_senzorial.auditor.integrat) ? ' Integrat ' : ( (this.evaluare_reflexe_primitive.sistem_senzorial.auditor.partial_retinut) ? ' Partial Retinut' :  ((this.evaluare_reflexe_primitive.sistem_senzorial.auditor.retinut) ? 'Retinut' : 'N/A')  )),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Kinestezic : " + ((this.evaluare_reflexe_primitive.sistem_senzorial.kinestezic.integrat) ? ' Integrat ' : ( (this.evaluare_reflexe_primitive.sistem_senzorial.kinestezic.partial_retinut) ? ' Partial Retinut' :  ((this.evaluare_reflexe_primitive.sistem_senzorial.kinestezic.retinut) ? 'Retinut' : 'N/A')  )),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Vestibular : " + ((this.evaluare_reflexe_primitive.sistem_senzorial.vestibular.integrat) ? ' Integrat ' : ( (this.evaluare_reflexe_primitive.sistem_senzorial.vestibular.partial_retinut) ? ' Partial Retinut' :  ((this.evaluare_reflexe_primitive.sistem_senzorial.vestibular.retinut) ? 'Retinut' : 'N/A')  )),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "2.	Reflex tonic labirintic de Flexie/Extensie:",
          bold: true,
          alignment: 'left',
          decoration: 'underline',
          fontSize: 18,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Observarea posturii : " + ((this.evaluare_reflexe_primitive.observarea_posturii!="")?this.evaluare_reflexe_primitive.observarea_posturii:" - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Evaluarea tonusului muscular și musculaturii CORE : " + ((this.evaluare_reflexe_primitive.evaluarea_tonusului_muscular!="")?this.evaluare_reflexe_primitive.evaluarea_tonusului_muscular:" - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "DV - avion (observarea reapirației) : " + ((this.evaluare_reflexe_primitive.dv_avion!="")?this.evaluare_reflexe_primitive.dv_avion:" - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "DD - capul în piept ( observarea MS și MI, respirație) : " + ((this.evaluare_reflexe_primitive.capul_in_piept!="")?this.evaluare_reflexe_primitive.capul_in_piept:" - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Decubit lateral : " + ((this.evaluare_reflexe_primitive.decubit_lateral!="")?this.evaluare_reflexe_primitive.decubit_lateral:" - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "3.	Reflexul spinal GALANT:",
          bold: true,
          alignment: 'left',
          decoration: 'underline',
          fontSize: 18,
          margin: [0, 10, 0, 20]
        },
        {
          text: " Patrupedie/ DV- trage degetul de-a lungul coloanei vertebrale Stg/ Drp: ",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Dreapta : " + ((this.evaluare_reflexe_primitive.reflex_spinal_drp!="")?this.evaluare_reflexe_primitive.reflex_spinal_drp:" - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 10]
        },
        {
          text: "Stanga : " + ((this.evaluare_reflexe_primitive.reflex_spinal_stg!="")?this.evaluare_reflexe_primitive.reflex_spinal_stg:" - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 10]
        },
        {
          text: "Observați dacă pelvisul este simetric ori se rotește în mers: "+ ((this.evaluare_reflexe_primitive.obs_pelvis!="")?this.evaluare_reflexe_primitive.obs_pelvis:" - "),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "4.	Reflexul tonic cervical ASIMETRIC",
          bold: true,
          alignment: 'left',
          decoration: 'underline',
          fontSize: 18,
          margin: [0, 10, 0, 20]
        },
        {
          text: "DD / patrupedie (întoarcerea capului stg/drp): ",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "STG : " + ((this.evaluare_reflexe_primitive.reflex_tonic_cervical.stang.integrat) ? ' Integrat ' : ( (this.evaluare_reflexe_primitive.reflex_tonic_cervical.stang.partial_retinut) ? ' Partial Retinut' :  ((this.evaluare_reflexe_primitive.reflex_tonic_cervical.stang.retinut) ? 'Retinut' : 'N/A')  )),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 10]
        },
        {
          text: "DRP : " + ((this.evaluare_reflexe_primitive.reflex_tonic_cervical.drept.integrat) ? ' Integrat ' : ( (this.evaluare_reflexe_primitive.reflex_tonic_cervical.drept.partial_retinut) ? ' Partial Retinut' :  ((this.evaluare_reflexe_primitive.reflex_tonic_cervical.drept.retinut) ? 'Retinut' : 'N/A')  )),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 10]
        },
        {
          columns:[
            {
              stack: [
                {
                  text: "Semnatura terapeut, ",
                  bold: true,
                  alignment: 'left',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_Terapeut ? this.semnatura_Terapeut : base_img_64,
                  height: 40,
                  width: 140,
                  margin: [0, 0, 0, 20]
                }
              ]
            },
            {
              stack: [
                {
                  text: "Luat la cunoștință de părinte, ",
                  bold: true,
                  alignment: 'right',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_RL ? this.semnatura_RL : base_img_64,
                  alignment: 'right',
                  height: 40,
                  width: 140,
                  margin: [0, 0, 0, 20]
                }
              ]
            }
          ]
        }
      ],
      styles: {
        textheader: {
          alignment: 'center',
          bold: true,
          fontSize: 14,
          // lineHeight: 2
        }
      }
    }
    return docDefinition
  }

}
