<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Numar Contract:</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.numar_contract" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Data Contract:</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.data_contract" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Nume Beneficiar:</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.nume_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Prenume Beneficiar:</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.prenume_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Domiciliu Beneficiar:</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.domiciliu_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Serie CI Beneficiar:</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.serie_ci_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Numar CI Beneficiar:</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.nr_ci_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Emitent CI :</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.eliberator_ci_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Data eliberare CI :</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.data_eliberare_ci_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Inchiriere din data :</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.data_start_inchiriere" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Inchiriere pana in data :</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.data_end_inchiriere" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Valoare despagubiri :</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.despagubiri" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Valoare despagubiri :</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.despagubiri" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Predarea imobilului cel tarziu la data de:</mat-label>
    <textarea matInput [(ngModel)]="contract_inchiriere.data_finala_predare" ></textarea>
</mat-form-field>

<button mat-raised-button (click)="generatePDF()"> Printeaza</button>
<button mat-raised-button > Salveaza</button>