import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-filedashboard',
  templateUrl: './filedashboard.component.html',
  styleUrls: ['./filedashboard.component.css']
})
export class FiledashboardComponent implements OnInit {
  userfiles: FocusEvent[];
  dataSource: any;
  displayedColumns = ["nume", "Folder", "Data Adaugarii", "status"]
  constructor(
  @Inject(MAT_DIALOG_DATA) private data:any ) {
    if(data){
      // this.pacient = data.rowz;
      // console.log(this.pacient);
      this.userfiles = data;
      // console.log(data);
    }
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.userfiles);
  }

}
