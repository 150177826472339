import { Component, OnInit, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {  Inject, ViewChild, AfterViewInit } from '@angular/core';
import { DosarelectronicComponent } from '../pacient/dosarelectronic/dosarelectronic.component';
import {ConsultComponent } from '../consult/consult.component';
import { CalitatePersoanaService } from '../services/calitate-persoana.service';
import { CalitatePersoana } from '../models/calitate-persoana.model';
import { Pacient, DateContact, DatePersonale, Adresa, AdresaCreate, DateContactCreate, DatePersonaleCreate, PacientCreate, IntervalIndisponibilitateCreate, PacientCreateCreatePacient, DateContactCreatePacientCreate, ReprezentantLegalCreateTwo } from '../models/pacientdetails.model';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTableDataSource } from '@angular/material/table';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { PacientiService } from '../services/pacienti.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AdresaCreateInterface, CalitateInterface, PacientCreateInterface, PacientInterface, IntervalIndisponibilitateInterfaceCreate, PacientCreateCreatePacientInterface, DateContactCreatePacientCreateInterface, DatePersonaleCreateInterface, ReprezentantLegalCreateTwoInterface } from '../interfaces/pacient.interface';
import { Valabilitate } from '../models/nomenclatoare.model';
import { AdresaService } from '../services/adresa.service'
import { DateDeContactService } from '../services/datedecontact.service'
import { DatePersonaleService } from '../services/datepersonale.service'
import {MatSnackBar} from '@angular/material/snack-bar';
import { IntervalService } from '../services/interval.service';
import { MatTab } from '@angular/material/tabs';
import { DatePipe } from '@angular/common';
import {TooltipPosition} from '@angular/material/tooltip';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-addpacient',
  templateUrl:'./addpacient.component.html',
  styleUrls: ['./addpacient.component.css']
})
export class AddpacientComponent implements OnInit {
  pacient : PacientCreateInterface
  adresa : AdresaCreateInterface

  pacienttwo : PacientCreateCreatePacientInterface;
  datecontacttwo : DateContactCreatePacientCreateInterface;
  datepersonaletwo : DatePersonaleCreateInterface;
  adresatwo : AdresaCreateInterface;

  adresa_beneficiar = new FormControl('',[Validators.email])
  email_beneficiar = new FormControl('',[Validators.email])
  telefon_beneficiar = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])

  adresaRL : AdresaCreateInterface
  telefon_RL = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
  email_RL= new FormControl('',[Validators.email])

  old_telefon_RL : any
  old_RL_element : any

  adresaPC : AdresaCreateInterface
  telefon_PC = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
  email_PC= new FormControl('',[Validators.email])

  old_telefon_PC : any
  old_PC_element : any

  date_personale : DatePersonaleCreate
  date_contact : DateContactCreate

  lista:any[] = []
  listacontact:any[] = []

  dataSource: any;
  dataSourcetwo: any; // reprezentanti legali
  dataSourcethree: any; // persoane de contact
  intervale_indisponibilitate_dataSource: any;

  displayedColumns: string[] = ['id','nume', 'prenume', 'CNP',"email", "telefon","calitate"];
  displayedColumnstwo: string[] = ['nume', 'prenume', 'calitate',"telefon","email","edit","deleteselect"]; // coloane tabel reprezentanti legali
  displayedColumnsthree: string[] = ['nume', 'prenume', 'calitate',"telefon","email","edit","deleteselect"]; // coloane tabel persoane de contact
  displayedColumnIntervaleIndisponibilitate: string[] = ['ora_start', 'ora_end', 'detalii','edit', 'deleteselect']

  PacientList: PacientInterface[];

  program_de_somn = new Valabilitate()
  interval_indisponibilitate_nou = new IntervalIndisponibilitateCreate()

  reprezentant_legal_nonuser_date_personale : DatePersonaleCreate
  reprezentant_legal_nonuser_date_contact : DateContactCreate

  persoana_contact_nonuser_date_personale : DatePersonaleCreate
  persoana_contact_nonuser_date_contact : DateContactCreate

  calitate: any
  addnewperson:number = 0
  addnewcontactperson:number = 0
  carte_identitate_check: boolean = false
  todelete: any[] = []
  interval_start = new Date()
  interval_end = new Date()
  ora_start:number = 0
  interval_indisponibilitate_editat: IntervalIndisponibilitateInterfaceCreate

  flag_camp_detalii_activ: boolean = false

  intermediar_poztitie_minim: number = -1
  intermediar_interval_edit_detalii: string = ''
  intermediar_index_interval_editat: number = -1

  flag_sunt_orare_indisponibilitate:boolean = false

  flag_interval_in_editare:boolean = false

  flag_adauga_alt_interval:boolean = false
  flag_adauga_primul_interval: boolean = false
  flag_show_detalii_interval: boolean = false
  flag_salveaza_interval_nou:boolean = false
  flag_salveaza_interval_editat:boolean = false

  flag_telefon_beneficiar_valid:boolean = false
  flag_email_beneficiar_valid:boolean = false


  flag_telefon_beneficiar_set:boolean = false
  flag_email_beneficiar_set:boolean = false

  flag_adresa_beneficiar_set:boolean = false
  flag_adresa_beneficiar_add:boolean = false
  flag_beneficiar_fara_adresa:boolean = false
  flag_telefon_email_saved:boolean = false

  flag_telefon_email_RL_saved:boolean = false
  flag_reprezentant_legal_ci:boolean = false
  flag_persoana_contact_ci:boolean = false

  flag_telefon_email_PC_saved:boolean = false
  flag_PC_in_editare:boolean = false

  flag_adresa_PC_add:boolean = false
  flag_adresa_PC_set:boolean = false
  flag_PC_fara_adresa:boolean = false

  flag_adresa_RL_add:boolean = false
  flag_adresa_RL_set:boolean = false
  flag_RL_fara_adresa:boolean = false

  flag_RL_in_editare:boolean = false


  selectedTab: number = 0
  selectedTabRL: number = 0
  selectedTabPC: number = 0
  todeleteRL = []
  todeletePC = []

  nextStepButton = [
    "Pasul 1 - Date Personale",
    "Pasul 2 - Date de contact",
    "Pasul 3 - Reprezentant Legal",
    "Pasul 4 - Persoana de contact"
  ]

  constructor(public dialogRef: MatDialogRef<AddpacientComponent>,
    public pacientiService: PacientiService,
    public adresaService: AdresaService,
    public calitateService: CalitatePersoanaService,
    public datedecontactService: DateDeContactService,
    public datepersonaleService: DatePersonaleService,
    private _snackBar: MatSnackBar,
    public intervalService: IntervalService,
    public datepipe: DatePipe,
    ) {
      this.calitateService.getCalitatefromServer()
      .subscribe((data) =>{
        if (data){
          this.calitate = data
          // console.log("Calitate din service ==>> ", this.calitate)
        }
      })
  }

  ngOnInit(): void {
    // console.log("pacient chiar chand se initializeaza => ", this.pacient);
    this.pacienttwo = new PacientCreateCreatePacient();
    this.datecontacttwo = new DateContactCreatePacientCreate();
    this.datepersonaletwo = new DatePersonaleCreate();
    this.datecontacttwo.adresa = new AdresaCreate();
    // this.pacienttwo.date_personale = new
    this.pacienttwo.date_personale = new DatePersonaleCreate();
    this.pacienttwo.date_contact = new DateContactCreatePacientCreate();
    this.pacienttwo.date_contact.adresa = new AdresaCreate();
    this.pacienttwo.reprezentant_legal_nonusers = [];
    this.pacienttwo.persoana_contact_nonusers = [];
    this.pacienttwo.intervale_indisponibilitate = [];
    this.pacienttwo.program_de_somn = []
    console.log ( "pacient two on init  ==>> ", this.pacienttwo)
    console.log ( "pacient two on init keys === ==== ==== ==>> ", Object.keys(this.pacienttwo))
    for (var i of Object.keys(this.pacienttwo)){
      console.log ( "pacient two on init keys === ==== ==== ==>> ", this.pacienttwo[i])
    }
    this.pacient= new PacientCreate();
    this.date_personale = new DatePersonaleCreate();
    this.date_contact = new DateContactCreate();
    this.adresa = new AdresaCreate();
    this.adresaRL = new AdresaCreate();
    this.adresaPC = new AdresaCreate();
    this.reprezentant_legal_nonuser_date_personale = new DatePersonaleCreate();
    this.reprezentant_legal_nonuser_date_contact = new DateContactCreate()
    this.reprezentant_legal_nonuser_date_contact.adresa = new AdresaCreate()
    this.persoana_contact_nonuser_date_personale = new DatePersonaleCreate()
    this.persoana_contact_nonuser_date_contact = new DateContactCreate()
    this.persoana_contact_nonuser_date_contact.adresa = new AdresaCreate()
    this.pacient.intervale_indisponibilitate = []
    this.pacientiService.getPacientifromServer()
      .subscribe((data) => {
        if (data){
          this.PacientList = data
          this.dataSource = new MatTableDataSource(this.PacientList);
        }
      })
    this.reprezentant_legal_nonuser_date_personale = new DatePersonaleCreate();
    // this.pacient.program_de_somn[0] = new Valabilitate()
  }
  removeRLfromToDelte(element){
    var intermediar_lista = []
    var gasit = false
    for (var i = 0; i < this.todeleteRL.length; i++){
      if (i == this.todeleteRL.indexOf(element)){
        gasit = true
      }
      else{
        intermediar_lista.push(this.todeleteRL[i])
      }
    }
    this.todeleteRL = intermediar_lista
  }

  removePCfromToDelte(element){
    var intermediar_lista = []
    var gasit = false
    for (var i = 0; i < this.todeletePC.length; i++){
      if (i == this.todeletePC.indexOf(element)){
        gasit = true
      }
      else{
        intermediar_lista.push(this.todeletePC[i])
      }
    }
    this.todeletePC = intermediar_lista
  }

  checkRLinListaDeSters( element ){
    if (this.todeleteRL.indexOf(element) >= 0){
      return true
    }
    return false
  }

  checkPCinListaDeSters( element ){
    if (this.todeletePC.indexOf(element) >= 0){
      return true
    }
    return false
  }

  marktodeletePC(PC_de_sters){
    console.log ("RL DE STERS ==>> ", PC_de_sters)
    var index_PC_de_sters_intermediar = this.listacontact.indexOf(PC_de_sters)
    // check daca lista RL marcat pt stergere are elemente
    if ( this.todeletePC.length > 0){
      //daca lista are elemente vad daca RL de sters este deja element
      var index_PC_deja_selectat = this.todeletePC.indexOf(PC_de_sters)
      if( index_PC_deja_selectat >= 0){
        //daca este deja selectat procedam la deselectarea lui si scoaterea din lista RL marcati pt delete
        console.log("PC deja selectat => Perform deselect (remove from to delete list)")
        if(this.todeletePC.length == 1){
          this.todeletePC = []
          console.log("PC deja selectat => Perform deselect ==>> todeletePC lenght > 1 ==>> ", this.todeletePC)
          this.dataSourcethree  = new MatTableDataSource(this.listacontact);
        }
        else{
          this.removePCfromToDelte(PC_de_sters)
        }
      }
      //daca nu este deja selectat
      else{
        //procedam la adaugarea elementului in lista to delete rl
        console.log("PC Neselectat => Perform add to todeletePC list ==>> ", this.todeletePC)
        this.todeletePC.push(PC_de_sters)
        console.log("PC Neselectat => Performed  add to todeletePC list result ==>> ", this.todeletePC)
      }
      // if (index_RL_de_sters_intermediar >= 0){
      // }
      // else{

      // }
    }
    else{
      if (this.todeletePC.length == 0){
        this.todeletePC.push(PC_de_sters)
      }
    }
    // this.todeletePC.push(PC_de_sters)
    console.log ("todeletePC DE STERS ==>> ", this.todeletePC)
  }

  marktodeleteRL(RL_de_sters){
    console.log ("RL DE STERS ==>> ", RL_de_sters)
    var index_RL_de_sters_intermediar = this.lista.indexOf(RL_de_sters)
    // check daca lista RL marcat pt stergere are elemente
    if ( this.todeleteRL.length > 0){
      //daca lista are elemente vad daca RL de sters este deja element
      var index_RL_deja_selectat = this.todeleteRL.indexOf(RL_de_sters)
      if( index_RL_deja_selectat >= 0){
        //daca este deja selectat procedam la deselectarea lui si scoaterea din lista RL marcati pt delete
        console.log("RL deja selectat => Perform deselect (remove from to delete list)")
        if(this.todeleteRL.length == 1){
          this.todeleteRL = []
          this.dataSourcetwo  = new MatTableDataSource(this.lista);
        }
        else{
          this.removeRLfromToDelte(RL_de_sters)
        }
      }
      //daca nu este deja selectat
      else{
        //procedam la adaugarea elementului in lista to delete rl
        this.todeleteRL.push(RL_de_sters)
      }
      // if (index_RL_de_sters_intermediar >= 0){
      // }
      // else{

      // }
    }
    this.todeleteRL.push(RL_de_sters)
    console.log ("todeleteRL DE STERS ==>> ", this.todeleteRL)
  }
  deletePC(){
    console.log("delete PC")
    // verific daca lungimea listei de RL este egala cu 1
    if (this.listacontact.length == 1){
      //daca lista de RL este egala cu 1 atunci sterg lista initializand cu o lista goala
      this.listacontact = []
      this.todeletePC = []
      this.dataSourcethree  = new MatTableDataSource(this.listacontact);
      this.reInitPC()
    }
    else{
      // daca lungimea listei este mai mare decat 1
      //initializez o lista intermediara
      var intermediar_lista_PC = []
      //verific  lungimea elementelor marcate pentru stergere
      if (this.todeletePC.length == 1){
        // daca lungimea elementelor marcare este 1
        // parcurg lista si adaug in lista rl intermediara elementele care nu sunt marcate
        for (var q = 0; q < this.listacontact.length; q++){
          if (q == this.listacontact.indexOf(this.todeletePC[0])){
            console.log("Element marcat, nu-l adaug. to delete / lista", this.todeletePC," // ", this.listacontact, " // q = ",q)
          }
          else{
            intermediar_lista_PC.push(this.listacontact[q])
          }
        }
        this.todeletePC = []
        this.listacontact = intermediar_lista_PC
        this.dataSourcethree  = new MatTableDataSource(this.listacontact);
      }

      else{
        // daca elementele marcate sunt mai multe decat 1
        if (this.todeletePC.length >= 1){
          //parcurg lista RL si verific daca elementul de la index-ul k se regaseste in lista elementelor marcate
          for (var k = 0; k < this.listacontact.length; k++){
            if (this.todeletePC.indexOf(this.listacontact[k]) >= 0){
              console.log("element marcat, nu-l adaug in intermediar")
            }
            else{
              intermediar_lista_PC.push(this.listacontact[k])
            }
          }
        }
        this.todeletePC = []
        this.listacontact = intermediar_lista_PC
        this.dataSourcethree  = new MatTableDataSource(this.listacontact);
      }
    }
  }

  deleteRL(){
    console.log("delete RL")
    // verific daca lungimea listei de RL este egala cu 1
    if (this.lista.length == 1){
      //daca lista de RL este egala cu 1 atunci sterg lista initializand cu o lista goala
      this.lista = []
      this.todeleteRL = []
      this.dataSourcetwo  = new MatTableDataSource(this.lista);
      this.reInitRL()
    }
    else{
      // daca lungimea listei este mai mare decat 1
      //initializez o lista intermediara
      var intermediar_lista_RL = []
      //verific  lungimea elementelor marcate pentru stergere
      if (this.todeleteRL.length == 1){
        // daca lungimea elementelor marcare este 1
        // parcurg lista si adaug in lista rl intermediara elementele care nu sunt marcate
        for (var q = 0; q < this.lista.length; q++){
          if (q == this.lista.indexOf(this.todeleteRL[0])){
            console.log("Element marcat, nu-l adaug. to delete / lista", this.todeleteRL," // ", this.lista, " // q = ",q)
          }
          else{
            intermediar_lista_RL.push(this.lista[q])
          }
        }
        this.todeleteRL = []
        this.lista = intermediar_lista_RL
        this.dataSourcetwo  = new MatTableDataSource(this.lista);
      }

      else{
        // daca elementele marcate sunt mai multe decat 1
        if (this.todeleteRL.length >= 1){
          //parcurg lista RL si verific daca elementul de la index-ul k se regaseste in lista elementelor marcate
          for (var k = 0; k < this.lista.length; k++){
            if (this.todeleteRL.indexOf(this.lista[k]) >= 0){
              console.log("element marcat, nu-l adaug in intermediar")
            }
            else{
              intermediar_lista_RL.push(this.lista[k])
            }
          }
        }
        this.todeleteRL = []
        this.lista = intermediar_lista_RL
        this.dataSourcetwo  = new MatTableDataSource(this.lista);
      }
    }
  }
  anuleazaPCEditat(){
    this.addnewcontactperson = 1
    this.flag_PC_in_editare = false
    this.listacontact[this.listacontact.indexOf(this.old_PC_element)].datepersonale = this.persoana_contact_nonuser_date_personale
    this.dataSourcethree  = new MatTableDataSource(this.listacontact);
    // this.reInitRL()
  }
  anuleazaRLEditat(){
    this.addnewperson = 1
    this.flag_RL_in_editare = false
    this.lista[this.lista.indexOf(this.old_RL_element)].datepersonale = this.reprezentant_legal_nonuser_date_personale
    this.dataSourcetwo  = new MatTableDataSource(this.lista);
    // this.reInitRL()
  }
  salveazaPCEditat(){
    this.addnewcontactperson = 1
    this.flag_PC_in_editare = false
    if (this.old_PC_element.datecontact.adresa != undefined){
      if(this.checkObjectHasAnyValueDefined(this.old_PC_element.datecontact.adresa)){
        if (!this.checkObjectHasAnyValueDefined(this.adresaPC)){
          this.listacontact[this.listacontact.indexOf(this.old_PC_element)].datecontact.adresa = undefined
        }
      }
    }
    if (!this.flag_persoana_contact_ci){
      if(this.old_PC_element.datepersonale.numar_carte_identitate != null ){
        this.listacontact[this.listacontact.indexOf(this.old_PC_element)].datepersonale.numar_carte_identitate = undefined
      }
      if(this.old_PC_element.datepersonale.serie_carte_identitate != null ){
        this.listacontact[this.listacontact.indexOf(this.old_PC_element)].datepersonale.serie_carte_identitate = undefined
      }
    }
    this.listacontact[this.listacontact.indexOf(this.old_PC_element)].calitateid = this.pacient.persoana_contact_calitateid
    this.flag_persoana_contact_ci = false
    this.dataSourcethree  = new MatTableDataSource(this.listacontact);
    delete this.pacient.persoana_contact_calitateid
  }
  salveazaRLEditat(){
    this.addnewperson = 1
    this.flag_RL_in_editare = false
    console.log("Salveaza RL editat = adresa RL ==>> ", this.adresaRL)
    console.log("Salveaza RL editat = adresa RL old ==>> ", this.old_RL_element)
    if (this.old_RL_element.datecontact.adresa != undefined){
      if(this.checkObjectHasAnyValueDefined(this.old_RL_element.datecontact.adresa)){
        if (!this.checkObjectHasAnyValueDefined(this.adresaRL)){
          console.log("Salveaza RL editat = this.lista.indexOf(this.old_RL_element)] ==>> ", this.lista[this.lista.indexOf(this.old_RL_element)])
          this.lista[this.lista.indexOf(this.old_RL_element)].datecontact.adresa = undefined
          console.log("Salveaza RL editat = this.lista.indexOf(this.old_RL_element)] ==>> 222 ==>> ", this.lista[this.lista.indexOf(this.old_RL_element)])
        }
      }
    }
    if (!this.flag_reprezentant_legal_ci){
      if(this.old_RL_element.datepersonale.numar_carte_identitate != null ){
        this.lista[this.lista.indexOf(this.old_RL_element)].datepersonale.numar_carte_identitate = undefined
      }
      if(this.old_RL_element.datepersonale.serie_carte_identitate != null ){
        this.lista[this.lista.indexOf(this.old_RL_element)].datepersonale.serie_carte_identitate = undefined
      }
    }

    this.lista[this.lista.indexOf(this.old_RL_element)].calitateid = this.pacient.reprezentant_legal_calitateid
    this.flag_reprezentant_legal_ci = false
    this.dataSourcetwo  = new MatTableDataSource(this.lista);
    delete this.pacient.reprezentant_legal_calitateid

  }
  checkTelefonOldValue(value){
    if (value == this.old_telefon_RL){
      return true
    }
    return false
  }
  checkTelefonPCOldValue(value){
    if (value == this.old_telefon_PC){
      return true
    }
    return false
  }
  checkEmailPCOldValue(value){
    return this.old_PC_element.datecontact.email == this.email_PC.value
  }

  checkEmailOldValue(value){
    return this.old_RL_element.datecontact.email == this.email_RL.value
  }

  saveTelefonEmailPCEditat(){
    this.email_PC.disable()
    this.telefon_PC.disable()
    if (!this.checkTelefonPCOldValue(this.telefon_PC.value)){
      this.persoana_contact_nonuser_date_contact.telefon = this.telefon_PC.value
      this.listacontact[this.listacontact.indexOf(this.old_PC_element)].datecontact.telefon = this.telefon_PC.value
    }
    if(!this.checkEmailPCOldValue(this.email_PC.value)){
      this.persoana_contact_nonuser_date_contact.email = this.email_PC.value
      this.listacontact[this.listacontact.indexOf(this.old_PC_element)].datecontact.email = this.email_PC.value
    }
    this.flag_telefon_email_PC_saved = true
  }

  saveTelefonEmailRLEditat(){
    this.email_RL.disable()
    this.telefon_RL.disable()
    console.log("telefon old value ==>> ", this.old_RL_element)
    console.log("this.checkTelefonOldValue(this.telefon_RL.value) ==>> ", this.checkTelefonOldValue(this.telefon_RL.value))
    if (!this.checkTelefonOldValue(this.telefon_RL.value)){
      this.reprezentant_legal_nonuser_date_contact.telefon = this.telefon_RL.value
      // this.pacienttwo.reprezentant_legal_nonusers[this.pacienttwo.reprezentant_legal_nonusers.indexOf(this.old_RL_element.datecontact)].date_contact.telefon = this.telefon_RL.value
      this.lista[this.lista.indexOf(this.old_RL_element)].datecontact.telefon = this.telefon_RL.value
    }
    if(!this.checkEmailOldValue(this.email_RL.value)){
      this.reprezentant_legal_nonuser_date_contact.email = this.email_RL.value
      this.lista[this.lista.indexOf(this.old_RL_element)].datecontact.email = this.email_RL.value
    }
    this.flag_telefon_email_RL_saved = true
  }

  editeazaPersoanaContact(element){
    this.flag_PC_in_editare = true
    this.old_PC_element = element
    this.reInitPC()
    this.selectedTabPC = 0
    this.persoana_contact_nonuser_date_personale = new DatePersonaleCreate();
    this.persoana_contact_nonuser_date_personale = element.datepersonale
    this.pacient.persoana_contact_calitateid = element.calitateid
    this.persoana_contact_nonuser_date_contact = new DateContactCreate()
    this.persoana_contact_nonuser_date_contact = element.datecontact
    this.flag_adresa_PC_set = false
    this.flag_adresa_PC_add = false
    if(element.datepersonale.numar_carte_identitate != undefined || element.datepersonale.serie_carte_identitate != undefined){
      this.flag_persoana_contact_ci = true
    }
    if(element.datecontact.telefon != undefined){
      this.telefon_PC = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
      this.telefon_PC.setValue(element.datecontact.telefon)
      this.old_telefon_PC = element.datecontact.telefon
      this.old_PC_element = element
    }
    if(element.datecontact.email != undefined ){
      this.email_PC= new FormControl('',[Validators.email])
      this.email_PC.setValue(element.datecontact.email)
      if (this.flag_adresa_PC_set == false){
        this.flag_adresa_PC_set = true
        if (!this.flag_telefon_email_PC_saved){
          this.flag_telefon_email_PC_saved = true
          this.telefon_PC.disable()
          this.email_PC.disable()
        }
      }
      if (element.datecontact.email == undefined && element.datecontact.telefon == undefined){
        this.email_PC= new FormControl('',[Validators.email])
        this.telefon_PC = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
        if (this.flag_telefon_email_PC_saved){
          this.flag_telefon_email_PC_saved = false
        }
        this.flag_PC_in_editare = false
        this.email_PC.enable()
        this.telefon_PC.enable()
      }
    }
    if(element.datecontact.adresa != undefined && this.checkObjectHasAnyValueDefined(element.datecontact.adresa)){
      this.flag_adresa_PC_set = true
      this.flag_adresa_PC_add = true
      for (var q of Object.keys(element.datecontact.adresa)){
        if (element.datecontact.adresa[q] != undefined){
          this.adresaPC[q] = element.datecontact.adresa[q]
        }
      }
    }

  }

  editeazaReprezentantLegal(element){
    console.log("RL Editat ==>> ", element)
    this.flag_RL_in_editare = true
    this.old_RL_element = element
    this.reInitRL()
    this.selectedTabRL = 0
    this.reprezentant_legal_nonuser_date_personale = new DatePersonaleCreate();
    this.reprezentant_legal_nonuser_date_personale = element.datepersonale
    this.pacient.reprezentant_legal_calitateid = element.calitateid
    this.reprezentant_legal_nonuser_date_contact = new DateContactCreate()
    this.reprezentant_legal_nonuser_date_contact = element.datecontact
    this.flag_adresa_RL_set = false
    this.flag_adresa_RL_add = false
    // this.flag_telefon_email_RL_saved = true
    if(element.datepersonale.numar_carte_identitate != undefined || element.datepersonale.serie_carte_identitate != undefined){
      this.flag_reprezentant_legal_ci = true
    }
    if(element.datecontact.telefon != undefined){
      this.telefon_RL = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
      this.telefon_RL.setValue(element.datecontact.telefon)
      this.old_telefon_RL = element.datecontact.telefon
      this.old_RL_element = element
    }
    if(element.datecontact.email != undefined ){
      this.email_RL= new FormControl('',[Validators.email])
      this.email_RL.setValue(element.datecontact.email)
      if (this.flag_adresa_RL_set == false){
        this.flag_adresa_RL_set = true
        if (!this.flag_telefon_email_RL_saved){
          this.flag_telefon_email_RL_saved = true
          this.telefon_RL.disable()
          this.email_RL.disable()
        }
      }
      if (element.datecontact.email == undefined && element.datecontact.telefon == undefined){
        console.log("STATEMENT TRUE !!")
        this.email_RL= new FormControl('',[Validators.email])
        this.telefon_RL = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
        if (this.flag_telefon_email_RL_saved){
          this.flag_telefon_email_RL_saved = false
        }
        this.flag_RL_in_editare = false
        this.email_RL.enable()
        this.telefon_RL.enable()
      }
    }

    if(element.datecontact.adresa != undefined && this.checkObjectHasAnyValueDefined(element.datecontact.adresa)){
      this.flag_adresa_RL_set = true
      this.flag_adresa_RL_add = true
      for (var q of Object.keys(element.datecontact.adresa)){
        if (element.datecontact.adresa[q] != undefined){
          this.adresaRL[q] = element.datecontact.adresa[q]
        }
      }
    }



  }
reInitRL(){
  this.addnewperson = 0
  this.adresaRL = new AdresaCreate()
  this.telefon_RL = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
  this.email_RL= new FormControl('',[Validators.email])
  this.reprezentant_legal_nonuser_date_personale = new DatePersonaleCreate();
  this.reprezentant_legal_nonuser_date_contact = new DateContactCreate()
  this.reprezentant_legal_nonuser_date_contact.adresa = new AdresaCreate()
  this.flag_adresa_RL_add = false;
  this.flag_telefon_email_RL_saved = false;
  this.selectedTabRL = 0
  this.flag_adresa_RL_set = false
  this.flag_reprezentant_legal_ci = false
}
reInitPC(){
  this.addnewcontactperson = 0
  this.adresaPC = new AdresaCreate()
  this.telefon_PC = new FormControl('', [Validators.pattern('0[0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)])
  this.email_PC= new FormControl('',[Validators.email])
  this.persoana_contact_nonuser_date_personale = new DatePersonaleCreate();
  this.persoana_contact_nonuser_date_contact = new DateContactCreate()
  this.persoana_contact_nonuser_date_contact.adresa = new AdresaCreate()
  this.flag_adresa_PC_add = false;
  this.flag_telefon_email_PC_saved = false;
  this.selectedTabPC = 0
  this.flag_adresa_PC_set = false
  this.flag_persoana_contact_ci = false
}

stergeAdresaBeneficiar(){
  this.adresa = new AdresaCreate()
  this.flag_adresa_beneficiar_add = false
  this.flag_adresa_beneficiar_set = false

}
initFlagsRLDateContact(){
  this.flag_telefon_email_RL_saved = false
}
beneficiarFaraAdresa(){
  this.flag_beneficiar_fara_adresa = !this.flag_beneficiar_fara_adresa
}
adaugaAdresaBeneficiar(){
  console.log("Adresa judet == >> ", this.adresa.judet )

    this.flag_adresa_beneficiar_add = true

}

salveazaAdresaBeneficiar(){
  if (this.adresa.judet == undefined || this.adresa.localitate == undefined || this.adresa.strada == undefined ){
    this._snackBar.open("Completeaza Judetul, Localitatea si Strada pentru a putea salva","OK")
  }
  else{
    this.flag_adresa_beneficiar_set = true

  }
}

editeazaAdresaBeneficiar(){
  this.flag_adresa_beneficiar_set = false
}

anuleazaAdresaBeneficiar(){
  this.flag_adresa_beneficiar_add = false
}

salveazaAdresaPC(){
  // console.log("Salveaza Adresa RL == reprezentant legal ==>> ",this.reprezentant_legal_nonuser_date_contact)
  // console.log("Salveaza Adresa RL == this.adresaRL ==>> ",this.adresaRL)
  // if (this.checkObjectHasAnyValueDefined(this.reprezentant_legal_nonuser_date_contact.adresa))
  if (this.persoana_contact_nonuser_date_contact.adresa == undefined){
    // console.log("Reprezentant fara adresa !! ")
    this.persoana_contact_nonuser_date_contact.adresa = new AdresaCreate();
  }
  if (this.adresaPC.judet == undefined || this.adresaPC.localitate == undefined || this.adresaPC.strada == undefined ){
    this._snackBar.open("Completeaza Judetul, Localitatea si Strada pentru a putea salva","OK")
  }
  else{
    if (this.adresaPC.judet != undefined){
      this.persoana_contact_nonuser_date_contact.adresa.judet = this.adresaPC.judet
    }
    if (this.adresaPC.localitate != undefined){
      this.persoana_contact_nonuser_date_contact.adresa.localitate = this.adresaPC.localitate
    }
    if (this.adresaPC.strada != undefined){
      this.persoana_contact_nonuser_date_contact.adresa.strada = this.adresaPC.strada
    }
    if (this.adresaPC.numar != undefined){
      this.persoana_contact_nonuser_date_contact.adresa.numar = this.adresaPC.numar
    }
    if (this.adresaPC.bloc != undefined){
      this.persoana_contact_nonuser_date_contact.adresa.bloc = this.adresaPC.bloc
    }
    if (this.adresaPC.etaj != undefined){
      this.persoana_contact_nonuser_date_contact.adresa.etaj = this.adresaPC.etaj
    }
    if (this.adresaPC.ap != undefined){
      this.persoana_contact_nonuser_date_contact.adresa.ap = this.adresaPC.ap
    }

    this.flag_adresa_PC_set = true

  }
}

salveazaAdresaRL(){
  console.log("Salveaza Adresa RL == reprezentant legal ==>> ",this.reprezentant_legal_nonuser_date_contact)
  console.log("Salveaza Adresa RL == this.adresaRL ==>> ",this.adresaRL)
  // if (this.checkObjectHasAnyValueDefined(this.reprezentant_legal_nonuser_date_contact.adresa))
  if (this.reprezentant_legal_nonuser_date_contact.adresa == undefined){
    console.log("Reprezentant fara adresa !! ")
    this.reprezentant_legal_nonuser_date_contact.adresa = new AdresaCreate();
  }
  if (this.adresaRL.judet == undefined || this.adresaRL.localitate == undefined || this.adresaRL.strada == undefined ){
    this._snackBar.open("Completeaza Judetul, Localitatea si Strada pentru a putea salva","OK")
  }
  else{
    if (this.adresaRL.judet != undefined){
      this.reprezentant_legal_nonuser_date_contact.adresa.judet = this.adresaRL.judet
    }
    if (this.adresaRL.localitate != undefined){
      this.reprezentant_legal_nonuser_date_contact.adresa.localitate = this.adresaRL.localitate
    }
    if (this.adresaRL.strada != undefined){
      this.reprezentant_legal_nonuser_date_contact.adresa.strada = this.adresaRL.strada
    }
    if (this.adresaRL.numar != undefined){
      this.reprezentant_legal_nonuser_date_contact.adresa.numar = this.adresaRL.numar
    }
    if (this.adresaRL.bloc != undefined){
      this.reprezentant_legal_nonuser_date_contact.adresa.bloc = this.adresaRL.bloc
    }
    if (this.adresaRL.etaj != undefined){
      this.reprezentant_legal_nonuser_date_contact.adresa.etaj = this.adresaRL.etaj
    }
    if (this.adresaRL.ap != undefined){
      this.reprezentant_legal_nonuser_date_contact.adresa.ap = this.adresaRL.ap
    }

    this.flag_adresa_RL_set = true

  }
}
adaugaAdresaPC(){
  this.flag_adresa_PC_add = true
  if (this.flag_adresa_PC_set){
    this.flag_adresa_PC_set = false
  }
}

adaugaAdresaRL(){
    this.flag_adresa_RL_add = true
    if (this.flag_adresa_RL_set){
      this.flag_adresa_RL_set = false
    }
}
editeazaAdresaPC(){
  this.flag_adresa_PC_set = false
}

editeazaAdresaRL(){
  this.flag_adresa_RL_set = false
}
stergeAdresaPC(){
  this.adresaPC = new AdresaCreate()
  this.flag_adresa_PC_add = false
  this.flag_adresa_PC_set = false

}
stergeAdresaRL(){
  this.adresaRL = new AdresaCreate()
  this.flag_adresa_RL_add = false
  this.flag_adresa_RL_set = false

}
anuleazaAdresaPC(){
  this.flag_adresa_PC_add = false
}
anuleazaAdresaRL(){
  this.flag_adresa_RL_add = false
}
copiazaAdresaBeneficiarPC(){
  this.adresaPC = this.adresa
  this.salveazaAdresaPC()
  this.flag_adresa_PC_add = true
  this.flag_adresa_PC_set = true
}
copiazaAdresaBeneficiar(){
  this.adresaRL = this.adresa
  this.salveazaAdresaRL()
  this.flag_adresa_RL_add = true
  this.flag_adresa_RL_set = true
}
copiazaAdresaBeneficiarToPC(){
  this.adresaPC = this.adresa
  this.salveazaAdresaPC()
  this.flag_adresa_PC_add = true
  this.flag_adresa_PC_set = true
}
RLFaraAdresa(){
  this.flag_RL_fara_adresa = !this.flag_RL_fara_adresa
}
PCFaraAdresa(){
  this.flag_PC_fara_adresa = !this.flag_PC_fara_adresa
}

onTelefonValid(){
  this.flag_telefon_beneficiar_valid = true
  console.log("Telefon Valid")
}
returnCaractereTelefon(value){
  // console.log("Value from return caractere telefon ==>> ", value.toString().length)
  return(value.toString().length)
}
editeazaTelefonEmailPC(){
  this.flag_telefon_email_PC_saved = false
  this.email_PC.enable()
  this.telefon_PC.enable()
}

editeazaTelefonEmailRL(){
  this.flag_telefon_email_RL_saved = false
  this.email_RL.enable()
  this.telefon_RL.enable()
}

saveTelefonEmailPC(){
  this.email_PC.disable()
  this.telefon_PC.disable()
  if (this.telefon_PC.value != '') {
    this.persoana_contact_nonuser_date_contact.telefon = this.telefon_PC.value
  }
  if (this.email_PC.value != ''){
    this.persoana_contact_nonuser_date_contact.email = this.email_PC.value
  }
  this.flag_telefon_email_PC_saved = true
}

saveTelefonEmailRL(){
  this.email_RL.disable()
  this.telefon_RL.disable()
  if (this.telefon_RL.value != '') {
    this.reprezentant_legal_nonuser_date_contact.telefon = this.telefon_RL.value
  }
  if (this.email_RL.value != ''){
    this.reprezentant_legal_nonuser_date_contact.email = this.email_RL.value
  }
  this.flag_telefon_email_RL_saved = true
}

saveTelefonEmailBeneficiar(){
  this.email_beneficiar.disable()
  this.telefon_beneficiar.disable()
  if (this.email_beneficiar.value != ''){
    this.date_contact.email = this.email_beneficiar.value
  }
  if (this.telefon_beneficiar.value != ''){
    this.date_contact.telefon = this.telefon_beneficiar.value
  }
  this.flag_telefon_email_saved = true
}
editeazaTelefonEmailBeneficiar(){
  this.flag_telefon_email_saved = false
  this.email_beneficiar.enable()
  this.telefon_beneficiar.enable()
}
getCalitateKey(calitateid){
  for (let i of this.calitate){
    if (i.id == calitateid){
      return (i.calitate)
    }
  }
  return ("N/A")
}
adaugaDetaliiIntervalIndisponibilitate(){
  // this.interval_indisponibilitate_nou = new IntervalIndisponibilitateCreate()
  if (!this.flag_camp_detalii_activ){
    this.flag_camp_detalii_activ = true
  }
}
nextStepButtonText(){
  if (this.selectedTab < this.nextStepButton.length){
    return this.nextStepButton[this.selectedTab+1]
  }
  return this.nextStepButton[this.selectedTab]
}
verificaSuprapunereInterval(ora_start, ora_end){
  var pozitie_minim = -1
  for(var i = 0; i < this.pacient.intervale_indisponibilitate.length;i++ ){
    console.log("Pacient.intervale orare de I ==>> ", i, " == >> ", this.pacient.intervale_indisponibilitate[i])
    if (ora_start >= this.pacient.intervale_indisponibilitate[i].ora_start && ora_start < this.pacient.intervale_indisponibilitate[i].ora_end){
      console.log ( " Suprapunere Caz 1 - Start mai mare sau egal cu start si mai mic decat end ")
      // this._snackBar.open("Intervalele se suprapun. Intervalul: "+this.interval_start.getHours()+":"+this.interval_start.getMinutes()+" <=> "+this.interval_end.getHours()+" : "+this.interval_end.getMinutes()+" Se suprapune cu intervalul: "+this.pacient.intervale_indisponibilitate[i].ora_start+" <=> "+this.pacient.intervale_indisponibilitate[i].ora_end+" !!","Editeaza Intervalul")
      if (ora_end <= this.pacient.intervale_indisponibilitate[i].ora_end){
        this._snackBar.open("Intervalele se suprapun. Intervalul: "+ora_start+" <=> "+ora_end+" Se suprapune cu intervalul: "+this.pacient.intervale_indisponibilitate[i].ora_start+" <=> "+this.pacient.intervale_indisponibilitate[i].ora_end+". Intervalul setat este inclus intr-un interval deja setat. Editeaza intervalul salvat cu detalii despre acest nou interval.","Editeaza Intervalul")
        return 1
      }
      if(ora_end > this.pacient.intervale_indisponibilitate[i].ora_end){
        this._snackBar.open("Intervalele se suprapun. Intervalul: "+ora_start+" <=> "+ora_end+" Se suprapune cu intervalul: "+this.pacient.intervale_indisponibilitate[i].ora_start+" <=> "+this.pacient.intervale_indisponibilitate[i].ora_end+". Editeaza intervalul deja salvat: "+this.pacient.intervale_indisponibilitate[i].ora_start+" <=> "+this.pacient.intervale_indisponibilitate[i].ora_end+" prelungind pana la "+ora_end,"Editeaza Intervalul")
        return 1
      }
    }
    else{
      if(ora_end > this.pacient.intervale_indisponibilitate[i].ora_start && ora_end <= this.pacient. intervale_indisponibilitate[i].ora_end ){
        console.log ( " Suprapunere Caz 2 - End mai mare decat start si mai mic sau egal cu end ")
        return 2
      }
    }
    if ( this.pacient.intervale_indisponibilitate[i].ora_start > ora_start ){
      // minim = this.pacient.intervale_indisponibilitate[i].ora_start
      if (pozitie_minim < 0){
        pozitie_minim = i
        console.log ( " Pozitie minim din if = ", pozitie_minim)
      }
    }
  }
  console.log ( " Pozitie minim final = ", pozitie_minim)
  this.intermediar_poztitie_minim = pozitie_minim
  return 0
}

reInitIntervalStartEnd(ora_start?:string, ora_end?:string){
  if(ora_start){
    this.interval_start = this.intervalService.returnDateString(ora_start)
    if(ora_end){
      this.interval_end = this.intervalService.returnDateString(ora_end)
    }
    else{
      this.interval_end = new Date()
      this.interval_end.setMinutes(this.interval_start.getMinutes() + 30)
    }
  }
  else{
    // if(ora_end){}
    this.interval_start = new Date(new Date().setHours(8,0,0))
    this.interval_end = new Date(new Date().setHours(8,30,0))
  }
}
salveazaIntervalNou(){
  this.adaugaIntervaleIndisponibilitate()
  this.flag_show_detalii_interval = false
  this.flag_salveaza_interval_nou = false
  this.flag_adauga_alt_interval = true
}
adaugaIntervalNou(){
  this.flag_show_detalii_interval = true
  this.flag_adauga_alt_interval = false
  this.flag_salveaza_interval_nou = true
}
adaugaIntervaleIndisponibilitate(){
  // console.log("Interval ==>> ", this.interval_indisponibilitate_nou)
  var intermediar_interval_ora_start = this.intervalService.returnStringDate(this.interval_start)
  var intermediar_interval_ora_end = this.intervalService.returnStringDate(this.interval_end)
  // console.log("Interval 22 ==>> ", this.interval_indisponibilitate_nou)
  if (intermediar_interval_ora_start === intermediar_interval_ora_end){
    console.log( " Interval start egal cu end !! ")
    this._snackBar.open("Intervalul este gol. Ora de inceput este egala cu ora de final. ","Editeaza Intervalul")
  }
  else {
    if (intermediar_interval_ora_start > intermediar_interval_ora_end ){
      this._snackBar.open("Sfarsitul intervalului este inaintea orei de start ","Editeaza Intervalul")
    }
    else{
      if(this.pacient.intervale_indisponibilitate.length > 0){
        console.log("Este deja un interval")
        var intermediar_suprapunere = this.verificaSuprapunereInterval(intermediar_interval_ora_start,intermediar_interval_ora_end)
        if( intermediar_suprapunere == 0 ){
          console.log("Nu exista suprapuneri")
          this.interval_indisponibilitate_nou.ora_start = intermediar_interval_ora_start
          this.interval_indisponibilitate_nou.ora_end = intermediar_interval_ora_end
          if(this.intermediar_poztitie_minim < 0 ){
            this.pacient.intervale_indisponibilitate.push(this.interval_indisponibilitate_nou)
          }
          else{
            var intermediar_lista = []
            if (this.intermediar_poztitie_minim == 0){
              intermediar_lista.push(this.interval_indisponibilitate_nou)
              for (var p of this.pacient.intervale_indisponibilitate){
                intermediar_lista.push(p)
              }
              this.pacient.intervale_indisponibilitate = intermediar_lista
            }
            else{
              for (var contor = 0; contor < this.pacient.intervale_indisponibilitate.length; contor++){
                if (contor === this.intermediar_poztitie_minim){
                  intermediar_lista.push(this.interval_indisponibilitate_nou)
                  intermediar_lista.push(this.pacient.intervale_indisponibilitate[contor])
                }
                else{
                  intermediar_lista.push(this.pacient.intervale_indisponibilitate[contor])
                }
              }
              this.pacient.intervale_indisponibilitate = intermediar_lista

            }
          }
          this.interval_start = new Date()
          this.interval_end = new Date()
          this.intermediar_poztitie_minim = -1
          this.interval_indisponibilitate_nou = new IntervalIndisponibilitateCreate()
          if (this.flag_camp_detalii_activ){
            this.flag_camp_detalii_activ = !this.flag_camp_detalii_activ
          }
          this.intervale_indisponibilitate_dataSource = new MatTableDataSource(this.pacient.intervale_indisponibilitate)
          // this.interval_indisponibilitate_nou.ora_end = new Date()
          // this.interval_indisponibilitate_nou.ora_start = new Date()
        }

      }
      else{
        this.flag_adauga_primul_interval = false
        this.flag_show_detalii_interval = false
        this.flag_adauga_alt_interval = true
        this.interval_indisponibilitate_nou.ora_start = intermediar_interval_ora_start
        this.interval_indisponibilitate_nou.ora_end = intermediar_interval_ora_end
        this.pacient.intervale_indisponibilitate.push(this.interval_indisponibilitate_nou)
        this.interval_start = new Date()
        this.interval_end = new Date()
        this.intermediar_poztitie_minim = -1
        this.interval_indisponibilitate_nou = new IntervalIndisponibilitateCreate()
        if (!this.flag_sunt_orare_indisponibilitate && this.pacient.intervale_indisponibilitate.length>0){
          this.flag_sunt_orare_indisponibilitate = true
        }
        if(this.flag_camp_detalii_activ){
          this.flag_camp_detalii_activ = false
        }
        this.intervale_indisponibilitate_dataSource = new MatTableDataSource(this.pacient.intervale_indisponibilitate)
      }
    }
  }

  // this.interval_indisponibilitate_nou.ora_start = new Date()
}
adaugaIntervalOrdonat(interval_de_adaugat){

}
removeFromToDelete(elementul_de_sters){
  var intermediar_todelete = []
  for (var i in this.todelete){
    // console.log(" I este egal cu elementul de sters 22 ==>> ", i, " << == >> ", elementul_de_sters)
    if( i == elementul_de_sters ){
      // console.log(" I este egal cu elementul de sters ==>> ", i, " << == >> ", elementul_de_sters)
    }
    else{
      intermediar_todelete.push(this.todelete[i])
    }
  }
  this.todelete = intermediar_todelete
  console.log("this.todelete == >> ", this.todelete)

}
marktodelete(element){
  // console.log("mark to delete", element)
  var intermediar_index = this.pacient.intervale_indisponibilitate.indexOf(element)
  if (this.todelete.length > 0)
  {
    var intermediar_check_index_already_market = this.todelete.indexOf(intermediar_index)
    if (intermediar_check_index_already_market >= 0 ){
      console.log("elementul este deja marcat")
      if (this.todelete.length == 1){
        this.todelete = []
      }
      else{
        this.removeFromToDelete(intermediar_check_index_already_market)
      }
    }
    else {
      console.log("elementul nu este deja marcat")
      this.todelete.push(intermediar_index)
      console.log("to delete ==>> ", this.todelete)
    }
  }
  else{
    this.todelete.push(intermediar_index)
    console.log("to delete este gol ==>> ", this.todelete)

  }

}
goToNextTabPC(){
  var complet:boolean = true
  var mesaj=" Toate campurile sunt obligatorii pentru a continua. Urmatoarele campuri sunt necompletate : "
  console.log("this.reprezentant_legal_nonuser_date_personale.nume ==>> ", this.persoana_contact_nonuser_date_personale.nume)
  if (this.persoana_contact_nonuser_date_personale.nume == undefined){
    mesaj = mesaj + " Nume, "
    complet = false
  }
  if (this.persoana_contact_nonuser_date_personale.prenume == undefined){
    mesaj = mesaj + " Prenume, "
    complet = false
  }
  if (this.persoana_contact_nonuser_date_personale.cnp == undefined){
    mesaj = mesaj + " CNP, "
    complet = false
  }
  if (this.persoana_contact_nonuser_date_personale.sex == undefined){
    mesaj = mesaj + " Sex, "
    complet = false
  }
  if (!this.pacient.persoana_contact_calitateid){
    mesaj = mesaj + " Calitatea, "
    complet = false
  }
  if (this.persoana_contact_nonuser_date_personale.data_nasterii == undefined){
    mesaj = mesaj + " Data Nasterii "
    complet = false
  }
  if(!complet){
    this._snackBar.open(mesaj,"Am inteles")
  }
  if (this.selectedTabPC < 1 && complet){
    this.selectedTabPC = this.selectedTabPC + 1
  }
}
goToNextTabRL(){
  var complet:boolean = true
  var mesaj=" Toate campurile sunt obligatorii pentru a continua. Urmatoarele campuri sunt necompletate : "
  console.log("this.reprezentant_legal_nonuser_date_personale.nume ==>> ", this.reprezentant_legal_nonuser_date_personale.nume)
  if (this.reprezentant_legal_nonuser_date_personale.nume == undefined){
    mesaj = mesaj + " Nume, "
    complet = false
  }
  if (this.reprezentant_legal_nonuser_date_personale.prenume == undefined){
    mesaj = mesaj + " Prenume, "
    complet = false
  }
  if (this.reprezentant_legal_nonuser_date_personale.cnp == undefined){
    mesaj = mesaj + " CNP, "
    complet = false
  }
  if (this.reprezentant_legal_nonuser_date_personale.sex == undefined){
    mesaj = mesaj + " Sex, "
    complet = false
  }
  if (!this.pacient.reprezentant_legal_calitateid){
    mesaj = mesaj + " Calitatea, "
    complet = false
  }
  if (this.reprezentant_legal_nonuser_date_personale.data_nasterii == undefined){
    mesaj = mesaj + " Data Nasterii "
    complet = false
  }
  if(!complet){
    this._snackBar.open(mesaj,"Am inteles")
  }
  if (this.selectedTabRL < 1 && complet){
    this.selectedTabRL = this.selectedTabRL + 1
  }
}

goToNextTab(){
  // this.selectedTab = 1
  if (this.selectedTab < this.nextStepButton.length){
    this.selectedTab = this.selectedTab+1
  }
  else{
    this.selectedTab = this.selectedTab
  }
}
deleteitems(){
  for (var i in this.todelete){
    this.pacient.intervale_indisponibilitate = this.pacient.intervale_indisponibilitate.filter(item => item !== this.pacient.intervale_indisponibilitate[this.todelete[i]]);
  }
  this.todelete = []
  this.intervale_indisponibilitate_dataSource = new MatTableDataSource(this.pacient.intervale_indisponibilitate)
  console.log(" deleted items ==>> ", this.pacient.intervale_indisponibilitate)

}

returnStatusMarcat(element){
  var intermediar_index = this.pacient.intervale_indisponibilitate.indexOf(element)
  // console.log("intermediar_index ==>> ",intermediar_index)
  // console.log("this.todelete ==>> ",this.todelete)
  // console.log("element ==>> ",element)
  if (this.todelete.length > 0)
  {
    // console.log("to delete lenght mai mare ca 0")
    var intermediar_check_index_already_market = this.todelete.indexOf(intermediar_index)
    // console.log("intermediar_check_index_already_market ==>> ",intermediar_check_index_already_market)
    if (intermediar_check_index_already_market >= 0 ){
      // console.log("elementul este deja marcat")
      return true
    }
  }
      // console.log("return false")
      return false
  }

editeazaIntervalIndisponibilitate(element){
  this.flag_interval_in_editare = true
  this.flag_adauga_alt_interval = false
  // console.log("Element ==>> ", element)
  if (this.intermediar_index_interval_editat < 0){
      this.intermediar_index_interval_editat = this.pacient.intervale_indisponibilitate.indexOf(element)
      // console.log("index of Element ==>> ", this.pacient.intervale_indisponibilitate.indexOf(element))
      this.flag_show_detalii_interval = true
      this.reInitIntervalStartEnd(element.ora_start, element.ora_end)
    }
    else{
      console.log (" Eroare -> Intermediar index interval editat nu este -1 ")
    }

  if (element.detalii != undefined){
    this.intermediar_interval_edit_detalii = element.detalii
    this.flag_camp_detalii_activ = true
  }

  // this.intervalService.returnDateString(this.interval_indisponibilitate_nou.ora_end)
  // this.interval_indisponibilitate_editat = element
  // this.interval_indisponibilitate_nou = new IntervalIndisponibilitateCreate()
  // this.interval_indisponibilitate_nou.ora_end = this.datepipe.transform(new Date(this.interval_indisponibilitate_editat.ora_end),'HH:mm')
}
salveazaIntervalulEditat(){

  // console.log(" intervale la inceput ==>> ", this.pacient.intervale_indisponibilitate)
  if (this.pacient.intervale_indisponibilitate[this.intermediar_index_interval_editat].ora_start != this.intervalService.returnStringDate(this.interval_start)){
    this.pacient.intervale_indisponibilitate[this.intermediar_index_interval_editat].ora_start =this.intervalService.returnStringDate(this.interval_start)
  }
  if (this.pacient.intervale_indisponibilitate[this.intermediar_index_interval_editat].ora_end != this.intervalService.returnStringDate(this.interval_end)){
    this.pacient.intervale_indisponibilitate[this.intermediar_index_interval_editat].ora_end =this.intervalService.returnStringDate(this.interval_end)
  }
  if (this.pacient.intervale_indisponibilitate[this.intermediar_index_interval_editat].detalii != this.intermediar_interval_edit_detalii){
    this.pacient.intervale_indisponibilitate[this.intermediar_index_interval_editat].detalii = this.intermediar_interval_edit_detalii
  }
  // console.log(" intervale la sfarsit ==>> ", this.pacient.intervale_indisponibilitate)

  this.flag_interval_in_editare = false
  this.flag_show_detalii_interval = false
  this.flag_adauga_alt_interval = true
  this.intermediar_index_interval_editat = -1
  this.intermediar_interval_edit_detalii = ''
  this.reInitIntervalStartEnd()
  if (this.flag_camp_detalii_activ){
    this.flag_camp_detalii_activ = !this.flag_camp_detalii_activ
  }
  this._snackBar.open("Intervalul a fost salvat cu succes", "Inchide")
}

setReprezentantLegalCalitatea(calitateid){
  this.pacient.reprezentant_legal_calitateid = calitateid
}
setPersoanaDeContactCalitatea(calitateid){
  this.pacient.persoana_contact_calitateid = calitateid
}
setPersoana_contact_id(checkedUserID: number)
{
  // this.pacient.persoana_contact_user_id = checkedUserID;
}

setReprezentant_Legal_id(checkedUserID:number)
{
  this.pacient.reprezentant_legal_user_id = checkedUserID;
}

setReprezentantiLegalList(){
  // this.lista.push(this.reprezentant_legal_nonuser_date_contact)
  // this.lista.push(this.reprezentant_legal_nonuser_date_personale)
  var reprezentant_legal = {
    datecontact : this.reprezentant_legal_nonuser_date_contact,
    datepersonale : this.reprezentant_legal_nonuser_date_personale,
    calitateid : this.pacient.reprezentant_legal_calitateid
  }
  var reprezentant_legaltwo = new ReprezentantLegalCreateTwo()
  reprezentant_legaltwo.date_personale = this.reprezentant_legal_nonuser_date_personale
  reprezentant_legaltwo.calitateid = this.pacient.reprezentant_legal_calitateid
  reprezentant_legaltwo.date_contact = this.reprezentant_legal_nonuser_date_contact
  this.pacienttwo.reprezentant_legal_nonusers.push(reprezentant_legaltwo)
  this.lista.push(reprezentant_legal)
  console.log("Pacient two cu reprezentant legal adaugat ==>> ", this.pacienttwo)
  this.dataSourcetwo  = new MatTableDataSource(this.lista);
  // console.log("reprezentant_legal_calitateid din set reprezentant legal list before delete ==>> ", this.pacient.reprezentant_legal_calitateid)
  delete this.pacient.reprezentant_legal_calitateid
  this.reprezentant_legal_nonuser_date_personale = new DatePersonaleCreate();
  this.reprezentant_legal_nonuser_date_contact = new DateContactCreate()
  this.reprezentant_legal_nonuser_date_contact.adresa = new AdresaCreate()
  // this.persoana_contact_nonuser_date_personale = new DatePersonaleCreate()
  // this.persoana_contact_nonuser_date_contact = new DateContactCreate()
  // this.persoana_contact_nonuser_date_contact.adresa = new AdresaCreate()
  this.addnewperson = 1
  // console.log("Lista din set reprezentant legal list ==>> ", this.lista)
  // console.log("reprezentant_legal_calitateid din set reprezentant legal list ==>> ", this.pacient.reprezentant_legal_calitateid)
}

setPersoanaDeContactList(){
  var persoana_contact = {
    datecontact : this.persoana_contact_nonuser_date_contact,
    datepersonale : this.persoana_contact_nonuser_date_personale,
    calitateid : this.pacient.persoana_contact_calitateid
  }
  this.listacontact.push(persoana_contact)
  this.dataSourcethree = new MatTableDataSource(this.listacontact);
  delete this.pacient.persoana_contact_calitateid
  this.persoana_contact_nonuser_date_personale = new DatePersonaleCreate();
  this.persoana_contact_nonuser_date_contact = new DateContactCreate()
  this.persoana_contact_nonuser_date_contact.adresa = new AdresaCreate()
  this.addnewcontactperson = 1

}

createAdresaSiDateDeContact(adresa){
  this.adresaService.addAdresaToServer(adresa)
  .subscribe((data)=>{
    if (data){
      console.log("data from add Adresa to server => ", data)
      this.date_contact.adresa = data
      this.createDateDeContact(this.date_contact)
    }
    else{
      console.log("No Data from add Adresa to server")
    }
  })
}

createDateDeContact(date_contact){
  let id = -1
  this.datedecontactService.addDateDeContactToServer(date_contact)
  .subscribe((data) => {
    if (data){
      console.log("data from add Date de Contact to server => ", data)
      this.pacient.datedecontactid = data.id
      this.createDatePersonale(this.date_personale)
    }
    else{
      console.log("No Data from add Date De Contact to Server")
    }
  })
}

anuleazaAddIntervaleIndisponibilitate(){
  this.flag_show_detalii_interval = false
  if(this.flag_interval_in_editare){
    this.flag_interval_in_editare = false
    this.flag_salveaza_interval_editat = false
    this.flag_adauga_alt_interval = true
  }
  else{
    if(this.pacient.intervale_indisponibilitate.length>0){
      this.flag_adauga_alt_interval = true
      this.flag_salveaza_interval_nou = false
    }
    else{
      if(this.pacient.intervale_indisponibilitate.length == 0 ){
        this.flag_adauga_primul_interval = true
        this.flag_salveaza_interval_nou = false
      }
    }
  }
  if(this.pacient.intervale_indisponibilitate.length == 0){

  }
}

getStatusIndependenta(){
  if(this.pacient.independent){
    return true
  }
  return false
}
getStatusCI(){
  if(this.pacient.carte_identitate_check){
    return true
  }
  return false
}
getStatusOrarIndisponibilitate(){
  if(this.pacient.orar_de_somn){
    return true
  }
  return false
}
changedCIReprezentantLegal(){
  console.log ("carte_identitate_check === >>> === !!! -- ",this.flag_reprezentant_legal_ci)
}
getStatusCIReprezentantLegal(){
  if(this.flag_reprezentant_legal_ci){
    return true
  }
  return false
}
getStatusCIPersoanaContact(){
  if(this.flag_persoana_contact_ci){
    return true
  }
  return false
}
changedCIPersoanaContact(){
  console.log ("flag_persoana_contact_ci === >>> === !!! -- ",this.flag_persoana_contact_ci)
}
changed(){
  console.log ("carte_identitate_check === >>> === !!! -- ",this.pacient.carte_identitate_check)
}


changed_dependenta(){
  console.log ("independent === >>> === !!! -- ",this.pacient.independent)
}
changed_OrarIndisponibilitate(){

  if (this.pacient.orar_de_somn){
    console.log (" orar_de_somn === >>> === !!! -- ",this.pacient.orar_de_somn)
    if(this.pacient.intervale_indisponibilitate.length == 0){
      this.flag_adauga_primul_interval = true
      this.flag_show_detalii_interval = true
    }
    else{
      if (this.pacient.intervale_indisponibilitate.length > 0){
        this.flag_adauga_primul_interval = false
      }
    }
  }
  else{
    if (this.flag_adauga_primul_interval){
      this.flag_adauga_primul_interval = false
    }
    if (this.flag_adauga_alt_interval){
      this.flag_adauga_alt_interval = false
    }
    if (this.flag_salveaza_interval_nou){
      this.flag_salveaza_interval_nou = false
    }
    if (this.flag_show_detalii_interval){
      this.flag_show_detalii_interval = false
    }
    if (this.flag_interval_in_editare){
      this.flag_interval_in_editare = false
    }
    if (this.flag_sunt_orare_indisponibilitate){
      this.flag_sunt_orare_indisponibilitate = false
    }
    if(this.pacient.intervale_indisponibilitate.length > 0){
      this.pacient.intervale_indisponibilitate = []
    }

  }

}


createDatePersonale(date_personale){
  this.datepersonaleService.addDatePersonaleToServer(date_personale)
    .subscribe((data) => {
      if(data){
        console.log ("data from add Date Personale to server => ", data)
        this.pacient.datepersonaleid = data.id
        this.createPacient(this.pacient)
      }
      else{
        console.log ("No Data from add Date Personale to Server")
      }
    })
}
checkObjectHasAnyValueDefined(object_to_check){
  for (var i of Object.keys(object_to_check)){
    if (object_to_check[i] != undefined){
      // console.log("Obiectu are ceva defined")
      return true
    }
  }
  // console.log("Tot obiectu undefined")
  return false
}

checkAndSetDatePersonalePacientTwo(date_personale_to_check){
  if(this.checkObjectHasAnyValueDefined(date_personale_to_check)){
    console.log(" date personale to check DEFINED !! ==>> ", date_personale_to_check)
    for (var i of Object.keys(date_personale_to_check)){
      if ( i == "adresa" ){
        if (this.checkObjectHasAnyValueDefined(date_personale_to_check[i])){
          console.log("ADRESA date personale to check DEFINED !! ==>> ", date_personale_to_check[i])
          for ( var j of Object.keys(this.pacienttwo.date_contact[i])){
            if (date_personale_to_check[i][j] ! = undefined ){
              console.log( " i ==>> ", i, " || j ==>> ", j, " || date_personale_to_check[i][j] ==>> ", date_personale_to_check[i][j], " || DEFINED ")
            }
            else{
              console.log( " i ==>> ", i, " || j ==>> ", j, " || date_personale_to_check[i][j] ==>> ", date_personale_to_check[i][j], " || UNDEFINED ")
            }
          }
        }
        else {
          console.log("ADRESA date personale to check == !! >> UN DEFINED !! ==>> ", date_personale_to_check[i])
        }
      }
    }
  }
  else{
    console.log(" date personale to check undefined ==>> ", date_personale_to_check)
  }
}

setDatePersonalePacientTwo(){
  if (this.date_personale.nume != undefined){
    this.pacienttwo.date_personale.nume = this.date_personale.nume
  }
  if (this.date_personale.prenume != undefined){
    this.pacienttwo.date_personale.prenume = this.date_personale.prenume
  }
  if (this.date_personale.sex != undefined){
    this.pacienttwo.date_personale.sex = this.date_personale.sex
  }
  if (this.date_personale.data_nasterii != undefined){
    this.pacienttwo.date_personale.data_nasterii = this.date_personale.data_nasterii
  }
  if (this.date_personale.locul_nasterii != undefined){
    this.pacienttwo.date_personale.locul_nasterii = this.date_personale.locul_nasterii
  }
  if (this.date_personale.cnp != undefined){
    this.pacienttwo.date_personale.cnp = this.date_personale.cnp
  }
  if (this.pacient.carte_identitate_check){
    if (this.date_personale.serie_carte_identitate != undefined){
      this.pacienttwo.date_personale.serie_carte_identitate = this.date_personale.serie_carte_identitate
    }
    if (this.date_personale.numar_carte_identitate != undefined){
      this.pacienttwo.date_personale.numar_carte_identitate = this.date_personale.numar_carte_identitate
    }
  }
}
setAdresaPacientTwo(){
    for (var i of Object.keys(this.pacienttwo.date_contact.adresa)){
      // console.log(" Doar I --- --- ---- ==>  ", i)
      // console.log(" this.date_contact[i] de key ==>  ", this.date_contact[i])
      // console.log(" keys this.date_contact[i] de key ==>  ", Object.keys(this.date_contact[i]))
      // console.log(" keys this.pacient.date_contact.adresa[i] de key ==>  ", Object.keys(this.pacient.date_contact.adresa[i]))
      // console.log ("Lungime === === keys this.date_contact[i] !! == !! == !! ---- => ", Object.keys(this.date_contact[i]).length)
      // console.log ("Array.isArray( === === keys this.date_contact[i] !! == !! == !! ---- => ", Array.isArray(this.date_contact[i]))
      if ( this.pacient.date_contact.adresa[i] != undefined){
        this.pacienttwo.date_contact.adresa[i] = this.pacient.date_contact.adresa[i]
        // console.log(" this.pacient.date_contact.adresa[i] DEFINED de i ==>  ", this.pacient.date_contact.adresa[i])
      }
      else{
        console.log(" this.pacient.date_contact.adresa[i] !!!!!  UNDEFINED !!!!! de i ==>  ", this.pacient.date_contact.adresa[i])
      }
  }
}
setDateContactPacientTwo(){
  if (this.flag_telefon_email_saved){
    if( this.date_contact.email != undefined){
      this.pacienttwo.date_contact.email = this.date_contact.email
    }
    if (this.date_contact.telefon != undefined){
      this.pacienttwo.date_contact.telefon = this.date_contact.telefon
    }
  }
  if (this.flag_adresa_beneficiar_set && this.checkObjectHasAnyValueDefined(this.pacient.date_contact.adresa)){
    this.setAdresaPacientTwo()
  }
  else{
    console.log(" flag adresa beneficiar false sau adresa n-are elemente")
  }
}

salveazaPacientParalel(pacientdetails){
  // this.pacienttwo.date_contact = this.date_contact
  // this.pacienttwo.date_personale = this.date_personale

  // console.log ("Lungime === === keys of pacient two -> date contact !! == !! == !! => ", Object.keys(this.pacienttwo.date_contact).length)
  // console.log ("keys of pacient two -> date contact !! == !! == !! => ", Object.keys(this.pacienttwo.date_contact))

  // console.log ("flag_adresa_beneficiar_set ", this.flag_adresa_beneficiar_set)
  // console.log ("checkObjectHasAnyValueDefined de adresa ", this.checkObjectHasAnyValueDefined(this.adresa))

  // this.checkAndSetDatePersonalePacientTwo(this.date_contact);
  this.setDatePersonalePacientTwo()
  console.log (" PACIENT TWO DUPA SET DATE personale == ",this.pacienttwo )
  this.setDateContactPacientTwo()
  console.log (" PACIENT TWO DUPA SET DATE Contact == ",this.pacienttwo )
  if (this.pacient.greutate != undefined){
    this.pacienttwo.greutate = this.pacient.greutate
  }
  if (this.pacient.inaltime != undefined){
    this.pacienttwo.inaltime = this.pacient.inaltime
  }
  this.pacienttwo.independent = this.pacient.independent
  this.pacienttwo.orar_de_somn = this.pacient.orar_de_somn
  if ( this.pacient.orar_de_somn ){
    if (this.pacient.intervale_indisponibilitate.length > 0){
      this.pacienttwo.intervale_indisponibilitate = this.pacient.intervale_indisponibilitate
      console.log(" intervale indisponibilitate pacient two ==>> ", this.pacienttwo.intervale_indisponibilitate)
    }
    else{
      console.log( " ERROR !! == >>  interval indisponibilitate TRUE dar LUNGIMEA listei PACIENT.INTERVALE_INDISPONIBILITATE este == 0")
    }
  }
  else{
    console.log (" interval indisponibilitate false ")
  }
  this.pacienttwo.reprezentant_legal_persoana_de_contact = this.pacient.reprezentant_legal_persoana_de_contact
  console.log (" lista contact in pacient two paralel ", this.listacontact)

  if(!this.pacienttwo.reprezentant_legal_persoana_de_contact && this.listacontact.length > 0){
    for (var z = 0; z < this.listacontact.length; z++ ){
      this.pacienttwo.persoana_contact_nonusers.push(this.listacontact[z])
    }
  }
  // console.log("Pacient details din create Pacient ==>> ", pacientdetails)

}
createPacient(pacientdetails){
  this.salveazaPacientParalel(pacientdetails)
  pacientdetails.reprezentant_legal_nonusers = this.lista
  pacientdetails.persoana_contact_nonusers = this.listacontact

  // this.pacientiService.addPacientToServer(pacientdetails)
  // .subscribe((data)=>{
  //   if (data){
  //     console.log("data from add pacient to server => ", data)
  //     }
  //     else{
  //       console.log("no data from add pacient to server")
  //     }
  //   })
}
sendvalues(){


  // this.createAdresaSiDateDeContact(this.adresa)
  // this.createDatePersonale(this.date_personale)
  this.pacient.date_personale = this.date_personale
  this.date_contact.adresa = this.adresa
  this.pacient.date_contact = this.date_contact



  // console.log("type of Hours from time picker: => ",typeof this.program_de_somn.start);    // !!==>  de bagat in for

  if(this.pacient.reprezentant_legal_user){

    this.pacient.reprezentant_legal_nonuser = false

  }
  else{

    this.pacient.reprezentant_legal_nonuser = true
    this.pacient.reprezentant_legal_nonuser_date_contact = this.reprezentant_legal_nonuser_date_contact
    this.pacient.reprezentant_legal_nonuser_date_personale = this.reprezentant_legal_nonuser_date_personale
  }

  if( !this.pacient.reprezentant_legal_persoana_de_contact ){
    if(!this.pacient.persoana_contact_user)
    {
      this.pacient.persoana_contact_nonuser = true
      this.pacient.persoana_contact_nonuser_date_contact = this.persoana_contact_nonuser_date_contact
      this.pacient.persoana_contact_nonuser_date_personale = this.persoana_contact_nonuser_date_personale
    }
  }

  // if(this.pacient.reprezentant_legal_persoana_de_contact)
  // {
    //   if(this.pacient.persoana_contact_user){
      //     // this.pacient.reprezentant_legal_user_id = this.pacient.persoana_contact_user_id;
      //     this.pacient.reprezentant_legal_user = true;
      //     this.pacient.reprezentant_legal_nonuser = this.pacient.persoana_contact_nonuser = false;
      //   }
      //   else {
        //     this.pacient.reprezentant_legal_nonuser = this.pacient.persoana_contact_nonuser = true;
        //     this.pacient.reprezentant_legal_nonuser_date_contact = this.pacient.persoana_contact_nonuser_date_contact;
        //     this.pacient.reprezentant_legal_nonuser_date_personale = this.pacient.persoana_contact_nonuser_date_personale;
        //   }
        // }

        console.log("from send values Pacient => ",this.pacient);
        this.createPacient(this.pacient)
        // this.pacientiService.addPacientToServer(this.pacient)
        // this.dialogRef.close()
        console.log(" pacienttwo adaugat ==>> ", this.pacienttwo)
        let body = JSON.stringify(this.pacienttwo)
        this.pacientiService.addPacientToServerTwo(body)
        .subscribe( (data) =>{
          if (data){
            console.log("Pacient two add to server ==>> ", data)
          }
          else{
            console.log("No date from add pacient two server !!")
          }
        })
        this._snackBar.open("Beneficiar adaugat cu succes","Inchide")
      }

}
