import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { AddconsultComponent } from '../addconsult/addconsult.component';
import { ChestionarDeAlergiiComponent } from '../templatedocumente/componente-chestionare/chestionar-de-alergii/chestionar-de-alergii.component';
import { ContractFurnizorServiciiSocialeComponent } from '../templatedocumente/componente-contracte/contract-furnizor-servicii-sociale/contract-furnizor-servicii-sociale.component';
import { ContractInchiriereComponent } from '../templatedocumente/componente-contracte/contract-inchiriere/contract-inchiriere.component';
import { ContractMasinaComponent } from '../templatedocumente/componente-contracte/contract-masina/contract-masina.component';
import { EvaluareIndependentaMnfComponent } from '../templatedocumente/componente-evaluari/evaluare-independenta-mnf/evaluare-independenta-mnf.component';
import { EvaluareMariComponent } from '../templatedocumente/componente-evaluari/evaluare-mari/evaluare-mari.component';
import { EvaluareMersComponent } from '../templatedocumente/componente-evaluari/evaluare-mers/evaluare-mers.component';
import { EvaluareaFunctieiMotoriiGrosiereComponent } from '../templatedocumente/componente-evaluari/evaluarea-functiei-motorii-grosiere/evaluarea-functiei-motorii-grosiere.component';
import { EvaluareaReflexelorPrimitiveComponent } from '../templatedocumente/componente-evaluari/evaluarea-reflexelor-primitive/evaluarea-reflexelor-primitive.component';
import { FisaEvaluareInfsParintiComponent } from '../templatedocumente/componente-evaluari/fisa-evaluare-infs-parinti/fisa-evaluare-infs-parinti.component';
import { FisaExaminareLogopedicaComponent } from '../templatedocumente/componente-evaluari/fisa-examinare-logopedica/fisa-examinare-logopedica.component';

@Component({
  selector: 'app-editeazaconsultbutton',
  templateUrl: './editeazaconsultbutton.component.html',
  styleUrls: ['./editeazaconsultbutton.component.css']
})
export class EditeazaconsultbuttonComponent implements OnInit {
  @Input() consultData: any;
  @Input() pacientData: any;
  @Output() edited = new EventEmitter<boolean>();

  pacient: any
  FisiereConsult = {
    nume: "" ,
    idfisier: ""
  }
  fisiere = []
  dataSource: any

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddconsultComponent>,

  ) { }

  ngOnInit(): void {
  }
  editBtnClick()
  {
    console.log("Edit BUTTON CLICKED ==>> ", this.consultData)
    switch (this.consultData.idEvaluare){
      case 19:
        // EVALUAREA FUNCTIEI MOTORII GROSIERE
        this.editeaza_Evaluare_Functie_Motorie_Grosiera(this.consultData.idEvaluare)
        break;
      case 21:
        // EVALUAREA REFLEXELOR PRIMITIVE
        this.editeaza_Evaluare_Reflexele_Primitive(this.consultData.idEvaluare)
        break;
      case 22:
        // EVALUARRE INFS PARINTI
        this.editeaza_Evaluare_INFS_Parinti(this.consultData.idEvaluare)
        break;
      case 23:
        // EVALUARE MERS
        this.editeaza_Evaluare_MERS(this.consultData.idEvaluare)
        break;
      case 25:
        // DE VERIFICAT
        // this.editeaza_Evaluare_Reflexele_Primitive(this.consultData.idEvaluare)
        console.log("ID EVALUARE 25 ==>> DE VERIFICAT -- TEORETIC INFS PARINTI")
        break;
      case 26:
        // EVALUARE INDEPENDENTA MNF
        this.editeaza_Evaluare_Independenta(this.consultData.idEvaluare)
        break;
      case 27:
        // DE VERIFICAT
        // this.editeaza_Evaluare_Reflexele_Primitive(this.consultData.idEvaluare)
        console.log("ID EVALUARE 27 ==>> DE VERIFICAT -- TEORETIC INFS PARINTI")

        break;
      case 28:
        // EVALUARE MARI
        this.editeaza_Evaluare_Mari(this.consultData.idEvaluare)
        break;
      case 33:
        // CHESTIONAR ALERGII
        this.editeaza_Chestionar_Alergii(this.consultData.idEvaluare)
        break;
      case 30:
        // PLAN PERSONALIZAT DE INTERVENTII
        this.editeaza_PPI(this.consultData.idEvaluare)
        break;
    }
  }

  editeaza_Evaluare_Functie_Motorie_Grosiera(idFisier){
    console.log (" EDITEAZA EVALUARE FUNCTIE MOTORIE GROSIERA ==>> ID FISIER ==>> ", idFisier)
    this.openEvaluareaFunctieiMotoriiGrosiereComponent()
  }

  editeaza_Evaluare_Reflexele_Primitive(idFisier){
    console.log (" EDITEAZA EVALUARE REFLEXELOR PRIMITIVE ==>> ID FISIER ==>> ", idFisier)
    this.openEvaluareaReflexelorPrimitiveComponent()
  }

  editeaza_Evaluare_INFS_Parinti(idFisier){
    console.log (" EDITEAZA EVALUARE INFS PARINTI ==>> ID FISIER ==>> ", idFisier)
    this.openFisaEvaluareInfsParintiComponent()
  }

  editeaza_Evaluare_MERS(idFisier){
    console.log (" EDITEAZA EVALUARE MERS ==>> ID FISIER ==>> ", idFisier)
    this.openEvaluareMersComponent();
  }

  editeaza_Evaluare_Independenta(idFisier){
    console.log (" EDITEAZA EVALUARE INDEPENDENTA MNF ==>> ID FISIER ==>> ", idFisier)
    this.openEvaluareIndependentaMnfComponent()
  }

  editeaza_Evaluare_Mari(idFisier){
    console.log (" EDITEAZA EVALUARE MARI ==>> ID FISIER ==>> ", idFisier)
    this.openEvaluareMariComponent()
  }

  editeaza_Chestionar_Alergii(idFisier){
    console.log (" EDITEAZA Chestionar Alergii ==>> ID FISIER ==>> ", idFisier)
    this.openChestionarDeAlergiiComponent()
  }

  editeaza_PPI(idFisier){
    console.log (" EDITEAZA PPI ==>> ID FISIER ==>> ", idFisier)
  }

  openEvaluareaFunctieiMotoriiGrosiereComponent() {
    const dialogRef = this.dialog.open(EvaluareaFunctieiMotoriiGrosiereComponent, {
      width: '80%',
      height: '100%',
      data: {
        "caz" : 2,
        'beneficiar' : this.pacientData,
        'fieldsData' : this.consultData
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      this.edited.emit(true)
    })
  }
  openEvaluareaReflexelorPrimitiveComponent() {
    const dialogRef = this.dialog.open(EvaluareaReflexelorPrimitiveComponent, {
      width: '80%',
      height: '100%',
      data: {
        "caz" : 2,
        'beneficiar' : this.pacientData,
        'fieldsData' : this.consultData
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      this.edited.emit(true)
    })
  }

  openFisaEvaluareInfsParintiComponent() {

    const dialogRef = this.dialog.open(FisaEvaluareInfsParintiComponent, {
      width: '80%',
      height: '100%',
      data: {
        "caz" : 2,
        'beneficiar' : this.pacientData,
        'fieldsData' : this.consultData
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      this.edited.emit(true)
    })
  }

  openEvaluareMersComponent() {
    const dialogRef = this.dialog.open(EvaluareMersComponent, {
      width: '80%',
      height: '100%',
      data: {
        "caz" : 2,
        'beneficiar' : this.pacientData,
        'fieldsData' : this.consultData
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      this.edited.emit(true)
    })
  }

  openFisaExaminareLogopedicaComponent() {
    const dialogRef = this.dialog.open(FisaExaminareLogopedicaComponent, {
      width: '80%',
      height: '100%',
      data: this.pacientData
    });
    // this.openSnackBar()
  }


  openEvaluareIndependentaMnfComponent() {
    const dialogRef = this.dialog.open(EvaluareIndependentaMnfComponent, {
      width: '80%',
      height: '100%',
      data: {
        "caz" : 2,
        'beneficiar' : this.pacientData,
        'fieldsData' : this.consultData
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      this.edited.emit(true)
    })
  }
  openEvaluareMariComponent() {
    const dialogRef = this.dialog.open(EvaluareMariComponent, {
      width: '80%',
      height: '100%',
      data: {
        "caz" : 2,
        'beneficiar' : this.pacientData,
        'fieldsData' : this.consultData
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      this.edited.emit(true)
    })
  }
  openChestionarDeAlergiiComponent() {
    const dialogRef = this.dialog.open(ChestionarDeAlergiiComponent, {
      width: '80%',
      height: '100%',
      data: {
        "caz" : 2,
        'beneficiar' : this.pacientData,
        'fieldsData' : this.consultData
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      this.edited.emit(true)
    })
    // this.openSnackBar()
  }
  openContractFurnizorServiciiSocialeComponent() {
    const dialogRef = this.dialog.open(ContractFurnizorServiciiSocialeComponent, {
      width: '80%',
      height: '100%',
      data: {
        "caz" : 2,
        'beneficiar' : this.pacientData,
        'fieldsData' : this.consultData
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      this.edited.emit(true)
    })
    // this.openSnackBar()
  }
  openContractMasinaComponent() {
    const dialogRef = this.dialog.open(ContractMasinaComponent, {
      width: '80%',
      height: '100%',
      data: this.pacient
    });
    // this.openSnackBar()
  }
  openContractInchiriereComponent() {
    const dialogRef = this.dialog.open(ContractInchiriereComponent, {
      width: '80%',
      height: '100%',
      data: this.pacient
    });
    // this.openSnackBar()
  }

}
