import { Component,Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { Pacient, DateContact, DatePersonale, Adresa, PacientTwoServer } from '../../../models/pacientdetails.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { PPIInterface, structuraPPIInterface } from 'src/app/interfaces/documente.interface';
import { EvalserviceService } from 'src/app/services/evalservice.service';
import { FileService } from 'src/app/services/file.service';
import { AdresaInterface } from 'src/app/interfaces/pacient.interface';
import { PPIClass, structuraPPI } from 'src/app/models/documente.models';
import { PinentryComponent } from 'src/app/pinentry/pinentry.component';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { PdfpreviewerComponent } from 'src/app/pdfpreviewer/pdfpreviewer.component';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { base_img_64 } from 'src/app/models/base-img64.model';
import { logo_url_base64 } from 'src/app/models/logo-base64.model';

@Component({
  selector: 'app-plan-de-interventie-personalizat',
  templateUrl: './plan-de-interventie-personalizat.component.html',
  styleUrls: ['./plan-de-interventie-personalizat.component.css']
})
export class PlanDeInterventiePersonalizatComponent implements OnInit {
  idFisier : any = null
  caz : number = 1
  semnatura_RL : any = null
  semnatura_Terapeut : any = null
  pacient = new PacientTwoServer()
  plan_de_interventie_personalizat: PPIInterface = new PPIClass()

  structura: structuraPPIInterface = new structuraPPI()

  structuri = []
  flag_adresa : boolean = false
  flag_date_mama : boolean = false
  flag_date_tata  : boolean = false
  displayedColumns = ['Sesiune','Evaluare', 'Obiective','Metode si mijloace de realizare','Recomandari']
  dataSource = new MatTableDataSource(this.structuri)

  constructor(
    public dialog: MatDialog,
    public evaluarefieldsService: EvalserviceService ,
    public fileService: FileService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) private data:any
    ) {
      if(data){
        console.log("Data from component ==>> ",data);
        this.pacient = data
      }
    }

  ngOnInit(): void {

  // FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI
  //   let intermediar : structuraPPIInterface = new structuraPPI()
  // let testone = {
  //   "denumire" : "Plan Personalizat de Interventie",
  //   "props" : intermediar
  // }

  // this.evaluarefieldsService.addToServer(testone )
  // .subscribe( (data) =>{
  //   if (data){
  //     console.log("Data from add sectiunea A to server ==>> ", data)
  //   }
  //   else{
  //     console.log("NO !!! Data from add sectiunea A to server ==>> ")
  //   }
  // })

  // END FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI
  this.plan_de_interventie_personalizat.data_elaborarii_planului = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')

  if (this.pacient.id){
    // console.log("Date Pacient plan_de_interventie_personalizat ==>> ", this.plan_de_interventie_personalizat)
    console.log("Date Pacient Primite ==>> ", this.pacient)
    console.log("Date Pacient structura ==>> ", this.structura)
    this.checkDateMama()
    this.checkDateTata()
    this.plan_de_interventie_personalizat.nume_pacient = this.pacient.date_personale.nume
    this.plan_de_interventie_personalizat.prenume_pacient = this.pacient.date_personale.prenume
    this.plan_de_interventie_personalizat.data_nastere_pacient = this.datePipe.transform( this.pacient.date_personale.data_nasterii, 'dd.MM.yyyy');
  }
  if (this.pacient.date_contact.adresa){
    this.plan_de_interventie_personalizat.domiciliu_pacient = this.returnDomiciliul(this.pacient.date_contact.adresa)
    this.flag_adresa = true
  }

  }

  parcurgeRLCautaCalitateID(calitateID){
    for (let i of this.pacient.reprezentanti_legali){
      if (i.calitateid == calitateID){
        if(calitateID == 1){
          this.plan_de_interventie_personalizat.mama_pacient.nume_mama_pacient = i.datepersonale.nume
          this.plan_de_interventie_personalizat.mama_pacient.prenume_mama_pacient = i.datepersonale.prenume
          return true
        }
        if(calitateID == 2){
          this.plan_de_interventie_personalizat.tata_pacient.nume_tata_pacient = i.datepersonale.nume
          this.plan_de_interventie_personalizat.tata_pacient.prenume_tata_pacient = i.datepersonale.prenume
          return true
        }
      }
    }
    return false
  }
  checkDateMama(){
    if (this.pacient.reprezentanti_legali.length > 0){
     if (this.parcurgeRLCautaCalitateID(1)) {
       this.flag_date_mama = true
     }
    }
    else{
      console.log(" NU !!! Are Reprezentanti Legali ==>> ",this.pacient )
    }
  }
  checkDateTata(){
    if (this.pacient.reprezentanti_legali.length > 0){
     if (this.parcurgeRLCautaCalitateID(2)) {
       this.flag_date_tata = true
     }
    }
    else{
      console.log(" NU !!! Are Reprezentanti Legali ==>> ",this.pacient )
    }
  }
  returnDomiciliul (adresa : AdresaInterface) : string{
    let intermediar = ""

    if (adresa.judet){
      intermediar = intermediar + " Judet: "+adresa.judet
    }
    if (adresa.localitate){
      intermediar = intermediar + " Localitatea: "+adresa.localitate
    }
    if (adresa.strada){
      intermediar = intermediar + " Strada: "+adresa.strada
    }
    if (adresa.numar){
      intermediar = intermediar + " Nr: "+adresa.numar
    }
    if (adresa.bloc){
      intermediar = intermediar + " Bloc: "+adresa.bloc
    }
    if (adresa.etaj){
      intermediar = intermediar + " Etaj: "+adresa.etaj
    }
    if (adresa.ap){
      intermediar = intermediar + " Ap: "+adresa.ap
    }
    return intermediar
  }
  add(){
    this.structuri.push(this.structura)
    this.dataSource.data = this.dataSource.data
    this.structura = new structuraPPI()
  }
  semneazaRL(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_RL = res
        console.log("Semnatura RL IMAGINE ESTE ==>> ",this.semnatura_RL)
      }
    })
  }
  semneazaTerapeut(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_Terapeut = res
        console.log("Semnatura Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
      }
    })
  }
  openPDFPreviewComponent(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      // console.log("data din pdfgenerator base 64 ==>> ",data);
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
    })
  }


  prepare_structura_programului(){
    var x = [ ['Nr.crt', 'Sesiunea', 'Evaluare','Obiective','Metode si mijloace de realizare','Recomandari']]
    let index = 1
    let q = []
    console.log("STRUCTURI ==>> ", this.structuri)
    for ( let y of this.structuri){
      q = [index+'.', this.datePipe.transform(y.sesiune.start, 'dd.MM.YYYY')  +" - "+ this.datePipe.transform(y.sesiune.end, 'dd.MM.YYYY'), y.obiective, y.evaluare, y.metode_si_mijloace, y.recomandari ]
      x.push(q)
      index = index + 1
    }
    return x
  }

  generatePDF(action = 'open') {
    let tabel_structura_programului = this.prepare_structura_programului()
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
        height: 60,
        columns:
          [
            // {
            //   image: logo_url_base64,
            //   width: 50,
            //   alignment: 'center',
            // },
            {
              stack: [
                "Fundația Mihai Neșu Foundation",
                "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
              ],
              margin: 10,
              alignament: 'center',
              style: 'textheader'
            }
          ],
        margin: [0, 0, 0, 90]
      },
      content: [
        {
          canvas: [
            {
              type: 'line',
              x1: -100, y1: 0,
              x2: 600, y2: 0,
              lineWidth: 1,
              lineCap: 'square'
            },
            {
              type: 'line',
              x1: -100, y1: 4,
              x2: 600, y2: 4,
              lineWidth: 1,
              lineCap: 'square'
            },
          ]
        },
        {
          image: logo_url_base64,
          width: 200,
          alignment: 'center',
        },
        {
          text: "Plan de intervenție personalizat ",
          bold: true,
          alignment: 'center',
          fontSize: 16,
          margin: [0, 50, 0, 80]
        },
        {
          text: "1.	Informații de bază",
          alignment: 'left',
          fontSize: 16,
          bold: true,
          margin: [20, 10, 0, 20]
        },
        {
          text: "Numele si prenumele copilului: " + this.plan_de_interventie_personalizat.nume_pacient + " " + this.plan_de_interventie_personalizat.prenume_pacient,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Data nasterii: " + this.plan_de_interventie_personalizat.data_nastere_pacient,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Domiciliul: " + this.plan_de_interventie_personalizat.domiciliu_pacient,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Diagnosticul copilului: " + this.plan_de_interventie_personalizat.diagnostic_pacient,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Starea medicală:  ",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: " -    Convulsii:  " + this.plan_de_interventie_personalizat.stare_medicala.convulsii.continut,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [30, 0, 0, 20]
        },
        {
          text: " -	Deficiențe fizice :" + this.plan_de_interventie_personalizat.stare_medicala.deficiente_fizice.continut,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [30, 0, 0, 20]
        },
        {
          text: " - 	Probleme pulmonare:" + this.plan_de_interventie_personalizat.stare_medicala.probleme_pulmonare.continut,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [30, 0, 0, 20]
        },
        {
          text: "-	Probleme cardiace:  " + this.plan_de_interventie_personalizat.stare_medicala.probleme_cardiace.continut,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [30, 0, 0, 20]
        },
        {
          text: "Tata: " + this.plan_de_interventie_personalizat.tata_pacient.nume_tata_pacient+" "+this.plan_de_interventie_personalizat.tata_pacient.prenume_tata_pacient,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Mama: " + this.plan_de_interventie_personalizat.mama_pacient.nume_mama_pacient+" "+this.plan_de_interventie_personalizat.mama_pacient.prenume_mama_pacient,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Instituția: Fundația Mihai Neșu",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Echipa de intervenție: "+this.plan_de_interventie_personalizat.echipa_de_interventie,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Data elaborării planului:  "+this.plan_de_interventie_personalizat.data_elaborarii_planului,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Perioada de intervenție:  "+this.plan_de_interventie_personalizat.perioada_de_interventie.data_start+" - "+this.plan_de_interventie_personalizat.perioada_de_interventie.data_end,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Probleme cu care se confruntă copilul ",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Evaluarea inițială : "+ this.plan_de_interventie_personalizat.evaluare_initiala,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "2. Structura programului de intervenție personalizat",
          alignment: 'left',
          fontSize: 16,
          bold: true,
          margin: [20, 10, 0, 20]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            width:'auto',
            body: tabel_structura_programului
          }
        },
        {
          columns:[
            {
              stack: [
                {
                  text: "Semnatura terapeut, ",
                  bold: true,
                  alignment: 'left',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_Terapeut ? this.semnatura_Terapeut : base_img_64,
                  width: 140,
                  height: 40,
                  margin: [0, 0, 0, 20]
                }
              ]

            },
            {
              stack: [
                {
                  text: "Luat la cunoștință de părinte, ",
                  bold: true,
                  alignment: 'right',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_RL ? this.semnatura_RL : base_img_64,
                  alignment: 'right',
                  width: 140,
                  height: 40,
                  margin: [0, 0, 0, 20]
                }
              ]
            }
          ]
        }
      ]
    }
    return docDefinition
  }

}
