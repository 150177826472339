import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NompropsService {

  fieldlist:any;
  testval2:any;
  field_details:any;
  fieldscomplete:any = [];

  constructor() { }
}
