import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contulmeu',
  templateUrl: './contulmeu.component.html',
  styleUrls: ['./contulmeu.component.css']
})
export class ContulmeuComponent implements OnInit {

  nume : string = "Admin"
  prenume : string = "Admin"
  email : string = "contact@optima.ztools.ro"
  telefon : string = "0722334455"
  parola : string
  parolanoua : string
  parolanouarepeat : string
  constructor() { }

  ngOnInit(): void {
  }

}
