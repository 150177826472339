import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError} from 'rxjs/operators/catchError';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ValabilitateInterface,  TipDiagnosticInterface, DiagnosticInterface, DiagnosticCreateInterface, DiagnosticCreateFaraValabilitateInterface, DiagnosticCreateFaraTipDiagnosticInterface } from '../interfaces/nomenclatoare.interface';
import { serverurl } from './server.service';
import { MatDialog } from '@angular/material/dialog';
import { PinentryComponent } from '../pinentry/pinentry.component';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

export interface Consultatie{
  dataconsult: Date,
  investigatii: string,
  recomandari: string,
  diagnostic: string,
  tratament: string,
  fisiere: any[]
}


@Injectable({
  providedIn: 'root'
})

export class ConsultatiiService {
  public consultatiilist: Consultatie [] = []
  public localstorageflag = 0;
  private server_url=serverurl+"/api/v1/consultatii/";


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService
    ) {
      // let data = this.localStorageService.get("Consultatii");
      // if (data)
      // {
      //   this.localstorageflag = 1;
      //   this.consultatiilist = data;
      //   this.consultatiilist.forEach(element=> {
      //     this.http.post<Consultatie>(this.server_url, element, cudOptions).pipe(
      //       catchError(this.handleError)
      //         )
      //       });
      //   }
      // else {
      //   // console.log("PacientList nu este Local")
      // }
     }
     private handleError(error: Response){
      console.log("Error from Pacient Service: => " + error)
      return Observable.throw("500 internal server error" )
    }
  getConsult(){
    return this.consultatiilist;
  }

  addConsultToServer(consultatie){
    return (this.http.post<any>(this.server_url, consultatie, this.httpOptions))
  }

  getConsultFromServerByID(idConsult){
    return (this.http.get<any>(this.server_url+idConsult))
  }
  getConsultFromServerByPacientID(idBeneficiar){
    return (this.http.get<any>(this.server_url+"beneficiar/"+idBeneficiar))
  }
  addConsult(consultatie){
    this.consultatiilist.push(consultatie);
    if (this.localstorageflag){
      this.localStorageService.remove("Consultatii");
      this.addtolocalStorage(this.consultatiilist);
    }
    else{
      this.addtolocalStorage(this.consultatiilist);
    }
  }
  addtolocalStorage(ConsultatiiList){
    console.log("addtolocalstorage CategorieServicii: =>", ConsultatiiList);
    console.log("Addtolocalstorage",this.localStorageService.set("Consultatii", ConsultatiiList));
  }

  getConsultDataFromServerByPFisierID(idFisier){
    return (this.http.get<any>(this.server_url+"savedData/"+idFisier))
  }

  updateConsultToServerByFisierID(idFisier, data_in){
    console.log("UPDATE CONSULT TO SERVER BY FISIER ID -->> DATA IN ==>> ", data_in)
    return (this.http.put<any>(this.server_url+"fileid/"+idFisier,data_in))
  }

  stergeConsultFromServerByFisierID(idFisier){
    return (this.http.delete<any>(this.server_url+"fileid/"+idFisier))
  }

  semneaza(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        return (res)
        // console.log("Semnaturas Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
      }
    })
  }

}
