<div class="fixActionRow">
<h1 mat-dialog-title >Fisiere</h1>

<div mat-dialog-content>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width:100%;height:100%">

        <ng-container matColumnDef="nume">
            <th mat-header-cell *matHeaderCellDef> nume </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="Folder">
            <th mat-header-cell *matHeaderCellDef> Folder </th>
            <td mat-cell *matCellDef="let element"> {{element.parent}} </td>
        </ng-container>

        <ng-container matColumnDef="Data Adaugarii">
            <th mat-header-cell *matHeaderCellDef> Data Adaugarii </th>
            <td mat-cell *matCellDef="let element"> {{element.dateadden}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
</div>

<div class="spacer"></div>

<div mat-dialog-actions style="display:flex; align-self: stretch;">
    <button mat-raised-button color="warn" mat-dialog-close >Cancel</button>
    <button mat-raised-button color="primary" >Ok</button>
</div>

</div>