<!-- <ngx-extended-pdf-viewer
[base64Src]="base64"
backgroundColor="#ffffff"
[height]="'90vh'"
[useBrowserLocale]="true"
[handTool]="false"
[showHandToolButton]="true"
>
</ngx-extended-pdf-viewer> -->
<div style="height: 90%;" class="pdfviewercontainer">
  <ngx-extended-pdf-viewer
  [base64Src]="base64"
  backgroundColor="#ffffff"
  [height]="'100%'"
  [useBrowserLocale]="true"
  [handTool]="true"
  [showHandToolButton]="true"
  [theme]="'dark'"
  >
</ngx-extended-pdf-viewer>
</div>
<button mat-raised-button (click)="save()" class = "mid-button-pdfviewer" *ngIf="caz == 1"> Salveaza si descarca fisierul</button>
<button mat-raised-button mat-dialog-close class = "mid-button-pdfviewer-close" color = "warn" *ngIf="caz == 2">Inchide</button>

