<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> Evaluarea reflexelor primitive</h2></span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> 1.	Reflexul MORO: </span>

<mat-form-field style="width: 100%;" >
    <mat-label>Mers cu MI în Rotație ext/ int.</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.mers_cu_mi_in_rotatie" placeholder="Mers cu MI în Rotație ext/ int." ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>DD - auditor</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.dd_auditor" placeholder="DD - auditor" ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>DD - kinestezic (mișcările corpului)</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.dd_kinestezic" placeholder="DD - kinestezic (mișcările corpului)" ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Așezat/ Stând- vestibular</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.asezat_stand_vestibular" placeholder="Așezat/ Stând- vestibular" ></textarea>
</mat-form-field>



<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> &nbsp;&nbsp;&nbsp;&nbsp; Auditor
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.sistem_senzorial.auditor.integrat" (change)="changestate(evaluare_reflexe_primitive.sistem_senzorial.auditor, 'integrat')" > Integrat  </mat-checkbox>
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.sistem_senzorial.auditor.partial_retinut" (change)="changestate(evaluare_reflexe_primitive.sistem_senzorial.auditor, 'partial_retinut')" > Parțial reținut  </mat-checkbox>
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.sistem_senzorial.auditor.retinut" (change)="changestate(evaluare_reflexe_primitive.sistem_senzorial.auditor, 'retinut')" > Reținut  </mat-checkbox>
</span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> &nbsp;&nbsp;&nbsp;&nbsp; Kinestezic
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.sistem_senzorial.kinestezic.integrat" (change)="changestate(evaluare_reflexe_primitive.sistem_senzorial.kinestezic, 'integrat')" > Integrat  </mat-checkbox>
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.sistem_senzorial.kinestezic.partial_retinut" (change)="changestate(evaluare_reflexe_primitive.sistem_senzorial.kinestezic, 'partial_retinut')" > Parțial reținut  </mat-checkbox>
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.sistem_senzorial.kinestezic.retinut" (change)="changestate(evaluare_reflexe_primitive.sistem_senzorial.kinestezic, 'retinut')" > Reținut  </mat-checkbox>
</span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> &nbsp;&nbsp;&nbsp;&nbsp; Vestibular
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.sistem_senzorial.vestibular.integrat" (change)="changestate(evaluare_reflexe_primitive.sistem_senzorial.vestibular, 'integrat')" > Integrat  </mat-checkbox>
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.sistem_senzorial.vestibular.partial_retinut" (change)="changestate(evaluare_reflexe_primitive.sistem_senzorial.vestibular, 'partial_retinut')" > Parțial reținut  </mat-checkbox>
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.sistem_senzorial.vestibular.retinut" (change)="changestate(evaluare_reflexe_primitive.sistem_senzorial.vestibular, 'retinut')" > Reținut  </mat-checkbox>

</span>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> 2.	Reflex tonic labirintic de Flexie/Extensie: </span>

<mat-form-field style="width: 100%;" >
    <mat-label>-	Observarea posturii</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.observarea_posturii" ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>- Evaluarea tonusului muscular și musculaturii CORE</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.evaluarea_tonusului_muscular" ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>- DV- avion (observarea reapirației)</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.dv_avion" ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>- DD- capul în piept ( observarea MS și MI, respirație)</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.capul_in_piept" ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>- Decubit lateral</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.decubit_lateral" ></textarea>
</mat-form-field>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> 3.	Reflexul spinal GALANT: </span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> -	Patrupedie/ DV- trage degetul de-a lungul coloanei vertebrale Stg/ Drp </span>
<mat-form-field style="width: 100%;" >
    <mat-label>DRP</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.reflex_spinal_drp" ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>STG</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.reflex_spinal_stg" ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>- Observați dacă pelvisul este simetric ori se rotește în mers</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.obs_pelvis" ></textarea>
</mat-form-field>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> 4.	Reflexul tonic cervical ASIMETRIC: </span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> -	DD/ patrupedie (întoarcerea capului stg/drp) </span>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> &nbsp;&nbsp;&nbsp;&nbsp; STG
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.reflex_tonic_cervical.stang.integrat" (change)="changestate(evaluare_reflexe_primitive.reflex_tonic_cervical.stang, 'integrat')" > Integrat  </mat-checkbox>
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.reflex_tonic_cervical.stang.partial_retinut" (change)="changestate(evaluare_reflexe_primitive.reflex_tonic_cervical.stang, 'partial_retinut')" > Parțial reținut  </mat-checkbox>
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.reflex_tonic_cervical.stang.retinut" (change)="changestate(evaluare_reflexe_primitive.reflex_tonic_cervical.stang, 'retinut')" > Reținut  </mat-checkbox>
</span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> &nbsp;&nbsp;&nbsp;&nbsp; DRP
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.reflex_tonic_cervical.drept.integrat" (change)="changestate(evaluare_reflexe_primitive.reflex_tonic_cervical.drept, 'integrat')" > Integrat  </mat-checkbox>
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.reflex_tonic_cervical.drept.partial_retinut" (change)="changestate(evaluare_reflexe_primitive.reflex_tonic_cervical.drept, 'partial_retinut')" > Parțial reținut  </mat-checkbox>
&nbsp;&nbsp;
<mat-checkbox [checked]="evaluare_reflexe_primitive.reflex_tonic_cervical.drept.retinut" (change)="changestate(evaluare_reflexe_primitive.reflex_tonic_cervical.drept, 'retinut')" > Reținut  </mat-checkbox>
</span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> 5.	Reflexul tonic cervical SIMETRIC: </span>

<mat-form-field style="width: 100%;" >
    <mat-label>- Patrupedie/ încercarea atingerii patrupediei, Flexia/ Extensia capului</mat-label>
    <textarea matInput [(ngModel)]="evaluare_reflexe_primitive.reflexul_tonic_cervical_simetric.patrupedie_incercarea_atingerii_patrupediei" ></textarea>
</mat-form-field>
<ng-container *ngIf="caz == 1">

<button mat-raised-button (click)="semneazaRL()" > Semnatura Reprezentant Legal</button>
<button mat-raised-button (click)="semneazaTerapeut()"  > Semnatura Terapeut</button>
<button mat-raised-button (click)="openPDFPreviewComponent()" > Previzualizeaza</button>
</ng-container>

<!-- </div> -->
<ng-container *ngIf="caz == 2">
  <button mat-raised-button (click)="openPDFPreviewSsaveModificari()"  > Genereaza Document </button>
  <button mat-raised-button color="warn"  mat-dialog-close > Inchide </button>
</ng-container>
