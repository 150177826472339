import { Component, OnInit } from '@angular/core';
import { ChestionarDeAlergiiComponent } from './componente-chestionare/chestionar-de-alergii/chestionar-de-alergii.component';
import { ContractFurnizorServiciiSocialeComponent } from './componente-contracte/contract-furnizor-servicii-sociale/contract-furnizor-servicii-sociale.component';
import { ContractInchiriereComponent } from './componente-contracte/contract-inchiriere/contract-inchiriere.component';
import { ContractMasinaComponent } from './componente-contracte/contract-masina/contract-masina.component';
import { EvaluareIndependentaMnfComponent } from './componente-evaluari/evaluare-independenta-mnf/evaluare-independenta-mnf.component';
import { EvaluareMariComponent } from './componente-evaluari/evaluare-mari/evaluare-mari.component';
import { EvaluareMersComponent } from './componente-evaluari/evaluare-mers/evaluare-mers.component';
import { EvaluareaFunctieiMotoriiGrosiereComponent } from './componente-evaluari/evaluarea-functiei-motorii-grosiere/evaluarea-functiei-motorii-grosiere.component';
import { EvaluareaReflexelorPrimitiveComponent } from './componente-evaluari/evaluarea-reflexelor-primitive/evaluarea-reflexelor-primitive.component';
import { FisaEvaluareInfsParintiComponent } from './componente-evaluari/fisa-evaluare-infs-parinti/fisa-evaluare-infs-parinti.component';
import { FisaExaminareLogopedicaComponent } from './componente-evaluari/fisa-examinare-logopedica/fisa-examinare-logopedica.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { FisaMonitorizarePpiComponent } from './componente-monitorizari-plan-interventie/fisa-monitorizare-ppi/fisa-monitorizare-ppi.component';
import { PlanDeInterventiePersonalizatComponent } from './componente-monitorizari-plan-interventie/plan-de-interventie-personalizat/plan-de-interventie-personalizat.component';

@Component({
  selector: 'app-templatedocumente',
  templateUrl: './templatedocumente.component.html',
  styleUrls: ['./templatedocumente.component.css']
})
export class TemplatedocumenteComponent implements OnInit {

  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }
  openSnackBar() {
    this._snackBar.open('Error !! Template / Interface mismatch','close');
  }
  openEvaluareaFunctieiMotoriiGrosiereComponent(){
    const dialogRef = this.dialog.open(EvaluareaFunctieiMotoriiGrosiereComponent, {
      width: '80%',
      height: '100%',
      data: {}
    });
  }
  openEvaluareaReflexelorPrimitiveComponent(){
    const dialogRef = this.dialog.open(EvaluareaReflexelorPrimitiveComponent, {
      width: '80%',
      height: '100%',
      data: {}
    });

  }
  openFisaMonitorizarePpiComponent(){
    const dialogRef = this.dialog.open(FisaMonitorizarePpiComponent, {
      width: '80%',
      height: '100%',
      data: {}
    });
    // this.openSnackBar()
  }
  openPlanDeInterventiePersonalizatComponent(){
    const dialogRef = this.dialog.open(PlanDeInterventiePersonalizatComponent, {
      width: '80%',
      height: '100%',
      data: {}
    });
    // this.openSnackBar()
  }
  openFisaExaminareLogopedicaComponent(){
    const dialogRef = this.dialog.open(FisaExaminareLogopedicaComponent, {
      width: '80%',
      height: '100%',
      data: {}
    });
    // this.openSnackBar()
  }
  openFisaEvaluareInfsParintiComponent(){
    const dialogRef = this.dialog.open(FisaEvaluareInfsParintiComponent, {
      width: '80%',
      height: '100%',
      data: {}
    });
  }
  
  openEvaluareMersComponent(){
    const dialogRef = this.dialog.open(EvaluareMersComponent, {
      width: '80%',
      height: '100%',
      data: {}
    });
    // this.openSnackBar()
  }
  
  openEvaluareIndependentaMnfComponent(){
    const dialogRef = this.dialog.open(EvaluareIndependentaMnfComponent, {
      width: '80%',
      height: '100%',
      data: {}
    });
    // this.openSnackBar()
  }
  openEvaluareMariComponent(){
      const dialogRef = this.dialog.open(EvaluareMariComponent, {
      width: '80%',
      height: '100%',
      data: {}
    });
    // this.openSnackBar()
  }
  openChestionarDeAlergiiComponent(){
    const dialogRef = this.dialog.open(ChestionarDeAlergiiComponent, {
    width: '80%',
    height: '100%',
    data: {}
    });
    // this.openSnackBar()
  }
  openContractFurnizorServiciiSocialeComponent(){
    const dialogRef = this.dialog.open(ContractFurnizorServiciiSocialeComponent, {
      width: '80%',
      height: '100%',
      data: {}
      });  
    // this.openSnackBar()
  }
  openContractMasinaComponent(){
    const dialogRef = this.dialog.open(ContractMasinaComponent, {
      width: '80%',
      height: '100%',
      data: {}
      });  
    // this.openSnackBar()
  }
  openContractInchiriereComponent(){
    const dialogRef = this.dialog.open(ContractInchiriereComponent, {
      width: '80%',
      height: '100%',
      data: {}
      });  
    // this.openSnackBar()
  }

}
