import { Adresa, AdresaCreate } from '../models/pacientdetails.model';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of  } from 'rxjs';
import {catchError} from 'rxjs/operators/catchError';
import { AdresaCreateInterface, AdresaInterface, ReprezentantLegalCreateInterface, ReprezentantLegalInterface, ReprezentantLegalUpdateInterface } from '../interfaces/pacient.interface';
import { serverurl } from './server.service';
const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
    providedIn: 'root'
})

export class asocPacientReprezentantLegalService{
  // private server_url="http://optimabk.ztools.ro/api/v1/adresa/"
  private server_url=serverurl+"/api/v1/asocpacientreprezentantlegal/"

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
      private http: HttpClient
  ){}

  addAsocToServer(asocierenoua){
      console.log("Add asociere to server ==>> ", asocierenoua)
      return (this.http.post<any>(this.server_url,{'pacientid': asocierenoua.pacientid, 'datecontactid':asocierenoua.datecontactid,'datapersonaleid':asocierenoua.datapersonaleid, 'calitateid':asocierenoua.calitateid}, this.httpOptions))
  }

  addAsocToServerFromBeneficiar(asocierenoua){
    // console.log("Add asociere to server from beneficiar ==>> ", asocierenoua)
    return (this.http.post<any>(this.server_url+"createfrombeneficiar/",asocierenoua, this.httpOptions))
  }

  updateAsocToServer(asociere : ReprezentantLegalUpdateInterface){
    return this.http.put<any>(this.server_url+asociere.id,asociere, this.httpOptions)
  }

  getRLFromServer(id){
    return this.http.get<any>(this.server_url+id,this.httpOptions)
  }

  }



