import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of  } from 'rxjs';
import {catchError} from 'rxjs/operators/catchError';
import { serverurl } from './server.service';
import { CasieriiServiciiInterface } from '../interfaces/nomenclatoare.interface';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
  providedIn: 'root'
})
export class CasierieService {
  private server_url=serverurl+"/api/v1/addserviciuincasierie/"

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient
  ) { }

  addserviciuincasierie(serviciu_nou: CasieriiServiciiInterface){
    console.log("Add Serviciu in Casierie to server ==>> ", serviciu_nou)
    return (this.http.post<any>(this.server_url, serviciu_nou, this.httpOptions))
  }

  getbybeneficiarid(beneficiarid: number){
    console.log("Get servicii din casierie by beneficiar id ==>> ", beneficiarid)
    return (this.http.get<any>(this.server_url+beneficiarid ))
  }
}
