import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { TipDiagnostic, Diagnostic, DiagnosticCreate, ValabilitateCreate } from 'src/app/models/nomenclatoare.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { TipDiagnosticService } from 'src/app/services/tipdiagnostic.service';
import { IntervalService } from '../../services/interval.service'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { DatePipe } from '@angular/common'
import { MatInput } from '@angular/material/input';
import { ValabilitateInterface, ValabilitateCreateInterface, TipDiagnosticInterface, DiagnosticInterface,DiagnosticCreateFaraTipDiagnosticInterface } from 'src/app/interfaces/nomenclatoare.interface';
// import { threadId } from 'worker_threads';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { DiagnosticeditComponent } from '../diagnosticedit/diagnosticedit.component';
import { MatDialog } from '@angular/material/dialog';
// import { threadId } from 'worker_threads';
// import { type } from 'os';


export const MY_FORMATS = {
  parse: {
    dateInput: 'd',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-diagnostic',
  templateUrl: './diagnostic.component.html',
  styleUrls: ['./diagnostic.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DiagnosticComponent implements OnInit {
  diagnostice: Diagnostic[] = [];
  public diagnosticList : any[];
  tipDiagnostic: TipDiagnostic[];
  displayedColumns: string[] = ['id','cod', 'denumire', 'edit','deleteselect'];
  dataSource: any
  newDiagnostic = new DiagnosticCreate();
  interval:ValabilitateInterface
  intervalcreate: ValabilitateCreateInterface
  listaintervale:ValabilitateInterface[] = []
  start: Date
  end: Date
  startformated: string
  todelete: any[] = []
  endformated: string
  public IntervalList: ValabilitateInterface [] = []
  public TipDiagnosticList: TipDiagnosticInterface [] = []
  public selectedTipDiagnostic = new FormControl();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialog: MatDialog,public tipDiagnosticService: TipDiagnosticService,public diagnosticService: DiagnosticService, public intervalService: IntervalService,public datepipe: DatePipe) { }

  ngOnInit(): void {
  //  this.tipDiagnostic = this.tipDiagnosticService.getTipDiagnostic();
  //  this.diagnostice = this.diagnosticService.getDiagnostic();
  //  this.dataSource = new MatTableDataSource(this.diagnostice);

  this.tipDiagnosticService.getfromserver()
    .subscribe( (data:TipDiagnosticInterface[]) => {
      if (data){
        this.TipDiagnosticList = data
        console.log (" tip diagnostic list in diagnostic component ==>> ", this.TipDiagnosticList)
      }
    })

   this.diagnosticService.getfromserver()
   .subscribe( (data:DiagnosticInterface[] ) => {
     if (data){
       this.diagnosticList = data
       this.dataSource =new MatTableDataSource(this.diagnosticList)
      this.dataSource.paginator = this.paginator;

     }
   })

  }
  openDiagnosticEdit(diagnostic):void{
    const dialogRef = this.dialog.open(DiagnosticeditComponent, {
      width: '850px',
      height: '550px',
      data: {
        diagnostic: diagnostic,
        tipDiagnostic: this.TipDiagnosticList
      }
    });
    dialogRef.afterClosed().subscribe( result =>{
      this.updatedata()
    })
  }
  AddDiagnostic(){
    this.diagnosticService.addDiagnostic(this.newDiagnostic);
    this.diagnostice = this.diagnosticService.getDiagnostic();
    this.dataSource = new MatTableDataSource(this.diagnostice);
      this.dataSource.paginator = this.paginator;
      this.newDiagnostic = new Diagnostic();
}
marktodelete(deleteid){
  if (this.todelete.includes(deleteid)){
    console.log("Id-ul deja selectat !! ")
    this.todelete.forEach( (value,index) =>{
      if (value == deleteid) this.todelete.splice(index,1)
    })
  }
  else{
    this.todelete.push(deleteid)
  }
  console.log("Id-uri de sers ==>> ",this.todelete)
}
deleteitems(){
  this.todelete.forEach( value =>{
    this.diagnosticService.deletefromserver(value).subscribe( (data) =>{
      if (data){
        console.log("Data from delete categorie de servicii ==>> ", data)
        this.updatedata()
      }
    })
  })
}
updatedata(){
  this.diagnosticService.getfromserver()
  .subscribe((data:Diagnostic[]) => {
    if (data){
      console.log("data din cat service subscribe ==>> ", data)
      this.diagnostice = data
      this.dataSource = new MatTableDataSource(this.diagnostice);
      this.dataSource.paginator = this.paginator;
    }
  });
}

someMethod(value){
  // this.selectedTipDiagnostic
  console.log(this.selectedTipDiagnostic.value);
}

valabilitatestatuschange(v){
  console.log("status v =>", v)
  this.newDiagnostic.arevalabilitate = v
}
startdateset(v) {
  let vd2 = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
  let intermediar = this.datepipe.transform(new Date(vd2),"MM.dd.YYYY")
  this.start = new Date(intermediar)
}
enddateset(v) {
  let vd = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
  let intermediar = this.datepipe.transform(new Date(vd),"MM.dd.YYYY")
  this.end = new Date(intermediar)
  return vd
}

addnewdiagnostic(){
  if (this.selectedTipDiagnostic.value.length){
    console.log( " Din add new diagnostic =>", this.selectedTipDiagnostic.value);
    console.log( " Din add new diagnostic lenght =>", this.selectedTipDiagnostic.value.length);
  }
  console.log("Selected Tip Diagnostic la add new Diagnostic ==>> ",this.selectedTipDiagnostic.value);
  console.log ("categoriiservicii component -> addnewcategorie() -> newcat inainte de orice  => ", this.newDiagnostic)
  if (this.newDiagnostic.arevalabilitate){ // check daca este bifata valabilitatea
    console.log ("categoriiservicii component -> addnewcategorie() -> are valabilitate TRUE  => ", this.newDiagnostic.arevalabilitate)
    this.intervalcreate = new ValabilitateCreate()
    this.intervalcreate.format_type = "MM.dd.YYYY"
    this.intervalcreate.start = this.start.toString()
    this.intervalcreate.end = this.end.toString()
    console.log("categoriiservicii component -> addnewcategorie() -> this.intervalcreate  => ",this.intervalcreate)
    console.log("categorii array 1 => -- !! -- ==> ",this.tipDiagnostic)      //commented before
    this.intervalService.addIntervalToServer(this.intervalcreate)
      .subscribe((data)=>{
        if (data){
          console.log("data from add interval to server => ", data)
          this.newDiagnostic.valabilitateid = data
          console.log("newcat.valabilitateid from add interval to server from subscribe => ",this.newDiagnostic.valabilitateid)
          if (this.selectedTipDiagnostic.value){
            console.log( " Din add new diagnostic fara valabilitate =>", this.selectedTipDiagnostic.value);
            this.newDiagnostic.tipdiagnostic_ids = this.selectedTipDiagnostic.value
            console.log ("this.newDiagnostic full object daca nu are valabilitate dar are tip diagnostic => ", this.newDiagnostic)
          }
          // console.log("type of newcat.valabilitateid from add interval to server from subscribe => ",typeof(this.newDiagnostic.valabilitateid))
          this.diagnosticService.addDiagnosticCuValabilitate(this.newDiagnostic)
              .subscribe( data => {
                console.log("data put id ==> ",data.id)
                console.log("data keys ==> ",Object.keys(data))
                console.log("data full object ==> ",data)
                this.diagnosticList.push(data)
                console.log("categorii array => -- !! -- ==> ",this.selectedTipDiagnostic.value)      //commented before
                this.refreshformafteradd()
              });
        }
      })
    console.log("categorii array 2 => -- !!!! -- ==> ",this.tipDiagnostic)      //commented before
    console.log("newcat full object from add interval to server => ",this.newDiagnostic)
  }
  else{
    console.log ("categoriiservicii component -> addnewcategorie() -> are valabilitate FALSE  => ", this.newDiagnostic.arevalabilitate)
    console.log ("this.newDiagnostic full object daca nu are valabilitate  => ", this.newDiagnostic)
    if (this.selectedTipDiagnostic.value){
      console.log( " Din add new diagnostic fara valabilitate =>", this.selectedTipDiagnostic.value);
      this.newDiagnostic.tipdiagnostic_ids = this.selectedTipDiagnostic.value
      console.log ("this.newDiagnostic full object daca nu are valabilitate dar are tip diagnostic => ", this.newDiagnostic)
    }
    this.diagnosticService.addDiagnosticFaraValabilitate(this.newDiagnostic)
      .subscribe(data =>{
        if (data){
          console.log("data din addHeroFaraValabilitate ==>> ",data)
          this.diagnosticList.push(data)
          this.refreshformafteradd()
        }
      })
  }
}

@ViewChild('startDate',{
  read:MatInput
}) startDate:MatInput;
@ViewChild('endDate',{
  read:MatInput
}) endDate:MatInput;

refreshformafteradd(){
  this.newDiagnostic = new DiagnosticCreate();
  this.startDate.value='';
  this.endDate.value='';
  this.dataSource = new MatTableDataSource(this.diagnosticList);
      this.dataSource.paginator = this.paginator;
      this.selectedTipDiagnostic = new FormControl();
  console.log("===>> REFRESH FORMAT <<===")
}

gettipdiagnostic_ids(diagnosticid: number){

}

}

