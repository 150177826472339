import { IntervalIndisponibilitateCreateFromPacientInterface, IntervalIndisponibilitateInterface, PacientTwoServerInterface, DateContactCreateTwoInterface, ReprezentantLegalCreateTwoInterface, ProgramareCreateInterface, ProgramareInterface, DatePersonaleInterface,DatePersonaleCreateInterface, AdresaInterface, AdresaCreateInterface, DateContactInterface, PacientInterface, programareRecurentaInterface, DateContactCreateInterface, PacientCreateInterface, programareRecurentaCreateInterface, CalitateInterface, ReprezentantLegalCreateInterface, ReprezentantLegalValuesInterface, IntervalIndisponibilitateInterfaceCreate, DateContactCreatePacientCreateInterface, PacientCreateCreatePacientInterface, DateContactEmailTelefonInterface, RLCreateFromBeneficiarInterface, ReprezentantLegalInterface, ReprezentantLegalCompletInterface, ReprezentantLegalUpdateInterface, DatePersonaleDialogCardnterface, DateContactDialogCardnterface, RLDialogCardInterface, PacientNakedInterface, PCDialogCardInterface, SemnaturaRLCreateInterface} from '../interfaces/pacient.interface';
import { Valabilitate, Intervalnew, Interval, } from "../models/nomenclatoare.model";
import { EventAction } from 'calendar-utils'
import { CalendarEvent } from 'calendar-utils'
import { MatTableDataSource } from '@angular/material/table';

export class SemnaturaRLCreate implements SemnaturaRLCreateInterface{
  constructor(
    public semnatura ?: string,
    public idFisier ?: string
  ){}
}

export class PacientNaked implements PacientNakedInterface{
  constructor(
    public aprobat : boolean = false,
    public greutate ?: number,
    public inaltime ?: number,
    public independent : boolean = false,
    public orar_de_somn : boolean = false,
    public reprezentant_legal_persoana_de_contact : boolean = true
  ){}
}

export class PacientTwoServer implements PacientTwoServerInterface{
    constructor(
        public id?: number,
        public aprobat ?:boolean,
        public date_personale?: DatePersonale,
        public date_contact?: DateContact,
        public reprezentant_legal_persoana_de_contact ?: boolean,
        public persoane_contact?: ReprezentantLegalComplet[],
        public reprezentanti_legali?: ReprezentantLegalComplet[],
        public greutate?: number,
        public inaltime?:number,
        public orar_de_somn ?: boolean,
        public independent ?: boolean,
        public intervale_indisponibilitate?: IntervalIndisponibilitate[],
    ){

    }
}

export class DateContactEmailTelefon  implements DateContactEmailTelefonInterface{
    constructor(
    public id?:number,
    public telefon?:string,
    public email?:string
    ){}
}

export class DateContactCreateTwo implements DateContactCreateTwoInterface{
    constructor(
    public adresa?: AdresaCreateInterface,
    public telefon?:string,
    public email?:string
    ){
        adresa = new AdresaCreate()
    }
}


export class ReprezentantLegalComplet implements ReprezentantLegalCompletInterface{
    constructor(
        public id ?: number,
        public pacientid ?: number,
        public datecontactid ?: number,
        public datapersonaleid ?: number,
        public are_cont : boolean = false,
        public datepersonale?: DatePersonaleCreateInterface,
        public datecontact?: DateContactCreatePacientCreateInterface,
        public calitateid ?: number ,
    ){
        datepersonale = new DatePersonaleCreate();
        datecontact = new DateContactCreatePacientCreate();
    }
  }
export class ReprezentantLegalCreateTwo implements ReprezentantLegalCreateTwoInterface{
    constructor(
        public date_personale?: DatePersonaleCreateInterface,
        public date_contact?: DateContactCreatePacientCreateInterface,
        public calitateid : number = -1,
    ){
        date_personale = new DatePersonaleCreate();
        date_contact = new DateContactCreatePacientCreate();
    }
  }

export class RLCreateFromBeneficiar implements RLCreateFromBeneficiarInterface{
    constructor(
        public datepersonale?: DatePersonaleCreateInterface,
        public datecontact?: DateContactCreatePacientCreateInterface,
        public calitateid : number = -1,
        public pacientid : number = -1,
        public id ?: number,
        public are_cont : boolean = false
    ){
        datepersonale = new DatePersonaleCreate();
        datecontact = new DateContactCreatePacientCreate();
    }
}

export class PacientCreateCreatePacient implements PacientCreateCreatePacientInterface {
    constructor(
    public date_personale?: DatePersonaleCreateInterface,
    public date_contact?: DateContactCreatePacientCreateInterface,
    public reprezentant_legal_persoana_de_contact: boolean = true,
    public reprezentant_legal_nonusers?: ReprezentantLegalCreateTwo[],
    public persoana_contact_nonusers?: ReprezentantLegalCreateTwo[],
    public greutate?: number,
    public inaltime?:number,
    public orar_de_somn: boolean = false,
    public independent: boolean = true,
    public intervale_indisponibilitate?: IntervalIndisponibilitateInterfaceCreate[],
    public program_de_somn?: Valabilitate[]
    ){
      date_personale = new DatePersonaleCreate();
      date_contact = new DateContactCreatePacientCreate();
      reprezentant_legal_nonusers = [];
      persoana_contact_nonusers = [];
      intervale_indisponibilitate = [];
      program_de_somn = []
    }
}

export class PacientCreate implements PacientCreateInterface {
    constructor(
    public aprobat:boolean = false,
    public datepersonaleid?: number,
    public datedecontactid?: number,
    public carte_identitate_check: boolean = false,
    public date_personale?: DatePersonale,
    public date_contact?: DateContact,
    public reprezentant_legal_user: boolean = false,
    public reprezentant_legal_persoana_de_contact: boolean = true,
    public reprezentant_legal_user_id ?: number,
    public reprezentant_legal_nonuser: boolean = true,
    public reprezentant_legal_nonusers?: ReprezentantLegal[],
    public reprezentant_legal_nonuser_date_personale?: DatePersonale,
    public reprezentant_legal_nonuser_date_contact?: DateContact,
    public reprezentant_legal_calitateid?: number,
    public persoana_contact_user: boolean = false,
    public persoana_contact_user_id?: number,
    public persoana_contact_nonuser: boolean = true,
    public persoana_contact_nonusers?: ReprezentantLegal[],
    public persoana_contact_nonuser_date_personale?: DatePersonale,
    public persoana_contact_nonuser_date_contact?: DateContact,
    public persoana_contact_calitateid?: number,
    public greutate?: number,
    public inaltime?:number,
    public orar_de_somn: boolean = false,
    public independent: boolean = true,
    public intervale_indisponibilitate?: IntervalIndisponibilitateCreate[],
    public program_de_somn?: Valabilitate[]
    ){}
}


export class DateContactCreatePacientCreate implements DateContactCreatePacientCreateInterface{
    constructor(
    public adresa?: AdresaCreateInterface,
    public telefon?:string,
    public email?:string
    ){
        adresa = new AdresaCreate()
    }
}

export class ProgramareCreate implements ProgramareCreateInterface{
  constructor(
   public title?: string, //
   public recurent?: boolean, //
   public draggable?: boolean, //
   public allDay?: boolean, //
   public cssClass?: string, //
   public recurenta_zile?:number, //
   public durata?:number, //
   public specialitate_id?:any, //
   public pacientid?: number, //
   public medicid?: number, //
   public color_id?: number, //
   public recurenta_id?:number, //
   public status_id?:number,
   public datacalendaristica?: string,
   public servicii_id?:number[],
   public departament_id?:number[],
   public actions_id?:number[],
   public actions?:EventAction[],
   public interval_start_id?: number,
   public intervalnew_start_id?: number,
   public interval_start?:Interval,
   public intervalnew_start ?: Intervalnew,
   public interval_end_id ?: number,
   public intervalnew_end_id ?: number,
   public interval_end ?: Interval,
   public intervalnew_end ?: Intervalnew,
  ){

  }
}
export class ReprezentantLegalUpdate implements ReprezentantLegalUpdateInterface{
    constructor(
    public id ?: number,
    public pacientid ?: number,
    public detecontactid ?: number,
    public datapersonaleid ?: number,
    public calitateid ?: number,
    ){}

}
export class ReprezentantLegalCreate implements ReprezentantLegalCreateInterface{
  constructor(
    public pacientid ?: number,
    public datecontactid ?: number,
    public datapersonaleid ?: number,
    public calitateid ?: number,
  )
  {}
}

export class ReprezentantLegal implements ReprezentantLegalValuesInterface{
  constructor(
    public datecontact ?: DateContact,
    public datepersonale ?: DatePersonale,
    public calitate ?: Calitate
  )
  {}
}

export class DatePersonale implements DatePersonaleInterface {
    constructor(
    public id?: number,
    public nume?: string,
    public prenume?: string,
    public sex?: string,
    public data_nasterii?: string,
    public cnp?: string,
    public locul_nasterii?: string,
    public carte_identitate?: string,
    public serie_carte_identitate?: string,
    public numar_carte_identitate?: string
    )
    {}
}

export class PCDialogCard implements PCDialogCardInterface {
  constructor(
    public reprezentant_legal_persoana_de_contact: boolean = true,
    public persoane_contact: ReprezentantLegalCompletInterface[] = []
    )
  {}
}

export class RLDialogCard implements RLDialogCardInterface {
  constructor(
    public reprezentanti_legali: ReprezentantLegalCompletInterface[] = []
    )
  {}
}

export class DateContactDialogCard implements DateContactDialogCardnterface {
  constructor(
    public telefon?: string,
    public email?: string,
    public adresa?: AdresaInterface,
    )
  {}
}

export class DatePersonaleDialogCard implements DatePersonaleDialogCardnterface {
  constructor(
    public aprobat?: string,
    public nume?: string,
    public prenume?: string,
    public sex?: string,
    public data_nasterii?: string,
    public cnp?: string,
    public locul_nasterii?: string,
    public serie_carte_identitate?: string,
    public numar_carte_identitate?: string,
    public orar_de_somn?: boolean,
    public greutate?: number,
    public inaltime?: number,
    public carte_identitate_check?: boolean,
    public independent?: boolean,
    public intervale_indisponibilitate?: MatTableDataSource<IntervalIndisponibilitateInterface>
    )
    {}
}

export class DatePersonaleCreate implements DatePersonaleCreateInterface {
    constructor(
    public nume?: string,
    public prenume?: string,
    public sex?: string,
    public data_nasterii?: string,
    public cnp?: string,
    public locul_nasterii?: string,
    public serie_carte_identitate?: string,
    public numar_carte_identitate?: string
    )
    {}
}

export class Adresa implements AdresaInterface {
    constructor(
    public id?: number,
    public strada?: string,
    public numar?: string,
    public bloc?: string,
    public etaj?: string,
    public ap?: string,
    public localitate?: string,
    public judet?: string,
    ){}
}
export class Calitate implements CalitateInterface {
  constructor(
  public id?: number,
  public calitate?: string,
  ){}
}

export class AdresaCreate implements AdresaCreateInterface {
    constructor(
    public strada?: string,
    public numar?: string,
    public bloc?: string,
    public etaj?: string,
    public ap?: string,
    public localitate?: string,
    public judet?: string,
    ){}
}

export class DateContact implements DateContactInterface{
    constructor(
    public id?: number,
    public adresaid?: number,
    public adresa?: Adresa,
    public telefon?:string,
    public email?:string
    ){}
}

export class DateContactCreate implements DateContactCreateInterface{
    constructor(
    public adresaid?: number,
    public adresa?: AdresaInterface,
    public telefon?:string,
    public email?:string
    ){}
}

export class OrarDeSomn {
    constructor(
        public start_interval?: Date,
        public end_interval ?: Date
    ){}
}

// export class PacientTwoServer

export class Pacient implements PacientInterface {
    constructor(
    public id?: number,
    public aprobat:boolean = false,
    public date_personale?: DatePersonale,
    public date_contact?: DateContact,
    public reprezentant_legal_user: boolean = true,
    public reprezentant_legal_persoana_de_contact: boolean = true,
    public reprezentant_legal_user_id ?: number,
    public reprezentant_legal_nonuser: boolean = false,
    public reprezentant_legal_nonusers?: ReprezentantLegal[],
    public reprezentant_legal_nonuser_date_personale?: DatePersonale,
    public reprezentant_legal_nonuser_date_contact?: DateContact,
    public reprezentant_legal_calitateid?: number,
    public persoana_contact_user: boolean = true,
    public persoana_contact_user_id?: number,
    public persoana_contact_nonuser: boolean = false,
    public persoana_contact_nonusers?: ReprezentantLegal[],
    public persoana_contact_nonuser_date_personale?: DatePersonale,
    public persoana_contact_nonuser_date_contact?: DateContact,
    public persoana_contact_calitateid?: number,
    public greutate?: number,
    public inaltime?:number,
    public orar_de_somn: boolean = false,
    public independent: boolean = true,
    public program_de_somn?: Valabilitate[]
    ){
        // this.date_personale = new DatePersonale();
        // this.date_contact = new DateContact();
        // this.reprezentant_legal_nonuser_date_personale = new DatePersonale();
        // this.reprezentant_legal_nonuser_date_contact = new DateContact();
        // this.persoana_contact_nonuser_date_personale = new DatePersonale();
        // this.persoana_contact_nonuser_date_contact = new DateContact();
        // this.program_de_somn = new OrarDeSomn();
    }
}
// export class IntervalIndisponibilitateCreate implements IntervalIndisponibilitateInterfaceCreate{
//     constructor(
//         public ora_start ?: string,
//         public ora_end ?: string,
//         public detalii ?: string
//     ){}
// }
export class IntervalIndisponibilitateCreate implements IntervalIndisponibilitateInterfaceCreate{
    constructor(
        public ora_start?: string,
        public ora_end?: string,
        public detalii?: string
    ){}
}

export class IntervalIndisponibilitate implements IntervalIndisponibilitateInterface{
    constructor(
        public ora_start?: string,
        public ora_end?: string,
        public detalii?: string,
        public id?: number,
        public id_pacient?: number
    ){}
}

export class IntervalIndisponibilitateCreateFromPacient implements IntervalIndisponibilitateCreateFromPacientInterface{
    constructor(
        public ora_start?: string,
        public ora_end?: string,
        public detalii?: string,
        public id_pacient?: number
    ){}
}

export class programareRecurenta implements programareRecurentaInterface {
    constructor(
        public id?:number,
        public zile?:number,
        public idsProgramari?:number[]
    ){}
}

export class programareRecurentaCreate implements programareRecurentaCreateInterface {
    constructor(
        public start_date_pr: Date = new Date(),
        public end_date_pr?:Date,
        public zile?:number,
        public pacientid?:number,
        public medicid?:number[],
        public idsProgramari?:number[]
    ){}
}
