import { Pacient, DateContact, DatePersonale, Adresa, programareRecurenta } from '../models/pacientdetails.model';
import { Injectable, OnInit } from '@angular/core';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError} from 'rxjs/operators/catchError'; 
import { LocalStorageService } from './local-storage.service';
import { CalendarDateFormatter,
  CalendarView,
  DAYS_OF_WEEK } from 'angular-calendar';
  import {
    startOfDay,
    endOfDay,
    subDays,
    addDays,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours,
  } from 'date-fns';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};


@Injectable({
  providedIn: 'root'
})
export class ProgramariRecurenteService{
  public programariRecurente: programareRecurenta[] = []
  private server_url="api/programarirecurente"
  public localstorageflag = 0;
  localStorageChanges$ = this.localStorageService.changes$;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) { 
    let data = this.localStorageService.get("ProgramariRecurenteList");
    // console.log("On Initi DAta Local storage => ",data)

    if (data)
    {
      this.localstorageflag = 1;
      this.programariRecurente = data;
      this.programariRecurente.forEach(element=> {
        this.http.post<programareRecurenta>(this.server_url, element, cudOptions).pipe(
          catchError(this.handleError)
            )
          });
      }
    else {
      // console.log("PacientList nu este Local")
    }
  }

  getProgramariRecurente():programareRecurenta[] {
    return this.programariRecurente;
  }  
  getProgramariRecurentebyId(id:number):programareRecurenta {
    for (var i of this.programariRecurente){
      if (i.id === id)
      return i;
    }
    return undefined;
  }

  updateInfo(programareselectata:programareRecurenta){
    this.programariRecurente.forEach(element => {
      if (element.id === programareselectata.id){
        // console.log("UpdatePacientInfo / OldValue => ", element);
        element = programareselectata;
      }
    });
    // console.log("UpdatePacientInfo / NewValue => ", pacientselectat);
    if (this.localstorageflag){
      this.localStorageService.remove("ProgramariRecurenteList");
      this.addtolocalStorage(this.programariRecurente);
    }
    else {
      this.addtolocalStorage(this.programariRecurente);
    }
  }
  

  addProgramare(programareNoua : programareRecurenta) {
    programareNoua.id = this.programariRecurente.length + 1;
    this.programariRecurente.push(programareNoua);
    if (this.localstorageflag){
      this.localStorageService.remove("ProgramariRecurenteList");
      this.addtolocalStorage(this.programariRecurente);
    }
    else {
      this.addtolocalStorage(this.programariRecurente);
    }
    // console.log("Lista pacienti din pacienti service =>", this.programari);

    this.http.post<Pacient>(this.server_url, programareNoua, cudOptions).pipe(
      catchError(this.handleError)
    );
    return programareNoua.id
  }

  private handleError(error: Response){
    console.log("Error from Pacient Service: => " + error)
    return Observable.throw("500 internal server error" )
  }

  addtolocalStorage(PacientList){
    // console.log("addtolocalstorage PacientList: =>", PacientList);
    // console.log("Addtolocalstorage",this.localStorageService.set("ProgramariRecurenteList", PacientList));
    this.localStorageService.set("ProgramariRecurenteList", PacientList);
  }
}
