import { Component, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter, SecurityContext, ElementRef } from '@angular/core';
import { FileElement } from './model/element';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NewFolderDialogComponent } from './modals/new-folder-dialog/new-folder-dialog.component';
import { RenameDialogComponent } from './modals/rename-dialog/rename-dialog.component';
import { FileService } from '../services/file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileElementCls } from '../models/fisier.model';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent implements OnChanges {
  foldere : FileElement[] = [];
  foldereabove : FileElement[] = [];
  suprafolders : boolean = false
  index_suprafolder : boolean = false
  index_subfolder : boolean = false
  width : number 
  height : number 
  @Input() fileElements: FileElement[];
  @Input() pacientFilesList: FileElement[];
  @Input() canNavigateUp: boolean;
  @Input() path: string;
  @Output() folderAdded = new EventEmitter<{ name: string }>();
  @Output() elementRemoved = new EventEmitter<FileElement>();
  @Output() statuschanged = new EventEmitter<FileElement>();
  @Output() elementRenamed = new EventEmitter<FileElement>();
  @Output() navigatedDown = new EventEmitter<FileElement>();
  @Output() elementMoved = new EventEmitter<{ element: FileElement; moveTo: FileElement }>();
  @Output() navigatedUp = new EventEmitter();
  @Output() downloadfile = new EventEmitter<FileElement>();
  coloaneGrid : number = 4

  constructor(public dialog: MatDialog, public fileService: FileService, public sanitizer: DomSanitizer) { }
  
  @ViewChild('listafisiere') listafisiere: ElementRef;
  ngAfterViewInit() {
    this.width = this.listafisiere.nativeElement.offsetWidth;
    this.height = this.listafisiere.nativeElement.offsetHeight;

  }

  ngOnChanges(changes: SimpleChanges): void { 
    this.getAllFolderList()
    this.coloaneGrid = this.width / 200
    if (this.coloaneGrid < Math.floor(this.coloaneGrid)){
      this.coloaneGrid = Math.floor(this.coloaneGrid) - 1
    }
    else{
      this.coloaneGrid = Math.floor(this.coloaneGrid)
    }
    console.log('Width:' + this.width);
    console.log('Coloane: ' + this.coloaneGrid);
    console.log('Height: ' + this.height);
  }

  ngOnInit(){

  }

  checkSubFoldere(elements){
    // console.log ("Check Sub Foldere ==>> ", elements)
      if(this.fileElements.length > 1){
        for (let i of this.fileElements){
          if ( i.isFolder && i.id !== elements.id){
            return true
          }
        }
      }
    return false
  }

  parcurgeFolderTree(folders, parentid){
    console.log("Parcurg TREE ==>> ", folders)
    let above_folder = folders.find( obj => {
      return obj.id === parentid
    })
    if (!above_folder){
      for (let i of folders){
        if (i.isFolder){
          this.parcurgeFolderTree(i,parentid)
        }
      }
    }
    return above_folder
  }
  async parcurgeFolderTreeInvers(parentid,pacientid){
    if (!this.foldereabove.find( obj => {
      obj.id === parentid
    })){
      // let intermediar = await this.fileService.getPacientFilesFromServer(pacientid,parentid).toPromise()
      let intermediar = await this.fileService.getPacientFileFromServerByFisierID(parentid).toPromise()
      console.log(" FOLDER ABOVE // intermediar ==>> ", intermediar)
      if ( intermediar != null){
        this.foldereabove.unshift(intermediar)
        console.log(" FOLDER ABOVE ==>> ", this.foldereabove)
        if (intermediar.parent != 'root'){
          this.parcurgeFolderTreeInvers(intermediar.parent,pacientid)
        }
      }
    }
    return this.foldereabove
  }
  resetSupraFolderList(){
    this.index_suprafolder = false
    this.foldereabove = []
  }

  getSupraFolderList(element){
    let index = false
    if (!index)
    {
      // console.log("GET SUPRA FOLDERS ==>> ", element)
      // console.log("GET SUPRA FOLDERS // this.pacientFilesList ==>> ", this.pacientFilesList)
      // console.log("GET SUPRA FOLDERS // element.parent ==>> ", element.parent)
      // console.log("GET SUPRA FOLDERS // Parcurg TREE ==>> ", this.parcurgeFolderTreeInvers(element.parent, element.idPacient))
      this.parcurgeFolderTreeInvers(element.parent, element.idPacient);
      let intermediar_two = new FileElementCls()
      intermediar_two.name = "Folder principal"
      intermediar_two.id = "root"
      intermediar_two.isFolder = true
      // console.log(" FOLDER ABOVE // intermediar_two ==>> ", intermediar_two)
      this.foldereabove.unshift(intermediar_two)
      // console.log(" FOLDER ABOVE // FINAL ==>> ", this.foldereabove)
      index = true
      this.suprafolders = true
      this.index_suprafolder = true
    }
    return true
  }

  getAllFolderList(){
    if (this.path === 'root'){
      this.suprafolders = false
      // console.log("Path ROOT == >> ",this.path)
    }
    else{ 
      this.suprafolders = true
      // console.log("Path NO ROOT == >> ",this.path)
    }
  }




  over(element){
    // console.log("Mouseover called ==>> ", element);
  }

  out(element){
    // console.log("Mouseout called ==>> ", element);
  }

  deleteElement(element: FileElement) {
    // console.log( " Current Path ==>> ", this.path)
    this.elementRemoved.emit(element);
  }

  navigate(element: FileElement) {
    if (element.isFolder) {
      this.resetSupraFolderList()
      this.navigatedDown.emit(element);
    }
  }

  navigateUp() {
    this.navigatedUp.emit();
    this.resetSupraFolderList()
  }

  moveElement(element: FileElement, moveTo: FileElement) {
    console.log("move element: =>", element);
    console.log("move moveTo: =>", moveTo);
    this.elementMoved.emit({ element: element, moveTo: moveTo });
    this.resetSupraFolderList()
  }

  openNewFolderDialog() {
    let dialogRef = this.dialog.open(NewFolderDialogComponent);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.folderAdded.emit({ name: res });
      }
    });
  }

  openRenameDialog(element: FileElement) {
    let dialogRef = this.dialog.open(RenameDialogComponent,{
      data: element,
      width: '450px'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        element.name = res;
        this.elementRenamed.emit(element);
      }
    });
  }
  approveDoc(element: FileElement) {
    element.status = 'aprobat';
    this.statuschanged.emit(element)
  }
  rejectDoc(element: FileElement) {
    element.status = 'respins';
    this.statuschanged.emit(element)
  }


  async downloadingfile(element: FileElement) {
    element.path = await this.fileService.getFileDataFromServerByFisierID(element.id).toPromise()
    this.downloadfile.emit(element);
    // console.log("PATH FROM SERVER == >> ", element.path)
    fetch(element.path.toString())
      .then(res => res.blob())
      .then(blob => {
        var link = window.document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = element.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        }
      )
  }

  openMenu(event: MouseEvent, element: FileElement, viewChild: MatMenuTrigger) {
    event.preventDefault();
    viewChild.openMenu();
  }

}
