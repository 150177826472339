import { Injectable } from '@angular/core';
import { TipDiagnostic, Valabilitate, TipDiagnosticCreate, TipDiagnosticCreateFaraValabilitate } from '../models/nomenclatoare.model';
import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {catchError} from 'rxjs/operators/catchError';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ValabilitateInterface, TipDiagnosticCreateInterface, TipDiagnosticInterface, TipDiagnosticCreateFaraValabilitateInterface } from '../interfaces/nomenclatoare.interface';
import { IntervalService } from './interval.service'
import { serverurl } from './server.service';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
  providedIn: 'root'
})
export class TipDiagnosticService {
  public tipDiagnostic: TipDiagnostic [] = []
  public localstorageflag = 0;
  // private server_url="api/tipdiagnostic";
  private server_url=serverurl+"/api/v1/tipuridediagnostic/";
  // private server_url="http://localhost/api/v1/tipuridediagnostic/";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    public intervalService: IntervalService,
    private localStorageService: LocalStorageService
    ) {
      let data = this.localStorageService.get("TipDiagnostic");
      // console.log("On Initi DAta Local storage => ",data)
      if (data)
      {
        this.localstorageflag = 1;
        this.tipDiagnostic = data;
        this.tipDiagnostic.forEach(element=> {
          this.http.post<TipDiagnostic>(this.server_url, element, cudOptions).pipe(
            catchError(this.handleError)
              )
            });
        }
      else {
        // console.log("PacientList nu este Local")
      }
    }

  getTipDiagnostic(){
    return this.tipDiagnostic
  }

  getfromserver() {
    return this.http.get<TipDiagnosticInterface[]>(this.server_url)
  }
  deletefromserver(id){
    return this.http.delete(this.server_url+id)
  }
  updatetoserver(id,departament){
    return this.http.put(this.server_url+id, departament)
  }
  addTipDiagnosticCuValabilitate(tipdiagnosticnou: TipDiagnosticCreateInterface){
    console.log("intru in add post request")
    console.log("categorie de servicii service -> addhero -> hero => ", tipdiagnosticnou)
    let toate_detaliile: TipDiagnosticCreateInterface = new TipDiagnostic()
    return(this.http.post<any>(this.server_url,tipdiagnosticnou,this.httpOptions))
  }

  addTipDiagnosticFaraValabilitate(tipdiagnosticnou: TipDiagnosticCreateFaraValabilitateInterface){
    console.log("intru in add post request fara valabilitate")
    console.log("categorie de servicii service -> addspecialitateMedic fara valabilitate -> specialitateMedic => ", tipdiagnosticnou)
    return (this.http.post<any>(this.server_url,tipdiagnosticnou,this.httpOptions))
  }

  addtipdiagnognostic(newtipdiagnostic){
    this.tipDiagnostic.push(newtipdiagnostic);
    if (this.localstorageflag){
      this.localStorageService.remove("TipDiagnostic");
      this.addtolocalStorage(this.tipDiagnostic);
    }
    else {
      this.addtolocalStorage(this.tipDiagnostic);
    }
    console.log("Lista pacienti din pacienti service =>", this.tipDiagnostic);

    return this.http.post<TipDiagnostic>(this.server_url, newtipdiagnostic, cudOptions).pipe(
      catchError(this.handleError)
    );
  }

  addtolocalStorage(PacientList){
    console.log("addtolocalstorage CategorieServicii: =>", PacientList);
    console.log("Addtolocalstorage",this.localStorageService.set("TipDiagnostic", PacientList));
  }
  private handleError(error: Response){
    console.log("Error from Pacient Service: => " + error)
    return Observable.throw("500 internal server error" )
  }
}
