import { Component, Inject, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicFormComponent } from 'src/app/components/dynamic-form/dynamic-form.component';
import { FieldConfig, Validator } from 'src/app/interfaces/field.interface';
import { Subject } from 'rxjs';
import { NompropsService } from 'src/app/services/nomprops.service';
export interface Pacient{

  nume: string;
  prenume: string;
  diagnostic: string;
  email:string;
  telefon:string;
}

const ELEMENT_DATA: Pacient[] = [
  {nume: 'Ion', prenume:"Popescu", diagnostic:"diagnostic test" ,email:"dhwon@gmail.com", telefon:"+40 712 018 528" },
  {nume: 'Maria', prenume:"Ionescu", diagnostic:"diagnostic test" ,email:"rhialto@msn.com", telefon:"+40 702 054 317" },
];

const FIELD_NUME_CONFIG: FieldConfig[] = [
  <FieldConfig>{
    type: "input",
    label: "Nume2",
    inputType: "text",
    name: "Nume",
    validations: <Validator[]>
    [
      <Validator>{
        name: "required",
        validator: Validators.required,
        message: "Camp obligatoriu"
      }
    ]
   }
  ]


@Component({
  selector: 'app-newpropertydiag',
  templateUrl: './newpropertydiag.component.html',
  styleUrls: ['./newpropertydiag.component.css']
})
export class NewpropertydiagComponent implements OnInit, OnDestroy {

  propertyname: string
  ctg:string;

  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;
  regConfig: FieldConfig[] = [];

  constructor(private nompropsService:NompropsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<NewpropertydiagComponent>,
    @Inject(MAT_DIALOG_DATA) public data) 
    { 
      this.regConfig = data.fields;
      this.ctg = data.ctg;

    }

  ngOnInit(): void {
    //this.regConfig= this.nompropsService.testval;
  }

  submit(form) {

    console.log("form.value = ", form)

    this.nompropsService.field_details = this.getnewfieldbaseprop(this.ctg,form);
    console.log("test val 4 =", this.nompropsService.field_details)
    // this._addtotableService.createpr({tablename: this.TableName, tablevalues: value}).subscribe(
    //   data => console.log('Succes',data),
    //   error => console.log('Error',error)
    // );
    //console.log("value = ", $(form))
    this.dialogRef.close(this.nompropsService.field_details);

  }

  ngOnDestroy() {
  }

  getnewfieldbaseprop(ctg:string,value:any):FieldConfig {
    var intermediara:FieldConfig
    if (ctg == "Alfanumeric" ){
      intermediara = <FieldConfig>{
        type: "input",
        label: value.Nume,
        inputType: "text",
        name: value.Nume,
        validations: <Validator[]>
        [
          <Validator>{
            name: "required",
            validator: Validators.required,
            message: "Camp obligatoriu"
          }
        ]
       }
      }
    else if(ctg == "Text-Only"){
      intermediara = <FieldConfig>{
        type: "input",
        label: value.Nume,
        inputType: "text",
        name: value.Nume,
        validations: <Validator[]>
        [
          <Validator>{
            name: "required",
            validator: Validators.required,
            message: "Camp obligatoriu"
          },

          <Validator>{
            name: "pattern",
            validator: Validators.pattern("^[a-zA-Z]+$"),
            message: "Accept only text"
          }
        ]
       }
      }
      else if (ctg=="Fara virgule"){
        intermediara = <FieldConfig>{
          type: "input",
          label: value.Nume,
          inputType: "number",
          name: value.Nume,
          validations: <Validator[]>
          [
            <Validator>{
              name: "required",
              validator: Validators.required,
              message: "Camp obligatoriu"
            },
            <Validator>{
              name: "pattern",
              validator: Validators.pattern("^\d+$"),
              message: "Accept only number"
            }
          ]
         }
        }
      else if (ctg == "Cu virgula (float)"){
        intermediara = <FieldConfig>{
          type: "input",
          label: value.Nume,
          inputType: "number",
          name: value.Nume,
          validations: <Validator[]>
          [
            <Validator>{
              name: "required",
              validator: Validators.required,
              message: "Camp obligatoriu"
            }
          ]
         }
        }
      else if (ctg == "Procent cu virgula" ){
        intermediara = <FieldConfig>{
          type: "input",
          label: value.Nume,
          inputType: "number",
          name: value.Nume,
          validations: <Validator[]>
          [
            <Validator>{
              name: "required",
              validator: Validators.required,
              message: "Camp obligatoriu"
            }
          ]
         }
        }
        else if(ctg== "Procent fara virgula"){
          intermediara = <FieldConfig>{
            type: "input",
            label: value.Nume,
            inputType: "number",
            name: value.Nume,
            validations: <Validator[]>
            [
              <Validator>{
                name: "required",
                validator: Validators.required,
                message: "Camp obligatoriu"
              },
              <Validator>{
                name: "pattern",
                validator: Validators.pattern("^\d+$"),
                message: "Accept only number"
              }
            ]
           }
          }
          else if (ctg == "Email"){
            intermediara = <FieldConfig>{
              type: "input",
              label: value.Nume,
              inputType: "email",
              name: value.Nume,
              validations: <Validator[]>
              [
                <Validator>{
                  name: "required",
                  validator: Validators.required,
                  message: "Camp obligatoriu"
                },
                <Validator>{
                  name: "pattern",
                  validator: Validators.pattern(
                    "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"
                  ),
                  message: "Invalid email"
                }
              ]
             }
            }
          else if (ctg == "Password"){
            intermediara = <FieldConfig>{
              type: "input",
              label: value.Nume,
              inputType: "password",
              name: value.Nume,
              validations: <Validator[]>
              [
                <Validator>{
                  name: "required",
                  validator: Validators.required,
                  message: "Camp obligatoriu"
                }
              ]
             }
            }
            else if (ctg == "Data"){
              intermediara = <FieldConfig>{
                type: "date",
                label: value.Nume,
                name: value.Nume,
                validations: <Validator[]>
                [
                  <Validator>{
                    name: "required",
                    validator: Validators.required,
                    message: "Camp obligatoriu"
                  }
                ]
               }
              }
            else if (ctg == "Checkbox"){
              intermediara = <FieldConfig>{
                type: "checkbox",
                label: value.Nume,
                name: value.Nume,
                value:true
              }
            }

    return intermediara;
  }

}
