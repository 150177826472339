import {  ActivitateEvaluareIndependentaMNFInterface,RowEvaluareMersInterface, CampSectiuneInterface, DocumentBeneficiarInterface, EvaluareaFunctieiMotoriiGrosiereInterface, EvaluareaINFSParintiInterface, EvaluareaReflexelorPrimitiveInterface, EvaluareaReflexelorPrimitiveOptiuniInterface, EvaluareMersInterface, DescriereScalaActivitateEvaluareIndependentaMNFInterface, EvaluareIndependentaMNFInterface, EvaluareMariInterface, OptiuniIdTextSelectedEvaluareMariInterface, OptiuniTitluContinutEvaluareMariInterface, SubcategoriiEvaluareMariInterface, OptiuniTitluSubCategorieEvaluareMariInterface, ChestionarAlergiiInterface, PPIInterface, OptiuniNumeContinutPPIInterface, OptiuniRandTabelPPIInterface, sesiunePPIInterface, structuraPPIInterface, ContractMasinaInterface, FisaMonitorizarePPIInterface } from "../interfaces/documente.interface";



export class FisaMonitorizarePPI implements FisaMonitorizarePPIInterface{
  constructor(
    public titlu:string = "Fisa de monitorizare a PPI",
    public data_monitorizare:string = "",
    public nume_pacient:string = "",
    public prenume_pacient:string = "",
    public diagnostic_pacient:string = "",
    public obiectivele_actuale:string = "",
    public grad_de_indeplinire:{
      titlu:string,
      problema_identificate:{
        titlu:string,
        continut:string
      },
      solutii:{
        titlu:string,
        continut:string
      }
    } = {
      titlu : "",
      problema_identificate : {
        titlu : "Problema identificata",
        continut : ""
      },
      solutii : {
        titlu : "Solutii",
        continut : ""
      }
    },
    public recomandare:string = "",
    public rezultate_obtinute:string = "",
    public intocmit_de:{
      titlu:string,
      nume_medic:string,
      prenume_medic:string
    } = {
      titlu : "",
      nume_medic : "",
      prenume_medic : ""
    }
  ){}
}
export class ContractMasina implements ContractMasinaInterface{
  constructor(
    public nr_contract : string = '',
    public data_contract : string = '',
    public contact_beneficiar : string = '',
    public nume_beneficiar : string = '',
    public prenume_beneficiar : string = '',
    public serie_ci_beneficiar : string = '',
    public numar_ci_beneficiar : string = '',
    public cnp_beneficiar : string = '',
    public domiciliu : string = '',
    public insotitor_beneficiar : string = '',
    public serie_ci_insotitor : string = '',
    public numar_ci_insotitor : string = '',
    public incheiat_de : string = ''
    ){}
  }

export class DocumentBeneficiar implements DocumentBeneficiarInterface{
  constructor(
    public id ?: number,
    public denumire ?: string,
    public props ?: any,
    ){}
  }

export class CampSectiune implements CampSectiuneInterface{
  constructor(
    public id ?: string,
    public text ?: string,
    public netestat : boolean = true,
    public valoare ?: number
  ){}
}

export class EvaluareaFunctieiMotoriiGrosiereDoc implements EvaluareaFunctieiMotoriiGrosiereInterface{
  constructor(
    public nume : string = 'Nume Pacient',
    public prenume : string = 'Prenume Pacient',
    public pacientid ?: number,
    public data_nasterii : string = 'Data Nasterii Pacient',
    public varsta_cronologica : string = 'Varsta Pacient',
    public evaluator : string = 'Nume Penume Evaluator',
    public conditii_de_testare : string = '',
    public performanta_adevarata_a_copilului : boolean = true,
    public comentarii : string = '',
    public gnfm_66_scor : string = '',
    public gnfm_66_scor_vechi : string = '',
    public gnfm_66_schimbari : string = '',
    public performanta : string = "DA",
    public rolator ?: number,
    public cadru_de_mers ?: number,
    public carje_subalixare ?: number,
    public carja_cu_4_pic ?: number,
    public carja ?: number,
    public mijlocnimic ?: number,
    public ort_sold ?: number,
    public ort_genunchi ?: number,
    public ort_glezna_pic ?: number,
    public ort_picior ?: number,
    public pantofi_ortopedici ?: number,
    public ortnimic ?: number,
    public mijlocaltele : string = '',
    public ortaltele : string = '',
    public ignora_campurile_goale : boolean = true,
    public sectiunea_a : CampSectiune[] = [],
    public sectiunea_b : CampSectiune[] = [],
    public sectiunea_c : CampSectiune[] = [],
    public sectiunea_d : CampSectiune[] = [],
  ){}
}

export class EvaluareaReflexelorPrimitiveOptiuni implements EvaluareaReflexelorPrimitiveOptiuniInterface{
  constructor (
    public integrat : boolean = false,
    public partial_retinut : boolean = false,
    public retinut : boolean = false,
  ){}
}

export class EvaluareaReflexelorPrimitive implements EvaluareaReflexelorPrimitiveInterface{
  constructor (
    public pacientid ?: number,
    public mers_cu_mi_in_rotatie : string = '',
    public dd_auditor : string = '',
    public dd_kinestezic : string = '',
    public asezat_stand_vestibular : string = '',
    public observarea_posturii : string = '',
    public evaluarea_tonusului_muscular : string = '',
    public dv_avion : string = '',
    public capul_in_piept : string = '',
    public decubit_lateral : string = '',
    public reflex_spinal_drp : string = '',
    public reflex_spinal_stg : string = '',
    public obs_pelvis : string = '',
    public data_evaluare : string = '',
    public reflex_tonic_cervical = {
      stang : new EvaluareaReflexelorPrimitiveOptiuni(),
      drept : new EvaluareaReflexelorPrimitiveOptiuni()
    },
    public sistem_senzorial = {
      auditor : new EvaluareaReflexelorPrimitiveOptiuni(),
      kinestezic : new EvaluareaReflexelorPrimitiveOptiuni(),
      vestibular : new EvaluareaReflexelorPrimitiveOptiuni()
    },
    public reflexul_tonic_cervical_simetric = {
      patrupedie_incercarea_atingerii_patrupediei : ''
    }

  ){}
}

export class EvaluareaINFSParinti implements EvaluareaINFSParintiInterface{
  constructor(
    public pacientid ?: number,
    public nume_pacient : string = "Nespecificat",
    public prenume_pacient : string = "Nespecificat",
    public varsta_pacient : string = "Nespecificat",
    public adresa_pacient : string = "Nespecificat",
    public diagnostic_pacient : string = "",
    public istoric_chirurgical : string = "",
    public istoric_de_botox : string = "",
    public istoric_de_fracturi_osteopenie_osteoporoza : string = "",
    public greutatea_copilului : string = "",
    public inaltime : string = "",
    public convulsii : string = "",
    public probleme_cardiace_pulmonare_renale : string = "",
    public hidrocefalie_shunt : string = "",
    public probleme_metabolice_diabet : string = "",
    public comunicare : string = "",
    public antentie : string = "",
    public abilitati_de_indeplinire_a_comenzilor : string = "",
    public abilitati_functionale_ale_copilului : string = "",
    public dureri : string = "",
    public orteze : string = "",
    public lista_echipamente_folosite : string = "",
    public lista_medicamente_suplimente : string = "",
    public somn : string = "",
    public nutritie = {
      cate_mese_zi : "",
      alergii_alimentare : "",
      exemple_alimente_consumate : ""
    },
    public oboseala : string = "",
    public stare_emotionala : string = "",
    public scoala_interactiune_sociala : string = "",
    public tehnologie_folosita_zilnic : string = "",
    public terapie : string = "",
    public nume_kinetoterapeut : string = "",
    public prenume_kinetoterapeut : string = "",
    public parinte : string = ""
  ){}
}

export class EvaluareMers implements EvaluareMersInterface{
  constructor(
    public id ?: number,
    public pacientid ?: number,
    public nume_pacient : string = "",
    public prenume_pacient : string = "",
    public varsta_pacient : string = "",
    public data_evaluare : string = '',
    public testul_de_echilibru = {
      titlu : "",
      detalii : "",
      row : [],
      scor_total_echilibru : "",
    },
    public testul_de_mers = {
      titlu : "",
      detalii : "",
      row : [],
      scor_total_mers : "",
    },
    public evaluator : string = '',
    ){}
}

export class DescriereScalaActivitateEvaluareIndependentaMNF implements DescriereScalaActivitateEvaluareIndependentaMNFInterface{
  constructor(
    public titlu : string = "",
    public text : string = "",
    ){}
}
export class EvaluareIndependentaMNF implements EvaluareIndependentaMNFInterface{
  constructor(
    public id ?: number,
    public pacientid ?: number,
    public nume_pacient : string = "",
    public prenume_pacient : string = "",
    public evaluator : string = "",
    public data_nasterii_pacient : string = "",
    public data_evaluarii : string = "",
    public descriere_scale_de_evaluare_functionala : DescriereScalaActivitateEvaluareIndependentaMNFInterface = new DescriereScalaActivitateEvaluareIndependentaMNF(),
    public descriere_scala_de_incapacitate_barthel : DescriereScalaActivitateEvaluareIndependentaMNFInterface = new DescriereScalaActivitateEvaluareIndependentaMNF(),
    public tabel_scala_barthel : {
      activitate : ActivitateEvaluareIndependentaMNFInterface[],
      total_scala_barthel : string,
      mentiuni_scala_barthel : string
    } = {
      activitate: [],
      total_scala_barthel : "",
      mentiuni_scala_barthel : ""
    },
    public scor_adl:{
      titlu:string,
      tabel_adl:{
        activitate : ActivitateEvaluareIndependentaMNFInterface[],
      },
      total_tabel_adl :string,
      calificare_tabel_adl :string
    } = {
      titlu: "",
      tabel_adl: {
        activitate: []
      },
      total_tabel_adl : "",
      calificare_tabel_adl : ""
    },
    public scala_adl:{
      titlu:string,
      tabel_scala_adl:{
        activitate:{
          id:string,
          titlu:string,
          valoare:number
        }[]
      },
      total_tabel_scala_adl:string,
      mentiuni_scala_adl:string,
    }={
      titlu:"",
      tabel_scala_adl:{
        activitate : []
      },
      total_tabel_scala_adl : "",
      mentiuni_scala_adl : ""
    }
    ){}
}

export class OptiuniTitluSubCategorieEvaluareMari implements OptiuniTitluSubCategorieEvaluareMariInterface{
  constructor(
    public titlu : string = "",
    public subcategorii : SubcategoriiEvaluareMariInterface[] = []
    ){}
}

export class OptiuniTitluContinutEvaluareMari implements OptiuniTitluContinutEvaluareMariInterface{
  constructor(
    public titlu : string = "",
    public continut : string = "",
    ){}
}


export class OptiuniIdTextSelectedEvaluareMari implements OptiuniIdTextSelectedEvaluareMariInterface{
  constructor(
    public id : string = "",
    public text : string = "",
    public selected : number = 0,
    ){}
}

export class EvaluareMari implements EvaluareMariInterface{
  constructor(
    public id ?: number,
    public pacientid ?: number,
    public nume_pacient : string = "",
    public prenume_pacient : string = "",
    public evaluator : string = "",
    public data_nasterii_pacient : string = "",
    public data_completarii : string = "",
    public comunicare_si_limbaj : OptiuniIdTextSelectedEvaluareMariInterface[] = [],
    public comportament_motor : OptiuniIdTextSelectedEvaluareMariInterface[] = [],
    public tulburari_de_dezvoltare : OptiuniIdTextSelectedEvaluareMariInterface[] = [],
    public anatomia_valului_palatin_omusorului_limbii_frenului_dintilor_buzelor : OptiuniTitluContinutEvaluareMariInterface = new OptiuniTitluContinutEvaluareMari(),
    public capacitate_de_relaxare : OptiuniTitluSubCategorieEvaluareMariInterface = new OptiuniTitluSubCategorieEvaluareMari(),
    public respiratia : OptiuniTitluSubCategorieEvaluareMariInterface = new OptiuniTitluSubCategorieEvaluareMari(),
    public imitatia_miscarilor_faciale : OptiuniTitluSubCategorieEvaluareMariInterface = new OptiuniTitluSubCategorieEvaluareMari(),
    public miscari_ale_limbii_buzelor_obrajilor_maxilarelor : OptiuniTitluSubCategorieEvaluareMariInterface = new OptiuniTitluSubCategorieEvaluareMari(),
    public aspectul_fonetic_si_fonologic : {
      titlu:string,
      subtitlu:string,
      continut:string
    } = {
      titlu : "",
      subtitlu : "",
      continut : "",
    },
    public imitare_de_onomatopee : OptiuniTitluContinutEvaluareMariInterface = new OptiuniTitluContinutEvaluareMari(),
    public discriminare_fonologica : OptiuniTitluContinutEvaluareMariInterface = new OptiuniTitluContinutEvaluareMari(),
    public aspectul_semantic : {
      titlu:string,
      vocabular:OptiuniTitluSubCategorieEvaluareMariInterface,
      comenzi:OptiuniTitluSubCategorieEvaluareMariInterface,
      intelegerea_unui_text_audiat:OptiuniTitluSubCategorieEvaluareMariInterface,
      clasificari:OptiuniTitluSubCategorieEvaluareMariInterface,
    } = {
      titlu : "",
      vocabular : new OptiuniTitluSubCategorieEvaluareMari(),
      comenzi : new OptiuniTitluSubCategorieEvaluareMari(),
      intelegerea_unui_text_audiat : new OptiuniTitluSubCategorieEvaluareMari(),
      clasificari : new OptiuniTitluSubCategorieEvaluareMari(),
    },
    public aspectul_morfosintactic : {
      titlu:string,
      sintaxa:OptiuniTitluSubCategorieEvaluareMariInterface,
      morfologie:OptiuniTitluSubCategorieEvaluareMariInterface,
      secventialitate_temporala:OptiuniTitluSubCategorieEvaluareMariInterface,
    } = {
      titlu : "",
      sintaxa : new OptiuniTitluSubCategorieEvaluareMari(),
      morfologie : new OptiuniTitluSubCategorieEvaluareMari(),
      secventialitate_temporala : new OptiuniTitluSubCategorieEvaluareMari()
    },
    public aspect_pragmatic : {
      titlu:string,
      intentionalitatea_comunicarii:OptiuniTitluSubCategorieEvaluareMariInterface,
      modul_de_comunicare:OptiuniTitluSubCategorieEvaluareMariInterface,
      foloseste_limbajul_pentru_a:OptiuniTitluSubCategorieEvaluareMariInterface,
    } = {
      titlu : "",
      intentionalitatea_comunicarii : new OptiuniTitluSubCategorieEvaluareMari(),
      modul_de_comunicare : new OptiuniTitluSubCategorieEvaluareMari(),
      foloseste_limbajul_pentru_a : new OptiuniTitluSubCategorieEvaluareMari()
    }
    ){}
}

export class ChestionarAlergii implements ChestionarAlergiiInterface{
  constructor(
    public nume_pacient : string = "",
    public prenume_pacient : string = "",
    public istoric_de_alergii : boolean = false,
    public istoric_de_alergii_detalii : string = "",
    public astm_febra_fanului : boolean = false,
    public astm_febra_fanului_detalii : string = "",
    public sensibilitate_la_alimente : boolean = false,
    public sensibilitate_la_alimente_detalii : string = "",
    public   intrebarea_6:{
      proceduri_dentare:boolean,
      contactul_cu_baloane:boolean,
      examinare_cu_manusi:boolean
    } = {
      proceduri_dentare : false,
      contactul_cu_baloane : false,
      examinare_cu_manusi : false,
    },
    public alergie_la_cauciuc : boolean = false,
    ){}
}

export class OptiuniNumeContinutPPI implements OptiuniNumeContinutPPIInterface{
  constructor(
    public nume : string = "",
    public continut : string = "",
    ){}
  }

export class PPIClass implements PPIInterface{
  constructor(
    public id ?: number,
    public pacientid ?: number,
    public titlu : string = "",
    public nume_pacient : string = "",
    public prenume_pacient : string = "",
    public domiciliu_pacient : string = "",
    public data_nastere_pacient : string = "",
    public diagnostic_pacient : string = "",
    public data_elaborarii_planului : string = "",
    public probleme_cu_care_se_confrunta_copilul : string = "",
    public evaluare_initiala : string = "",
    public stare_medicala : {
      titlu:string,
      convulsii: OptiuniNumeContinutPPIInterface,
      deficiente_fizice: OptiuniNumeContinutPPIInterface,
      probleme_pulmonare: OptiuniNumeContinutPPIInterface,
      probleme_cardiace: OptiuniNumeContinutPPIInterface,
    } = {
      titlu : "",
      convulsii : new OptiuniNumeContinutPPI() ,
      deficiente_fizice : new OptiuniNumeContinutPPI(),
      probleme_pulmonare : new OptiuniNumeContinutPPI(),
      probleme_cardiace : new OptiuniNumeContinutPPI(),
    },
    public tata_pacient:{
      nume_tata_pacient:string,
      prenume_tata_pacient:string
    } = {
      nume_tata_pacient: "",
      prenume_tata_pacient: ""
    },
    public mama_pacient:{
      nume_mama_pacient:string,
      prenume_mama_pacient:string
    } = {
      nume_mama_pacient: "",
      prenume_mama_pacient: ""
    },
    public   echipa_de_interventie:{
      nume_medic:string,
      prnume_medic:string
    }[] = [],
    public perioada_de_interventie:{
      data_start:string,
      data_end:string
    } = {
      data_start: "",
      data_end: ""
    },
    public structura_program_interventie_personalizat : {
      titlu:string,
      tabel_program_interventie_personalizat:{
        rand:OptiuniRandTabelPPIInterface[]
      }
    } = {
      titlu : "",
      tabel_program_interventie_personalizat : {
        rand: []
      }
    }
  ){
    stare_medicala.convulsii.nume = "Convulsii: "
    stare_medicala.deficiente_fizice.nume = "Deficiente fizice: "
    stare_medicala.probleme_pulmonare.nume = "Probleme pulmonare: "
    stare_medicala.probleme_cardiace.nume = "Probleme cardiace: "
  }
}
export class sesiunePPI implements sesiunePPIInterface{
  constructor(
    public start : Date = new Date(),
    public end : Date = new Date()
    ){ }
}
export class structuraPPI implements structuraPPIInterface{
  constructor(
    public sesiune : sesiunePPIInterface = new sesiunePPI(),
    public evaluare : string = "",
    public obiective : string = "",
    public metode_si_mijloace : string = "",
    public recomandari : string = "",
    ){ }
}
// constructor(
//   public nume : string = 'Nume Pacient',
//   public prenume : string = 'Prenume Pacient',
//   public pacientid ?: number,
//   public data_nasterii : string = 'Data Nasterii Pacient',
//   public varsta_cronologica : string = 'Varsta Pacient',
//   public evaluator : string = 'Nume Penume Evaluator',
//   public conditii_de_testare : string = '',
//   public performanta_adevarata_a_copilului : boolean = true,
//   public comentarii ?: string,
//   public gnfm_66_scor ?: string,
//   public gnfm_66_scor_vechi ?: string,
//   public gnfm_66_schimbari ?: string,
//   public performanta : string = "DA",
//   public rolator ?: number,
//   public cadru_de_mers ?: number,
//   public carje_subalixare ?: number,
//   public carja_cu_4_pic ?: number,
//   public carja ?: number,
//   public mijlocnimic ?: number,
//   public ort_sold ?: number,
//   public ort_genunchi ?: number,
//   public ort_glezna_pic ?: number,
//   public ort_picior ?: number,
//   public pantofi_ortopedici ?: number,
//   public ortnimic ?: number,
//   public mijlocaltele ?: string,
//   public ortaltele ?: string,
//   public ignora_campurile_goale : boolean = true,
//   public sectiunea_a : CampSectiune[] = [],
//   public sectiunea_b : CampSectiune[] = [],
//   public sectiunea_c : CampSectiune[] = [],
//   public sectiunea_d : CampSectiune[] = [],
// ){}
