import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-programare-eroare',
  templateUrl: './programare-eroare.component.html',
  styleUrls: ['./programare-eroare.component.css']
})
export class ProgramareEroareComponent implements OnInit {
  text:string = ""
  constructor( public dialogRef: MatDialogRef<ProgramareEroareComponent>, public dialg: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data:any ) {
      if(data){
        this.text = data;
      }
      else{
        this.text = "Programarea este in afara programului terapeutului. Sigur doresti sa salvezi programarea?"
      }
    }

  sendvalues(){
    this.dialogRef.close('ok')
  }

  ngOnInit(): void {
  }

}
