import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of  } from 'rxjs';
import {catchError} from 'rxjs/operators/catchError';
import { DateContactInterface, DateContactCreateInterface, DateContactEmailTelefonInterface } from '../interfaces/pacient.interface';
import { serverurl } from './server.service';
import { DateContact, DateContactEmailTelefon } from '../models/pacientdetails.model';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
    providedIn: 'root'
})

export class DateDeContactService{
  private server_url=serverurl+"/api/v1/datedecontact/"

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient
  ){}

  addDateDeContactToServer(datedecontactnou: DateContactCreateInterface){
      console.log("Add Date De Contact to Server ==>> ", datedecontactnou )
      return (this.http.post<any>(this.server_url, datedecontactnou, this.httpOptions))
  }

  updateDateContactTelefonEmail(date_contact: DateContactInterface){
    var intermediar : DateContactEmailTelefonInterface = new DateContactEmailTelefon()
    intermediar.id = date_contact.id
    intermediar.telefon = date_contact.telefon
    intermediar.email = date_contact.email
    return this.http.put<any>(this.server_url+intermediar.id,intermediar,this.httpOptions)
  }
  updateDateContactTelefonEmailTwo(date_contact: DateContactEmailTelefonInterface){
    return this.http.put<any>(this.server_url+date_contact.id,date_contact,this.httpOptions)
  }

  updateDateContact(date_contact: DateContactInterface){
    return this.http.put<any>(this.server_url+date_contact.id,date_contact,this.httpOptions)
  }
  updateDateContactStergeAdresa(id:number){
    console.log(" Update date contact sterge adresa id == >> ", id)
    return this.http.put<any>(this.server_url+"stergeadresa/"+id,this.httpOptions)
  }

}
