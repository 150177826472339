<ng-template
#customHTemplate
let-segment="segment"
let-locale="locale"
let-segmentHeight="segmentHeight"
let-isTimeLabel="isTimeLabel">
<div
  class="cal-hour-segment"
  [style.height.px]="segmentHeight"
  [class.cal-hour-start]="segment.isStart"
  [class.cal-after-hour-start]="!segment.isStart"
  [ngClass]="segment.cssClass">
  <div class="cal-time" *ngIf="isTimeLabel" >
    <!-- De aici pot modifica coloana pe care este afisata ora -->
    <!-- {{ checkSegmentDate(segment)  }} -->
    <!-- {{ checkSegmentDate(segment) | calendarDate:'weekViewHour':locale }} -->
    <!-- {{ checkSegmentDate() }} -->
    {{ segment.date | calendarDate:'weekViewHour':locale }}
  </div>
</div>
</ng-template>



<div style="text-align: right; margin-left: 2%;">
    <button mat-raised-button color="primary" (click)="setView(CalendarView.Month)"
        [class.active]="view === CalendarView.Month">
        Luna
    </button>

    <button mat-raised-button color="primary" (click)="setView(CalendarView.Week)"
        [class.active]="view === CalendarView.Week">
        Saptamana
    </button>

    <button mat-raised-button color="primary" (click)="setView(CalendarView.Day)"
        [class.active]="view === CalendarView.Day">
        Zi
    </button>
</div>


    <div [ngSwitch]="view" style="text-align: right;">

        <!-- Month Buttons View -->
        <button mat-raised-button color="primary" *ngSwitchCase="'month'" mwlCalendarPreviousView [view]="view"
            [(viewDate)]="viewDate" [excludeDays]="excludeDays"> Luna Anterioara </button>

        <button mat-raised-button color="primary" *ngSwitchCase="'month'" mwlCalendarToday [(viewDate)]="viewDate"> Luna
            Curenta </button>

        <button mat-raised-button color="primary" *ngSwitchCase="'month'" mwlCalendarNextView [view]="view"
            [(viewDate)]="viewDate" [excludeDays]="excludeDays"> Luna Urmatoare </button>

        <!--Enf of Month Buttons View -->

        <!-- Week Buttons View -->

        <button mat-raised-button color="primary" *ngSwitchCase="'week'" mwlCalendarPreviousView [view]="view"
            [(viewDate)]="viewDate" [excludeDays]="excludeDays"> Saptamana Anterioara </button>

        <button mat-raised-button color="primary" *ngSwitchCase="'week'" mwlCalendarToday [(viewDate)]="viewDate">
            Saptamana Curenta </button>

        <button mat-raised-button color="primary" *ngSwitchCase="'week'" mwlCalendarNextView [view]="view"
            [(viewDate)]="viewDate" [excludeDays]="excludeDays"> Saptamana Urmatoare </button>

        <!--End of Week Buttons View -->

        <!-- Day Buttons View -->

        <button mat-raised-button color="primary" *ngSwitchCase="'day'" mwlCalendarPreviousView [view]="view"
            [(viewDate)]="viewDate" [excludeDays]="excludeDays"> Ziua Anterioara </button>

        <button mat-raised-button color="primary" *ngSwitchCase="'day'" mwlCalendarToday [(viewDate)]="viewDate"> Ziua
            Curenta </button>

        <button mat-raised-button color="primary" *ngSwitchCase="'day'" mwlCalendarNextView [view]="view"
            [(viewDate)]="viewDate" [excludeDays]="excludeDays"> Ziua Urmatoare </button>

        <mwl-calendar-month-view *ngSwitchCase="'month'" [refresh]="refresh" [locale]="locale" [refresh]="refresh" [viewDate]="viewDate" [events]="events" [weekStartsOn]="weekStartsOn" [excludeDays]="excludeDays"
             (beforeViewRender)="beforeMonthViewRender($event)" (eventClicked)="eventClicked($event)">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events"
          [locale]="locale"
          [refresh]="refresh"
          [weekStartsOn]="weekStartsOn"
          [excludeDays]="excludeDays"
          [dayStartHour]="startHourProgram"
          [dayStartMinute] = "startMinuteProgram"
          (hourSegmentClicked)="clickedtime($event.date)"
          [dayEndHour]="endHourProgram"
          [dayEndMinute]="endMinuteProgram"
          [hourSegments]="hsegments"
          (beforeViewRender)="beforeWeekViewRender($event)"
          (eventClicked)="eventClicked($event)"
          [hourSegmentTemplate]="customHTemplate"
          >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view *ngSwitchCase="'day'" [refresh]="refresh" [viewDate]="viewDate" [events]="events" [locale]="locale" [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour" [hourSegments]="hsegments"
            (beforeViewRender)="beforeDayViewRender($event)" (eventClicked)="eventClicked($event)">
        </mwl-calendar-day-view>
    </div>
