import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { logo_url_base64 } from '../../../models/logo-base64.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Pacient, SemnaturaRLCreate } from '../../../models/pacientdetails.model';
import { Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FileService } from '../../../services/file.service';
import { EvalserviceService } from 'src/app/services/evalservice.service';
import { ConsultatiiService } from 'src/app/services/consultatii.service';
import { ChestionarAlergiiInterface } from 'src/app/interfaces/documente.interface';
import { ChestionarAlergii } from 'src/app/models/documente.models';
import { PinentryComponent } from 'src/app/pinentry/pinentry.component';
import { ConsultCreate } from 'src/app/models/consult.model';
import { ConsultCreateInterface } from 'src/app/interfaces/consult.interface';
import { base_img_64 } from 'src/app/models/base-img64.model';
import { PdfpreviewerComponent } from 'src/app/pdfpreviewer/pdfpreviewer.component';
import { FileElementCreate } from 'src/app/file-manager/model/element';
import { FileElementCls } from 'src/app/models/fisier.model';
import { SemnaturiRLService } from 'src/app/services/semnaturirl.service';
import { SemnaturiTerapeutiFilesService } from 'src/app/services/semnaturiterapeutifiles.service';
import { SemnaturaRLCreateInterface } from 'src/app/interfaces/pacient.interface';
@Component({
  selector: 'app-chestionar-de-alergii',
  templateUrl: './chestionar-de-alergii.component.html',
  styleUrls: ['./chestionar-de-alergii.component.css']
})
export class ChestionarDeAlergiiComponent implements OnInit {
  @Output () fileid = new EventEmitter<string>();
  idFisier : any = null
  caz : number = 1
  semnatura_RL : any = null
  semnatura_Terapeut : any = null
  chestionar_alergii: ChestionarAlergiiInterface = new ChestionarAlergii()
   pacient = new Pacient()
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<ChestionarDeAlergiiComponent>,
    public evaluarefieldsService: EvalserviceService ,
    public dialog: MatDialog,
    public semnaturirlService: SemnaturiRLService,
    public semnaturiTerapeutFilesService: SemnaturiTerapeutiFilesService,
    public consultatiiService: ConsultatiiService,
    public fileService: FileService
    ) {
    if (data) {
      if(data.caz == 1){
        console.log("Chestionar ALERGII NEW ==>> DATA ==>> ",data )
        this.caz = 1
        this.pacient = data.beneficiar
        this.getInitialFieldsFromServer()
      }
      else if( data.caz == 2){
        console.log("Chestionar ALERGII EDIT ==>> DATA ==>> ",data )
        this.caz = 2
        this.idFisier = data.fieldsData.idFisier
        this.getSavedDataFromServer(data.fieldsData.idFisier)
      }

      }
   }
   getSavedDataFromServer(idFisier){
    console.log( "GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", idFisier)
    this.consultatiiService.getConsultDataFromServerByPFisierID(idFisier)
    .subscribe( (data) => {
      if (data){
        console.log( "DATA FROM GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", data)
        this.chestionar_alergii = data.dataJSON
      }
    })
  }
   initDataCazEvaluareNoua(beneficiar){
     this.chestionar_alergii.pacientid = beneficiar.id
     if(beneficiar.date_personale.prenume){
      this.chestionar_alergii.prenume_pacient = beneficiar.date_personale.prenume
      }
      if(beneficiar.date_personale.nume){
        this.chestionar_alergii.nume_pacient = beneficiar.date_personale.nume
        }
   }
   getInitialFieldsFromServer(){
    this.evaluarefieldsService.getDocFieldsFromServerByID(33)
    .subscribe ( (data) => {
      if (data){
        console.log("DATA FROM EVALUAREA FUNCTIEI MOTORII GROSIERE -->> Initial Props ==>> ", data)
        // this.originalData = data
        this.chestionar_alergii = data.props
        this.initDataCazEvaluareNoua(this.pacient)

      }
    })
   }
  ngOnInit(): void {
    if (this.caz == 2){
      this.semnaturirlService.getSemnaturaRLfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA RL FROM SERVER ==>> ", data)
          this.semnatura_RL = data.semnatura
        }
      })
      this.semnaturiTerapeutFilesService.getSemnaturaTerapeutFilesfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA TERAPEUT FROM SERVER ==>> ", data)
          this.semnatura_Terapeut = data.semnatura
        }
      })
    }

  // FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI

  // let intermediar : ChestionarAlergiiInterface = new ChestionarAlergii()
  // let testone = {
  //   "denumire" : "Chestionar Alergii",
  //   "props" : intermediar
  // }
  // console.log("TEST ONE ==>> ", testone)
  // this.evaluarefieldsService.addToServer(testone )
  // .subscribe( (data) =>{
  //   if (data){
  //     console.log("Data from add sectiunea A to server ==>> ", data)
  //   }
  //   else{
  //     console.log("NO !!! Data from add sectiunea A to server ==>> ")
  //   }
  // })

  // END FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI

  }

  checkboxchecked(variabila, valoare){
    variabila = valoare
  }

  semneazaRL(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_RL = res
        console.log("Semnatura RL IMAGINE ESTE ==>> ",this.semnatura_RL)
      }
    })
  }
  semneazaTerapeut(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_Terapeut = res
        console.log("Semnatura Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
      }
    })
  }
  addConsultToServer(idFisier){
    let intermediar_consult : ConsultCreateInterface = new ConsultCreate()
    intermediar_consult.idPacient = this.pacient.id
    intermediar_consult.idFisier = idFisier
    intermediar_consult.dataAdaugare = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
    intermediar_consult.idEvaluare = 33
    intermediar_consult.denumire = "Chestionar Alergii"
    intermediar_consult.dataJSON = this.returnObjectForAddConsult()
    console.log(" ADD CONSULT TO SERVER ==>> ", intermediar_consult)
    this.consultatiiService.addConsultToServer(intermediar_consult)
    .subscribe( (data) => {
      if (data){
        console.log("DATA DIN ADD CONSULT TO SERVER ==>> ", data)
      }
      let semnatura_noua_RL : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
      semnatura_noua_RL.idFisier = idFisier
      semnatura_noua_RL.semnatura = this.semnatura_RL
      this.semnaturirlService.addSemnaturaRLToServer(semnatura_noua_RL)
      .subscribe ( (data) => {
        if (data){
          console.log ("DATA DIN ADD SEMNATURA RL TO SERVER ==>> ", data)
        }
      })
      let semnatura_noua_TerapeutFiles : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
      semnatura_noua_TerapeutFiles.idFisier = idFisier
      semnatura_noua_TerapeutFiles.semnatura = this.semnatura_Terapeut
      this.semnaturiTerapeutFilesService.addSemnaturaTerapeutFilesToServer(semnatura_noua_TerapeutFiles)
      .subscribe ( (data) => {
        if (data){
          console.log ("DATA DIN ADD SEMNATURA TERAPEUT FILES TO SERVER ==>> ", data)
        }
      })
    })
  }

  returnObjectForAddConsult(){
    let intermediar = {
      "dataJSON" : this.chestionar_alergii
    }
    return intermediar
  }

  returnDashIfEmpty(variabila){
    if (variabila === ""){
      return "-"
    }
    return variabila
  }
  openPDFPreviewSsaveModificari(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      // console.log("data din pdfgenerator base 64 ==>> ",data);
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
      dialogRef.afterClosed().subscribe( async res => {
        if (res){
                    // this.semnatura_Terapeut = res
            // console.log("PDF PREVIEW FROM SAVE MODIFICARI RESULT AFTER CLOSE ==>> ",res)
            let update_file  = {
              'id' : this.idFisier,
              'dateadden' : this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm'),
              'path' : "data:application/pdf;base64,"+data,
              'name' : "Chestionar Alergii - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
            }
            this.fileService.updateFileToServer(update_file)
            .subscribe ( (data) => {
              // console.log ( "DATA FROM UPDATE FILE FROM SERVER ==> ", data)
            })
            this.consultatiiService.updateConsultToServerByFisierID(this.idFisier,{'dataJSON' : this.returnObjectForAddConsult()})
            .subscribe( (data) => {
              // console.log ("DATA FROM ADD CONSULT TO SERVICE ==>> ", data)
            })
            this.dialogRef.close(1)
        }
      })
    });
  }
  openPDFPreviewComponent(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      // console.log("data din pdfgenerator base 64 ==>> ",data);
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
      dialogRef.afterClosed().subscribe( async res => {
        if (res){
          let fisier_to_upload : FileElementCreate = new FileElementCls()
          fisier_to_upload.id = this.fileService.generateFileID();
          fisier_to_upload.idPacient = this.pacient.id
          fisier_to_upload.isFolder = false
          fisier_to_upload.parent = 'root'
          fisier_to_upload.dateadden = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
          fisier_to_upload.type = "application/pdf"
          fisier_to_upload.extension = "pdf"
          fisier_to_upload.path = "data:application/pdf;base64,"+data
          fisier_to_upload.status = 'neverificat';
          fisier_to_upload.name = "Chestionar Alergii - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
          let intermediar_from_server = await this.fileService.addFileToServerReturnFileID(fisier_to_upload).toPromise()
          this.addConsultToServer(intermediar_from_server)
          this.dialogRef.close(0)
        }
      })

    });
  }

  generatePDF(action = 'open') {
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
        height: 60,
        columns:
          [
            // {
            //   image: logo_url_base64,
            //   width: 50,
            //   alignment: 'center',
            // },
            {
              stack: [
                "Fundația Mihai Neșu Foundation",
                "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
              ],
              margin: 10,
              style: 'textheader'
            }
          ],
        margin: [0, 0, 0, 90]
      },
      content: [
        {
          canvas: [
            {
              type: 'line',
              x1: -100, y1: 0,
              x2: 600, y2: 0,
              lineWidth: 1,
              lineCap: 'square'
            },
            {
              type: 'line',
              x1: -100, y1: 4,
              x2: 600, y2: 4,
              lineWidth: 1,
              lineCap: 'square'
            }
          ]
        },
        {
          image: logo_url_base64,
          width: 200,
          alignment: 'center',
        },
        {
          text: "CHESTIONAR DE ALERGII",
          bold: true,
          alignment: 'center',
          fontSize: 18,
          margin: [0, 50, 0, 80]
        },
        {
          text: "Nume " + this.chestionar_alergii.nume_pacient + " "+ this.chestionar_alergii.prenume_pacient,
          bold: true,
          alignment: 'left',
          fontSize: 14,
          margin: [0, 0, 0, 20]
        },

        {
          text: "1.	Are copilul dvs istoric de alergii?  R: " + ((this.chestionar_alergii.istoric_de_alergii)?'DA':'NU'),
          bold: true,
          alignment: 'left',
          fontSize: 14,
          margin: [0, 0, 0, 10]
        },

        {
          text: "2.	Are copilul dvs astm, febra fânului, eczemă, sau erupții cutanate? Dacă da, bifați și comentați. R: " + ((this.chestionar_alergii.astm_febra_fanului)?'DA':'NU'),
          bold: true,
          alignment: 'left',
          fontSize: 14,
          margin: [0, 0, 0, 10]
        },
        {
          text: "3. Are copilul dvs sensibilitate la alimente?  R: " + ((this.chestionar_alergii.sensibilitate_la_alimente)?'DA':'NU'),
          bold: true,
          alignment: 'left',
          fontSize: 14,
          margin: [0, 0, 0, 10]
        },
        {
          text: "4. A avut copilul dvs. vreodată prurit, urticarie, umflături sau simptome ca nas înfundat, respirație șuierătoare, iritația ochilor sau dificultăți respiratorii după: ",
          bold: true,
          alignment: 'left',
          fontSize: 14,
          margin: [0, 0, 0, 10]
        },
        {
          text: "a. examen și proceduri dentare  R: " + ((this.chestionar_alergii.intrebarea_6.proceduri_dentare)?'DA':'NU'),
          bold: true,
          alignment: 'left',
          fontSize: 14,
          margin: [0, 0, 0, 10]
        },

        {
          text: "b. contactul cu baloane R: " + ((this.chestionar_alergii.intrebarea_6.contactul_cu_baloane)?'DA':'NU'),
          bold: true,
          alignment: 'left',
          fontSize: 14,
          margin: [0, 0, 0, 10]
        },
        {
          text: "c. o examinare de către cineva care purta mănuși R: " + ((this.chestionar_alergii.intrebarea_6.examinare_cu_manusi)?'DA':'NU') ,
          bold: true,
          alignment: 'left',
          fontSize: 14,
          margin: [0, 0, 0, 10]
        },
        {
          columns:[
            {
              stack: [
                {
                  text: "Terapeut: " ,
                  bold: true,
                  alignment: 'left',
                  fontSize: 12,
                  margin: [0, 0, 0, 5]
                },
                {
                  image: this.semnatura_Terapeut ? this.semnatura_Terapeut : base_img_64,
                  alignment: 'left',
                  fit: [50, 50],
                  margin: [0, 0, 0, 20]
                }
              ]
            },
            {
              stack: [
                {
                  text: "Luat la cunoștință de părinte, ",
                  bold: true,
                  alignment: 'right',
                  fontSize: 12,
                  margin: [0, 0, 0, 5]
                },
                {
                  image: this.semnatura_RL ? this.semnatura_RL : base_img_64,
                  alignment: 'right',
                  width: 30,
                  margin: [0, 0, 0, 20]
                }
              ]
            }

          ]

        },

      ],
      styles: {
        textheader: {
          alignment: 'center',
          bold: true,
          fontSize: 14,
          // lineHeight: 2
        }
      }
    };
    return docDefinition
  }
}
