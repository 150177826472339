<!-- <mat-form-field>
    <input matInput [(ngModel)]="newservice.cod" placeholder="Cod Serviciu Nou">
 </mat-form-field> -->
<mat-form-field >
    <input matInput [(ngModel)]="newservice.denumire" placeholder="Nume Serviciu Nou">
 </mat-form-field>
<!-- <br> -->
 <mat-form-field style="padding-left: 20px;">
     <mat-label>Categorie</mat-label>
     <mat-select [(value)] = newservice.tipServiciu>
         <mat-option *ngFor="let cat of tipuriDeServicii" [value]="cat">
             {{cat.denumire}}
         </mat-option>
     </mat-select>
 </mat-form-field>
<!-- <br> -->
<mat-form-field style="padding-left: 20px;">
    <mat-label>Specialitate</mat-label>
    <mat-select [(value)] = newservice.specialitate >
        <mat-option *ngFor="let specialitateMedic of specialitatiMedici" [value]="specialitateMedic">
            {{specialitateMedic.denumire}}
        </mat-option>
    </mat-select>
</mat-form-field>
<!-- <br> -->
<mat-form-field >
    <mat-label>Durata</mat-label>
    <input matInput type="number" [(ngModel)]= "newservice.timp">
    <span matSuffix>minute</span>
  </mat-form-field>
  <!-- <br> -->
  <!-- <br> -->
<mat-form-field style="padding-left: 20px;">
    <mat-label>Pret</mat-label>
    <input matInput type="number" [(ngModel)]= "newservice.pret">
    <span matSuffix>RON  </span>
  </mat-form-field>
  <!-- <br> -->
  <mat-checkbox style="padding-left: 20px; padding-right: 20px;" [(ngModel)]="newservice.tva"> Discount: </mat-checkbox>
  <!-- <br> -->
  <mat-form-field>
    <input matInput type="number" [disabled]="!newservice.tva" [(ngModel)]= "newservice.valoare_tva">
    <span matSuffix>%</span>
  </mat-form-field>
  <!-- <br> -->

 <button mat-raised-button (click)="AddServiciuToServer()" style="padding-left: 20px;" [disabled] = "!newservice.denumire || !newservice.timp"> Adauga</button>

 <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width:100%">

    <!-- ID Column -->
    <ng-container matColumnDef="nume">
    <th mat-header-cell *matHeaderCellDef> Nume </th>
    <td mat-cell *matCellDef="let element"> {{element.denumire}} </td>
    </ng-container>

     <!-- ID Column -->
     <ng-container matColumnDef="categorie">
     <th mat-header-cell *matHeaderCellDef> Categorie </th>
     <!-- <td mat-cell *matCellDef="let element"> {{findCategorieServiciu(element.tipserviciuid).denumire}} </td> -->
     <td mat-cell *matCellDef="let element"> {{findCategorieServiciuTwo(element)}} </td>
     </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="specialitate">
    <th mat-header-cell *matHeaderCellDef> Specialitate </th>
    <td mat-cell *matCellDef="let element"> {{findDenumireSpecialitateMedicTwo(element)}} </td>
    </ng-container>

          <!-- ID Column -->
    <ng-container matColumnDef="cuanta">
    <th mat-header-cell *matHeaderCellDef> Durata </th>
    <td mat-cell *matCellDef="let element"> {{element.timp}} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>
        <!-- <button  mat-raised-button color="primary" (click)="sendvalues()" [disabled]="!date_personale.nume || !date_personale.prenume" mat-dialog-close>Ok</button> -->
      </th>
      <td mat-cell *matCellDef="let element">

        <button mat-raised-button class="save-button" (click)="openServiciiEdit(element)">edit</button>

      </td>
    </ng-container>

    <ng-container matColumnDef="deleteselect">
      <th mat-header-cell *matHeaderCellDef>
        <button  mat-raised-button color="warn" [disabled]="todelete.length == 0" (click)="deleteitems()" > Sterge </button>
      </th>
      <td mat-cell *matCellDef="let element">

        <mat-checkbox (change)="marktodelete(element.id)"></mat-checkbox>

      </td>
    </ng-container>


     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
     <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

 </table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
<div mat-dialog-actions align='center'>
  <button mat-raised-button color="warn" mat-dialog-close >Inchide</button>
</div>
