<mat-toolbar>
  <mat-icon *ngIf="canNavigateUp" class="pointer" (click)="navigateUp()">arrow_back</mat-icon>
  <span style="margin-left: 8px">{{path != 'root' ? path : 'Fisiere'}}</span>
  <span class="spacer"></span>
  <mat-icon class="pointer" (click)="openNewFolderDialog()">create_new_folder</mat-icon>
</mat-toolbar>

<div class="container" fxFlex fxLayout="row" fxLayoutAlign="space-around stretch" #listafisiere>
  <div class="content" fxFlex fxLayout="row">
    <mat-grid-list cols="{{coloaneGrid}}" rowHeight="100px" fxFlex>
      <mat-grid-tile *ngFor="let element of fileElements" class=file-or-folder>
        <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{element: element}"
          #menuTrigger="matMenuTrigger"></span>
        <div fxLayout="column" fxFlexFill fxLayoutAlign="center center" (click)="navigate(element)" class = "manage-fisier-alone"
          (contextmenu)="openMenu($event,element, menuTrigger)" (mouseover)='over(element)' (mouseout)='out(element)'>
          <mat-icon color="warm" class="file-or-folder-icon pointer" *ngIf="element.isFolder && (element.status === 'neverificat') ">folder</mat-icon>
          <mat-icon color="accent" class="file-or-folder-icon pointer" *ngIf="element.isFolder && (element.status === 'respins') ">folder</mat-icon>
          <mat-icon color="primary" class="file-or-folder-icon pointer" *ngIf="element.isFolder && (element.status === 'aprobat') ">folder</mat-icon>
          
          <mat-icon color="warm" class="file-or-folder-icon pointer" *ngIf="!element.isFolder && (element.status === 'neverificat')">insert_drive_file</mat-icon>
          <mat-icon color="accent" class="file-or-folder-icon pointer" *ngIf="!element.isFolder && (element.status === 'respins') ">insert_drive_file</mat-icon>
          <mat-icon color="primary" class="file-or-folder-icon pointer" *ngIf="!element.isFolder && (element.status === 'aprobat') ">insert_drive_file</mat-icon>
          <span class = "file-name">{{element.name}}</span>
        </div> 
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>


<mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
  <ng-template matMenuContent let-element="element">
    <button mat-menu-item [matMenuTriggerFor]="moveToMenu" [matMenuTriggerData]="{self: element}">
      <mat-icon>open_with</mat-icon>
      <span>Muta in Folder</span>
    </button>
    <button mat-menu-item (click)="approveDoc(element)">
      <mat-icon>download_done</mat-icon>
      <span>Aproba</span>
    </button>
    <button mat-menu-item (click)="rejectDoc(element)">
      <mat-icon>cancel</mat-icon>
      <span>Respinge</span>
    </button>
    <button mat-menu-item (click)="openRenameDialog(element)">
      <mat-icon>edit</mat-icon>
      <span>Redenumeste</span>
    </button>
    <button mat-menu-item (click)="deleteElement(element)">
      <mat-icon>delete</mat-icon>
      <span>Sterge</span>
    </button>
    <button mat-menu-item (click)="downloadingfile(element)" *ngIf="!element.isFolder">
      <mat-icon>save</mat-icon >
      <!-- <span><a href={{element.path.changingThisBreaksApplicationSecurity}} download={{element.name}}>Descarca fisierul </a></span> -->
      <span>Descarca fisierul </span>
    </button>
    <!-- <button mat-menu-item>
      <mat-icon>save</mat-icon>
      <span><a href={{element.path}} download={{element.name}}>Preview</a></span>
    </button> -->
  </ng-template>
</mat-menu>

<mat-menu #moveToMenu="matMenu">
  <ng-template matMenuContent let-self="self">
    <button  *ngIf="checkSubFoldere(self)" mat-menu-item [matMenuTriggerFor]="moveToMenuBelow" [matMenuTriggerData]="{self: self}">Sub-Foldere</button>
    <button *ngIf="suprafolders" mat-menu-item [matMenuTriggerFor]="moveToMenuAbove" [matMenuTriggerData]="{self: self}">Supra-Foldere</button>
  </ng-template>
</mat-menu>

<mat-menu #moveToMenuBelow="matMenu">
  <ng-template matMenuContent let-self="self">
    <ng-container *ngFor="let element of fileElements">
      <button *ngIf="element.isFolder && element.id !== self.id" mat-menu-item  
        (click)="moveElement(self, element)">{{element.name}}</button>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #moveToMenuAbove="matMenu">
  <ng-template matMenuContent let-self="self" >
    <ng-container *ngIf="!index_suprafolder">
      <ng-container *ngIf="getSupraFolderList(self) ">
      </ng-container>
    </ng-container>
    <ng-container *ngIf="suprafolders">
      <ng-container *ngFor="let element of foldereabove" >
        <button *ngIf="element.isFolder && element.id !== self.parent" mat-menu-item
        (click)="moveElement(self, element)">{{element.name}}</button>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>

<!-- <mat-menu #moveToMenu="matMenu">
  <ng-template matMenuContent let-self="self">
    <ng-container *ngFor="let element of fileElements">
      <button *ngIf="element.isFolder && element.id !== self.id" mat-menu-item
        (click)="moveElement(self, element)">{{element.name}}</button>
    </ng-container>
  </ng-template>
</mat-menu> -->