import { Component, OnInit } from '@angular/core';
import { logo_url_base64 } from 'src/app/models/logo-base64.model';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-contract-inchiriere',
  templateUrl: './contract-inchiriere.component.html',
  styleUrls: ['./contract-inchiriere.component.css']
})
export class ContractInchiriereComponent implements OnInit {

  contract_inchiriere:{
    titlu:string,
    numar_contract:string,
    data_contract:string,
    nume_beneficiar:string,
    prenume_beneficiar:string,
    domiciliu_beneficiar:string,
    serie_ci_beneficiar:string,
    nr_ci_beneficiar:string,
    eliberator_ci_beneficiar:string,
    data_eliberare_ci_beneficiar:string,
    data_start_inchiriere:string,
    data_end_inchiriere:string,
    despagubiri:string,
    data_finala_predare:string
  }={
    titlu:"",
    numar_contract:"",
    data_contract:"",
    nume_beneficiar:"",
    prenume_beneficiar:"",
    domiciliu_beneficiar:"",
    serie_ci_beneficiar:"",
    nr_ci_beneficiar:"",
    eliberator_ci_beneficiar:"",
    data_eliberare_ci_beneficiar:"",
    data_start_inchiriere:"",
    data_end_inchiriere:"",
    despagubiri:"",
    data_finala_predare:""
  }

  constructor() { }

  ngOnInit(): void {
  }
  generatePDF(action = 'open') {
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
      height: 60,
      columns:
        [
          // {
          //   image: logo_url_base64,
          //   width: 50,
          //   alignment: 'center',
          // },
          {
            stack: [
              "Fundația Mihai Neșu Foundation",
              "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
            ],
            margin: 10,
            style: 'textheader'
          }
        ],
      margin: [0, 0, 0, 90]
    },
    content: [
      {
        canvas: [
          {
            type: 'line',
            x1: -100, y1: 0,
            x2: 600, y2: 0,
            lineWidth: 1,
            lineCap: 'square'
          },
          {
            type: 'line',
            x1: -100, y1: 4,
            x2: 600, y2: 4,
            lineWidth: 1,
            lineCap: 'square'
          }
        ]
      },
      {
        image: logo_url_base64,
        width: 200,
        alignment: 'center',
      },
      {
        stack: [
          "CONTRACT DE CAZARE ",
          "Nr. " + this.contract_inchiriere.numar_contract + " / " + this.contract_inchiriere.data_contract,
        ],
        bold: true,
        alignment: 'center',
        fontSize: 16,
        margin: [0, 50, 0, 80]
      },
      {
        text: " Încheiat astăzi: " +this.contract_inchiriere.data_contract,
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: " Între subsemnaţii: ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.	Fundatia Mihai Neșu cu sediul social în Strada George Emil Palade nr 55 posesor a CIF 32896090 , în calitate de proprietar-comodatar al imobilului situat la adresa Strada Lavandei, nr 6, bloc 3A, ap 106, Oradea, Bihor ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "2. " + this.contract_inchiriere.nume_beneficiar + " " + this.contract_inchiriere.prenume_beneficiar + " domiciliat(ă) în " + this.contract_inchiriere.domiciliu_beneficiar + " posesor a B.I. / C.I. seria: "+ this.contract_inchiriere.serie_ci_beneficiar + " nr. "+this.contract_inchiriere.nr_ci_beneficiar + " eliberat de " + this.contract_inchiriere.eliberator_ci_beneficiar + " la data de: " + this.contract_inchiriere.data_eliberare_ci_beneficiar + " în calitate de beneficiar." ,
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Primul în calitate de proprietar închiriez, iar al doilea în calitate de beneficiar iau în chirie imobilul situat la adresa Strada Lavandei, nr 6, bloc 3A, ap 106, Oradea, Bihor compus din 2 dormitoare, un living comun cu bucataria, 2 bai, un balcon, mobilate conform listei de inventar ce se va întocmi de către părţi la data intrării în imobil",        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"1. Termenul de închiriere este de la data de " + this.contract_inchiriere.data_start_inchiriere + "până la data de " + this.contract_inchiriere.data_end_inchiriere + ".",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"2.Contributia parintilor pentru cazare este de 50 lei/noapte . Plata se face în numerar pentru fiecare saptamana in parte rezervata, la sediul fundatiei. La cererea parintilor plata contributiei se poate face si integral",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"3. Încetarea contractului se face :",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	la împlinirea termenului prevăzut la art. 1,",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	prin acordul ambelor părţi înainte de termen,",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	prin denunțare unilaterală cu un preaviz de 30 zile calendaristice,",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	prin reziliere în caz de nerespectare a clauzelor contractuale, fără alte formalități și fără trecerea vreunui termen.",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"În situaţia în care oricare din părţi nu respectă aceste condiţii va plăti celeilalte părţi despăgubiri în valoare de " + this.contract_inchiriere.despagubiri + " RON" ,
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"6. OBLIGAŢIILE BENEFICIARULUI:",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"- beneficiarul se obligă să folosească bunul închiriat conform destinaţiei sale, să nu tulbure liniştea proprietăţilor vecine prin folosinţa sa,",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"- va preda imobilul la finalul perioadei de locaţiune în condiţiile iniţiale preluării lui",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"- nu va subînchiria imobilul",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"- să respecte normele de prevenire a incendiilor şi să întreţină bunurile în folosinţă exclusive (instalaţii de apă, gaz metan, mobilier)",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"- să păstreze curăţenia şi să respecte normele de igienă în interiorul imobilului",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	la finalul perioadei de cazare beneficiarul va pune toate lenjeriile murdare intr-un sac de gunoi in mijlocul livingului, pentru a fi duse ulterior la curatatorie de personalul fundatiei.",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"- să restituie imobilul la data expirării contractului sau la încetarea acestuia înainte de termen în condiţiile prezentului contract precum si cheile imobilului",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"- să despăgubească proprietarul de eventualele daune produse imobilului sau bunurilor din interiorul acestuia din folosinţa sa",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	sa nu instraineze obiectele din imobil, sa le foloseasca exclusiv in interior si sa anunte degradarea lor naturala daca este cazul",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	 sa utilizeze aparatele din cadrul imobilului conform manualelor de instructiune sau sa contacteze reprezentantii fundatiei pentru instructiuni suplimentare",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"- să nu schimbe destinaţia imobilului",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	Sa anunte proprietarul imediat ce se afla in imposibilitatea de a mai onora cazarea, acest lucru fiind acceptat doar in cazul in care copilul nu mai poate participa la terapia Therasuit pentru care are rezervare",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	Chiriasul va intelege ca aceasta cazare este strict legata de participarea la terapia Therasuit, scopul cazarii fiind ca Fundatia Mihai Nesu sa faciliteze participarea copiilor la terapie cu costuri mai reduse",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	Beneficiarul va intelege ca Fundatia Mihai Nesu va acorda intotdeauna prioritate de inchiriere a imobilului beneficiarilor care au o conditie financiara mai precara din lista beneficiarilor care depun cerere de cazare in aceeasi perioada. Capacitatea de cazare a imobilului este de aproximativ ½, din numarul sesiunilor disponibile intr-un an cu 8 sesiuni simultan, pentru beneficiari din afara orasului. Desi prioritatea pentru ordinea cazarii este a familiilor cu conditie financiara mai redusa din numarul de cereri, toti cei care fac Therasuit si sunt din alt oras au posibilitatea de a solicita cazare pentru ½ din numarul lor de sedinte programate intr-un an, astfel incat toti participantii sa poata participa. Daca intr-un an sunt mai putini participanti din afara orasului, procentul de ocupare a cazarii creste pentru fiecare caz.",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	Beneficiarul va intelege ca pentru eficientizarea costurilor si a cazurilor, apartamentul se va inchiria cu prioritate pe o perioada de 3 saptamani, durata unei sesiuni Therasuit. In cazul in care intr-o sesiune Therasuit nu sunt 2 solicitari de cazare pentru 3 saptamani, atunci Fundatia Mihai Nesu poate sa inchirieze si pentru o perioada mai scurta, pentru alt beneficiar al terapiei Therasuit. ",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	beneficiarul se obliga sa respecte ora intalnirii stabilita cu reprezentantul Fundatiei Mihai Nesu pentru preluarea si predarea apartamentului",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	beneficiarul se obliga sa anunte orice neregula legata de apartament sau de ceilalti chiriasi care poate pune in pericol buna intretinere a apartamentului, poate dauna calitatii sederii in apartament sau poate pune in pericol sanatatea sau viata beneficiarilor cazarii",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	Beneficiarul va intelege ca Fundatia Mihai Nesu va caza maxim 4 persoane in apartament, echialentul a 2 adulti cu 2 copii. Fundatia Mihai Nesu conditioneaza ca in cazarea simultana (in aceeasi sesiune) cei 2 adulti sa fie de acelasi sex (2 mamici/2 tatici) pentru a nu exista situatii de disconfort sau alte neplaceri. Daca prin exceptie un tatic e nevoit sa vina cu masina sa aduca mama cu copilul la terapii, acesta poate solicita posibilitatea de a se caza pentru maxim o noapte, dar va fi constient ca acest lucru se va aproba doar daca celalalt colocatar din alta familie se simte confortabil cu acest aspect",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	Pentru urgente care nu tin de partea adminstrativa (incendii, jafuri sau alte probleme grave, probleme medicale grave) beneficiarul va suna la 112 si va anunta conducerea fundatiei",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"-	Pentru efectuarea curateniei pentru noii beneficiari, apartamentul va fi predat intotdeauna fie in ziua de vineri, dupa finalizarea terapiei Therasuit, fie sambata, cel tarziu la ora 10.00 dimineata (urmatoarea zi dupa finalizarea terapiei Therasuit) .",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"7. OBLIGAŢIILE PROPRIETARULUI:",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"- proprietarul se obligă să predea imobilul la data stabilită în contract în stare de folosinţă",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"- proprietarul se obligă să asigure beneficiarul imobilul potrivit destinaţiei pentru care a fost închiriat,",
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text:"Predarea imobilului către beneficiar se va face cel mai târziu la data de: " + this.contract_inchiriere.data_finala_predare,
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
    ],styles: {
      textheader: {
        alignment: 'center',
        bold: true,
        fontSize: 14,
        // lineHeight: 2
      }
    }
  }
  if (action === 'download') {
    pdfMake.createPdf(docDefinition).download();
  } else if (action === 'print') {
    pdfMake.createPdf(docDefinition).print();
  } else {
    pdfMake.createPdf(docDefinition).open();
  }
  }

}
