import { Component, Inject, OnInit } from '@angular/core';
import { ContractFurnizorServiciiSocialeComponent } from '../templatedocumente/componente-contracte/contract-furnizor-servicii-sociale/contract-furnizor-servicii-sociale.component';
import { ContractInchiriereComponent } from '../templatedocumente/componente-contracte/contract-inchiriere/contract-inchiriere.component';
import { ContractMasinaComponent } from '../templatedocumente/componente-contracte/contract-masina/contract-masina.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pacient } from 'src/app/models/pacientdetails.model';

@Component({
  selector: 'app-contracte-pacient',
  templateUrl: './contracte-pacient.component.html',
  styleUrls: ['./contracte-pacient.component.css']
})
export class ContractePacientComponent implements OnInit {
  pacient = new Pacient();

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<ContractePacientComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    if (data){
      this.pacient = data
    }
   }

  ngOnInit(): void {
  }

  openContractFurnizorServiciiSocialeComponent(){
    const dialogRef = this.dialog.open(ContractFurnizorServiciiSocialeComponent, {
      width: '80%',
      height: '100%',
      data: {
        'caz' : 1,
        'beneficiar' : this.pacient
      }
      });
    // this.openSnackBar()
  }
  openContractMasinaComponent(){
    const dialogRef = this.dialog.open(ContractMasinaComponent, {
      width: '80%',
      height: '100%',
      data:{
        'caz' : 1,
        'beneficiar' : this.pacient
      }
      });
    // this.openSnackBar()
  }
  openContractInchiriereComponent(){
    const dialogRef = this.dialog.open(ContractInchiriereComponent, {
      width: '80%',
      height: '100%',
      data: this.pacient
      });
    // this.openSnackBar()
  }

}
