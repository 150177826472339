import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NewpropertydiagComponent } from './newpropertydiag/newpropertydiag.component';
import { DynamicFormComponent } from 'src/app/components/dynamic-form/dynamic-form.component';
import { FieldConfig, Validator } from 'src/app/interfaces/field.interface';
import { Validators } from '@angular/forms';
import { dynamic_menu, dynamic_menu_items } from 'src/app/interfaces/dynamic-menu.interface';
import { ChangeDetectionStrategy } from '@angular/core';
import { NompropsService } from 'src/app/services/nomprops.service';
import { filter } from 'rxjs/operators';
export interface Pacient{

  nume: string;
  prenume: string;
  diagnostic: string;
  email:string;
  telefon:string;
}


const FIELD_NUME_CONFIG: FieldConfig[] = [
  <FieldConfig>{
    type: "input",
    label: "Nume",
    inputType: "text",
    name: "Nume",
    validations: <Validator[]>
    [
      <Validator>{
        name: "required",
        validator: Validators.required,
        message: "Camp obligatoriu"
      }
    ]
   }
  ]

@Component({
  selector: 'app-newnomenclatordiag',
  templateUrl: './newnomenclatordiag.component.html',
  styleUrls: ['./newnomenclatordiag.component.css']
})
export class NewnomenclatordiagComponent implements OnInit {

  propertyname: string 
  ctype:dynamic_menu = {
    menu_button_name: "Adauga proprietate",
    items: [
      <dynamic_menu_items>{
      name: "Input",
      children: [
        {
          name: "Alfanumeric"
        },
        {
          name: "Text-Only"
        },
        {
          name: "Numeric-Only",
          children:[
            {
              name:"Fara virgule"
            },
            {
              name:"Cu virgula (float)"
            },
            {
              name:"Procent cu virgula"
            },
            {
              name:"Procent fara virgula"
            }
          ]
        },
        {
          name: "Email"
        },
        {
          name:"Password"
        },
        {
          name:"Custom"
        }
      ]
      },
      <dynamic_menu_items>{
        name: "Button"
      },
      <dynamic_menu_items>{
        name: "Select"
      },
      <dynamic_menu_items>{
        name: "Date"
      },
      <dynamic_menu_items>{
        name: "Radio Button"
      },
      <dynamic_menu_items>{
        name: "Checkbox"
      },
    ]
  }
  fconfig:FieldConfig[] = [];
  
  newpropertydiagDialogRef: MatDialogRef<NewpropertydiagComponent>;

  constructor(public dialog2: MatDialog,public nompropsService:NompropsService, private dlg: MatDialog) { }

  ngOnInit(): void {
    this.nompropsService.fieldlist = {name:"smth"}
  }
  
  addvaluetoregConfig(newfield){
    console.log("newfield= ", newfield)
    this.fconfig.push(newfield);
  }

  testmodal(){
    console.log("Incercare de array: ",this.testmodalt(this.nompropsService.field_details));
    console.log("Testval4= ",this.nompropsService.fieldscomplete)
    const dialogRef5 = this.dialog2.open(NewpropertydiagComponent, {
      width: '650px',
      height: '550px',
      data: {fields: this.nompropsService.fieldscomplete, ctg: "Alfanumeric"}
    });
  }
  testmodalt(obiect){
    let arr = [];
    Object.keys(obiect).map(function(key){
      arr.push({[key]:obiect})
      console.log("arr push= ",arr);
      return arr;
    });
    console.log("arr= ",arr);
  }

  enumeratestuff(arr):void{
    for (let i of arr){
      console.log("i= ", i);
    }
  }

  openpropertymodal(selection: string): void{

    this.nompropsService.fieldlist = this.getfieldslist(selection)
    console.log("newfieldconfig= ", this.nompropsService.fieldlist);
    console.log("regconf= ", this.regConfig);


    this.newpropertydiagDialogRef = this.dlg.open(NewpropertydiagComponent, {
      width: '650px',
      height: '550px',
      data: {fields: this.nompropsService.fieldlist, ctg: selection}
    });

    this.newpropertydiagDialogRef.afterClosed().pipe(
      filter(item => item)
    ).subscribe(item =>{
      this.nompropsService.fieldscomplete.push(item)
      this.enumeratestuff(this.nompropsService.fieldscomplete);
    });

    
  }
  
  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;
  regConfig:FieldConfig[] = this.fconfig;
  adaugaproprietate(): void{
    const dialogRef = this.dialog2.open(NewpropertydiagComponent, {
      width: '650px',
      height: '550px',
      data: {}
    });
  }

  submit(value: any) {
    // this._addtotableService.createpr({tablename: this.TableName, tablevalues: value}).subscribe(
    //   data => console.log('Succes',data),
    //   error => console.log('Error',error)
    // );
    console.log(value);
  }
  getfieldslist(fieldname):FieldConfig[]{
    var standardfields:FieldConfig[] = [];
    if (fieldname == "Alfanumeric"){
      standardfields= [
        <FieldConfig>{
          type: "input",
          label: "Nume",
          inputType: "text",
          name: "Nume",
          validations: <Validator[]>
          [
            <Validator>{
              name: "required",
              validator: Validators.required,
              message: "Camp obligatoriu"
            }
          ]
          },
          <FieldConfig>{
          type: "input",
          label: "Limita de caractere",
          inputType: "number",
          name: "fieldcharlimit",
          },
          <FieldConfig>{
          type: "button",
          label: "Save"
          }                             
      ];
    }
    else {       
      if (fieldname == "Text-Only"){
        standardfields= [
          <FieldConfig>{
            type: "input",
            label: "Nume",
            inputType: "text",
            name: "Nume",
            validations: <Validator[]>
            [
              <Validator>{
                name: "required",
                validator: Validators.required,
                message: "Camp obligatoriu"
              }
            ]
          },
          <FieldConfig>{
            type: "input",
            label: "Limita de caractere",
            inputType: "number",
            name: "fieldcharimit",
          },
          <FieldConfig>{
            type: "button",
            label: "Save"
          }                             
        ];
      }   
      else {       
     
          if (fieldname == "Fara virgule"){
            standardfields= [
              <FieldConfig>{
                type: "input",
                label: "Nume",
                inputType: "text",
                name: "Nume",
                validations: <Validator[]>
                [
                  <Validator>{
                    name: "required",
                    validator: Validators.required,
                    message: "Camp obligatoriu"
                  }
                ]
              },
              <FieldConfig>{
                type: "input",
                label: "Limita de caractere",
                inputType: "number",
                name: "fieldcharimit",
              },
              <FieldConfig>{
                type: "button",
                label: "Save"
              }                             
            ];
          }
          else {       
            if (fieldname == "Cu virgula (float)"){
              standardfields= [
                <FieldConfig>{
                  type: "input",
                  label: "Nume",
                  inputType: "text",
                  name: "Nume",
                  validations: <Validator[]>
                  [
                    <Validator>{
                      name: "required",
                      validator: Validators.required,
                      message: "Camp obligatoriu"
                    }
                  ]
                },
                <FieldConfig>{
                  type: "input",
                  label: "Limita de caractere",
                  inputType: "number",
                  name: "fieldcharimit",
                },
                <FieldConfig>{
                  type: "button",
                  label: "Save"
                }                             
              ];
            }
            else {       
              if (fieldname == "Procent cu virgula"){
                standardfields= [
                  <FieldConfig>{
                    type: "input",
                    label: "Nume",
                    inputType: "text",
                    name: "Nume",
                    validations: <Validator[]>
                    [
                      <Validator>{
                        name: "required",
                        validator: Validators.required,
                        message: "Camp obligatoriu"
                      }
                    ]
                  },
                  <FieldConfig>{
                    type: "input",
                    label: "Limita de caractere",
                    inputType: "number",
                    name: "fieldcharimit",
                  },
                  <FieldConfig>{
                    type: "button",
                    label: "Save"
                  }                             
                ];
              }
              else {       
                if (fieldname == "Procent fara virgula"){
                  standardfields= [
                    <FieldConfig>{
                      type: "input",
                      label: "Nume",
                      inputType: "text",
                      name: "Nume",
                      validations: <Validator[]>
                      [
                        <Validator>{
                          name: "required",
                          validator: Validators.required,
                          message: "Camp obligatoriu"
                        }
                      ]
                    },
                    <FieldConfig>{
                      type: "input",
                      label: "Limita de caractere",
                      inputType: "number",
                      name: "fieldcharimit",
                    },
                    <FieldConfig>{
                      type: "button",
                      label: "Save"
                    }                             
                  ];
                }
                else {       
                  if (fieldname == "Email"){
                    standardfields= [
                      <FieldConfig>{
                        type: "input",
                        label: "Nume",
                        inputType: "text",
                        name: "Nume",
                        validations: <Validator[]>
                        [
                          <Validator>{
                            name: "required",
                            validator: Validators.required,
                            message: "Camp obligatoriu"
                          }
                        ]
                      },
                      <FieldConfig>{
                        type: "input",
                        label: "Limita de caractere",
                        inputType: "number",
                        name: "fieldcharimit",
                      },
                      <FieldConfig>{
                        type: "button",
                        label: "Save"
                      }                             
                    ];
                  }
                  else {       
                    if (fieldname == "Password"){
                      standardfields= [
                        <FieldConfig>{
                          type: "input",
                          label: "Nume",
                          inputType: "text",
                          name: "Nume",
                          validations: <Validator[]>
                          [
                            <Validator>{
                              name: "required",
                              validator: Validators.required,
                              message: "Camp obligatoriu"
                            }
                          ]
                        },
                        <FieldConfig>{
                          type: "input",
                          label: "Limita de caractere",
                          inputType: "number",
                          name: "fieldcharimit",
                        },
                        <FieldConfig>{
                          type: "button",
                          label: "Save"
                        }                             
                      ];
                    }
                    else {       
                      if (fieldname == "Date"){
                        standardfields= [
                          <FieldConfig>{
                            type: "input",
                            label: "Nume",
                            inputType: "text",
                            name: "Nume",
                            validations: <Validator[]>
                            [
                              <Validator>{
                                name: "required",
                                validator: Validators.required,
                                message: "Camp obligatoriu"
                              }
                            ]
                          },
                          <FieldConfig>{
                            type: "button",
                            label: "Save"
                          }                             
                        ];
                      }
                      else {       
                        if (fieldname == "Checkbox"){
                          standardfields= [
                            <FieldConfig>{
                              type: "input",
                              label: "Nume",
                              inputType: "text",
                              name: "Nume",
                              validations: <Validator[]>
                              [
                                <Validator>{
                                  name: "required",
                                  validator: Validators.required,
                                  message: "Camp obligatoriu"
                                }
                              ]
                            },
                            <FieldConfig>{
                              type: "button",
                              label: "Save"
                            }                             
                          ];
                        }
                        else {       
                          if (fieldname == "Select"){
                            standardfields= [
                              <FieldConfig>{
                                type: "input",
                                label: "Nume",
                                inputType: "text",
                                name: "Nume",
                                validations: <Validator[]>
                                [
                                  <Validator>{
                                    name: "required",
                                    validator: Validators.required,
                                    message: "Camp obligatoriu"
                                  }
                                ]
                              },
                              <FieldConfig>{
                                type: "input",
                                options: [""]
                              },
                              <FieldConfig>{
                                type: "button",
                                label: "Save"
                              }                             
                            ];
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        
      }
    }
    //let fds:FieldConfig[] = standardfields;
    //console.log("fds = ",fds);
    return standardfields;
  }

}
