import { Input, Component, EventEmitter, Output, SecurityContext } from '@angular/core';
import { NgModule } from '@angular/core';
import { BrowserModule, SafeResourceUrl } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { FuploadDirective } from './fupload.directive';
import { ProgressComponent } from './progress/progress.component'
import { FileService } from '../services/file.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { FileElement, FileElementCreate } from '../file-manager/model/element';
import { FileElementCls } from '../models/fisier.model';



@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FileuploadComponent {
  files: FileElementCreate[] = [];
  fileindex: number = -1;
  @Input() idPacient: number;
  @Input() folder: string;
  @Output () uploaded = new EventEmitter<string>();

  constructor(public fileService: FileService, public datepipe: DatePipe, private sanitizer: DomSanitizer) {
    // if (this.folder){ 
      // }
    }
    
    /**
     * on file drop handler
     */
    onFileDropped($event) {
      console.log("on droppedevent: ", $event);
      this.prepareFilesList($event);
    }
    
    /**
     * handle file from browsing
     */
    fileBrowseHandler($event) {
    // console.log (" PATH INPUT ==>> ", this.folder)
    const x = $event.target as HTMLInputElement;
    console.log("on fileBrowserHandler: => ", x.files);
    this.prepareFilesList(x.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  async uploadFileTwo (){
    for(let i of this.files){
      // console.log("Files de I ==>> ", i.id)
      i.path = this.fileService.returnFile(i.id)
      // console.log("Path de I ==>> ", i)
      let intermediar = this.getElementInterfaceFromCreate(i)
      // console.log("Path de I ==>> ", intermediar)
      let progress = 0
      let intermediar_from_server = await this.fileService.addFileToServer(intermediar).toPromise()
      console.log("Path de I ==>> ", intermediar_from_server)
      const progressInterval = setInterval(() => {
        if (i.progress === 95){
          if ( intermediar_from_server ){
            i.progress = 100;
            this.uploaded.emit(i.name);
            clearInterval(progressInterval)
          }
        }
        else {
          i.progress += 5
        }
      },10 )

    }
  }
  
  /**z
   * Simulate the upload process
   */
  async uploadFilesSimulator(index: number) {
    console.log("UPLOAD FILE ==>> ", index)
    let addendate = new Date();
    let progress = 0
    setTimeout(() => {
      if (index === this.files.length) {
        console.log("UPLOAD FILE ==>> INDEX ==>> ", index," EGAL FILES LENGTH ==>> ", this.files.length)
        return;
      } else {
        console.log("UPLOAD FILE ==>> INDEX ==>> ", index,"NU ESTE !!! EGAL FILES LENGTH ==>> ", this.files.length)
        console.log("FILES COMPLET ==>> ", this.files)
        // console.log(this.idPacient)
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            // console.log("upload completed ", this.files[index].name);
            console.log("upload completed, file details ==>> ", this.files[index]);
            this.fileService.addFileToServer(
              {
                name: this.files[index].name,
                isFolder: false,
                parent: 'root',
                idPacient: this.idPacient,
                // path: this.sanitizer.bypassSecurityTrustUrl(this.files[index].path),
                // path: this.sanitizer.sanitize(SecurityContext.URL,this.files[index].path),
                path: this.files[index].path,
                type: this.files[index].type,
                dateadden: this.datepipe.transform(addendate, 'dd.MM.YYYY, HH:mm'),
                extension: this.files[index].name.split('.').pop(),
                status: 'neverificat'
              }
            )
            .subscribe((data)=>{
              if (data){
                console.log("data from add file to server => ", data)
                this.uploaded.emit(this.files[index].name);
              }
              else{
                // console.log("no data from add file to server")
              }
            })
            this.fileService.queryInFolder('root');
            // this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 10);
      }
    }, 50);
  }

  nimicSpecial(variabila){
    console.log ("NIMIC SPECIAR ==>> ", variabila.toString())
  }

  tryReaderFunction(id,item){
    let reader = new FileReader();
    reader.readAsDataURL(item);
    reader.onload = () => {
      // this.nimicSpecial(reader.result)
      this.fileService.addfilepath(id,reader.result)
      if (this.fileService.filespaths.length-1 == this.fileindex){
        this.uploadFileTwo()
      }
      // console.log("TRY DIN FUNCT ==>> ", reader.result.toString())
    }
    // return reader.result
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  async prepareFilesList(files) {
    // console.log(" FILES FROM PREPARE FILE LIST == >> ", files)
    let addendate = new Date();
    for (const item of files) {
      item.id = this.fileService.generateFileID();
      this.tryReaderFunction(item.id,item)
      item.progress = 0;
      item.isFolder = false;
      item.idPacient = this.idPacient;
      item.parent = this.folder;
      item.dateadden = this.datepipe.transform(addendate, 'dd.MM.YYYY, HH:mm');
      item.extension = item.name.split('.').pop();
      item.status = 'neverificat';
      // console.log("Files from prepare FilesList: => ", item);
      this.files.push(item);
      this.fileindex += 1;
      this.fileService.fileindex += 1;
      // this.uploadFilesSimulator(this.fileindex);
    }
    // this.uploadFileTwo()
  }

  returnEmptyIfNull ( variabila ){
    if (typeof(variabila) === 'boolean'){
      return variabila
    }
    if (variabila){
      return variabila
    }
    return ''
  }
  getElementInterfaceFromCreate(fileCreate: FileElementCreate) : FileElement{
    let fisier_intermediar : FileElement = new FileElementCls()
    // console.log ("Keyle lui fisier intermediar == ", Object.keys(fisier_intermediar))
    for ( let i of Object.keys(fisier_intermediar)) {
      // console.log ("I == ",i," // fisier_intermediar[i] ==>> ", fisier_intermediar[i]," // fileCreate[i] ==>>  ", fileCreate[i])
      fisier_intermediar[i] = this.returnEmptyIfNull(fileCreate[i])
    }
    return fisier_intermediar
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
