import { Component,Output, EventEmitter , Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Pacient, DateContact, DatePersonale, Adresa } from '../models/pacientdetails.model';

@Component({
  selector: 'app-tabelpacienti',
  templateUrl: './tabelpacienti.component.html',
  styleUrls: ['./tabelpacienti.component.css']
})

export class TabelpacientiComponent implements OnInit {
  @Input() dataSource
  @Input() displayedColumns
  @Input() checkedID
  @Output() checkedUserID = new EventEmitter<number>();
  pacient= new Pacient();
  selection = new SelectionModel(true, []);  

  @ViewChild(MatPaginator) paginator: MatPaginator;


  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor() { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = function(data, filter):boolean
    {
      console.log("data din filtru: =>",data);
      return data.id.toString().includes(filter) || data.date_personale.nume.toLowerCase().includes(filter) || data.date_personale.prenume.toLowerCase().includes(filter) 
      || data.date_personale.cnp.toString().includes(filter) || data.date_contact.email.includes(filter) || data.date_contact.telefon.toLowerCase().includes(filter)
    }
  }

  checkedUserId(pacient){
    console.log("checked id ==> ", pacient.id)
    this.checkedID = pacient.id;
    this.checkedUserID.emit(pacient.id);
    this.dataSource = this.dataSource
  }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Pacient): string {
      if (!row) {
        
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
    }

}
