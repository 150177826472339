import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NewnomenclatordiagComponent } from './newnomenclatordiag/newnomenclatordiag.component';
import { CategoriiserviciiComponent } from './categoriiservicii/categoriiservicii.component';
import { ProduseComponent } from './produse/produse.component';
import { ServiciiComponent } from './servicii/servicii.component';
import { SpecialitatemediciComponent } from './specialitatemedici/specialitatemedici.component';
import { TipdepartamentComponent } from './tipdepartament/tipdepartament.component';
import { DepartamentComponent } from './departament/departament.component';
import { MediciComponent } from './medici/medici.component';
import { TipdiagnosticComponent } from './tipdiagnostic/tipdiagnostic.component';
import { DiagnosticComponent } from './diagnostic/diagnostic.component';
@Component({
  selector: 'app-nomenclatoare',
  templateUrl: './nomenclatoare.component.html',
  styleUrls: ['./nomenclatoare.component.css']
})
export class NomenclatoareComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  adauganomenclator():void{
    const dialogRef = this.dialog.open(NewnomenclatordiagComponent, {
      width: '650px',
      height: '550px',
      data: {}
    });
  }

  openCategoriiServicii():void{
    const dialogRef = this.dialog.open(CategoriiserviciiComponent, {
      width: '850px',
      height: '850px',
      data: {}
    });
  }
  openSpecialitati():void{
    const dialogRef = this.dialog.open(SpecialitatemediciComponent, {
      width: '850px',
      height: '850px',
      data: {}
    });
  }

  openServicii():void{
    const dialogRef = this.dialog.open(ServiciiComponent, {
      width: '850px',
      height: '850px',
      data: {}
    });
  }

  openProduse():void{
    const dialogRef = this.dialog.open(ProduseComponent, {
      width: '650px',
      height: '550px',
      data: {}
    });
  }

  openTipDepartament():void{
    const dialogRef = this.dialog.open(TipdepartamentComponent, {
      width: '650px',
      height: '550px',
      data: {}
    });
  }
  openDepartament():void{
    const dialogRef = this.dialog.open(DepartamentComponent, {
      width: '650px',
      height: '550px',
      data: {}
    });
  }
  openMedici():void{
    const dialogRef = this.dialog.open(MediciComponent, {
      width: '95%',
      height: '95%',
      data: {}
    });
  }

  openTipDiagnostic():void{
    const dialogRef = this.dialog.open(TipdiagnosticComponent, {
      width: '650px',
      height: '550px',
      data: {}
    });
  }

  openDiagnostic():void{
    const dialogRef = this.dialog.open(DiagnosticComponent, {
      width: '650px',
      height: '550px',
      data: {}
    });
  }

  openFurnizorServiciiSociale():void{

  }

  openCaseDeAsigurari():void{

  }


}
