import { Component, OnInit } from '@angular/core';
import { DepartamentService } from 'src/app/services/departament.service';
import {MatTableDataSource} from '@angular/material/table';
import { Departament,TipDepartament, Servicii, Valabilitate, ValabilitateCreate } from 'src/app/models/nomenclatoare.model';
import { TipDepartamentService } from 'src/app/services/tipdepartament.service';
import {ServiciiService} from '../../services/servicii.service';
import { IntervalService } from '../../services/interval.service'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { DatePipe } from '@angular/common'
import { MatInput } from '@angular/material/input';
import { ValabilitateInterface, ValabilitateCreateInterface, TipDiagnosticInterface, DiagnosticInterface,DiagnosticCreateFaraTipDiagnosticInterface, ServiciiInterface, TipDepartamentInterface } from 'src/app/interfaces/nomenclatoare.interface';

export const MY_FORMATS = {
  parse: {
    dateInput: 'd',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-departament',
  templateUrl: './departament.component.html',
  styleUrls: ['./departament.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class DepartamentComponent implements OnInit {
  departamente: Departament[] = [];
  tipdepartamente: TipDepartamentInterface[];
  servicii: ServiciiInterface[];
  displayedColumns: string[] = ['cod', 'denumire', 'tip'];
  dataSource: any
  newDepartament = new Departament();

  constructor(public serviciiService: ServiciiService, public tipDepartamentService: TipDepartamentService, public departamentService: DepartamentService ) { }

  ngOnInit(): void {
    // this.servicii= this.serviciiService.getServicii();
    this.serviciiService.getServiciiFromServer()
    .subscribe( (data) =>{
      if (data){
        this.servicii = data
      }
    })
    // this.tipdepartamente = this.tipDepartamentService.getTipDepartament();
    this.tipDepartamentService.getfromserver()
    .subscribe ( (data) =>{
      if (data){
        this.tipdepartamente = data
      }
    })
    // this.departamente = this.departamentService.getDepartament();
    this.departamentService.getDepartamentFromServer()
    .subscribe( (data) => {
      if(data){
        this.departamente = data
        this.dataSource = new MatTableDataSource(this.departamente)
      }
    })
    // this.dataSource = new MatTableDataSource(this.departamente);
  }

  AddDepartament(){
    this.departamentService.addDepartament(this.newDepartament);
    this.departamente = this.departamentService.getDepartament();
    this.dataSource = new MatTableDataSource(this.departamente);
    this.newDepartament = new Departament();
  }

  AddDepartamentToServer(){
    console.log("New Department ==>> ", this.newDepartament)
    let departament_nou = {
      cod: this.newDepartament.cod,
      denumire: this.newDepartament.denumire,
      tipdepartamentid: this.newDepartament.tipDepartement.id,
      serviciuid:0
    }
    departament_nou.serviciuid = this.newDepartament.serviciuid
    console.log("departament nou ==>> ", departament_nou)
    this.departamentService.addDepartamentToServer(departament_nou)
    .subscribe( (data) => {
      if (data){
        console.log ("Data from Add Departament To Server ==>> ", data)
      }
    })
  }

}
