import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { FileService } from '../services/file.service';

@Component({
  selector: 'app-descarcaconsultpdfbutton',
  templateUrl: './descarcaconsultpdfbutton.component.html',
  styleUrls: ['./descarcaconsultpdfbutton.component.css']
})
export class DescarcaconsultpdfbuttonComponent implements OnInit {
  @Input() consultData: any;
  @Input() pacientData: any;
  public base64 : string
  fileUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private printService: NgxExtendedPdfViewerService,
    public fileService : FileService
    // public matDialogRef: MatDialogRef<PdfpreviewerComponent>
  ) { }

  ngOnInit(): void {
  }

  processBase64(input_string){
    return input_string.substring(input_string.indexOf(',')+1)
  }

  async descarcaPDFBtnClick(){
    // console.log("CONSULT DATA -->> DOWNLOAD BUTTON ==>> ", this.consultData)
    // console.log("PACIENT DATA -->> DOWNLOAD BUTTON ==>> ", this.pacientData)
    this.fileService.getPacientFileFromServerByFisierID(this.consultData.idFisier)
    .subscribe (  ( fisier_details ) => {
      // console.log(" FILESERVICE GET PACIENT FROM SERVER ")
      if (fisier_details){
        // console.log(" FILESERVICE GET PACIENT FROM SERVER ==>> ", fisier_details)
        this.fileService.getFileDataFromServerByFisierID(this.consultData.idFisier)
        .subscribe ( ( element_path ) => {
          // console.log(" FILESERVICE GET PACIENT FROM SERVER ==>> ELEMENT PATH ==>> ", element_path)
          fetch(element_path.toString())
            .then(res => res.blob())
            .then(blob => {
              var link = window.document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fisier_details.name+'.'+fisier_details.extension;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              }
            )
        })
      }
    })
    // this.fileService.getFileDataFromServerByFisierID(this.consultData.idFisier)
    // .subscribe ((file_base64) => {
    //   if (file_base64){
    //     // console.log("FILE BASE 64 FROM DESCARCA ==>> ", file_base64)
    //     this.base64 = this.processBase64(file_base64)
    //     // const blob = await this.printService.getCurrentDocumentAsBlob()
    //     // if (blob) {
    //     //     console.log('The BLOB contains == DOWNLOAD DOCUMENT ==>> ' + blob.size + ' byte.')
    //     //     var filetodownload = new Blob([blob], { type:  'application/pdf' });
    //     //     this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    //     //     const a = document.createElement('a')
    //     //     a.href = window.URL.createObjectURL(blob)
    //     //     a.download = 'Evaluare.pdf';
    //     //     a.click();
    //     // }
    //     // else {
    //     //   console.log('download failed')
    //     // }
    //   }
    // })
  }
}
