import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tipfurnizorserviciisociale',
  templateUrl: './tipfurnizorserviciisociale.component.html',
  styleUrls: ['./tipfurnizorserviciisociale.component.css']
})
export class TipfurnizorserviciisocialeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
