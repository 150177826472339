import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  localStorage: Storage;
  changes$ = new Subject();
  keys:string[] = []

  constructor() {
    this.localStorage = window.localStorage;
   }

   addKeyinarray(keyz:string){
      this.keys.push(keyz);
      let data = this.get("keys");
      if (data)
      {
      this.remove("keys");
      this.set("key", this.keys)
      }
      else{
        this.set("key", this.keys)
      }
   }

   checkKeysfromarray(){
     return this.keys;
   }

   get(key: string): any {
    if (this.isLocalStorageSupported) {

      return JSON.parse(this.localStorage.getItem(key));
    }

    return null;
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      if (!this.keys.includes(key))
      {
        this.addKeyinarray(key);
        // console.log("LocalStorage Keys: => ", this.keys)
      }
      // console.log("LocalStorage e acceptat");
      this.localStorage.setItem(key, JSON.stringify(value));
      this.changes$.next({
        type: 'set',
        key,
        value
      });
      return true;
    }

    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      this.changes$.next({
        type: 'remove',
        key
      });
      return true;
    }

    return false;
  }

  get isLocalStorageSupported(): boolean {
    return !!this.localStorage
  }
}
