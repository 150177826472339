<mat-form-field>
  <input matInput [(ngModel)]="diagnostic.cod" placeholder="Cod Diagnostic">
</mat-form-field>
<mat-form-field>
   <input matInput [(ngModel)]="diagnostic.denumire" placeholder="Denumire Diagnostic">
</mat-form-field>
<br>
<mat-form-field>
  <mat-label>Tip Diagnostic</mat-label>
  <mat-select [value]="diagnostic.tipDiagnostic" >
      <mat-option *ngFor="let tipdiag of tipDiagnostic" [value]="tipdiag.id">
          {{tipdiag.denumire}}
      </mat-option>
  </mat-select>
</mat-form-field>
<div mat-dialog-actions align='center'>
  <button mat-raised-button color="warn" mat-dialog-close >Inchide</button>
  <button mat-raised-button color="primary" (click)="updatevalues()" mat-dialog-close >Salveaza</button>

</div>
