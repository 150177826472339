import { Adresa, AdresaCreate } from '../models/pacientdetails.model';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of  } from 'rxjs';
import {catchError} from 'rxjs/operators/catchError';
import { AdresaCreateInterface, AdresaInterface } from '../interfaces/pacient.interface';
import { serverurl } from './server.service';
const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
    providedIn: 'root'
})

export class MedicprogramService{
  // private server_url="http://optimabk.ztools.ro/api/v1/adresa/"
  private server_url=serverurl+"/api/v1/program/"

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
      private http: HttpClient
  ){}

  // addAdresaToServer(adresanoua: AdresaCreateInterface){
  //     console.log("Add Adresa to server ==>> ", serverurl+" -- sometext")
  //     return (this.http.post<any>(this.server_url,adresanoua, this.httpOptions))
  //   }

  //   stergeAdresaFromServer (id: number){
  //   console.log("Sterge Adresa to server ==>> ", id)
  //   return this.http.delete<any>(this.server_url+id,this.httpOptions)
  // }

  updateZiProgramToServer(zi_noua,id){
    return this.http.put<any>(this.server_url+"updatezi/"+id,zi_noua, this.httpOptions)
  }


}

