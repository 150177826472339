import { Component, OnInit, ViewChild } from '@angular/core';
import {SpecialitateMedicService} from '../../services/servmedic.service';
import {MatTableDataSource} from '@angular/material/table';
import { specialitateMedici, specialitateMediciCreate, specialitateMediciCreateFaraValabilitate, Valabilitate, ValabilitateCreate } from 'src/app/models/nomenclatoare.model';
import {MatPaginator} from '@angular/material/paginator';
import { IntervalService } from '../../services/interval.service'
import { ValabilitateInterface, ValabilitateCreateInterface, specialitateMediciCreateInterface, specialitateMediciInterface, specialitateMediciCreateFaraValabilitateInterface} from 'src/app/interfaces/nomenclatoare.interface';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { DatePipe } from '@angular/common'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { SpecialitateeditComponent } from '../specialitateedit/specialitateedit.component';
// import { type } from 'os';


export const MY_FORMATS = {
  parse: {
    dateInput: 'd',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-specialitatemedici',
  templateUrl: './specialitatemedici.component.html',
  styleUrls: ['./specialitatemedici.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class SpecialitatemediciComponent implements OnInit {
  public specialitati: any[];
  displayedColumns: string[] = ['id','cod', 'denumire','edit','deleteselect'];
  dataSource: any
  newSpecialitate = new specialitateMediciCreate();
  interval:ValabilitateInterface
  intervalcreate: ValabilitateCreateInterface
  listaintervale:ValabilitateInterface[] = []
  start: Date
  end: Date
  startformated: string
  todelete: any[] = []
  endformated: string
  public IntervalList: ValabilitateInterface [] = []
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialog: MatDialog, public specialitateMedicService: SpecialitateMedicService, public intervalService: IntervalService,public datepipe: DatePipe) { }

  ngOnInit(): void {
    // this.specialitati = this.specialitateMedicService.getSpecialitatiMedic();
    this.specialitateMedicService.getfromserver()
      .subscribe( (data:specialitateMediciInterface[]) => {
        if (data){
          this.specialitati = data
          this.dataSource  =  new MatTableDataSource(this.specialitati);
          this.dataSource.paginator = this.paginator;

          console.log("Specialitati Medici ==>> ",this.specialitati);
        }
      })
    // this.dataSource = new MatTableDataSource(this.specialitati);
  }
  AddSpecialitateMedic(){
    this.specialitateMedicService.addSpecialitateMedic(this.newSpecialitate);
    this.specialitati = this.specialitateMedicService.getSpecialitatiMedic();
    this.dataSource = new MatTableDataSource(this.specialitati);
    this.dataSource.paginator = this.paginator;
    this.newSpecialitate = new specialitateMedici();
  }

  valabilitatestatuschange(v){
    console.log("status v =>", v)
    this.newSpecialitate.arevalabilitate = v
  }

  startdateset(v) {
    let vd2 = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    let intermediar = this.datepipe.transform(new Date(vd2),"MM.dd.YYYY")
    this.start = new Date(intermediar)
  }
  enddateset(v) {
    let vd = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    let intermediar = this.datepipe.transform(new Date(vd),"MM.dd.YYYY")
    this.end = new Date(intermediar)
    return vd
  }
  addnewspecialitate(){
    console.log ("categoriiservicii component -> addnewcategorie() -> newcat inainte de orice  => ", this.newSpecialitate)
    if (this.newSpecialitate.arevalabilitate){ // check daca este bifata valabilitatea
      console.log ("categoriiservicii component -> addnewcategorie() -> are valabilitate TRUE  => ", this.newSpecialitate.arevalabilitate)
      this.intervalcreate = new ValabilitateCreate()
      this.intervalcreate.format_type = "MM.dd.YYYY"
      this.intervalcreate.start = this.start.toString()
      this.intervalcreate.end = this.end.toString()
      console.log("categoriiservicii component -> addnewcategorie() -> this.intervalcreate  => ",this.intervalcreate)
      console.log("categorii array 1 => -- !! -- ==> ",this.specialitati)      //commented before
      this.intervalService.addIntervalToServer(this.intervalcreate)
        .subscribe((data)=>{
          if (data){
            console.log("data from add interval to server => ", data)
            this.newSpecialitate.valabilitateid = data
            console.log("newcat.valabilitateid from add interval to server from subscribe => ",this.newSpecialitate.valabilitateid)
            // console.log("type of newcat.valabilitateid from add interval to server from subscribe => ",typeof(this.newSpecialitate.valabilitateid))
            this.specialitateMedicService.addSpecialitateMedicCuValabilitate(this.newSpecialitate)
                .subscribe( data => {
                  console.log("data put id ==> ",data.id)
                  console.log("data keys ==> ",Object.keys(data))
                  console.log("data full object ==> ",data)
                  this.specialitati.push(data)
                  console.log("categorii array => -- !! -- ==> ",this.specialitati)      //commented before
                  this.refreshformafteradd()
                });
          }
        })
      console.log("categorii array 2 => -- !!!! -- ==> ",this.specialitati)      //commented before
      console.log("newcat full object from add interval to server => ",this.newSpecialitate)
    }
    else{
      console.log ("categoriiservicii component -> addnewcategorie() -> are valabilitate FALSE  => ", this.newSpecialitate.arevalabilitate)
      console.log ("this.newSpecialitate full object daca nu are valabilitate  => ", this.newSpecialitate)
      this.specialitateMedicService.addSpecialitateMedicFaraValabilitate(this.newSpecialitate)
        .subscribe(data =>{
          if (data){
            console.log("data din addHeroFaraValabilitate ==>> ",data)
            this.specialitati.push(data)
            this.refreshformafteradd()
          }
        })
    }
  }

  @ViewChild('startDate',{
    read:MatInput
  }) startDate:MatInput;
  @ViewChild('endDate',{
    read:MatInput
  }) endDate:MatInput;

  openSpecialitateEdit(specialitate):void{
    const dialogRef = this.dialog.open(SpecialitateeditComponent, {
      width: '850px',
      height: '550px',
      data: specialitate
    });
    dialogRef.afterClosed().subscribe( result =>{
      this.updatedata()
    })
  }
  refreshformafteradd(){
    this.newSpecialitate = new specialitateMediciCreate();
    this.startDate.value='';
    this.endDate.value='';
    this.dataSource = new MatTableDataSource(this.specialitati);
    this.dataSource.paginator = this.paginator;
    console.log("===>> REFRESH FORMAT <<===")
  }
  marktodelete(deleteid){
    if (this.todelete.includes(deleteid)){
      console.log("Id-ul deja selectat !! ")
      this.todelete.forEach( (value,index) =>{
        if (value == deleteid) this.todelete.splice(index,1)
      })
    }
    else{
      this.todelete.push(deleteid)
    }
    console.log("Id-uri de sers ==>> ",this.todelete)
  }
  deleteitems(){
    this.todelete.forEach( value =>{
      this.specialitateMedicService.deletefromserver(value).subscribe( (data) =>{
        if (data){
          console.log("Data from delete categorie de servicii ==>> ", data)
          this.updatedata()
        }
      })
    })
  }
  updatedata(){
    this.specialitateMedicService.getfromserver()
    .subscribe((data:specialitateMediciInterface[]) => {
      if (data){
        console.log("data din cat service subscribe ==>> ", data)
        this.specialitati = data
        this.dataSource = new MatTableDataSource(this.specialitati);
        this.dataSource.paginator = this.paginator;
      }
    });
  }
}
