
      <mat-checkbox [(ngModel)]="incasareintegralaflag" (change)="plataintegrala()"> Plata integrala </mat-checkbox>
      <div *ngIf="incasareintegralaflag">
        <mat-form-field >
          <input matInput [(ngModel)]="total_de_plata" [disabled]="incasareintegralaflag" value={{total_de_plata}} placeholder="Total de plata">
          <span matSuffix> RON</span>
        </mat-form-field>
        <!-- <button mat-raised-button  style="padding-left: 20px;" [disabled]="confirmareplataintegralaflag" (click)="confirmintegral()"> Confirma </button> -->

      </div>

      <div *ngIf="!incasareintegralaflag">
        <mat-form-field >
          <input matInput [(ngModel)]="plata_partiala" [disabled]="confirmareplatapartialaflag"  placeholder="Suma partiala de plata">
          <span matSuffix> RON</span>
        </mat-form-field>




      </div>

      <!-- <div *ngIf="confirmareplataintegralaflag || confirmareplatapartialaflag">
        <button mat-raised-button  style="padding-left: 20px;" [disabled]="tipplataselectedflag" (click)="platacash()"> Plata Cash </button>
        <button mat-raised-button  style="padding-left: 20px;" [disabled]="tipplataselectedflag" (click)="platacard()"> Plata Card </button>
      </div> -->

      <br>

      <div>
        <button mat-raised-button  style="padding-left: 20px;" [disabled]="tip_incasareid == 1" (click)="settipplata(1)"> Plata Cash </button>
        <button mat-raised-button  style="padding-left: 20px;" [disabled]="tip_incasareid == 2" (click)="settipplata(2)"> Plata Card </button>
        <!-- <button mat-raised-button  style="padding-left: 20px;" [disabled]="!tipplataselectedflag || confirmareplataflag" (click)="resetplata()"> Reset Metoda de Plata</button> -->
      </div>

      <br>
      <button mat-raised-button  style="padding-left: 20px;" [disabled]="!tipplataselectedflag" (click)="confirmaplata()"> Confirma Plata</button>
      <br>
      <!-- {{"Tip plata selected flag => " + tipplataselectedflag}}
      <br>
      {{"Confirmare plata flag => " + confirmareplataflag}}
      <br>
      {{"NOT !!! Tip plata selected flag => " + !tipplataselectedflag}}
      <br>
      {{"NOT !!! Confirmare plata flag => " + !confirmareplataflag}}
      <br>
      {{"Rezultatul logic Nu este selectat tip plata SAU Nu este confirmata plata => " }}
      <br>
      {{!tipplataselectedflag || !confirmareplataflag}}
      <br>
      {{"Rezultatul logic Nu este selectat tip plata Si Nu este confirmata plata => " }}
      <br>
      {{!tipplataselectedflag && !confirmareplataflag}}
      <br>
      {{"Rezultatul logic Nu este selectat tip plata SAU Este confirmata plata => " }}
      <br>
      {{!tipplataselectedflag || confirmareplataflag}}
      <br>
      {{"Rezultatul logic Nu este selectat tip plata Si Este confirmata plata => " }}
      <br>
      {{!tipplataselectedflag && confirmareplataflag}}
      <br> -->

      <table style="width: 100%;" mat-table [dataSource]="serviciidatasource" class="mat-elevation-z8">
        <!-- ID Column -->
        <ng-container matColumnDef="data">
          <th mat-header-cell *matHeaderCellDef> Data </th>
          <!-- <td mat-cell *matCellDef="let element"> {{element.dataefectuarii | date:'dd.MM.y'}} </td> -->
          <td mat-cell *matCellDef="let element"> {{incasareService.parsedata(element.dataefectuarii) | date:'dd.MM.y'}} </td>
        </ng-container>
          <!-- Ora Column -->
          <ng-container matColumnDef="ora">
            <th mat-header-cell *matHeaderCellDef> Ora </th>
            <td mat-cell *matCellDef="let element"> {{incasareService.parsetime(element.dataefectuarii) | date:'HH:mm'}} </td>
          </ng-container>
            <!-- Nume Column -->
        <ng-container matColumnDef="terapeut">
          <th mat-header-cell *matHeaderCellDef> Terapeut </th>
          <td mat-cell *matCellDef="let element"> {{returndenumiremedici(element.medicid)}} </td>
        </ng-container>
        <!-- Nume Column -->
        <ng-container matColumnDef="denumire">
          <th mat-header-cell *matHeaderCellDef> Denumire </th>
          <!-- <td mat-cell *matCellDef="let element"> {{element | json}} </td> -->
          <!-- <td mat-cell *matCellDef="let element"> {{Object.values(serviciidenumirilist)}} </td> -->
          <!-- <td mat-cell *matCellDef="let element"> <div *ngFor = " let item of serviciidenumirilist | keyvalue"> -->
          <td mat-cell *matCellDef="let element">
            {{denumiri_servicii_medicale[serviciiidlist.indexOf(element.serviciuid)]}}
            <!-- {{serviciidenumirilist}} -->
            <!-- <div *ngIf = "item.key == 'id' ">

            </div> -->
           </td>
        </ng-container>
        <!-- Prenume Column -->
        <ng-container matColumnDef="pret">
          <th mat-header-cell *matHeaderCellDef> Pret </th>
          <td mat-cell *matCellDef="let element"> {{element.pret}} RON </td>
        </ng-container>
        <!-- Discount Column -->
        <ng-container matColumnDef="discount">
          <th mat-header-cell *matHeaderCellDef> Discount </th>
          <td mat-cell *matCellDef="let element"> {{element.discount}} % </td>
        </ng-container>
          <!-- Total Column -->
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef> Total </th>
            <td mat-cell *matCellDef="let element"> {{element.total}} RON</td>
          </ng-container>
        <!-- Email Column -->
        <ng-container matColumnDef="platit">
          <th mat-header-cell *matHeaderCellDef> Platit </th>
          <td mat-cell *matCellDef="let element"> {{element.platit}} RON </td>
        </ng-container>
        <!-- Telefon Column -->
        <ng-container matColumnDef="sold">
          <th mat-header-cell *matHeaderCellDef> Sold </th>
          <td mat-cell *matCellDef="let element"> {{element.total - element.platit}} RON </td>
        </ng-container>
        <!-- <ng-container matColumnDef="selectincaseaza">
          <th mat-header-cell *matHeaderCellDef>
            <button  mat-raised-button color="warn" [disabled]="marcatepentruincasat.length == 0" (click)="incaseazaservicii()" > Incaseaza </button>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox (change)="marcheazaserviciul(element.id)"></mat-checkbox>
          </td>

        </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="displayedColumnstwo"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnstwo;"></tr>
        <!-- Row shown when there is no matching data. -->
      </table>
