<h1 mat-dialog-title>Creeaza un folder nou</h1>

<mat-dialog-content>
	<mat-form-field class="example-full-width">
		<input matInput placeholder="Folder Name" [(ngModel)]="folderName">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button mat-dialog-close>Renunta</button>
	<button mat-raised-button [mat-dialog-close]="folderName" color="primary">Salveaza</button>
</mat-dialog-actions>