import { Injectable } from '@angular/core';
import { Diagnostic, Valabilitate, TipDiagnostic, DiagnosticCreate, DiagnosticCreateFaraValabilitate } from '../models/nomenclatoare.model';
import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError} from 'rxjs/operators/catchError';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ValabilitateInterface,  TipDiagnosticInterface, DiagnosticInterface, DiagnosticCreateInterface, DiagnosticCreateFaraValabilitateInterface, DiagnosticCreateFaraTipDiagnosticInterface } from '../interfaces/nomenclatoare.interface';
import { IntervalService } from './interval.service'
import { serverurl } from './server.service';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};


@Injectable({
  providedIn: 'root'
})

export class DiagnosticService {
  public diagnostic: Diagnostic [] = []
  public localstorageflag = 0;
  // private server_url="api/diagnostic";
  private server_url=serverurl+"/api/v1/diagnostic/";
  // private server_url="http://localhost/api/v1/diagnostic/";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    public intervalService: IntervalService,
    private localStorageService: LocalStorageService
  ) {
    let data = this.localStorageService.get("Diagnostic");
    // console.log("On Initi DAta Local storage => ",data)
    if (data)
    {
      this.localstorageflag = 1;
      this.diagnostic = data;
      this.diagnostic.forEach(element=> {
        this.http.post<Diagnostic>(this.server_url, element, cudOptions).pipe(
          catchError(this.handleError)
            )
          });
      }
    else {
      // console.log("PacientList nu este Local")
    }

   }

  getDiagnostic(){
    return this.diagnostic;
  }

  getfromserver() {
    return this.http.get<DiagnosticInterface[]>(this.server_url)
  }
  deletefromserver(id){
    return this.http.delete(this.server_url+id)
  }
  updatetoserver(id,departament){
    return this.http.put(this.server_url+id, departament)
  }
  addDiagnosticCuValabilitate(diagnosticnou: DiagnosticCreateInterface){
    console.log("intru in add post request")
    console.log("categorie de servicii service -> addhero -> hero => ", diagnosticnou)
    let toate_detaliile: DiagnosticCreateInterface = new TipDiagnostic()
    return(this.http.post<any>(this.server_url,diagnosticnou,this.httpOptions))
  }

  addDiagnosticFaraValabilitate(diagnosticnou: DiagnosticCreateFaraValabilitateInterface){
    console.log("intru in add post request fara valabilitate")
    console.log("categorie de servicii service -> addspecialitateMedic fara valabilitate -> specialitateMedic => ", diagnosticnou)
    return (this.http.post<any>(this.server_url,diagnosticnou,this.httpOptions))
  }


  addDiagnostic(newdiagnostic){
    this.diagnostic.push(newdiagnostic);
    console.log("lista departament: => ", newdiagnostic);
    if (this.localstorageflag){
      this.localStorageService.remove("Diagnostic");
      this.addtolocalStorage(this.diagnostic);
    }
    else {
      this.addtolocalStorage(this.diagnostic);
    }
    console.log("Lista pacienti din pacienti service =>", this.diagnostic);

    return this.http.post<Diagnostic>(this.server_url, newdiagnostic, cudOptions).pipe(
      catchError(this.handleError)
    );
  }
  addtolocalStorage(PacientList){
    console.log("addtolocalstorage CategorieServicii: =>", PacientList);
    console.log("Addtolocalstorage",this.localStorageService.set("Diagnostic", PacientList));
  }
  private handleError(error: Response){
    console.log("Error from Pacient Service: => " + error)
    return Observable.throw("500 internal server error" )
  }
}
