import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { MatTableDataSource } from '@angular/material/table';
import { logo_url_base64 } from 'src/app/models/logo-base64.model';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { DatePipe } from '@angular/common';
import { RouterLinkWithHref } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pacient, SemnaturaRLCreate } from 'src/app/models/pacientdetails.model';
import { FileService } from 'src/app/services/file.service';
import { EvaluareIndependentaMNFInterface } from 'src/app/interfaces/documente.interface';
import { EvalserviceService } from 'src/app/services/evalservice.service';
import { EvaluareIndependentaMNF } from 'src/app/models/documente.models';
import { PinentryComponent } from 'src/app/pinentry/pinentry.component';
import { ConsultCreate } from 'src/app/models/consult.model';
import { ConsultCreateInterface } from 'src/app/interfaces/consult.interface';
import { ConsultatiiService } from 'src/app/services/consultatii.service';
import { PdfpreviewerComponent } from 'src/app/pdfpreviewer/pdfpreviewer.component';
import { FileElementCls } from 'src/app/models/fisier.model';
import { FileElementCreate } from 'src/app/file-manager/model/element';
import { base_img_64 } from 'src/app/models/base-img64.model';
import { SemnaturiRLService } from 'src/app/services/semnaturirl.service';
import { SemnaturiTerapeutiFilesService } from 'src/app/services/semnaturiterapeutifiles.service';
import { SemnaturaRLCreateInterface } from 'src/app/interfaces/pacient.interface';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-evaluare-independenta-mnf',
  templateUrl: './evaluare-independenta-mnf.component.html',
  styleUrls: ['./evaluare-independenta-mnf.component.css']
})
export class EvaluareIndependentaMnfComponent implements OnInit {
  @Output () fileid = new EventEmitter<string>();
  idFisier : any = null
  semnatura_Terapeut : any = null
  semnatura_RL : any = null
  caz : number = 1
  pacient = new Pacient()
  fisa_evaluare_independenta: EvaluareIndependentaMNFInterface = new EvaluareIndependentaMNF()
  categorii=['Independent', 'Cu asistenta minima' , 'Cu asistenta majora', 'Dependent']
  datasourceTabelBarthel = new MatTableDataSource(this.fisa_evaluare_independenta.tabel_scala_barthel.activitate)
  datasourceScorADL = new MatTableDataSource(this.fisa_evaluare_independenta.scor_adl.tabel_adl.activitate)
  datasourceScalaADL = new MatTableDataSource(this.fisa_evaluare_independenta.scala_adl.tabel_scala_adl.activitate)
  displayedcolumns = [ 'id','Activitate','Descriere','Scor']
  displayedcolumnsScalaADL = [ 'id','Activitate','Slider']
  constructor(
    public dialogRef: MatDialogRef<EvaluareIndependentaMnfComponent>,
    public dialog: MatDialog,
    public consultatiiService: ConsultatiiService,
    private cdr: ChangeDetectorRef,
    public evaluarefieldsService: EvalserviceService ,
    private datePipe: DatePipe,
    public semnaturirlService: SemnaturiRLService,
    public semnaturiTerapeutFilesService: SemnaturiTerapeutiFilesService,
    @Inject(MAT_DIALOG_DATA) private data:any,
    public fileService: FileService
    ) {
    if(data){
      if (data.caz == 1 ){
        this.pacient = data.beneficiar
        this.caz = 1
        console.log("CAZ = 1 ==>> ", data)
      }
      else if (data.caz == 2){
        console.log("CAZ = 2 ==>> ", data)
        this.caz = 2
        // this.fisa_evaluare_independenta = data.fieldsData
        this.pacient = data.beneficiar
        this.idFisier = data.fieldsData.idFisier
        this.getSavedDataFromServer(this.idFisier)
      }
    }
   }

   getSavedDataFromServer(idFisier){
    console.log( "GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", idFisier)
    this.consultatiiService.getConsultDataFromServerByPFisierID(idFisier)
    .subscribe( (data) => {
      if (data){
        console.log( "DATA FROM GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", data)
        this.fisa_evaluare_independenta = data.dataJSON
        this.datasourceTabelBarthel = new MatTableDataSource(this.fisa_evaluare_independenta.tabel_scala_barthel.activitate)
        this.datasourceScorADL = new MatTableDataSource(this.fisa_evaluare_independenta.scor_adl.tabel_adl.activitate)
        this.datasourceScalaADL = new MatTableDataSource(this.fisa_evaluare_independenta.scala_adl.tabel_scala_adl.activitate)
        this.semnaturirlService.getSemnaturaRLfromServerByFileID(this.idFisier)
        .subscribe( (data) => {
          if (data){
            console.log("DATA FROM GET SEMNATURA RL FROM SERVER ==>> ", data)
            this.semnatura_RL = data.semnatura
          }
        })
        this.semnaturiTerapeutFilesService.getSemnaturaTerapeutFilesfromServerByFileID(this.idFisier)
        .subscribe( (data) => {
          if (data){
            console.log("DATA FROM GET SEMNATURA TERAPEUT FROM SERVER ==>> ", data)
            this.semnatura_Terapeut = data.semnatura
          }
        })
      }
    })
  }

  numberedversion(valoare){
    return Number(valoare)
  }
  refreshDS(){
    this.datasourceTabelBarthel.data = this.datasourceTabelBarthel.data
  }
  refreshScalaDS(){
    this.datasourceScalaADL.data = this.datasourceScalaADL.data
  }
  change($event:MatSliderChange){
    this.datasourceTabelBarthel.data = this.datasourceTabelBarthel.data
  }
  changeslider($event:MatSliderChange){
    this.datasourceScalaADL.data = this.datasourceScalaADL.data
  }
  changecheckbox(element,val)
  {
    console.log("checkbox change, element: => ", element)
    if (element.valoare === val){
      element.valoare = ""
    }
    else {
      element.valoare = val
    }
    this.refreshScalaDS()
  }
  gettotalbarthel(){
    let total = 0
    for (let x of this.fisa_evaluare_independenta.tabel_scala_barthel.activitate)
    {
      total = total + this.numberedversion(x.scor)
    }
    this.fisa_evaluare_independenta.tabel_scala_barthel.total_scala_barthel = total.toString()
    return total
  }
  getclasificareADL(){
    if (this.numberedversion(this.fisa_evaluare_independenta.scor_adl.total_tabel_adl) === 10){
      return 'Autonomie'
    }
    else{
      if (this.numberedversion(this.fisa_evaluare_independenta.scor_adl.total_tabel_adl) >= 8){
        return 'Cvasiindependent'
      }
      else{
        if (this.numberedversion(this.fisa_evaluare_independenta.scor_adl.total_tabel_adl) >= 3){
          return 'Independenta Asistata'
        }
        else{
          if (this.numberedversion(this.fisa_evaluare_independenta.scor_adl.total_tabel_adl) >= 0){
            return 'Dependenta Totala'
          }
        }
      }
    }
    return ''
  }
  ischecked(element,valoare){
    if (element.valoare === valoare )
    {
      return true
    }
    return false
  }
  gettotalADL(){
    let total = 0
    for (let x of this.fisa_evaluare_independenta.scor_adl.tabel_adl.activitate)
    {
      total = total + this.numberedversion(x.scor)
    }
    this.fisa_evaluare_independenta.scor_adl.total_tabel_adl = total.toString()
    this.fisa_evaluare_independenta.scor_adl.calificare_tabel_adl = this.getclasificareADL()
    return total
  }
  ngOnInit(): void {
    if (this.caz == 1 ){

      this.evaluarefieldsService.getDocFieldsFromServerByID(26)
      .subscribe ( (data) => {
        if (data){
          this.fisa_evaluare_independenta = data.props
          this.fisa_evaluare_independenta.id = data.id
          this.fisa_evaluare_independenta.pacientid = this.pacient.id
          this.fisa_evaluare_independenta.nume_pacient = this.pacient.date_personale.nume
          this.fisa_evaluare_independenta.prenume_pacient = this.pacient.date_personale.prenume
          this.fisa_evaluare_independenta.data_nasterii_pacient = this.pacient.date_personale.data_nasterii
          this.fisa_evaluare_independenta.data_evaluarii = new Date().toString()
          this.datasourceTabelBarthel = new MatTableDataSource(this.fisa_evaluare_independenta.tabel_scala_barthel.activitate)
          this.datasourceScorADL = new MatTableDataSource(this.fisa_evaluare_independenta.scor_adl.tabel_adl.activitate)
          this.datasourceScalaADL = new MatTableDataSource(this.fisa_evaluare_independenta.scala_adl.tabel_scala_adl.activitate)

          console.log("DATA FROM EVALUARE INDEPENDENTA SERVER ==>> ", this.fisa_evaluare_independenta)
        }
      })
    }
    if (this.caz == 2){
      this.semnaturirlService.getSemnaturaRLfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA RL FROM SERVER ==>> ", data)
          this.semnatura_RL = data.semnatura
        }
      })
      this.semnaturiTerapeutFilesService.getSemnaturaTerapeutFilesfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA TERAPEUT FROM SERVER ==>> ", data)
          this.semnatura_Terapeut = data.semnatura
        }
      })
    }

  // FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI

  // let testone = {
  //   "denumire" : "Evaluarea INDEPENDENTA MNF",
  //   "props" : this.fisa_evaluare_independenta
  // }

  // this.evaluarefieldsService.addToServer(testone )
  // .subscribe( (data) =>{
  //   if (data){
  //     console.log("Data from add sectiunea A to server ==>> ", data)
  //   }
  //   else{
  //     console.log("NO !!! Data from add sectiunea A to server ==>> ")
  //   }
  // })

  // END FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI

  }

  ngAfterViewChecked(){
    //your code to update the model
    this.cdr.detectChanges();
 }
  getVarstaPacient(data_nasterii){
    var intermediar = Math.abs( Date.now() - new Date(data_nasterii).getTime() )
    var varsta_intermediar = Math.floor( intermediar / (1000 * 3600 * 24) / 365.25  )
    return varsta_intermediar.toString()
  }

  prepareRows(r){
    var x = []
    for (var y of r.descriere){
      x.push(
        {
          text: y.detalii + " - " + y.puncte,
        }
      )
    }
    return x
  }
  prepareTabelBarthel(){
    var x = [
      [
        'id',
        'Activitate',
        'Descriere',
        'Scor'
      ]
    ]
    var y=[]
    for (var i of this.fisa_evaluare_independenta.tabel_scala_barthel.activitate){
      y = [i.id, i.titlu, this.prepareRows(i), i.scor]
      x.push(y)
    }
    y = ['','Total',this.fisa_evaluare_independenta.tabel_scala_barthel.total_scala_barthel + " puncte",'']
      x.push(y)
      return x
  }
  prepareScorADL(){
    var x = [
      [
        'id',
        'Activitate',
        'Descriere',
        'Scor'
      ]
    ]
    var y=[]
    for (var i of this.fisa_evaluare_independenta.scor_adl.tabel_adl.activitate){
      y = [i.id, i.titlu, this.prepareRows(i), i.scor]
      x.push(y)
    }
    y = ['','Total',this.fisa_evaluare_independenta.scor_adl.total_tabel_adl + " puncte",'']
      x.push(y)
      return x
  }
  prepareScalaADL(){
    var x = [
      [
        'id',
        'Activitate',
        'Grad de dependenta'
      ]
    ]
    var y = []
    for ( var i of this.fisa_evaluare_independenta.scala_adl.tabel_scala_adl.activitate ){
      y = [i.id, i.titlu, this.categorii[i.valoare]]
      x.push(y)
    }
    return x
  }

  semneazaRL(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_RL = res
        console.log("Semnatura RL IMAGINE ESTE ==>> ",this.semnatura_RL)
      }
    })
  }
  semneazaTerapeut(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_Terapeut = res
        console.log("Semnatura Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
      }
    })
  }


  addConsultToServer(idFisier){
    let intermediar_consult : ConsultCreateInterface = new ConsultCreate()
    intermediar_consult.idPacient = this.fisa_evaluare_independenta.pacientid
    intermediar_consult.idFisier = idFisier
    intermediar_consult.dataAdaugare = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
    intermediar_consult.idEvaluare = 26,
    intermediar_consult.denumire = "Evaluarea INDEPENDENTA MNF"
    intermediar_consult.dataJSON = this.returnObjectForAddConsult()
    console.log(" ADD CONSULT TO SERVER ==>> ", intermediar_consult)
    this.consultatiiService.addConsultToServer(intermediar_consult)
    .subscribe( (data) => {
      if (data){
        console.log("DATA DIN ADD CONSULT TO SERVER ==>> ", data)
        let semnatura_noua_RL : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
        semnatura_noua_RL.idFisier = data.idFisier
        semnatura_noua_RL.semnatura = this.semnatura_RL
        this.semnaturirlService.addSemnaturaRLToServer(semnatura_noua_RL)
        .subscribe ( (data) => {
          if (data){
            console.log ("DATA DIN ADD SEMNATURA RL TO SERVER ==>> ", data)
          }
        })
        let semnatura_noua_TerapeutFiles : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
        semnatura_noua_TerapeutFiles.idFisier = data.idFisier
        semnatura_noua_TerapeutFiles.semnatura = this.semnatura_Terapeut
        this.semnaturiTerapeutFilesService.addSemnaturaTerapeutFilesToServer(semnatura_noua_TerapeutFiles)
        .subscribe ( (data) => {
          if (data){
            console.log ("DATA DIN ADD SEMNATURA TERAPEUT FILES TO SERVER ==>> ", data)
          }
        })
      }
    })
  }

  returnObjectForAddConsult(){
    let intermediar = {
      "dataJSON" : this.fisa_evaluare_independenta
    }
    return intermediar
  }

  openPDFPreviewSsaveModificari(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      // console.log("data din pdfgenerator base 64 ==>> ",data);
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
      dialogRef.afterClosed().subscribe( async res => {
        if (res){
                    // this.semnatura_Terapeut = res
            // console.log("PDF PREVIEW FROM SAVE MODIFICARI RESULT AFTER CLOSE ==>> ",res)
            let update_file  = {
              'id' : this.idFisier,
              'dateadden' : this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm'),
              'path' : "data:application/pdf;base64,"+data,
              'name' : "Evaluare Independenta MNF - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
            }
            this.fileService.updateFileToServer(update_file)
            .subscribe ( (data) => {
              // console.log ( "DATA FROM UPDATE FILE FROM SERVER ==> ", data)
            })
            this.consultatiiService.updateConsultToServerByFisierID(this.idFisier,{'dataJSON' : this.returnObjectForAddConsult()})
            .subscribe( (data) => {
              // console.log ("DATA FROM ADD CONSULT TO SERVICE ==>> ", data)
            })
            this.dialogRef.close(1)
        }
      })
    });
    }

  openPDFPreviewComponent(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
      dialogRef.afterClosed().subscribe( async res => {
        if (res){
          let fisier_to_upload : FileElementCreate = new FileElementCls()
          fisier_to_upload.id = this.fileService.generateFileID();
          fisier_to_upload.idPacient = this.fisa_evaluare_independenta.pacientid
          fisier_to_upload.isFolder = false
          fisier_to_upload.parent = 'root'
          fisier_to_upload.dateadden = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
          fisier_to_upload.type = "application/pdf"
          fisier_to_upload.extension = "pdf"
          fisier_to_upload.path = "data:application/pdf;base64,"+data
          fisier_to_upload.status = 'neverificat';
          fisier_to_upload.name = "Evaluare Independenta MNF - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
          let intermediar_from_server = await this.fileService.addFileToServerReturnFileID(fisier_to_upload).toPromise()
          this.addConsultToServer(intermediar_from_server)
          this.dialogRef.close(0)
        }
      })
    })
  }

  generatePDF(action = 'open') {
    var preparedTabelBarthel = this.prepareTabelBarthel()
    var preparedScorADL = this.prepareScorADL()
    var preparedScalaADL = this.prepareScalaADL()
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
        height: 60,
        columns:
          [
            // {
            //   image: logo_url_base64,
            //   width: 50,
            //   alignment: 'center',
            // },
            {
              stack: [
                "Fundația Mihai Neșu Foundation",
                "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
              ],
              margin: 10,
              style: 'textheader'
            }
          ],
        margin: [0, 0, 0, 90]
      },
      content: [
        {
          canvas: [
            {
              type: 'line',
              x1: -100, y1: 0,
              x2: 600, y2: 0,
              lineWidth: 1,
              lineCap: 'square'
            },
            {
              type: 'line',
              x1: -100, y1: 4,
              x2: 600, y2: 4,
              lineWidth: 1,
              lineCap: 'square'
            }
          ]
        },
        {
          image: logo_url_base64,
          width: 200,
          alignment: 'center',
        },
        {
          stack: [
            "EVALUAREA",
            " ABILITĂȚILOR DE VIAȚA INDEPENDENTĂ ",
            "(ACTIVITIES OF DAILY LIVING CHECKLIST)"
          ],
          bold: true,
          alignment: 'center',
          fontSize: 16,
          margin: [0, 50, 0, 80]
        },
        {
          text: "Nume: " + this.fisa_evaluare_independenta.nume_pacient + " " + this.fisa_evaluare_independenta.prenume_pacient,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Data nasterii: "  + ((this.fisa_evaluare_independenta.data_nasterii_pacient != "")?this.datePipe.transform(new Date(this.fisa_evaluare_independenta.data_nasterii_pacient),'dd.MM.yyyy'):" - ") ,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Varsta cronologica: "  + ((this.fisa_evaluare_independenta.data_nasterii_pacient != "")? this.getVarstaPacient(this.fisa_evaluare_independenta.data_nasterii_pacient):" - ") + " ani " ,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Data evaluarii "  + ((this.fisa_evaluare_independenta.data_evaluarii != "")?this.datePipe.transform(new Date(this.fisa_evaluare_independenta.data_evaluarii),'dd.MM.yyyy'):" - ")  ,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: this.fisa_evaluare_independenta.descriere_scale_de_evaluare_functionala.titlu,
          alignment: 'left',
          bold:true,
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        {
          text: this.fisa_evaluare_independenta.descriere_scale_de_evaluare_functionala.text ,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: this.fisa_evaluare_independenta.descriere_scala_de_incapacitate_barthel.titlu,
          alignment: 'left',
          bold:true,
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        {
          text: this.fisa_evaluare_independenta.descriere_scala_de_incapacitate_barthel.text ,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [20,'20%', '*', 30 ],
            body: preparedTabelBarthel
          }
        },
        {
          text: "Mentiuni: " + this.fisa_evaluare_independenta.tabel_scala_barthel.mentiuni_scala_barthel,
          bold: true,
          alignment: 'left',
          fontSize: 14,
          margin: [0, 10, 0, 20]

        },
        {
          text: this.fisa_evaluare_independenta.scor_adl.titlu,
          bold: true,
          alignment: 'left',
          fontSize: 14,
          decoration: 'underline',
          margin: [0, 10, 0, 20]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [20,'20%', '*', 30 ],
            body: preparedScorADL
          }
        },
        {
          text:"Clasificare: " + ((this.fisa_evaluare_independenta.scor_adl.total_tabel_adl  === '10')?'Autonomie':((this.fisa_evaluare_independenta.scor_adl.total_tabel_adl >= '8')?'Cvasiindependent':((this.fisa_evaluare_independenta.scor_adl.total_tabel_adl >= '3')?'Independenta asistata':'Dependenta Totala'))),
          bold: true,
          alignment: 'left',
          fontSize: 14,
          decoration: 'underline',
          margin: [0, 10, 0, 20]
        },
        {
          text:"Clasificare: ",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          stack:[
          "st. I - 10 puncte - Autonomie ",
          "st. Ii - 8 <-> 10 puncte - Cvasiindependent ",
          "st. III - 3 <-> 8 puncte - Independenta Asistata ",
          "st. IV - 0 <-> 3 puncte - Dependenta Totala ",
          ]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [20,'*', "25%" ],
            body: preparedScalaADL
          }
        },
        {
          text: "Mentiuni: " + this.fisa_evaluare_independenta.scala_adl.mentiuni_scala_adl,
          bold: true,
          alignment: 'left',
          fontSize: 14,
          margin: [0, 10, 0, 20]

        },
        {
          columns:[
            {
              stack: [
                {
                  text: "Semnatura terapeut, ",
                  bold: true,
                  alignment: 'left',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_Terapeut ? this.semnatura_Terapeut : base_img_64,
                  height: 40,
                  width: 140,
                  margin: [0, 0, 0, 20]
                }
              ]
            },
            {
              stack: [
                {
                  text: "Luat la cunoștință de părinte, ",
                  bold: true,
                  alignment: 'right',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_RL ? this.semnatura_RL : base_img_64,
                  alignment: 'right',
                  height: 40,
                  width: 140,
                  margin: [0, 0, 0, 20]
                }
              ]
            }
          ]
        }

      ],
      styles: {
        textheader: {
          alignment: 'center',
          bold: true,
          fontSize: 14,
          // lineHeight: 2
        }
      }
    }
    return docDefinition
  }
}
