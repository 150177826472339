<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> Fisa de monitorizare a PPI</h2></span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Data monitorizare: {{ (fisa_monitorizare_ppi.data_monitorizare != "") ? fisa_monitorizare_ppi.data_monitorizare : "Nespecificat" }} </span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Nume: {{ (fisa_monitorizare_ppi.nume_pacient != "") ? fisa_monitorizare_ppi.nume_pacient : "Nespecificat" }} </span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Prenume: {{ (fisa_monitorizare_ppi.prenume_pacient != "") ? fisa_monitorizare_ppi.prenume_pacient : "Nespecificat" }} </span>



    <mat-form-field style="width: 100%;" >
        <mat-label>Diagnostic: </mat-label>
        <textarea matInput [(ngModel)]="fisa_monitorizare_ppi.diagnostic_pacient" ></textarea>
    </mat-form-field>


    <mat-form-field style="width: 100%;" >
        <mat-label>Obiectivele actuale: </mat-label>
        <textarea matInput [(ngModel)]="fisa_monitorizare_ppi.obiectivele_actuale" ></textarea>
    </mat-form-field>


<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Grad de indeplinire: </span>



    <mat-form-field style="width: 100%;" >
        <mat-label>Problema identificata: </mat-label>
        <textarea matInput [(ngModel)]="fisa_monitorizare_ppi.grad_de_indeplinire.problema_identificate.continut" ></textarea>
    </mat-form-field>


    <mat-form-field style="width: 100%;" >
        <mat-label>Solutii: </mat-label>
        <textarea matInput [(ngModel)]="fisa_monitorizare_ppi.grad_de_indeplinire.solutii.continut" ></textarea>
    </mat-form-field>

    <mat-form-field style="width: 100%;" >
        <mat-label>Recomandare: </mat-label>
        <textarea matInput [(ngModel)]="fisa_monitorizare_ppi.recomandare" ></textarea>
    </mat-form-field>


    <mat-form-field style="width: 100%;" >
        <mat-label>Rezultate Obtinute: </mat-label>
        <textarea matInput [(ngModel)]="fisa_monitorizare_ppi.rezultate_obtinute" ></textarea>
    </mat-form-field>


<ng-container *ngIf="caz == 1">
  <button mat-raised-button (click)="semneazaRL()" > Semnatura Reprezentant Legal</button>
  <button mat-raised-button (click)="semneazaTerapeut()"  > Semnatura Terapeut</button>
  <button mat-raised-button (click)="openPDFPreviewComponent()" > Previzualizeaza </button>
</ng-container>
<ng-container *ngIf="caz == 2">
  <!-- <button mat-raised-button (click)="openPDFPreviewSsaveModificari()"  > Genereaza Document </button> -->
  <button mat-raised-button color="warn"  mat-dialog-close > Inchide </button>
</ng-container>
