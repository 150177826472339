
<ejs-tab id="element" headerPlacement = "Left" cssClass = "e-fill">
  <e-tabitems >

      <e-tabitem [header]='headerText[0]'>
        <ng-template #content>
          <div class="continut-tab" *ngIf="flag_date_personale_can_load_component">
            <app-form-date-personale
            [flag_date_personale_create]="flag_date_personale_create"
            [date_personale]="date_personale"
            [date_personale_id]="date_personale_id"
            (date_personale_final)="datePersonaleFromComponent($event)"
            (flag_modificat)="flagDatePersonaleModificat($event)"
            ></app-form-date-personale>
            <div class = "e-card" id="CradGradDeDependenta">
             <div class="e-card-header">
               <div class="e-card-header-caption">
                   <div class="e-card-header-title">Grad de dependenta:</div>
               </div>
             <div class="e-card-content">
               Dependent
               <ejs-switch  [checked]="beneficiar.independent" (change)="gradDeDependentaChanged($event)"></ejs-switch>
               Independent
             </div>
            </div>
           </div>
          </div>

         </ng-template>
    </e-tabitem>

    <e-tabitem [header]='headerText[0]' [content]="content0"></e-tabitem>
    <e-tabitem [header]='headerText[1]' [content]="content1"></e-tabitem>
    <e-tabitem [header]='headerText[2]' [content]="content2"></e-tabitem>
    <e-tabitem [header]='headerText[3]' [content]="content2"></e-tabitem>
  </e-tabitems>
</ejs-tab>
