import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarComponentx } from './calendar/calendar.component';
import { InscrieriComponent } from './inscrieri/inscrieri.component';
import { ListaAsteptareComponent } from './lista-asteptare/lista-asteptare.component';
import { NomenclatoareComponent } from './nomenclatoare/nomenclatoare.component';
import { PacientsComponent } from './pacients/pacients.component';
import { ProgramariComponent } from './programari/programari.component';
import { TemplatedocumenteComponent } from './templatedocumente/templatedocumente.component';
import { LoginpageComponent } from './loginpage/loginpage.component';
import { CasierieComponent } from './casierie/casierie.component';
import { TerapiilemeleComponent } from './terapiilemele/terapiilemele.component';
import { DashboardbeneficiariComponent } from './dashboardbeneficiari/dashboardbeneficiari.component';
import { BeneficiarDetailsTabComponent } from './beneficiar-details-tab/beneficiar-details-tab.component';
import { FormDatePersonaleComponent } from './form-date-personale/form-date-personale.component';
const routes: Routes = [
  { path:'pacienti', component: PacientsComponent, pathMatch: 'full' },
  { path:'programari', component: ProgramariComponent, pathMatch: 'full' },
  { path:'lista-asteptare', component: InscrieriComponent, pathMatch: 'full' },
  { path:'inscrieri', component: ListaAsteptareComponent, pathMatch: 'full' },
  { path:'nomenclatoare', component: NomenclatoareComponent, pathMatch: 'full' },
  { path:'templatedocumente', component: TemplatedocumenteComponent, pathMatch: 'full' },
  { path:'text', component: CalendarComponentx, pathMatch: 'full' },
  { path:'login', component: LoginpageComponent, pathMatch: 'full' },
  { path:'casierie', component: CasierieComponent, pathMatch: 'full' },
  { path:'terapiilemele', component: TerapiilemeleComponent, pathMatch: 'full' },
  { path:'dashboard-beneficiari', component: DashboardbeneficiariComponent, pathMatch: 'full' },
  { path:'beneficiari-details-tab', component: BeneficiarDetailsTabComponent, pathMatch: 'full' },
  { path:'form-date-personale', component: FormDatePersonaleComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
