import { Component, OnInit, Inject, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatePipe } from '@angular/common'
import { specialitateMedici,Servicii,Departament, Medici, MediciCreate, MediciDetalii } from 'src/app/models/nomenclatoare.model';
import { ServiciiInterface, specialitateMediciInterface, IntervaleZilniceCreateInterface, MediciDetaliiInterface } from 'src/app/interfaces/nomenclatoare.interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MedicprogramService } from 'src/app/services/medicprogram.service';
import { MediciService } from 'src/app/services/medici.service';
import { IntervalorarmedicService } from 'src/app/services/intervalorarmedic.service';

import {NgModule} from '@angular/core';
@Component({
  selector: 'app-mediciedit',
  templateUrl: './mediciedit.component.html',
  styleUrls: ['./mediciedit.component.css']
})
export class MedicieditComponent implements OnInit {

  detaliiMedicBK : MediciDetaliiInterface = new MediciDetalii()

  // medic: Medici
  medic: any
  specialitateMedici: specialitateMediciInterface[] = []
  servicii: ServiciiInterface[] = [];

  intervaleorarebk: any = null
  programbk: any = null

  displayedColumnsIntervale: string[] = ["interval","edit","intervalselect"]

  intervale_orare_todelete: IntervaleZilniceCreateInterface[] = []
  interval_editat_index: number = -1

  max_width_intervale_card: number = 400
  min_width_intervale_card: number = 200

  inaltime_interval_orar_select:number = 0
  inaltime_randuri_grid_program: number = 28
  row_span_grid_program: number = 1

  programDataSource: any
  displayedColumnsProgram: string[] = ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri','Edit'];

  inaltime_card: number = 400

  intervaleDataSource: any
  interval_orar_start: Date
  interval_orar_end: Date
  inaltime_tabel: number = 0

  // flags
  flag_edit_detalii: boolean = false
  flag_edit_program: boolean = false
  flag_editeaza_interval_orar: boolean = false
  flag_time_pickerele_sunt_setate: boolean = false
  flag_separatoare_time_picker_intervale: boolean = false

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('intervalorastart') intervalorastart:ElementRef
  @ViewChild('adaugaintervalbutton') adaugaintervalbutton:ElementRef
  @ViewChild('separatortimepicker') separatortimepicker:ElementRef
  @ViewChild('tabelintervale') tabelintervale:ElementRef
  @ViewChild('listaadaugaintervale') listaadaugaintervale:ElementRef
  @ViewChild('programpezile') programpezile:ElementRef
  // @ViewChild('intervaldinprogram') intervaldinprogram:ElementRef
  // @ViewChild('zidinprogram') zidinprogram:ElementRef

  @ViewChildren('zidinprogram') ziledinprogram: QueryList<ElementRef>;
  @ViewChildren('separatortimepickerintervale') separatortimepickerintervale: QueryList<ElementRef>;
  @ViewChildren('intervaldinprogram') intervaldinprogram: QueryList<ElementRef>;

  ngAfterViewChecked() {
        // Verific daca este instantiata lista cu id-ul programpezile
        // if (this.programpezile != undefined){
        //   console.log("Program Pe Zile definit 222 ==>> ", this.programpezile)
        //   // daca programpezile este initiat atunci ar trebui sa fie initiat si intervaldinprogram
        //   // pentru ca intervaldinprogram este id-ul tile-urilor de pe randul 2
        // }
        // Verific daca este instantiata lsita cu interval din program (div-urile cu time pickere)
        if(this.flag_edit_program){
          if(this.intervaldinprogram != undefined){

            // console.log("Interval din program definit 222 ==>> ", this.intervaldinprogram)
            // daca interfav din program e definit si programul este in editare
            // verific daca _results are date
            if (this.intervaldinprogram["_results"].length > 0){
              // console.log("interval din program _result are data ==>> ", this.intervaldinprogram["_results"].length)
              // iau o variabila intermediara
              var intermediar_a: number = 0
              // parcurg array-ul _results
              // console.log("interval din program _result  ==>> ", this.intervaldinprogram["_results"])
              for (var i in this.intervaldinprogram["_results"]){

                // console.log(" i in this.intervaldinprogram _results ==>> ", this.intervaldinprogram["_results"][i] )
                // console.log(" i in this.intervaldinprogram _results ==>> ", this.intervaldinprogram["_results"][i].nativeElement )
                // console.log(" i in this.intervaldinprogram _results ==>> ", this.intervaldinprogram["_results"][i].nativeElement.offsetHeight )

                // compar inaltimea fiecarui element cu intermediar_a si retin maximul in intermediar_a
                if (this.intervaldinprogram["_results"][i].nativeElement.offsetHeight > intermediar_a){
                  intermediar_a = this.intervaldinprogram["_results"][i].nativeElement.offsetHeight
                }
                // selectam toate elementele care au clasa .ngx-mat-timepicker form in variabila temporara elements
                var elements = this.elem.nativeElement.querySelectorAll('.ngx-mat-timepicker form');
                // console.log(" Elements ==>> ", elements)
                // console.log(" Elements length ==>> ", elements.length)
                // console.log(" Flagu' cu pickerele ==>> ", this.flag_time_pickerele_sunt_setate)
                // verific daca elements nu este gol si ca sa nu pierdem timpul setam o singura data
                // si marcam flag-ul flag_time_pickerele_sunt_setate cu true pentru ca am trecut prin ele
                if(elements.length > 0 && !this.flag_time_pickerele_sunt_setate){
                  // daca elements are date il parcurg cu un for
                  for (var t = 0; t < elements.lenth; t = t + 1){
                    // verific daca exista element [t]
                    if ( elements[t] != undefined){
                      // daca exista, ii modific min winWidth-ul si width-ul
                      elements[t].style.minWidth = "20px"
                      elements[t].style["width"] = "100%"
                    }
                  }
                  // dupa ce am parcurs for-ul setam this.flag_time_pickerele_sunt_setate true ca sa nu il mai parcurg
                }
                this.flag_time_pickerele_sunt_setate = true
              }
              //in intermediar_a este cea mai mare intaltime din time - pickerele de la editarea programului (cele pe zilele saptamanii in tabel)
              //adaugam 10 pixeli pentru confort si impartim la inaltimea randurilor din gridul program
              intermediar_a = (intermediar_a + 10) / this.inaltime_randuri_grid_program
              //verificam daca se rotunjeste in minus sau in plus (tot ce este sub 0,5 se rotunjeste catre valoare inferioara, etc..)
              //daca se rotunjeste in minus, mai adaugam o unitate ca sa fim siguri ca avem spatiu
              if ( intermediar_a > Math.round(intermediar_a) ){
                intermediar_a = Math.round(intermediar_a) + 1
              }
              else{
                if ( intermediar_a < Math.round(intermediar_a)){
                  intermediar_a = Math.round(intermediar_a)
                }
              }
              //acum ca este rotunjit corespunzator, setam variabila globala row_span_grid_program
              this.row_span_grid_program = intermediar_a
            }
            // daca interval din program nu are date nu facem nimic momentan
            else{

            }

          }
            //daca intervaldinprogram este initiat ar trebui sa fie initiat si separatortimepickerintervale
            //pentru ca este separatorul intre cele doua time pickere
            // verific sa nu fi modificat deja elementele verificand this.flag_separatoare_time_picker_intervale
            if(this.separatortimepickerintervale != undefined && !this.flag_separatoare_time_picker_intervale){
              // console.log("Separator Time Picker definit 222 ==>> ", this.separatortimepickerintervale)
              // daca separatort time picker intervale e definit si flag_separatoare_time_picker_intervale este fals
              // verific daca _results are date
              if ( this.separatortimepickerintervale["_results"].length > 0 && !this.flag_separatoare_time_picker_intervale ){
                // console.log (" Separatoare Time Pciekr Inter _results are date ==>> ",this.separatortimepickerintervale["_results"].length )
                // daca _results are date il parcurg cu un for si schimb style-urile
                for (var p in this.separatortimepickerintervale["_results"] ){
                  this.separatortimepickerintervale["_results"][p].nativeElement.style["font-size"] = "35px"
                  this.separatortimepickerintervale["_results"][p].nativeElement.style["padding-left"] = "10px"
                  this.separatortimepickerintervale["_results"][p].nativeElement.style["padding-right"] = "10px"
                }
                // dupa ce am terminat de parcurs si modificat separatoarele setam this.flag_separatoare_time_picker_intervale
                // true ca sa nu mai parcurgem o data
                this.flag_separatoare_time_picker_intervale = true
              }
              // daca _results nu are date nu facem nimic momentan
              else{

              }
            }
        }
  }

  constructor(
    public dialg: MatDialog,
    public elem: ElementRef,
    public dialogRef: MatDialogRef<MedicieditComponent>,
    public datePipe: DatePipe,
    public programMedicService: MedicprogramService,
    public medicService: MediciService,
    public intervalOrarMedicService: IntervalorarmedicService,

    @Inject(MAT_DIALOG_DATA) private data:any
    ) {
      if (data){
        console.log("Data exista ==>> ", data)
        if(data['medic']){
          this.medic = new Medici()
          this.medic = data['medic']
          console.log("Data medic exista ==>> ", this.medic)
          var intermediar = []
          intermediar.push(this.medic)
          this.programDataSource = new MatTableDataSource(intermediar)
          this.intervaleDataSource = new MatTableDataSource(this.medic.intervale_zilnice)
        }
        if(data['specialitateMedici']){
          console.log("DATA SPECIALITATE MEDICI ==>> ", data['specialitateMedici'])
          this.specialitateMedici = data['specialitateMedici']
          // for (var i of data['specialitateMedici'] ){
            // console.log("I OF DATA SPECIALITATE MEDICI ==>> ", i)
            // this.specialitateMedici.push(i)
          // }
          console.log("Data specialitateMedici exista ==>> ", this.specialitateMedici)
        }
        if(data['servicii']){
          for (var j of data['servicii']){
            this.servicii.push(j)
          }
          console.log("Data servicii exista ==>> ", this.servicii)
        }
      }
    }

  ngOnInit(): void {
        // Verific daca este instantiata lista cu id-ul programpezile
        if (this.programpezile != undefined){
          console.log("Program Pe Zile definit ==>> ", this.programpezile)
          // daca programpezile este initiat atunci ar trebui sa fie initiat si intervaldinprogram
          // pentru ca intervaldinprogram este id-ul tile-urilor de pe randul 2
        }
          if(this.intervaldinprogram != undefined){
            console.log("Interval din program definit ==>> ", this.intervaldinprogram)
          }
            //daca intervaldinprogram este initiat ar trebui sa fie initiat si separatortimepickerintervale
            //pentru ca este separatorul intre cele doua time pickere
            if(this.separatortimepickerintervale != undefined){
              console.log("Separator Time Picker definit ==>> ", this.separatortimepickerintervale)
        }
        this.convertProgramToDate()

        if(this.medic.intervale_zilnice.length > 0){
          console.log("ON Init -> intervale zilnice length ==>> ", this.medic.intervale_zilnice.length)
          this.inaltime_card = (this.medic.intervale_zilnice.length + 1) * 38 + 50
        }

  }


  convertProgramToDate(){
    console.log("Convert program to date")
    this.medic.program.luni.OraStart = new Date(this.medic.program.luni.OraStart)
    this.medic.program.luni.OraEnd = new Date(this.medic.program.luni.OraEnd)
    this.medic.program.marti.OraStart = new Date(this.medic.program.marti.OraStart)
    this.medic.program.marti.OraEnd = new Date(this.medic.program.marti.OraEnd)
    this.medic.program.miercuri.OraStart = new Date(this.medic.program.miercuri.OraStart)
    this.medic.program.miercuri.OraEnd = new Date(this.medic.program.miercuri.OraEnd)
    this.medic.program.joi.OraStart = new Date(this.medic.program.joi.OraStart)
    this.medic.program.joi.OraEnd = new Date(this.medic.program.joi.OraEnd)
    this.medic.program.vineri.OraStart = new Date(this.medic.program.vineri.OraStart)
    this.medic.program.vineri.OraEnd = new Date(this.medic.program.vineri.OraEnd)
  }
  checkIntervaleOrare(start : Date ,end : Date ){
    if (start.getTime() > end.getTime())
    {
      return 0
    }
    return 1
  }
  setIntervalStartEnd(start: Date,end: Date){
    this.interval_orar_start = start
    this.interval_orar_end = end
  }
  afla_durata_serviciului(id:number){
    for ( var i in this.servicii){
      if (this.servicii[i].id == id){
        return this.servicii[i].timp
      }
    }
    return 0
  }
  actualizeazaInaltimeaTabelului(){
    console.log("Inaltimea la inceputul functiei ==>> ", this.inaltime_tabel)
    console.log("Inaltimea tabel intervale offsetheight la inceputul functiei ==>> ", this.tabelintervale["_elementRef"].nativeElement.offsetHeight)
    this.inaltime_tabel = this.tabelintervale["_elementRef"].nativeElement.offsetHeight
  }
  actualizeazaIntervaleDataSource(){
    this.intervaleDataSource = new MatTableDataSource(this.medic.intervale_zilnice)
    this.actualizeazaInaltimeaTabelului()
  }
  addNewInterval(){
    if (this.checkIntervaleOrare(this.interval_orar_start, this.interval_orar_end)){
      var intermediar : IntervaleZilniceCreateInterface ={
        start: this.datePipe.transform(this.interval_orar_start,'HH:mm'),
        end: this.datePipe.transform(this.interval_orar_end,'HH:mm')
      }
      this.setIntervalStartEnd(this.interval_orar_end, new Date(this.interval_orar_end.getTime() + (this.afla_durata_serviciului(this.medic.servicii_id) * 60 * 1000)))
      this.intervalOrarMedicService.addIntervalOrarToServer(this.medic.id,intermediar)
      .subscribe ( (data) => {
        if (data){
          console.log(" DATA FROM ADD INTERVAL ORAR TO SERVER ==>> ", data)
          this.medic.intervale_zilnice.push(data)
          this.actualizeazaIntervaleDataSource()
        }
      })

    }
  }
  // De revenit
  salveazaInterval(){
    if (this.flag_editeaza_interval_orar){
      this.flag_editeaza_interval_orar = false
    }
    if (this.checkIntervaleOrare(this.interval_orar_start, this.interval_orar_end)){
      // console.log("INTERVAL EDITAT INDEX == >> ", this.interval_editat_index)
      // console.log("INTERVAL EDITAT INDEX == >> ", this.medic.intervale_zilnice[this.interval_editat_index].id)
      this.intervalOrarMedicService.updateIntervalOrar(this.medic.intervale_zilnice[this.interval_editat_index].id, {'start' : this.datePipe.transform (this.interval_orar_start,"HH:mm"),'end' : this.datePipe.transform (this.interval_orar_end,"HH:mm")})
      .subscribe ( (data) => {
        if (data){
          console.log("DATA DIN UPDATE INTERVAL PE SERVER == >> ", data)
          this.medic.intervale_zilnice[this.interval_editat_index].start = this.datePipe.transform (this.interval_orar_start,"HH:mm")
          this.medic.intervale_zilnice[this.interval_editat_index].end = this.datePipe.transform (this.interval_orar_end,"HH:mm")
          this.actualizeazaIntervaleDataSource()
          var intermediar_x = this.obtineOraSiMinuteleSeparate(this.medic.intervale_zilnice[this.medic.intervale_zilnice.length-1])
          this.interval_orar_start = new Date(new Date().setHours(intermediar_x.end_ora,intermediar_x.end_minute,0))
          this.interval_orar_end = new Date(this.interval_orar_start.getTime() + (this.afla_durata_serviciului(this.medic.servicii_id) * 60 * 1000))
        }
      })
    }
  }

  // De revenit

  obtineOraSiMinuteleSeparate(interval_orar: IntervaleZilniceCreateInterface){
    var start_ora: number
    var start_minute: number
    var end_ora: number
    var end_minute: number
    // console.log("obtine ora si minute ==>> ", interval_orar)
    // console.log("obtine ora si minute index-ul lui : ==>> ", interval_orar.start.indexOf(":"))
    start_ora = Number(interval_orar.start.substring(0,interval_orar.start.indexOf(":")))
    // console.log("obtine ora start  ==>> ", start_ora )
    start_minute = Number(interval_orar.start.substring(interval_orar.start.indexOf(":")+1, interval_orar.start.length))
    // console.log("obtine minute start  ==>> ", start_minute )
    end_ora = Number(interval_orar.end.substring(0,interval_orar.end.indexOf(":")))
    // console.log("obtine ora end  ==>> ", end_ora )
    end_minute = Number(interval_orar.end.substring(interval_orar.end.indexOf(":")+1, interval_orar.end.length))
    // console.log("obtine minute end  ==>> ", end_minute )
    return {start_ora,start_minute,end_ora,end_minute}
  }

  // De revenit
  editeazaInterval(interval_orar: IntervaleZilniceCreateInterface){
    // console.log("Editeaza interval ==>> ", interval_orar)
    this.interval_editat_index = this.medic.intervale_zilnice.indexOf(interval_orar)
    // console.log("Editeaza interval index or interval orar in this.intervale_orare ==>> ", this.interval_editat_index)
    var intermediar = this.obtineOraSiMinuteleSeparate(interval_orar)
    // console.log(" Return de la Obtine ora si minutul == >>",intermediar)
    this.flag_editeaza_interval_orar = true
    // this.interval_orar_start = new Date(interval_orar.start)
    this.interval_orar_start = new Date(new Date().setHours(intermediar.start_ora,intermediar.start_minute))
    this.interval_orar_end = new Date(new Date().setHours(intermediar.end_ora,intermediar.end_minute))
    // console.log("interval_orar start ==>> ",  interval_orar.start)
    // console.log("Date interval_orarl ==>> ", new Date(interval_orar.start))
  }
  marktodelete( interval_orar ){
    console.log("mark to delete ==>> ", interval_orar)
    this.intervale_orare_todelete.push(interval_orar)
  }

  deleteIntervaleOrareDinDataSource(lista_ids){
    let intermediar = []
    for (let i of this.medic.intervale_zilnice){
      if ( lista_ids.indexOf(i.id) > -1 ){
        console.log("Acest element trebuie sters !! == >> ", i)
      }
      else {
        intermediar.push(i)
      }
    }
    console.log("Intervale Orare Finale == >> ", intermediar)
  }

  deleteIntervaleOrare(){
    var intermediar: number[] = []
    var intermediar_deleted: number[] = []
    for( let q of this.intervale_orare_todelete){
      console.log(" Q == >> ", q['id'])
      this.intervalOrarMedicService.stergeIntervalOrarFromServer(q['id'])
      .subscribe ( (data) => {
        if (data){
          console.log("DATA FROM DELETED INTERVAL ORAR == >> ", data)
          intermediar_deleted.push(data.id)
          if (intermediar_deleted.length == this.intervale_orare_todelete.length){
            console.log("Au fost sterse toate intervalele !! ")
            this.deleteIntervaleOrareDinDataSource(intermediar_deleted)
          }
        }
      })
    }
    for ( var i in this.medic.intervale_zilnice){
      for ( var y in this.intervale_orare_todelete ){
        if (this.medic.intervale_zilnice[i] === this.intervale_orare_todelete[y]){
          intermediar.push( Number(i))
        }
      }
    }
    if (intermediar.length > 0){
      var intermediar_two : IntervaleZilniceCreateInterface[] = []
      for (var x in this.medic.intervale_zilnice){
        var contor:boolean = false
          for ( var j in intermediar){
          if ( Number(x) == intermediar[j]){
            contor = true
          }
        }
        if (!contor){
          intermediar_two.push(this.medic.intervale_zilnice[x])
        }
      }
      this.medic.intervale_zilnice = intermediar_two
      console.log(" intervale_orare dupa stergere ==>> ", this.medic.intervale_zilnice)
      this.intervale_orare_todelete = []
      this.actualizeazaIntervaleDataSource()

      var intermediar_calcul_inaltime_two = Math.max(this.inaltime_interval_orar_select,this.inaltime_tabel)+30

      if (intermediar_calcul_inaltime_two < this.listaadaugaintervale["height"]){
        console.log(" intermediar_calcul_inaltime_two < this.listaadaugaintervale[height] ==>> ", intermediar_calcul_inaltime_two, "<< == lista adauga intervale [height] == >> ", this.listaadaugaintervale["height"])
        if (intermediar_calcul_inaltime_two <= this.max_width_intervale_card){
          this.inaltime_card = intermediar_calcul_inaltime_two
          console.log("inaltime tabel dupa daos interva ==>> ", this.inaltime_tabel)
        }
      }
      // console.log(" this.intervale_orare dupa stergere ==>> ", this.intervale_orare)
    }
  }

  editDetalii(){
    // console.log("KEYLE MEDIC DETAILS BK ==>> ", Object.keys(this.detaliiMedicBK))
    // console.log("MEDIC ==>> ", this.medic)
    // console.log("KEYLE MEDIC ==>> ", Object.keys(this.medic))
    for ( let i of Object.keys(this.detaliiMedicBK)){
      // console.log("i Keie a lui MEDIC DETAILS BK ==>> ", i)
      if (Object.keys(this.medic).indexOf(i) != -1 ){
        // console.log("KEYA = ",i, " SE AFLA SI IN MEDIC ==>> ")
        // console.log("BK DE I = ",this.detaliiMedicBK.i, " << == MEDIC ==>> ", this.medic.i)
        // console.log("BK DE I = ",this.detaliiMedicBK[i], " << == MEDIC ==>> ", this.medic[i])

        this.detaliiMedicBK[i] = this.medic[i]

        // console.log("BK DE I 22 = ",this.detaliiMedicBK[i], " << == MEDIC ==>> ", this.medic[i])

      }
      else{
        // console.log("KEYA NU SE REGASESTE IN MEDIC")
      }
    }
    this.flag_edit_detalii = true
  }
  checkModificariDetalii(){
    for ( let i of Object.keys(this.detaliiMedicBK)){
      if (Object.keys(this.medic).indexOf(i) != -1 ){
        if ( this.detaliiMedicBK[i] != this.medic[i] ){
          return 1
          // console.log( " KEIA = ", i, " DIFERA DE LA BK LA SALVAT == >> ", this.detaliiMedicBK[i], "<< == SALVAT == >> ", this.medic[i])
        }
      }
      else{
        // console.log("KEYA NU SE REGASESTE IN MEDIC")
      }
    }
    return 0
  }
  changeServicii(variabila){
    console.log ("check Specialitate == >> ", variabila)
    // console.log ("check Specialitate == >> ID ==>> ", variabila.id)
    console.log ("check Specialitate this.medic == >> ", this.medic)
    this.medic.servicii_id = variabila
    for (let q of this.servicii){
      if (q.id == variabila){
        this.medic.servicii = q
      }
    }
    console.log ("check Servicii this.medic == >> ", this.medic)
  }

  changeSpecialitate(variabila){
    // console.log ("check Specialitate == >> ", variabila)
    // console.log ("check Specialitate == >> ID ==>> ", variabila.id)
    // console.log ("check Specialitate this.medic == >> ", this.medic)
    this.medic.specialitatea_id = variabila
    for (let q of this.specialitateMedici){
      if (q.id == variabila){
        this.medic.specialitate = q
      }
    }
    // console.log ("check Specialitate this.medic == >> ", this.medic)
  }
  saveDetalii(){
    if (this.checkModificariDetalii()){
      console.log("Detaliile sunt diferite")
      for ( let i of Object.keys(this.detaliiMedicBK)){
        if (Object.keys(this.medic).indexOf(i) != -1 ){
          this.detaliiMedicBK[i] = this.medic[i]
        }
      }
      this.medicService.updateMediciToServer(this.medic.id,this.detaliiMedicBK)
      .subscribe ( (data) => {
        if (data){
          console.log ("DATA FROM UPDATE MEDIC TO SERVER == >> ", data)
        }
      })
    }
    else{
      console.log('Detaliile sunt la fel')
    }
    this.flag_edit_detalii = false
  }
  checkZiModificata(originalValue, newValue){
    // console.log(" ORIGINAL VALUE ==>> Ora start  ==>> ", originalValue.OraStart)
    // console.log(" ORIGINAL VALUE DATA ==>> Ora start  ==>> ", new Date(originalValue.OraStart))
    // console.log(" ORIGINAL VALUE DATA  ==>> GET TIME ==>> Ora start  ==>> ", new Date(originalValue.OraStart).getTime())
    // console.log(" NEW VALUE ==>> Ora start  ==>> ", newValue.OraStart)
    // console.log(" NEW VALUE ==>> Ora start  ==>> ", newValue.OraStart.toString())
    // console.log(" NEW VALUE ==>> GET TIME  ==>> Ora start  ==>> ", newValue.OraStart.getTime())
    // console.log(" ORIGINAL VALUE ==>> Ora start == NEW VALUE ==>> Ora start  ==>> ", originalValue.OraStart == newValue.OraStart)
    // console.log(" ORIGINAL VALUE ==>> Ora start == NEW VALUE ==>> Ora start  22 ==>> ", originalValue.OraStart === newValue.OraStart)
    // console.log(" DATA OF ORIGINAL VALUE ==>> Ora start == NEW VALUE ==>> Ora start  ==>> ", new Date(originalValue.OraStart) == newValue.OraStart)
    // console.log(" DATA OF ORIGINAL VALUE ==>> Ora start == NEW VALUE ==>> Ora start  22 ==>> ", new Date(originalValue.OraStart) === newValue.OraStart)
    // console.log(" GET TIME DATA OF ORIGINAL VALUE ==>> Ora start == GET TIME NEW VALUE ==>> Ora start  ==>> ", new Date(originalValue.OraStart).getTime() == newValue.OraStart.getTime())
    // console.log(" GET TIME DATA OF ORIGINAL VALUE ==>> Ora start == GET TIME NEW VALUE ==>> Ora start 22 ==>> ", new Date(originalValue.OraStart).getTime() === newValue.OraStart.getTime())

    if(new Date(originalValue.OraStart).getTime() != newValue.OraStart.getTime()){
      // console.log("Ora start diferite ==>> ", originalValue.OraStart, " << == NEW VALUE == >> ", newValue.OraStart)
      return 1
    }
    else{
      // console.log("Ora start NU SUNT diferite ==>> ", new Date(originalValue.OraStart), " << == NEW VALUE == >> ", newValue.OraStart)
    }
    if(new Date(originalValue.OraEnd).getTime() != newValue.OraEnd.getTime()){
      // console.log("Ora END diferite ==>> ", new Date(originalValue.OraEnd), " << == NEW VALUE == >> ", newValue.OraEnd)
      return 1
    }
    else{
      // console.log("Ora END NU SUNT diferite ==>> ", new Date(originalValue.OraEnd), " << == NEW VALUE == >> ", newValue.OraEnd)
    }
    return 0
  }

  saveProgram(){
    this.flag_edit_program = false
    // console.log("PROGRAM MEDIC DUPA SAVE ==>> ", this.medic.program)
    if (this.checkZiModificata(this.programbk.luni, this.medic.program.luni)){
      this.programMedicService.updateZiProgramToServer(this.medic.program.luni, this.medic.program.luni_id)
      .subscribe ( (data) => {
        if (data){
          console.log("DATA FROM UPDATE ZI DIN PROGRAM TO SERVER == >> LUNI == >> ", data)
        }
      })
    }
    if (this.checkZiModificata(this.programbk.marti, this.medic.program.marti)){
      this.programMedicService.updateZiProgramToServer(this.medic.program.marti, this.medic.program.marti_id)
      .subscribe ( (data) => {
        if (data){
          console.log("DATA FROM UPDATE ZI DIN PROGRAM TO SERVER == >> MARTI == >>", data)
        }
      })
    }
    if (this.checkZiModificata(this.programbk.miercuri, this.medic.program.miercuri)){
      this.programMedicService.updateZiProgramToServer(this.medic.program.miercuri, this.medic.program.miercuri_id)
      .subscribe ( (data) => {
        if (data){
          console.log("DATA FROM UPDATE ZI DIN PROGRAM TO SERVER == >> MIERCURI == >>", data)
        }
      })
    }
    if (this.checkZiModificata(this.programbk.joi, this.medic.program.joi)){
      this.programMedicService.updateZiProgramToServer(this.medic.program.joi, this.medic.program.joi_id)
      .subscribe ( (data) => {
        if (data){
          console.log("DATA FROM UPDATE ZI DIN PROGRAM TO SERVER == >> JOI == >>", data)
        }
      })
    }
    if (this.checkZiModificata(this.programbk.vineri, this.medic.program.vineri)){
      this.programMedicService.updateZiProgramToServer(this.medic.program.vineri, this.medic.program.vineri_id)
      .subscribe ( (data) => {
        if (data){
          console.log("DATA FROM UPDATE ZI DIN PROGRAM TO SERVER == >> VINERI == >>", data)
        }
      })
    }
  }

  editProgram(){
    this.programbk = JSON.parse(JSON.stringify(this.medic.program))
    // console.log("PROGRAM BK ==>> ", this.programbk)
    // console.log("PROGRAM BK LUNI ORA START ==>> ", new Date(this.programbk.luni.OraStart))
    // console.log("PROGRAM MEDIC ==>> ", this.medic.program)
    this.flag_edit_program = true
  }

  openProgramEdit(variabila){

  }

  saveEditedMedic(){
    console.log("Edited Medic ==>> ", this.medic)
    this.dialogRef.close(this.medic)
  }

}
