import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatePipe } from '@angular/common'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import { MatInput } from '@angular/material/input';
import { IntervalService } from '../../services/interval.service'
import {MatSnackBar} from '@angular/material/snack-bar';
import { TipDepartament, TipDepartamentCreate, ValabilitateCreate } from 'src/app/models/nomenclatoare.model';
import { TipDepartamentService } from 'src/app/services/tipdepartament.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'd',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-tipdepartamentedit',
  templateUrl: './tipdepartamentedit.component.html',
  styleUrls: ['./tipdepartamentedit.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class TipdepartamenteditComponent implements OnInit {

  tipdepartament = new TipDepartament()
  start: Date
  end: Date
  constructor(public tipDepartamentService: TipDepartamentService, private _snackBar: MatSnackBar,public intervalService: IntervalService,public datepipe: DatePipe,public dialogRef: MatDialogRef<TipdepartamenteditComponent>, public dialg: MatDialog,@Inject(MAT_DIALOG_DATA) private data:any) {
    if(data){
      this.tipdepartament = data
      if (this.tipdepartament.arevalabilitate){
        this.start = new Date( this.datepipe.transform(new Date(this.tipdepartament.valabilitate.start),"MM.dd.YYYY") )
        this.end = new Date( this.datepipe.transform(new Date(this.tipdepartament.valabilitate.end),"MM.dd.YYYY") )
      }
    }
  }

  ngOnInit(): void {
  }
  valabilitatestatuschange(v){
    console.log("status v =>", v)
    this.tipdepartament.arevalabilitate = v
  }
  startdateset(v) {
    let vd2 = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    let intermediar = this.datepipe.transform(new Date(vd2),"MM.dd.YYYY")
    this.start = new Date(intermediar)
  }

  enddateset(v) {
    let vd = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    let intermediar = this.datepipe.transform(new Date(vd),"MM.dd.YYYY")
    this.end = new Date(intermediar)
    return vd
  }
  @ViewChild('startDate',{
    read:MatInput
  }) startDate:MatInput;
  @ViewChild('endDate',{
    read:MatInput
  }) endDate:MatInput;
  updatevalues(){
    if(this.tipdepartament.arevalabilitate){
      let interval = new ValabilitateCreate()
      interval.format_type = this.tipdepartament.valabilitate.format_type
      interval.start = this.start.toString()
      interval.end = this.end.toString()
      this.intervalService.updateInterval(this.tipdepartament.valabilitateid, interval)
      .subscribe( (data) => {
        if (data){
          console.log("Data from update Interval ==>> ", data)
        }
      })
    }
    let newtipdepartament = new TipDepartamentCreate()
    for ( let key in newtipdepartament){
      newtipdepartament[key] = this.tipdepartament[key]
    }
    this.tipDepartamentService.updatetoserver(this.tipdepartament.id, newtipdepartament)
    .subscribe( (data) => {
      if (data){
        console.log("Data from update Tip Departament ==>> ", data)
      }
    })
    this._snackBar.open("Tip Departament actualizat","Inchide")
  }
}
