import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GridComponent, RowSelectEventArgs, FilterService, PageService,ToolbarService, EditService, QueryCellInfoEventArgs} from '@syncfusion/ej2-angular-grids';
import { PacientCreateInterface, PacientTwoServerInterface } from '../interfaces/pacient.interface';
import { PacientiService } from '../services/pacienti.service';
import { DashboardLayoutComponent, PanelModel } from '@syncfusion/ej2-angular-layouts';
import {  SortService,ReorderService, ResizeService } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { BeneficiarDetailsTabComponent } from '../beneficiar-details-tab/beneficiar-details-tab.component';
import { PacientCreate, PacientTwoServer } from '../models/pacientdetails.model';

@Component({
  selector: 'app-dashboardbeneficiari',
  templateUrl: './dashboardbeneficiari.component.html',
  providers: [FilterService, PageService,ToolbarService, EditService, SortService,ReorderService,ResizeService],
  styleUrls: ['./dashboardbeneficiari.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardbeneficiariComponent implements OnInit {
  public lista_Beneficiari: PacientTwoServerInterface[] = []
  public test
  public pageSettings: object;
  public beneficiar : PacientCreateInterface = new PacientCreate()
  @ViewChild('grid') public Grid: GridComponent;
  @ViewChild('default_dashboard') public dashboard: DashboardLayoutComponent;

  @ViewChild('ejDialog') ejDialog: DialogComponent;
  // Create element reference for dialog target element.
  @ViewChild('container', { read: ElementRef, static: true }) container: ElementRef;
  // The Dialog shows within the target element.
  public targetElement: HTMLElement;
  public flag_dialog_pacient_open : boolean = false
  public flag_dialog_pacient_can_open : boolean = false

  public count: number = 8;
  // Set Dialog position
  public position: PositionDataModel ={ X: 'center', Y: 'center' };
  public cellSpacing: number[] = [10, 10];
  public cellAspectRatio: number = 100/10;
  public panels: any = [{ "sizeX": 1, "sizeY": 1, "row": 0, "col": 0, content:'<div class="content">0</div>' },
  { "sizeX": 3, "sizeY": 2, "row": 0, "col": 1, content:'<div class="content">1</div>' },
  { "sizeX": 1, "sizeY": 3, "row": 0, "col": 4, content:'<div class="content">2</div>' },
  { "sizeX": 1, "sizeY": 1, "row": 1, "col": 0, content:'<div class="content">3</div>' },
  { "sizeX": 2, "sizeY": 1, "row": 2, "col": 0, content:'<div class="content">4</div>' },
  { "sizeX": 1, "sizeY": 1, "row": 2, "col": 2, content:'<div class="content">5</div>' },
  { "sizeX": 1, "sizeY": 1, "row": 2, "col": 3, content:'<div class="content">6</div>' }
  ];


  constructor(
    public pacientiService: PacientiService
  ) { }
  public onOpenDialog = function(event: any): void {
  console.log( " SELECTED ROW RECORDS xxx == >> ", this.beneficiar)
  // Call the show method to open the Dialog this.flag_dialog_pacient_open
    // console.log("targetElement DIN Open Dialog ==>> ", this.container.nativeElement.parentElement.parentNode.childNodes)
    this.flag_dialog_pacient_open = true
    this.ejDialog.show();
    };
  onCloseIconHandler(event: any): void {
    if ((<HTMLElement>event.target).offsetParent) {
        this.dashboard.removePanel((<HTMLElement>event.target).offsetParent.id);
    }
}
  // Sample level code to hide the Dialog when click the Dialog overlay
  public onOverlayClick: EmitType<object> = () => {
    this.flag_dialog_pacient_open = false
    this.ejDialog.hide();
    }

    ngAfterViewInit():void{
      // this.initilaizeTarget();

    }
  ngOnInit(): void {
    this.test = this.pacientiService.getPacientifromServertest()
    // .subscribe ( val => console.log("VAR DIN OBSERVABLE ==>> ", val))
    .subscribe ( val => {
      console.log("VAR DIN OBSERVABLE ==>> ", val);
      this.lista_Beneficiari = val
      this.flag_dialog_pacient_can_open = true
    })
    this.pageSettings = { pageSizes: true, pageSize: 5 };


    // this.lista_Beneficiari = await this.pacientiService.getPacientifromServerTwoById(this.pacient.id)
    // .toPromise();

    // if (this.lista_Beneficiari.orar_de_somn){
    //   if (!this.lista_Beneficiari.intervale_indisponibilitate){
    //     this.lista_Beneficiari.orar_de_somn = false
    //     this.lista_Beneficiari.intervale_indisponibilitate = []
    //   }
    //   else{
    //     if (this.lista_Beneficiari.intervale_indisponibilitate.length == 0){
    //       this.lista_Beneficiari.orar_de_somn = false
    //     }
    //   }
    // }
    // else{
    //   if (this.lista_Beneficiari.intervale_indisponibilitate){
    //     if (this.lista_Beneficiari.intervale_indisponibilitate.length > 0){
    //       this.lista_Beneficiari.orar_de_somn = true
    //     }
    //   }
    // }
  }

    // Initialize the Dialog component target element.
    public initilaizeTarget: EmitType<object> = () => {
      this.targetElement = this.container.nativeElement;
    // this.ejDialog.hide();
  }

  load() {
    console.log("PACIENT LIST : => ",this.lista_Beneficiari)
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    // const pageSize: number = this.Grid.pageSettings.pageSize;   // initial page size
    const pageSize: number = 10;   // initial page size
    // const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
    // this.Grid.pageSettings.pageSize = pageSize + Math.round(pageResize);
}
rowSelected(args: RowSelectEventArgs) {
  // this.flag_dialog_pacient_open = true
  const selectedrecords: object[] = this.Grid.getSelectedRecords();  // Get the selected records.
  console.log( " SELECTED ROW RECORDS == >> ", selectedrecords)

  this.beneficiar = selectedrecords[0] as PacientCreateInterface
  console.log( " SELECTED ROW RECORDS xx == >> ", this.beneficiar)
  this.onOpenDialog(args)
  // this.initilaizeTarget();
  const selectedrowindex: number[] = this.Grid.getSelectedRowIndexes();  // Get the selected row indexes.
  // alert(selectedrowindex); // To alert the selected row indexes.
  // if (this.flag_dialog_pacient_can_open){
  //   this.initilaizeTarget();
  // }


}
testfunct(argmt, levels, current_level : number = 0, cont : string[]){
  console.log( " INTRU IN RECURSIVITATE CU PARAMS -->> ", argmt, " // ==>> ",levels, " // ==>> ",current_level, " // ==>> ",cont, " ->||<- ")
  if (cont.length > 0){
    if (cont.length == levels){
      if (current_level < levels - 1){
        if (cont[current_level] in argmt){
            console.log( " APELEZ RECURSIVITATE CU PARAMETRII -->> ", argmt[cont[current_level]], " // ==>> ",levels, " // ==>> ",current_level+1, " // ==>> ",cont, " ->||<- ")
            return this.testfunct(argmt[cont[current_level]],levels, current_level+1,cont)
        }
        else{
          console.log( " KEY ==>>", cont[current_level], " NU SE AFLA IN ARGMT ==>> ", argmt)
          return false
        }argmt
      }
      else{
        if (current_level == levels - 1){
          if (argmt[cont[current_level]] != null){
            console.log( " SUCCES - am ajuns pe ultimul nivel si nu este NULL ")
            return true
          }
          else{
            console.log( " FAIL - am ajuns pe ultimul nivel si ESTE !! NULL ")

            return false
          }
        }
      }
    }
    else{
      console.log( "EROARE - Lungimea Parametrilor este diferita de numarul de niveluri " )
      return false
      //EROARE, CONT TREBUIE SA FIE EGAL CU LEVELS
    }
  }
  console.log( "EROARE - Lungimea Parametrilor este ZERO " )
  return false
}

ngOnDestroy() {
  console.log('Dashboard Beneficiari :OnDestroy');
}
customiseCell(args: QueryCellInfoEventArgs) {
  if (args.column.field != "id"){
    let intermediar_split_values = args.column.field.split('.')


  //  console.log(this.testfunct(args.data,intermediar_split_values.length,0,intermediar_split_values))

    // console.log("Field ==>> ", args.column.field)
    // console.log("Field SPLITTED ==>> ", intermediar_split_values)
    // console.log("Field DATA ==>> ", args.data[args.column.field])
    // console.log("Field DATA FULL ==>> ", args.data)
    // console.log("Field DATA FULL de 0 !! ==>> ", args.data[intermediar_split_values[0]][intermediar_split_values[1]])
    // console.log("Field ARGS FULL ==>> ", args)

    if (args.data[intermediar_split_values[0]][intermediar_split_values[1]] == undefined || args.data[intermediar_split_values[0]][intermediar_split_values[1]] == null) {
      console.log (" Este gol !!! ", args.data['id'])
      args.cell.innerHTML = " - "
      return (" - ")
    }
    return (args.data[args.column.field])
  }
  }

}
