<span>Detalii Pacient</span>
<br>
Nume Pacient: {{pacient.date_personale.nume + " " + pacient.date_personale.prenume}}
<br>
<strong>
    <mat-grid-list  cols="3" rowHeight="50px"  [style.border] = '30' >
      <mat-grid-tile class = "bordurica-mat-grid-list"  >
         {{ (pacient.orar_de_somn)?("Interval de somn: " + (pacient.program_de_somn[0].start | date:'H:mm') + " - " + (pacient.program_de_somn[0].end | date:'H:mm') ):"Nu are un program de somn" }}
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list">
        {{ (pacient.date_contact.telefon)?("Telefon: " + pacient.date_contact.telefon):'' }}
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list">
        {{ (pacient.date_contact.email)?("Email: " + pacient.date_contact.email):'' }}
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list">
        {{ (pacient.independent)?("Gradul de dependenta: Independent"):("Gradul de dependenta: Dependent") }}
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list">
        {{ (pacient.greutate)?("Greutatea: " + pacient.greutate + " kg"):"" }}
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list">
        {{ (pacient.inaltime)?("Inaltime: " + pacient.inaltime + " cm"):"" }}
      </mat-grid-tile>
    </mat-grid-list>
</strong>
<br>
Status Programare:
<br>
<br>
<br>
<!-- <countdown-timer [end]="'2018-01-01 00:00:00'"></countdown-timer> -->
<button mat-raised-button color="primary" >Confirmat</button>
<button mat-raised-button color="primary" >Sosit</button>
<button mat-raised-button color="primary" >Absent</button>
<button mat-raised-button color="primary" >Anuleaza</button>
<br>
<button mat-raised-button color="primary" (click)="startprocedura()" cdkFocusInitial>Incepe procedura</button>
<br>
<div *ngIf="opencountdown">
  <br>
  <h2>
    <b>
      <!-- <countdown [config]="{ leftTime: 10 }"></countdown> -->
      <countdown [config]="{ leftTime: duratainsecunde }"></countdown>
    </b>
  </h2>
</div>

<div mat-dialog-actions>
    <button mat-raised-button color="warn" mat-dialog-close >Cancel</button>
    <button mat-raised-button color="primary" (click)="openconsult()" cdkFocusInitial>Deschide consult</button>
</div>
