import { Injectable } from '@angular/core';
import { TipDepartament, TipDepartamentCreate, TipDepartamentCreateFaraValabilitate , Valabilitate, ValabilitateCreate } from '../models/nomenclatoare.model';
import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {catchError} from 'rxjs/operators/catchError';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ValabilitateInterface, TipDepartamentInterface, TipDepartamentCreateInterface, TipDepartamentCreateFaraValabilitateInterface } from '../interfaces/nomenclatoare.interface';
import { IntervalService } from './interval.service'
import { serverurl } from './server.service';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
  providedIn: 'root'
})
export class TipDepartamentService {
  public tipDepartament: TipDepartament [] = []
  public localstorageflag = 0;
  // private server_url="api/tipdepartament";
  private server_url=serverurl+"/api/v1/tipuridedepartamente/";
  // private server_url="http://localhost/api/v1/tipuridedepartamente/";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    public intervalService: IntervalService,
    private localStorageService: LocalStorageService
    ) {
      let data = this.localStorageService.get("TipDepartament");
      // console.log("On Initi DAta Local storage => ",data)
      if (data)
      {
        this.localstorageflag = 1;
        this.tipDepartament = data;
        this.tipDepartament.forEach(element=> {
          this.http.post<TipDepartament>(this.server_url, element, cudOptions).pipe(
            catchError(this.handleError)
              )
            });
        }
      else {
        // console.log("PacientList nu este Local")
      }
    }

  getTipDepartament(){
    return this.tipDepartament
  }

  getfromserver() {
    return this.http.get<TipDepartamentInterface[]>(this.server_url)
  }
  deletefromserver(id){
    return this.http.delete(this.server_url+id)
  }
  updatetoserver(id,departament){
    return this.http.put(this.server_url+id, departament)
  }
  addTipDepartamentCuValabilitate(tipdepartament: TipDepartamentCreateInterface){
    console.log("intru in add post request")
    console.log("categorie de servicii service -> addhero -> hero => ", tipdepartament)
    let toate_detaliile: TipDepartamentCreateInterface = new TipDepartament()
    return(this.http.post<any>(this.server_url,tipdepartament,this.httpOptions))
  }

  addTipDepartamentFaraValabilitate(tipdepartament: TipDepartamentCreateFaraValabilitateInterface){
    console.log("intru in add post request fara valabilitate")
    console.log("categorie de servicii service -> addtipdepartament fara valabilitate -> tipdepartament => ", tipdepartament)
    return (this.http.post<any>(this.server_url,tipdepartament,this.httpOptions))
  }

  addtipdepartament(categorie){
    this.tipDepartament.push(categorie);
    if (this.localstorageflag){
      this.localStorageService.remove("TipDepartament");
      this.addtolocalStorage(this.tipDepartament);
    }
    else {
      this.addtolocalStorage(this.tipDepartament);
    }
    console.log("Lista pacienti din pacienti service =>", this.tipDepartament);

    return this.http.post<TipDepartament>(this.server_url, categorie, cudOptions).pipe(
      catchError(this.handleError)
    );
  }

  addtolocalStorage(PacientList){
    console.log("addtolocalstorage CategorieServicii: =>", PacientList);
    console.log("Addtolocalstorage",this.localStorageService.set("TipDepartament", PacientList));
  }
  private handleError(error: Response){
    console.log("Error from Pacient Service: => " + error)
    return Observable.throw("500 internal server error" )
  }
}
