import { ConsultCreateInterface } from "../interfaces/consult.interface";




export class ConsultCreate implements ConsultCreateInterface{
  constructor(
    public idPacient ?: number,
    public denumire : string = '',
    public idEvaluare ?: number,
    public idFisier : string = '',
    public dataAdaugare : string = '',
    public dataJSON ?: any,
  ){}
}

// constructor(
//   public nume : string = 'Nume Pacient',
//   public prenume : string = 'Prenume Pacient',
//   public pacientid ?: number,
//   public data_nasterii : string = 'Data Nasterii Pacient',
//   public varsta_cronologica : string = 'Varsta Pacient',
//   public evaluator : string = 'Nume Penume Evaluator',
//   public conditii_de_testare : string = '',
//   public performanta_adevarata_a_copilului : boolean = true,
//   public comentarii ?: string,
//   public gnfm_66_scor ?: string,
//   public gnfm_66_scor_vechi ?: string,
//   public gnfm_66_schimbari ?: string,
//   public performanta : string = "DA",
//   public rolator ?: number,
//   public cadru_de_mers ?: number,
//   public carje_subalixare ?: number,
//   public carja_cu_4_pic ?: number,
//   public carja ?: number,
//   public mijlocnimic ?: number,
//   public ort_sold ?: number,
//   public ort_genunchi ?: number,
//   public ort_glezna_pic ?: number,
//   public ort_picior ?: number,
//   public pantofi_ortopedici ?: number,
//   public ortnimic ?: number,
//   public mijlocaltele ?: string,
//   public ortaltele ?: string,
//   public ignora_campurile_goale : boolean = true,
//   public sectiunea_a : CampSectiune[] = [],
//   public sectiunea_b : CampSectiune[] = [],
//   public sectiunea_c : CampSectiune[] = [],
//   public sectiunea_d : CampSectiune[] = [],
// ){}
