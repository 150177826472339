import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { Subject } from 'rxjs';
import { PDFViewerDialogService } from '../services/pdfviewerdialog.service';

@Component({
  templateUrl: './pdfpreviewer.component.html',
  styleUrls: ['./pdfpreviewer.component.css']
})
export class PdfpreviewerComponent implements OnInit {
  public base64 : string
  public caz : number = 1
  fileUrl;
  constructor(
    private sanitizer: DomSanitizer,
    private printService: NgxExtendedPdfViewerService,
    public pdfViewerFromTabelService : PDFViewerDialogService,
    @Inject(MAT_DIALOG_DATA) private data:any,
    public matDialogRef: MatDialogRef<PdfpreviewerComponent>
    ) {
    if (data){
      // console.log("Data in PDF PREVIEWER ==>> ", data)
      this.base64 = data
    }
   }

  ngOnInit(): void {
    if (this.pdfViewerFromTabelService.getStatusViewerFromTabel()){
      this.caz = 2
    }
  }

  async save(){
    const blob = await this.printService.getCurrentDocumentAsBlob()
    if (blob) {
      // this.downloaded = 'The BLOB contains ' + blob.size + ' byte.';
      console.log('The BLOB contains ' + blob.size + ' byte.')
      var filetodownload = new Blob([blob], { type:  'application/pdf' });
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = 'Evaluare.pdf';
      a.click();
      // window.open(window.URL.createObjectURL(blob));
    } else {
      console.log('download failed')
      // this.downloaded = 'download failed';
    }
    this.matDialogRef.close(true);
  }

}
