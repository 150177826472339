import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { logo_url_base64 } from 'src/app/models/logo-base64.model';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { DatePipe } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { FileService } from 'src/app/services/file.service';
import { EvaluareMersInterface } from 'src/app/interfaces/documente.interface';
import { EvalserviceService } from 'src/app/services/evalservice.service';
import { EvaluareMers } from 'src/app/models/documente.models';
import { PinentryComponent } from 'src/app/pinentry/pinentry.component';
import { ConsultCreate } from 'src/app/models/consult.model';
import { ConsultCreateInterface } from 'src/app/interfaces/consult.interface';
import { ConsultatiiService } from 'src/app/services/consultatii.service';
import { PdfpreviewerComponent } from 'src/app/pdfpreviewer/pdfpreviewer.component';
import { FileElementCls } from 'src/app/models/fisier.model';
import { FileElementCreate } from 'src/app/file-manager/model/element';
import { base_img_64 } from 'src/app/models/base-img64.model';
import { SemnaturiRLService } from 'src/app/services/semnaturirl.service';
import { SemnaturiTerapeutiFilesService } from 'src/app/services/semnaturiterapeutifiles.service';
import { SemnaturaRLCreateInterface } from 'src/app/interfaces/pacient.interface';
import { SemnaturaRLCreate } from 'src/app/models/pacientdetails.model';


@Component({
  selector: 'app-evaluare-mers',
  templateUrl: './evaluare-mers.component.html',
  styleUrls: ['./evaluare-mers.component.css']
})
export class EvaluareMersComponent implements OnInit {
  @Output () fileid = new EventEmitter<string>();
  pacientid: number
  caz : number = 1
  displayedColumns: string[] = ['position', 'name'];
  semnatura_RL : any = null
  semnatura_Terapeut : any = null
  fisa_evaluare_mers:EvaluareMersInterface = new EvaluareMers()
  idFisier : any = null

  dataSourceTestulDeEchilibru = new MatTableDataSource(this.fisa_evaluare_mers.testul_de_echilibru.row);
  displayedcolumns = ["Titlu", "Scor"]
  dataSourceTestulDeMers = new MatTableDataSource(this.fisa_evaluare_mers.testul_de_mers.row);
  dataSourceLungimeaSiLatimeaPiciorului

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public evaluarefieldsService: EvalserviceService ,
    public dialogRef: MatDialogRef<EvaluareMersComponent>,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    public consultatiiService: ConsultatiiService,
    public fileService: FileService,
    public semnaturirlService: SemnaturiRLService,
    public semnaturiTerapeutFilesService: SemnaturiTerapeutiFilesService,
    ) {

      let data_from_parent_component : any = null
      if(data){
        console.log("DATA EVALUARE MERS ==>> ", data)
        if (data.caz == 1 ){
          console.log("CAZ = 1")
          data_from_parent_component = data.beneficiar
          console.log("Data parent component ==>>",data_from_parent_component)


      this.evaluarefieldsService.getDocFieldsFromServerByID(23)
      .subscribe ( (data) => {
        if (data){
          this.fisa_evaluare_mers = data.props
          console.log("Data ==>> GET DOC FIELDS ==>>",data)
          console.log("Data parent component ==>> GET DOC FIELDS ==>>",data_from_parent_component)
          if (data_from_parent_component){
            this.fisa_evaluare_mers.nume_pacient = data_from_parent_component.date_personale.nume
            this.fisa_evaluare_mers.prenume_pacient = data_from_parent_component.date_personale.prenume
            this.pacientid = data_from_parent_component.id
            this.fisa_evaluare_mers.pacientid = this.pacientid
            if (data_from_parent_component.date_personale.data_nasterii){
               var intermediar = Math.abs(Date.now() - new Date(data_from_parent_component.date_personale.data_nasterii).getTime());
               var varsta_intermediar = Math.floor(intermediar / (1000 * 3600 * 24) / 365.25)
              this.fisa_evaluare_mers.varsta_pacient =  varsta_intermediar.toString();
            }
            this.fisa_evaluare_mers.data_evaluare = this.datePipe.transform(new Date(), 'dd.MM.YYYY')
            this.dataSourceTestulDeEchilibru = new MatTableDataSource(this.fisa_evaluare_mers.testul_de_echilibru.row);
            this.dataSourceTestulDeMers = new MatTableDataSource(this.fisa_evaluare_mers.testul_de_mers.row);
            console.log("Data from get Fisa EVALUARE MERS FRON server ==>> ",this.fisa_evaluare_mers)
          }
          else{
            console.log("NO !!! Data from get Fisa EVALUARE MERS FRON server ==>> ")
          }
        }
      })
    }
   else if (data.caz == 2 ){
    console.log("CAZ = 2")
    this.caz = 2
    console.log (" DATA CAZ 2 ==>> ", data)
    console.log (" this.fisa_evaluare_mers CAZ 2 ==>> ", this.fisa_evaluare_mers)
    this.idFisier = data.fieldsData.idFisier
    this.evaluarefieldsService.getDocFieldsFromServerByID(23)
    .subscribe ( (data) => {
      if (data){
        this.fisa_evaluare_mers = data.props
      }
      this.consultatiiService.getConsultDataFromServerByPFisierID(this.idFisier)
      .subscribe( (data) => {
        if (data){

          console.log( "DATA FROM GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", data)
          this.fisa_evaluare_mers = data.dataJSON
          this.dataSourceTestulDeMers = new MatTableDataSource(this.fisa_evaluare_mers.testul_de_mers.row);
          this.dataSourceTestulDeEchilibru = new MatTableDataSource(this.fisa_evaluare_mers.testul_de_echilibru.row);
        }
    });
    })
  }}
}
  ngOnInit(): void {
    if (this.caz == 2){
      this.semnaturirlService.getSemnaturaRLfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA RL FROM SERVER ==>> ", data)
          this.semnatura_RL = data.semnatura
        }
      })
      this.semnaturiTerapeutFilesService.getSemnaturaTerapeutFilesfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA TERAPEUT FROM SERVER ==>> ", data)
          this.semnatura_Terapeut = data.semnatura
        }
      })
    }
    // console.log("datasource == ",this.dataSourceLungimeaSiLatimeaPiciorului )

    // FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI

    // let testone = {
    //   "denumire" : "Evaluare MERS",
    //   "props" : this.fisa_evaluare_mers
    // }

    // this.evaluarefieldsService.addToServer(testone )
    // .subscribe( (data) =>{
    //   if (data){
    //     console.log("Data from add sectiunea A to server ==>> ", data)
    //   }
    //   else{
    //     console.log("NO !!! Data from add sectiunea A to server ==>> ")
    //   }
    // })

    // END FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI
  }
  getDSArray(arraytods){
    var x = []
    console.log("array to ds first: == ",arraytods )
   for (var j of arraytods.subcategorie)
    {
      console.log("array to ds -> j: == ",j )
      for (var i of j.row){
        var intermediar = new MatTableDataSource(i)
        x.push(intermediar)
      }
    }
    console.log(x)
    return x
  }
  change($event:MatSliderChange){
    this.dataSourceTestulDeEchilibru.data = this.dataSourceTestulDeEchilibru.data
  }
  changeTestDeMers($event:MatSliderChange){
    this.dataSourceTestulDeEchilibru.data = this.dataSourceTestulDeEchilibru.data
  }

  numberedversion(valoare){
    return Number(valoare)
  }
  refreshDS(){
    this.dataSourceTestulDeMers.data = this.dataSourceTestulDeMers.data
  }
  getTotalEchilibru()
  {
    var x = 0
    for (let i of this.fisa_evaluare_mers.testul_de_echilibru.row){
      x = x + Number(i.selectat)
    }
    this.fisa_evaluare_mers.testul_de_echilibru.scor_total_echilibru = x.toString()
    return this.fisa_evaluare_mers.testul_de_echilibru.scor_total_echilibru
  }
  getTotalMers()
  {
    var x = 0
    for (let i of this.fisa_evaluare_mers.testul_de_mers.row){
      x = x + Number(i.selectat)
    }
    this.fisa_evaluare_mers.testul_de_mers.scor_total_mers = x.toString()
    return this.fisa_evaluare_mers.testul_de_mers.scor_total_mers
  }

  prepareRows(rowz){
    var x = [{
      text:rowz.titlu,
      alignament:'left',
      bold:true
    }]
    for (var y of rowz.detalii)
    {
      x.push({
        text: y.numar_puncte + " " + y.mentiune_puncte,
        alignament:'left',
        bold:false
      })
    }
    // console.log("xzz== ",x)
    return x
  }
  prepareTestDeEchilibru(){
    var x = [ [this.fisa_evaluare_mers.testul_de_echilibru.titlu + "(" + this.fisa_evaluare_mers.testul_de_echilibru.detalii+")",'  Scor  '] ]
    var y = []
    for (var i of this.fisa_evaluare_mers.testul_de_echilibru.row){
      this.prepareRows(i)
      y = [this.prepareRows(i),i.selectat]
      x.push(y)
    }
    y=[{
      text:"Scor echilibru: ",
      alignament:'right',
      bold:true,
      margin: [0, 0, 30, 0]
    },{
      text: this.getTotalEchilibru() + "/16",
    }
  ]
    x.push(y)
    return x
  }
  prepareTestMers(){
    var x = [ [this.fisa_evaluare_mers.testul_de_mers.titlu + "(" + this.fisa_evaluare_mers.testul_de_mers.detalii+")",'Scor'] ]
    var y = []
    for (var i of this.fisa_evaluare_mers.testul_de_mers.row){
      this.prepareRows(i)
      y = [this.prepareRows(i),i.selectat]
      x.push(y)
    }
    y=[{
      text:"Scor Mers: ",
      alignament:'right',
      bold:true
    },{
      text: this.getTotalMers() + "/11"
    }]

    x.push(y)
    return x
  }
  logOn(x){
    console.log("log x => ",x);
    return "ok"
  }

  semneazaRL(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_RL = res
        // console.log("Semnatura RL IMAGINE ESTE ==>> ",this.semnatura_RL)
      }
    })
  }
  semneazaTerapeut(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_Terapeut = res
        // console.log("Semnatura Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
      }
    })
  }


  addConsultToServer(idFisier){
    let intermediar_consult : ConsultCreateInterface = new ConsultCreate()
    intermediar_consult.idPacient = this.fisa_evaluare_mers.pacientid
    intermediar_consult.idFisier = idFisier
    intermediar_consult.dataAdaugare = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
    intermediar_consult.idEvaluare = 23
    intermediar_consult.denumire = "Evaluare MERS"
    intermediar_consult.dataJSON = this.returnObjectForAddConsult()
    console.log(" ADD CONSULT TO SERVER ==>> ", intermediar_consult)
    this.consultatiiService.addConsultToServer(intermediar_consult)
    .subscribe( (data) => {
      if (data){
        console.log("DATA DIN ADD CONSULT TO SERVER ==>> ", data)
      }
    })
    let semnatura_noua_RL : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
    semnatura_noua_RL.idFisier = idFisier
    semnatura_noua_RL.semnatura = this.semnatura_RL
    this.semnaturirlService.addSemnaturaRLToServer(semnatura_noua_RL)
    .subscribe ( (data) => {
      if (data){
        console.log ("DATA DIN ADD SEMNATURA RL TO SERVER ==>> ", data)
      }
    })
    let semnatura_noua_TerapeutFiles : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
    semnatura_noua_TerapeutFiles.idFisier = idFisier
    semnatura_noua_TerapeutFiles.semnatura = this.semnatura_Terapeut
    this.semnaturiTerapeutFilesService.addSemnaturaTerapeutFilesToServer(semnatura_noua_TerapeutFiles)
    .subscribe ( (data) => {
      if (data){
        console.log ("DATA DIN ADD SEMNATURA TERAPEUT FILES TO SERVER ==>> ", data)
      }
    })
  }

  returnObjectForAddConsult(){
    let intermediar = {
      "dataJSON" : this.fisa_evaluare_mers
    }
    return intermediar
  }
  openPDFPreviewSsaveModificari(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBase64((data) => {
        const dialogRef = this.dialog.open(PdfpreviewerComponent, {
          width: '800px',
          height: '100%',
          data: data
        });
        dialogRef.afterClosed().subscribe( async res => {
          if (res){
                      // this.semnatura_Terapeut = res
              // console.log("PDF PREVIEW FROM SAVE MODIFICARI RESULT AFTER CLOSE ==>> ",res)
              let update_file  = {
                'id' : this.idFisier,
                'dateadden' : this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm'),
                'path' : "data:application/pdf;base64,"+data,
                'name' : "Evaluare MERS - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
              }
              this.fileService.updateFileToServer(update_file)
              .subscribe ( (data) => {
                // console.log ( "DATA FROM UPDATE FILE FROM SERVER ==> ", data)
              })
              this.consultatiiService.updateConsultToServerByFisierID(this.idFisier,{'dataJSON' : this.returnObjectForAddConsult()})
              .subscribe( (data) => {
                // console.log ("DATA FROM ADD CONSULT TO SERVICE ==>> ", data)
              })
              this.dialogRef.close(1)
          }
        })
      })

  }
  openPDFPreviewComponent(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBase64((data) => {
        const dialogRef = this.dialog.open(PdfpreviewerComponent, {
          width: '800px',
          height: '100%',
          data: data
        });
        dialogRef.afterClosed().subscribe( async res => {
          if (res){
            let fisier_to_upload : FileElementCreate = new FileElementCls()
            fisier_to_upload.id = this.fileService.generateFileID();
            fisier_to_upload.idPacient = this.fisa_evaluare_mers.pacientid
            fisier_to_upload.isFolder = false
            fisier_to_upload.parent = 'root'
            fisier_to_upload.dateadden = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
            fisier_to_upload.type = "application/pdf"
            fisier_to_upload.extension = "pdf"
            fisier_to_upload.path = "data:application/pdf;base64,"+data
            fisier_to_upload.status = 'neverificat';
            fisier_to_upload.name = "Evaluare MERS - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
            let intermediar_from_server = await this.fileService.addFileToServerReturnFileID(fisier_to_upload).toPromise()
            this.addConsultToServer(intermediar_from_server)
            this.dialogRef.close(0)
          }
        })
      });

  }

  generatePDF(action = 'open') {
    var preparedEchilibru = this.prepareTestDeEchilibru();
    var preparedMers = this.prepareTestMers();
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
        height: 60,
        columns:
          [
            {
              stack: [
                "Fundația Mihai Neșu Foundation",
                "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
              ],
              margin: 10,
              style: 'textheader'
            }
          ],
        margin: [0, 0, 0, 90]
      },
      content: [
        {
          canvas: [
            {
              type: 'line',
              x1: -100, y1: 0,
              x2: 600, y2: 0,
              lineWidth: 1,
              lineCap: 'square'
            },
            {
              type: 'line',
              x1: -100, y1: 4,
              x2: 600, y2: 4,
              lineWidth: 1,
              lineCap: 'square'
            }
          ]
        },
        {
          image: logo_url_base64,
          width: 200,
          alignment: 'center',
        },
        {
          text: "Scala Tinetti - evaluarea echilibrului și a mersului",
          bold: true,
          alignment: 'center',
          fontSize: 16,
          margin: [0, 50, 0, 80]
        },
        {
          text: "Nume: " + this.fisa_evaluare_mers.nume_pacient + " " + this.fisa_evaluare_mers.prenume_pacient,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Varsta: " + this.fisa_evaluare_mers.varsta_pacient,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Data: " + this.fisa_evaluare_mers.data_evaluare,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['*', 40 ],
            body: preparedEchilibru
          },
          margin: [0, 10, 0, 20]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['*', 40 ],
            body: preparedMers
          },
          margin: [0, 10, 0, 20]
        },
        // {
        //   layout: 'lightHorizontalLines', // optional
        //   table: {
        //     // headers are automatically repeated if the table spans over multiple pages
        //     // you can declare how many rows should be treated as headers
        //     headerRows: 1,
        //     widths: [50 , 50 ],
        //     body: [
        //       {
        //       text:'Evaluator',
        //       },
        //       {
        //       text:'Luat la cunostinta de parinte: ',
        //       }
        //     ]
        //   }
        // },
        {
          columns:[
            {
              stack: [
                {
                  text: "Semnatura terapeut, ",
                  bold: true,
                  alignment: 'left',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_Terapeut ? this.semnatura_Terapeut : base_img_64,
                  height: 40,
                  width: 140,
                  margin: [0, 0, 0, 20]
                }
              ]
            },
            {
              stack: [
                {
                  text: "Luat la cunoștință de părinte, ",
                  bold: true,
                  alignment: 'right',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_RL ? this.semnatura_RL : base_img_64,
                  alignment: 'right',
                  height: 40,
                  width: 140,
                  margin: [0, 0, 0, 20]
                }
              ]
            }
          ]
        }

      ],
      styles: {
        textheader: {
          alignment: 'center',
          bold: true,
          fontSize: 14,
          // lineHeight: 2
        }
      }
    }
    return docDefinition
  }
}
