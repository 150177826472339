import { Component, ChangeDetectionStrategy, ViewChild, OnInit, ElementRef } from '@angular/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarView,
  CalendarWeekViewBeforeRenderEvent,
  DAYS_OF_WEEK
} from 'angular-calendar';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  addMinutes,
} from 'date-fns';
import { CustomDateFormatter } from "./custom-date-formatter.provider";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgramaredialogComponent } from '../programaredialog/programaredialog.component';
import { Subject } from 'rxjs';
import { DepartamentService } from 'src/app/services/departament.service';
import { ServiciiService } from 'src/app/services/servicii.service';
import { SpecialitateMedicService } from 'src/app/services/servmedic.service';
import { specialitateMedici, Servicii, Departament, Medici } from 'src/app/models/nomenclatoare.model';
import { MediciService } from 'src/app/services/medici.service';
import { FormsModule } from '@angular/forms';
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatListModule } from '@angular/material/list';
import { Pacient, DateContact, DatePersonale, Adresa, programareRecurenta } from '../models/pacientdetails.model';
import { PacientiService } from '../services/pacienti.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EditprogramareComponent } from '../editprogramare/editprogramare.component';
import { ProgramariService } from '../services/programari.service';
import {
  ViewEncapsulation,
} from '@angular/core';
import {
  CalendarMonthViewBeforeRenderEvent,
  CalendarDayViewBeforeRenderEvent,
} from 'angular-calendar';
import { CalendarComponentx } from '../calendar/calendar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatBottomSheet, MatBottomSheetConfig} from '@angular/material/bottom-sheet';
import { ProgramareEroareComponent } from '../programare-eroare/programare-eroare.component';
import { ProgramariRecurenteService } from '../services/programarirecurente.service';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { PacientdetailsComponent } from '../pacientdetails/pacientdetails.component';
import { ServiciiInterface } from '../interfaces/nomenclatoare.interface';
import { PacientInterface } from '../interfaces/pacient.interface';
import { MatInput } from '@angular/material/input';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-programari',
  templateUrl: './programari.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./programari.component.css']
})

export class ProgramariComponent implements OnInit {
  @ViewChild(SelectAutocompleteComponent) multiSelect: SelectAutocompleteComponent;
  varsta_cronologica: number;
  openCalendarFlag: boolean = false
  medici: Medici[];
  available_medici: Medici[];
  mediciCtrl = new FormControl();
  filteredMedici: Observable<Medici[]>
  selectedMedici: Medici
  mySubscription: any;
  departamente: Departament[];
  available_departamente: Departament[];
  departamenteCtrl = new FormControl()
  filteredDepartament: Observable<Departament[]>
  selectedDepartamente: Departament[] = []

  servicii: ServiciiInterface[];
  available_servicii: ServiciiInterface[];
  filteredServicii: Observable<ServiciiInterface[]>;
  serviciiCtrl = new FormControl();
  selectedservicii: ServiciiInterface;

  specialitatemedici: specialitateMedici[];
  available_specialitatemedici: specialitateMedici[];
  filteredSpecialitatea: Observable<specialitateMedici[]>
  specialitateCtrl = new FormControl()
  selectedSpecialitate: specialitateMedici


  pacientList: Pacient[] = [];
  selectedPacientId: number;
  selectedpacient: Pacient;
  pacientdataSource = new MatTableDataSource();
  selectedpacientflag: boolean = false;
  displayedColumns: string[] = ['id', 'nume', 'prenume', 'CNP', "email", "telefon"];

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  // teste filtrare
    filter_id_pacient = new FormControl('')
    filter_nume_pacient = new FormControl('')
    filter_prenume_pacient = new FormControl('')
    filter_cnp_pacient = new FormControl('')
    filter_email_pacient = new FormControl('')
    filter_telefon_pacient = new FormControl('')
    filterValuestwo = {
      id:'',
      date_personale:{
        nume:'',
        prenume:'',
        cnp:''
      },
      date_contact:{
        email:'',
        telefon:''
      }
    }

  @ViewChild('serviciiInput') serviciiInput: ElementRef<HTMLInputElement>;
  @ViewChild('mediciInput') mediciInput: ElementRef<HTMLInputElement>;
  @ViewChild('departamentInput') departamentInput: ElementRef<HTMLInputElement>;
  @ViewChild('specialitateInput') specialitateInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('auto2') matAutocomplete2: MatAutocomplete;
  @ViewChild('auto3') matAutocomplete3: MatAutocomplete;
  @ViewChild('auto4') matAutocomplete4: MatAutocomplete;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
  }
  constructor(
      private datePipe: DatePipe,
      public programariRecurenteService:ProgramariRecurenteService,
      private _snackBar: MatSnackBar,
      public programariService: ProgramariService,
      public pacientiService: PacientiService,
      public dialog: MatDialog,
      public serviciiService: ServiciiService,
      public departamentService: DepartamentService,
      public specialitateMedicService: SpecialitateMedicService,
      public mediciService: MediciService
      ) {
    // this.servicii =
    this.serviciiService.getServiciiFromServer()
    .subscribe ( (data) =>{
      if (data){
        this.servicii = data
      }
    })
    this.available_servicii = this.servicii;
    // this.departamente =
    this.departamentService.getDepartamentFromServer()
    .subscribe ( (data) => {
      if (data){
        this.departamente = data
        this.available_departamente = this.departamente
      }
    })
    this.specialitatemedici = this.specialitateMedicService.getSpecialitatiMedic();
    this.available_specialitatemedici = this.specialitatemedici
    this.mediciService.getMediciFromServer()
    .subscribe( (data) => {
      if (data){
        this.medici = data
        this.available_medici = this.medici
      }
    })
    // this.pacientList = this.pacientiService.getPacienti();

    // Old Pacienti from server si filtrare

    // this.pacientiService.getPacientifromServer()
    // .subscribe((data) => {
    //     this.pacientList = data
    //     console.log("Data from server == >>", this.pacientList)
    //     this.pacientdataSource  = new MatTableDataSource(this.pacientList);
    //     this.pacientdataSource.paginator = this.paginator;
    //     console.log("Data from pacientdataSource == >>", this.pacientdataSource)
    //   })
    // this.pacientdataSource = new MatTableDataSource(this.pacientList);
    // this.pacientdataSource.paginator = this.paginator;
    // this.pacientdataSource.filterPredicate = function (data, filter): boolean {
    //   // console.log("data din filtru: =>",data);
    //   return data.id.toString().includes(filter) || data.date_personale.nume.toLowerCase().includes(filter) || data.date_personale.prenume.toLowerCase().includes(filter)
    //     || data.date_personale.cnp.toString().includes(filter) || data.date_contact.email.includes(filter) || data.date_contact.telefon.toLowerCase().includes(filter)
    // }

    this.pacientiService.getPacientifromServer()
    .subscribe((data) => {
      if (data){
        console.log("Data din Programari -->> Ger pacienti from server ==>> ", data)
        for (var i of data){
          console.log(" >>> I din Data --> Programari component --> Pacient List ==>> ", i )
          this.pacientList.push(i)
          console.log(" >>> this.pacientList dupa push i --> Programari component --> Pacient List ==>> ", this.pacientList )
        }
        // this.pacientList = data
        console.log("Pacient List din programari ==>> ", this.pacientList)
        this.pacientdataSource.data = this.pacientList
        this.pacientdataSource.filterPredicate = this.createFilter()
        this.pacientdataSource.paginator = this.paginator;
        console.log("Data from pacientdataSource == >>", this.pacientdataSource)
      }
    })
    this.pacientdataSource = new MatTableDataSource(this.pacientList);
    this.pacientdataSource.paginator = this.paginator;

    this.events = this.programariService.getProgramari();
    for (var i of this.events) {
      i.start = new Date(i.start)
      i.end = new Date(i.end)
    }
    this.filteredServicii = this.serviciiCtrl.valueChanges.pipe(
      startWith(null),
      map((serv: Servicii | null) => serv ? this._filterservicii(serv) : this.servicii.slice()));
    this.filteredMedici = this.mediciCtrl.valueChanges.pipe(
      startWith(null),
      map((med: Medici | null) => med ? this._filtermedici(med.nume) : this.medici.slice()));
    this.filteredDepartament = this.departamenteCtrl.valueChanges.pipe(
      startWith(null),
      map((dep: Departament | null) => dep ? this._filterdepartamente(dep.denumire) : this.departamente.slice()));
    this.filteredSpecialitatea = this.specialitateCtrl.valueChanges.pipe(
      startWith(null),
      map((spec: specialitateMedici | null) => spec ? this._filterSpecialitati(spec.denumire) : this.specialitatemedici.slice()));
    }

    ngOnInit(): void {
      this.filter_id_pacient.valueChanges
      .subscribe(
        id => {
          this.filterValuestwo.id = id
          this.pacientdataSource.filter = JSON.stringify(this.filterValuestwo)
        }
      )
      this.filter_nume_pacient.valueChanges
      .subscribe(
        nume => {
          this.filterValuestwo.date_personale.nume = nume
          this.pacientdataSource.filter = JSON.stringify(this.filterValuestwo)
        }
      )
      this.filter_prenume_pacient.valueChanges
      .subscribe(
        prenume => {
          this.filterValuestwo.date_personale.prenume = prenume
          this.pacientdataSource.filter = JSON.stringify(this.filterValuestwo)
        }
      )
      this.filter_cnp_pacient.valueChanges
      .subscribe(
        cnp => {
          this.filterValuestwo.date_personale.cnp = cnp
          this.pacientdataSource.filter = JSON.stringify(this.filterValuestwo)
        }
      )
      this.filter_telefon_pacient.valueChanges
      .subscribe(
        telefon => {
          this.filterValuestwo.date_contact.telefon = telefon
          this.pacientdataSource.filter = JSON.stringify(this.filterValuestwo)
        }
      )
      this.filter_email_pacient.valueChanges
      .subscribe(
        email => {
          this.filterValuestwo.date_contact.email = email
          this.pacientdataSource.filter = JSON.stringify(this.filterValuestwo)
        }
      )
    }

  private _filterservicii(serv): ServiciiInterface[] {
    console.log("filtered servicii, serv1 : ", this.serviciiCtrl.value)
    var filterValue

    // if (this.selectedSpecialitate.length > 0) {
    //   console.log("Specialitati selectate: ", this.selectedSpecialitate)
    // }

    if (this.serviciiCtrl.value != "") {
      // console.log("filtered servicii, serv2 : ", this.serviciiCtrl.value)
      filterValue = this.serviciiCtrl.value;
    }
    else {
      filterValue = ''
    }
    return this.servicii.filter(serviciu => (serviciu.denumire.toLowerCase().indexOf(filterValue) === 0));
  }
  private _filterSpecialitati(serv): specialitateMedici[] {
    // console.log("filtered specialitate, serv1 : ", this.specialitateCtrl.value)
    var filterValue
    if (this.serviciiCtrl.value != "") {
      console.log("filtered specialitate, serv2 : ", this.specialitateCtrl.value)
      filterValue = this.specialitateCtrl.value;
    }
    else {
      filterValue = ''
    }
    return this.specialitatemedici.filter(specialitate => specialitate.denumire.toLowerCase().indexOf(filterValue) === 0);
  }
  private _filterdepartamente(serv): Departament[] {
    console.log("filtered departament, serv1 : ", this.departamenteCtrl.value)
    var filterValue
    if (this.serviciiCtrl.value != "") {
      console.log("filtered departament, serv2 : ", this.departamenteCtrl.value)
      filterValue = this.departamenteCtrl.value;
    }
    else {
      filterValue = ''
    }
    return this.departamente.filter(dep => dep.denumire.toLowerCase().indexOf(filterValue) === 0);
  }
  private _filtermedici(med): Medici[] {
    console.log("filtered servicii, serv1 : ", this.mediciCtrl.value)
    var filterValue
    if (this.mediciCtrl.value != "") {
      console.log("filtered servicii, serv2 : ", this.mediciCtrl.value)
      filterValue = this.mediciCtrl.value.nume;
    }
    else {
      filterValue = ''
    }
    return this.medici.filter(med => med.nume.toLowerCase().indexOf(filterValue) === 0);
  }

  selectedServicii(event: MatAutocompleteSelectedEvent): void {
    // console.log("selected servicii event: ", event.option.value);
    // console.log("selected serv ctrl1 : ", this.serviciiCtrl)
    // this.selectedservicii.push(event.option.value);
    this.serviciiInput.nativeElement.value = '';
    this.serviciiCtrl.setValue(null);
    // console.log("selected serv ctrl : ", this.filteredServicii)
    if (this.selectedDepartamente.length === 0){
      // console.log("selected departamente este gol -----")
      this.departamente = []
      for (var i of this.available_departamente)
      {
        // console.log ("i of available_departamente =>: ", i)
        var x = false
        for (var j of i.servicii)
        {
          // console.log("j of servicii =>: ", j)
          if (j.cod === event.option.value.cod)
            {
              x = true
            }
        }
        if (x){
          this.departamente.push(i)
        }
      }
      this.filteredDepartament = this.departamenteCtrl.valueChanges.pipe(
        startWith(null),
        map((dep: Departament | null) => dep ? this._filterdepartamente(dep.denumire) : this.departamente.slice()));
    }
    if (!this.selectedMedici) {
      // console.log("selected medici este gol -----")
      this.medici = []
      // console.log('Available medici: -> ',this.available_medici)
        // console.log("each medic from available :-> ",q)
        var x = false
        for (var z of this.available_medici){
          // console.log("j is each servicii: -> ",z)
          // console.log("option value cod: -> ",event.option.value.cod)
          // console.log("<==================>")
          for(var q of z.servicii){
            if (q.cod === event.option.value.cod){
              // console.log("expresia este TRUE <-> ",z)
              x = true
              }
          }
        }
        if (x){
          this.medici.push(z)
        }
      // console.log("servicii dupa if-uri : =>> ", this.servicii)
      this.filteredMedici = this.mediciCtrl.valueChanges.pipe(
        startWith(null),
        map((med: Medici | null) => med ? this._filtermedici(med.nume) : this.medici.slice()));
      }
  }
  selectedSpecialitati(event: MatAutocompleteSelectedEvent): void {
    // console.log("selected servicii event: ", event.option.value);
    // console.log("selected serv ctrl1 : ", this.specialitateCtrl)
    // this.selectedSpecialitate.push(event.option.value);
    this.specialitateInput.nativeElement.value = '';
    this.specialitateCtrl.setValue(null);
    // if (this.selectedservicii.length === 0) {
    //   this.servicii = []
    //   this.available_servicii.forEach(serviciu => {
    //     // console.log("foreach :-=> ", serviciu)
    //     // console.log("foreach event option value :-=> ", event.option.value)
    //     // console.log("<=---------=> ")
    //     // console.log("comparatia intre valori:-=> ", (serviciu.specialitate.cod === event.option.value.cod))
    //     // console.log("<=---------=> ")

    //     if (serviciu.specialitate[0].cod === event.option.value.cod) { // DE CORECTAT AICI

    //       // console.log("serviciul.specialitatea === event.option.value -> TRUE") // old

    //       this.servicii.push(serviciu)

    //       // console.log("servicii in case it is true : =>> ", this.servicii) // old

    //     }
    //   })
    //   // console.log("servicii dupa if-uri : =>> ", this.servicii)
    //   this.filteredServicii = this.serviciiCtrl.valueChanges.pipe(
    //     startWith(null),
    //     map((serv: Servicii | null) => serv ? this._filterservicii(serv) : this.servicii.slice()));
    // }
    if (!this.selectedMedici) {
      this.medici = []
      console.log('Available medici: -> ',this.available_medici)
      for (var i of this.available_medici){
        // console.log("each medic from available :-> ",i)
        var x = false
        for (var j of i.specialitate){
          // console.log("j is each specialitate: -> ",j)
          // console.log("option value cod: -> ",event.option.value.cod)
          // console.log("<==================>")
          if (j.cod === event.option.value.cod){
            // console.log("expresia este TRUE <-> ",j)
            x = true
          }
        }
        if (x){
          this.medici.push(i)
        }
      }
      // console.log("servicii dupa if-uri : =>> ", this.servicii)
      this.filteredMedici = this.mediciCtrl.valueChanges.pipe(
        startWith(null),
        map((med: Medici | null) => med ? this._filtermedici(med.nume) : this.medici.slice()));
      }

  }
  selectedDepartament(event: MatAutocompleteSelectedEvent): void {
    console.log("selected depar event: ", event.option.value);
    console.log("selected depar ctrl1 : ", this.departamenteCtrl)
    this.selectedDepartamente.push(event.option.value);
    this.departamentInput.nativeElement.value = '';
    this.departamenteCtrl.setValue(null);
    console.log("selected depar ctrl : ", this.departamenteCtrl)
  }
  selectedMedic(event: MatAutocompleteSelectedEvent): void {
    console.log("selected medici event: ", event.option.value);
    // console.log("selected medici ctrl1 : ", this.serviciiCtrl)
    this.selectedMedici = event.option.value;
    this.mediciInput.nativeElement.value = '';
    this.mediciCtrl.setValue(null);
    // console.log("selected serv ctrl : ", this.serviciiCtrl)
    // if (this.selectedservicii.length === 0) {
    //   this.servicii = []
    //     for (var j of this.selectedMedici.servicii){
    //       this.servicii.push(j)
    //   }
    // }
    this.filteredServicii = this.serviciiCtrl.valueChanges.pipe(
      startWith(null),
      map((serv: Servicii | null) => serv ? this._filterservicii(serv) : this.servicii.slice()));

      if (this.selectedDepartamente.length === 0){
        // console.log("selected departamente este gol -----")
        this.departamente = []
          // console.log ("i of available_departamente =>: ", i)
          for (var q of this.selectedMedici.departament)
          {
            this.departamente.push(q)
          }

      this.filteredDepartament = this.departamenteCtrl.valueChanges.pipe(
          startWith(null),
          map((dep: Departament | null) => dep ? this._filterdepartamente(dep.denumire) : this.departamente.slice()));
      }

      // if (this.selectedSpecialitate.length === 0){
      //   this.specialitatemedici = []
      //     for (var t of this.selectedMedici.specialitate){
      //       this.specialitatemedici.push(t)
      //     }
      //   this.filteredSpecialitatea = this.specialitateCtrl.valueChanges.pipe(
      //     startWith(null),
      //     map((spec: specialitateMedici | null) => spec ? this._filterSpecialitati(spec.denumire) : this.specialitatemedici.slice()));
      // }
  }
  removeMedic(): void {
    const index = -1
    this.mediciInput.nativeElement.value = '';
    this.mediciCtrl.setValue(null);
    this.selectedMedici = undefined
    console.log("selected departamente => ",this.selectedDepartamente )
    if (this.selectedDepartamente.length === 0 ) {
      console.log("niciun departament selectat")
      this.available_departamente = this.departamente = this.departamentService.getDepartament()
      this.filteredDepartament = this.departamenteCtrl.valueChanges.pipe(
        startWith(null),
        map((dep: Departament | null) => dep ? this._filterdepartamente(dep.denumire) : this.departamente.slice()));
    }
    // if (this.selectedservicii.length === 0){
    //   console.log("niciun serviciu selectat")
    //   this.servicii = this.available_servicii = this.serviciiService.getServicii()
    //   this.filteredServicii = this.serviciiCtrl.valueChanges.pipe(
    //     startWith(null),
    //     map((serv: Servicii | null) => serv ? this._filterservicii(serv) : this.servicii.slice()));
    // }
    if (this.specialitatemedici.length === 0){
      console.log("nicio specialitate selectata")
      this.specialitatemedici = this.available_specialitatemedici = this.specialitateMedicService.getSpecialitatiMedic()
      this.filteredSpecialitatea = this.specialitateCtrl.valueChanges.pipe(
        startWith(null),
        map((spec: specialitateMedici | null) => spec ? this._filterSpecialitati(spec.denumire) : this.specialitatemedici.slice()));
    }

    // const index = this.selectedMedici.indexOf(serv);

    // if (index >= 0) {
    //   this.selectedMedici.splice(index, 1);
    // }
  }
  // removeSpecialitate(serv): void {
  //   const index = this.selectedSpecialitate.indexOf(serv);

  //   if (index >= 0) {
  //     this.selectedSpecialitate.splice(index, 1);
  //   }
  //   this.available_medici = this.medici = this.mediciService.getMedici()
  //   this.filteredMedici = this.mediciCtrl.valueChanges.pipe(
  //     startWith(null),
  //     map((med: Medici | null) => med ? this._filtermedici(med.nume) : this.medici.slice()));
  //     this.selectedservicii = []
  //     this.available_servicii = this.servicii = this.serviciiService.getServicii()
  //     this.filteredServicii = this.serviciiCtrl.valueChanges.pipe(
  //       startWith(null),
  //       map((serv: Servicii | null) => serv ? this._filterservicii(serv) : this.servicii.slice()));
  // }
  removeDepartament(serv): void {
    const index = this.selectedDepartamente.indexOf(serv);

    if (index >= 0) {
      this.selectedDepartamente.splice(index, 1);
    }
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      // this.fruits.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    // this.fruitCtrl.setValue(null);
  }

  // removeServicii(serv): void {
  //   const index = this.selectedservicii.indexOf(serv);

  //   if (index >= 0) {
  //     this.selectedservicii.splice(index, 1);
  //   }
  // }




  refresh: Subject<any> = new Subject();

  view: CalendarView = CalendarView.Week;

  viewDate = new Date();

  events: CalendarEvent[] = [];

  locale: string = "ro";

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  clickedDate: Date;

  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  excludeDays: number[] = [0, 6];

  CalendarView = CalendarView;
  programdesomnselselectatFlag: boolean = false


  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          if (
            segment.date.getHours() >= 2 &&
            segment.date.getHours() <= 5 &&
            segment.date.getDay() === 2
          ) {
            segment.cssClass = 'bg-pink';
          }
        });
      });
    });
  }
  hidePacientTable:boolean = false

  getSelectedOptions(selected) {
    this.selectedservicii = selected;
  }
  setView(view: CalendarView) {
    this.view = view;
  }
  checkindependenta(pacient){
    return pacient.independent
  }
  // reset(){
  //   this.ngOnInit();
  //   this.selectedpacient = new Pacient()
  //   this.selectedPacientId = 0
  //   this.pacientList = this.pacientiService.getPacienti()
  //   this.selectedpacientflag = false
  //   this.hidePacientTable = false
  //   this.available_servicii = this.servicii = this.serviciiService.getServicii()

  //   this.selectedMedici = undefined;
  //   this.available_medici = this.medici = this.mediciService.getMedici()

  //   this.selectedDepartamente = []
  //   this.available_departamente = this.departamente =this.departamentService.getDepartament()

  //   // this.selectedSpecialitate = []
  //   this.specialitatemedici = this.available_specialitatemedici = this.specialitateMedicService.getSpecialitatiMedic()

  //   this.filteredServicii = this.serviciiCtrl.valueChanges.pipe(
  //     startWith(null),
  //     map((serv: Servicii | null) => serv ? this._filterservicii(serv) : this.servicii.slice()));
  //   this.filteredMedici = this.mediciCtrl.valueChanges.pipe(
  //     startWith(null),
  //     map((med: Medici | null) => med ? this._filtermedici(med.nume) : this.medici.slice()));
  //   this.filteredDepartament = this.departamenteCtrl.valueChanges.pipe(
  //     startWith(null),
  //     map((dep: Departament | null) => dep ? this._filterdepartamente(dep.denumire) : this.departamente.slice()));
  //   this.filteredSpecialitatea = this.specialitateCtrl.valueChanges.pipe(
  //     startWith(null),
  //     map((spec: specialitateMedici | null) => spec ? this._filterSpecialitati(spec.denumire) : this.specialitatemedici.slice()));
  // }
  createFilter():(data: PacientInterface[], filter: string) => boolean{
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      console.log(" filter ==>> ", filter)
      console.log("Search Terms ==>> ", searchTerms)
      console.log("Search Terms Data ==>> ", data)
      var flag_id_pacient_Filter : boolean = false
      var flag_nume_pacient_Filter : boolean = false
      var flag_prenume_pacient_Filter : boolean = false
      var flag_cnp_pacient_Filter : boolean = false
      var flag_email_pacient_Filter : boolean = false
      var flag_telefon_pacient_Filter : boolean = false

      if( data.id != null ){
          if (data.id.toString().toLowerCase().indexOf(searchTerms.id.toString().toLowerCase()) !== -1){
            flag_id_pacient_Filter = true
          }
        }

      if (data.date_personale){
        if (data.date_personale.nume !== '' ){
              if (data.date_personale.nume.toLowerCase().indexOf(searchTerms.date_personale.nume.toLowerCase()) !== -1){
                flag_nume_pacient_Filter = true
              }
        }
        if (data.date_personale.prenume){
            if (data.date_personale.prenume.toLowerCase().indexOf(searchTerms.date_personale.prenume.toLowerCase()) !== -1){
              flag_prenume_pacient_Filter = true
          }
        }
        if( data.date_personale.cnp !== null ){
            if (data.date_personale.cnp.toString().toLowerCase().indexOf(searchTerms.date_personale.cnp.toString().toLowerCase()) !== -1){
              flag_cnp_pacient_Filter = true
          }
        }
      }
      if (data.date_contact){
          if (data.date_contact.email.toLowerCase().indexOf(searchTerms.date_contact.email.toLowerCase()) !== -1){
              flag_email_pacient_Filter = true
        }

        if( data.date_contact.telefon != null ){
            if (data.date_contact.telefon.toString().toLowerCase().indexOf(searchTerms.date_contact.telefon.toString().toLowerCase()) !== -1){
              flag_telefon_pacient_Filter = true
          }
        }

      }
      console.log(" flag_id_pacient_Filter ==>> ", flag_id_pacient_Filter )
      console.log(" flag_nume_pacient_Filter ==>> ", flag_nume_pacient_Filter )
      console.log(" flag_prenume_pacient_Filter ==>> ", flag_prenume_pacient_Filter )
      console.log(" flag_email_pacient_Filter ==>> ", flag_email_pacient_Filter )
      console.log(" flag_telefon_pacient_Filter ==>> ", flag_telefon_pacient_Filter )
      console.log(" flag_cnp_pacient_Filter  ==>> ", flag_cnp_pacient_Filter )
      // return true
      return flag_id_pacient_Filter && flag_nume_pacient_Filter && flag_prenume_pacient_Filter && flag_cnp_pacient_Filter
      // return flag_id_pacient_Filter && flag_nume_pacient_Filter && flag_prenume_pacient_Filter && flag_email_pacient_Filter && flag_telefon_pacient_Filter && flag_cnp_pacient_Filter
    }
    return filterFunction
  }


  selectPacient(id) {
    console.log("select pacient -> ", this.selectedpacientflag)
    if (this.selectedpacientflag)
    {
      if (this.selectedpacient.id === id)
      {
        this.selectedpacient = undefined
        this.selectedPacientId = undefined
        this.selectedpacientflag = false
      }
      else{
        this.selectedPacientId = id;
    this.pacientdataSource = new MatTableDataSource(this.pacientList);
    this.pacientdataSource.paginator = this.paginator;
    this.selectedpacient = this.selectedpacientdetails(id)
    this.selectedpacientflag = true
    this.hidePacientTable = true
    if (!this.checkindependenta(this.selectedpacient))
    {
      this.available_medici = []
      for (var i of this.medici){
        if (i.sex === 'masculin'){
          this.available_medici.push(i)
        }
        else{
          if(i.sex === 'feminin'){
            if (i.greutate/2 >= this.selectedpacient.greutate ){
              this.available_medici.push(i)
            }
          }
        }
      }
      this.medici = this.available_medici
      this.filteredMedici = this.mediciCtrl.valueChanges.pipe(
        startWith(null),
        map((med: Medici | null) => med ? this._filtermedici(med.nume) : this.medici.slice()));
    }

    // console.log('pacient details selectat in tabel:=> ', this.selectedpacient.program_de_somn.start_interval)
    var intermediar = Math.abs(Date.now() - new Date(this.selectedpacient.date_personale.data_nasterii).getTime());
    this.varsta_cronologica = Math.floor(intermediar / (1000 * 3600 * 24) / 365.25)
      }
    }

    else
{    this.selectedPacientId = id;
    this.pacientdataSource = new MatTableDataSource(this.pacientList);
    this.pacientdataSource.paginator = this.paginator;
    this.selectedpacient = this.selectedpacientdetails(id)
    this.selectedpacientflag = true
    this.hidePacientTable = true
    if (!this.checkindependenta(this.selectedpacient))
    {
      this.available_medici = []
      for (var i of this.medici){
        if (i.sex === 'masculin'){
          this.available_medici.push(i)
        }
        else{
          if(i.sex === 'feminin'){
            if (i.greutate/2 >= this.selectedpacient.greutate ){
              this.available_medici.push(i)
            }
          }
        }
      }
      this.medici = this.available_medici
      this.filteredMedici = this.mediciCtrl.valueChanges.pipe(
        startWith(null),
        map((med: Medici | null) => med ? this._filtermedici(med.nume) : this.medici.slice()));
    }

    // console.log('pacient details selectat in tabel:=> ', this.selectedpacient.program_de_somn.start_interval)
    var intermediar = Math.abs(Date.now() - new Date(this.selectedpacient.date_personale.data_nasterii).getTime());
    this.varsta_cronologica = Math.floor(intermediar / (1000 * 3600 * 24) / 365.25)
  }

  }
  selectedpacientdetails(id) {
    for (let x of this.pacientList) {
      if (x.id === id) {
        return x
      }
    }
    return null
  }
  applyFilter(event: Event) {
    console.log("Event: => ", event)
    const filterValue = (event.target as HTMLInputElement).value;
    console.log("filter value: => ", filterValue)
    this.pacientdataSource.filter = filterValue.trim().toLowerCase();
  }
  addDays(date, days) {
    var result = date;
    // console.log("addDays date: ", date)
    result.setDate(result.getDate() + days);
    if (result.getDay() === 0) {
      result.setDate(result.getDate() + 1)
    }
    if (result.getDay() === 6) {
      result.setDate(result.getDate() + 2)
    }
    return result;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  getTotalCuanta():number{
    var x = 0
    // for (var i of this.selectedservicii){
    //   x = x + i.timp
    // }
    console.log("This.selectedservicii.timp ==>> ", this.selectedservicii.timp)
    x = x+this.selectedservicii.timp
    return x
  }
  dataInTrecut(variabila: Date): boolean{
    if (variabila < new Date())
    {
      // console.log(' >> Data din trecut <<')
      return true
    }
    // console.log(' >> Data din viitor <<')
    return false
  }

  /// Functia de adaugat programari
  programarenoua(vari: Date): void {
    // check daca data este in trecut
    if (this.dataInTrecut(vari)){
      // this._snackBar.open('Data selectata este in trecut', 'close');

    }
    //daca data nu este in trecut
    else{

    // console.log("vari simplu")

    // console.log(this.dataInTrecut(vari))
    var programare_noua_start_h = new Date(vari).getHours()
    console.log("programare noua start h = ", programare_noua_start_h)
    var programare_noua_end_h = new Date(new Date(vari).setMinutes(new Date(vari).getMinutes() + this.getTotalCuanta())).getHours()
    console.log("cuanta aferenta:", this.getTotalCuanta())
    console.log("programare noua end h = ", programare_noua_end_h)

    if (this.selectedpacient.orar_de_somn){
      console.log("pacientul are orar de somn")
      // console.log("Pacientul are specificat un interval de somn cuprins intre: "+ new Date(this.selectedpacient.program_de_somn.start_interval).getHours() + ":" + new Date(this.selectedpacient.program_de_somn.start_interval).getMinutes() + " si " + new Date(this.selectedpacient.program_de_somn.end_interval).getHours() + ":" + new Date(this.selectedpacient.program_de_somn.start_interval).getMinutes() ,'ok' );
      if ( programare_noua_start_h >= new Date(this.selectedpacient.program_de_somn[0].start).getHours() &&     // !!==>  de bagat in for
          programare_noua_start_h <  new Date(this.selectedpacient.program_de_somn[0].end).getHours() ||     // !!==>  de bagat in for
          programare_noua_end_h >= new Date(this.selectedpacient.program_de_somn[0].start).getHours() &&    // !!==>  de bagat in for
          programare_noua_end_h <  new Date(this.selectedpacient.program_de_somn[0].end).getHours()     // !!==>  de bagat in for
          ){
            this.programdesomnselselectatFlag = true
            // console.log("Pacientul are specificat un interval de somn cuprins intre: "+ new Date(this.selectedpacient.program_de_somn.start_interval).getHours() + ":" + new Date(this.selectedpacient.program_de_somn.start_interval).getMinutes() + " si " + new Date(this.selectedpacient.program_de_somn.end_interval).getHours() + ":" + new Date(this.selectedpacient.program_de_somn.end_interval).getMinutes() ,'ok' );

            const dialogRef = this.dialog.open(ProgramareEroareComponent, {
              width: '250px',
              height: '250px',
              // data: "Pacientul are specificat un interval de somn cuprins intre: "+ this.datePipe.transform(this.selectedpacient.program_de_somn.start_interval, 'H:mm') + " si " + this.datePipe.transform(this.selectedpacient.program_de_somn.end_interval, 'H:mm') +  ". Incerci sa faci o programare care incepe la  ora: "
              // +  this.datePipe.transform(vari, 'H:mm') + ". Doresti sa faci programarea in intervalul de somn? ",
            });
            dialogRef.afterClosed().subscribe(result => {
              console.log("closed eroare, result => ", result)
              if (result){
                this.openProgramareDialogComponent(vari, this.getTotalCuanta())
              }
              else{
                this.programdesomnselselectatFlag = false
              }
            }
            )
          }
        }
      if (this.selectedMedici){
        console.log("sunt medici selectati")
        switch (vari.getDay())
        {
          case 1:
            if (
                ( (
                  ((vari.getHours() < new Date(this.selectedMedici.program.luni.OraStart).getHours()) ||
                (vari.getHours() >= new Date(this.selectedMedici.program.luni.OraEnd).getHours())) ||
                (
                (programare_noua_end_h < new Date(this.selectedMedici.program.luni.OraStart).getHours()) ||
                (programare_noua_end_h >= new Date(this.selectedMedici.program.luni.OraEnd).getHours()))) &&
                vari.getDay() === 1)
            ) {
              const dialogRef = this.dialog.open(ProgramareEroareComponent, {
                width: '250px',
                height: '250px',
                // data: "In zilele de luni programul terapeutului incepe la: " + this.datePipe.transform(this.selectedMedici.program.luni.OraStart, 'H:mm') + " si se incheie la ora: " + this.datePipe.transform(this.selectedMedici.program.luni.OraEnd, 'H:mm')
                // + ". Incerci sa faci o programare care incepe la  ora: " + this.datePipe.transform(vari, 'H:mm') + ". Doresti sa faci programarea inafara programului terapeutului? ",
              });
              dialogRef.afterClosed().subscribe(result => {
                console.log("closed eroare, result => ", result)
                if (result){
                  this.openProgramareDialogComponent(vari, this.getTotalCuanta())
                }
              }
              )
            }
            else{
              if (!this.programdesomnselselectatFlag){
              this.openProgramareDialogComponent(vari, this.getTotalCuanta())}
            }
            break;
          case 2:
              if (
                ( (
                    ((vari.getHours() < new Date(this.selectedMedici.program.marti.OraStart).getHours()) ||
                  (vari.getHours() >= new Date(this.selectedMedici.program.marti.OraEnd).getHours())) ||
                  (
                  (programare_noua_end_h < new Date(this.selectedMedici.program.marti.OraStart).getHours()) ||
                  (programare_noua_end_h >= new Date(this.selectedMedici.program.marti.OraEnd).getHours()))) &&
                  vari.getDay() === 2)
              )
              {
              const dialogRef = this.dialog.open(ProgramareEroareComponent, {
                width: '750px',
                height: '650px',
                // data: "In zilele de marti programul terapeutului incepe la: " + this.datePipe.transform(this.selectedMedici.program.marti.OraStart, 'H:mm') + " si se incheie la ora: " + this.datePipe.transform(this.selectedMedici.program.marti.OraEnd, 'H:mm')
                // + ". Incerci sa faci o programare care incepe la  ora: " + this.datePipe.transform(vari, 'H:mm') + ". Doresti sa faci programarea inafara programului terapeutului? ",
              });
              dialogRef.afterClosed().subscribe(result => {
                console.log("closed eroare, result => ", result)
                if (result){
                  this.openProgramareDialogComponent(vari, this.getTotalCuanta())
                }
              }
              )

            }  else{
              if (!this.programdesomnselselectatFlag){

              this.openProgramareDialogComponent(vari, this.getTotalCuanta())}
            }
            break;
          case 3:
            if (
              ( (
                  ((vari.getHours() < new Date(this.selectedMedici.program.miercuri.OraStart).getHours()) ||
                (vari.getHours() >= new Date(this.selectedMedici.program.miercuri.OraEnd).getHours())) ||
                (
                (programare_noua_end_h < new Date(this.selectedMedici.program.miercuri.OraStart).getHours()) ||
                (programare_noua_end_h >= new Date(this.selectedMedici.program.miercuri.OraEnd).getHours()))) &&
                vari.getDay() === 3)
            )
            {
            const dialogRef = this.dialog.open(ProgramareEroareComponent, {
              width: '250px',
              height: '250px',
              // data: "In zilele de miercuri programul terapeutului incepe la: " + this.datePipe.transform(this.selectedMedici.program.miercuri.OraStart, 'H:mm') + " si se incheie la ora: " + this.datePipe.transform(this.selectedMedici.program.miercuri.OraEnd, 'H:mm')
              // + ". Incerci sa faci o programare care incepe la  ora: " + this.datePipe.transform(vari, 'H:mm') + ". Doresti sa faci programarea inafara programului terapeutului? ",
            });
            dialogRef.afterClosed().subscribe(result => {
              console.log("closed eroare, result => ", result)
              if (result){
                this.openProgramareDialogComponent(vari, this.getTotalCuanta())
              }
            }
            )
          }
          else{
            if (!this.programdesomnselselectatFlag){

            this.openProgramareDialogComponent(vari, this.getTotalCuanta())}
          }
          break;
          case 4:
            if (
              ( (
                  ((vari.getHours() < new Date(this.selectedMedici.program.joi.OraStart).getHours()) ||
                (vari.getHours() >= new Date(this.selectedMedici.program.joi.OraEnd).getHours())) ||
                (
                (programare_noua_end_h < new Date(this.selectedMedici.program.joi.OraStart).getHours()) ||
                (programare_noua_end_h >= new Date(this.selectedMedici.program.joi.OraEnd).getHours()))) &&
                vari.getDay() === 4)
            )
            {
            const dialogRef = this.dialog.open(ProgramareEroareComponent, {
              width: '250px',
              height: '250px',
              // data: "In zilele de joi programul terapeutului incepe la: " + this.datePipe.transform(this.selectedMedici.program.joi.OraStart, 'H:mm') + " si se incheie la ora: " + this.datePipe.transform(this.selectedMedici.program.joi.OraEnd, 'H:mm')
              // + ". Incerci sa faci o programare care incepe la  ora: " + this.datePipe.transform(vari, 'H:mm') + ". Doresti sa faci programarea inafara programului terapeutului? ",
            });
            dialogRef.afterClosed().subscribe(result => {
              console.log("closed eroare, result => ", result)
              if (result){
                this.openProgramareDialogComponent(vari, this.getTotalCuanta())
              }
            }
            )
          }
          else{
            if (!this.programdesomnselselectatFlag){

            this.openProgramareDialogComponent(vari, this.getTotalCuanta())}
          }
          break;
          case 5:
            if (
              ( (
                  ((vari.getHours() < new Date(this.selectedMedici.program.vineri.OraStart).getHours()) ||
                (vari.getHours() >= new Date(this.selectedMedici.program.vineri.OraEnd).getHours())) ||
                (
                (programare_noua_end_h < new Date(this.selectedMedici.program.vineri.OraStart).getHours()) ||
                (programare_noua_end_h >= new Date(this.selectedMedici.program.vineri.OraEnd).getHours()))) &&
                vari.getDay() === 5)
            )
            {
            const dialogRef = this.dialog.open(ProgramareEroareComponent, {
              width: '250px',
              height: '250px',
              // data: "In zilele de vineri programul terapeutului incepe la: " + this.datePipe.transform(this.selectedMedici.program.vineri.OraStart, 'H:mm') + " si se incheie la ora: " + this.datePipe.transform(this.selectedMedici.program.vineri.OraEnd, 'H:mm')
              // + ". Incerci sa faci o programare care incepe la  ora: " + this.datePipe.transform(vari, 'H:mm') + ". Doresti sa faci programarea inafara programului terapeutului? ",
            });
            dialogRef.afterClosed().subscribe(result => {
              console.log("closed eroare, result => ", result)
              if (result){
                this.openProgramareDialogComponent(vari, this.getTotalCuanta())
              }
            }
            )
          }
          else{
            if (!this.programdesomnselselectatFlag){

            this.openProgramareDialogComponent(vari, this.getTotalCuanta())}
          }
          break;
        }
      }

  }

  }

  openProgramareDialogComponent(date, durata){
    const dialogRef = this.dialog.open(ProgramaredialogComponent, {
      width: '750px',
      height: '650px',
      // data: {
      //   'data': date,
      //   'durata': durata
      // }
      data: {
        data: date,
        durata: durata
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        console.log("after closed ", result);
        if (result[1]) {
          var programare_recurenta = new programareRecurenta()
          console.log("after closed Selected Medici", this.selectedMedici.id);
          // programare_recurenta.medicid = this.selectedMedici.id
          // programare_recurenta.pacientid = this.selectedPacientId
          // programare_recurenta.start_date_pr = new Date(result[0])
          // programare_recurenta.end_date_pr = new Date ()
          programare_recurenta.zile = result[2]

          var programare_recurenta_id = this.programariRecurenteService.addProgramare(programare_recurenta)
          var lastDate = new Date(result[0])
          this.addprogramare(lastDate,programare_recurenta_id,true,programare_recurenta.zile)
          for (let i = 1; i < result[2]; i++) {
            // console.log("after closed add days ", this.addDays(lastDate,1));
            this.addprogramare(this.addDays(lastDate, 1),programare_recurenta_id,true,programare_recurenta.zile)
          }
          programare_recurenta.idsProgramari = this.programariService.getProgramaribyRecId(programare_recurenta_id)
          this.programariRecurenteService.updateInfo(programare_recurenta)
          this.refresh.next()

        }
        else {
          this.addprogramare(new Date(result[0]))
          this.refresh.next(this.view)
        }

        // this.addprogramare(result.value)
      }
      });
  }
  eventClicked({ event }: { event: CalendarEvent }): void {
    console.log('Event clicked -- ', event);
    const dialogRef = this.dialog.open(EditprogramareComponent, {
      width: '750px',
      height: '650px',
      data: event,
    });

  }
  addprogramare(datestart,recurentaid?:number, recurent?:boolean, recurenta_zile?:number) {
    console.log("add programare", datestart);
    var datas = new Date(datestart)
    var recurenta
    if(recurentaid){
      recurenta = recurentaid
    }

    var programarenoua: CalendarEvent = {
      title: 'Programare ' + this.selectedpacient.date_personale.nume + " " + this.selectedpacient.date_personale.prenume,
      start: datas,
      end: new Date(addHours(datestart, 1)),
      color: colors.red,
      // servicii: this.selectedservicii,
      medicid: Number(this.selectedMedici.id),
      pacientid: this.selectedPacientId,
      // departament: this.selectedDepartamente,
      // specialitate: [this.selectedSpecialitate],
      recurent: recurent,
      recurenta_zile: recurenta_zile,
      recurenta_id: recurentaid,
      durata: this.getTotalCuanta()
    }
    this.programariService.addProgramare(programarenoua)
    this.events = this.programariService.getProgramari()
    console.log("Calendar flag 1 ==> ", this.openCalendarFlag)
    this.openCalendarFlag = ! this.openCalendarFlag
    console.log("Calendar flag 2 ==> ", this.openCalendarFlag)

    // this.events = [
    //   ...this.events,
    //   {
    //     title: 'Programare ' + this.selectedpacient.date_personale.nume + " "+ this.selectedpacient.date_personale.prenume,
    //     start:datas,
    //     end: addHours(datestart, 1),
    //     color: colors.red,
    //     servicii: this.selectedservicii,
    //     medic: this.selectedMedici,
    //     pacient: this.selectedpacient,
    //     departament: this.selectedDepartamente,
    //     specialitatea: this.selectedSpecialitate
    //     // draggable: true,
    //     // resizable: {
    //     //   beforeStart: true,
    //     //   afterEnd: true,
    //     // },
    //   },
    // ];
    // console.log("events :", this.events)
    // this.openCalendarFlag = ! this.openCalendarFlag
    this.refresh.next(this.view)
  }

  openPacientDetailsComponent(){
    console.log(this.selectedpacient)
    const dialogRef = this.dialog.open(PacientdetailsComponent, {
      width: '950px',
      height: '850px',
      data: this.selectedpacient,
    });
  }

}

