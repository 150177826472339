<div class="content-title">
  <div class="cnt-text" style="padding-right: 25px;">Date Personale</div>
  <div style="display: inline-block; padding-right: 10px;" *ngIf="flag_date_personale_salvate">
    <button id="EditeazaDP"
      class="samplebtn e-control e-btn e-success"
      style="height:40px;width: 250px;" data-ripple="true" (click)="editeazaDatePersonale()">Editeaza Datele Personale</button>
  </div>
</div>
<form id="formId" [formGroup]="reactForm" #formDir="ngForm"
class="form-horizontal" novalidate="">

<div class="form-group " >
  <div class="e-float-input ">
    <input id="nume" type="text" formControlName="nume" [ngClass]="{'e-input-by-me-green': !nume.errors, 'e-input-by-me-red' : nume.errors }">
    <!-- <span class="e-float-line"></span> -->
    <label for="nume" class="e-float-text">Nume</label>
  </div>
  <div *ngIf="nume.errors">
    <div *ngIf="nume.errors.required && nume.touched" class="e-error">
      Camp Necesar.
    </div>
  </div>
</div>

<div class="form-group">
  <div class="e-float-input">
    <input id="prenume" type="text" formControlName="prenume" [ngClass]="{'e-input-by-me-green': !prenume.errors, 'e-input-by-me-red' : prenume.errors }">
    <!-- <span class="e-float-line"></span> -->
    <label for="prenume" class="e-float-text">Prenume</label>
  </div>
  <div *ngIf="prenume.errors">
    <div *ngIf="prenume.errors.required && prenume.touched" class="e-error">
      Camp Necesar.
    </div>
  </div>
</div>

<div class="form-group">
  <div class="">
    <ejs-datepicker id="data_nasterii" formControlName="data_nasterii"  format='dd-MM-yyyy'
      name="data_nasterii" placeholder="Data Nasterii"
      floatLabelType='Always'>
    </ejs-datepicker>
    <!-- <span class="e-float-line"></span> -->
    <div *ngIf="data_nasterii.errors ">
      <div *ngIf="data_nasterii.errors.date && data_nasterii.touched"
        class="e-error">
        Camp Necesar.
      </div>
    </div>
  </div>
</div>

<div class="form-group">
  <div class="e-float-input">
    <input id="cnp" formControlName="cnp" [ngClass]="{'e-input-by-me-green': !cnp.errors, 'e-input-by-me-red' : cnp.errors }">
    <!-- <span class="e-float-line"></span> -->
    <label class="e-float-text" for="cnp">CNP</label>
    <div *ngIf="cnp.errors ">
      <div *ngIf="cnp.errors.required && cnp.touched"
        class="e-error">
        Camp Necesar.
      </div>
    </div>
  </div>
</div>

<div class="form-group">
  <div class="e-float-input">
    <input  class="e-input-by-me-green" id="locul_nasterii" formControlName="locul_nasterii">
    <!-- <span class="e-float-line"></span> -->
    <label for="locul_nasterii" class="e-float-text">Locul Nasterii</label>
    <!-- <span class="e-float-line"></span> -->
  </div>
</div>


<ejs-checkbox name="Carte de Identitate" value="Carte de Identitate" label="Carte de Identitate" [(checked)]="flag_are_CI" [disabled]="flag_date_personale_salvate"></ejs-checkbox>

<div class="form-group" *ngIf="flag_are_CI">
  <div class="e-float-input">
    <input class="e-input-by-me-green" id="serie_carte_identitate" formControlName="serie_carte_identitate">
    <!-- <span class="e-float-line"></span> -->
    <label for="serie_carte_identitate" class="e-float-text">Serie CI</label>
  </div>
</div>

<div class="form-group" *ngIf="flag_are_CI">
  <div class="e-float-input">
    <input class="e-input-by-me-green" id="numar_carte_identitate" formControlName="numar_carte_identitate">
    <!-- <span class="e-float-line"></span> -->
    <label for="numar_carte_identitate" class="e-float-text">NR CI</label>
  </div>
</div>
<div class="break"></div>
<ng-container *ngIf="!flag_date_personale_salvate">
  <div class="row" style="width: 100%;">
    <div
      style="width: 320px;margin:0px auto;height: 100px;padding-top: 25px; display: flex; justify-content:center">
      <div style="display: inline-block; padding-right: 10px;">
        <button id="validateSubmit"
          class="samplebtn e-control e-btn e-success" type="submit"
          style="height:40px;width: 250px;" data-ripple="true">Salveaza Datele Personale</button>
      </div>
      <div style="float: right;">
        <button id="resetbtn" class="samplebtn e-primary e-btn"
          type="reset" style="height:40px;width: 150px;"
          data-ripple="true">Anuleaza</button>
      </div>
    </div>
  </div>
</ng-container>
</form>
