import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { map, tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators/catchError';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
  providedIn: 'root'
})
export class SmssenderService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient
  ) { }

  sendsms(numar){
    console.log("Numarul de telefon trimis ==>> ", numar)
    return this.http.get("https://semysms.net/api/3/sms.php?token=b71a57be9af76212b264a0049bf29ee8&device=293208&phone=%2B4"+numar+"&msg=Mesaj din aplicatia MNF", cudOptions)
  }
}
