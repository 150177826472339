import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-loginpage',
  templateUrl: './loginpage.component.html',
  styleUrls: ['./loginpage.component.css']
})
export class LoginpageComponent implements OnInit {
  emailFormControl = new FormControl()
  cupin:number = 0
  email: string
  telefon: string
  parola: string
  pin: string
  public_pin: string
  username: string
  constructor() { }

  ngOnInit(): void {
  }
  logincupin(){
    if (this.cupin == 0){
      this.cupin = 1
    }
    else{
      this.cupin = 0
    }
  }
}
