import { Component, Input, OnInit,ViewChild, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TabComponent,TabItemModel } from '@syncfusion/ej2-angular-navigations';
import { FormDatePersonaleComponent } from '../form-date-personale/form-date-personale.component';
import { DatePersonaleCreateInterface, PacientCreateInterface, PacientTwoServerInterface } from '../interfaces/pacient.interface';
import { DatePersonaleCreate, PacientTwoServer } from '../models/pacientdetails.model';
import { SwitchComponent } from '@syncfusion/ej2-angular-buttons';
@Component({
  selector: 'app-beneficiar-details-tab',
  templateUrl: './beneficiar-details-tab.component.html',
  styleUrls: ['./beneficiar-details-tab.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class BeneficiarDetailsTabComponent implements OnInit {
  @Input() beneficiar : PacientCreateInterface

  public flag_date_personale_can_load_component : boolean = false
  public flag_date_personale_salvat : boolean = false
  public flag_date_contact_salvat : boolean = false
  public flag_reprezentant_legal_salvat : boolean = false
  public flag_parsoana_contact_salvat : boolean = false
  public flag_date_personale_create : boolean = false
  public date_personale : DatePersonaleCreateInterface
  public date_personale_din_component : DatePersonaleCreateInterface = new DatePersonaleCreate()
  public date_personale_id : number
  public flag_DP_beneficiar_modificat : boolean = false


  // @ViewChild('switchIndependenta') public switchIndependentaV: SwitchComponent;
  public headerText: Object = [
    { 'text': 'Date Personale','iconCss': this.flag_date_personale_salvat ? 'e-checked' : 'e-no-checked', 'iconPosition':'Right'},
    { 'text': 'Date de Contact','iconCss': this.flag_date_contact_salvat ? 'e-checked' : 'e-no-checked', 'iconPosition':'Right' },
    { 'text': 'Reprezentant Legal','iconCss': this.flag_reprezentant_legal_salvat ? 'e-checked' : 'e-no-checked', 'iconPosition':'Right' },
    { 'text': 'Persoana de contact','iconCss': this.flag_parsoana_contact_salvat ? 'e-checked' : 'e-no-checked', 'iconPosition':'Right' }
  ];

  public content0: string = 'Twitter is an online social networking service that enables users to send and read short 140-character ' +
       'messages called "tweets". Registered users can read and post tweets, but those who are unregistered can only read ' +
       'them. Users access Twitter through the website interface, SMS or mobile device app Twitter Inc. is based in San ' +
       'Francisco and has more than 25 offices around the world. Twitter was created in March 2006 by Jack Dorsey, ' +
       'Evan Williams, Biz Stone, and Noah Glass and launched in July 2006. The service rapidly gained worldwide popularity, ' +
   'with more than 100 million users posting 340 million tweets a day in 2012.The service also handled 1.6 billion ' +
   'search queries per day.';

public content1: string = 'Facebook is an online social networking service headquartered in Menlo Park, California. Its website was ' +
   'launched on February 4, 2004, by Mark Zuckerberg with his Harvard College roommates and fellow students Eduardo ' +
   'Saverin, Andrew McCollum, Dustin Moskovitz and Chris Hughes.The founders had initially limited the website\'\s ' +
   'membership to Harvard students, but later expanded it to colleges in the Boston area, the Ivy League, and Stanford ' +
   'University. It gradually added support for students at various other universities and later to high-school students.';

public content2: string = 'WhatsApp Messenger is a proprietary cross-platform instant messaging client for smartphones that operates ' +
   'under a subscription business model. It uses the Internet to send text messages, images, video, user location and ' +
   'audio media messages to other users using standard cellular mobile numbers. As of February 2016, WhatsApp had a user ' +
   'base of up to one billion,[10] making it the most globally popular messaging application. WhatsApp Inc., based in ' +
   'Mountain View, California, was acquired by Facebook Inc. on February 19, 2014, for approximately US6. 9.3 billi';
  constructor() { }

  ngOnInit(): void {
    console.log ("BENEFICIAR ==>> ", this.beneficiar)
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.beneficiar.currentValue){
      console.log ("BENEFICIAR UPDATED ==>> ", this.beneficiar)
      this.date_personale = this.beneficiar.date_personale
      this.date_personale_id = this.beneficiar.datepersonaleid
      this.flag_date_personale_can_load_component = true
    }
  }

  ngOnDestroy() {
    console.log('Beneficiar Details Tab :OnDestroy');
  }

  public created(event) {
      // this.switchIndependentaV.toggle();
  }

  gradDeDependentaChanged(ev){
    console.log("Noul Grad de DEPENDENTA ==>> ev ==>> ", ev)
    this.beneficiar.independent = ev.checked
    console.log("Noul Grad de DEPENDENTA ==>> ", this.beneficiar.independent)
  }

  datePersonaleFromComponent(datePersonalePrimiteDinComponent : DatePersonaleCreate){
    this.date_personale_din_component = datePersonalePrimiteDinComponent
    console.log("Date personale primite ==>> ", this.date_personale_din_component)
  }

  flagDatePersonaleModificat(flag:boolean){
    this.flag_DP_beneficiar_modificat = flag
    console.log("flag_modificat primite ==>> ", this.flag_DP_beneficiar_modificat)
  }

}


