import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';

export interface Pacient{

  nume: string;
  prenume: string;
  diagnostic: string;
  email:string;
  telefon:string;
}

const ELEMENT_DATA: Pacient[] = [
  {nume: 'Ion', prenume:"Popescu", diagnostic:"diagnostic test" ,email:"dhwon@gmail.com", telefon:"+40 712 018 528" },
  {nume: 'Maria', prenume:"Ionescu", diagnostic:"diagnostic test" ,email:"rhialto@msn.com", telefon:"+40 702 054 317" },
];

@Component({
  selector: 'app-lista-asteptare',
  templateUrl: './lista-asteptare.component.html',
  styleUrls: ['./lista-asteptare.component.css']
})
export class ListaAsteptareComponent implements OnInit {
  displayedColumns: string[] = ['nume', 'prenume', "diagnostic", "email", "telefon"];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  constructor() { }

  ngOnInit(): void {
  }

}
