import { Injectable } from '@angular/core';
import { Servicii } from '../models/nomenclatoare.model';
import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {catchError} from 'rxjs/operators/catchError';
import { Observable } from 'rxjs';
import { ServiciiInterface } from '../interfaces/nomenclatoare.interface';
import { serverurl } from './server.service';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};


@Injectable({
  providedIn: 'root'
})

export class ServiciiService {
  public servicii: Servicii [] = []
  public localstorageflag = 0;
  // private server_url="api/servicii";
  private server_url=serverurl+"/api/v1/serviciu/";
  // private server_url="http://localhost/api/v1/serviciu/";
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    let data = this.localStorageService.get("Servicii");
    // console.log("On Initi DAta Local storage => ",data)
    if (data)
    {
      this.localstorageflag = 1;
      this.servicii = data;
      this.servicii.forEach(element=> {
        this.http.post<Servicii>(this.server_url, element, cudOptions).pipe(
          catchError(this.handleError)
            )
          });
      }
    else {
      // console.log("PacientList nu este Local")
    }

   }

  getServicii(){
    return this.servicii;
  }
  getServiciiFromServer(){
    return this.http.get<ServiciiInterface[]>(this.server_url)
  }
  deletefromserver(id){
    return this.http.delete(this.server_url+id)
  }
  addServiciiToServer(serviciu){
    return(this.http.post<any>(this.server_url,serviciu,this.httpOptions))
  }
  updateServiciuToServer(serviciu){
    return(this.http.put<any>(this.server_url+serviciu.id,serviciu,this.httpOptions))
  }
  addServicii(serviciu){
    this.servicii.push(serviciu);
    if (this.localstorageflag){
      this.localStorageService.remove("Servicii");
      this.addtolocalStorage(this.servicii);
    }
    else {
      this.addtolocalStorage(this.servicii);
    }
    console.log("Lista pacienti din pacienti service =>", this.servicii);

    return this.http.post<Servicii>(this.server_url, serviciu, cudOptions).pipe(
      catchError(this.handleError)
    );
  }
  addtolocalStorage(PacientList){
    console.log("addtolocalstorage CategorieServicii: =>", PacientList);
    console.log("Addtolocalstorage",this.localStorageService.set("Servicii", PacientList));
  }
  private handleError(error: Response){
    console.log("Error from Pacient Service: => " + error)
    return Observable.throw("500 internal server error" )
  }
}
