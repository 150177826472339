<!-- <mat-form-field>
    <input matInput [(ngModel)]="newMedic.codParafa" placeholder="Cod Parafa">
 </mat-form-field>
 <br> -->
 <mat-card *ngIf="flag_add_medic">
  <mat-card-header style="padding-left: 35%;" >
    <mat-card-title > Detalii: </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list  cols="8" rowHeight="65px" >
     <mat-grid-tile class = "bordurica-mat-grid-list"  colspan="2" >
       <mat-form-field>
           <input matInput [(ngModel)]="newMedic.nume" name="scenarioName" placeholder="Nume" required>
           <mat-hint class="error-hint" *ngIf="numeError">Numele este obligatoriu</mat-hint>
        </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2" >
       <mat-form-field>
         <input matInput [(ngModel)]="newMedic.prenume" placeholder="Prenume" required>
           <mat-hint class="error-hint" *ngIf="prenumeError">Prenumele este obligatoriu</mat-hint>
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2"  >
       <mat-form-field>
         <input matInput [(ngModel)]="newMedic.telefon" placeholder="Telefon" required>
           <mat-hint class="error-hint" *ngIf="telefonError">Telefonul este obligatoriu</mat-hint>
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2"  >
       <mat-form-field>
         <input matInput [(ngModel)]="newMedic.mail" placeholder="Mail" required>
         <mat-hint class="error-hint" *ngIf="emailError">Email-ul este obligatoriu</mat-hint>
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="1"  >
       <mat-form-field style="width: 90%;" >
         <mat-label>Sex</mat-label>
         <mat-select [(ngModel)]="newMedic.sex" required>
           <mat-option value="masculin">Masculin</mat-option>
           <mat-option value="feminin">Feminin</mat-option>
         </mat-select>
         <mat-hint class="error-hint" *ngIf="sexError">Selectarea sexului este obligatorie</mat-hint>
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="1"  >
       <mat-form-field style="width: 90%;">
         <mat-label>Greutate</mat-label>
         <input matInput type="text" [(ngModel)]= "newMedic.greutate" required>
         <span matSuffix>kg</span>
           <mat-hint class="error-hint" *ngIf="greutateError">Greutatea este obligatorie</mat-hint>
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2"  >
       <mat-form-field>
         <input matInput [(ngModel)]="newMedic.cnp" placeholder="CNP">
        </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2"  >
       <mat-form-field>
         <mat-label>Specialitate</mat-label>
         <mat-select [(value)] = newMedic.specialitatea_id >
             <mat-option *ngFor="let specialitate of specialitateMedici" [value]="specialitate.id">
                 {{specialitate.denumire}}
             </mat-option>
         </mat-select>
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2"  >
       <mat-form-field>
         <mat-label>Servicii</mat-label>
         <mat-select [(value)] = newMedic.servicii_id required>
             <mat-option *ngFor="let serviciu of servicii" [value]="serviciu.id">
                 {{serviciu.denumire}}
             </mat-option>
         </mat-select>
           <mat-hint class="error-hint" *ngIf="serviciiError">Selectarea unui este obligatoriu</mat-hint>
         </mat-form-field>
     </mat-grid-tile>
   </mat-grid-list>
  </mat-card-content>
 </mat-card>

<br>
<button mat-raised-button color="primary" (click)="showNewProgram()" *ngIf="!flag_add_program && flag_add_medic && !flag_form_errors"> Adauga Program </button>
<button mat-raised-button class="btngalben" (click)="showNewProgram()" *ngIf="!flag_add_program && flag_add_medic && flag_form_errors"> Adauga Program </button>
<mat-hint class="error-hint" style="padding-left:10px" *ngIf="flag_form_errors">Corecteaza erorile si incearca din nou</mat-hint>

<div *ngIf="flag_add_program">

  <br>
  <!-- Card-ul cu Programul -->
  <mat-card style="width: 100%;max-height: 550px;text-align: center;">
    <mat-card-header style="padding-left: 35%;">
        <mat-card-title > Program: </mat-card-title>
      <!-- <mat-card-subtitle> {{ (selectedpacient.date_personale.data_nasterii | date: 'd.MM.YYYY') + " -- " + varsta_cronologica + " ani" }} </mat-card-subtitle> -->
    </mat-card-header>
<mat-card-content>
  <strong>
    <mat-grid-list cols="5"  [style.border] = '30' *ngIf = "!flag_fixed_program" [rowHeight]="inaltime_randuri_grid_program" #programpezile id="programpezile">
      <mat-grid-tile class = "bordurica-mat-grid-list" #zidinprogram id="zidinprogram" >
        Luni
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" #zidinprogram id="zidinprogram">
      Marti
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" #zidinprogram id="zidinprogram"  >
      Miercuri
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" #zidinprogram id="zidinprogram"  >
      Joi
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" #zidinprogram id="zidinprogram"  >
      Vineri
      </mat-grid-tile>

      <mat-grid-tile class = "bordurica-mat-grid-list" [rowspan] = "row_span_grid_program" >

      <div style="display: flex;align-items: center;" #intervaldinprogram id="intervaldinprogram">

        <ngx-mat-timepicker
              [(ngModel)]="newMedic.program.luni.OraStart"
              [showSpinners]="true">
        </ngx-mat-timepicker>

        <div style="height:100%;" #separatortimepickerintervale id="separatortimepickerintervale"> -  </div>

        <ngx-mat-timepicker
        [(ngModel)]="newMedic.program.luni.OraEnd"
        [showSpinners]="true">
        </ngx-mat-timepicker>
        </div>

      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list"  [rowspan] = "row_span_grid_program">

      <div style="display: flex;align-items: center;" #intervaldinprogram id="intervaldinprogram">

        <ngx-mat-timepicker
              [(ngModel)]="newMedic.program.marti.OraStart"
              [showSpinners]="true">
        </ngx-mat-timepicker>

        <div style="height:100%;" #separatortimepickerintervale id="separatortimepickerintervale"> -  </div>

        <ngx-mat-timepicker
        [(ngModel)]="newMedic.program.marti.OraEnd"
        [showSpinners]="true">
        </ngx-mat-timepicker>
        </div>

      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" [rowspan] = "row_span_grid_program" >

      <div style="display: flex;align-items: center;" #intervaldinprogram id="intervaldinprogram">

      <ngx-mat-timepicker
            [(ngModel)]="newMedic.program.miercuri.OraStart"
            [showSpinners]="true">
      </ngx-mat-timepicker>

      <div style="height:100%;" #separatortimepickerintervale id="separatortimepickerintervale"> -  </div>

      <ngx-mat-timepicker
      [(ngModel)]="newMedic.program.miercuri.OraEnd"
      [showSpinners]="true">
      </ngx-mat-timepicker>
      </div>

      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" [rowspan] = "row_span_grid_program" >

      <div style="display: flex;align-items: center;" #intervaldinprogram id="intervaldinprogram">

      <ngx-mat-timepicker
          [(ngModel)]="newMedic.program.joi.OraStart"
          [showSpinners]="true">
      </ngx-mat-timepicker>

      <div style="height:100%;" #separatortimepickerintervale id="separatortimepickerintervale"> -  </div>

      <ngx-mat-timepicker
      [(ngModel)]="newMedic.program.joi.OraEnd"
      [showSpinners]="true">
      </ngx-mat-timepicker>
      </div>

      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" [rowspan] = "row_span_grid_program" >

      <div style="display: flex;align-items: center;" #intervaldinprogram id="intervaldinprogram">

      <ngx-mat-timepicker
        [(ngModel)]="newMedic.program.vineri.OraStart"
        [showSpinners]="true">
      </ngx-mat-timepicker>

      <div style="height:100%;" #separatortimepickerintervale id="separatortimepickerintervale"> -  </div>

      <ngx-mat-timepicker
      [(ngModel)]="newMedic.program.vineri.OraEnd"
      [showSpinners]="true">
      </ngx-mat-timepicker>
      </div>

      </mat-grid-tile>
    </mat-grid-list>
  </strong>
  <br>
  <button mat-raised-button color="primary" (click)="addProgramToMedic()" *ngIf = "!flag_fixed_program" > Adauga programul</button>
  <div *ngIf="flag_fixed_program">
    <table mat-table [dataSource]="programDataSource" class="mat-elevation-z8" style="width: 100%;">

      <ng-container matColumnDef="Luni">
         <th mat-header-cell *matHeaderCellDef > Luni </th>
         <td mat-cell *matCellDef="let element"> {{element.program.luni.OraStart | date: ' H:mm ' }} - {{ element.program.luni.OraEnd | date: ' H:mm '  }} </td>
     </ng-container>

      <ng-container matColumnDef="Marti">
          <th mat-header-cell *matHeaderCellDef> Marti </th>
          <td mat-cell *matCellDef="let element"> {{element.program.marti.OraStart | date: ' H:mm ' }} - {{ element.program.marti.OraEnd | date: ' H:mm ' }} </td>
      </ng-container>

      <ng-container matColumnDef="Miercuri">
          <th mat-header-cell *matHeaderCellDef> Miercuri </th>
          <td mat-cell *matCellDef="let element"> {{element.program.miercuri.OraStart | date: ' H:mm ' }} - {{ element.program.miercuri.OraEnd | date: ' H:mm ' }} </td>
      </ng-container>

      <ng-container matColumnDef="Joi">
          <th mat-header-cell *matHeaderCellDef> Joi </th>
          <td mat-cell *matCellDef="let element"> {{element.program.joi.OraStart | date: ' H:mm ' }} - {{ element.program.joi.OraEnd | date: ' H:mm ' }} </td>
      </ng-container>

      <ng-container matColumnDef="Vineri">
          <th mat-header-cell *matHeaderCellDef> Vineri </th>
          <td mat-cell *matCellDef="let element"> {{element.program.vineri.OraStart | date: ' H:mm ' }} - {{ element.program.vineri.OraEnd | date: ' H:mm ' }} </td>
      </ng-container>
      <ng-container matColumnDef="Edit">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button class="save-button" (click)="openProgramEdit(element)"> Edit</button>
        </td>
    </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsProgram"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsProgram;"></tr>

  </table>
  </div>

</mat-card-content>

  </mat-card>

</div>

<br>
<button mat-raised-button color="primary" (click)="addIntervaleOrare()" *ngIf="flag_fixed_program && !flag_add_intervale_orare"> Adauga intervale orare</button>
<br>
  <mat-card style="width: 100%;max-height: 500px;" *ngIf="flag_add_intervale_orare">

    <mat-card-content >
      <mat-grid-list cols="2" rowHeight={{inaltime_card}} #listaadaugaintervale id="listaadaugaintervale" >
        <mat-grid-tile   >
          <div style="display: flex;align-self: flex-start;" id = "intervalorastart" #intervalorastart>

            <ngx-mat-timepicker
                  [(ngModel)]="interval_orar_start"
                  [showSpinners]="true">
            </ngx-mat-timepicker>

            <div style="height:100%;" #separatortimepicker id="separatortimepicker"> -  </div>

            <ngx-mat-timepicker
            [(ngModel)]="interval_orar_end"
            [showSpinners]="true">
            </ngx-mat-timepicker>
            </div>
            <br>

            <button *ngIf="!flag_editeaza_interval_orar" mat-raised-button color="primary" (click)="addNewInterval()" style="display: flex;align-self: flex-start;" id="adaugaintervalbutton" #adaugaintervalbutton> Adauga Interval</button>
            <button *ngIf="flag_editeaza_interval_orar" mat-raised-button class="save-button-two" (click)="salveazaInterval()" style="display: flex;align-self: flex-start;" id="adaugaintervalbutton" #adaugaintervalbutton> Salveaza Modificarile</button>

        </mat-grid-tile>
        <mat-grid-tile  style="overflow: scroll;">
          <table mat-table [dataSource]="intervaleDataSource" class="mat-elevation-z8" style="width: 90%;" id="tabelintervale" #tabelintervale>
            <ng-container matColumnDef="interval">
              <th mat-header-cell *matHeaderCellDef> <b>Intervale</b> </th>
              <td mat-cell *matCellDef="let element" style="text-align:center"> {{element.start }} - {{ element.end }} </td>
            </ng-container>
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="save-button" (click)="editeazaInterval(element)" [disabled]="flag_editeaza_interval_orar && interval_editat_index!=intervale_orare.indexOf(element)">edit</button>
              </td>
            </ng-container>

            <ng-container matColumnDef="intervalselect">
              <th mat-header-cell *matHeaderCellDef>
                <button  mat-raised-button color="warn" [disabled]="intervale_orare_todelete.length == 0" (click)="deleteIntervaleOrare()" > Sterge </button>
              </th>
              <td mat-cell *matCellDef="let element">

                <mat-checkbox (change)="marktodelete(element)"></mat-checkbox>

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsIntervale" style="height: 35px;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsIntervale;" style="height: 35px;"></tr>
          </table>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>

  </mat-card>
<br>
 <button mat-raised-button color="primary" (click)="addNewMedic()" *ngIf="!flag_add_medic"> Adauga un terapeut nou</button>
 <button mat-raised-button color="primary" (click)="showAllMedici()" *ngIf="!flag_add_medic && !flag_show_all_medici"> Afiseaza terapeuti</button>
 <button mat-raised-button color="primary" (click)="AddMedicToServer()" *ngIf="flag_add_medic && flag_fixed_program && flag_fixed_intervale_orare "> Adauga Terapeut</button>
<div [hidden]="!flag_show_all_medici">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

     <!-- ID Column -->
     <ng-container matColumnDef="Nume">
     <th mat-header-cell *matHeaderCellDef> Nume </th>
     <td mat-cell *matCellDef="let element"> {{element.nume}} </td>
     </ng-container>

     <ng-container matColumnDef="Prenume">
         <th mat-header-cell *matHeaderCellDef> Prenume </th>
         <td mat-cell *matCellDef="let element"> {{element.prenume}} </td>
     </ng-container>

     <ng-container matColumnDef="Luni">
        <th mat-header-cell *matHeaderCellDef> Luni </th>
        <td mat-cell *matCellDef="let element"> {{element.program.luni.OraStart | date: ' H:mm ' }} - {{ element.program.luni.OraEnd | date: ' H:mm '  }} </td>
    </ng-container>

     <ng-container matColumnDef="Marti">
         <th mat-header-cell *matHeaderCellDef> Marti </th>
         <td mat-cell *matCellDef="let element"> {{element.program.marti.OraStart | date: ' H:mm ' }} - {{ element.program.marti.OraEnd | date: ' H:mm ' }} </td>
     </ng-container>

     <ng-container matColumnDef="Miercuri">
         <th mat-header-cell *matHeaderCellDef> Miercuri </th>
         <td mat-cell *matCellDef="let element"> {{element.program.miercuri.OraStart | date: ' H:mm ' }} - {{ element.program.miercuri.OraEnd | date: ' H:mm ' }} </td>
     </ng-container>

     <ng-container matColumnDef="Joi">
         <th mat-header-cell *matHeaderCellDef> Joi </th>
         <td mat-cell *matCellDef="let element"> {{element.program.joi.OraStart | date: ' H:mm ' }} - {{ element.program.joi.OraEnd | date: ' H:mm ' }} </td>
     </ng-container>

     <ng-container matColumnDef="Vineri">
         <th mat-header-cell *matHeaderCellDef> Vineri </th>
         <td mat-cell *matCellDef="let element"> {{element.program.vineri.OraStart | date: ' H:mm ' }} - {{ element.program.vineri.OraEnd | date: ' H:mm ' }} </td>
     </ng-container>

     <ng-container matColumnDef="Telefon">
      <th mat-header-cell *matHeaderCellDef> Telefon </th>
      <td mat-cell *matCellDef="let element"> {{element.telefon}}</td>
    </ng-container>

    <ng-container matColumnDef="Email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.mail}}</td>
    </ng-container>

    <ng-container matColumnDef="Servicii">
      <th mat-header-cell *matHeaderCellDef> Servicii </th>
      <td mat-cell *matCellDef="let element"> {{  (afla_numele_serviciului(element.servicii_id)!=null)  ? afla_numele_serviciului(element.servicii_id) : ' - ' }}</td>
    </ng-container>


     <ng-container matColumnDef="Edit">
       <th mat-header-cell *matHeaderCellDef>  </th>
       <td mat-cell *matCellDef="let element">
         <button mat-raised-button class="save-button" (click)="openMedicEdit(element)"> Edit</button>
       </td>
   </ng-container>

     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
     <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons ></mat-paginator>
</div>
<button mat-raised-button color="warn" mat-dialog-close >Inchide</button>

 <!-- <mat-form-field>
     <input matInput [(ngModel)]="newMedic.certificatDigital" placeholder="Certificat Digital">
  </mat-form-field> -->
      <!-- <br>
      <mat-form-field>
          <input matInput [(ngModel)]="newMedic.contract_CNAS.numar" placeholder="Numar Contract CNAS">
       </mat-form-field> -->

    <!-- <br>
  <mat-checkbox [(ngModel)]="newMedic.contract_CNAS.arevalabilitate"> Valabilitate: </mat-checkbox>

  <mat-form-field >
     <mat-label>De la</mat-label>
     <input matInput [matDatepicker]="picker" [(ngModel)]= "newMedic.contract_CNAS.valabilitate.start" [disabled]="!newMedic.contract_CNAS.arevalabilitate">
     <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
     <mat-datepicker #picker></mat-datepicker>
   </mat-form-field>
   <mat-form-field >
     <mat-label>Pana la</mat-label>
     <input matInput [matDatepicker]="picker2" [(ngModel)]= "newMedic.contract_CNAS.valabilitate.end" [disabled]="!newMedic.contract_CNAS.arevalabilitate">
     <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
     <mat-datepicker #picker2></mat-datepicker>
   </mat-form-field> -->
   <!-- <br> -->
   <!-- <mat-form-field>
    <mat-label>Departament</mat-label>
    <mat-select [(value)] = newMedic.departament_id >
        <mat-option *ngFor="let departament of departamente" [value]="departament.id">
            {{departament.denumire}}
        </mat-option>
    </mat-select>
    </mat-form-field> -->
