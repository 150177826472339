import { Component, OnInit,ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { dynamic_menu, dynamic_menu_items } from 'src/app/interfaces/dynamic-menu.interface';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {
  selected:string;
  @ViewChild("menu", {static: true}) menu: MatMenu;
  @Input() items: dynamic_menu_items[];
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }
  select(pText :string)
  {
    this.notify.emit(pText);
  }
}