<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> EVALUAREA ABILITĂȚILOR DE VIAȚA INDEPENDENTĂ</h2></span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Nume: {{ fisa_evaluare_independenta.nume_pacient + " " + fisa_evaluare_independenta.prenume_pacient}} </span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Data Nasterii: {{fisa_evaluare_independenta.data_nasterii_pacient | date:'d.MM.yyyy'}}
</span>
<!-- <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Vârsta cronologică: {{fisa_evaluare_independenta.data_nasterii_pacient | date:'d.MM.yyyy'}}
</span> -->

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> SCALA DE INCAPACITATE BARTHEL</h2></span>

<table mat-table [dataSource]="datasourceTabelBarthel" style="width: 100%;">
    <ng-container matColumnDef="Activitate">
        <th mat-header-cell *matHeaderCellDef> Activitate
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.titlu}} </b>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>
    <ng-container matColumnDef="Descriere">
        <th mat-header-cell *matHeaderCellDef> Descriere
        </th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngFor=" let details of element.descriere " >
                <br>
                {{ details.detalii  + ": " + details.puncte}}
            </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
            Scor :
        </td>
    </ng-container>
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> id
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.id}} </b>

        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>
    <ng-container matColumnDef="Scor">
        <th mat-header-cell *matHeaderCellDef> Scor
        </th>
		<td mat-cell *matCellDef="let element">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'redfundal': element.scor > 0, 'notallowed': element.scor == 0  }"
            (click) = "((element.scor > 0)?(element.scor = (numberedversion(element.scor) - 5)).toString(): undefined) ;refreshDS()"
            >remove</mat-icon>
            <input matInput type="number" style="width: 35px; text-align: center;vertical-align: middle;" [(ngModel)]="element.scor">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'greenfundal': element.scor < element.descriere[0].puncte, 'notallowed': element.scor == element.descriere[0].puncte  }"
            (click) = "((element.scor < element.descriere[0].puncte)?(element.scor = (numberedversion(element.scor) + 5).toString()): undefined) ;refreshDS()"
            >add</mat-icon>
            &nbsp;&nbsp;&nbsp;
            <mat-slider
            [max]="element.descriere[0].puncte" [(ngModel)]="element.scor" [min]="0" [thumbLabel]="true" [step]="5" [tickInterval]="5" (change)="change($event)" >
            </mat-slider>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
            {{gettotalbarthel()}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedcolumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedcolumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedcolumns;"></tr>
</table>

<mat-form-field style="width: 100%;" >
    <mat-label>Mentiuni: </mat-label>
    <textarea matInput [(ngModel)]="fisa_evaluare_independenta.tabel_scala_barthel.mentiuni_scala_barthel" ></textarea>
</mat-form-field>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> Scor ADL</h2></span>

<table mat-table [dataSource]="datasourceScorADL" style="width: 100%;">
    <ng-container matColumnDef="Activitate">
        <th mat-header-cell *matHeaderCellDef> Activitate
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.titlu}} </b>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>
    <ng-container matColumnDef="Descriere">
        <th mat-header-cell *matHeaderCellDef> Descriere
        </th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngFor=" let details of element.descriere " >
                <br>
                {{ details.detalii  + ": " + details.puncte}}
            </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
            Scor :
        </td>
    </ng-container>
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> id
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.id}} </b>

        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>
    <ng-container matColumnDef="Scor">
        <th mat-header-cell *matHeaderCellDef> Scor
        </th>
		<td mat-cell *matCellDef="let element">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'redfundal': element.scor > 0, 'notallowed': element.scor == 0  }"
            (click) = "((element.scor > 0)?(element.scor = (numberedversion(element.scor) - 1)).toString(): undefined) ;refreshDS()"
            >remove</mat-icon>
            <input matInput type="number" style="width: 35px; text-align: center;vertical-align: middle;" [(ngModel)]="element.scor">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'greenfundal': element.scor < element.descriere[0].puncte, 'notallowed': element.scor == element.descriere[0].puncte  }"
            (click) = "((element.scor < element.descriere[0].puncte)?(element.scor = (numberedversion(element.scor) + 1).toString()): undefined) ;refreshDS()"
            >add</mat-icon>
            &nbsp;&nbsp;&nbsp;
            <mat-slider
            [max]="element.descriere[0].puncte" [(ngModel)]="element.scor" [min]="0" [thumbLabel]="true" [step]="1" [tickInterval]="1" (change)="change($event)" >
            </mat-slider>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
            {{gettotalADL()}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedcolumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedcolumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedcolumns;"></tr>
</table>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> Clasificare : {{fisa_evaluare_independenta?.scor_adl?.calificare_tabel_adl }}</h2></span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> st. I - 10 puncte - autonom</h3></span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> st. II - 8 - 10 puncte - cvasiindependent</h3></span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> st. III - 3 - 8 puncte - independenta asistata</h3></span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h3> st. IV - 0 - 3 puncte - dependenta totala</h3></span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> SCALA ADL (activitățile vieții zilnice)</h2></span>

<table mat-table [dataSource]="datasourceScalaADL" style="width: 100%;">
    <ng-container matColumnDef="Activitate">
        <th mat-header-cell *matHeaderCellDef> Activitate
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.titlu}} </b>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> id
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.id}} </b>

        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>

    <ng-container matColumnDef="Slider">
        <th mat-header-cell *matHeaderCellDef> Independent - Cu asistenta minima - Cu asistenta Majora - Dependent
        </th>
		<td mat-cell *matCellDef="let element">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'redfundal': element.valoare > 0, 'notallowed': element.valoare == 0  }"
            (click) = "((element.valoare > 0)?(element.valoare = (numberedversion(element.valoare) - 1)).toString(): undefined) ;refreshDS()"
            >remove</mat-icon>
            <input matInput type="text" style=" width:30%;text-align: center;vertical-align: middle;" [value]="categorii[element.valoare]">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'greenfundal': element.valoare < 3, 'notallowed': element.valoare == 3  }"
            (click) = "((element.valoare < 3)?(element.valoare = (numberedversion(element.valoare) + 1).toString()): undefined) ;refreshDS()"
            >add</mat-icon>
            &nbsp;&nbsp;&nbsp;
            <mat-slider
            [max]="3" [(ngModel)]="element.valoare" [min]="0" [thumbLabel]="true" [step]="1" [tickInterval]="1" (change)="changeslider($event)" >
            </mat-slider>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
            {{gettotalADL()}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedcolumnsScalaADL"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedcolumnsScalaADL"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedcolumnsScalaADL;"></tr>
</table>

<mat-form-field style="width: 100%;" >
    <mat-label>Mentiuni: </mat-label>
    <textarea matInput [(ngModel)]="fisa_evaluare_independenta.scala_adl.mentiuni_scala_adl" ></textarea>
</mat-form-field>
<ng-container *ngIf="caz == 1">

<button mat-raised-button (click)="semneazaRL()" > Semnatura Reprezentant Legal</button>
<button mat-raised-button (click)="semneazaTerapeut()"  > Semnatura Terapeut</button>
<button mat-raised-button (click)="openPDFPreviewComponent()"  > Previzualizeaza </button>
</ng-container>
<ng-container *ngIf="caz == 2">
  <button mat-raised-button (click)="openPDFPreviewSsaveModificari()"  > Genereaza Document </button>
  <button mat-raised-button color="warn"  mat-dialog-close > Inchide </button>
</ng-container>
