import { ChangeDetectionStrategy, Component, Input, OnInit, Output, ViewEncapsulation,EventEmitter } from '@angular/core';
import { CalendarView, CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent, CalendarDayViewBeforeRenderEvent } from 'angular-calendar';
import { CalendarDateFormatter,
  DAYS_OF_WEEK } from 'angular-calendar';
  import {
    startOfDay,
    endOfDay,
    subDays,
    addDays,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours,
  } from 'date-fns';
  import { CalendarUtilsModule } from '../calendar-utils/module/module.module';
  import { interval, Subject } from 'rxjs';

import { CustomDateFormatter } from "../programari/custom-date-formatter.provider";
import { Medici } from '../models/nomenclatoare.model';
import { ProgramariService } from '../services/programari.service';
import { EditprogramareComponent } from '../editprogramare/editprogramare.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

import { IntervaleZilniceInterface, IntervaleZilniceCreateInterface,IntervalInterface,IntervalCreateInterface,IntervalnewCreateInterface,IntervalnewInterface ,TipplataInterface, IncasaricreateInterface, CasierieDateDinModalAdaugaServiciuInterface, ServiciiInterface, TipDepartamentInterface, TipDepartamentCreateInterface, TipDepartamentCreateFaraValabilitateInterface , TipuriDeServiciiInterface,TipuriDeServiciiCreateInterface,TipuriDeServiciiCreateFaraValabilitateInterface,specialitateMediciCreateInterface, specialitateMediciInterface, specialitateMediciCreateFaraValabilitateInterface, TipDiagnosticCreateFaraValabilitateInterface, TipDiagnosticCreateInterface, TipDiagnosticInterface, DiagnosticCreateFaraValabilitateInterface, DiagnosticCreateInterface, DiagnosticInterface, DiagnosticCreateFaraTipDiagnosticInterface, CasieriiServiciiInterface    } from '../interfaces/nomenclatoare.interface';
// import { WeekViewHour, WeekViewHourSegment } from 'calendar-utils';


@Component({
  selector: 'mwl-demo-component',
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ],
  encapsulation: ViewEncapsulation.None,
  styleUrls:['./calendar.component.css']
})


export class CalendarComponentx  implements OnInit {
  @Input() start_program_somn?: Date
  @Input() end_program_somn?: Date
  @Input() orar_somn?: boolean = false
  @Input() medic?: Medici
  @Input() pacientid?: number
  @Output() dateclicked = new EventEmitter<Date>();
  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  hsegments: number = 1
  segments: number[] = [1, 2, 4, 6, 12, 60]
  segmnets_minutes: number[] = [60, 30, 15, 10, 5, 1]
  events: CalendarEvent[] = [];
  locale: string = "ro";
  dayStartHour: number
  dayEndHour: number
  startHourProgram: number = 23
  startMinuteProgram: number = 59
  endHourProgram: number = 0
  endMinuteProgram: number = 0
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  // excludeDays: number[] = [];
  excludeDays: number[] = [0, 6];
  CalendarView = CalendarView;
  refresh: Subject<any> = new Subject();
  segmente_orare: IntervaleZilniceCreateInterface[] = []
  programari: any[] = []
  trackTimeLabel: number = 0
  view_start_date: Date = new Date()
  view_end_date: Date = new Date()
  intervale_zilnice_ordonate: IntervaleZilniceInterface[] = []
  constructor(public programariService: ProgramariService,public dialog: MatDialog, public datePipe: DatePipe,){}
  ngOnInit():void{
    // console.log("Initi Calendar ==>> Medic ==> ",this.medic)
    // this.events = this.programariService.getProgramaribyUserId(this.pacientid)
    // var intermediar = this.programariService.getProgramari()

    // this.events = this.programariService.getProgramari()
    // for ( let i of this.events ){
    //   if (i.start){
    //     i.start = new Date(i.start)
    //   }
    //   if(i.end){
    //     i.end = new Date(i.end)
    //   }
    // }
    console.log(" viewDate ==>> ", this.view)

    this.programariService.getProgramariFromServerByMedicId(this.medic.id)
    .subscribe( (data) => {
      if(data){
        console.log ( "Programari din service ==>> ", data)
        this.programari.push(data)
      }
    })
    console.log(" This events ==>> ", this.events)

    this.parcurge_program_medic_obtine_start_end_program()
    if(this.medic.intervale_zilnice.length){
      this.ordoneazaIntervaleleZilnice()
    }
    // this.timeHourSegments()

    // this.createTimeSegments()
  }

  checkSegmentDate(){
    // console.log( "Check segment Date == >> ", data_segment)
    // console.log( "Check segment Date == >> ", data_segment)
    // return data_segment.date
    // return "8:00-8:50"
    if(this.trackTimeLabel < this.intervale_zilnice_ordonate.length){
      this.trackTimeLabel = this.trackTimeLabel + 1
      return (this.intervale_zilnice_ordonate[this.trackTimeLabel-1].start+" - "+this.intervale_zilnice_ordonate[this.trackTimeLabel-1].end)
    }
    return " - x - "
  }
  checkTimeLabel(label,testvar){
    // console.log (" Check time label ==>> ", testvar)
    return label
  }

  timeHourSegments(){
    let intermediar_program_start = new Date(new Date().setHours(this.startHourProgram,this.startMinuteProgram))
    let intermediar_program_end = new Date(new Date().setHours(this.endHourProgram,this.endMinuteProgram))
    let sfarsitul_ultimului_interval = intermediar_program_start
    for (let i of this.medic.intervale_zilnice){
      let intermediar_interval_start = new Date(new Date().setHours(this.getHourFromIntervalZilnic(i.start),this.getMinutesFromIntervalZilnic(i.start)))
      let intermediar_interval_end = new Date(new Date().setHours(this.getHourFromIntervalZilnic(i.end),this.getMinutesFromIntervalZilnic(i.end)))
      var interval_zilnic: IntervaleZilniceCreateInterface = {
        start: null,
        end: null
      }
      // daca ultimul element din interval este interval start
      if (sfarsitul_ultimului_interval == intermediar_program_start){
        // verific daca interval start este mai mic sau decat program start
        // console.log (" Sfarsitul ultimului element este egal cu startul programului")
        // console.log (" Sfarsitul ultimului element == >> ",sfarsitul_ultimului_interval," << == este egal cu startul programului == >> ",intermediar_program_start )
        if (intermediar_interval_start <= intermediar_program_start ){
          // console.log (" inceputul intervalului este mai mic sau egal decat startul programului ")
          // console.log (" inceputul intervalului == >> ",intermediar_interval_start," << == este mai mic decat startul programului == >> ",intermediar_program_start )
          // daca startul intervalului este inaintea startului programului
          // verific daca sfarsitul intervalului este dupa startul programului
          if ( intermediar_interval_end > intermediar_program_start){
            // console.log (" Sfarsitul intervalului este mai mare decat inceputul programului ")
            // console.log (" Startul intervalului este inceputul programului ")
            // staca start interval este inaintea startului programului dar starsitul este dupa startul programului
            // startul intervalului este egal cu startul programului
            // iar sfarsitul intervalului este egal cu sfarsitul intervalului zilnic
            interval_zilnic.start = this.datePipe.transform(intermediar_program_start,"HH:mm")
            // verific daca sfarsitul intervalului este inaintea sfarsitului programului
            if (intermediar_interval_end < intermediar_program_end){
              // console.log (" Sfarsitul intervalului este mai mic decat sfarsitul programului ")
              // console.log (" Startul intervalului este sfarsitul intervalului ")
              // daca sfarsitul intervalului este inaintea sfarsitului programului
              //  sfarsitul intervalului zilnic este sfarsitul intervalului intermediar
              interval_zilnic.end = this.datePipe.transform(intermediar_interval_end,"HH:mm")
              sfarsitul_ultimului_interval = new Date(new Date().setHours(this.getHourFromIntervalZilnic(interval_zilnic.end), this.getMinutesFromIntervalZilnic(interval_zilnic.end)))
              // console.log (" ultimul interval este egal cu end-ul intervalului zilnic ==>> ", sfarsitul_ultimului_interval)
              // adaug intervalul zilnic in array-ul global segmente_orare
              this.segmente_orare.push(interval_zilnic)
              // console.log("Adaug intervalul la intervale orare ==>> start =  ", interval_zilnic.start, "  end ==>> ", interval_zilnic.end)
            }
            // daca sfarsitul intervalului este dupa sfarsitul programului
            else{
              // console.log (" Sfarsitul intervalului este mai mare decat sfarsitul programului ")
              // console.log (" Sfarsitul intervalului ==>> ", intermediar_interval_end,"este mai mare decat sfarsitul programului ==>> ", intermediar_program_end)
              // console.log (" Sfarsitul intervalului devine sfarsitul programului ")
              // sfarsitul intervalului va fi egal cu sfarsitul programului
              interval_zilnic.end = this.datePipe.transform(intermediar_program_end,"HH:mm")
              sfarsitul_ultimului_interval = new Date(new Date().setHours(this.getHourFromIntervalZilnic(interval_zilnic.end), this.getMinutesFromIntervalZilnic(interval_zilnic.end)))
              // console.log (" ultimul interval este egal cu end-ul intervalului zilnic ==>> ", sfarsitul_ultimului_interval)
              // adaug intervalul zilnic in array-ul global segmente_orare
              this.segmente_orare.push(interval_zilnic)
              // console.log("Adaug intervalul la intervale orare ==>> start =  ", interval_zilnic.start, "  end ==>> ", interval_zilnic.end)
            }
          }
          else{
            // daca inceputul si sfarsitul intervalului este inainte de inceputul programului
            // ignor intervalul
            // console.log(" sfarsitul intervalului == >> ", intermediar_interval_end , " este mai mic startul programului == >> ", intermediar_program_start )
            // console.log("nu fac nimic aici")

          }
        }
        else{
          // daca startul inervalului este mai mare decat startul programului
          // verific daca startul intervalului este mai mic decat sfarsitul programului
          // console.log(" startul inervalului == >> ", intermediar_interval_start," este mai mare decat startul programului == >> ", intermediar_program_start)
          // console.log(" ultimul interval adaugat == >> ", sfarsitul_ultimului_interval)
          if( intermediar_interval_start < intermediar_program_end ){
              // console.log("  Startul intervalului este mai mic decat sfarsitul programului")
              // console.log(" startul inervalului == >> ", intermediar_interval_start," este mai mare decat sfarsitul programului == >> ", intermediar_program_end)
            // acum am un startul intervalului care este cuprins intre start-ul si end-ul programului
            // stiu ca startul intervalului este mai mare decat startul programului asa ca mai creez un interval
            // care are startul la inceputul programului si end-ul la inceputul intervalului
            interval_zilnic.start = this.datePipe.transform(intermediar_program_start,"HH:mm")
            interval_zilnic.end = this.datePipe.transform(intermediar_interval_start,"HH:mm")
            sfarsitul_ultimului_interval = new Date(new Date().setHours(this.getHourFromIntervalZilnic(interval_zilnic.end), this.getMinutesFromIntervalZilnic(interval_zilnic.end)) )
            this.segmente_orare.push(interval_zilnic)
            // console.log (" ultimul interval este egal cu end-ul intervalului zilnic ==>> ", sfarsitul_ultimului_interval)
            // console.log("Adaug intervalul la intervale orare ==>> start =  ", interval_zilnic.start, "  end ==>> ", interval_zilnic.end)

            //dupa ce am adaugat intervalul dintre inceputul programului si inceputul intervalului
            //verific daca sfarsitul intervalului este mai mic decat sfarsitul programului
            if( intermediar_interval_end < intermediar_program_end){
              // daca sfarsitul intervalului este inaintea sfarsitului programului
              //  interval zilnic de start este startul intervalului si interval zilnic de end este endul intervalului
              interval_zilnic.start = this.datePipe.transform(intermediar_interval_start,"HH:mm")
              interval_zilnic.end = this.datePipe.transform(intermediar_interval_end,"HH:mm")
              // sfarsitul ultimului interval devin sfarsitul acestui interval
              sfarsitul_ultimului_interval = new Date(new Date().setHours(this.getHourFromIntervalZilnic(interval_zilnic.end), this.getMinutesFromIntervalZilnic(interval_zilnic.end)) )
              this.segmente_orare.push(interval_zilnic)
            }
            else{
              // daca sfarsitul intervalului este dupa sfarsitul programului
              // sfarsitul intervalului devine sfarsitul programului
              interval_zilnic.start = this.datePipe.transform(intermediar_interval_start,"HH:mm")
              interval_zilnic.end = this.datePipe.transform(intermediar_program_end,"HH:mm")
              sfarsitul_ultimului_interval = new Date(new Date().setHours(this.getHourFromIntervalZilnic(interval_zilnic.end), this.getMinutesFromIntervalZilnic(interval_zilnic.end)) )
              this.segmente_orare.push(interval_zilnic)
            }
          }
        }
      }
      // daca sfarsitul ultimului interval nu este egal cu inceputul programului
      else{
        // verific daca sfarsitul ultimului interval este dupa startul programului
        if (sfarsitul_ultimului_interval > intermediar_program_start){
          // verific daca sfarsitul ultimului interval este inainte de sfarsitul programului
          if (sfarsitul_ultimului_interval < intermediar_program_end){
            // verific daca startul intervalului este egal cu sfarsitul ultimului interval
            if (intermediar_interval_start == sfarsitul_ultimului_interval){
              // daca este egal, verific daca end-ul intervalului este mai mic decat sfarsitul programului
              if(intermediar_interval_end <= intermediar_program_end){
                // daca sfarsitul intervalului este inaintea sfarsitului programului
                // startul este startul intervalului si end-ul la fel
                interval_zilnic.start = this.datePipe.transform(intermediar_interval_start,"HH:mm")
                interval_zilnic.end = this.datePipe.transform(intermediar_interval_end,"HH:mm")
                sfarsitul_ultimului_interval = new Date(new Date().setHours(this.getHourFromIntervalZilnic(interval_zilnic.end), this.getMinutesFromIntervalZilnic(interval_zilnic.end)) )
                this.segmente_orare.push(interval_zilnic)
              }
              else{
                // daca sfarsitul intervalului este dupa sfarsitul programului
                // sfarsitul intervalului este egal cu sfarsitul programului
                interval_zilnic.start = this.datePipe.transform(intermediar_interval_start,"HH:mm")
                interval_zilnic.end = this.datePipe.transform(intermediar_program_end,"HH:mm")
                sfarsitul_ultimului_interval = new Date(new Date().setHours(this.getHourFromIntervalZilnic(interval_zilnic.end), this.getMinutesFromIntervalZilnic(interval_zilnic.end)) )
                this.segmente_orare.push(interval_zilnic)
              }
            }
            else{
              // verific daca sfarsitul ultimului interval e mai mic decat inceputul noului interval
              if(sfarsitul_ultimului_interval < intermediar_interval_start){
                // daca sfarsitul ultimului interval este mai mic decat startul intervalului
                // adaug un interval intre ele
                interval_zilnic.start = this.datePipe.transform(sfarsitul_ultimului_interval,"HH:mm")
                interval_zilnic.end = this.datePipe.transform(intermediar_interval_start,"HH:mm")
                sfarsitul_ultimului_interval = new Date(new Date().setHours(this.getHourFromIntervalZilnic(interval_zilnic.end), this.getMinutesFromIntervalZilnic(interval_zilnic.end)) )
                this.segmente_orare.push(interval_zilnic)
                let interval_zilnic_two: IntervaleZilniceCreateInterface = {
                  start: null,
                  end: null
                }
                interval_zilnic = interval_zilnic_two
                // Verific daca end-ul intervalului este mai mic decat end-ul programului
                if ( intermediar_interval_end <= intermediar_program_end){
                  //daca sfarsitul intervalului este inaintea sfarsitului programului
                  interval_zilnic.start = this.datePipe.transform(intermediar_interval_start,"HH:mm")
                  interval_zilnic.end = this.datePipe.transform(intermediar_interval_end,"HH:mm")
                  sfarsitul_ultimului_interval = new Date(new Date().setHours(this.getHourFromIntervalZilnic(interval_zilnic.end), this.getMinutesFromIntervalZilnic(interval_zilnic.end)) )
                  this.segmente_orare.push(interval_zilnic)
                }
                else{
                  // daca sfarsitul intervalului este dupa sfarsitul programului
                  // sfarsitul intervalului devine sfarsitul programului
                  interval_zilnic.start = this.datePipe.transform(intermediar_interval_start,"HH:mm")
                  interval_zilnic.end = this.datePipe.transform(intermediar_program_end,"HH:mm")
                  sfarsitul_ultimului_interval = new Date(new Date().setHours(this.getHourFromIntervalZilnic(interval_zilnic.end), this.getMinutesFromIntervalZilnic(interval_zilnic.end)) )
                  this.segmente_orare.push(interval_zilnic)
                }
              }
            }
          }
        }
      }
    }
    console.log(" INTERvale orare la final =======>>> ", this.segmente_orare)
  }

  createTimeSegments(segmente){
    if (this.medic.intervale_zilnice.length > 0){
      // console.log("Intervale_zilnice length == >> ",this.medic.intervale_zilnice.length)
      // console.log("Intervale_zilnice  == >> ",this.medic.intervale_zilnice)
      // console.log("segmente  == >> ",segmente)
    }
    for ( let j of segmente ){
      // console.log( " J of segmente == >> ", j)
      // console.log( " J of segmente . hours == >> ", j.hours)
      for (let z of j.hours){
        // console.log(" z of j.hours === >>> ", z)
        for ( let q of z.segments ){
          let segment = q
          // console.log( " q of z.segments == >> ", q)
          for ( let k of this.medic.intervale_zilnice ){
            let start_interval_date = new Date(new Date(q.date).setHours(this.getHourFromIntervalZilnic(k.start), this.getMinutesFromIntervalZilnic(k.start)))
            // console.log("Start interval Date intermediar == >>> ", start_interval_date)
            if ( q.date > start_interval_date ){
              // console.log( " q date MAI MARE DECAT start interval date ")
            }
            else{
              if ( q.date == start_interval_date ){
                // console.log (" q date EGAL cu interval date ")
              }
              else{
                if (q.date < start_interval_date ){
                  // console.log( " q date mai mic decat start interval date")
                }
              }
            }
          }
        }
      }
    }
    // for ( let i of this.medic.intervale_zilnice ){
    //   // if (  )
    //   // console.log (" I in intervale zilnice == >> ",new Date().setHours() (i.start))
    //   console.log ( " ora inceput program -->> ", this.startHourProgram)
    //   console.log ( " ora inceput program number -->> ", this.getHourFromIntervalZilnic(i.start))
    //   let startHourProgramDate = new Date().setHours(this.startHourProgram, this.startMinuteProgram)
    //   console.log ( " startHourProgramDate -->> ", this.datePipe.transform(startHourProgramDate,"HH:mm"))
    //   let intervalStartDate = new Date().setHours(this.getHourFromIntervalZilnic(i.start), this.getMinutesFromIntervalZilnic(i.start))
    //   console.log ( " intervalStartDate -->> ", this.datePipe.transform(intervalStartDate,"HH:mm"))
    //   if (startHourProgramDate < intervalStartDate ) {
    //     var segment_orar_intermediar = segmente[0].hours[0].segments[0]
    //     segment_orar_intermediar.date = new Date(startHourProgramDate)
    //     segment_orar_intermediar.displayDate = new Date(intervalStartDate)
    //     console.log("segment orar intermediar == >>>>>>> ", segment_orar_intermediar)
    //     console.log("start hour program mai MIC interval start date")
    //   }
    //   else{
    //     if (startHourProgramDate == intervalStartDate ) {
    //       console.log("start hour program EGAL interval start date")
    //     }
    //     else{
    //       if (startHourProgramDate > intervalStartDate ) {
    //         console.log("start hour program mai MARE interval start date")
    //       }
    //     }
    //   }
    // }
  }

  parcurge_program_medic_obtine_start_end_program(){
    // console.log (" Calendar component - program medic == >> ", this.medic.program)
    // console.log (" Calendar component - program medic == >> ", new Date(this.medic.program.luni.OraStart).getHours())
    // luni
    if (new Date(this.medic.program.luni.OraStart).getHours() < this.startHourProgram){
      this.startHourProgram = new Date(this.medic.program.luni.OraStart).getHours()
      this.startMinuteProgram = new Date(this.medic.program.luni.OraStart).getMinutes()
    }
    else{
      if ( new Date(this.medic.program.luni.OraStart).getHours() == this.startHourProgram ){
        if (new Date(this.medic.program.luni.OraStart).getMinutes() < this.startMinuteProgram){
          this.startMinuteProgram = new Date(this.medic.program.luni.OraStart).getMinutes()
        }
      }
    }
    if (new Date(this.medic.program.luni.OraEnd).getHours() > this.endHourProgram){
      this.endHourProgram = new Date(this.medic.program.luni.OraEnd).getHours()
      this.endMinuteProgram = new Date(this.medic.program.luni.OraEnd).getMinutes()
    }
    else{
      if ( new Date(this.medic.program.luni.OraEnd).getHours() == this.endHourProgram ){
        if (new Date(this.medic.program.luni.OraEnd).getMinutes() > this.endMinuteProgram){
          this.endMinuteProgram = new Date(this.medic.program.luni.OraEnd).getMinutes()
        }
      }
    }
    //marti
    if (new Date(this.medic.program.marti.OraStart).getHours() < this.startHourProgram){
      this.startHourProgram = new Date(this.medic.program.marti.OraStart).getHours()
      this.startMinuteProgram = new Date(this.medic.program.marti.OraStart).getMinutes()
    }
    else{
      if ( new Date(this.medic.program.marti.OraStart).getHours() == this.startHourProgram ){
        if (new Date(this.medic.program.marti.OraStart).getMinutes() < this.startMinuteProgram){
          this.startMinuteProgram = new Date(this.medic.program.marti.OraStart).getMinutes()
        }
      }
    }
    if (new Date(this.medic.program.marti.OraEnd).getHours() > this.endHourProgram){
      this.endHourProgram = new Date(this.medic.program.marti.OraEnd).getHours()
      this.endMinuteProgram = new Date(this.medic.program.marti.OraEnd).getMinutes()
    }
    else{
      if ( new Date(this.medic.program.marti.OraEnd).getHours() == this.endHourProgram ){
        if (new Date(this.medic.program.marti.OraEnd).getMinutes() > this.endMinuteProgram){
          this.endMinuteProgram = new Date(this.medic.program.marti.OraEnd).getMinutes()
        }
      }
    }
    //miercuri
    if (new Date(this.medic.program.miercuri.OraStart).getHours() < this.startHourProgram){
      this.startHourProgram = new Date(this.medic.program.miercuri.OraStart).getHours()
      this.startMinuteProgram = new Date(this.medic.program.miercuri.OraStart).getMinutes()
    }
    else{
      if ( new Date(this.medic.program.miercuri.OraStart).getHours() == this.startHourProgram ){
        if (new Date(this.medic.program.miercuri.OraStart).getMinutes() < this.startMinuteProgram){
          this.startMinuteProgram = new Date(this.medic.program.miercuri.OraStart).getMinutes()
        }
      }
    }
    if (new Date(this.medic.program.miercuri.OraEnd).getHours() > this.endHourProgram){
      this.endHourProgram = new Date(this.medic.program.miercuri.OraEnd).getHours()
      this.endMinuteProgram = new Date(this.medic.program.miercuri.OraEnd).getMinutes()
    }
    else{
      if ( new Date(this.medic.program.miercuri.OraEnd).getHours() == this.endHourProgram ){
        if (new Date(this.medic.program.miercuri.OraEnd).getMinutes() > this.endMinuteProgram){
          this.endMinuteProgram = new Date(this.medic.program.miercuri.OraEnd).getMinutes()
        }
      }
    }
        //joi
        if (new Date(this.medic.program.joi.OraStart).getHours() < this.startHourProgram){
          this.startHourProgram = new Date(this.medic.program.joi.OraStart).getHours()
          this.startMinuteProgram = new Date(this.medic.program.joi.OraStart).getMinutes()
        }
        else{
          if ( new Date(this.medic.program.joi.OraStart).getHours() == this.startHourProgram ){
            if (new Date(this.medic.program.joi.OraStart).getMinutes() < this.startMinuteProgram){
              this.startMinuteProgram = new Date(this.medic.program.joi.OraStart).getMinutes()
            }
          }
        }
        if (new Date(this.medic.program.joi.OraEnd).getHours() > this.endHourProgram){
          this.endHourProgram = new Date(this.medic.program.joi.OraEnd).getHours()
          this.endMinuteProgram = new Date(this.medic.program.joi.OraEnd).getMinutes()
        }
        else{
          if ( new Date(this.medic.program.joi.OraEnd).getHours() == this.endHourProgram ){
            if (new Date(this.medic.program.joi.OraEnd).getMinutes() > this.endMinuteProgram){
              this.endMinuteProgram = new Date(this.medic.program.joi.OraEnd).getMinutes()
            }
          }
        }
            //vineri
    if (new Date(this.medic.program.vineri.OraStart).getHours() < this.startHourProgram){
      this.startHourProgram = new Date(this.medic.program.vineri.OraStart).getHours()
      this.startMinuteProgram = new Date(this.medic.program.vineri.OraStart).getMinutes()
    }
    else{
      if ( new Date(this.medic.program.vineri.OraStart).getHours() == this.startHourProgram ){
        if (new Date(this.medic.program.vineri.OraStart).getMinutes() < this.startMinuteProgram){
          this.startMinuteProgram = new Date(this.medic.program.vineri.OraStart).getMinutes()
        }
      }
    }
    if (new Date(this.medic.program.vineri.OraEnd).getHours() > this.endHourProgram){
      this.endHourProgram = new Date(this.medic.program.vineri.OraEnd).getHours()
      this.endMinuteProgram = new Date(this.medic.program.vineri.OraEnd).getMinutes()
    }
    else{
      if ( new Date(this.medic.program.vineri.OraEnd).getHours() == this.endHourProgram ){
        if (new Date(this.medic.program.vineri.OraEnd).getMinutes() > this.endMinuteProgram){
          this.endMinuteProgram = new Date(this.medic.program.vineri.OraEnd).getMinutes()
        }
      }
    }
    console.log( " h start == >> ", this.startHourProgram," m start == >> ", this.startMinuteProgram, " h end == >> ", this.endHourProgram, " m end == >> ", this.endMinuteProgram)
    // this.dayStartHour = (new Date().setHours(this.startHourProgram,this.startMinuteProgram))
    // this.dayEndHour = new Date().setHours(this.endHourProgram, this.endMinuteProgram)
    // console.log (" day start hour dupa adunare ==>> ", this.dayStartHour)
    // console.log (" day end hour dupa adunare ==>> ", this.dayEndHour)
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    // renderEvent.body.forEach((day) => {
    //   const dayOfMonth = day.date.getDate();
    //   if (dayOfMonth > 5 && dayOfMonth < 10 && day.inMonth) {
    //     day.cssClass = 'interval-somn';
    //   }
    // });
  }

  getHourFromIntervalZilnic(ora_de_convertit: string){
    let intermediar = ora_de_convertit.slice(0,ora_de_convertit.indexOf(':'))
    return Number(intermediar)
  }
  getMinutesFromIntervalZilnic(ora_de_convertit: string){
    let intermediar = ora_de_convertit.slice(ora_de_convertit.indexOf(':')+1,ora_de_convertit.length)
    return Number(intermediar)
  }
  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    // console.log("medic in calendar: => ", this.medic)
    // this.createTimeSegments(renderEvent.hourColumns)
    console.log(" inainte de week view render, hour segments ==>> ", renderEvent.hourColumns)
    this.view_start_date = renderEvent.hourColumns[0].date
    this.view_end_date = renderEvent.hourColumns[renderEvent.hourColumns.length-1].date
    // console.log(" prima si ultima data ==>> ", this.view_start_date, " << == >> ", this.view_end_date)
    for (var q in renderEvent.hourColumns){
      console.log(" Q in renderEvend.HourColumn ==>> ", renderEvent.hourColumns[q])
    }
    this.programariService.getProgramariFromServerByMedicIdInIntervalWeek(this.medic.id,this.datePipe.transform(renderEvent.hourColumns[0].date,'dd.MM.YYYY-HH:mm'), this.datePipe.transform(renderEvent.hourColumns[renderEvent.hourColumns.length-1].date,'dd.MM.YYYY-HH:mm'))
    .subscribe( (data) => {
      if (data){
        console.log("Data din programari service by medic id si in interval ==>> ", data)
      }
    })
    if (this.medic.intervale_zilnice.length) {
      // console.log(" Medic cu intervale zilnice ")

    }
    else(
      console.log(" Medic fara intervale zilnice ")
    )

    renderEvent.hourColumns.forEach((hourColumn) => {
      // console.log("hourColumns before weeke view renderer : =>", hourColumn)
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          // console.log("hour segment switch", this.medic)
          // this.medic.forEach((progmedic) => {
            //  console.log("progmedic in calendar: => ", progmedic)
            if(segment.date > new Date()){if(this.medic)
            {
              switch (segment.date.getDay())
            {
              case 1:

                if (
                  ((segment.date.getHours() < new Date(this.medic.program.luni.OraStart).getHours()) ||
                  (segment.date.getHours() >= new Date(this.medic.program.luni.OraEnd).getHours())) &&
                  segment.date.getDay() === 1
                ) {
                  segment.cssClass = 'extra-program-medic';
                };
                break;
              case 2:
                  if (
                    (segment.date.getHours() < new Date(this.medic.program.marti.OraStart).getHours() ||
                    segment.date.getHours() >= new Date(this.medic.program.marti.OraEnd).getHours()) &&
                    segment.date.getDay() === 2
                    ) {
                      segment.cssClass = 'extra-program-medic';
                    }
                    break;
              case 3:
                if (
                (segment.date.getHours() < new Date(this.medic.program.miercuri.OraStart).getHours() ||
                segment.date.getHours() >= new Date(this.medic.program.miercuri.OraEnd).getHours()) &&
                segment.date.getDay() === 3
                ) {
                segment.cssClass = 'extra-program-medic';
              }
              break;
              case 4:
                if (
                  (segment.date.getHours() < new Date(this.medic.program.joi.OraStart).getHours() ||
                  segment.date.getHours() >= new Date(this.medic.program.joi.OraEnd).getHours()) &&
                  segment.date.getDay() === 4
                  ) {
                  segment.cssClass = 'extra-program-medic';
                }
                break;
              case 5:
                if (
                  (segment.date.getHours() < new Date(this.medic.program.vineri.OraStart).getHours() ||
                  segment.date.getHours() >= new Date(this.medic.program.vineri.OraEnd).getHours()) &&
                  segment.date.getDay() === 5
                  ) {
                    segment.cssClass = 'extra-program-medic';
                  }
                  break;
            }
          }
          }
          else{
            // console.log("in trecut == ", segment.date)
            segment.cssClass = "in-trecut"
          }
        }
      )})
      // console.log("hourColumn.hours ==>> ", hourColumn.hours)
      hourColumn.hours.forEach((hour) => {
        // console.log("hour fara segments for each ==>> ", hour)
        hour.segments.forEach((segment) => {
          // console.log("hour segments for each ==>> ", segment)
          // segment.date.setMinutes(segment.date.getMinutes()+15)
            if(this.orar_somn)
          {
            // console.log("orar de somn", this.end_program_somn)
            if
            (
              segment.date.getHours() >= new Date(this.start_program_somn).getHours() &&
              segment.date.getHours() < new Date(this.end_program_somn).getHours()
            )
            {
              segment.cssClass = 'interval-somn';
            }
          }
        });
      });
    });
  }
  ordoneazaIntervaleleZilnice(){
    for( var i of this.medic.intervale_zilnice ){
      if (this.intervale_zilnice_ordonate.length){
        if (this.intervale_zilnice_ordonate.length == 1){
          if ( this.getHourFromIntervalZilnic(this.intervale_zilnice_ordonate[0].start) > this.getHourFromIntervalZilnic(i.start)){
            this.intervale_zilnice_ordonate = [i].concat(this.intervale_zilnice_ordonate)
          }
          else{
            if (this.getHourFromIntervalZilnic(this.intervale_zilnice_ordonate[0].start) < this.getHourFromIntervalZilnic(i.start)){
              this.intervale_zilnice_ordonate.push(i)
            }
            else{
              if ( this.getHourFromIntervalZilnic(this.intervale_zilnice_ordonate[0].start) == this.getHourFromIntervalZilnic(i.start) ){
                if ( this.getMinutesFromIntervalZilnic(this.intervale_zilnice_ordonate[0].start) <  this.getMinutesFromIntervalZilnic(i.start)){
                  this.intervale_zilnice_ordonate = [i].concat(this.intervale_zilnice_ordonate)

                }
                else{
                  if (this.getMinutesFromIntervalZilnic(this.intervale_zilnice_ordonate[0].start) > this.getMinutesFromIntervalZilnic(i.start)){
                    this.intervale_zilnice_ordonate.push(i)
                  }
                }
              }
            }
          }
        }
        else{
          for ( var j = 0; j < this.intervale_zilnice_ordonate.length-1;j++ ){
            if ( (j == 0) && (this.getHourFromIntervalZilnic(i.start) < this.getHourFromIntervalZilnic(this.intervale_zilnice_ordonate[j].start)) ){
              this.intervale_zilnice_ordonate = [i].concat(this.intervale_zilnice_ordonate)
              break
            }
            if ( (j == 0) && (this.getHourFromIntervalZilnic(i.start) == this.getHourFromIntervalZilnic(this.intervale_zilnice_ordonate[j].start)) ){
              if(this.getMinutesFromIntervalZilnic(i.start) < this.getMinutesFromIntervalZilnic(this.intervale_zilnice_ordonate[j].start)){
                this.intervale_zilnice_ordonate = [i].concat(this.intervale_zilnice_ordonate)
                break;
              }
              else{
                if(this.getMinutesFromIntervalZilnic(i.start) > this.getMinutesFromIntervalZilnic(this.intervale_zilnice_ordonate[j].start)){
                  this.intervale_zilnice_ordonate = this.intervale_zilnice_ordonate.slice(0,Number(j)+1).concat(i,this.intervale_zilnice_ordonate.slice(Number(j)+1,))
                  break;
                }
              }
            }
            if ( (this.getHourFromIntervalZilnic(i.start) > this.getHourFromIntervalZilnic(this.intervale_zilnice_ordonate[j].start)) && ( this.getHourFromIntervalZilnic(i.start) < this.getHourFromIntervalZilnic(this.intervale_zilnice_ordonate[j+1].start) ) ){
              this.intervale_zilnice_ordonate = this.intervale_zilnice_ordonate.slice(0,Number(j)+1).concat(i,this.intervale_zilnice_ordonate.slice(Number(j)+1,))
              break;
            }
            if ( this.getHourFromIntervalZilnic(i.start) == this.getHourFromIntervalZilnic(this.intervale_zilnice_ordonate[j].start)  ){
              if(this.getMinutesFromIntervalZilnic(i.start) < this.getMinutesFromIntervalZilnic(this.intervale_zilnice_ordonate[j].start)){
                this.intervale_zilnice_ordonate = [i].concat(this.intervale_zilnice_ordonate)
                break;
              }
              else{
                if(this.getMinutesFromIntervalZilnic(i.start) > this.getMinutesFromIntervalZilnic(this.intervale_zilnice_ordonate[j].start)){
                  this.intervale_zilnice_ordonate = this.intervale_zilnice_ordonate.slice(0,Number(j)+1).concat(i,this.intervale_zilnice_ordonate.slice(Number(j)+1,))
                  break;
                }
              }
            }
            if ( j == this.intervale_zilnice_ordonate.length - 2 ){
              this.intervale_zilnice_ordonate.push(i)
              break;
            }
          }
        }
      }
      else{
        // intervale ordonate length 0
        console.log(" B -- interval ordonat este gol ==>> ", this.intervale_zilnice_ordonate)
        this.intervale_zilnice_ordonate.push(i)
        console.log( " B ==>> ", this.intervale_zilnice_ordonate)
      }
    }
    console.log("Am ordonat intervalele zilnice ==>> ",this.intervale_zilnice_ordonate)
  }



 beforeDayViewRender(renderEvent: CalendarDayViewBeforeRenderEvent) {
    // renderEvent.hourColumns.forEach((hourColumn) => {
    //   hourColumn.hours.forEach((hour) => {
    //     hour.segments.forEach((segment) => {
    //       if (segment.date.getHours() >= 2 && segment.date.getHours() <= 5) {
    //         segment.cssClass = 'interval-somn';
    //       }
    //     });
    //   });
    // });
  }
  refreshView(): void {
    this.refresh.next();
  }
  setView(view: CalendarView) {
    this.view = view;
  }

  clickedtime(ev: Date): void {

    console.log('Event clicked', ev);
    this.dateclicked.emit(ev)
    this.refresh.next()

  }
  eventClicked({ event }: { event: CalendarEvent }): void {
    console.log('Event clicked -- ', event);
    const dialogRef = this.dialog.open(EditprogramareComponent, {
      width: '750px',
      height: '650px',
      data: event,
    });
    dialogRef.afterClosed().subscribe (result =>{
      this.refresh.next()
    })
  }
}
