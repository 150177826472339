import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatTableDataSource } from '@angular/material/table';
import 'rxjs/Rx';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { MatSliderChange } from '@angular/material/slider';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { logo_url_base64 } from 'src/app/models/logo-base64.model';
import { base_img_64 } from 'src/app/models/base-img64.model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { DatePipe } from '@angular/common';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { formatNumber } from '@angular/common';
import { FileService } from 'src/app/services/file.service';
import { EvalserviceService } from 'src/app/services/evalservice.service';
import { EvaluareaFunctieiMotoriiGrosiereDoc } from 'src/app/models/documente.models';
import { PdfpreviewerComponent } from 'src/app/pdfpreviewer/pdfpreviewer.component';
import { PinentryComponent } from 'src/app/pinentry/pinentry.component';
import { FileElement, FileElementCreate } from 'src/app/file-manager/model/element';
import { FileElementCls } from 'src/app/models/fisier.model';
import { ConsultCreateInterface } from 'src/app/interfaces/consult.interface';
import { ConsultCreate } from 'src/app/models/consult.model';
import { ConsultatiiService } from 'src/app/services/consultatii.service';
import { SemnaturiRLService } from 'src/app/services/semnaturirl.service';
import { SemnaturaRLCreateInterface } from 'src/app/interfaces/pacient.interface';
import { SemnaturaRLCreate } from 'src/app/models/pacientdetails.model';
import { SemnaturiTerapeutiFilesService } from 'src/app/services/semnaturiterapeutifiles.service';

@Component({
  selector: 'app-evaluarea-functiei-motorii-grosiere',
  templateUrl: './evaluarea-functiei-motorii-grosiere.component.html',
  styleUrls: ['./evaluarea-functiei-motorii-grosiere.component.css']
})

export class EvaluareaFunctieiMotoriiGrosiereComponent implements OnInit {
  @Output () fileid = new EventEmitter<string>();
  caz : number = 1
  idFisier : any = null
  originalData : any = null
  semnatura_RL : any = null
  semnatura_Terapeut : any = null
  bkdataadaugare : any = null
  nume = 'Nume Pacient'
  pacientid: number
  prenume = 'Prenume Pacient'
  data_nasterii = 'Data Nasterii Pacient'
  varsta_cronologica = 'Varsta Pacient'
  evaluator = 'Nume Penume Evaluator'
  conditii_de_testare:string = ""

  sectiunea_a = []
  sectiunea_b = []
  sectiunea_c = []
  sectiunea_d = []
  dataSourceSectiuneaA = new MatTableDataSource(this.sectiunea_a);
  dataSourceSectiuneaB = new MatTableDataSource(this.sectiunea_b);
  dataSourceSectiuneaC = new MatTableDataSource(this.sectiunea_c);
  dataSourceSectiuneaD = new MatTableDataSource(this.sectiunea_d);

  displayedColumnsSectiuneA: string[] = ['Nr.', 'Miscare', 'Valoare', 'NT'];
  displayedColumnsSectiuneB: string[] = ['Nr.', 'Miscare', 'Valoare', 'NT'];
  displayedColumnsSectiuneC: string[] = ['Nr.', 'Miscare', 'Valoare', 'NT'];
  displayedColumnsSectiuneD: string[] = ['Nr.', 'Miscare', 'Valoare', 'NT'];

  performanta_adevarata_a_copilului:boolean=true;
  comentarii:string
  gnfm_66_scor:string
  gnfm_66_scor_vechi:string
  gnfm_66_schimbari:string
  performanta = "DA"
  rolator:number
  cadru_de_mers:number
  carje_subalixare:number
  carja_cu_4_pic:number
  carja:number
  mijlocnimic:number
  ort_sold:number
  ort_genunchi:number
  ort_glezna_pic:number
  ort_picior:number
  pantofi_ortopedici:number
  ortnimic:number
  mijlocaltele:string
  ortaltele:string
  capturedImage
  ignora_campurile_goale: boolean = true

  docfromcls = new EvaluareaFunctieiMotoriiGrosiereDoc()

  constructor(
    public dialogRef: MatDialogRef<EvaluareaFunctieiMotoriiGrosiereDoc>,
    public evaluarefieldsService: EvalserviceService ,
    public consultatiiService: ConsultatiiService,
    public semnaturirlService: SemnaturiRLService,
    public semnaturiTerapeutFilesService: SemnaturiTerapeutiFilesService,
    @Inject(MAT_DIALOG_DATA) private data:any,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    public fileService: FileService,
    private changeDetector: ChangeDetectorRef
    ) {

    this.getInitialFieldsFromServer()

    if(data){
      console.log("Data from evaluare fct mot ==>> ", data);
      if(data.caz == 1){
        console.log( " EVALUARE FUNCTIE MOTORIE GROSIERA NOUA !!")
        this.caz = 1
        this.initDataCazEvaluareNoua(data.beneficiar)
      }
      else{
        if( data.caz == 2){
          console.log( " EVALUARE FUNCTIE MOTORIE GROSIERA IN EDITARE !! ==>> ", data.fieldsData)
          this.caz = 2
          this.idFisier = data.fieldsData.idFisier
          this.getSavedDataFromServer(this.idFisier)
        }
      }
    }
   }

   @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;

  initDataCazEvaluareEditata(evaluare_salvata){
    // this.docfromcls = evaluare_salvata
    console.log(" DATA FROM INIT CAZ EVALUARE EDITATA ==>> ", evaluare_salvata)
    // this.sectiunea_a = evaluare_salvata.sectiunea_a
    // this.sectiunea_b = evaluare_salvata.sectiunea_b
    // this.sectiunea_c = evaluare_salvata.sectiunea_c
    // this.sectiunea_d = evaluare_salvata.sectiunea_d
    // this.dataSourceSectiuneaA = new MatTableDataSource(this.sectiunea_a);
    // this.dataSourceSectiuneaB = new MatTableDataSource(this.sectiunea_b);
    // this.dataSourceSectiuneaC = new MatTableDataSource(this.sectiunea_c);
    // this.dataSourceSectiuneaD = new MatTableDataSource(this.sectiunea_d);
    this.docfromcls = evaluare_salvata
    this.sectiunea_a = this.docfromcls.sectiunea_a
    this.sectiunea_b = this.docfromcls.sectiunea_b
    this.sectiunea_c = this.docfromcls.sectiunea_c
    this.sectiunea_d = this.docfromcls.sectiunea_d
    this.dataSourceSectiuneaA = new MatTableDataSource(this.docfromcls.sectiunea_a);
    this.dataSourceSectiuneaB = new MatTableDataSource(this.docfromcls.sectiunea_b);
    this.dataSourceSectiuneaC = new MatTableDataSource(this.docfromcls.sectiunea_c);
    this.dataSourceSectiuneaD = new MatTableDataSource(this.docfromcls.sectiunea_d);
  }

  initDataCazEvaluareNoua(beneficiar){
    if (beneficiar.date_personale.nume){
      this.nume = beneficiar.date_personale.nume
    }
    if (beneficiar.date_personale.prenume){
      this.prenume = beneficiar.date_personale.prenume
    }
    if (beneficiar.date_personale.data_nasterii){
      this.data_nasterii = beneficiar.date_personale.data_nasterii
      var intermediar = Math.abs(Date.now() - new Date(beneficiar.date_personale.data_nasterii).getTime());
      var varsta_intermediar = Math.floor(intermediar / (1000 * 3600 * 24) / 365.25)
      this.varsta_cronologica = varsta_intermediar.toString();
    }
    if (beneficiar.id){
      this.pacientid = beneficiar.id
    }
  }
  getInitialFieldsFromServer(){
    this.evaluarefieldsService.getDocFieldsFromServerByID(19)
    .subscribe ( (data) => {
      if (data){
        console.log("DATA FROM EVALUAREA FUNCTIEI MOTORII GROSIERE -->> Initial Props ==>> ", data)
        this.originalData = data
        this.docfromcls = data.props
        // console.log("Data from get DOC FRON server ==>> ", this.originalData)
        // console.log("Data from get DOC FRON server ==>> docfromcls ==>> ", this.docfromcls)
        // console.log("Data from get DOC FRON server ==>> docfromcls ==>> sectiunea_a ==>> ", this.docfromcls.sectiunea_a)
        if (this.docfromcls.nume != this.nume){
          this.docfromcls.nume = this.nume
        }
        if (this.docfromcls.prenume != this.prenume){
          this.docfromcls.prenume = this.prenume
        }
        if (this.docfromcls.data_nasterii != this.data_nasterii){
          this.docfromcls.data_nasterii = this.datePipe.transform(this.data_nasterii,'d.MM.yyyy')
        }
        if (this.docfromcls.varsta_cronologica != this.varsta_cronologica){
          this.docfromcls.varsta_cronologica = this.varsta_cronologica
        }
        if (this.docfromcls.pacientid != this.pacientid){
          this.docfromcls.pacientid = this.pacientid
        }
        this.sectiunea_a = this.docfromcls.sectiunea_a
        this.sectiunea_b = this.docfromcls.sectiunea_b
        this.sectiunea_c = this.docfromcls.sectiunea_c
        this.sectiunea_d = this.docfromcls.sectiunea_d
        this.dataSourceSectiuneaA = new MatTableDataSource(this.docfromcls.sectiunea_a);
        this.dataSourceSectiuneaB = new MatTableDataSource(this.docfromcls.sectiunea_b);
        this.dataSourceSectiuneaC = new MatTableDataSource(this.docfromcls.sectiunea_c);
        this.dataSourceSectiuneaD = new MatTableDataSource(this.docfromcls.sectiunea_d);
        // console.log("Data from get DOC FRON server ==>> sectiunea_a ==>> ", this.sectiunea_a)
        // this.testInitFromServer()
      }
      else{
        console.log("NO !!! Data from get DOC FRON server ==>> ")
      }
    })
  }

  getSavedDataFromServer(idFisier){
    console.log( "GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", idFisier)
    this.consultatiiService.getConsultDataFromServerByPFisierID(idFisier)
    .subscribe( (data) => {
      if (data){
        console.log( "DATA FROM GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", data)
        this.initDataCazEvaluareEditata(data.dataJSON)
      }
    })
  }

  testInitFromServer(){
    this.consultatiiService.getConsultFromServerByID(2)
    .subscribe ( (data) => {
      if (data){
        console.log(" DATA FROM SERVER -->> TEST INIT EVAL ==>> ",data)
        this.docfromcls = data.dataJSON.dataJSON
        console.log(" DATA FROM SERVER -->> TEST INIT EVAL !!!! docfromcls ==>> ",this.docfromcls)
        this.dataSourceSectiuneaA = new MatTableDataSource(this.docfromcls.sectiunea_a);
        this.dataSourceSectiuneaB = new MatTableDataSource(this.docfromcls.sectiunea_b);
        this.dataSourceSectiuneaC = new MatTableDataSource(this.docfromcls.sectiunea_c);
        this.dataSourceSectiuneaD = new MatTableDataSource(this.docfromcls.sectiunea_d);
        this.changeDetector.detectChanges();
      }
    })
  }

  ngOnInit(): void {

  if (this.caz == 2){
    this.semnaturirlService.getSemnaturaRLfromServerByFileID(this.idFisier)
    .subscribe( (data) => {
      if (data){
        console.log("DATA FROM GET SEMNATURA RL FROM SERVER ==>> ", data)
        this.semnatura_RL = data.semnatura
      }
    })
    this.semnaturiTerapeutFilesService.getSemnaturaTerapeutFilesfromServerByFileID(this.idFisier)
    .subscribe( (data) => {
      if (data){
        console.log("DATA FROM GET SEMNATURA TERAPEUT FROM SERVER ==>> ", data)
        this.semnatura_Terapeut = data.semnatura
      }
    })
  }


    // this.docfromcls.nume = "test"
    // this.changeDetector.detectChanges();
    // if (this.docfromcls.data_nasterii != this.data_nasterii){
    //   console.log("Data nasterii from DATA ==>> docfromcls ==>> ", this.docfromcls.data_nasterii)
    //   console.log("Data nasterii from DATA ==>> data_nasterii ==>> ", this.data_nasterii)
    // }

  // this.dataSourceSectiuneaA = new MatTableDataSource(this.sectiunea_a);
  // this.generatePDF()

  // FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI

  // this.docfromcls.sectiunea_a = this.sectiunea_a
  // this.docfromcls.sectiunea_b = this.sectiunea_b
  // this.docfromcls.sectiunea_c = this.sectiunea_c
  // this.docfromcls.sectiunea_d = this.sectiunea_d
  // let testone = {
  //   "denumire" : "Evaluarea functiei motorii grosiere",
  //   "props" : this.docfromcls
  // }

  // this.evaluarefieldsService.addToServer(testone )
  // .subscribe( (data) =>{
  //   if (data){
  //     console.log("Data from add sectiunea A to server ==>> ", data)
  //   }
  //   else{
  //     console.log("NO !!! Data from add sectiunea A to server ==>> ")

  //   }
  // })

  // END FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI

  }
  ngAfterViewInit() {

  }
  schimbaperformanta(){
    // this.performanta_adevarata_a_copilului = !this.performanta_adevarata_a_copilului;
    this.docfromcls.performanta_adevarata_a_copilului = !this.docfromcls.performanta_adevarata_a_copilului;
  }
  changeA($event:MatSliderChange, id: string){
    for(let suport of this.docfromcls.sectiunea_a){
      if (suport.id === id && suport.netestat){
        suport.netestat = !suport.netestat
        this.dataSourceSectiuneaA.data = this.dataSourceSectiuneaA.data
      }
    }
  }
  checkedA($event:MatCheckboxChange, id: string){
    for(let suport of this.docfromcls.sectiunea_a){
      if (suport.id === id && suport.netestat){
        suport.netestat = !suport.netestat
        this.dataSourceSectiuneaA.data = this.dataSourceSectiuneaA.data
      }
    }
  }
  changeB($event:MatSliderChange, id: string){
    for(let suport of this.docfromcls.sectiunea_b){
      if (suport.id === id && suport.netestat){
        suport.netestat = !suport.netestat
        this.dataSourceSectiuneaB.data = this.dataSourceSectiuneaB.data
      }
    }
  }
  checkedB($event:MatCheckboxChange, id: string){
    for(let suport of this.docfromcls.sectiunea_b){
      if (suport.id === id && suport.netestat){
        suport.netestat = !suport.netestat
        this.dataSourceSectiuneaB.data = this.dataSourceSectiuneaB.data
      }
    }
  }
  changeC($event:MatSliderChange, id: string){
    for(let suport of this.docfromcls.sectiunea_c){
      if (suport.id === id && suport.netestat){
        suport.netestat = !suport.netestat
        this.dataSourceSectiuneaC.data = this.dataSourceSectiuneaC.data
      }
    }
  }
  checkedC($event:MatCheckboxChange, id: string){
    for(let suport of this.docfromcls.sectiunea_c){
      if (suport.id === id && suport.netestat){
        suport.netestat = !suport.netestat
        this.dataSourceSectiuneaC.data = this.dataSourceSectiuneaC.data
      }
    }
  }
  changeD($event:MatSliderChange, id: string){
    for(let suport of this.docfromcls.sectiunea_d){
      if (suport.id === id && suport.netestat){
        suport.netestat = !suport.netestat
        this.dataSourceSectiuneaD.data = this.dataSourceSectiuneaD.data
      }
    }
  }
  checkedD($event:MatCheckboxChange, id: string){
    for(let suport of this.docfromcls.sectiunea_d){
      if (suport.id === id && suport.netestat){
        suport.netestat = !suport.netestat
        this.dataSourceSectiuneaD.data = this.dataSourceSectiuneaD.data
      }
    }
  }
  getTotal(){
    let total = 0

    for (let x of this.docfromcls.sectiunea_a){
      // console.log("value from for: => ",x.valoare);
      if (x.valoare >= 0)
      {
        total = total + x.valoare;
      }
    }
    return total
    // return (this.dataSourceSectiuneaA.map(t => t.value).reduce((acc, value) => +acc + +value, 0));
  }

  getTotalB(){
    let total = 0

    for (let x of this.docfromcls.sectiunea_b){
      // console.log("value from for: => ",x.valoare);
      if (x.valoare >= 0)
      {
        total = total + x.valoare;
      }
    }
    return total
    // return (this.dataSourceSectiuneaA.map(t => t.value).reduce((acc, value) => +acc + +value, 0));
  }
  getTotalC(){
    let total = 0

    for (let x of this.docfromcls.sectiunea_c){
      // console.log("value from for: => ",x.valoare);
      if (x.valoare >= 0)
      {
        total = total + x.valoare;
      }
    }
    return total
    // return (this.dataSourceSectiuneaA.map(t => t.value).reduce((acc, value) => +acc + +value, 0));
  }

  getTotalD(){
    let total = 0

    for (let x of this.docfromcls.sectiunea_d){
      // console.log("value from for: => ",x.valoare);
      if (x.valoare >= 0)
      {
        total = total + x.valoare;
      }
    }
    return total
    // return (this.dataSourceSectiuneaA.map(t => t.value).reduce((acc, value) => +acc + +value, 0));
  }

  prepare_sectiunea_a(){
    var x = [ ['Nr.', 'Miscare', 'Scor'] ]
    var y = []
    for ( var i of this.docfromcls.sectiunea_a ){
      if (i.netestat){
        y = [i.id+'.', i.text, "NT"]
      }
      else{
        y = [i.id+'.', i.text, i.valoare]
      }
      x.push(y)
    }
    y = ['',{text: 'Total Sectiune A: ', alignment:'right'},this.getTotal().toString()]
    x.push(y)
    return x
  }
  prepare_sectiunea_b(){
    var x = [ ['Nr.', 'Miscare', 'Scor'] ]
    var y = []
    for ( var i of this.docfromcls.sectiunea_b ){
      if (i.netestat){
        y = [i.id+'.', i.text, "NT"]
      }
      else{
        y = [i.id+'.', i.text, i.valoare]
      }
      x.push(y)
    }
    y = ['',{text: 'Total Sectiune B: ', alignment:'right'},this.getTotalB().toString()]
    x.push(y)
    return x
  }
  prepare_sectiunea_c(){
    var x = [ ['Nr.', 'Miscare', 'Scor'] ]
    var y = []
    for ( var i of this.docfromcls.sectiunea_c ){
      if (i.netestat){
        y = [i.id+'.', i.text, "NT"]
      }
      else{
        y = [i.id+'.', i.text, i.valoare]
      }
      x.push(y)
    }
    y = ['',{text: 'Total Sectiune C: ', alignment:'right'},this.getTotalC().toString()]
    x.push(y)
    return x
  }
  prepare_sectiunea_d(){
    var x = [ ['Nr.', 'Miscare', 'Scor'] ]
    var y = []
    for ( var i of this.docfromcls.sectiunea_d ){
      if (i.netestat){
        y = [i.id+'.', i.text, "NT"]
      }
      else{
        y = [i.id+'.', i.text, i.valoare]
      }
      x.push(y)
    }
    y = ['',{text: 'Total Sectiune D: ', alignment:'right'},this.getTotalD().toString()]
    x.push(y)
    return x
  }

  test(){
    console.log("Sectiunea A == >> ", this.docfromcls)
    const dialogRef = this.dialog.open(PdfpreviewerComponent, {
      width: '80%',
      height: '100%'
    });
  }

  semneazaRL(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_RL = res
        // console.log("Semnatura RL IMAGINE ESTE ==>> ",this.semnatura_RL)
      }
    })
  }
  semneazaTerapeut(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_Terapeut = res
        // console.log("Semnatura Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
      }
    })
  }


  addConsultToServer(idFisier){
    let intermediar_consult : ConsultCreateInterface = new ConsultCreate()
    intermediar_consult.idPacient = this.docfromcls.pacientid
    intermediar_consult.idFisier = idFisier
    intermediar_consult.dataAdaugare = this.bkdataadaugare
    intermediar_consult.idEvaluare = this.originalData.id
    intermediar_consult.denumire = this.originalData.denumire
    intermediar_consult.dataJSON = this.returnObjectForAddConsult()
    console.log(" ADD CONSULT TO SERVER ==>> ", intermediar_consult)
    this.consultatiiService.addConsultToServer(intermediar_consult)
    .subscribe( (data) => {
      if (data){
        console.log("DATA DIN ADD CONSULT TO SERVER ==>> ", data)
        let semnatura_noua_RL : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
        semnatura_noua_RL.idFisier = data.idFisier
        semnatura_noua_RL.semnatura = this.semnatura_RL
        this.semnaturirlService.addSemnaturaRLToServer(semnatura_noua_RL)
        .subscribe ( (data) => {
          if (data){
            console.log ("DATA DIN ADD SEMNATURA RL TO SERVER ==>> ", data)
          }
        })
        let semnatura_noua_TerapeutFiles : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
        semnatura_noua_TerapeutFiles.idFisier = data.idFisier
        semnatura_noua_TerapeutFiles.semnatura = this.semnatura_Terapeut
        this.semnaturiTerapeutFilesService.addSemnaturaTerapeutFilesToServer(semnatura_noua_TerapeutFiles)
        .subscribe ( (data) => {
          if (data){
            console.log ("DATA DIN ADD SEMNATURA TERAPEUT FILES TO SERVER ==>> ", data)
          }
        })
      }
    })
  }

  returnObjectForAddConsult(){
    let intermediar = {
      "dataJSON" : this.docfromcls
    }
    return intermediar
  }
  export(){
    // console.log(this.sectiunea_a)
    html2canvas(document.querySelector("#someid")).then(canvas => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
       pdf.save("HTML-Document.pdf");

     });
  }

  saveConsultModificatToServer(){

  }
  openPDFPreviewSsaveModificari(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      // console.log("data din pdfgenerator base 64 ==>> ",data);
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
      dialogRef.afterClosed().subscribe( async res => {
        if (res){
          // this.semnatura_Terapeut = res
          // console.log("PDF PREVIEW FROM SAVE MODIFICARI RESULT AFTER CLOSE ==>> ",res)
          let update_file  = {
            'id' : this.idFisier,
            'dateadden' : this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm'),
            'path' : "data:application/pdf;base64,"+data,
            'name' : "Evaluarea Functiei motorii grosiere - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
          }
          this.fileService.updateFileToServer(update_file)
          .subscribe ( (data) => {
            // console.log ( "DATA FROM UPDATE FILE FROM SERVER ==> ", data)
          })
          this.consultatiiService.updateConsultToServerByFisierID(this.idFisier,{'dataJSON' : this.returnObjectForAddConsult()})
          .subscribe( (data) => {
            // console.log ("DATA FROM ADD CONSULT TO SERVICE ==>> ", data)
          })
          this.dialogRef.close(1)
        }
      })
    });
  }

  openPDFPreviewComponent(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      // console.log("data din pdfgenerator base 64 ==>> ",data);
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
      dialogRef.afterClosed().subscribe( async res => {
        if (res){
          // this.semnatura_Terapeut = res
          console.log("PDF PREVIEW RESULT AFTER CLOSE ==>> ",res)
          let fisier_to_upload : FileElementCreate = new FileElementCls()
          fisier_to_upload.id = this.fileService.generateFileID();
          fisier_to_upload.idPacient = this.docfromcls.pacientid
          fisier_to_upload.isFolder = false
          fisier_to_upload.parent = 'root'
          fisier_to_upload.dateadden = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
          this.bkdataadaugare = fisier_to_upload.dateadden
          fisier_to_upload.type = "application/pdf"
          fisier_to_upload.extension = "pdf"
          fisier_to_upload.path = "data:application/pdf;base64,"+data
          fisier_to_upload.status = 'neverificat';
          fisier_to_upload.name = "Evaluarea Functiei motorii grosiere - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')

          // console.log("fisier_to_upload ==>> ",fisier_to_upload)
          let intermediar_from_server = await this.fileService.addFileToServerReturnFileID(fisier_to_upload).toPromise()
          // console.log("RASPUNS SERVER ADD FISIER ==>> ", intermediar_from_server)
          this.addConsultToServer(intermediar_from_server)
          this.dialogRef.close(0)
        }
      })

    });
  }

  generatePDF() {
    var sectiunea_a_prepared = this.prepare_sectiunea_a();
    var sectiunea_b_prepared = this.prepare_sectiunea_b();
    var sectiunea_c_prepared = this.prepare_sectiunea_c();
    var sectiunea_d_prepared = this.prepare_sectiunea_d();
    // console.log("Sectiunea a prepared: => ", sectiunea_a_prepared)
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
        height: 60,
        columns:
          [
            // {
            //   image: logo_url_base64,
            //   width: 50,
            //   alignment: 'center',
            // },
            {
              stack: [
                "Fundația Mihai Neșu Foundation",
                "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
              ],
              margin: 10,
              style: 'textheader'
            }
          ],
        margin: [0, 0, 0, 90]
      },
      content: [
        {
          canvas: [
            {
              type: 'line',
              x1: -100, y1: 0,
              x2: 600, y2: 0,
              lineWidth: 1,
              lineCap: 'square'
            },
            {
              type: 'line',
              x1: -100, y1: 4,
              x2: 600, y2: 4,
              lineWidth: 1,
              lineCap: 'square'
            }
          ]
        },
        {
          image: logo_url_base64,
          width: 200,
          alignment: 'center',
        },
        {
          stack: [
            "Evaluarea functiei motorii grosiere",
            " (GMFM) "
          ],
          bold: true,
          alignment: 'center',
          fontSize: 16,
          margin: [0, 50, 0, 80]
        },
        {
          text: "Nume: " + this.docfromcls.nume,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Prenume: " + this.docfromcls.prenume,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Data Nasterii: " + this.docfromcls.data_nasterii,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Varsta: " + this.docfromcls.varsta_cronologica + " ani",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },

        {
          text: "GMFM este un instrument observațional, standardizat, descoperit și validat pentru a măsura schimbări în funcția motorie grosieră a copiilor cu paralizie cerebrală. Sistemul de scorare își propune să fie o linie de ghidare generală. Multe dintre puncte au descrieri specifice pentru fiecare scor.  ",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          stack: [
            "Scor: 0 = nu inițiază",
            "         1 = inițiază ",
            "         2 = termină complet mișcarea ",
            "         3 = termină mișcarea ",
            "         9 ( sau liber) = netestat ",
          ],
          bold: true,
          alignment: 'center',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Este importantă diferențierea unui scor 0, unde subiectul nu inițiază mișcarea și punctul ramas liber netestat!!! ",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Completați scorul apropiat: dacă un punct este netestat, încercuiți numărul sarcinii din coloana dreaptă.",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "A: Decubit si rostogolire",
          bold: true,
          alignment: 'left',
          decoration: 'underline',
          fontSize: 18,
          margin: [0, 10, 0, 20]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [ 20, '*', 40 ],
            body: sectiunea_a_prepared
          }
        },
        {
          text: "B: ASEZAT",
          bold: true,
          alignment: 'left',
          decoration: 'underline',
          fontSize: 18,
          margin: [0, 10, 0, 20]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [ 20, '*', 40 ],
            body: sectiunea_b_prepared
          }
        },
        {
          text: "C: TARARE & STAND PE GENUNCHI",
          bold: true,
          alignment: 'left',
          decoration: 'underline',
          fontSize: 18,
          margin: [0, 10, 0, 20]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [ 20, '*', 40 ],
            body: sectiunea_c_prepared
          }
        },
        {
          text: "D: MERS, ALERGAT & SĂRITURĂ",
          bold: true,
          alignment: 'left',
          decoration: 'underline',
          fontSize: 18,
          margin: [0, 10, 0, 20]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [ 20, '*', 40 ],
            body: sectiunea_d_prepared
          }
        },
        {
          text: 'A prezentat aceasta evaluare performanța adevărată a copilului?' + ((this.docfromcls.performanta_adevarata_a_copilului)? ' DA' : ' NU' ),
          bold: true,
          alignment: 'left',
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Comentarii:" + ((this.docfromcls.comentarii)? ' this.comentarii' : '' ),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "GMFM-88 Scor sumar",
          bold: true,
          alignment: 'left',
          decoration: 'underline',
          fontSize: 18,
          margin: [0, 10, 0, 20]
        },
        {
          text: "A. Decubit si rostogolire => (Total Dim. A) ",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          columns: [
            {
                stack: [
                  "Secțiune",
                  "",
                  "A.	Decubit și rostogolire	",
                  "B.	Așezat",
                  "C.	Târâre & pe genunchi",
                  "D.	Stând	",
                  "E.	Mers, alergare, săritură (??) ",
                ],
                bold: true,
                alignment: 'left',
                fontSize: 12,
                margin: [0, 0, 0, 20]
            },
            {
              stack: [
                "Procentajul de calcul a dimensiunii",
                "",
                "Total Dim.A - " + this.getTotal().toString() + " /51 * 100 = " + (formatNumber((this.getTotal()/51*100),"en-US","1.0-2")).toString() + " %" ,
                "Total Dim.B - " + this.getTotalB().toString() + " /60 * 100 = " + (formatNumber((this.getTotalB()/60*100),"en-US","1.0-2")).toString() + " %",
                "Total Dim.C - " + this.getTotalC().toString() + " /42 * 100 = " + (formatNumber((this.getTotalC()/42*100),"en-US","1.0-2")).toString() + " %",
                "Total Dim.D - " + this.getTotalD().toString() + " /39 * 100 = " + (formatNumber((this.getTotalD()/39*100),"en-US","1.0-2")).toString() + " %",
                "E.	 (??) "
              ],
              bold: true,
              alignment: 'left',
              fontSize: 12,
              margin: [0, 0, 0, 20]
            }
          ]
        },
        {
          text: "Scor total = %A + %B + %C + %D + %E / totalul dimensiunilor = " + (formatNumber((this.getTotal()/51*100+this.getTotalB()/60*100+this.getTotalC()/42*100+this.getTotalD()/39*100),"en-US","1.0-2")).toString() + " / 5 => " + (formatNumber(((this.getTotal()/51+this.getTotalB()/60+this.getTotalC()/42+this.getTotalD()/39)/5*100),"en-US","1.0-2")).toString(),
          bold: true,
          alignment: 'center',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "GMFM 66 Scor etimativ a abilității motorii grosiere " ,
          bold: true,
          alignment: 'center',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "GMFM- 66 Scor =  " + this.docfromcls.gnfm_66_scor,
          bold: true,
          alignment: 'center',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "GMFM- 66 Scor vechi =  " + this.docfromcls.gnfm_66_scor_vechi,
          bold: true,
          alignment: 'center',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "GMFM- 66 Scor schimbari  =  " + this.docfromcls.gnfm_66_schimbari,
          bold: true,
          alignment: 'center',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Testare cu mijloace ajutătoare/orteze GMFM- 88 " ,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          columns: [
            {
                stack: [
                {text:"MIJLOC", fontSize:14, margin: [0, 0, 0, 20],decoration: 'underline' },
                  "",
                  "Rolator	",
                  "Cadru de mers",
                  "Cârje subaxilare",
                  "Cârja cu 4 pic.",
                  "Cârja",
                  "Nimic",
                  "Altele",
                ],
                bold: true,
                alignment: 'left',
                fontSize: 12,
                margin: [0, 0, 0, 20]
            },
            {
              stack: [
                {text:"Dimensiune", fontSize:14, margin: [0, 0, 0, 20],decoration: 'underline' },
                "",
                (this.docfromcls.rolator)?this.docfromcls.rolator.toString():"-",
                (this.docfromcls.cadru_de_mers)?this.docfromcls.cadru_de_mers.toString():"-",
                (this.docfromcls.carje_subalixare)?this.docfromcls.carje_subalixare.toString():"-",
                (this.docfromcls.carja_cu_4_pic)?this.docfromcls.carja_cu_4_pic.toString():"-",
                (this.docfromcls.carja)?this.docfromcls.carja.toString():"-",
                (this.docfromcls.mijlocnimic)?this.docfromcls.mijlocnimic.toString():"-",
                (this.docfromcls.mijlocaltele)?this.docfromcls.mijlocaltele.toString():"-",
              ],
              bold: true,
              alignment: 'left',
              fontSize: 12,
              margin: [0, 0, 0, 20]
            },
            {
              stack: [
                {text:"ORTEZA", fontSize:14, margin: [0, 0, 0, 20],decoration: 'underline' },
                "",
                "Ort. Șold",
                "Ort. Genunchi",
                "Ort. Glezna-pic",
                "Ort. Picior",
                "Pantofi ortopedici",
                "Nimic",
                "Altele",
              ],
              bold: true,
              alignment: 'left',
              fontSize: 12,
              margin: [0, 0, 0, 20]
            },
            {
              stack: [
                {text:"Dimensiune", fontSize:14, margin: [0, 0, 0, 20],decoration: 'underline' },
                "",
                (this.docfromcls.ort_sold)?this.docfromcls.ort_sold.toString():"-",
                (this.docfromcls.ort_genunchi)?this.docfromcls.ort_genunchi.toString():"-",
                (this.docfromcls.ort_glezna_pic)?this.docfromcls.ort_glezna_pic.toString():"-",
                (this.docfromcls.ort_picior)?this.docfromcls.ort_picior.toString():"-",
                (this.docfromcls.pantofi_ortopedici)?this.docfromcls.pantofi_ortopedici.toString():"-",
                (this.docfromcls.ortnimic)?this.docfromcls.ortnimic.toString():"-",
                (this.docfromcls.ortaltele)?this.docfromcls.ortaltele.toString():"-",
              ],
              bold: true,
              alignment: 'left',
              fontSize: 12,
              margin: [0, 0, 0, 20]
            },
          ]
        },
        {
          columns:[
            {
              stack: [
                {
                  text: "Semnatura terapeut, ",
                  bold: true,
                  alignment: 'left',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_Terapeut ? this.semnatura_Terapeut : base_img_64,
                  width: 140,
                  height: 40,
                  margin: [0, 0, 0, 20]
                }
              ]

            },
            {
              stack: [
                {
                  text: "Luat la cunoștință de părinte, ",
                  bold: true,
                  alignment: 'right',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_RL ? this.semnatura_RL : base_img_64,
                  alignment: 'right',
                  width: 140,
                  height: 40,
                  margin: [0, 0, 0, 20]
                }
              ]
            }
          ]
        }
      ],
      styles: {
        textheader: {
          alignment: 'center',
          bold: true,
          fontSize: 14,
          // lineHeight: 2
        }
      }
    };
    return docDefinition
  }

}
