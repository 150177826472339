import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError} from 'rxjs/operators/catchError';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ValabilitateInterface,  TipDiagnosticInterface, DiagnosticInterface, DiagnosticCreateInterface, DiagnosticCreateFaraValabilitateInterface, DiagnosticCreateFaraTipDiagnosticInterface } from '../interfaces/nomenclatoare.interface';
import { serverurl } from './server.service';
import { MatDialog } from '@angular/material/dialog';
import { PinentryComponent } from '../pinentry/pinentry.component';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};




@Injectable({
  providedIn: 'root'
})

export class ContracteService {
  // public consultatiilist: Consultatie [] = []
  public localstorageflag = 0;
  private server_url=serverurl+"/api/v1/contracte/";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService
    ) {
     }
     private handleError(error: Response){
      console.log("Error from Pacient Service: => " + error)
      return Observable.throw("500 internal server error" )
    }


  addConstractToServer(contract){
    return (this.http.post<any>(this.server_url, contract, this.httpOptions))
  }

  getContractFromServerByID(idContract){
    return (this.http.get<any>(this.server_url+idContract))
  }
  getContractFromServerByPacientID(idBeneficiar){
    return (this.http.get<any>(this.server_url+"beneficiar/"+idBeneficiar))
  }


  getContractDataFromServerByPFisierID(idFisier){
    return (this.http.get<any>(this.server_url+"savedData/"+idFisier))
  }

  updateContractToServerByFisierID(idFisier, data_in){
    console.log("UPDATE CONSULT TO SERVER BY FISIER ID -->> DATA IN ==>> ", data_in)
    return (this.http.put<any>(this.server_url+"fileid/"+idFisier,data_in))
  }

  stergeContractFromServerByFisierID(idFisier){
    return (this.http.delete<any>(this.server_url+"fileid/"+idFisier))
  }

  semneaza(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        return (res)
        // console.log("Semnaturas Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
      }
    })
  }

}
