import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AddpacientComponent } from '../addpacient/addpacient.component';
import { PacientdetailsComponent } from '../pacientdetails/pacientdetails.component';

export interface Pacient{
  id?:number;
  nume: string;
  prenume: string;
  CNP:number;
  email:string;
  telefon:string;
}

const ELEMENT_DATA: Pacient[] = [
  {id: 1, nume: 'Ion', prenume:"Popescu", CNP: 1900506335412, email:"dhwon@gmail.com", telefon:"+40 712 018 528" },
  {id: 2, nume: 'Maria', prenume:"Ionescu", CNP: 2880814442365, email:"rhialto@msn.com", telefon:"+40 702 054 317" },
  {id: 3, nume: 'Alex', prenume:"Popa", CNP: 1861014075720, email:"leakin@msn.com", telefon:"+40 712 000 535" },
  {id: 4, nume: 'Alin', prenume:"Pop", CNP: 6000925370687, email:"warrior@outlook.com", telefon:"+40 702 074 478" },
  {id: 5, nume: 'Gabi', prenume:"Constantinescu", CNP: 2901017018201, email:"hahiss@verizon.net", telefon:"+40 702 098 498" },
  {id: 6, nume: 'Adrian', prenume:"Stan", CNP: 2990828185228, email:"whimsy@att.net", telefon:"+40 712 021 276" },
  {id: 7, nume: 'Raluca', prenume:"Stanciu", CNP: 6020824347303, email:"bruck@live.com", telefon:"+40 705 731 811" },
  {id: 8, nume: 'Ioana', prenume:"Dumitrescu", CNP: 2930419322467, email:"martyloo@outlook.com", telefon:"+40 702 056 018" },
  {id: 9, nume: 'Bianca', prenume:"Dima", CNP: 2940513142249, email:"afifi@icloud.com", telefon:"+40 704 102 486" },
  {id: 10, nume: 'Marin', prenume:"Gheorghiu", CNP: 2990914224347, email:"mdielmann@msn.com", telefon:"+40 708 162 286" },
];

@Component({
  selector: 'app-inscrieri',
  templateUrl: './inscrieri.component.html',
  styleUrls: ['./inscrieri.component.css']
})
export class InscrieriComponent {

  constructor() { }

  displayedColumns: string[] = ['id', 'nume', 'prenume', 'CNP',"email", "telefon"];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
