import { Component, OnInit, ViewChild } from '@angular/core';
import {CatserviciiService} from '../../services/categoriedeservicii.service';
import {MatTableDataSource} from '@angular/material/table';
import {ServiciiService} from '../../services/servicii.service';
import { Servicii, TipuriDeServicii, specialitateMedici } from 'src/app/models/nomenclatoare.model';
import { SpecialitateMedicService } from 'src/app/services/servmedic.service';
import { IntervalService } from '../../services/interval.service'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { DatePipe } from '@angular/common'
import { MatInput } from '@angular/material/input';
import { FormControl } from '@angular/forms';
import { ServiciiInterface, specialitateMediciInterface, TipuriDeServiciiInterface } from 'src/app/interfaces/nomenclatoare.interface';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import { ServiciieditComponent } from '../serviciiedit/serviciiedit.component';

@Component({
  selector: 'app-servicii',
  templateUrl: './servicii.component.html',
  styleUrls: ['./servicii.component.css']
})
export class ServiciiComponent implements OnInit {
  tipuriDeServicii: any[] = [];
  servicii: ServiciiInterface[];
  specialitatiMedici: any[];
  displayedColumns: string[] = ['nume', 'categorie','specialitate', 'cuanta','edit','deleteselect'];
  dataSource: any;
  newservice = new Servicii();
  todelete: any[] = []

  // public cod ?: string,
  // public denumire ?: string,
  // public tipServiciu ?: TipuriDeServicii,
  // public timp?: number
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    public tipuriDeServiciiService: CatserviciiService,
    public specialitateMedicService: SpecialitateMedicService,
    public serviciiService: ServiciiService
    ) { }

  ngOnInit(): void {
    // this.tipuriDeServicii = this.tipuriDeServiciiService.getcategoriiservicii();
    this.tipuriDeServiciiService.getfromserver()
    .subscribe((data:TipuriDeServiciiInterface[]) => {
      if (data){
        console.log("data din cat service subscribe ==>> ", data)
        this.tipuriDeServicii = data
        // this.dataSource = new MatTableDataSource(this.tipuriDeServicii);
      }
      console.log("this.tipuriDeServicii.length =>> ", this.tipuriDeServicii.length);
      console.log("this.tipuriDeServicii full object onInit =>> ", this.tipuriDeServicii);
    });

    this.specialitateMedicService.getfromserver()
    .subscribe( (data:specialitateMediciInterface[]) => {
      if (data){
        this.specialitatiMedici = data
        // this.dataSource  =  new MatTableDataSource(this.specialitatiMedici);
        console.log("specialitatiMedici Medici ==>> ",this.specialitatiMedici);
      }
    })
    // console.log(this.categorii)
    this.servicii= this.serviciiService.getServicii();
    this.serviciiService.getServiciiFromServer()
    .subscribe( (data) =>{
      if (data){
        this.servicii = data
        this.dataSource = new MatTableDataSource(this.servicii.sort( (a,b) => {
          if (b.id < a.id) return 1
          if (b.id > a.id) return -1
          return 0
        }));
        this.dataSource.paginator = this.paginator;
      }
    })
    // console.log(this.servicii)
    // this.specialitatiMedici = this.specialitateMedicService.getSpecialitatiMedic();
  }
  openServiciiEdit(serviciu):void{
    let serviciubk = JSON.parse(JSON.stringify(serviciu))
    // console.log("RESULT FROM SERVICII EDIT CLOSE ==>> ORIGINAL 1 ==>> ", serviciubk)

    const dialogRef = this.dialog.open(ServiciieditComponent, {
      width: '850px',
      height: '850px',
      data: {
        serviciu: serviciu,
        specialitatiMedici: this.specialitatiMedici,
        tipuriDeServicii: this.tipuriDeServicii
      }
    });
    dialogRef.afterClosed().subscribe( result =>{
      if (result){
        // console.log("RESULT FROM SERVICII EDIT CLOSE ==>> ", result)
        // console.log("RESULT FROM SERVICII EDIT CLOSE ==>> ORIGINAL 3 ==>> ", serviciubk)
        // console.log("RESULT FROM SERVICII EDIT CLOSE ==>> ORIGINAL 2 ==>> ", this.servicii.find( x=> x.id == result.id))
        let intermediar = this.checkServiciuModificat(serviciubk,result)
        if ( intermediar != null){
          this.serviciiService.updateServiciuToServer(intermediar)
          .subscribe ( (data) => {
            console.log("De facut modificarea")
            if (data){
              console.log("New  Data from update SERVICIU == >> ", data)
              this.updatedata()
            }
          })
        }
      }
    })
  }


  checkServiciuModificat(originalValue, newValue){
    let modificat = 0
    console.log("ORIGINAL VALUE LA INCEPUTUL VERIFICAFII ==>> ", originalValue)
    if(originalValue.denumire != newValue.denumire){
      // console.log("Denumirea SERVICIULUI S-A SCHIMBAT ==>> ",originalValue.denumire," ==>> ", newValue.denumire)
      originalValue.denumire = newValue.denumire
      modificat = 1
    }
    if(originalValue.timp != newValue.timp){
      // console.log("timp SERVICIULUI S-A SCHIMBAT ==>> ",originalValue.timp," ==>> ", newValue.timp)
      originalValue.timp = newValue.timp
      modificat = 1
    }
    if(originalValue.pret != newValue.pret){
      // console.log("pret SERVICIULUI S-A SCHIMBAT ==>> ",originalValue.pret," ==>> ", newValue.pret)
      originalValue.pret = newValue.pret
      modificat = 1
    }
    if(originalValue.tva != newValue.tva){
      // console.log("tva SERVICIULUI S-A SCHIMBAT ==>> ",originalValue.tva," ==>> ", newValue.tva)
      if (originalValue.tva && !newValue.tva){
        originalValue.valoare_tva = 0
      }
      else{
        originalValue.valoare_tva = newValue.valoare_tva
      }
      originalValue.tva = newValue.tva
      modificat = 1
    }
    else{
      if(originalValue.valoare_tva != newValue.valoare_tva){
        // console.log("valoare_tva SERVICIULUI S-A SCHIMBAT ==>> ",originalValue.valoare_tva," ==>> ", newValue.valoare_tva)
        originalValue.valoare_tva = newValue.valoare_tva
        modificat = 1
      }
    }
    if(originalValue.specialitateid != newValue.specialitateid){
      // console.log("specialitateid SERVICIULUI S-A SCHIMBAT ==>> ",originalValue.specialitateid," ==>> ", newValue.specialitateid)
      originalValue.specialitateid = newValue.specialitateid
      modificat = 1
    }
    if(originalValue.tipserviciuid != newValue.tipserviciuid){
      // console.log("tipserviciuid SERVICIULUI S-A SCHIMBAT ==>> ",originalValue.tipserviciuid," ==>> ", newValue.tipserviciuid)
      originalValue.tipserviciuid = newValue.tipserviciuid
      modificat = 1
    }
    if(modificat){
      console.log("SERVICIUL A FOST MODIFICAT ")
      console.log("ORIGINAL VALUE LA SFARSITUL VERIFICAFII ==>> ", originalValue)
      return originalValue
    }
    else{
      console.log("SERVICIUL NU A FOST MODIFICAT")
      return null
    }
  }

  findDenumireSpecialitateMedic(id){
    // console.log("Find DENUMIRE SPECIALITATE MEDIC ==>> ")
    // console.log("Find DENUMIRE SPECIALITATE MEDIC ==>> ID ==>> ", id)
    // console.log("Find DENUMIRE SPECIALITATE MEDIC ==>> Specialitati Medici ==>> ",this.specialitatiMedici)
    if (this.specialitatiMedici != undefined){
      return this.specialitatiMedici.find( x=> x.id == id)
    }
    else{
      console.log("Specialitate Medici UNDEFINED ==>> ", id)
      return "N/A"
    }
  }
  findDenumireSpecialitateMedicTwo(elementTwo){
    // console.log("FIND DENUMIRE SPECIALITATE ==>> ELEMENT ==>> ",elementTwo)
    // console.log("FIND DENUMIRE SPECIALITATE ==>> SPECIALITATI MEDICI ==>> ", this.specialitatiMedici)
    if (elementTwo.specialitateid != null){
      // console.log("FIND DENUMIRE SPECIALITATE ==>> SPECIALITATE ID NOT NULL ==>> ", elementTwo.specialitateid)
      if (this.specialitatiMedici != undefined){
        let x = this.specialitatiMedici.find(x=> x.id == elementTwo.specialitateid)
        return x.denumire
      }
    }
    return "-"
  }
  findCategorieServiciuTwo(elementTwo){
    // if (elementTwo.tip)
    // console.log("ELEMENT TWO ==>> ", elementTwo)
    if (elementTwo.tipserviciuid != null){
      if (this.tipuriDeServicii != undefined){
        let x = this.tipuriDeServicii.find(x=> x.id == elementTwo.tipserviciuid)
        return x.denumire
      }
    }
    return "-"
  }

  findCategorieServiciu(id){
    return this.tipuriDeServicii.find( x=> x.id == id)
  }
  updatedata(){
    this.serviciiService.getServiciiFromServer()
    .subscribe((data:ServiciiInterface[]) => {
      if (data){
        console.log("data din cat service subscribe ==>> ", data)
        this.servicii = data
        this.dataSource = new MatTableDataSource(this.servicii.sort( (a,b) => {
          if (b.id < a.id) return 1
          if (b.id > a.id) return -1
          return 0
        }));
        this.dataSource.paginator = this.paginator;
      }
    });
    // this.specialitateMedicService.getfromserver()
    // .subscribe( (data:specialitateMediciInterface[]) => {
    //   if (data){
    //     this.specialitatiMedici = data
    //     // this.dataSource  =  new MatTableDataSource(this.specialitatiMedici);
    //     console.log("specialitatiMedici Medici ==>> ",this.specialitatiMedici);
    //   }
    // })

  }
  marktodelete(deleteid){
    if (this.todelete.includes(deleteid)){
      console.log("Id-ul deja selectat !! ")
      this.todelete.forEach( (value,index) =>{
        if (value == deleteid) this.todelete.splice(index,1)
      })
    }
    else{
      this.todelete.push(deleteid)
    }
    console.log("Id-uri de sers ==>> ",this.todelete)
  }

  resetDeleteItems(){
    this.todelete = []
    this.updatedata()
  }

  deleteitems(){
    let maxvalue = 0
    // console.log("this.todelete.length from delete categorie de servicii ==>> ", this.todelete.length)
    this.todelete.forEach( value =>{
      this.serviciiService.deletefromserver(value).subscribe( (data) =>{
        if (data){
          // console.log("Data from delete categorie de servicii ==>> ", data)
          // console.log("maxvalue from delete categorie de servicii ==>> ", maxvalue)
          maxvalue = maxvalue + 1
          if ( maxvalue >= this.todelete.length ){
            // console.log("maxvalue increment ==>> ", maxvalue)
            this.resetDeleteItems()
          }
        }
      })
    })
  }
  AddServ(){
    this.serviciiService.addServicii(this.newservice);
    this.servicii= this.serviciiService.getServicii();
    this.newservice = new Servicii();
    this.dataSource = new MatTableDataSource(this.servicii);
    // console.log("newserv name ", this.newservicename, " new serv cat ", this.newservicecat)
  }

  checkIfNewServiceHas(variabila){
    if ( variabila != undefined ){
      if ( variabila.id ){
        return variabila.id
      }
    }
    return null
  }

  AddServiciuToServer(){
    console.log("Serviciu de adaugat ==>> this.newservice ==>> ", this.newservice)
    let serviciu_nou = {
      cod:this.newservice.cod,
      denumire: this.newservice.denumire,
      pret: this.newservice.pret,
      timp: this.newservice.timp,
      tva: this.newservice.tva,
      valoare_tva: this.newservice.valoare_tva,
      specialitateid: this.checkIfNewServiceHas(this.newservice.specialitate),
      tipserviciuid: this.checkIfNewServiceHas(this.newservice.tipServiciu)
    }
    console.log("Serviciu de adaugat ==>> ", serviciu_nou)

    this.serviciiService.addServiciiToServer(serviciu_nou)
    .subscribe( (data) => {
      if (data){
        console.log("Data from Add Serviciu To Server ==>> ", data)
        this.newservice = new Servicii();
        this.serviciiService.getServiciiFromServer()
        .subscribe( (data) => {
          this.servicii = data
          this.dataSource = new MatTableDataSource(this.servicii);
          this.dataSource.paginator = this.paginator;
        })
      }
    })
  }

}
