import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CalendarDateFormatter,
  CalendarEvent,
  CalendarView,
  DAYS_OF_WEEK } from 'angular-calendar';
import { DatePipe } from '@angular/common';

import {MatPaginator} from '@angular/material/paginator';
import { isThisSecond } from 'date-fns';
import { PacientiService } from '../services/pacienti.service';
import { Pacient } from '../models/pacientdetails.model';
import { ServiciiService } from '../services/servicii.service';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-programaredialog',
  templateUrl: './programaredialog.component.html',
  styleUrls: ['./programaredialog.component.css']
})
export class ProgramaredialogComponent implements AfterViewInit{
  ngOnInit(): void {
  }
  datadestart = new FormControl(new Date());
  event:CalendarEvent;
  numeprogramare: string;
  selection = new SelectionModel(true, []);
  time = {hour: 13, minute: 30};
  start_time: Date;
  idPacientSelectat:number;
  programare_recurenta:boolean = false;
  nr_recurenta:number = 0;
  perioada_recurenta:number = 0;
  durata:number = 0;
  constructor(public serviciiservice: ServiciiService, public pacientiService: PacientiService, public dialogRef: MatDialogRef<ProgramaredialogComponent>, public dialg: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data:any ) {
      if(data){
        this.datadestart = new FormControl(data.data);
        // this.time = {hour: this.data.data.getHours(), minute: 0};
        this.start_time = data.data
        this.durata = data.durata
        console.log("Durata ==>> ", this.durata)
        // console.log("ora:",this.data.getHours());
      }
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    ngAfterViewInit() {
    }


    sendvalues(){
      console.log("end of prog diag ", this.datadestart)
      this.dialogRef.close([this.datadestart.value,this.programare_recurenta,this.nr_recurenta*this.perioada_recurenta]);
    }
    getpacientdata(pacientvals){}


}
