import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { logo_url_base64 } from 'src/app/models/logo-base64.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { FileService } from 'src/app/services/file.service';
import { EvaluareaINFSParintiInterface } from 'src/app/interfaces/documente.interface';
import { EvaluareaINFSParinti } from 'src/app/models/documente.models';
import { EvalserviceService } from 'src/app/services/evalservice.service';
import { PinentryComponent } from 'src/app/pinentry/pinentry.component';
import { ConsultatiiService } from 'src/app/services/consultatii.service';
import { ConsultCreate } from 'src/app/models/consult.model';
import { ConsultCreateInterface } from 'src/app/interfaces/consult.interface';
import { base_img_64 } from 'src/app/models/base-img64.model';
import { FileElementCreate } from 'src/app/file-manager/model/element';
import { FileElementCls } from 'src/app/models/fisier.model';
import { PdfpreviewerComponent } from 'src/app/pdfpreviewer/pdfpreviewer.component';
import { SemnaturiRLService } from 'src/app/services/semnaturirl.service';
import { SemnaturiTerapeutiFilesService } from 'src/app/services/semnaturiterapeutifiles.service';
import { SemnaturaRLCreateInterface } from 'src/app/interfaces/pacient.interface';
import { SemnaturaRLCreate } from 'src/app/models/pacientdetails.model';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-fisa-evaluare-infs-parinti',
  templateUrl: './fisa-evaluare-infs-parinti.component.html',
  styleUrls: ['./fisa-evaluare-infs-parinti.component.css']
})
export class FisaEvaluareInfsParintiComponent implements OnInit {
  @Output () fileid = new EventEmitter<string>();
  caz : number = 1
  idFisier : any = null
  beneficiar  : any = null
  pacientid: number
  fisa_evaluare_infs_parinti: EvaluareaINFSParintiInterface = new EvaluareaINFSParinti()
  ignora_campurile_goale: boolean = true
  semnatura_RL : any = null
  semnatura_Terapeut : any = null
  bkdataadaugare: any;
  constructor(
    public evaluarefieldsService: EvalserviceService ,
    public dialogRef: MatDialogRef<FisaEvaluareInfsParintiComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private datePipe: DatePipe,
    public fileService: FileService,
    public semnaturirlService: SemnaturiRLService,
    public semnaturiTerapeutFilesService: SemnaturiTerapeutiFilesService,
    public dialog: MatDialog,
    public consultatiiService: ConsultatiiService,
    ) {
    if(data){
      if (data.caz == 1){
        console.log("EVALUARE INFS PARINTI NEW ==>> DATA ==>> ",data )
        this.caz = 1
        this.beneficiar = data.beneficiar
        this.getInitialFieldsFromServer()
        // this.initDataCazEvaluareNoua(data.beneficiar)
      }
      else{
        if(data.caz == 2){
          this.caz = 2
          console.log("EVALUARE INFS PARINTI EDITAT")
          this.idFisier = data.fieldsData.idFisier
          this.getSavedDataFromServer(this.idFisier)
        }
      }
    }
   }
   initDataCazEvaluareNoua(beneficiar){
    console.log("Init Data Caz Evaluare Noua ==>> ", beneficiar)
    this.pacientid = beneficiar.id;
    this.fisa_evaluare_infs_parinti.pacientid = this.pacientid
    // console.log("this is data: => ", data.id);

    if (beneficiar.date_personale.nume){
      this.fisa_evaluare_infs_parinti.nume_pacient = beneficiar.date_personale.nume
    }
    if (beneficiar.date_personale.prenume){
      this.fisa_evaluare_infs_parinti.prenume_pacient = beneficiar.date_personale.prenume
    }
    if (beneficiar.date_personale.data_nasterii){
      var intermediar = Math.abs(Date.now() - new Date(beneficiar.date_personale.data_nasterii).getTime());
      var varsta_intermediar = Math.floor(intermediar / (1000 * 3600 * 24) / 365.25)
      this.fisa_evaluare_infs_parinti.varsta_pacient = varsta_intermediar.toString();
      // console.log("varsta in string: =>", this.fisa_evaluare_infs_parinti.varsta_pacient)
      // this.fisa_evaluare_infs_parinti.varsta_pacient = new Date(data.date_personale.data_nasterii) -
    }
    if (beneficiar.date_contact.adresa){
      var adresa_intermediar = ""
      if (beneficiar.date_contact.adresa.judet){
        adresa_intermediar = adresa_intermediar + " Judet: "+ beneficiar.date_contact.adresa.judet + ", ";
      }
      else{
        adresa_intermediar = adresa_intermediar + " Judet: -,";
      }
      if (beneficiar.date_contact.adresa.localitate){
        adresa_intermediar = adresa_intermediar + " Localitate: "+ beneficiar.date_contact.adresa.localitate + ", ";
      }
      else{
        adresa_intermediar = adresa_intermediar + " Localitate: -,";
      }
      if (beneficiar.date_contact.adresa.strada){
        adresa_intermediar = adresa_intermediar + " Strada: "+ beneficiar.date_contact.adresa.strada + ", ";
      }
      else{
        adresa_intermediar = adresa_intermediar + " Strada: -,";
      }
      if (beneficiar.date_contact.adresa.numar){
        adresa_intermediar = adresa_intermediar + " Numar: "+ beneficiar.date_contact.adresa.numar + ", ";
      }
      else{
        adresa_intermediar = adresa_intermediar + " Numar: -,";
      }
      if (beneficiar.date_contact.adresa.bloc){
        adresa_intermediar = adresa_intermediar + " Bloc: "+ beneficiar.date_contact.adresa.bloc + ", ";
      }
      else{
        adresa_intermediar = adresa_intermediar + " Bloc: -,";
      }
      if (beneficiar.date_contact.adresa.etaj){
        adresa_intermediar = adresa_intermediar + " Etaj: "+ beneficiar.date_contact.adresa.etaj + ", ";
      }
      else{
        adresa_intermediar = adresa_intermediar + " Etaj: -,";
      }
      if (beneficiar.date_contact.adresa.ap){
        adresa_intermediar = adresa_intermediar + " Apartament: "+ beneficiar.date_contact.adresa.ap + ", ";
      }
      else{
        adresa_intermediar = adresa_intermediar + " Apartament: -,";
      }
      this.fisa_evaluare_infs_parinti.adresa_pacient = adresa_intermediar
    }
   }
   getSavedDataFromServer(idFisier){
    console.log( "GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", idFisier)
    this.consultatiiService.getConsultDataFromServerByPFisierID(idFisier)
    .subscribe( (data) => {
      if (data){
        console.log( "DATA FROM GET CONSULT DATA FROM SERVER BY FISIER ID ==>> ID FISIER -->> ", data)
        this.fisa_evaluare_infs_parinti = data.dataJSON
      }
    })
  }
  getInitialFieldsFromServer(){
    this.evaluarefieldsService.getDocFieldsFromServerByID(22)
    .subscribe ( (data) => {
      if (data){
        console.log("DATA FROM EVALUAREA FUNCTIEI MOTORII GROSIERE -->> Initial Props ==>> ", data)
        // this.originalData = data
        this.fisa_evaluare_infs_parinti = data.props
        this.initDataCazEvaluareNoua(this.beneficiar)

      }
    })
   }
  ngOnInit(): void {
    if (this.caz == 2){
      this.semnaturirlService.getSemnaturaRLfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA RL FROM SERVER ==>> ", data)
          this.semnatura_RL = data.semnatura
        }
      })
      this.semnaturiTerapeutFilesService.getSemnaturaTerapeutFilesfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA TERAPEUT FROM SERVER ==>> ", data)
          this.semnatura_Terapeut = data.semnatura
        }
      })
    }
  // FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI
  // let intermediar: EvaluareaINFSParintiInterface = new EvaluareaINFSParinti()
  // let testone = {
  //   "denumire" : "Evaluarea INFS PARINTI",
  //   "props" : intermediar
  // }

  // this.evaluarefieldsService.addToServer(testone )
  // .subscribe( (data) =>{
  //   if (data){
  //     console.log("Data from add sectiunea A to server ==>> ", data)
  //   }
  //   else{
  //     console.log("NO !!! Data from add sectiunea A to server ==>> ")
  //   }
  // })

  // END FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI
  }

  saveData(){

  }

  semneazaRL(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_RL = res
        // console.log("Semnatura RL IMAGINE ESTE ==>> ",this.semnatura_RL)
      }
    })
  }
  semneazaTerapeut(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_Terapeut = res
        // console.log("Semnatura Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
      }
    })
  }
  addConsultToServer(idFisier){
    let intermediar_consult : ConsultCreateInterface = new ConsultCreate()
    intermediar_consult.idPacient = this.fisa_evaluare_infs_parinti.pacientid
    intermediar_consult.idFisier = idFisier
    intermediar_consult.dataAdaugare = this.bkdataadaugare
    intermediar_consult.idEvaluare = 22
    intermediar_consult.denumire = "Evaluarea INFS PARINTI"
    intermediar_consult.dataJSON = this.returnObjectForAddConsult()
    console.log(" ADD CONSULT TO SERVER ==>> ", intermediar_consult)
    this.consultatiiService.addConsultToServer(intermediar_consult)
    .subscribe( (data) => {
      if (data){
        console.log("DATA DIN ADD CONSULT TO SERVER ==>> ", data)
      }
      let semnatura_noua_RL : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
      semnatura_noua_RL.idFisier = idFisier
      semnatura_noua_RL.semnatura = this.semnatura_RL
      this.semnaturirlService.addSemnaturaRLToServer(semnatura_noua_RL)
      .subscribe ( (data) => {
        if (data){
          console.log ("DATA DIN ADD SEMNATURA RL TO SERVER ==>> ", data)
        }
      })
      let semnatura_noua_TerapeutFiles : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
      semnatura_noua_TerapeutFiles.idFisier = idFisier
      semnatura_noua_TerapeutFiles.semnatura = this.semnatura_Terapeut
      this.semnaturiTerapeutFilesService.addSemnaturaTerapeutFilesToServer(semnatura_noua_TerapeutFiles)
      .subscribe ( (data) => {
        if (data){
          console.log ("DATA DIN ADD SEMNATURA TERAPEUT FILES TO SERVER ==>> ", data)
        }
      })
    })
  }

  returnObjectForAddConsult(){
    let intermediar = {
      "dataJSON" : this.fisa_evaluare_infs_parinti
    }
    return intermediar
  }

  returnDashIfEmpty(variabila){
    if (variabila === ""){
      return "-"
    }
    return variabila
  }

  openPDFPreviewSsaveModificari(){
  let docDefinition = this.generatePDF()
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  pdfDocGenerator.getBase64((data) => {
    // console.log("data din pdfgenerator base 64 ==>> ",data);
    const dialogRef = this.dialog.open(PdfpreviewerComponent, {
      width: '800px',
      height: '100%',
      data: data
    });
    dialogRef.afterClosed().subscribe( async res => {
      if (res){
                  // this.semnatura_Terapeut = res
          // console.log("PDF PREVIEW FROM SAVE MODIFICARI RESULT AFTER CLOSE ==>> ",res)
          let update_file  = {
            'id' : this.idFisier,
            'dateadden' : this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm'),
            'path' : "data:application/pdf;base64,"+data,
            'name' : "Evaluarea INFS PARINTI - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
          }
          this.fileService.updateFileToServer(update_file)
          .subscribe ( (data) => {
            // console.log ( "DATA FROM UPDATE FILE FROM SERVER ==> ", data)
          })
          this.consultatiiService.updateConsultToServerByFisierID(this.idFisier,{'dataJSON' : this.returnObjectForAddConsult()})
          .subscribe( (data) => {
            // console.log ("DATA FROM ADD CONSULT TO SERVICE ==>> ", data)
          })
          this.dialogRef.close(1)
      }
    })
  });
}
  openPDFPreviewComponent(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      // console.log("data din pdfgenerator base 64 ==>> ",data);
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
      dialogRef.afterClosed().subscribe( async res => {
        if (res){
          let fisier_to_upload : FileElementCreate = new FileElementCls()
          fisier_to_upload.id = this.fileService.generateFileID();
          fisier_to_upload.idPacient = this.fisa_evaluare_infs_parinti.pacientid
          fisier_to_upload.isFolder = false
          fisier_to_upload.parent = 'root'
          fisier_to_upload.dateadden = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
          this.bkdataadaugare = fisier_to_upload.dateadden
          fisier_to_upload.type = "application/pdf"
          fisier_to_upload.extension = "pdf"
          fisier_to_upload.path = "data:application/pdf;base64,"+data
          fisier_to_upload.status = 'neverificat';
          fisier_to_upload.name = "Evaluarea INFS PARINTI - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
          let intermediar_from_server = await this.fileService.addFileToServerReturnFileID(fisier_to_upload).toPromise()
          this.addConsultToServer(intermediar_from_server)
          this.dialogRef.close(0)
        }
      })

    });
  }

  generatePDF(action = 'open') {

    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
        height: 60,
        columns:
          [
            // {
            //   image: logo_url_base64,
            //   width: 50,
            //   alignment: 'center',
            // },
            {
              stack: [
                "Fundația Mihai Neșu Foundation",
                "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
              ],
              margin: 10,
              style: 'textheader'
            }
          ],
        margin: [0, 0, 0, 90]
      },
      content: [
        {
          canvas: [
            {
              type: 'line',
              x1: -100, y1: 0,
              x2: 600, y2: 0,
              lineWidth: 1,
              lineCap: 'square'
            },
            {
              type: 'line',
              x1: -100, y1: 4,
              x2: 600, y2: 4,
              lineWidth: 1,
              lineCap: 'square'
            }
          ]
        },
        {
          image: logo_url_base64,
          width: 200,
          alignment: 'center',
        },
        {
          text: "FIȘĂ EVALUARE INFS",
          bold: true,
          alignment: 'center',
          fontSize: 16,
          margin: [0, 50, 0, 80]
        },
        {
          text: "Nume: " + this.fisa_evaluare_infs_parinti.nume_pacient,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Prenume: " + this.fisa_evaluare_infs_parinti.prenume_pacient,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Varsta: " + this.fisa_evaluare_infs_parinti.varsta_pacient + " ani",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Adresa: " + this.fisa_evaluare_infs_parinti.adresa_pacient,
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Diagnostic: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.diagnostic_pacient),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Istoric chirurgical: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.istoric_chirurgical),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Istoric de botox (data): " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.istoric_de_botox),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Istoric de fracturi, osteopenie, osteoporoză: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.istoric_de_fracturi_osteopenie_osteoporoza),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Greutatea copilului (KG): " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.greutatea_copilului),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Înălțime: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.inaltime),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Convulsii (frecvență, data ultimei convulsii):" + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.convulsii),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Probleme cardiace, pulmonare, renale: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.probleme_cardiace_pulmonare_renale),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Hidrocefalie/shunt: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.hidrocefalie_shunt),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Probleme metabolice/diabet: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.probleme_metabolice_diabet),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Comunicare ( verbală/non-verbală): " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.comunicare),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Atenție: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.antentie),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Abilități de îndeplinire a comenzilor: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.abilitati_de_indeplinire_a_comenzilor),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Abilități funcționale ale copilului (rotogolire, târâre, patrupedie, mers): " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.abilitati_functionale_ale_copilului),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Dureri: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.dureri),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Orteze (AFO/KAFO, etc): " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.orteze),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Lista echipamentelor pe care copilul le folosește (scaun rulant, cârje, triciclu, etc): " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.lista_echipamente_folosite),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Lista medicamentelor/suplimentelor: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.lista_medicamente_suplimente),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Somn (câte ore/noapte, ora la care adoarme seara): " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.somn),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Nutriție: ",
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 5]
        },
        {
          ul: [
            ' Câte mese/zi: ' + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.nutritie.cate_mese_zi),
            ' Alergii alimentare: ' + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.nutritie.alergii_alimentare),
            ' Lista cu exemple de alimente pe care le consumă copilul: ' + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.nutritie.exemple_alimente_consumate),
          ],
        },
        {
          text: "Oboseală (câte de repede obosește): " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.oboseala),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 20, 0, 20]
        },
        {
          text: "Starea emoțională și comportamentală a copilului (anxietate, frică, depresie, fericire, cât de repede plânge): " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.stare_emotionala),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Școală/interacțiune socială: " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.scoala_interactiune_sociala),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Tehnologia pe care o folosește copilul zilnic (TV, tabletă, telefon, jocuri video, cât timp/zi): " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.tehnologie_folosita_zilnic),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Terapie (ce fel de terapie/câte ore/zi): " + this.returnDashIfEmpty(this.fisa_evaluare_infs_parinti.terapie),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          stack: [
            {
              text: "Kinetoterapeut: " + this.fisa_evaluare_infs_parinti.nume_kinetoterapeut + ' ' + this.fisa_evaluare_infs_parinti.prenume_kinetoterapeut,
              bold: true,
              alignment: 'left',
              fontSize: 12,
              margin: [0, 0, 0, 20]
            },
            {
              image: this.semnatura_Terapeut ? this.semnatura_Terapeut : base_img_64,
                  height: 40,
                  width: 140,
              margin: [0, 0, 0, 20]
            }
          ]
        },
        {
          stack: [
            {
              text: "Luat la cunoștință de părinte, ",
              bold: true,
              alignment: 'right',
              fontSize: 12,
              margin: [0, 0, 0, 20]
            },
            {
              image: this.semnatura_RL ? this.semnatura_RL : base_img_64,
              alignment: 'right',
                  height: 40,
                  width: 140,
              margin: [0, 0, 0, 20]
            }
          ]
        }
      ],
      styles: {
        textheader: {
          alignment: 'center',
          bold: true,
          fontSize: 14,
          // lineHeight: 2
        }
      }
    };
    return docDefinition
  }

}
