import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { PacientsComponent } from './pacients/pacients.component';
import { MatTableModule } from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { AddpacientComponent } from './addpacient/addpacient.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ProgramariComponent } from './programari/programari.component';
import {MatBadgeModule} from '@angular/material/badge';
import { ListaAsteptareComponent } from './lista-asteptare/lista-asteptare.component';
import { NomenclatoareComponent } from './nomenclatoare/nomenclatoare.component';
import { InscrieriComponent } from './inscrieri/inscrieri.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PacientdetailsComponent } from './pacientdetails/pacientdetails.component';
import { CalendarUtilsComponent } from './calendar-utils/calendar-utils.component';
import { NewnomenclatordiagComponent } from './nomenclatoare/newnomenclatordiag/newnomenclatordiag.component';
import { NewpropertydiagComponent } from './nomenclatoare/newnomenclatordiag/newpropertydiag/newpropertydiag.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DateComponent } from './components/date/date.component';
import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { RadiobuttonComponent } from './components/radiobutton/radiobutton.component';
import { MenuComponent } from './components/menu/menu.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFieldDirective } from './components/dynamic-field/dynamic-field.directive';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import { DynamicMenuComponent } from './components/dynamic-menu/dynamic-menu.component';
import { MatMenuModule} from '@angular/material/menu';
import { DosarelectronicComponent } from './pacient/dosarelectronic/dosarelectronic.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { NewFolderDialogComponent } from './file-manager/modals/new-folder-dialog/new-folder-dialog.component';
import { RenameDialogComponent } from './file-manager/modals/rename-dialog/rename-dialog.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import { FileService } from './services/file.service';
import { FlexLayoutModule } from "@angular/flex-layout";
import { FuploadDirective } from './fileupload/fupload.directive';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { ProgressComponent } from './fileupload/progress/progress.component';
import { ProgramaredialogComponent } from './programaredialog/programaredialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';
import { CategoriiserviciiComponent } from './nomenclatoare/categoriiservicii/categoriiservicii.component';
import { ProduseComponent } from './nomenclatoare/produse/produse.component';
import { ServiciiComponent } from './nomenclatoare/servicii/servicii.component';
import { ConsultComponent } from './consult/consult.component';
import { AddconsultComponent } from './addconsult/addconsult.component';
import { HttpClientModule } from '@angular/common/http';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api'
import { MyInMemoryService } from './services/my-in-memory-service.service';
import { MyInMemoryCalitatePersoanaService } from './services/my-in-memory-calitate-persoana.service';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { TabelpacientiComponent } from './tabelpacienti/tabelpacienti.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule, NgxMatDateAdapter, NgxMatNativeDateAdapter } from '@angular-material-components/datetime-picker';
import { NgxMatMomentAdapter, NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { DatePipe, registerLocaleData } from '@angular/common';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FiledashboardComponent } from './filedashboard/filedashboard.component';
import { SpecialitatemediciComponent } from './nomenclatoare/specialitatemedici/specialitatemedici.component';
import { TipdepartamentComponent } from './nomenclatoare/tipdepartament/tipdepartament.component';
import { DepartamentComponent } from './nomenclatoare/departament/departament.component';
import { MediciComponent } from './nomenclatoare/medici/medici.component';
import { TipdiagnosticComponent } from './nomenclatoare/tipdiagnostic/tipdiagnostic.component';
import { DiagnosticComponent } from './nomenclatoare/diagnostic/diagnostic.component';
import { TipfurnizorserviciisocialeComponent } from './nomenclatoare/tipfurnizorserviciisociale/tipfurnizorserviciisociale.component';
import { FurnizorserviciisocialeComponent } from './nomenclatoare/furnizorserviciisociale/furnizorserviciisociale.component';
import { NomenclatorcasedeasigurariComponent } from './nomenclatoare/nomenclatorcasedeasigurari/nomenclatorcasedeasigurari.component';
import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { TemplatedocumenteComponent } from './templatedocumente/templatedocumente.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { EvaluareaFunctieiMotoriiGrosiereComponent } from './templatedocumente/componente-evaluari/evaluarea-functiei-motorii-grosiere/evaluarea-functiei-motorii-grosiere.component';
import { EvaluareaReflexelorPrimitiveComponent } from './templatedocumente/componente-evaluari/evaluarea-reflexelor-primitive/evaluarea-reflexelor-primitive.component';
import { FisaExaminareLogopedicaComponent } from './templatedocumente/componente-evaluari/fisa-examinare-logopedica/fisa-examinare-logopedica.component';
import { FisaEvaluareInfsParintiComponent } from './templatedocumente/componente-evaluari/fisa-evaluare-infs-parinti/fisa-evaluare-infs-parinti.component';
import { EvaluareMersComponent } from './templatedocumente/componente-evaluari/evaluare-mers/evaluare-mers.component';
import { EvaluareIndependentaMnfComponent } from './templatedocumente/componente-evaluari/evaluare-independenta-mnf/evaluare-independenta-mnf.component';
import { EvaluareMariComponent } from './templatedocumente/componente-evaluari/evaluare-mari/evaluare-mari.component';
import { ChestionarDeAlergiiComponent } from './templatedocumente/componente-chestionare/chestionar-de-alergii/chestionar-de-alergii.component';
import { ContractFurnizorServiciiSocialeComponent } from './templatedocumente/componente-contracte/contract-furnizor-servicii-sociale/contract-furnizor-servicii-sociale.component';
import { ContractMasinaComponent } from './templatedocumente/componente-contracte/contract-masina/contract-masina.component';
import { ContractInchiriereComponent } from './templatedocumente/componente-contracte/contract-inchiriere/contract-inchiriere.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { FisaMonitorizarePpiComponent } from './templatedocumente/componente-monitorizari-plan-interventie/fisa-monitorizare-ppi/fisa-monitorizare-ppi.component';
import { PlanDeInterventiePersonalizatComponent } from './templatedocumente/componente-monitorizari-plan-interventie/plan-de-interventie-personalizat/plan-de-interventie-personalizat.component';
import { PlandeinterventieComponent } from './plandeinterventie/plandeinterventie.component';
import { EditprogramareComponent } from './editprogramare/editprogramare.component';
import { CalendarComponentx } from './calendar/calendar.component';
import { CalendarUtilsModule } from './calendar-utils/module/module.module';
import localeRo from '@angular/common/locales/ro';
import {MatChipsModule} from '@angular/material/chips';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { ProgramareEroareComponent } from './programare-eroare/programare-eroare.component';
import { IstoricprogramariComponent } from './istoricprogramari/istoricprogramari.component';
import { ContractePacientComponent } from './contracte-pacient/contracte-pacient.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CategoriiserviciieditComponent } from './nomenclatoare/categoriiserviciiedit/categoriiserviciiedit.component';
import { ServiciieditComponent } from './nomenclatoare/serviciiedit/serviciiedit.component';
import { SpecialitateeditComponent } from './nomenclatoare/specialitateedit/specialitateedit.component';
import { TipdepartamenteditComponent } from './nomenclatoare/tipdepartamentedit/tipdepartamentedit.component';
import { TipdiagnosticeditComponent } from './nomenclatoare/tipdiagnosticedit/tipdiagnosticedit.component';
import { DepartamenteditComponent } from './nomenclatoare/departamentedit/departamentedit.component';
import { DiagnosticeditComponent } from './nomenclatoare/diagnosticedit/diagnosticedit.component';
import { ContulmeuComponent } from './contulmeu/contulmeu.component';
import { IstoricProgramariComponent } from './istoric-programari/istoric-programari.component';
// import { DateAdapter } from '@angular/material';
// import { CountdownTimerModule } from 'ngx-countdown-timer';
import { CountdownModule } from 'ngx-countdown';
import { LoginpageComponent } from './loginpage/loginpage.component';
import { CasierieComponent } from './casierie/casierie.component';
import { CasierieserviciiComponent } from './casierieservicii/casierieservicii.component';
import { IncasarecasierieComponent } from './incasarecasierie/incasarecasierie.component';
import { CountdownComponent } from './countdown/countdown.component';
import { PinentryComponent } from './pinentry/pinentry.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { TerapiilemeleComponent } from './terapiilemele/terapiilemele.component';
import { MedicieditComponent } from './nomenclatoare/mediciedit/mediciedit.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdfpreviewerComponent } from './pdfpreviewer/pdfpreviewer.component';
import { DatePickerModule, CalendarModule as CalendarBun } from '@syncfusion/ej2-angular-calendars';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { DashboardbeneficiariComponent } from './dashboardbeneficiari/dashboardbeneficiari.component';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { BeneficiarDetailsTabComponent } from './beneficiar-details-tab/beneficiar-details-tab.component';
import { FormDatePersonaleComponent } from './form-date-personale/form-date-personale.component';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { EditeazaconsultbuttonComponent } from './editeazaconsultbutton/editeazaconsultbutton.component';
import { VizualizeazaconsultpdfbuttonComponent } from './vizualizeazaconsultpdfbutton/vizualizeazaconsultpdfbutton.component';
import { DescarcaconsultpdfbuttonComponent } from './descarcaconsultpdfbutton/descarcaconsultpdfbutton.component';
import { StergeconsultpdfbuttonComponent } from './stergeconsultpdfbutton/stergeconsultpdfbutton.component';
import { ConfirmationdialogComponent } from './confirmationdialog/confirmationdialog.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MY_DATE_FORMATS } from './services/my-date-formats';
import { DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerAllModule, TimePickerAllModule, DateTimePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService} from '@syncfusion/ej2-angular-schedule';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { RecurrenceEditorModule } from '@syncfusion/ej2-angular-schedule';
registerLocaleData(localeRo);

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    PacientsComponent,
    AddpacientComponent,
    ListaAsteptareComponent,
    NomenclatoareComponent,
    InscrieriComponent,
    PacientdetailsComponent,
    NewnomenclatordiagComponent,
    NewpropertydiagComponent,
    ButtonComponent,
    CheckboxComponent,
    DateComponent,
    InputComponent,
    SelectComponent,
    RadiobuttonComponent,
    MenuComponent,
    DynamicFormComponent,
    DynamicFieldDirective,
    DynamicMenuComponent,
    DosarelectronicComponent,
    FileManagerComponent,
    NewFolderDialogComponent,
    RenameDialogComponent,
    FileuploadComponent,
    ProgressComponent,
    FuploadDirective,
    ProgramaredialogComponent,
    CategoriiserviciiComponent,
    ProduseComponent,
    ServiciiComponent,
    ConsultComponent,
    AddconsultComponent,
    TabelpacientiComponent,
    FiledashboardComponent,
    SpecialitatemediciComponent,
    TipdepartamentComponent,
    DepartamentComponent,
    MediciComponent,
    TipdiagnosticComponent,
    DiagnosticComponent,
    TipfurnizorserviciisocialeComponent,
    FurnizorserviciisocialeComponent,
    NomenclatorcasedeasigurariComponent,
    TemplatedocumenteComponent,
    EvaluareaFunctieiMotoriiGrosiereComponent,
    EvaluareaReflexelorPrimitiveComponent,
    FisaExaminareLogopedicaComponent,
    FisaEvaluareInfsParintiComponent,
    EvaluareMersComponent,
    EvaluareIndependentaMnfComponent,
    EvaluareMariComponent,
    ChestionarDeAlergiiComponent,
    ContractFurnizorServiciiSocialeComponent,
    ContractMasinaComponent,
    ContractInchiriereComponent,
    FisaMonitorizarePpiComponent,
    PlanDeInterventiePersonalizatComponent,
    PlandeinterventieComponent,
    EditprogramareComponent,
    CalendarComponentx,
    ProgramariComponent,
    ProgramareEroareComponent,
    IstoricprogramariComponent,
    ContractePacientComponent,
    CategoriiserviciieditComponent,
    ServiciieditComponent,
    SpecialitateeditComponent,
    TipdepartamenteditComponent,
    TipdiagnosticeditComponent,
    DepartamenteditComponent,
    DiagnosticeditComponent,
    ContulmeuComponent,
    IstoricProgramariComponent,
    LoginpageComponent,
    CasierieComponent,
    CasierieserviciiComponent,
    IncasarecasierieComponent,
    CountdownComponent,
    PinentryComponent,
    TerapiilemeleComponent,
    MedicieditComponent,
    PdfpreviewerComponent,
    DashboardbeneficiariComponent,
    BeneficiarDetailsTabComponent,
    FormDatePersonaleComponent,
    EditeazaconsultbuttonComponent,
    VizualizeazaconsultpdfbuttonComponent,
    DescarcaconsultpdfbuttonComponent,
    StergeconsultpdfbuttonComponent,
    ConfirmationdialogComponent

  ],
  imports: [
    RecurrenceEditorModule,
    TimePickerModule,
    DatePickerAllModule,
    TimePickerAllModule,
    DateTimePickerAllModule,
    MultiSelectAllModule,
    DropDownListAllModule,
    MomentDateModule,
    SwitchModule,
    CheckBoxModule,
    ButtonModule,
    DatePickerModule,
    TabModule,
    DialogModule,
    DashboardLayoutModule,
    GridModule,
    ScheduleModule,
    CalendarBun,
    SignaturePadModule,
    MatTooltipModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatMomentDateModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatBadgeModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatRadioModule,
    MatMenuModule,
    MatGridListModule,
    MatCardModule,
    FlexLayoutModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatPaginatorModule,
    HttpClientModule,
    MatTabsModule,
    MatSlideToggleModule,
    CountdownModule,
    NgxMaterialTimepickerModule,
    // CountdownTimerModule.forRoot(),
    HttpClientInMemoryWebApiModule.forRoot(MyInMemoryCalitatePersoanaService, {
      passThruUnknownUrl: true
    }),
    StorageServiceModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxExtendedPdfViewerModule,
    SelectAutocompleteModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatSliderModule,
    MatSnackBarModule,
    NgxMatMomentModule,
    MatChipsModule,
    MatBottomSheetModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CalendarUtilsModule,
  ],
  providers: [
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    MonthAgendaService,
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    FileService,DatePipe,{ provide: NgxMatDateAdapter, useClass: NgxMatNativeDateAdapter },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  // constructor(private dateAdapter: DateAdapter<Date>) {
  //   this.dateAdapter.setLocale('pt-BR');
  // }
}
