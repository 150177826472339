import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of  } from 'rxjs';
import {catchError} from 'rxjs/operators/catchError';
import { serverurl } from './server.service';
import { CasieriiServiciiInterface, IncasaricreateInterface } from '../interfaces/nomenclatoare.interface';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
  providedIn: 'root'
})
export class IncasariService {
  private server_url=serverurl+"/api/v1/incasaretwo/"

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient
  ) { }

  addplataincasierie(incasare_noua: IncasaricreateInterface){
    console.log("Add Incasare in Casierie to server ==>> ", incasare_noua)
    return (this.http.post<any>(this.server_url, incasare_noua, this.httpOptions))
  }
  getPlatifromServer(serviciucasierieid: number){
    return this.http.get(this.server_url+"incasaribycasierieserviciuid/"+serviciucasierieid)
  }

  getbybeneficiarid(beneficiarid: number){
    console.log("Get servicii din casierie by beneficiar id ==>> ", beneficiarid)
    return (this.http.get<any>(this.server_url+beneficiarid ))
  }

  parse_uneste_data_cu_timo(value: any): Date | null{
    if ((typeof value === 'string') && (value.includes('-')) && (value.includes(':'))) {
      const str = value.split('-');
      const tims = str[3].split(':')
      const year = Number(str[0]);
      const month = Number(str[1]) - 1;
      const date = Number(str[2]);

      const hour = Number (tims[0])
      const minute = Number (tims[1])
      return new Date(year, month, date, hour, minute);
    } else if((typeof value === 'string') && value === '') {
      return new Date();
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  parsedata(value: any): Date | null {
    if ((typeof value === 'string') && (value.includes('-')) && (value.includes(':'))) {
      const str = value.split('-');
      const tims = str[3].split(':')
      const year = Number(str[0]);
      const month = Number(str[1]) - 1;
      const date = Number(str[2]);

      const hour = Number (tims[0])
      const minute = Number (tims[1])

      // console.log(" Date din Parse Data --> year = ", year, " month = ", month, " date = ", date, " hour = ", hour, " munite = ", minute," data initiala =", value)
      return new Date(year, month, date);
    } else if((typeof value === 'string') && value === '') {
      return new Date();
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  parsetime(value: any): Date | null {
    if ((typeof value === 'string') && (value.includes('-')) && (value.includes(':'))) {
      const str = value.split('-');
      const tims = str[3].split(':')
      const year = Number(str[0]);
      const month = Number(str[1]) - 1;
      const date = Number(str[2]);

      const hour = Number (tims[0])
      const minute = Number (tims[1])

      console.log(" Date din Parse Data --> year = ", year, " month = ", month, " date = ", date, " hour = ", hour, " munite = ", minute," data initiala =", value)
      return new Date(year, month,date,hour, minute);
    } else if((typeof value === 'string') && value === '') {
      return new Date();
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

}
