<div style="text-align: center;">

    <button mat-raised-button color="primary"  style="margin-right: 10px;">Adauga in lista de asteptare</button>
        <mat-form-field floatLabel="never" appearance="outline" color="accent">
            <b><input matInput (keyup)="applyFilter($event)" #input color="warn" placeholder="Filtreaza" ></b>
        </mat-form-field>
    
    </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        
            <!-- ID Column -->
            <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
        
            <!-- Nume Column -->
            <ng-container matColumnDef="nume"> 
            <th mat-header-cell *matHeaderCellDef> Nume </th>
            <td mat-cell *matCellDef="let element"> {{element.nume}} </td>
            </ng-container>
        
            <!-- Prenume Column -->
            <ng-container matColumnDef="prenume">
            <th mat-header-cell *matHeaderCellDef> Prenume </th>
            <td mat-cell *matCellDef="let element"> {{element.prenume}} </td>
            </ng-container>
        
            <!-- CNP Column -->
            <ng-container matColumnDef="CNP">
            <th mat-header-cell *matHeaderCellDef> CNP </th>
            <td mat-cell *matCellDef="let element"> {{element.CNP}} </td>
            </ng-container>
                
            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>
                
            <!-- Telefon Column -->
            <ng-container matColumnDef="telefon">
                <th mat-header-cell *matHeaderCellDef> Telefon </th>
                <td mat-cell *matCellDef="let element"> {{element.telefon}} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
        
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>
    
      