import { Component, QueryList, ViewChildren } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LocalStorageService } from '../services/local-storage.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ContulmeuComponent } from '../contulmeu/contulmeu.component';
import { LoginService } from '../services/login.service';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent {
  loggedIn: number = 0

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
    @ViewChildren('drawer') drawer: MatSidenav
  constructor(public loginService: LoginService, public dialog: MatDialog,private localStorageService: LocalStorageService, private breakpointObserver: BreakpointObserver) {
    this.loggedIn = this.loginService.isLoggedIn()
  }

  deleteLocalStorage(){
    let keyz = this.localStorageService.checkKeysfromarray();
    console.log("keyz: =>", keyz)
    keyz.forEach(element => {
      console.log("deleting LocalStorage Key: =>", element);
      this.localStorageService.remove(element);
    })
  }
  toggle() {
    this.drawer.toggle();
  }

  opencontulmeu(){
    const dialogRef = this.dialog.open(ContulmeuComponent, {
      width: '850px',
      height: '550px',
      data: {},

    });
  }
}
