<table style="width: 100%;" mat-table [dataSource]="programari" class="mat-elevation-z8">
  <!-- id Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> ID </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>
  <!-- data Column -->
  <ng-container matColumnDef="data">
    <th mat-header-cell *matHeaderCellDef> Data </th>
    <td mat-cell *matCellDef="let element"> {{element.data}} </td>
  </ng-container>
  <!-- Ora_start Column -->
  <ng-container matColumnDef="Ora_start">
    <th mat-header-cell *matHeaderCellDef> Ora start </th>
    <td mat-cell *matCellDef="let element"> {{element.ora_start}} </td>
  </ng-container>
  <!-- Ora_end Column -->
  <ng-container matColumnDef="Ora_end">
    <th mat-header-cell *matHeaderCellDef> Ora sfarsit </th>
    <td mat-cell *matCellDef="let element"> {{element.ora_end}} </td>
  </ng-container>
  <!-- Ora_end Column -->
  <ng-container matColumnDef="Semneaza">
    <th mat-header-cell *matHeaderCellDef>  </th>
    <td mat-cell *matCellDef="let element">
      <button *ngIf="element.confirmat < 1" mat-raised-button color='primary' (click)="semneazacondica(element.id)">Semneaza</button>
      <div *ngIf="element.confirmat > 0"> <b>Semnat</b> </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
