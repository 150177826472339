<mat-card>
  <mat-card-header style="padding-left: 35%;" >
    <mat-card-title > Detalii: </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list  cols="8" rowHeight="65px" >
     <mat-grid-tile class = "bordurica-mat-grid-list"  colspan="2" >
       <mat-form-field>
           <input matInput [(ngModel)]="medic.nume"  placeholder="Nume" required [disabled] = "!flag_edit_detalii">
           <!-- <mat-hint class="error-hint" *ngIf="numeError">Numele este obligatoriu</mat-hint> -->
        </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2" >
       <mat-form-field>
         <input matInput [(ngModel)]="medic.prenume" placeholder="Prenume" required [disabled] = "!flag_edit_detalii">
           <!-- <mat-hint class="error-hint" *ngIf="prenumeError">Prenumele este obligatoriu</mat-hint> -->
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2"  >
       <mat-form-field>
         <input matInput [(ngModel)]="medic.telefon" placeholder="Telefon" required [disabled] = "!flag_edit_detalii">
           <!-- <mat-hint class="error-hint" *ngIf="telefonError">Telefonul este obligatoriu</mat-hint> -->
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2"  >
       <mat-form-field>
         <input matInput [(ngModel)]="medic.mail" placeholder="Mail" required [disabled] = "!flag_edit_detalii">
         <!-- <mat-hint class="error-hint" *ngIf="emailError">Email-ul este obligatoriu</mat-hint> -->
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="1"  >
       <mat-form-field style="width: 90%;" >
         <mat-label>Sex</mat-label>
         <mat-select [(ngModel)]="medic.sex" required [disabled] = "!flag_edit_detalii">
           <mat-option value="masculin">Masculin</mat-option>
           <mat-option value="feminin">Feminin</mat-option>
         </mat-select>
         <!-- <mat-hint class="error-hint" *ngIf="sexError">Selectarea sexului este obligatorie</mat-hint> -->
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="1"  >
       <mat-form-field style="width: 90%;">
         <mat-label>Greutate</mat-label>
         <input matInput type="text" [(ngModel)]= "medic.greutate" required [disabled] = "!flag_edit_detalii">
         <span matSuffix>kg</span>
           <!-- <mat-hint class="error-hint" *ngIf="greutateError">Greutatea este obligatorie</mat-hint> -->
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2"  >
       <mat-form-field>
         <input matInput [(ngModel)]="medic.cnp" placeholder="CNP" [disabled] = "!flag_edit_detalii">
        </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2"  >
       <mat-form-field>
         <mat-label>Specialitate</mat-label>
         <!-- <mat-select [(value)] = medic.specialitatea_id [disabled] = "!flag_edit_detalii"> -->
         <mat-select [(ngModel)] = "medic.specialitatea_id" (ngModelChange)="changeSpecialitate($event)"  [disabled] = "!flag_edit_detalii">
             <mat-option *ngFor="let specialitateq of specialitateMedici" [value]="specialitateq.id">
                 <!-- {{specialitateq | json}} -->
                 {{specialitateq.denumire}}
             </mat-option>
         </mat-select>
         </mat-form-field>
     </mat-grid-tile>
     <mat-grid-tile class = "bordurica-mat-grid-list" colspan="2"  >
       <mat-form-field>
         <mat-label>Servicii</mat-label>
         <mat-select [(ngModel)] = medic.servicii_id required (ngModelChange)="changeServicii($event)" [disabled] = "!flag_edit_detalii">
             <mat-option *ngFor="let serviciu of servicii" [value]="serviciu.id">
                 {{serviciu.denumire}}
             </mat-option>
         </mat-select>
           <!-- <mat-hint class="error-hint" *ngIf="serviciiError">Selectarea unui este obligatoriu</mat-hint> -->
         </mat-form-field>
     </mat-grid-tile>
   </mat-grid-list>
  </mat-card-content>
 </mat-card>
<br>
<button mat-raised-button color="primary" (click)="editDetalii()" *ngIf="!flag_edit_detalii"> Editeaza detaliile terapeutului </button>
<button mat-raised-button class="save-button" (click)="saveDetalii()" *ngIf="flag_edit_detalii"> Salveaza detaliile terapeutului </button>
<br>
  <!-- Card-ul cu Programul -->
  <mat-card style="width: 100%;max-height: 550px;text-align: center;">
    <mat-card-header style="padding-left: 35%;">
        <mat-card-title > Program: </mat-card-title>
      <!-- <mat-card-subtitle> {{ (selectedpacient.date_personale.data_nasterii | date: 'd.MM.YYYY') + " -- " + varsta_cronologica + " ani" }} </mat-card-subtitle> -->
    </mat-card-header>
<mat-card-content>
  <strong>
    <mat-grid-list cols="5"  [style.border] = '30' *ngIf = "flag_edit_program" [rowHeight]="inaltime_randuri_grid_program" #programpezile id="programpezile">
      <mat-grid-tile class = "bordurica-mat-grid-list" #zidinprogram id="zidinprogram" >
        Luni
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" #zidinprogram id="zidinprogram">
      Marti
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" #zidinprogram id="zidinprogram"  >
      Miercuri
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" #zidinprogram id="zidinprogram"  >
      Joi
      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" #zidinprogram id="zidinprogram"  >
      Vineri
      </mat-grid-tile>

      <mat-grid-tile class = "bordurica-mat-grid-list" [rowspan] = "row_span_grid_program" >

      <div style="display: flex;align-items: center;" #intervaldinprogram id="intervaldinprogram">

        <ngx-mat-timepicker
              [(ngModel)]="medic.program.luni.OraStart"
              [showSpinners]="true">
        </ngx-mat-timepicker>

        <div style="height:100%;" #separatortimepickerintervale id="separatortimepickerintervale"> -  </div>

        <ngx-mat-timepicker
        [(ngModel)]="medic.program.luni.OraEnd"
        [showSpinners]="true">
        </ngx-mat-timepicker>
        </div>

      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list"  [rowspan] = "row_span_grid_program">

      <div style="display: flex;align-items: center;" #intervaldinprogram id="intervaldinprogram">

        <ngx-mat-timepicker
              [(ngModel)]="medic.program.marti.OraStart"
              [showSpinners]="true">
        </ngx-mat-timepicker>

        <div style="height:100%;" #separatortimepickerintervale id="separatortimepickerintervale"> -  </div>

        <ngx-mat-timepicker
        [(ngModel)]="medic.program.marti.OraEnd"
        [showSpinners]="true">
        </ngx-mat-timepicker>
        </div>

      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" [rowspan] = "row_span_grid_program" >

      <div style="display: flex;align-items: center;" #intervaldinprogram id="intervaldinprogram">

      <ngx-mat-timepicker
            [(ngModel)]="medic.program.miercuri.OraStart"
            [showSpinners]="true">
      </ngx-mat-timepicker>

      <div style="height:100%;" #separatortimepickerintervale id="separatortimepickerintervale"> -  </div>

      <ngx-mat-timepicker
      [(ngModel)]="medic.program.miercuri.OraEnd"
      [showSpinners]="true">
      </ngx-mat-timepicker>
      </div>

      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" [rowspan] = "row_span_grid_program" >

      <div style="display: flex;align-items: center;" #intervaldinprogram id="intervaldinprogram">

      <ngx-mat-timepicker
          [(ngModel)]="medic.program.joi.OraStart"
          [showSpinners]="true">
      </ngx-mat-timepicker>

      <div style="height:100%;" #separatortimepickerintervale id="separatortimepickerintervale"> -  </div>

      <ngx-mat-timepicker
      [(ngModel)]="medic.program.joi.OraEnd"
      [showSpinners]="true">
      </ngx-mat-timepicker>
      </div>

      </mat-grid-tile>
      <mat-grid-tile class = "bordurica-mat-grid-list" [rowspan] = "row_span_grid_program" >

      <div style="display: flex;align-items: center;" #intervaldinprogram id="intervaldinprogram">

      <ngx-mat-timepicker
        [(ngModel)]="medic.program.vineri.OraStart"
        [showSpinners]="true">
      </ngx-mat-timepicker>

      <div style="height:100%;" #separatortimepickerintervale id="separatortimepickerintervale"> -  </div>

      <ngx-mat-timepicker
      [(ngModel)]="medic.program.vineri.OraEnd"
      [showSpinners]="true">
      </ngx-mat-timepicker>
      </div>

      </mat-grid-tile>
    </mat-grid-list>
  </strong>
  <br>
  <button mat-raised-button class="save-button" (click)="saveProgram()" *ngIf = "flag_edit_program" > Salveaza programul</button>
  <!-- <button mat-raised-button color="primary" (click)="editProgram()" *ngIf = "!flag_edit_program" > Editeaza programul</button> -->
  <div *ngIf="!flag_edit_program">
    <table mat-table [dataSource]="programDataSource" class="mat-elevation-z8" style="width: 100%;">

      <ng-container matColumnDef="Luni">
         <th mat-header-cell *matHeaderCellDef > Luni </th>
         <td mat-cell *matCellDef="let element"> {{(element.program.luni.OraStart) | date: ' H:mm ' }} - {{ element.program.luni.OraEnd | date: ' H:mm '  }} </td>
     </ng-container>

      <ng-container matColumnDef="Marti">
          <th mat-header-cell *matHeaderCellDef> Marti </th>
          <td mat-cell *matCellDef="let element"> {{element.program.marti.OraStart | date: ' H:mm ' }} - {{ element.program.marti.OraEnd | date: ' H:mm ' }} </td>
      </ng-container>

      <ng-container matColumnDef="Miercuri">
          <th mat-header-cell *matHeaderCellDef> Miercuri </th>
          <td mat-cell *matCellDef="let element"> {{element.program.miercuri.OraStart | date: ' H:mm ' }} - {{ element.program.miercuri.OraEnd | date: ' H:mm ' }} </td>
      </ng-container>

      <ng-container matColumnDef="Joi">
          <th mat-header-cell *matHeaderCellDef> Joi </th>
          <td mat-cell *matCellDef="let element"> {{element.program.joi.OraStart | date: ' H:mm ' }} - {{ element.program.joi.OraEnd | date: ' H:mm ' }} </td>
      </ng-container>

      <ng-container matColumnDef="Vineri">
          <th mat-header-cell *matHeaderCellDef> Vineri </th>
          <td mat-cell *matCellDef="let element"> {{element.program.vineri.OraStart | date: ' H:mm ' }} - {{ element.program.vineri.OraEnd | date: ' H:mm ' }} </td>
      </ng-container>
      <ng-container matColumnDef="Edit">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button class="save-button" (click)="editProgram()"> Edit</button>
        </td>
    </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsProgram"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsProgram;"></tr>

  </table>
  </div>

</mat-card-content>

  </mat-card>

  <mat-card style="width: 100%; " >

    <mat-card-content >
      <mat-grid-list cols="2" rowHeight={{inaltime_card}} #listaadaugaintervale id="listaadaugaintervale" >
        <mat-grid-tile   rowspan="1">
          <div id = "wrapperInterval" style="display: flex;">
            <div style="display: flex;align-self: flex-start;" id = "intervalorastart" #intervalorastart>

              <ngx-mat-timepicker
                    [(ngModel)]="interval_orar_start"
                    [showSpinners]="true">
              </ngx-mat-timepicker>

              <div style="height:100%;display: flex; align-self: center;padding-right: 30px;" #separatortimepicker id="separatortimepicker"> -  </div>

              <ngx-mat-timepicker
              [(ngModel)]="interval_orar_end"
              [showSpinners]="true">
              </ngx-mat-timepicker>
            </div>

            <div id = "wrapperButoane" style=" display: flex; align-self: center;" >
              <button *ngIf="!flag_editeaza_interval_orar" mat-raised-button color="primary" (click)="addNewInterval()" style="display: flex;align-self: flex-start;" id="adaugaintervalbutton" #adaugaintervalbutton> Adauga Interval</button>
              <button *ngIf="flag_editeaza_interval_orar" mat-raised-button class="save-button-two" (click)="salveazaInterval()" style="display: flex;align-self: flex-start;" id="adaugaintervalbutton" #adaugaintervalbutton> Salveaza Modificarile</button>
            </div>

          </div>
            <br>

        </mat-grid-tile>
        <mat-grid-tile  rowspan="1">
          <!-- <div style="overflow: scroll; padding-top: 50px;"> -->
            <table mat-table [dataSource]="intervaleDataSource" class="mat-elevation-z8" style="width: 90%;" id="tabelintervale" #tabelintervale>
              <ng-container matColumnDef="interval">
                <th mat-header-cell *matHeaderCellDef> <b>Intervale</b> </th>
                <td mat-cell *matCellDef="let element" style="text-align:center"> {{element.start }} - {{ element.end }} </td>
              </ng-container>
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                  <button mat-raised-button class="save-button" (click)="editeazaInterval(element)" [disabled]="flag_editeaza_interval_orar && interval_editat_index!=medic.intervale_zilnice.indexOf(element)">edit</button>
                </td>
              </ng-container>

              <ng-container matColumnDef="intervalselect">
                <th mat-header-cell *matHeaderCellDef>
                  <button  mat-raised-button color="warn" [disabled]="intervale_orare_todelete.length == 0" (click)="deleteIntervaleOrare()" > Sterge </button>
                </th>
                <td mat-cell *matCellDef="let element">

                  <mat-checkbox (change)="marktodelete(element)"></mat-checkbox>

                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsIntervale" style="height: 35px;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsIntervale;" style="height: 35px;"></tr>
            </table>
          <!-- </div> -->
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
    <!-- <button mat-raised-button color="primary" (click)="AddMedicToServer()" *ngIf="flag_add_medic && flag_fixed_program && flag_fixed_intervale_orare "> Adauga Terapeut</button> -->
    <button mat-raised-button color="primary" (click)="saveEditedMedic()" > Salveaza Modificarile</button>
    <button mat-raised-button color="warn" mat-dialog-close >Inchide</button>

  </mat-card>
