<div style="height: 100%; width: 100%;">
  <div style=" margin: auto; width: 400px; height: 500px">
    <div style="display:flex;justify-content: center">
      <img src="/assets/img/logo.png"  style="width:200px">
    </div>
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Login cu parola">
        <form style="width: 100%;" >
          <mat-form-field class="example-full-width" appearance="fill"style="width: 100%;">
            <mat-label>Email sau Telefon</mat-label>
            <input type="text" matInput >
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="fill"style="width: 100%;">
            <mat-label>Parola</mat-label>
            <input type="password" matInput [formControl]="emailFormControl">
          </mat-form-field>
        </form>
       <button mat-raised-button color="accent">Login</button>
      </mat-tab>
      <mat-tab label="Login cu PIN">
        <form style="width: 100%;">
          <div *ngIf="cupin == 0">
            <mat-form-field class="example-full-width" appearance="fill"style="width: 100%;">
              <mat-label>Email sau Telefon</mat-label>
              <input type="text" matInput [formControl]="emailFormControl">
            </mat-form-field>
            <button mat-raised-button color="accent" (click)="logincupin()">Continua</button>
          </div>
          <div *ngIf="cupin == 1">
            <mat-form-field class="example-full-width" appearance="fill"style="width: 100%;">
              <mat-label>PIN</mat-label>
              <input type="text" matInput [formControl]="emailFormControl">
            </mat-form-field>
            <button mat-raised-button color="accent">Login</button>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
