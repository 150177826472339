import { Injectable } from '@angular/core';
import { ValabilitateInterface,ValabilitateCreateInterface } from '../interfaces/nomenclatoare.interface'
import { Valabilitate } from '../models/nomenclatoare.model'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {catchError} from 'rxjs/operators/catchError';
import { Observable, of  } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { serverurl } from './server.service';
import { NativeDateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface Consultatie{
  investigatii: string;
  recomandari: string;
  diagnostic: string;
  tratament: string;
}

var consultatiilist:Consultatie [] = []

@Injectable({
  providedIn: 'root'
})

export class IntervalorarmedicService {
  public IntervalList: ValabilitateInterface [] = []

  private server_url=serverurl+"/api/v1/intervalorar/";
  private server_url_indisponibilitate=serverurl+"/api/v1/intervaleindisponibilitate/";

  // private server_url="http://localhost/api/v1/interval/";


  constructor(private http: HttpClient,public datepipe: DatePipe) { }

  adaugaIntervalIndisponibilitate( interval_de_adaugat ){
   return this.http.post<any>(this.server_url_indisponibilitate,interval_de_adaugat, httpOptions)
  }

  stergeIntervalIndisponibilitateMultiple( ids ){
    return this.http.post<any>(this.server_url_indisponibilitate+"multiple/" ,ids, httpOptions)
  }

  stergeIntervalOrarFromServer( id ){
    return this.http.delete<any>(this.server_url+id, httpOptions)
  }

  updateIntervalIndisponibilitate( interval ){
    return this.http.put<any>(this.server_url_indisponibilitate,interval, httpOptions)
  }


  updateIntervalOrar(id,interval){
    return this.http.put(this.server_url+id,interval, httpOptions)
  }
  getIntervalOrarFromServer(){
    return(this.http.get(this.server_url))
  }

returnStringDate(ora_in_date_format: Date){
  return (this.datepipe.transform(ora_in_date_format,'HH:mm'))
}
getHours(ora_in_string_format: string){
  return (Number(ora_in_string_format.substring(0,ora_in_string_format.indexOf(':'))))
}
getMinutes(ora_in_string_format: string){
  return (Number(ora_in_string_format.substring(ora_in_string_format.indexOf(":")+1,ora_in_string_format.length)))
}
returnDateString(ora_in_string_format: string):Date{
  var intermediar_ora = this.getHours(ora_in_string_format)
  var intermediar_minute = this.getMinutes(ora_in_string_format)
  console.log("Get Hours ==>> ", intermediar_ora)
  console.log("Get minutes ==>> ", intermediar_minute )
  var intermediar_data = new Date()
  intermediar_data.setHours(intermediar_ora,intermediar_minute)
  console.log("intermediar_data ==>> ", intermediar_data)
  return intermediar_data
}
serchIntervalOrarfromserver(id: number) {
    return this.http.get(this.server_url+id)
  }
addIntervalOrarToServer(id_medic ,interval){
    console.log("POST request la interval => ", interval)
   return this.http.post<any>(this.server_url+id_medic,interval,cudOptions)
  }
}


export const ZILELE_SAPTAMANII=["luni","marti","miercuri","joi","vineri","sambata","duminica"]
