import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConsultatiiService } from '../services/consultatii.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChestionarDeAlergiiComponent } from '../templatedocumente/componente-chestionare/chestionar-de-alergii/chestionar-de-alergii.component';
import { ContractFurnizorServiciiSocialeComponent } from '../templatedocumente/componente-contracte/contract-furnizor-servicii-sociale/contract-furnizor-servicii-sociale.component';
import { ContractInchiriereComponent } from '../templatedocumente/componente-contracte/contract-inchiriere/contract-inchiriere.component';
import { ContractMasinaComponent } from '../templatedocumente/componente-contracte/contract-masina/contract-masina.component';
import { EvaluareIndependentaMnfComponent } from '../templatedocumente/componente-evaluari/evaluare-independenta-mnf/evaluare-independenta-mnf.component';
import { EvaluareMariComponent } from '../templatedocumente/componente-evaluari/evaluare-mari/evaluare-mari.component';
import { EvaluareMersComponent } from '../templatedocumente/componente-evaluari/evaluare-mers/evaluare-mers.component';
import { EvaluareaFunctieiMotoriiGrosiereComponent } from '../templatedocumente/componente-evaluari/evaluarea-functiei-motorii-grosiere/evaluarea-functiei-motorii-grosiere.component';
import { EvaluareaReflexelorPrimitiveComponent } from '../templatedocumente/componente-evaluari/evaluarea-reflexelor-primitive/evaluarea-reflexelor-primitive.component';
import { FisaEvaluareInfsParintiComponent } from '../templatedocumente/componente-evaluari/fisa-evaluare-infs-parinti/fisa-evaluare-infs-parinti.component';
import { FisaExaminareLogopedicaComponent } from '../templatedocumente/componente-evaluari/fisa-examinare-logopedica/fisa-examinare-logopedica.component';
import { FisaMonitorizarePpiComponent } from '../templatedocumente/componente-monitorizari-plan-interventie/fisa-monitorizare-ppi/fisa-monitorizare-ppi.component';
import { PlanDeInterventiePersonalizatComponent } from '../templatedocumente/componente-monitorizari-plan-interventie/plan-de-interventie-personalizat/plan-de-interventie-personalizat.component';
import { ServiciiService } from '../services/servicii.service';
import { Servicii } from '../models/nomenclatoare.model';
import { PacientiService } from '../services/pacienti.service';
import { Pacient, DateContact, DatePersonale, Adresa } from '../models/pacientdetails.model';
import { Inject } from '@angular/core';
import { TipDiagnostic, Diagnostic } from '../models/nomenclatoare.model';
import { DiagnosticService } from '../services/diagnostic.service';
import { DiagnosticInterface, ServiciiInterface } from '../interfaces/nomenclatoare.interface';
import {MatTableDataSource} from '@angular/material/table';




@Component({
  selector: 'app-addconsult',
  templateUrl: './addconsult.component.html',
  styleUrls: ['./addconsult.component.css']
})
export class AddconsultComponent implements OnInit {
  @Output() newadaugat = new EventEmitter<boolean>();

  pacient = new Pacient();
  diagnostice: DiagnosticInterface[] = [];
  investigatii: string;
  recomandari: string;
  diagnostic: string;
  tratament: string;
  servicii: ServiciiInterface[];
  serviciuselectat: string;
  PacientList: Pacient[];
  pacientid: number;
  fileid: string
  FisiereConsult = {
    nume: "" ,
    idfisier: ""
  }
  fisiere = []
  displayedColumns: string[] = ['nume', 'descarca', 'edit'];
  dataSource: any

  constructor(
    public diagnosticService: DiagnosticService,
    public pacientiService: PacientiService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public serviciiService: ServiciiService,
    public dialogRef: MatDialogRef<AddconsultComponent>,
    public consultservice: ConsultatiiService,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    if(data){
      this.pacient = data;
      console.log(this.pacient);
    }
  }

  ngOnInit(): void {
    // this.servicii = this.serviciiService.getServicii();
    this.serviciiService.getServiciiFromServer()
    .subscribe((data) =>{
      this.servicii = data
    })
    // console.log("this.servicii: =>",this.servicii)
    this.diagnosticService.getfromserver()
    .subscribe((data) =>{
      this.diagnostice = data
    })
    // console.log("this.servicii: =>",this.servicii)
    // this.PacientList = this.pacientiService.getPacienti();

    // for (let pac of this.PacientList) {
    //    if(pac.id === this.pacientid ){
    //     this.pacient = pac;
    //    }
    // }
    // console.log("pac details =>", this.pacient)
    // this.diagnostice = this.diagnosticService.getDiagnostic();
    this.dataSource  = new MatTableDataSource(this.fisiere);

  }

  sendvalues() {
    this.consultservice.addConsult({dataconsult: new Date(), investigatii: this.investigatii, recomandari: this.recomandari, diagnostic: this.diagnostic, tratament: this.tratament, fisiere : this.fisiere })
    this.dialogRef.close()
  }

  openSnackBar() {
    this._snackBar.open('Error !! Template / Interface mismatch', 'close');
  }
  openEvaluareaFunctieiMotoriiGrosiereComponent() {
    const dialogRef = this.dialog.open(EvaluareaFunctieiMotoriiGrosiereComponent, {
      width: '80%',
      height: '100%',
      data:{
        "caz": 1,
        "beneficiar": this.pacient
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      if (res == 0){
        this.dialogRef.close(true)
      }
    })
  }
  openEvaluareaReflexelorPrimitiveComponent() {
    const dialogRef = this.dialog.open(EvaluareaReflexelorPrimitiveComponent, {
      width: '80%',
      height: '100%',
      data: {
        'caz' : 1,
        'beneficiar': this.pacient
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      if (res == 0){
        this.dialogRef.close(true)
      }
    })
  }

  openFisaEvaluareInfsParintiComponent() {

    const dialogRef = this.dialog.open(FisaEvaluareInfsParintiComponent, {
      width: '80%',
      height: '100%',
      data: {
        'caz' : 1,
        'beneficiar': this.pacient
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      if (res == 0){
        this.dialogRef.close(true)
      }
    })
  }
  // TO DO - TBD
  openFisaExaminareLogopedicaComponent() {
    const dialogRef = this.dialog.open(FisaExaminareLogopedicaComponent, {
      width: '80%',
      height: '100%',
      data: this.pacient
    });
    // this.openSnackBar()
  }
  openEvaluareMersComponent() {
    const dialogRef = this.dialog.open(EvaluareMersComponent, {
      width: '80%',
      height: '100%',
      data: {
        'caz' : 1,
        'beneficiar': this.pacient
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      if (res == 0){
        this.dialogRef.close(true)
      }
    })
    // this.openSnackBar()
  }

  openEvaluareIndependentaMnfComponent() {
    const dialogRef = this.dialog.open(EvaluareIndependentaMnfComponent, {
      width: '80%',
      height: '100%',
      data: {
        'caz' : 1,
        'beneficiar': this.pacient
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      if (res == 0){
        this.dialogRef.close(true)
      }
    })
  }
  openEvaluareMariComponent() {
    const dialogRef = this.dialog.open(EvaluareMariComponent, {
      width: '80%',
      height: '100%',
      data: {
        'caz' : 1,
        'beneficiar': this.pacient
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      if (res == 0){
        this.dialogRef.close(true)
      }
    })
  }
  openChestionarDeAlergiiComponent() {
    const dialogRef = this.dialog.open(ChestionarDeAlergiiComponent, {
      width: '80%',
      height: '100%',
      data: {
        'caz' : 1,
        'beneficiar': this.pacient
      }
    });
    dialogRef.afterClosed().subscribe( async res => {
      if (res == 0){
        this.dialogRef.close(true)
      }
    })
  }
  openContractFurnizorServiciiSocialeComponent() {
    const dialogRef = this.dialog.open(ContractFurnizorServiciiSocialeComponent, {
      width: '80%',
      height: '100%',
      data: this.pacient
    });
    // this.openSnackBar()
  }
  openContractMasinaComponent() {
    const dialogRef = this.dialog.open(ContractMasinaComponent, {
      width: '80%',
      height: '100%',
      data: this.pacient
    });
    // this.openSnackBar()
  }
  openContractInchiriereComponent() {
    const dialogRef = this.dialog.open(ContractInchiriereComponent, {
      width: '80%',
      height: '100%',
      data: this.pacient
    });
    // this.openSnackBar()
  }

}
