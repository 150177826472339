<div style="text-align: center;" class="fixActionRow">
  <h1 mat-dialog-title>Date Beneficiar</h1>
  <div mat-dialog-content>



    <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="selectedTab">

      <!--  Pacient -- Date Personale -->

      <mat-tab label="Date Personale">

        <br>

        <mat-form-field style="min-width:250px">
          <mat-label>Nume</mat-label>
          <input matInput type="text" [(ngModel)]="date_personale.nume" required>
        </mat-form-field>

        <mat-form-field style="min-width:250px">
          <mat-label>Prenume</mat-label>
          <input matInput type="text" [(ngModel)]="date_personale.prenume" required>
        </mat-form-field>

        <mat-form-field style="min-width:200px">
          <mat-label>CNP</mat-label>
          <input matInput type="number" [(ngModel)]="date_personale.cnp">
        </mat-form-field>

        <mat-form-field style="max-width: 100px;">
          <mat-label>Sex</mat-label>
          <mat-select [(ngModel)]="date_personale.sex">
            <mat-option value="masculin">Masculin</mat-option>
            <mat-option value="feminin">Feminin</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field style="max-width: 100px;">
          <mat-label>Greutate</mat-label>
          <input matInput type="text" [(ngModel)]="pacient.greutate">
          <span matSuffix>kg</span>
        </mat-form-field>

        <mat-form-field style="max-width: 100px;">
          <mat-label>Inaltime</mat-label>
          <input matInput type="text" [(ngModel)]="pacient.inaltime">
          <span matSuffix>cm</span>
        </mat-form-field>

        <mat-form-field style="max-width: 140px;">
          <mat-label>Data Nasterii</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="date_personale.data_nasterii">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Locul Nasterii</mat-label>
          <input matInput type="text" [(ngModel)]="date_personale.locul_nasterii">
        </mat-form-field>

        <!-- <mat-form-field>
            <mat-label>Carte de identitate</mat-label>
            <input matInput type="text" [(ngModel)]= "date_personale.carte_identitate">
          </mat-form-field> -->

        <!--
            <mat-form-field>
              <mat-checkbox [(ngModel)]="carte_identitate_check">Carte de Identitate</mat-checkbox>
            </mat-form-field> -->
        <div class="flex-align-center"  >

          <div style="display: flex;" class="slider-first-padding-font-set">
            <!-- <span class="orardesomn"> Carte de Identitate: </span> -->
            <!-- <mat-checkbox class="orardesomn" [(ngModel)]="pacient.carte_identitate_check"> </mat-checkbox> -->

            <div style="display: flex;">
              Carte de identitate
            </div>
            <div class="separator-flex-align-padding">
              :
            </div>
            <div class="flex-align-center">
              <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectata': !getStatusCI()  }">
                NU
              </div>
              <div class="flex-align-center wrapper-selector " >
                <mat-slide-toggle
                  [(ngModel)]="pacient.carte_identitate_check"
                  (change)="changed()"
                  class="slider-padding-left-set"
                  [ngClass]="{'optiune-selectata-selector': getStatusCI()  }"
                  >
                  DA
                </mat-slide-toggle>
              </div>
            </div>

          </div>

          <div style="display: flex;" class="slider-padding-font-set">
            <div class="flex-align-center">
              Grad de dependenta
            </div>
            <div class="separator-flex-align-padding">
              :
            </div>
            <div class="flex-align-center ">
              <div class="flex-align-center" [ngClass]="{'optiune-selectata': !getStatusIndependenta()  }">
                DEPENDENT
              </div>
              <div class="flex-align-center wrapper-selector ">
                <mat-slide-toggle
                  [(ngModel)]="pacient.independent"
                  (change)="changed_dependenta()"
                  class="slider-padding-left-set"
                  [ngClass]="{'optiune-selectata-selector': getStatusIndependenta()  }"
                  >
                  INDEPENDENT
                </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-align-center grid-ish" *ngIf="pacient.carte_identitate_check" >
          <div class="row-ish-ci">
            <div class="row-ci"  >
              <mat-form-field style="display: flex;" >
                <mat-label>Serie CI</mat-label>
                <input matInput type="text" [(ngModel)]="date_personale.serie_carte_identitate">
              </mat-form-field>

              <mat-form-field style="display: flex;align-items: center;" >
                <mat-label>Numar CI</mat-label>
                <input matInput type="text" [(ngModel)]="date_personale.numar_carte_identitate">
              </mat-form-field>
            </div>
          </div>
        </div>

          <!-- <div style="display: flex;align-items: center;">
            <span class="orardesomn"> Grad de dependenta: </span>
            <mat-checkbox class="orardesomn" [(ngModel)]="pacient.independent" *ngIf="pacient.independent"> Independent
            </mat-checkbox>
            <mat-checkbox class="orardesomn" [(ngModel)]="pacient.independent" *ngIf="!pacient.independent"> Dependent
            </mat-checkbox>
          </div> -->

        <div class="flex-align-center grid-ish" >
          <div class="row-ish">
            <div class="row" >
              <div class="slider-first-padding-font-set">
                <div class="flex-align-center">
                  Orar indisponibilitate
                </div>
                <div class="separator-flex-align-padding">
                  :
                </div>
                <div class="flex-align-center">
                  <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectata': !getStatusOrarIndisponibilitate()  }">
                    NU
                  </div>
                  <div class="flex-align-center wrapper-selector " >
                    <mat-slide-toggle
                      [(ngModel)]="pacient.orar_de_somn"
                      (change)="changed_OrarIndisponibilitate()"
                      class="slider-padding-left-set"
                      [ngClass]="{'optiune-selectata-selector': getStatusOrarIndisponibilitate()  }"
                      >
                      DA
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div style="display: flex; width:50%; justify-content: center;">
                <button mat-raised-button class="save-button" *ngIf="flag_adauga_primul_interval"
                (click)="adaugaIntervaleIndisponibilitate()"> Adauga interval </button>
                <button mat-raised-button class="save-button" *ngIf="flag_adauga_alt_interval"
                (click)="adaugaIntervalNou()"> Adauga interval nou </button>
                <button mat-raised-button class="save-button" *ngIf="flag_salveaza_interval_nou"
                (click)="salveazaIntervalNou()"> Salveaza intervalul nou </button>
                <button mat-raised-button class="save-button" *ngIf="flag_interval_in_editare"
                (click)="salveazaIntervalulEditat()"> Salveaza intervalul editat </button>
                <button mat-raised-button color="warn" (click)="anuleazaAddIntervaleIndisponibilitate()" *ngIf="pacient.orar_de_somn && flag_show_detalii_interval"> Anuleaza</button>
              </div>

            </div>
            <div class="row" *ngIf="flag_show_detalii_interval">
              <div class= " flex-align-center panel-add-interval-indisponibil">
                <div class="flex-align-center" >
                  <div class="column">
                    <span class="orardesomn" *ngIf="pacient.orar_de_somn"> De la: </span>
                  </div>
                  <ngx-mat-timepicker *ngIf="pacient.orar_de_somn" [(ngModel)]="interval_start"
                    [disabled]="!pacient.orar_de_somn" [showSpinners]="true">
                  </ngx-mat-timepicker>
                </div>

                <div class="flex-align-center">
                  <div class="column">
                    <span class="orardesomn" *ngIf="pacient.orar_de_somn"> Pana la: </span>
                  </div>
                  <ngx-mat-timepicker *ngIf="pacient.orar_de_somn" [(ngModel)]="interval_end"
                    [disabled]="!pacient.orar_de_somn" [showSpinners]="true">
                  </ngx-mat-timepicker>
                </div>

                <div class="flex-align-center">
                  <button mat-raised-button color="primary" *ngIf="pacient.orar_de_somn && !flag_camp_detalii_activ "
                    (click)="adaugaDetaliiIntervalIndisponibilitate()"> Adauga detalii </button>
                </div>

                <mat-form-field appearance="fill" *ngIf="flag_camp_detalii_activ" style="max-width: 450px; font-size: 15px;">
                  <mat-label *ngIf="!flag_interval_in_editare">Adauga detalii</mat-label>
                  <textarea [(ngModel)]="interval_indisponibilitate_nou.detalii" *ngIf="!flag_interval_in_editare" matInput placeholder="Detalii"></textarea>
                  <textarea [(ngModel)]="intermediar_interval_edit_detalii" *ngIf="flag_interval_in_editare" matInput ></textarea>
                </mat-form-field>
              </div>



            </div>
            <div class = "row">
              <table class="mat-elevation-z8" mat-table [dataSource]="intervale_indisponibilitate_dataSource"
                 *ngIf="flag_sunt_orare_indisponibilitate">
                <!-- Ora start Column -->
                <ng-container matColumnDef="ora_start">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Ora start </th>
                  <td mat-cell *matCellDef="let element"> {{element.ora_start}} </td>
                </ng-container>

                <!-- ORa end Column -->
                <ng-container matColumnDef="ora_end">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Ora end </th>
                  <td mat-cell *matCellDef="let element"> {{element.ora_end}} </td>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="detalii">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Detalii </th>
                  <td mat-cell *matCellDef="let element"> {{element.detalii ? element.detalii : ' - ' }} </td>
                </ng-container>

                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef>
                    <!-- <button  mat-raised-button color="primary" (click)="sendvalues()" [disabled]="!date_personale.nume || !date_personale.prenume" mat-dialog-close>Ok</button> -->
                  </th>
                  <td mat-cell *matCellDef="let element">

                    <button mat-raised-button class="save-button" [disabled]="flag_show_detalii_interval"
                      (click)="editeazaIntervalIndisponibilitate(element)">editeaza</button>

                  </td>
                </ng-container>

                <ng-container matColumnDef="deleteselect">
                  <th mat-header-cell *matHeaderCellDef>
                    <button mat-raised-button color="warn" [disabled]="todelete.length == 0" (click)="deleteitems()"> Sterge
                    </button>
                  </th>
                  <td mat-cell *matCellDef="let element">

                    <mat-checkbox (change)="marktodelete(element)" [checked]="returnStatusMarcat(element)"></mat-checkbox>

                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnIntervaleIndisponibilitate"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnIntervaleIndisponibilitate;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">Nu am gasit nimic</td>
                </tr>
              </table>
              <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>

      </mat-tab>

      <!-- END Pacient -- Date Personale -->

      <!-- Pacient -- Date de Contact -->

      <mat-tab label="Date de Contact">
        <div class="row-date-contact">
          <div class=" flex-align-center" [ngClass]="{'panel-add-telefon-email' : !flag_telefon_email_saved, 'panel-add-telefon-email-green' : flag_telefon_email_saved}">

            <div class="flex-align-center"
            [matTooltipDisabled]="!flag_telefon_email_saved"
            matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
            >
              <mat-form-field>
                <mat-label>Telefon</mat-label>
                <input matInput type="text" [maxLength]="10" [formControl]="telefon_beneficiar" [disabled]= "flag_telefon_email_saved"
                [ngClass]="{'input-telefon-email-valid' : (telefon_beneficiar.valid && (telefon_beneficiar.value != '')) }"
                >
                <!-- <input matInput type="number" [(ngModel)]="date_contact.telefon" [disabled]= flag_telefon_email_saved> -->
                <mat-hint align="end"> {{ telefon_beneficiar.value != ''  ? returnCaractereTelefon(telefon_beneficiar.value) : 0  }}/10</mat-hint>
                <mat-error class="eroare-email-invalid" *ngIf="telefon_beneficiar.invalid">Numarul de telefon nu este valid </mat-error>

              </mat-form-field>
            </div>

            <div class="flex-align-center"
              [matTooltipDisabled]="!flag_telefon_email_saved"
              matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
              >

              <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="email" [formControl]="email_beneficiar" [disabled]= "flag_telefon_email_saved"
                [ngClass]="{'input-telefon-email-valid' : (email_beneficiar.valid && (email_beneficiar.value != '')) }"
                 >
                <mat-error class="eroare-email-invalid" *ngIf="email_beneficiar.invalid">Adresa de email nu este valida. Introduceti un email de forma examplu @ domeniu . com </mat-error>
              </mat-form-field>
            </div>
            <div class="flex-align-center" *ngIf="!flag_telefon_email_saved" [matTooltipDisabled]="!((date_contact.telefon === undefined) && (date_contact.email === undefined))" matTooltip="Introdu un numar de telefon sau o adresa de email pentru a putea salva">
              <button mat-raised-button class="save-button" (click)="saveTelefonEmailBeneficiar()" [disabled]="!((email_beneficiar.valid && (email_beneficiar.value != '')) || (telefon_beneficiar.valid && (telefon_beneficiar.value != '')))">
                Salveaza
              </button>
              <!-- <br>
              telefon valid ==>> {{telefon_beneficiar.valid}} <br>
              telefon value ==>> {{telefon_beneficiar.value != ''}} <br>
              email valid ==>> {{email_beneficiar.valid}} <br>
              email value ==>> {{email_beneficiar.value != ''}} <br>
              conditie mail valid si value ==>>  {{email_beneficiar.valid && (email_beneficiar.value != '') }}  <br>
              conditie telefon valid si value ==>> {{ telefon_beneficiar.valid && (telefon_beneficiar.value != '') }} <br> -->
            </div>
            <div class="flex-align-center" *ngIf="flag_telefon_email_saved">
              <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaTelefonEmailBeneficiar()">
                Editeaza
              </button>
            </div>
          </div>
        </div>
        <br>


        <div class="flex-align-center" style="justify-content: center;">
          <div *ngIf="!flag_adresa_beneficiar_add">
            <button mat-raised-button class="save-button" (click)="adaugaAdresaBeneficiar()" [disabled]="flag_beneficiar_fara_adresa">
              + Adauga adresa
            </button>
            <button mat-raised-button (click)="beneficiarFaraAdresa()" [ngClass]="{'save-button': flag_beneficiar_fara_adresa, 'edit-telefon-email-button': !flag_beneficiar_fara_adresa }">
              Fara adresa
            </button>
          </div>
        </div>

        <div class="row-date-contact" *ngIf="flag_adresa_beneficiar_add">
          <div class=" flex-align-center " [ngClass]="{'panel-add-adresa-green': flag_adresa_beneficiar_set, 'panel-add-adresa': !flag_adresa_beneficiar_set }">
            <div style="width: 100%;">

            </div>
              <mat-form-field style="max-width: 30%;">
                <mat-label>Judet</mat-label>
                <input matInput type="text" [(ngModel)]="adresa.judet" required [disabled]="flag_adresa_beneficiar_set">
              </mat-form-field>

            <mat-form-field style="max-width: 30%;">
              <mat-label>Localitate</mat-label>
              <input matInput type="text" [(ngModel)]="adresa.localitate" required [disabled]="flag_adresa_beneficiar_set">
            </mat-form-field>

            <mat-form-field style="max-width: 30%;">
              <mat-label>Strada</mat-label>
              <input matInput type="text" [(ngModel)]="adresa.strada" required [disabled]="flag_adresa_beneficiar_set">
            </mat-form-field>

            <mat-form-field style="max-width: 80px;">
              <mat-label>Numar</mat-label>
              <input matInput type="text" [(ngModel)]="adresa.numar" [disabled]="flag_adresa_beneficiar_set">
            </mat-form-field>

            <mat-form-field style="max-width: 100px;">
              <mat-label>Bloc</mat-label>
              <input matInput type="text" [(ngModel)]="adresa.bloc" [disabled]="flag_adresa_beneficiar_set">
            </mat-form-field>

            <mat-form-field style="max-width: 80px;">
              <mat-label>Etaj</mat-label>
              <input matInput type="text" [(ngModel)]="adresa.etaj" [disabled]="flag_adresa_beneficiar_set">
            </mat-form-field>

            <mat-form-field style="max-width: 120px;">
              <mat-label>Apartament</mat-label>
              <input matInput type="text" [(ngModel)]="adresa.ap" [disabled]="flag_adresa_beneficiar_set">
            </mat-form-field>

          </div>
          <button mat-raised-button class="save-button" (click)="salveazaAdresaBeneficiar()" *ngIf="!flag_adresa_beneficiar_set"> Salveaza Adresa</button>
          <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaAdresaBeneficiar()" *ngIf="flag_adresa_beneficiar_set"> Editeaza Adresa</button>
          <button mat-raised-button color="warn" *ngIf="!flag_adresa_beneficiar_set" (click)="anuleazaAdresaBeneficiar()"> Anuleaza</button>
          <button mat-raised-button color="warn" *ngIf="flag_adresa_beneficiar_set" (click)="stergeAdresaBeneficiar()"> Sterge Adresa</button>
        </div>

      </mat-tab>

      <!-- END Pacient -- Date de Contact -->

      <!-- Reprezentant Legal -->

      <mat-tab label="Reprezentant Legal">

        <!-- Reprezentant Legal Non User  -->

          <!-- Tabel reprezentant legal (ideal mama & tata pentru date in documente) ==>> apare doar cand este cel putin un reprezentant legal introdus -->

          <table *ngIf="lista.length > 0" mat-table [dataSource]="dataSourcetwo" style="width:95%">

            <!-- Nume Column -->
            <ng-container matColumnDef="nume">
              <th mat-header-cell *matHeaderCellDef> Nume </th>
              <td mat-cell *matCellDef="let element"> {{element.datepersonale.nume?element.datepersonale.nume:"-"}}
              </td>
            </ng-container>

            <!-- Prenume Column -->
            <ng-container matColumnDef="prenume">
              <th mat-header-cell *matHeaderCellDef> Prenume </th>
              <td mat-cell *matCellDef="let element">
                {{element.datepersonale.prenume?element.datepersonale.prenume:"-"}} </td>
            </ng-container>

            <!-- Calitate Column -->
            <ng-container matColumnDef="calitate">
              <th mat-header-cell *matHeaderCellDef> Calitate </th> 
              <td mat-cell *matCellDef="let element"> {{getCalitateKey(element.calitateid)}} </td>
            </ng-container>

            <!-- Telefon Column -->
            <ng-container matColumnDef="telefon">
              <th mat-header-cell *matHeaderCellDef> Telefon </th>
              <td mat-cell *matCellDef="let element"> {{element.datecontact.telefon?element.datecontact.telefon:"-"}}
              </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.datecontact.email?element.datecontact.email:"-"}}
              </td>
            </ng-container>

            <!-- Edit Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef>  </th>
              <td mat-cell *matCellDef="let element"> 
                <button mat-raised-button class="save-button" [disabled]="flag_RL_in_editare"
                (click)="editeazaReprezentantLegal(element)">editeaza</button>
              </td>
            </ng-container>

            <!-- Delete Column -->

            <ng-container matColumnDef="deleteselect">
              <th mat-header-cell *matHeaderCellDef>
                <button  mat-raised-button color="warn" [disabled]="todeleteRL.length == 0" (click)="deleteRL()" > Sterge </button>
              </th>
              <td mat-cell *matCellDef="let element">
          
                <mat-checkbox (change)="marktodeleteRL(element)" value="checkRLinListaDeSters(element)"></mat-checkbox>
          
              </td>
            </ng-container>            

            <tr mat-header-row *matHeaderRowDef="displayedColumnstwo"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnstwo;"></tr>

          </table>

          <!-- End Tabel  -->

          <br>
          <ng-container *ngIf='lista.length < 1 || addnewperson == 0'>
            <mat-tab-group mat-align-tabs="center" animationDuration="500ms" [(selectedIndex)]="selectedTabRL">

              <!-- Reprezentant Legal Non User -- Date personale -->

              <mat-tab label="Date Personale">

                <mat-form-field style="min-width:250px" >
                  <mat-label>Nume</mat-label>
                  <input matInput type="text" 
                    [(ngModel)]="reprezentant_legal_nonuser_date_personale.nume">
                </mat-form-field>

                <mat-form-field style="min-width:250px" >
                  <mat-label>Prenume</mat-label>
                  <input matInput type="text" [(ngModel)]="reprezentant_legal_nonuser_date_personale.prenume">
                </mat-form-field>

                <mat-form-field style="min-width:200px">
                  <mat-label>CNP</mat-label>
                  <input matInput type="number" [(ngModel)]="reprezentant_legal_nonuser_date_personale.cnp">
                </mat-form-field>

                <div class="flex-align-center"  >

                  <mat-form-field style="max-width: 100px;">
                    <mat-label>Sex</mat-label>
                    <mat-select [(ngModel)]="reprezentant_legal_nonuser_date_personale.sex">
                      <mat-option value="masculin">Masculin</mat-option>
                      <mat-option value="feminin">Feminin</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field style="max-width: 140px;" >
                    <mat-label>Data Nasterii</mat-label>
                    <input matInput [matDatepicker]="picker2"
                      [(ngModel)]="reprezentant_legal_nonuser_date_personale.data_nasterii">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>


                  <mat-form-field>
                    <mat-label>Calitatea</mat-label>
                    <mat-select [(ngModel)]="pacient.reprezentant_legal_calitateid">
                      <mat-option *ngFor="let calitatea of calitate" [value]="calitatea.id">
                        {{calitatea.calitate}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div style="display: flex;" class="slider-first-padding-font-set">
                    <!-- <span class="orardesomn"> Carte de Identitate: </span> -->
                    <!-- <mat-checkbox class="orardesomn" [(ngModel)]="pacient.carte_identitate_check"> </mat-checkbox> -->

                    <div style="display: flex;">
                      Carte de identitate
                    </div>
                    <div class="separator-flex-align-padding">
                      :
                    </div>
                    <div class="flex-align-center">
                      <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectata': !getStatusCIReprezentantLegal()  }">
                        NU
                      </div>
                      <div class="flex-align-center wrapper-selector " >
                        <mat-slide-toggle
                          [(ngModel)]="flag_reprezentant_legal_ci"
                          (change)="changedCIReprezentantLegal()"
                          class="slider-padding-left-set"
                          [ngClass]="{'optiune-selectata-selector': getStatusCIReprezentantLegal()  }"
                          >
                          DA
                        </mat-slide-toggle>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="flex-align-center grid-ish" *ngIf="flag_reprezentant_legal_ci" >
                  <div class="row-ish-ci">
                    <div class="row-ci"  >
                      <mat-form-field style="display: flex;" >
                        <mat-label>Serie CI</mat-label>
                        <input matInput type="text" [(ngModel)]="reprezentant_legal_nonuser_date_personale.serie_carte_identitate">
                      </mat-form-field>

                      <mat-form-field style="display: flex;align-items: center;" >
                        <mat-label>Numar CI</mat-label>
                        <input matInput type="text" [(ngModel)]="reprezentant_legal_nonuser_date_personale.numar_carte_identitate">
                      </mat-form-field>
                    </div>
                  </div>
                </div>



              </mat-tab>

              <!-- END Reprezentant Legal Non User -- Date personale -->

              <!-- Reprezentant Legal Non User -- Date de Contact -->

              <mat-tab label="Date de Contact">

                <div class="row-date-contact">
                  <div class=" flex-align-center" [ngClass]="{'panel-add-telefon-email' : !flag_telefon_email_RL_saved, 'panel-add-telefon-email-green' : flag_telefon_email_RL_saved}">

                    <div class="flex-align-center"
                    [matTooltipDisabled]="!flag_telefon_email_RL_saved"
                    matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
                    >
                      <mat-form-field>
                        <mat-label>Telefon</mat-label>
                        <input matInput type="text" [maxLength]="10" [formControl]="telefon_RL" [disabled]= "flag_telefon_email_RL_saved"
                        [ngClass]="{'input-telefon-email-valid' : (telefon_RL.valid && (telefon_RL.value != '')) }"
                        >
                        <!-- <input matInput type="number" [(ngModel)]="date_contact.telefon" [disabled]= flag_telefon_email_saved> -->
                        <mat-hint align="end"> {{ telefon_RL.value != ''  ? returnCaractereTelefon(telefon_RL.value) : 0  }}/10</mat-hint>
                        <mat-error class="eroare-email-invalid" *ngIf="telefon_RL.invalid">Numarul de telefon nu este valid </mat-error>

                      </mat-form-field>
                    </div>

                    <div class="flex-align-center"
                      [matTooltipDisabled]="!flag_telefon_email_RL_saved"
                      matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
                      >

                      <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput type="email" [formControl]="email_RL" [disabled]= "flag_telefon_email_RL_saved"
                        [ngClass]="{'input-telefon-email-valid' : (email_RL.valid && (email_RL.value != '')) }"
                         >
                        <mat-error class="eroare-email-invalid" *ngIf="email_RL.invalid">Adresa de email nu este valida. Introduceti un email de forma examplu @ domeniu . com </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="flex-align-center" *ngIf="!flag_telefon_email_RL_saved && !this.flag_RL_in_editare" [matTooltipDisabled]="!((reprezentant_legal_nonuser_date_contact.telefon === undefined) && (reprezentant_legal_nonuser_date_contact.email === undefined))" matTooltip="Introdu un numar de telefon sau o adresa de email pentru a putea salva">
                      <button mat-raised-button class="save-button" (click)="saveTelefonEmailRL()" [disabled]="!((email_RL.valid && (email_RL.value != '')) || (telefon_RL.valid && (telefon_RL.value != '')))">
                        Salveaza
                      </button>
                    </div>
                    <div class="flex-align-center" *ngIf="!flag_telefon_email_RL_saved && this.flag_RL_in_editare" [matTooltipDisabled]="!((reprezentant_legal_nonuser_date_contact.telefon === undefined) && (reprezentant_legal_nonuser_date_contact.email === undefined))" matTooltip="Introdu un numar de telefon sau o adresa de email pentru a putea salva">
                      <button mat-raised-button class="save-button" (click)="saveTelefonEmailRLEditat()" [disabled]="!((email_RL.valid && (email_RL.value != '')) || (telefon_RL.valid && (telefon_RL.value != '')))">
                        Salveaza modificarile
                      </button>
                    </div>
                    <div class="flex-align-center" *ngIf="flag_telefon_email_RL_saved">
                      <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaTelefonEmailRL()">
                        Editeaza
                      </button>
                    </div>

                  </div>
                  </div>
                  <div class="flex-align-center" style="justify-content: center;">
                    <div *ngIf="!flag_adresa_RL_add">
                      <button mat-raised-button class="save-button" (click)="copiazaAdresaBeneficiar()" [disabled]="flag_RL_fara_adresa" *ngIf="flag_adresa_beneficiar_set">
                        + Foloseste adresa beneficiar
                      </button>
                      <button mat-raised-button class="save-button" (click)="adaugaAdresaRL()" [disabled]="flag_RL_fara_adresa">
                        + Adauga adresa
                      </button>
                      <button mat-raised-button (click)="RLFaraAdresa()" [ngClass]="{'save-button': flag_RL_fara_adresa, 'edit-telefon-email-button': !flag_RL_fara_adresa }">
                        Fara adresa
                      </button>
                    </div>
                  </div>

                  <div class="row-date-contact" style="margin-top: 20px;" *ngIf="flag_adresa_RL_add">
                    <div class=" flex-align-center " [ngClass]="{'panel-add-adresa-green': flag_adresa_RL_set, 'panel-add-adresa': !flag_adresa_RL_set }">

                        <mat-form-field style="max-width: 30%;">
                          <mat-label>Judet</mat-label>
                          <input matInput type="text" [(ngModel)]="adresaRL.judet" required [disabled]="flag_adresa_RL_set">
                        </mat-form-field>

                      <mat-form-field style="max-width: 30%;">
                        <mat-label>Localitate</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaRL.localitate" required [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                      <mat-form-field style="max-width: 30%;">
                        <mat-label>Strada</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaRL.strada" required [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                      <mat-form-field style="max-width: 80px;">
                        <mat-label>Numar</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaRL.numar" [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                      <mat-form-field style="max-width: 100px;">
                        <mat-label>Bloc</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaRL.bloc" [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                      <mat-form-field style="max-width: 80px;">
                        <mat-label>Etaj</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaRL.etaj" [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                      <mat-form-field style="max-width: 120px;">
                        <mat-label>Apartament</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaRL.ap" [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                    </div>
                    <button mat-raised-button class="save-button" (click)="salveazaAdresaRL()" *ngIf="!flag_adresa_RL_set"> Salveaza Adresa</button>
                    <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaAdresaRL ()" *ngIf="flag_adresa_RL_set"> Editeaza Adresa</button>
                    <button mat-raised-button color="warn" *ngIf="!flag_adresa_RL_set" (click)="anuleazaAdresaRL()"> Anuleaza</button>
                    <button mat-raised-button color="warn" *ngIf="flag_adresa_RL_set" (click)="stergeAdresaRL()"> Sterge Adresa</button>
                  </div>

                <!-- </div> -->

              </mat-tab>

              <!-- END Reprezentant Legal Non User -- Date de Contact -->

            </mat-tab-group>
          </ng-container>
          <ng-container *ngIf='lista.length > 0 && addnewperson == 1'>
            <button mat-raised-button color="primary" (click)="reInitRL()">Adauga RL nou</button>
          </ng-container>
 
          <button mat-raised-button color="primary" (click)="setReprezentantiLegalList()" *ngIf="selectedTabRL == 1 && addnewperson == 0 && !flag_RL_in_editare ">Adauga RL</button>
          <button mat-raised-button color="warn" (click)="anuleazaRLEditat()" *ngIf="addnewperson == 0 && flag_RL_in_editare ">Anuleaza modificarile</button>
          <button mat-raised-button color="primary" (click)="salveazaRLEditat()" *ngIf="addnewperson == 0 && flag_RL_in_editare ">Salveaza Modificarile</button>
          <button mat-raised-button color="primary" (click)="goToNextTabRL()" *ngIf="selectedTabRL < 1 && addnewperson == 0">Adauga Date de contact</button>

          <br>
          <br>
          RL = Reprezentant Legal
          <br>

        <!-- END Reprezentant Legal Non User  -->

      </mat-tab>

      <!-- END Reprezentant Legal -->

      <!-- Persoana de contact -->

      <mat-tab label="Persoana de contact">

        <br>

        <!-- Persoana de contact aceeasi cu reprezentantul legal -->
        <div class="row">
          <mat-slide-toggle [checked]="pacient.reprezentant_legal_persoana_de_contact"
            [(ngModel)]="pacient.reprezentant_legal_persoana_de_contact">Alege ca persoana de contact reprezentantul legal
          </mat-slide-toggle>
        </div>

        <!-- END Persoana de contact aceeasi cu reprezentantul legal -->

        <br>

        <!-- Persoana de contact DIFERITA DE reprezentantul legal -->

        <ng-container *ngIf='!pacient.reprezentant_legal_persoana_de_contact'>

          <!-- Persoana de contact user -->

          <!-- <mat-slide-toggle [checked]="pacient.persoana_contact_user" [(ngModel)]="pacient.persoana_contact_user">Alege un utilizator existent</mat-slide-toggle> -->

          <br>

          <!-- Persoana de contact non user -->

          <ng-container *ngIf='!pacient.persoana_contact_user'>


            <!-- Persoana de contact non user -- Date personale -->

            <!-- Tabel reprezentant legal (ideal mama & tata pentru date in documente) ==>> apare doar cand este cel putin un reprezentant legal introdus -->

            <table *ngIf="listacontact.length > 0" mat-table [dataSource]="dataSourcethree" style="width:100%">

              <!-- Nume Column -->
              <ng-container matColumnDef="nume">
                <th mat-header-cell *matHeaderCellDef> Nume </th>
                <td mat-cell *matCellDef="let element"> {{element.datepersonale.nume?element.datepersonale.nume:"-"}}
                </td>
              </ng-container>

              <!-- Prenume Column -->
              <ng-container matColumnDef="prenume">
                <th mat-header-cell *matHeaderCellDef> Prenume </th>
                <td mat-cell *matCellDef="let element">
                  {{element.datepersonale.prenume?element.datepersonale.prenume:"-"}} </td>
              </ng-container>

              <!-- Calitate Column -->
              <ng-container matColumnDef="calitate">
                <th mat-header-cell *matHeaderCellDef> Calitate</th>getCalitateKey
                <td mat-cell *matCellDef="let element"> {{getCalitateKey(element.calitateid)}} </td>
              </ng-container>

              <!-- Telefon Column -->
              <ng-container matColumnDef="telefon">
                <th mat-header-cell *matHeaderCellDef> Telefon</th>
                <td mat-cell *matCellDef="let element"> {{element.datecontact.telefon?element.datecontact.telefon:"-"}}
                </td>
              </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.datecontact.email?element.datecontact.email:"-"}}
              </td>
            </ng-container>

            <!-- Edit Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef>  </th>
              <td mat-cell *matCellDef="let element"> 
                <button mat-raised-button class="save-button" [disabled]="flag_PC_in_editare"
                (click)="editeazaPersoanaContact(element)">editeaza</button>
              </td>
            </ng-container>

            <!-- Delete Column -->

            <ng-container matColumnDef="deleteselect">
              <th mat-header-cell *matHeaderCellDef>
                <button  mat-raised-button color="warn" [disabled]="todeletePC.length == 0" (click)="deletePC()" > Sterge </button>
              </th>
              <td mat-cell *matCellDef="let element">
          
                <mat-checkbox (change)="marktodeletePC(element)" value="checkPCinListaDeSters(element)"></mat-checkbox>
          
              </td>
            </ng-container>  

              <tr mat-header-row *matHeaderRowDef="displayedColumnsthree"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsthree;"></tr>

            </table>

            <!-- End Tabel  -->

            <ng-container *ngIf='listacontact.length < 1 || addnewcontactperson == 0'>
              <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="selectedTabPC">
                <mat-tab label="Date Personale">

                  <mat-form-field style="min-width:250px">
                    <mat-label>Nume</mat-label>
                    <input matInput type="text" [(ngModel)]="persoana_contact_nonuser_date_personale.nume" required>
                  </mat-form-field>

                  <mat-form-field style="min-width:250px">
                    <mat-label>Prenume</mat-label>
                    <input matInput type="text" [(ngModel)]="persoana_contact_nonuser_date_personale.prenume" required>
                  </mat-form-field>

                  <mat-form-field style="min-width:250px">
                    <mat-label>CNP</mat-label>
                    <input matInput type="number" [(ngModel)]="persoana_contact_nonuser_date_personale.cnp">
                  </mat-form-field>

                  <div class="flex-align-center"  >

                    <mat-form-field style="max-width: 100px;">
                      <mat-label>Sex</mat-label>
                      <mat-select [(ngModel)]="persoana_contact_nonuser_date_personale.sex">
                        <mat-option value="masculin">Masculin</mat-option>
                        <mat-option value="feminin">Feminin</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field style="max-width: 140px;" >
                      <mat-label>Data Nasterii</mat-label>
                      <input matInput [matDatepicker]="picker2"
                        [(ngModel)]="persoana_contact_nonuser_date_personale.data_nasterii">
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                    
                    <!-- 
                    <mat-form-field>
                      <mat-label>Locul Nasterii</mat-label>
                      <input matInput type="text" [(ngModel)]="persoana_contact_nonuser_date_personale.locul_nasterii">
                    </mat-form-field> -->
                    
                    <mat-form-field>
                      <mat-label>Calitatea</mat-label>
                      <mat-select [(ngModel)]="pacient.persoana_contact_calitateid">
                        <mat-option *ngFor="let calitatea of calitate" [value]="calitatea.id">
                          {{calitatea.calitate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <div style="display: flex;" class="slider-first-padding-font-set">
                      <div style="display: flex;">
                        Carte de identitate
                      </div>
                      <div class="separator-flex-align-padding">
                        :
                      </div>

                      <div class="flex-align-center">
                        <!-- de modeificat -->

                        <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectata': !getStatusCIPersoanaContact()  }">
                          NU
                        </div>
                        <div class="flex-align-center wrapper-selector " > 
                          <mat-slide-toggle
                            [(ngModel)]="flag_persoana_contact_ci"
                            (change)="changedCIPersoanaContact()"
                            class="slider-padding-left-set"
                            [ngClass]="{'optiune-selectata-selector': getStatusCIPersoanaContact()  }"
                            >
                            DA
                          </mat-slide-toggle>
                        </div>

                      </div>

                    </div>
                  </div>
                  
                <div class="flex-align-center grid-ish" *ngIf="flag_persoana_contact_ci" >
                  <div class="row-ish-ci">
                    <div class="row-ci"  >
                      <mat-form-field style="display: flex;" >
                        <mat-label>Serie CI</mat-label>
                        <input matInput type="text" [(ngModel)]="persoana_contact_nonuser_date_personale.serie_carte_identitate">
                      </mat-form-field>

                      <mat-form-field style="display: flex;align-items: center;" >
                        <mat-label>Numar CI</mat-label>
                        <input matInput type="text" [(ngModel)]="persoana_contact_nonuser_date_personale.numar_carte_identitate">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                </mat-tab>

                <!-- END Persoana de contact non user -- Date personale -->

                <br>

                <!-- Persoana de contact non user -- Date de contact-->

                <mat-tab label="Date de Contact">

                  <div class="row-date-contact">
                    <div class=" flex-align-center" [ngClass]="{'panel-add-telefon-email' : !flag_telefon_email_PC_saved, 'panel-add-telefon-email-green' : flag_telefon_email_PC_saved}">
                      <div class="flex-align-center" 
                      [matTooltipDisabled]="!flag_telefon_email_PC_saved" 
                      matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
                      >
                      
                        <mat-form-field>
                          <mat-label>Telefon</mat-label>
                          <input matInput type="text" [maxLength]="10" [formControl]="telefon_PC" [disabled]= "flag_telefon_email_PC_saved"
                          [ngClass]="{'input-telefon-email-valid' : (telefon_PC.valid && (telefon_PC.value != '')) }"
                          >
                          <!-- <input matInput type="number" [(ngModel)]="date_contact.telefon" [disabled]= flag_telefon_email_saved> -->
                          <mat-hint align="end"> {{ telefon_PC.value != ''  ? returnCaractereTelefon(telefon_PC.value) : 0  }}/10</mat-hint>
                          <mat-error class="eroare-email-invalid" *ngIf="telefon_PC.invalid">Numarul de telefon nu este valid </mat-error>

                        </mat-form-field>
                      
                      </div>

                      <div class="flex-align-center"
                      [matTooltipDisabled]="!flag_telefon_email_PC_saved"
                      matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
                      >
                        <mat-form-field>
                          <mat-label>Email</mat-label>
                          <input matInput type="email" [formControl]="email_PC" [disabled]= "flag_telefon_email_PC_saved"
                          [ngClass]="{'input-telefon-email-valid' : (email_PC.valid && (email_PC.value != '')) }"
                          >
                          <mat-error class="eroare-email-invalid" *ngIf="email_PC.invalid">Adresa de email nu este valida. Introduceti un email de forma examplu @ domeniu . com </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="flex-align-center" *ngIf="!flag_telefon_email_PC_saved && !this.flag_PC_in_editare" [matTooltipDisabled]="!((persoana_contact_nonuser_date_contact.telefon === undefined) && (persoana_contact_nonuser_date_contact.email === undefined))" matTooltip="Introdu un numar de telefon sau o adresa de email pentru a putea salva">
                        <button mat-raised-button class="save-button" (click)="saveTelefonEmailPC()" [disabled]="!((email_PC.valid && (email_PC.value != '')) || (telefon_PC.valid && (telefon_PC.value != '')))">
                          Salveaza
                        </button>
                      </div>
                      <div class="flex-align-center" *ngIf="!flag_telefon_email_PC_saved && this.flag_PC_in_editare" [matTooltipDisabled]="!((persoana_contact_nonuser_date_contact.telefon === undefined) && (persoana_contact_nonuser_date_contact.email === undefined))" matTooltip="Introdu un numar de telefon sau o adresa de email pentru a putea salva">
                        <button mat-raised-button class="save-button" (click)="saveTelefonEmailPCEditat()" [disabled]="!((email_PC.valid && (email_PC.value != '')) || (telefon_PC.valid && (telefon_PC.value != '')))">
                          Salveaza modificarile
                        </button>
                      </div>
                      <div class="flex-align-center" *ngIf="flag_telefon_email_PC_saved">
                        <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaTelefonEmailPC()">
                          Editeaza
                        </button>
                      </div>

                    </div>

                  </div>
                  <div class="flex-align-center" style="justify-content: center;">
                    <div *ngIf="!flag_adresa_PC_add">
                      <button mat-raised-button class="save-button" (click)="copiazaAdresaBeneficiarToPC()" [disabled]="flag_PC_fara_adresa" *ngIf="flag_adresa_beneficiar_set">
                        + Foloseste adresa beneficiar
                      </button>
                      <button mat-raised-button class="save-button" (click)="adaugaAdresaPC()" [disabled]="flag_PC_fara_adresa">
                        + Adauga adresa
                      </button>
                      <button mat-raised-button (click)="PCFaraAdresa()" [ngClass]="{'save-button': flag_PC_fara_adresa, 'edit-telefon-email-button': !flag_PC_fara_adresa }">
                        Fara adresa
                      </button>
                    </div>
                  </div>
                  
                  <div class="row-date-contact" style="margin-top: 20px;" *ngIf="flag_adresa_PC_add">
                    <div class=" flex-align-center " [ngClass]="{'panel-add-adresa-green': flag_adresa_PC_set, 'panel-add-adresa': !flag_adresa_PC_set }">
                        <mat-form-field style="max-width: 30%;">
                          <mat-label>Judet</mat-label>
                          <input matInput type="text" [(ngModel)]="adresaPC.judet" required [disabled]="flag_adresa_PC_set">
                        </mat-form-field>

                      <mat-form-field style="max-width: 30%;">
                        <mat-label>Localitate</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaPC.localitate" required [disabled]="flag_adresa_PC_set">
                      </mat-form-field>

                      <mat-form-field style="max-width: 30%;">
                        <mat-label>Strada</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaPC.strada" required [disabled]="flag_adresa_PC_set">
                      </mat-form-field>

                      <mat-form-field style="max-width: 80px;">
                        <mat-label>Numar</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaPC.numar" [disabled]="flag_adresa_PC_set">
                      </mat-form-field>

                      <mat-form-field style="max-width: 100px;">
                        <mat-label>Bloc</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaPC.bloc" [disabled]="flag_adresa_PC_set">
                      </mat-form-field>

                      <mat-form-field style="max-width: 80px;">
                        <mat-label>Etaj</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaPC.etaj" [disabled]="flag_adresa_PC_set">
                      </mat-form-field>

                      <mat-form-field style="max-width: 120px;">
                        <mat-label>Apartament</mat-label>
                        <input matInput type="text" [(ngModel)]="adresaPC.ap" [disabled]="flag_adresa_PC_set">
                      </mat-form-field>
                    </div>
                    <button mat-raised-button class="save-button" (click)="salveazaAdresaPC()" *ngIf="!flag_adresa_PC_set"> Salveaza Adresa</button>
                    <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaAdresaPC ()" *ngIf="flag_adresa_PC_set"> Editeaza Adresa</button>
                    <button mat-raised-button color="warn" *ngIf="!flag_adresa_PC_set" (click)="anuleazaAdresaPC()"> Anuleaza</button>
                    <button mat-raised-button color="warn" *ngIf="flag_adresa_PC_set" (click)="stergeAdresaPC()"> Sterge Adresa</button>
                  </div>
                  


                </mat-tab>

                <!-- END Persoana de contact non user -- Date de contact-->

              </mat-tab-group>
            </ng-container>
          </ng-container>
          <ng-container *ngIf='listacontact.length>0 && addnewcontactperson == 1'>
            <button mat-raised-button color="primary" (click)="reInitPC()">Adauga PC nou</button>
          </ng-container>

          <button mat-raised-button color="primary" (click)="setPersoanaDeContactList()" *ngIf="selectedTabPC == 1 && addnewcontactperson == 0 && !flag_PC_in_editare "  >Adauga PC</button>
          <button mat-raised-button color="warn" (click)="anuleazaPCEditat()" *ngIf="addnewcontactperson == 0 && flag_PC_in_editare ">Anuleaza modificarile</button>
          <button mat-raised-button color="primary" (click)="salveazaPCEditat()" *ngIf="addnewcontactperson == 0 && flag_PC_in_editare ">Salveaza Modificarile</button>
          <button mat-raised-button color="primary" (click)="goToNextTabPC()" *ngIf="selectedTabPC < 1">Adauga Date de contact</button>
          <br>
          <br>
          PC = Persoana de Contact
          <br>

          <!-- END Persoana de contact non user -->

        </ng-container>

        <!-- END Persoana de contact DIFERITA DE reprezentantul legal -->

      </mat-tab>

      <!-- END Persoana de contact -->

    </mat-tab-group>

  </div>
  <div class="spacer"></div>
  <div mat-dialog-actions align='center'>
    <button  mat-raised-button color="warn" mat-dialog-close>Renunta</button>

    <!-- <button  mat-raised-button color="primary" (click)="sendvalues()" [disabled]="!date_personale.nume || !date_personale.prenume" mat-dialog-close>Ok</button> -->
    <div matTooltip="Completeaza datele obligatorii">
      <button mat-raised-button color="primary" (click)="sendvalues()"
      [disabled]="!date_personale.nume || !date_personale.prenume">Salveaza</button>
    </div>
    <button mat-raised-button class="next-step-button" (click)="goToNextTab()">{{nextStepButtonText()}} </button>
    <!-- <button mat-raised-button color="primary" (click)="sendvalues()" cdkFocusInitial>Ok</button> -->
  </div>
</div>
