import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileElement } from '../../model/element';

@Component({
  selector: 'app-renameDialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.css']
})
export class RenameDialogComponent implements OnInit {
  folderName: string;
  fisier: FileElement
  constructor(
    public dialogRef: MatDialogRef<RenameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data:any) {
      if (data){
        this.fisier = data
        console.log(" Data din rename dialog ==>> ", this.fisier)
        this.folderName = this.fisier.name
      }
    }
  
    salveazaNumeNou(){
      // console.log(" folderName ==>> ", this.folderName)
      if (!this.fisier.isFolder){
        // console.log(" Fisierul nu este folder ==>> ")
        if (!this.folderName.includes('.'+this.fisier.extension)){
          // console.log(" Fisierului i s-a sters extensia ==>> ")
          if (this.folderName.includes(this.fisier.extension)){ 
            // if(this.folderName[this.folderName.length-1-this.fisier.extension.length]){}
            // if(this.folderName[this.folderName.length-1-this.fisier.extension.length] ){}
            // console.log(" Fisierului i s-a sters extensia ==>> ", this.folderName[this.folderName.length-1-this.fisier.extension.length : this.folderName.length-1])
            // console.log(" Fisierului i s-a sters PUNCT extensia ==>> ", this.folderName.substring(this.folderName.length-this.fisier.extension.length,this.folderName.length ) )
            // console.log(" Fisierului i s-a sters PUNCT extensia ==>> ", this.folderName )
            if (this.folderName.substring(this.folderName.length-this.fisier.extension.length,this.folderName.length ) === this.fisier.extension){
              let intermediar = this.folderName.substring(0,this.folderName.length-this.fisier.extension.length)
              this.folderName = intermediar + '.' + this.fisier.extension
              // console.log("Folder name dupa ce adaug punct  ==>> ", this.folderName )
            }
          }
          else{
            if (this.folderName[this.folderName.length-1] === '.'){
              // console.log(" Fisierului se termina in punct ==>> ")
              this.folderName = this.folderName+this.fisier.extension
              // console.log(" folderName ==>> ", this.folderName)
            }
            else{
              // console.log(" Fisierului NU se termina in punct ==>> ")
              this.folderName = this.folderName+'.'+this.fisier.extension
              // console.log(" folderName ==>> ", this.folderName)
            }
          }
        }
        // console.log(this.folderName.includes('.'+this.fisier.extension));
      }
      // console.log(" folderName ==>> ", this.folderName)
      // console.log(" folderName ==>> ", this.folderName[this.folderName.length-1-this.fisier.extension.length])
      this.dialogRef.close(this.folderName)

    }


  ngOnInit() {}
}
