<!-- <div #pdfTable id="someid"> -->
<!-- <div mat-dialog-content style="display: flex;">

     <mat-grid-list cols="4">

        <mat-grid-tile
        colspan="4"
        >
            <span style="
            font-size: 18px;
            /* display: flex; */
            padding: 10px;
            "> Nume: {{nume}}
            </span>
        </mat-grid-tile>

        <br>

        GMFM este un instrument observațional, standardizat, descoperit și validat pentru a măsura schimbări în funcția motorie grosieră a copiilor cu paralizie cerebrală. Sistemul de scorare își propune să fie o linie de ghidare generală. Multe dintre puncte au descrieri specifice pentru fiecare scor.
        <br>

        <mat-grid-tile
        [colspan]="4"
        >
            <span style="
            font-size: 18px;
            /* display: flex; */
            padding: 10px;
            "> Prenume: {{prenume}}
            </span>
        </mat-grid-tile>

    </mat-grid-list>  -->

    <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Nume: {{docfromcls.nume}}
    </span>

    <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Prenume: {{docfromcls.prenume}}
    </span>
    <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Data Nasterii: {{docfromcls.data_nasterii }}
    </span>

    <!-- <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Evaluator: {{evaluator}}
    </span> -->
<br>
    <mat-form-field style="width: 100%;" >
        <mat-label>Conditii De Testare</mat-label>
        <textarea matInput [(ngModel)]="docfromcls.conditii_de_testare" placeholder="ex. Cameră, îmbrăcăminte, timp, alte persoane"></textarea>
    </mat-form-field>

    <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">      GMFM este un instrument observațional, standardizat, descoperit și validat pentru a măsura schimbări în funcția motorie grosieră a copiilor cu paralizie cerebrală. Sistemul de scorare își propune să fie o linie de ghidare generală. Multe dintre puncte au descrieri specifice pentru fiecare scor.
    </span>

    <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Scor:    <b>0 = nu inițiază / 1 = inițiază / 2 = termină complet mișcarea /  3 = termină mișcarea / 9 ( sau liber) = netestat </b>
    </span>

    <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">
    Este importantă diferențierea unui scor &nbsp;	 <b> 0 </b>, unde subiectul nu inițiază mișcarea și punctul ramas liber netestat!!!
    </span>

    <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">
    Completați scorul apropiat: dacă un punct este netestat, încercuiți numărul sarcinii din coloana dreaptă.
    </span>

    <span style="
    font-size: 18px;
    display: flex;
    padding: 10px;
    left: 5px;
    ">
    <h2>A: Decubit si rostogolire</h2>
    </span>
    <br>

    <table mat-table [dataSource]="dataSourceSectiuneaA" style="width: 100%;">

        <ng-container matColumnDef="Nr.">
            <th mat-header-cell *matHeaderCellDef> Nr. </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        <td mat-footer-cell *matFooterCellDef>
        </td>
        </ng-container>

        <ng-container matColumnDef="Miscare">
            <th mat-header-cell *matHeaderCellDef> Miscare </th>
            <td mat-cell *matCellDef="let element"> {{element.text}} </td>
            <td mat-footer-cell *matFooterCellDef>
            </td>
        </ng-container>

        <ng-container matColumnDef="Valoare">
            <th mat-header-cell *matHeaderCellDef>NT / Scor </th>
            <td mat-cell *matCellDef="let element">
                            <mat-checkbox
                [checked]="element.netestat"
                (change)="checkedA($event,element.id)"
                >
            </mat-checkbox>
                <mat-slider
                    [max]="5" [(ngModel)]="element.valoare" [min]="0" [thumbLabel]="true" [step]="1" [tickInterval]="1" (change)="changeA($event,element.id)" >
                </mat-slider>

            </td>
        <td mat-footer-cell *matFooterCellDef>
            Total Sectiunea A:
        </td>

        </ng-container>

        <ng-container matColumnDef="NT">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element">
                <input matInput type="number" [(ngModel)]="element.valoare">
            </td>
            <td mat-footer-cell *matFooterCellDef>
                {{getTotal()}}
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumnsSectiuneA"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumnsSectiuneA"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsSectiuneA;"></tr>

    </table>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">
    <h2>B: ASEZAT</h2>
</span>
<br>
<table mat-table [dataSource]="dataSourceSectiuneaB" style="width: 100%;">

	<!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

	<!-- Position Column -->
	<ng-container matColumnDef="Nr.">
		<th mat-header-cell *matHeaderCellDef> Nr. </th>
		<td mat-cell *matCellDef="let element"> {{element.id}} </td>
    <td mat-footer-cell *matFooterCellDef>
	</td>
    </ng-container>

    <ng-container matColumnDef="Miscare">
		<th mat-header-cell *matHeaderCellDef> Miscare </th>
        <td mat-cell *matCellDef="let element"> {{element.text}} </td>
        <td mat-footer-cell *matFooterCellDef>
        </td>
    </ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="Valoare">
		<th mat-header-cell *matHeaderCellDef>NT / Scor </th>
		<td mat-cell *matCellDef="let element">
                        <mat-checkbox
            [checked]="element.netestat"
            (change)="checkedB($event,element.id)"
            >
         </mat-checkbox>
			<mat-slider
				 [max]="5" [(ngModel)]="element.valoare" [min]="0" [thumbLabel]="true" [step]="1" [tickInterval]="1" (change)="changeB($event,element.id)" >
            </mat-slider>

		</td>
	<td mat-footer-cell *matFooterCellDef>
		Total Sectiunea B:
	</td>

    </ng-container>

    <ng-container matColumnDef="NT">
		<th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">
            <input matInput type="number" [(ngModel)]="element.valoare">
         </td>
         <td mat-footer-cell *matFooterCellDef>
            {{getTotalB()}}
        </td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumnsSectiuneB"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumnsSectiuneB"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumnsSectiuneB;"></tr>

</table>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">
    <h2>C: TARARE & STAND PE GENUNCHI</h2>
</span>
<br>
<table mat-table [dataSource]="dataSourceSectiuneaC" style="width: 100%;">

	<!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

	<!-- Position Column -->
	<ng-container matColumnDef="Nr.">
		<th mat-header-cell *matHeaderCellDef> Nr. </th>
		<td mat-cell *matCellDef="let element"> {{element.id}} </td>
    <td mat-footer-cell *matFooterCellDef>
	</td>
    </ng-container>

    <ng-container matColumnDef="Miscare">
		<th mat-header-cell *matHeaderCellDef> Miscare </th>
        <td mat-cell *matCellDef="let element"> {{element.text}} </td>
        <td mat-footer-cell *matFooterCellDef>
        </td>
    </ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="Valoare">
		<th mat-header-cell *matHeaderCellDef>NT / Scor </th>
		<td mat-cell *matCellDef="let element">
                        <mat-checkbox
            [checked]="element.netestat"
            (change)="checkedC($event,element.id)"
            >
         </mat-checkbox>
			<mat-slider
				 [max]="5" [(ngModel)]="element.valoare" [min]="0" [thumbLabel]="true" [step]="1" [tickInterval]="1" (change)="changeC($event,element.id)" >
            </mat-slider>

		</td>
	<td mat-footer-cell *matFooterCellDef>
		Total Sectiunea C:
	</td>

    </ng-container>

    <ng-container matColumnDef="NT">
		<th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">
            <input matInput type="number" [(ngModel)]="element.valoare">
         </td>
         <td mat-footer-cell *matFooterCellDef>
            {{getTotalC()}}
        </td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumnsSectiuneC"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumnsSectiuneC"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumnsSectiuneC;"></tr>

</table>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">
    <h2>D: MERS, ALERGAT & SĂRITURĂ</h2>
</span>
<br>
<table mat-table [dataSource]="dataSourceSectiuneaD" style="width: 100%;">

	<!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

	<!-- Position Column -->
	<ng-container matColumnDef="Nr.">
		<th mat-header-cell *matHeaderCellDef> Nr. </th>
		<td mat-cell *matCellDef="let element"> {{element.id}} </td>
    <td mat-footer-cell *matFooterCellDef>
	</td>
    </ng-container>

    <ng-container matColumnDef="Miscare">
		<th mat-header-cell *matHeaderCellDef> Miscare </th>
        <td mat-cell *matCellDef="let element"> {{element.text}} </td>
        <td mat-footer-cell *matFooterCellDef>
        </td>
    </ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="Valoare">
		<th mat-header-cell *matHeaderCellDef>NT / Scor </th>
		<td mat-cell *matCellDef="let element">
                        <mat-checkbox
            (change)="checkedD($event,element.id)"
            [checked]="element.netestat"
            >
         </mat-checkbox>
			<mat-slider
				 [max]="5" [(ngModel)]="element.valoare" [min]="0" [thumbLabel]="true" [step]="1" [tickInterval]="1" (change)="changeD($event,element.id)" >
            </mat-slider>

		</td>
	<td mat-footer-cell *matFooterCellDef>
		Total Sectiunea D:
	</td>

    </ng-container>

    <ng-container matColumnDef="NT">
		<th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">
            <input matInput type="number" [(ngModel)]="element.valoare">
         </td>
         <td mat-footer-cell *matFooterCellDef>
            {{getTotalD()}}
        </td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumnsSectiuneD"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumnsSectiuneD"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumnsSectiuneD;"></tr>

</table>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> A prezentat aceasta evaluare performanța adevărată a copilului?   &nbsp;
            <mat-checkbox [checked]="docfromcls.performanta_adevarata_a_copilului" (change)="schimbaperformanta()" > Da  </mat-checkbox>
            &nbsp;
            <mat-checkbox [checked]="!docfromcls.performanta_adevarata_a_copilului" (change)="schimbaperformanta()" > Nu  </mat-checkbox>
</span>


<mat-form-field style="width: 100%;" >
    <mat-label>Comentarii</mat-label>
    <textarea matInput [(ngModel)]="docfromcls.comentarii" ></textarea>
</mat-form-field>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> GMFM-88 Scor sumar</h2></span>


<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  A. Decubit si rostogolire => (Total Dim. A) {{getTotal()}} / 51 * 100 = {{getTotal()/51*100 | number: '1.0-2'}} %
</span>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  B. ASEZAT => (Total Dim. B) {{getTotalB()}} / 60 * 100 = {{getTotalB()/60*100 | number: '1.0-2'}} %
</span>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  C. TARARE & STAND PE GENUNCHI => (Total Dim. C) {{getTotalC()}} / 42 * 100 = {{getTotalC()/42*100 | number: '1.0-2'}} %
</span>

<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  D. MERS, ALERGAT & SĂRITURĂ (?? Stând  )=> (Total Dim. D) {{getTotalD()}} / 39 * 100 = {{getTotalD()/42*100 | number: '1.0-2'}} %
</span>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  E. ?? MERS, ALERGAT & SĂRITURĂ => (Total Dim. E) 0 / 39 * 100 = 0 %
</span>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  Scor total = %A + %B + %C + %D + %E / totalul dimensiunilor = {{getTotal()/51*100 + getTotalB()/60*100 + getTotalC()/42*100 + getTotalD()/42*100 | number: '1.0-2'}} / 5 = {{ (getTotal()/51*100 + getTotalB()/60*100 + getTotalC()/42*100 + getTotalD()/42*100) / 5 | number: '1.0-2'}}
</span>
<br>


<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> GMFM 66 Scor etimativ a abilității motorii grosiere</h2></span>

<mat-form-field style="width: 100%;" >
    <mat-label>GMFM - 66 Scor </mat-label>
    <textarea matInput [(ngModel)]="docfromcls.gnfm_66_scor" ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>GMFM - 66 Scor Vechi</mat-label>
    <textarea matInput [(ngModel)]="docfromcls.gnfm_66_scor_vechi" ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>GMFM - 66 Schimbari</mat-label>
    <textarea matInput [(ngModel)]="docfromcls.gnfm_66_schimbari" ></textarea>
</mat-form-field>

<br>

<div style="display: flex; flex-direction: column;">
  <span style="
  font-size: 18px;
  display: flex;
  padding: 10px;
  left: 5px;
  flex:1;
  justify-content: center;
  "> <h2> Testare cu mijloace ajutătoare/orteze GMFM- 88</h2></span>

  <div style="display: flex; flex-direction: row;" >
    <div style="display: flex; flex: 1; justify-content: center;flex-direction: column;">
      <span style="
      font-size: 18px;
      padding: 10px;
      text-align: center;
      left: 5px;
      "> <h2> Mijloc / Dimensiune</h2></span>
        <div id="testdecautat" style="display: flex; flex-direction: column;">

          <mat-form-field appearance="outline">
            <mat-label>Rolator</mat-label>
            <input matInput type="number" [(ngModel)]="docfromcls.rolator">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Cadru de mers</mat-label>
            <input matInput type="number" [(ngModel)]="docfromcls.cadru_de_mers">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Cârje subaxilare</mat-label>
            <input matInput type="number" [(ngModel)]="docfromcls.carje_subalixare">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Cârja cu 4 pic.</mat-label>
            <input matInput type="number" [(ngModel)]="docfromcls.carja_cu_4_pic">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Cârja</mat-label>
            <input matInput type="number" [(ngModel)]="docfromcls.carja">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Nimic</mat-label>
            <input matInput  [(ngModel)]="docfromcls.mijlocnimic">
          </mat-form-field>

          <mat-form-field appearance="outline" >
            <mat-label>Altele</mat-label>
            <textarea matInput [(ngModel)]="docfromcls.mijlocaltele" ></textarea>
        </mat-form-field>
        </div>
    </div>
    <div style="display: flex; flex: 1; justify-content: center;flex-direction: column;">
      <span style="
      font-size: 18px;
      padding: 10px;
      text-align: center;
      left: 5px;
      "> <h2> Orteza / Dimensiune</h2></span>
        <div id="testdecautat" style="display: flex; flex-direction: column;">

          <mat-form-field appearance="outline">
            <mat-label>Ort. Șold</mat-label>
            <input matInput type="number" [(ngModel)]="docfromcls.ort_sold">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Ort. Genunchi</mat-label>
            <input matInput type="number" [(ngModel)]="docfromcls.ort_genunchi">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Ort. Glezna-pic	</mat-label>
            <input matInput type="number" [(ngModel)]="docfromcls.ort_glezna_pic">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Ort. Picior</mat-label>
            <input matInput type="number" [(ngModel)]="docfromcls.ort_picior">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Pantofi ortopedici</mat-label>
            <input matInput type="number" [(ngModel)]="docfromcls.pantofi_ortopedici">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Nimic</mat-label>
            <input matInput   [(ngModel)]="docfromcls.ortnimic">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Altele</mat-label>
            <textarea matInput [(ngModel)]="docfromcls.ortaltele" ></textarea>
        </mat-form-field>
        </div>
    </div>
  </div>

</div>

<ng-container *ngIf="caz == 1">
  <!-- </div> -->
  <button mat-raised-button (click)="semneazaRL()" > Semnatura Reprezentant Legal</button>
  <button mat-raised-button (click)="semneazaTerapeut()"  > Semnatura Terapeut</button>
  <!-- <button mat-raised-button (click)="test()"  > Salveaza Test</button> -->
  <button mat-raised-button (click)="openPDFPreviewComponent()"  > Previzualizeaza </button>
  <!-- <button mat-raised-button > Salveaza</button> -->
</ng-container>

<!-- </div> -->
<ng-container *ngIf="caz == 2">
  <!-- </div> -->
  <!-- <button mat-raised-button (click)="semneazaRL()" > Salveaza Modificarile </button> -->
  <!-- <button mat-raised-button (click)="test()"  > Salveaza Test</button> -->
  <button mat-raised-button (click)="openPDFPreviewSsaveModificari()"  > Genereaza Document </button>
  <button mat-raised-button color="warn"  mat-dialog-close > Inchide </button>
  <!-- <button mat-raised-button > Salveaza</button> -->
</ng-container>
