
<div>
  <mat-form-field style="width: 30%;padding-left: 2%;" >
      <mat-label>Numar Contract:</mat-label>
      <textarea matInput [(ngModel)]="contract_masina.nr_contract" ></textarea>
  </mat-form-field>
  <!-- <mat-form-field style="width: 30%;padding-left: 2%;" >
      <mat-label>Data Contract:</mat-label>
      <textarea matInput [(ngModel)]="contract_masina.data_contract" ></textarea>
  </mat-form-field> -->
  <mat-form-field appearance="fill">
    <mat-label>Data Contract:</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="intermediar_data" (ngModelChange)="intermediar_data=$event">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

</div>
<div>

<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Nume Beneficiar:</mat-label>
    <textarea matInput [(ngModel)]="contract_masina.nume_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Prenume Beneficiar:</mat-label>
    <textarea matInput [(ngModel)]="contract_masina.prenume_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>CNP:</mat-label>
    <textarea matInput [(ngModel)]="contract_masina.cnp_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
  <mat-label>Serie CI  Beneficiar:</mat-label>
  <textarea matInput [(ngModel)]="contract_masina.serie_ci_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
  <mat-label>Nr. CI  Beneficiar:</mat-label>
  <textarea matInput [(ngModel)]="contract_masina.numar_ci_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Contact  Beneficiar:</mat-label>
    <textarea matInput [(ngModel)]="contract_masina.contact_beneficiar" ></textarea>
</mat-form-field>
<mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Domiciliul:</mat-label>
    <textarea matInput [(ngModel)]="contract_masina.domiciliu" ></textarea>
</mat-form-field>
</div>
<div>

  <mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Nume si Prenume insotitor:</mat-label>
    <textarea matInput [(ngModel)]="contract_masina.insotitor_beneficiar" ></textarea>
  </mat-form-field>
  <mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Serie CI insotitor:</mat-label>
    <textarea matInput [(ngModel)]="contract_masina.serie_ci_insotitor" ></textarea>
  </mat-form-field>
  <mat-form-field style="width: 30%;padding-left: 2%;" >
    <mat-label>Nr. CI insotitor:</mat-label>
    <textarea matInput [(ngModel)]="contract_masina.numar_ci_insotitor" ></textarea>
  </mat-form-field>
</div>

<ng-container *ngIf="caz == 1">
  <button mat-raised-button (click)="semneazaRL()" > Semnatura Reprezentant Legal</button>
  <button mat-raised-button (click)="semneazaTerapeut()"  > Semnatura Terapeut</button>
  <button mat-raised-button (click)="openPDFPreviewComponent()" > Previzualizeaza </button>
</ng-container>
<ng-container *ngIf="caz == 2">
  <button mat-raised-button (click)="openPDFPreviewSsaveModificari()"  > Genereaza Document </button>
  <button mat-raised-button color="warn"  mat-dialog-close > Inchide </button>
</ng-container>
