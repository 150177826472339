import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'mihainesufoundation';
  constructor(loginService: LoginService,private router: Router){
    if (!loginService.isLoggedIn()){
      console.log("login Service")
      this.router.navigate(['login'])
    }
  }
}
