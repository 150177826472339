
<mat-menu #menu>
    <ng-container *ngFor="let item of items">
      <button *ngIf="!item.children; else branch" mat-menu-item (click)="select(item.name)" >{{item.name}}</button>
      <ng-template #branch>
        <button mat-menu-item [matMenuTriggerFor]="innerMenu.menu" >{{item.name}}</button>
        <app-menu #innerMenu [items]="item.children" (notify)="select($event)"></app-menu>
      </ng-template>
    </ng-container>
  </mat-menu>
