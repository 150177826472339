<div style="text-align: center;">

<button mat-raised-button color="primary" (click)="openDialog()" style="margin-right: 10px;">Adauga Beneficiar</button>

</div>

<ejs-grid
  #grid
  [dataSource]="PacientListtwo"
  (load)='load()'
  [allowFiltering]='true'
  [allowSorting]='true'
  [allowPaging]='true'
  [pageSettings]="pageSettings"
  [allowResizing]="true"
  [allowReordering]="true"
  (rowSelected)='rowSelected($event)'
  >
  <e-columns>
    <e-column field='id' headerText='ID' textAlign='Right' width=120></e-column>
    <e-column field='date_personale.nume' headerText='Nume' width=150></e-column>
    <e-column field='date_personale.prenume' headerText='Prenume' width=150></e-column>
    <e-column field='date_personale.cnp' headerText='CNP' width=150></e-column>
    <e-column field='date_contact.email' headerText='Email' width=150></e-column>
    <e-column field='date_contact.telefon' headerText='Telefon' width=150></e-column>
  </e-columns>
</ejs-grid>


