import { Component, OnInit, ViewChild } from '@angular/core';
import {CatserviciiService} from '../../services/categoriedeservicii.service';
import {MatTableDataSource} from '@angular/material/table';
import { TipuriDeServicii, Valabilitate, ValabilitateCreate,TipuriDeServiciiCreate, TipuriDeServiciiFaraValabilitateCreate } from 'src/app/models/nomenclatoare.model';
import { TipuriDeServiciiInterface, ValabilitateInterface,ValabilitateCreateInterface,TipuriDeServiciiCreateInterface,TipuriDeServiciiCreateFaraValabilitateInterface } from 'src/app/interfaces/nomenclatoare.interface';
import { IntervalService } from '../../services/interval.service'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { DatePipe } from '@angular/common'
import { MatInput } from '@angular/material/input';
// import { type } from 'os';

import {MatPaginator} from '@angular/material/paginator';
import { CategoriiserviciieditComponent } from '../categoriiserviciiedit/categoriiserviciiedit.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export const MY_FORMATS = {
  parse: {
    dateInput: 'd',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-categoriiservicii',
  templateUrl: './categoriiservicii.component.html',
  styleUrls: ['./categoriiservicii.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class CategoriiserviciiComponent implements OnInit {
  public categorii: any[] = [];
  categorii2:any[] = [];
  displayedColumns: string[] = ['id','cod', 'denumire', 'edit','deleteselect'];
  dataSource: any
  newcat = new TipuriDeServiciiCreate();
  interval:ValabilitateInterface
  intervalcreate: ValabilitateCreateInterface
  listaintervale:ValabilitateInterface[] = []
  start: Date
  end: Date
  startformated: string
  endformated: string
  todelete: any[] = []
  cod_nou: string = ''
  public IntervalList: ValabilitateInterface [] = []
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialog: MatDialog, public catService: CatserviciiService, public intervalService: IntervalService,public datepipe: DatePipe) { }

  ngOnInit(): void {
    // this.categorii = this.catService.getcategoriiservicii();
    this.catService.getfromserver()
      .subscribe((data:TipuriDeServiciiInterface[]) => {
        if (data){
          console.log("data din cat service subscribe ==>> ", data)
          this.categorii = data
          this.dataSource = new MatTableDataSource(this.categorii);
          this.dataSource.paginator = this.paginator;

        }
        // console.log("this.dataSource din cat service subscribe ==>> ", this.dataSource)
        // for(let x in data){
        //   let newcatintermediar:TipuriDeServicii = new TipuriDeServicii();
        //   this.categorii.push(data[x])
        //   // for (let y of Object.keys(data[x]) )
        //   // {
        //   //   if ( data[x][y] != null )
        //   //   {
        //   //     newcatintermediar[y] = data[x][y]
        //   //   }
        //   // }
        //   // this.categorii.push(newcatintermediar)
        //   console.log("data[x].keys =>> ", Object.keys(data[x]));
        // }
        console.log("this.categorii.length 22 =>> ", this.categorii.length);
        console.log("this.categorii full object onInit 22 =>> ", this.categorii);
      });

      // this.getCatfromService()
    // console.log("this.categorii.length 11 =>> ", this.categorii.length);
    // console.log("this.categorii onInit ==> ",this.categorii)
    // console.log("this.categorii.length =>> ", Object.keys(this.categorii));

    // this.dataSource = new MatTableDataSource(this.categorii);
    // console.log("categorii 2 length ==> ",this.categorii2.length)
    // console.log("trigger pentru serviiu ==> ",this.getCatfromService())
    this.getListaIntervalefromServer()
    console.log("this.IntervalList onInit ==> ",this.IntervalList)
    // console.log("trigger pentru Intervale ==> ",this.getListaIntervalefromServer())
    // console.log("dupa trigger, categorii 2 length ==> ",this.categorii2.length)
    // console.log("dupa trigger, categorii 2 ==> ",this.categorii2)
    // for (let serv of this.categorii2){
    //   console.log("servicii from get ==>> ",serv)
    // }
    let intermediar = {}

    // console.log(this.catService.addHero(intermediar))
    // console.log("this.categorii2 tarziu => ", this.categorii2)
  }
  openCategoriiServiciiEdit(categorieServiciu):void{
    const dialogRef = this.dialog.open(CategoriiserviciieditComponent, {
      width: '850px',
      height: '550px',
      data: categorieServiciu
    });
    dialogRef.afterClosed().subscribe( result =>{
      this.updatedata()
    })
  }
  marktodelete(deleteid){
    if (this.todelete.includes(deleteid)){
      console.log("Id-ul deja selectat !! ")
      this.todelete.forEach( (value,index) =>{
        if (value == deleteid) this.todelete.splice(index,1)
      })
    }
    else{
      this.todelete.push(deleteid)
    }
    console.log("Id-uri de sers ==>> ",this.todelete)
  }
  deleteitems(){
    this.todelete.forEach( value =>{
      this.catService.deletefromserver(value).subscribe( (data) =>{
        if (data){
          console.log("Data from delete categorie de servicii ==>> ", data)
          this.updatedata()
        }
      })
    })
  }
  updatedata(){
    this.catService.getfromserver()
    .subscribe((data:TipuriDeServiciiInterface[]) => {
      if (data){
        console.log("data din cat service subscribe ==>> ", data)
        this.categorii = data
        this.dataSource = new MatTableDataSource(this.categorii);
        this.dataSource.paginator = this.paginator;
      }
    });
  }
  startdateset(v) {
    // console.log("type of v =>",v.toString());
    // console.log("start data set -> v =>",v);
    // console.log("start data set ->type of v =>",typeof v);
    // for (var i in v){
    //   console.log ("i in lenght ->", v[i], " i = >", i )
    // }
    // let vd:Date = new Date(v.substring(6,9)+"."+v.substring(3,4)+"."+v.substring(0,1) + " 00:00:01")
    // console.log("substrings 6-9 =>",v.substring(0,2) )
    // let vd = v.substring(6,)+"."+v.substring(3,5)+"."+v.substring(0,2) + " 00:00:01"
    // console.log("vd => ", vd)
    let vd2 = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    // console.log("vd2 => ", vd2)

    let intermediar = this.datepipe.transform(new Date(vd2),"MM.dd.YYYY")
    // console.log("type of intermediar => ",typeof intermediar)
    this.start = new Date(intermediar)
    // console.log("intermediar => ",intermediar)
    // console.log("this start => ",this.start)
    // return vd;
    // console.log("start formated => ",this.startformated)
    // this.startformated = v;
    // console.log("start formated => ",this.startformated)
    // this.intervalcreate.start = this.startformated.toString()
  }

  valabilitatestatuschange(v){
    console.log("status v =>", v)
    this.newcat.arevalabilitate = v
    // this.intervalcreate = new ValabilitateCreate()
    // this.intervalcreate.format_type = "dd.MM.YYYY"
    // if (v){
    //   this.intervalcreate = new ValabilitateCreate()
    //   this.intervalcreate.format_type = "dd.MM.YYYY"
    // }
  }

  enddateset(v) {
    // console.log("end data set v => ",v);
    // console.log("type of v end data set => ",typeof (v));
    // for (var i in v){
    //   console.log ("i in lenght ->", v[i], " i = >", i )
    // }
    // let vd:Date = new Date(v.substring(6,9)+"."+v.substring(3,4)+"."+v.substring(0,1) + " 00:00:01")
    // console.log("substrings 6-9 =>",v.substring(0,2) )
    let vd = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    // console.log("vd => ",typeof vd)
    // console.log("end data set v2 => ",v);

    let intermediar = this.datepipe.transform(new Date(vd),"MM.dd.YYYY")
    this.end = new Date(intermediar)
    // console.log("intermediar => ",intermediar)
    // console.log("this.end => ",this.end)
    return vd
    // console.log("start formated => ",this.endformated)
    // this.startformated = v;
    // console.log("start formated => ",this.startformated)
    // this.intervalcreate.end = this.endformated.toString()
  }
  formatdatefields(v: string){
    console.log("v ==> ",v)
  }
  getListaIntervalefromServer(){
    this.intervalService.getIntervalFromServer()
    .subscribe( (data:ValabilitateInterface[]) =>{
      // console.log("getIntervalefromServer subscribe -> Data ==> ", data)
      for (let x in data){
        // console.log("x in data ==>> ",x)
        // console.log("data[x]==> ", data[x])
        let interval:ValabilitateInterface = new Valabilitate()
        for (let y of Object.keys(data[x]) ){
          if ( data[x][y] != null )
          {
            interval[y] = data[x][y]
          }
        }

        // for(let y of Object.entries(data[x])){
        //   // interval[y]
        //   console.log(" get interval from server -> y => ", y)
        //   console.log(" get interval from server -> y => ", y, " y[0] = ", y[0]," interval."+y[0]+" = "+y[1])
        //   console.log("keys of interval => ", Object.keys(interval))
        //   console.log("y[0] in keys of interval => ", (y[0] in Object.keys(interval)))
        //   for (let q in Object.keys(interval)){
        //     console.log("q => ", Object.keys(interval)[q])
        //     if (y[0] === Object.keys(interval)[q]){
        //       console.log("BINGO!! ", y[0], " si ", Object.keys(interval)[q] )
        //     }
        //   }

        //   if (y[0] in Object.keys(interval) && y[1]){
        //     console.log("y[0] "+ y[0] +" este o cheie comuna si pt interval si pentru y")
        //     console.log("interval."+ y[0] +" = " + y[1] )
        //     interval[y[0]] = y[1]
        //     console.log("interval din for object keys =>", interval)

        //   }
        //   console.log("y din for object keys =>", interval)
        // }

        // if (data[x]["id"]){
        //   interval.id = data[x]["id"]
        // }
        // if (data[x]["start"]){
        //   interval.start = data[x]["start"]
        // }
        // if (data[x]["end"]){
        //   interval.end = data[x]["end"]
        // }
        // if (data[x]["format_type"]){
        //   interval.format_type = data[x]["format_type"]
        // }

        // console.log("Interval intermediar ==>> ",interval)
        // console.log("Intermediar de x: ",x," ==>> ",data[x])
        this.IntervalList.push(interval)

      }
      // console.log("IntervalList intermediar ==>> ",this.IntervalList)
      // return this.IntervalList
    })

    // console.log("get lista from server => ", this.intervalService.getIntervalFromServer())
    // .subscribe((data:ValabilitateInterface[]) =>{
    //   console.log("Data getListaIntervalefromServer ==> ", data)
    //   for (let x in data){
    //     console.log("x in data =>> ", data[x])
    //   }
    // })
  }

  getCatfromService() {
    // this.categorii2.push(this.catService.getfromserver())
    // return this.categorii2
    this.catService.getfromserver()
    .subscribe((data:TipuriDeServiciiInterface[]) => {
        // for (let x in data)
        // {
          //   console.log("x from for =>> ", x)
          //   this.categorii2.push()
          // }
          // console.log("getcatfromservice subscribe -> Data ==> ", data)
          for(let x in data){
            // console.log("x==> ", x)
            // console.log("data[x]==> ", data[x])
            // console.log("object keys of x==> ", Object.keys(data[x]))
            let newcatintermediar:TipuriDeServicii = new TipuriDeServicii();
            //  console.log("key new cat => ",Object.keys(newcatintermediar))
            for (let y of Object.keys(data[x]) )
            {
              //  console.log("y ==> ", y)
              //  console.log("data[x][y] ==> ", data[x][y])

              if ( data[x][y] != null )
              {
                newcatintermediar[y] = data[x][y]
              }
            }
            // console.log("Data de y ==> ", newcatintermediar)
            // this.categorii2.push(newcatintermediar)
            this.categorii.push(newcatintermediar)
            // this.addtocategorie2(newcatintermediar)
          }
          // console.log("categorii 2 dupa x in data ==> ", this.categorii2)
          // return this.categorii2
        });
    // return this.categorii2
    // this.catService.getfromserver()
    // .subscribe((data:TipuriDeServiciiInterface) => {this.categorii2.push(data)});
    // return this.categorii2
  }

  getCatfromServicebyis() {
    this.catService.serchfromserver(2)
    .subscribe((data:TipuriDeServiciiInterface[]) => {
          console.log("Data ==> ", data)
          // for(let x in data){
          //   let newcatintermediar:TipuriDeServicii = new TipuriDeServicii();
          //   for (let y of Object.keys(data[x]) )
          //   {
          //     if ( data[x][y] != null )
          //     {
          //       newcatintermediar[y] = data[x][y]
          //     }
          //   }
          //   this.addtocategorie2(newcatintermediar)
          // }
          return data
        });
    return this.categorii2
  }

  // updatedata(){
  //   let intermediar = this.categorii2[4]
  //   console.log("intermediar update data => ",intermediar)
  //   intermediar.denumire="teszzs"
  //   this.catService.updatefromserver(intermediar).subscribe((data:TipuriDeServicii) =>{
  //     console.log("Data ==> ", data)
  //     return data
  //   })
  // }

  addtocategorie2(deagaugat: TipuriDeServicii){
    this.categorii2.push(deagaugat)
  }
  addnewcategorie(){
    console.log ("categoriiservicii component -> addnewcategorie() -> newcat inainte de orice  => ", this.newcat)
    if (this.newcat.arevalabilitate){ // check daca este bifata valabilitatea
      console.log ("categoriiservicii component -> addnewcategorie() -> are valabilitate TRUE  => ", this.newcat.arevalabilitate)
      // var intermediar:ValabilitateCreateInterface = new ValabilitateCreate()
      // intermediar.start = this.newcat.valabilitate.start
      // intermediar.end = this.newcat.valabilitate.end
      // intermediar.format_type = this.newcat.valabilitate.format_type
      // console.log("intermediar de separat => ",intermediar)
      this.intervalcreate = new ValabilitateCreate()
      this.intervalcreate.format_type = "MM.dd.YYYY"
      this.intervalcreate.start = this.start.toString()
      this.intervalcreate.end = this.end.toString()
      console.log("categoriiservicii component -> addnewcategorie() -> this.intervalcreate  => ",this.intervalcreate)
      console.log("categorii array 1 => -- !! -- ==> ",this.categorii)      //commented before
      this.intervalService.addIntervalToServer(this.intervalcreate)
        .subscribe((data)=>{
          if (data){
            console.log("data from add interval to server => ", data)
            this.newcat.valabilitateid = data
            console.log("newcat.valabilitateid from add interval to server from subscribe => ",this.newcat.valabilitateid)
            // console.log("type of newcat.valabilitateid from add interval to server from subscribe => ",typeof(this.newcat.valabilitateid))
            this.catService.addHeroCuValabilitate(this.newcat)
                .subscribe( data => {
                  console.log("data put id ==> ",data.id)
                  console.log("data keys ==> ",Object.keys(data))
                  console.log("data full object ==> ",data)
                  this.categorii.push(data)
                  console.log("categorii array => -- !! -- ==> ",this.categorii)      //commented before
                  this.refreshformafteradd()
                  this.dataSource.paginator = this.paginator;
                  // console.log("typeof Toate detaliile !! ==> ",typeof(toate_detaliile))      //commented before

                  // toate_detaliile = data
                  // this.toate_detaliile2 = data
                  // console.log("toate detaliile ==>> !1 =>> ", toate_detaliile)
                  // console.log("toate detaliile2 ==>> !1 =>> ", this.toate_detaliile2)

                  // toate_detaliile.id = data.id  // commented before
                  // for (let its of Object.entries(hero)){  // commented before
                  //   if (its[0] in hero){  // commented before
                  //     if(its[1]){  // commented before
                  //       toate_detaliile[its[0]] = its[1]  // commented before
                  //     }  // commented before
                  //   }  // commented before
                  // }  // commented before
                });
          }
        })
      console.log("categorii array 2 => -- !!!! -- ==> ",this.categorii)      //commented before
      console.log("newcat full object from add interval to server => ",this.newcat)
    }
    else{
      console.log ("categoriiservicii component -> addnewcategorie() -> are valabilitate FALSE  => ", this.newcat.arevalabilitate)
      console.log ("this.newcat full object daca nu are valabilitate  => ", this.newcat)
      // let newcategory : TipuriDeServiciiCreateFaraValabilitateInterface =  new TipuriDeServiciiFaraValabilitateCreate()
      // for (let its of Object.entries(this.newcat)){
      //   console.log( " its din object => ", its)
      //   // console.log( " its lenght din object => ",  its.length)
      //   console.log( " its[0] din object => ", its[0])
      //   if (its[0] in newcategory){
      //     console.log ("cheia its[0]: " + its[0] + " is present in newcategory keys => " + Object.keys(newcategory))
      //     if (its[1]){
      //       console.log("cheia its[0]: " + its[0]+" cu valoarea: "+ its[1] + " este definita")
      //       newcategory[its[0]] = its[1]
      //     }
      //     else{
      //       console.log("cheia its[0]: " + its[0]+" cu valoarea: "+ its[1] + " NU este definita")
      //     }
      //   }
      //   else{
      //     console.log ("cheia its[0]: " + its[0] + " is NOT present in newcategory keys => " + Object.keys(newcategory))
      //   }
      //   // console.log("this.catService.addHeroFaraValabilitate(newcategory2) => ", this.catService.addHeroFaraValabilitate(newcategory))
      // }
      this.catService.addHeroFaraValabilitate(this.newcat)
        .subscribe(data =>{
          if (data){
            console.log("data din addHeroFaraValabilitate ==>> ",data)
            this.categorii.push(data)
            this.refreshformafteradd()
             this.dataSource.paginator = this.paginator;
          }
        })
      // console.log("new category dupa for => ", newcategory)
      // this.catService.addHeroFaraValabilitate(newcategory)
    }
  //  this.intervalService.addIntervalToServer(this.newcat.valabilitate).subscribe((data) => {
  //     if (data){
  //       var intermediar = new Valabilitate()
  //       intermediar.id = data.id
  //       intermediar.start = data.start
  //       intermediar.end = data.end
  //       intermediar.format_type = data.format_type
  //       console.log("data interval post =>", data)

  //       console.log("Iintermediar din agg new categorie => ",intermediar.id)
  //       this.intervalService.IntervalList.push(intermediar)
  //       this.newcat.valabilitateid = intermediar.id
  //       this.newcat.valabilitate = intermediar
  //     }
  //     console.log ("new cat from add new categorie => ", this.newcat)
  //     var intermediarnewcat:TipuriDeServiciiInterface
  //     intermediarnewcat.cod = this.newcat.cod
  //     intermediarnewcat.denumire = this.newcat.denumire
  //     if (this.newcat.arevalabilitate){
  //       intermediarnewcat.arevalabilitate = this.newcat.arevalabilitate
  //       intermediarnewcat.valabilitateid = this.newcat.valabilitateid
  //     }
  //     console.log("intermediar new catt inainte de add hero ==> ", intermediarnewcat)
  //     this.catService.addHero(intermediarnewcat)
  //     this.newcat = new TipuriDeServicii()
  //     this.categorii2 = []
  //     this.categirie2afis()
  //   })

}
@ViewChild('startDate',{
  read:MatInput
}) startDate:MatInput;
@ViewChild('endDate',{
  read:MatInput
}) endDate:MatInput;
  refreshformafteradd(){
    this.newcat = new TipuriDeServiciiCreate();
    // this.startDate.value='';
    // this.endDate.value='';
     // this.start = null

     this.dataSource = new MatTableDataSource(this.categorii);
     this.dataSource.paginator = this.paginator;

     console.log("===>> REFRESH FORMAT <<===")
  }

  categirie2afis(){
    this.categorii2 = []
    this.getCatfromService()
    console.log("categorie 2 afisa ==> ",this.categorii2)
  }

  AddCat(){
    console.log("new cat => ", this.newcat)
    this.catService.addcategorieservicii(this.newcat);
    this.categorii = this.catService.getcategoriiservicii();
    this.dataSource = new MatTableDataSource(this.categorii);
    this.newcat = new TipuriDeServicii();
  }

}
