<div style="padding:20px;height:100%;width:96%">
  <!-- <div>
    <mat-form-field >
      <mat-label>Servicii</mat-label>
      <input type="text"
             placeholder="Selecteaza Serviciu"
             aria-label="Servicii"
             matInput
             [formControl]="myControl"
             [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </div> -->
  <div style="width: 100%; display: flex;">
    <div *ngIf="!hidePacientTable" style="width: 70%;">
        <table style="width: 100%;" mat-table [dataSource]="pacientdataSource" class="mat-elevation-z8">
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
              <mat-grid-list [cols]="1" rowHeight="35px">
                <mat-grid-tile class =" borduricaDreapta">
                  ID
                </mat-grid-tile>
                <mat-grid-tile class =" borduricaDreapta">
                  <mat-form-field class="filter" floatLabel="never" >
                    <mat-label>Cauta</mat-label>
                    <input matInput ngModel [formControl]="filter_id_pacient" >
                  </mat-form-field>
                </mat-grid-tile>
              </mat-grid-list>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
          <!-- Nume Column -->
          <ng-container matColumnDef="nume">
            <th mat-header-cell *matHeaderCellDef>
              <mat-grid-list [cols]="1" rowHeight="35px">
                <mat-grid-tile class =" borduricaDreapta">
                  Nume
                </mat-grid-tile>
                <mat-grid-tile class =" borduricaDreapta">
                  <mat-form-field class="filter" floatLabel="never" style="width: 100%">
                    <mat-label>Cauta</mat-label>
                    <input matInput ngModel [formControl]="filter_nume_pacient">
                  </mat-form-field>
                </mat-grid-tile>
              </mat-grid-list>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.date_personale.nume}} </td>
          </ng-container>
          <!-- Prenume Column -->
          <ng-container matColumnDef="prenume">
            <th mat-header-cell *matHeaderCellDef>
              <mat-grid-list [cols]="1" rowHeight="35px">
                <mat-grid-tile class =" borduricaDreapta">
                  Prenume
                </mat-grid-tile>
                <mat-grid-tile class =" borduricaDreapta">
                  <mat-form-field class="filter" floatLabel="never"style="width: 100%">
                    <mat-label>Cauta</mat-label>
                    <input matInput ngModel [formControl]="filter_nume_pacient">
                  </mat-form-field>
                </mat-grid-tile>
              </mat-grid-list>
              </th>
            <td mat-cell *matCellDef="let element"> {{element.date_personale.prenume}} </td>
          </ng-container>
          <!-- CNP Column -->
          <ng-container matColumnDef="CNP">
            <th mat-header-cell *matHeaderCellDef>
              <mat-grid-list [cols]="1" rowHeight="35px">
                <mat-grid-tile class =" borduricaDreapta">
                  CNP
                </mat-grid-tile>
                <mat-grid-tile class =" borduricaDreapta">
                  <mat-form-field class="filter" floatLabel="never"style="width: 100%">
                    <mat-label>Cauta</mat-label>
                    <input matInput ngModel [formControl]="filter_cnp_pacient">
                  </mat-form-field>
                </mat-grid-tile>
              </mat-grid-list>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.date_personale.cnp}} </td>
          </ng-container>
          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>
              <mat-grid-list [cols]="1" rowHeight="35px">
                <mat-grid-tile class =" borduricaDreapta">
                  Email
                </mat-grid-tile>
                <mat-grid-tile class =" borduricaDreapta">
                  <mat-form-field class="filter" floatLabel="never"style="width: 100%">
                    <mat-label>Cauta</mat-label>
                    <input matInput ngModel [formControl]="filter_email_pacient">
                  </mat-form-field>
                </mat-grid-tile>
              </mat-grid-list>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.date_contact.email}} </td>
          </ng-container>
          <!-- Telefon Column -->
          <ng-container matColumnDef="telefon">
            <th mat-header-cell *matHeaderCellDef>
              <mat-grid-list [cols]="1" rowHeight="35px">
                <mat-grid-tile class =" borduricaDreapta">
                   Telefon
                  </mat-grid-tile>
                  <mat-grid-tile class =" borduricaDreapta">
                    <mat-form-field class="filter" floatLabel="never"style="width: 100%">
                      <mat-label>Cauta</mat-label>
                      <input matInput ngModel [formControl]="filter_telefon_pacient">
                    </mat-form-field>
                  </mat-grid-tile>
                </mat-grid-list>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.date_contact.telefon}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectPacient(row.id)"
            [ngClass]="{'selectedpacient': row.id == selectedPacientId  }"></tr>
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Nu am gasit nimic</td>
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>

    <mat-card class="example-card" style="width: 70%;max-height: 350px;text-align: center;" *ngIf= "hidePacientTable">
      <mat-card-header style="padding-left: 25%;">
        <span mat-card-avatar style="font-size: 45px;    color: #3f51b5;" class="material-icons">
          account_circle
          </span>
        <mat-card-title > {{ selectedpacient.date_personale.nume + ' ' + selectedpacient.date_personale.prenume }} </mat-card-title>
        <mat-card-subtitle> {{ (selectedpacient.date_personale.data_nasterii | date: 'd.MM.YYYY') + " -- " + varsta_cronologica + " ani" }} </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <strong>
        	<mat-grid-list  cols="3" rowHeight="50px"  [style.border] = '30' >
        	  <mat-grid-tile class = "bordurica-mat-grid-list"  >

        	     {{ (selectedpacient.orar_de_somn)?("Interval de somn: " + (selectedpacient.program_de_somn[0].start | date:'H:mm') + " - " + (selectedpacient.program_de_somn[0].end | date:'H:mm') ):"Nu are un program de somn" }}
        	  </mat-grid-tile>
        	  <mat-grid-tile class = "bordurica-mat-grid-list">
        	    {{ (selectedpacient.date_contact.telefon)?("Telefon: " + selectedpacient.date_contact.telefon):'' }}
        	  </mat-grid-tile>
        	  <mat-grid-tile class = "bordurica-mat-grid-list">
        	    {{ (selectedpacient.date_contact.email)?("Email: " + selectedpacient.date_contact.email):'' }}
        	  </mat-grid-tile>
        	  <mat-grid-tile class = "bordurica-mat-grid-list">
        	    {{ (selectedpacient.independent)?("Gradul de dependenta: Independent"):("Gradul de dependenta: Dependent") }}
        	  </mat-grid-tile>
        	  <mat-grid-tile class = "bordurica-mat-grid-list">
        	    {{ (selectedpacient.greutate)?("Greutatea: " + selectedpacient.greutate + " kg"):"" }}
        	  </mat-grid-tile>
        	  <mat-grid-tile class = "bordurica-mat-grid-list">
        	    {{ (selectedpacient.inaltime)?("Inaltime: " + selectedpacient.inaltime + " cm"):"" }}
        	  </mat-grid-tile>
        	</mat-grid-list>

        </strong>

      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button (click)="openPacientDetailsComponent()" >Vezi detalii</button>
        <button mat-raised-button (click)="hidePacientTable = !hidePacientTable">Selecteaza alt pacient</button>
      </mat-card-actions>
    </mat-card>

    <div style="padding-left: 2%;width: 30%;">

      <mat-form-field>
        <mat-label>Terapeut</mat-label>
        <mat-select [(value)] = selectedMedici >
            <mat-option *ngFor="let medic of medici" [value]="medic">
                {{medic.nume + ' ' + medic.prenume}}
            </mat-option>
        </mat-select>
        </mat-form-field>

      <mat-form-field>
        <mat-label>Servicii</mat-label>
        <mat-select [(value)] = selectedservicii >
            <mat-option *ngFor="let serviciu of servicii" [value]="serviciu">
                {{serviciu.denumire}}
            </mat-option>
        </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Departamente</mat-label>
          <mat-select [(value)] = selectedDepartamente >
              <mat-option *ngFor="let departament of departamente" [value]="departament">
                  {{departament.denumire}}
              </mat-option>
          </mat-select>
          </mat-form-field>
    </div>
  </div>
  <div fxFlexAlign="stretch" align="end" style="padding: 2%;">
  <button mat-raised-button color="primary" >Reset</button>
  <button mat-raised-button color="primary" (click)="openCalendarFlag = !openCalendarFlag" style="margin-left: 60%;" >Cauta fereastra libera</button>
</div>


  <ng-container *ngIf="openCalendarFlag">
    <mwl-demo-component [pacientid]="selectedPacientId" [medic]="selectedMedici"
      (dateclicked)="programarenoua($event)"
      >
    </mwl-demo-component>
  </ng-container>

  <!-- <wl-demo-component></wl-demo-component> -->
