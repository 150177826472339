<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> Scala Tinetti – evaluarea echilibrului și a mersului</h2></span>
<br>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Nume: {{ (fisa_evaluare_mers.nume_pacient != "") ? fisa_evaluare_mers.nume_pacient : "Nespecificat" }} </span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Prenume: {{ (fisa_evaluare_mers.prenume_pacient != "") ? fisa_evaluare_mers.prenume_pacient : "Nespecificat" }} </span>

<!-- <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Varsta: {{ (fisa_evaluare_mers.varsta_pacient != "") ? fisa_evaluare_mers.varsta_pacient : "Nespecificat" }} </span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Data: {{ (fisa_evaluare_mers.data_evaluare != "") ? fisa_evaluare_mers.data_evaluare : "Nespecificat" }} </span>  -->

<table mat-table [dataSource]="dataSourceTestulDeEchilibru" style="width: 100%;">

	<ng-container matColumnDef="Titlu">
		<th mat-header-cell *matHeaderCellDef> {{ fisa_evaluare_mers.testul_de_echilibru.titlu }}
            <br>
            {{ fisa_evaluare_mers.testul_de_echilibru.detalii}}
        </th>
		<td mat-cell *matCellDef="let element"> <b> {{element.titlu}} </b>
            <ng-container *ngFor=" let details of element.detalii " >
                <br>
                {{ details.numar_puncte + " " + details.mentiune_puncte }}
            </ng-container>
        </td>
    <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        Scor echilibru:
	</td>
    </ng-container>

    <ng-container matColumnDef="Scor">
		<th mat-header-cell *matHeaderCellDef> Scor  </th>
		<td mat-cell *matCellDef="let element">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'redfundal': element.selectat > 0, 'notallowed': element.selectat == 0  }"
            (click) = "((element.selectat > 0)?(element.selectat = (numberedversion(element.selectat) - 1).toString()): undefined) ;refreshDS()"
            >remove</mat-icon>
            <input matInput type="number" style="width: 25px; text-align: center; vertical-align: middle;" [(ngModel)]="element.selectat">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'greenfundal': element.selectat < element.detalii.length-1, 'notallowed': element.selectat == element.detalii.length-1  }"
            (click) = "((element.selectat < element.detalii.length-1)?(element.selectat = (numberedversion(element.selectat) + 1).toString()): undefined) ;refreshDS()"
            >add</mat-icon>
            &nbsp;&nbsp;&nbsp;
            <mat-slider
            [max]="element.detalii.length-1" [(ngModel)]="element.selectat" [min]="0" [thumbLabel]="true" [step]="1" [tickInterval]="1" (change)="change($event)" >
            </mat-slider>
        </td>
    <td mat-footer-cell *matFooterCellDef style="text-align: center">
        {{"  " + getTotalEchilibru() }} / 16
	</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedcolumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedcolumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedcolumns;"></tr>

</table>

<table mat-table [dataSource]="dataSourceTestulDeMers" style="width: 100%;">

	<ng-container matColumnDef="Titlu">
		<th mat-header-cell *matHeaderCellDef> {{ fisa_evaluare_mers.testul_de_mers.titlu }}
            <br>
            {{fisa_evaluare_mers.testul_de_mers.detalii }}
        </th>
		<td mat-cell *matCellDef="let element"> <b> {{element.titlu}} </b>
            <ng-container *ngFor=" let details of element.detalii " >
                <br>
                {{ details.numar_puncte + " " + details.mentiune_puncte }}

            </ng-container>
        </td>
    <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        <b>
            Scor mers:
        </b>
	</td>
    </ng-container>

    <ng-container matColumnDef="Scor">
		<th mat-header-cell *matHeaderCellDef> Scor  </th>
		<td mat-cell *matCellDef="let element">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'redfundal': element.selectat > 0, 'notallowed': element.selectat == 0  }"
            (click) = "((element.selectat > 0)?(element.selectat = (numberedversion(element.selectat) - 1).toString()): undefined) ;refreshDS()"
            >remove</mat-icon>
            <input matInput type="number" style="width: 25px; text-align: center; vertical-align: middle; " [(ngModel)]="element.selectat">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'greenfundal': element.selectat < element.detalii.length-1, 'notallowed': element.selectat == element.detalii.length-1  }"
            (click) = "((element.selectat < element.detalii.length-1)?(element.selectat = (numberedversion(element.selectat) + 1).toString()): undefined) ;refreshDS()"
            >add</mat-icon>
            &nbsp;&nbsp;&nbsp;
            <mat-slider
            [max]="element.detalii[element.detalii.length-1].numar_puncte" [(ngModel)]="element.selectat" [min]="0" [thumbLabel]="true" [step]="1" [tickInterval]="1" (change)="changeTestDeMers($event)" >
            </mat-slider>
        </td>
    <td mat-footer-cell *matFooterCellDef style="text-align: center">
        {{"  " + getTotalMers() }} / 11
	</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedcolumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedcolumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedcolumns;"></tr>

</table>
<ng-container *ngIf="caz == 1">
  <button mat-raised-button (click)="semneazaRL()" > Semnatura Reprezentant Legal</button>
  <button mat-raised-button (click)="semneazaTerapeut()"  > Semnatura Terapeut</button>
  <button mat-raised-button (click)="openPDFPreviewComponent()" > Previzualizeaza </button>
</ng-container>
<ng-container *ngIf="caz == 2">
  <button mat-raised-button (click)="openPDFPreviewSsaveModificari()"  > Genereaza Document </button>
  <button mat-raised-button color="warn"  mat-dialog-close > Inchide </button>
</ng-container>

<!-- <button mat-raised-button (click)="generatePDF()" > Salveaza </button> -->
<!-- <button mat-raised-button > Salveaza</button> -->
