
<table style="width: 50%;" mat-table [dataSource]="pacientdataSource" class="mat-elevation-z8">
  <!-- ID Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> ID
      <div *ngIf="!pacientselectatflag"  >
        <mat-form-field class="filter" floatLabel="never">
        <mat-label>Cauta</mat-label>
        <input matInput [formControl]="idFilter">
      </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>
  <!-- Nume Column -->
  <ng-container matColumnDef="nume">
    <th mat-header-cell *matHeaderCellDef> Nume
      <div *ngIf="!pacientselectatflag"  >
        <mat-form-field class="filter" floatLabel="never">
          <mat-label>Cauta</mat-label>
          <input matInput [formControl]="numeFilter">
        </mat-form-field>
      </div>
    </th>

    <td mat-cell *matCellDef="let element"> {{element.date_personale.nume}} </td>
  </ng-container>
  <!-- Prenume Column -->
  <ng-container matColumnDef="prenume">
    <th mat-header-cell *matHeaderCellDef> Prenume
      <div *ngIf="!pacientselectatflag"  >
        <mat-form-field class="filter" floatLabel="never">
        <mat-label>Cauta</mat-label>
        <input matInput [formControl]="prenumeFilter">
      </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.date_personale.prenume}} </td>
  </ng-container>
  <!-- CNP Column -->
  <ng-container matColumnDef="CNP">
    <th mat-header-cell *matHeaderCellDef> CNP
      <div *ngIf="!pacientselectatflag"  >
        <mat-form-field class="filter" floatLabel="never">
        <mat-label>Cauta</mat-label>
        <input matInput [formControl]="cnpFilter">
      </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.date_personale.cnp}} </td>
  </ng-container>
  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element"> {{element.date_contact.email}} </td>
  </ng-container>
  <!-- Telefon Column -->
  <ng-container matColumnDef="telefon">
    <th mat-header-cell *matHeaderCellDef> Telefon </th>
    <td mat-cell *matCellDef="let element"> {{element.date_contact.telefon}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectPacient(row)"
    [ngClass]="{'selectedpacient': row.id == selectedPacientId  }"></tr>
  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">Nu am gasit nimic</td>
  </tr>
</table>
<mat-paginator style="width: 50%;" *ngIf="!pacientselectatflag" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

<button mat-raised-button (click)="opencasierieservicii()" *ngIf="pacientselectatflag" style="padding-left: 20px;"> Adauga serviciu</button>
<button mat-raised-button (click)="resetPacient()" *ngIf="pacientselectatflag" style="padding-left: 20px;"> Reset Beneficiar</button>
<button mat-raised-button (click)="getcasieriedataforuser()" *ngIf="pacientselectatflag"  style="padding-left: 20px;"> Afiseaza Info Financiare</button>
<div *ngIf="casierieflag">
<table style="width: 80%;" mat-table [dataSource]="serviciideplata" class="mat-elevation-z8">
  <!-- ID Column -->
  <ng-container matColumnDef="data">
    <th mat-header-cell *matHeaderCellDef> Data </th>
    <td mat-cell *matCellDef="let element"> {{incasareService.parsedata(element.dataefectuarii) | date:'dd.MM.y'}} </td>
  </ng-container>
    <!-- Ora Column -->
    <ng-container matColumnDef="ora">
      <th mat-header-cell *matHeaderCellDef> Ora </th>
      <!-- <td mat-cell *matCellDef="let element"> {{incasareService.parsetime(element.dataefectuarii) | date:'HH:mm'}} //---===>> {{element.dataefectuarii | date:'HH:mm'}} //---===222>> {{element.dataefectuarii}}  </td> -->
      <td mat-cell *matCellDef="let element"> {{ incasareService.parsetime(element.dataefectuarii) | date:'HH:mm'  }}  </td>
    </ng-container>
      <!-- Nume Column -->
  <ng-container matColumnDef="terapeut">
    <th mat-header-cell *matHeaderCellDef> Terapeut </th>
    <td mat-cell *matCellDef="let element"> {{returndenumiremedici(element.medicid)}} </td>
  </ng-container>
  <!-- Nume Column -->
  <ng-container matColumnDef="denumire">
    <th mat-header-cell *matHeaderCellDef> Denumire </th>
    <td mat-cell *matCellDef="let element"> {{returndenumireserviciu(element.serviciuid)}} </td>
  </ng-container>
  <!-- Prenume Column -->
  <ng-container matColumnDef="pret">
    <th mat-header-cell *matHeaderCellDef> Pret </th>
    <td mat-cell *matCellDef="let element"> {{element.pret}} RON </td>
  </ng-container>
  <!-- Discount Column -->
  <ng-container matColumnDef="discount">
    <th mat-header-cell *matHeaderCellDef> Discount </th>
    <td mat-cell *matCellDef="let element"> {{element.discount}} % </td>
  </ng-container>
    <!-- Total Column -->
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef> Total </th>
      <td mat-cell *matCellDef="let element"> {{element.total}} RON</td>
    </ng-container>
  <!-- Email Column -->
  <ng-container matColumnDef="platit">
    <th mat-header-cell *matHeaderCellDef> Platit </th>
    <td mat-cell *matCellDef="let element"> {{element.platit?element.platit:' 0 '}} RON</td>
  </ng-container>
  <!-- Telefon Column -->
  <ng-container matColumnDef="sold">
    <th mat-header-cell *matHeaderCellDef> Sold </th>
    <td mat-cell *matCellDef="let element"> {{element.total - element.platit}} RON </td>
  </ng-container>
  <ng-container matColumnDef="selectincaseaza">
    <th mat-header-cell *matHeaderCellDef>
      <button  mat-raised-button color="warn" [disabled]="marcatepentruincasat.length == 0" (click)="incaseazaservicii()" > Incaseaza </button>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox (change)="marcheazaserviciul(element)" *ngIf="(element.total - element.platit)>0"></mat-checkbox>
    </td>

  </ng-container>

  <ng-container matColumnDef="achitat">
    <th mat-header-cell *matHeaderCellDef> Achitat </th>
    <td mat-cell *matCellDef="let element"> {{  checkachitat(element.total, element.platit) }} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumnstwo"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnstwo;"></tr>
  <!-- Row shown when there is no matching data. -->
</table>
<mat-paginator *ngIf="pacientselectatflag" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

</div>
