import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { logo_url_base64 } from 'src/app/models/logo-base64.model';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PdfpreviewerComponent } from 'src/app/pdfpreviewer/pdfpreviewer.component';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-contract-furnizor-servicii-sociale',
  templateUrl: './contract-furnizor-servicii-sociale.component.html',
  styleUrls: ['./contract-furnizor-servicii-sociale.component.css']
})
export class ContractFurnizorServiciiSocialeComponent implements OnInit {
  @Output () fileid = new EventEmitter<string>();
  // nume,
  // prenume,
  // localitate,
  // strada,
  // numar,
  // judet,
  // cnp,
  // serie ci,
  // nr ci,
  // data eliberare ci,
  // sectie de politie ci,
  // nume reprezentant,
  // prenume reprezentant,
  // localitate reprezentant,
  // strada reprezentant,
  // numar reprezentant,
  // judet reprezentant,
  // serie ci,
  // nr ci,
  // data eliberare ci,
  // sectie de politie ci,
  // conform reprezentant,
  // nr plan interventie,
  // data plan interventie,
  // cost total lunar pct 2,
  // cost servicii sociale= [ "kinetoterapie", "psihopedagogie speciala", "logopedie", "hidroterapie", "Therasuit", "transport adaptat", "consiliere psihologica", "hipoterapie" ]
  // contributie servicii sociale= [ "kinetoterapie", "psihopedagogie speciala", "logopedie", "hidroterapie", "Therasuit", "transport adaptat", "consiliere psihologica", "hipoterapie" ]
  // serviciiSociale = [ "kinetoterapie", "psihopedagogie speciala", "logopedie", "hidroterapie", "Therasuit", "transport adaptat", "consiliere psihologica", "hipoterapie" ]
  // data inceput,
  // data sfarsit,
  // perioada nedeterminata
  constructor(
    public dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    this.openPDFPreviewComponent()

  }
  openPDFPreviewComponent(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      // console.log("data din pdfgenerator base 64 ==>> ",data);
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      });
    })
  }
  generatePDF() {
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
      height: 60,
      columns:
        [
          // {
          //   image: logo_url_base64,
          //   width: 50,
          //   alignment: 'center',
          // },
          {
            stack: [
              "Fundația Mihai Neșu Foundation",
              "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
            ],
            margin: 10,
            style: 'textheader'
          }
        ],
      margin: [0, 0, 0, 90]
    },
    content: [
      {
        canvas: [
          {
            type: 'line',
            x1: -100, y1: 0,
            x2: 600, y2: 0,
            lineWidth: 1,
            lineCap: 'square'
          },
          {
            type: 'line',
            x1: -100, y1: 4,
            x2: 600, y2: 4,
            lineWidth: 1,
            lineCap: 'square'
          }
        ]
      },
      {
        image: logo_url_base64,
        width: 200,
        alignment: 'center',
      },
      {
        stack: [
          "ORDIN nr. 73 din 17 februarie 2005 privind aprobarea modelului Contractului pentru acordarea de servicii sociale, incheiat de furnizorii de servicii sociale, acreditati conform legii, cu beneficiarii de servicii sociale"
        ],
          bold: true,
          alignment: 'center',
          fontSize: 16,
          margin: [0, 50, 0, 80]
      },
      {
        text: "EMITENT: MINISTERUL MUNCII, SOLIDARITATII SOCIALE SI FAMILIEI ",
        alignment: 'center',
        fontSize: 14,
        bold:true,
        // decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "PUBLICAT IN: MONITORUL OFICIAL nr. 176 din 1 martie 2005",
        alignment: 'center',
        fontSize: 14,
        bold:true,
        // decoration:'underline',
        margin: [0, 10, 0, 10]
      },
      {
        text: "Avand in vedere prevederile art. 13 din Ordonanta Guvernului nr. 68/2003 privind serviciile sociale, aprobata cu modificari si completari prin Legea nr. 515/2003, cu modificarile si completarile ulterioare, in temeiul art. 17 din Hotararea Guvernului nr. 737/2003 privind organizarea si functionarea Ministerului Muncii, Solidaritatii Sociale si Familiei, cu modificarile si completarile ulterioare, ministrul muncii, solidaritatii sociale si familiei emite urmatorul ordin:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Art.1",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Se aproba modelul Contractului pentru acordarea de servicii sociale, incheiat de furnizorii de servicii sociale, acreditati conform legii, cu beneficiarii de servicii sociale, prevazut in anexa care face parte integranta din prezentul ordin.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Art.2",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "(1) Contractul pentru acordarea de servicii sociale, incheiat de furnizorii de servicii sociale cu beneficiarii de servicii sociale, va cuprinde cel putin clauzele prevazute in modelul de contract.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "(2) Prin negociere intre parti, contractul pentru acordarea de servicii sociale poate cuprinde si clauze specifice, fara incalcarea prevederilor legale in vigoare.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Art.3",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Partile contractante vor modifica si vor completa, dupa caz, contractele pentru acordarea de servicii sociale, aflate in desfasurare la data intrarii in vigoare a prezentului ordin, in conformitate cu modelul de contract, pana la data de 31 iunie 2005.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Art.4",
        alignment: 'left',
        fontSize: 14,
        bold:true,
        decoration:'underline',
        margin: [0, 0, 0, 20]
      },
      {
        text: "Prezentul ordin se publica in Monitorul Oficial al Romaniei, Partea I.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 30]
      },
      {
        text: "Ministrul muncii, solidaritatii sociale si familiei,",
        alignment: 'center',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Gheorghe Barbu",
        alignment: 'center',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "CONTRACT pentru acordarea de servicii sociale",
        alignment: 'center',
        bold:true,
        fontSize: 16,
        margin: [0, 20, 0, 20]
      },
      {
        text: "Partile contractante:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 30]
      },
      {
        text: "1. Fundația Mihai Neșu Foundation cu sediul social în Oradea, str. Emil Palade, nr.55, jud. Bihor, având Cod Fiscal 32896090, Cont Iban: RO63BTRLRONCRT0246793101 deschis la Banca Transilvania, sucursala Oradea și Cont Euro: RO13BTRLEURCRT0246793101, reprezentată prin director executiv Maria-Iulia Băcăran, certificatul de acreditare seria AF. nr. 002688;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "si",
        alignment: 'center',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "2. ............................................(numele beneficiarului de servicii sociale)....... denumit in continuare beneficiar, domiciliat/locuieste in localitatea ......................, str. ................................ nr. ......, judetul/sectorul ......................., codul numeric personal ..................................., posesor al B.I./C.I. seria .... nr. .............., eliberat/eliberata la data de ...................... de Sectia de politie .................., reprezentat prin domnul/doamna ......................................, domiciliat/domiciliata in localitatea .........................., str. ...................... nr. ...., judetul/sectorul ................., posesor/posesoare al/a B.I./C.I. seria ...... nr. ......, eliberat/eliberata la data de .............. de Sectia de politie ....................., conform ........... (se va mentiona actul care atesta calitatea de reprezentant) ...... nr. ...../data ..................",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1. avand in vedere:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- planul de interventie nr. ......../data ...........;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- evaluarea complexa efectuata in perioada .....................................................;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "2. convin asupra urmatoarelor:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1. Definitii:",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.1. contractul pentru furnizarea de servicii sociale - actul juridic incheiat intre o persoana fizica sau juridica, publica ori privata, acreditata conform legii sa acorde servicii sociale, denumita furnizor de servicii sociale, si o persoana fizica aflata in situatie de risc sau de dificultate sociala, denumita beneficiar de servicii sociale, care exprima acordul de vointa al acestora in vederea acordarii de servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.2. furnizor de servicii sociale - persoana fizica sau juridica, publica ori privata, acreditata conform legii in vederea acordarii de servicii sociale, prevazuta la art. 11 din Ordonanta Guvernului nr. 68/2003 privind serviciile sociale, aprobata cu modificari si completari prin Legea nr. 515/2003, cu modificarile si completarile ulterioare;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.3. beneficiar de servicii sociale - persoana aflata in situatie de risc si de dificultate sociala, impreuna cu familia acesteia, care necesita servicii sociale, conform planului de interventie revizuit in urma evaluarii complexe;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: " 1.4. servicii sociale - ansamblu de masuri si actiuni realizate pentru a raspunde nevoilor sociale individuale, familiale sau de grup, in vederea prevenirii si depasirii unor situatii de dificultate, vulnerabilitate sau de dependenta pentru prezervarea autonomiei si protectiei persoanei, pentru prevenirea marginalizarii si excluziunii sociale, pentru promovarea incluziunii sociale si in scopul cresterii calitatii vietii, definite in conditiile prevazute de Ordonanta Guvernului nr. 68/2003, aprobata cu modificari si completari prin Legea nr. 515/2003, cu modificarile si completarile ulterioare;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.5. reevaluarea situatiei beneficiarului de servicii sociale - activitatea obligatorie a furnizorului de servicii sociale de a evalua situatia beneficiarului de servicii sociale dupa acordarea de servicii sociale pe o anumita perioada;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.6. revizuirea sau completarea planului individualizat de recuperare - modificarea sau completarea adusa planului individualizat pe baza rezultatelor reevaluarii situatiei beneficiarului de servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.7. contributia beneficiarului de servicii sociale - cota-parte din costul total al serviciului/ serviciilor acordat/acordate de furnizorul de servicii sociale, in functie de tipul serviciului si de situatia materiala a beneficiarului de servicii sociale, si care poate fi in bani, in servicii sau in natura;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.8. obligatiile beneficiarului de servicii sociale - totalitatea indatoririlor pe care beneficiarul de servicii sociale si le asuma prin contract si pe care le va indeplini valorificandu-si maximal potentialul psiho-fizic;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.9. standarde minimale de calitate - ansamblul de cerinte privind cadrul organizatoric si material, resursele umane si financiare in vederea atingerii nivelului de performanta obligatoriu pentru toti furnizorii de servicii sociale specializate, aprobate in conditiile legii;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.10. modificari de drept ale contractului de acordare de servicii sociale - modificarile aduse contractelor de acordare de servicii sociale in mod independent de vointa partilor, in temeiul prevederilor unui act normativ;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.11. forta majora - eveniment mai presus de controlul partilor, care nu se datoreaza greselii sau vinii acestora, care nu putea fi prevazut in momentul incheierii contractului si care face imposibila executarea si, respectiv, indeplinirea acestuia;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.12. evaluarea initiala - activitatea de identificare/determinare a naturii cauzelor, a starii actuale de dezvoltare si de integrare sociala a beneficiarului de servicii sociale, precum si a prognosticului acestora, efectuata prin utilizarea de metode si tehnici specifice profesiilor sociale, de catre furnizorul de servicii sociale. Scopurile evaluarii sunt cunoasterea si intelegerea problemelor cu care se confrunta beneficiarul de servicii sociale si identificarea masurilor initiale pentru elaborarea planului initial de masuri;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.13. planul individualizat de recuperare - ansamblul de masuri si servicii adecvate si individualizate potrivit nevoilor sociale identificate ca urmare a efectuarii evaluarii complexe, cuprinzand programarea serviciilor sociale, personalul responsabil si procedurile de acordare a serviciilor sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "1.14. evaluarea complexa - activitatea de investigare si analiza a starii actuale de dezvoltare si de integrare sociala a beneficiarului de servicii sociale, a cauzelor care au generat si care intretin situatia de dificultate in care acesta se afla, precum si a prognosticului acestora, utilizandu-se instrumente si tehnici standardizate specifice domeniilor: asistenta sociala, psihologic, educational, medical, juridic.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "2. Obiectul contractului",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "2.1.*) Obiectul contractului il constituie acordarea urmatoarelor servicii sociale:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "a) kinetoterapie",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "b) psihopedagogie speciala",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "c) logopedie",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "d) hidroterapie",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "e) Therasuit",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "f) transport adaptat",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "g) consiliere psihologica",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "h) hipoterapie",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "*) Lista serviciilor sociale ce vor fi acordate poate fi prevazuta cu acordul partilor in anexa la contract.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "2.2.*) Descrierea serviciilor sociale acordate de furnizorul de servicii sociale:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "a) ședințele de kinetoterapie sunt individuale și durează 50 min ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "b) ședințele de psihopedagogie speciala sunt individuale și durează 50 min",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "c) ședințele de logopedie sunt individuale și durează 50 min    ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "d) ședințele de hidroterapie sunt individuale și durează 40 min, se desfășoar la piscina Hotelului Maxim ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "e) sesiunile de Therasuit sunt individuale, durează 3h/zi, cu pauze de 10 minute după fiecare 50 min, se fac pentru o perioadă de 3 săptămâni și se programează cu un an înainte. Spre deosebire de alte terapii se solicită și o radiografie de șold care nu poate fi mai veche de 12 luni. În urma documentației medicale terapeuții fundației confirmă sau nu dacă terapia este potrivită copilului.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "f) transportul adaptat poate fi accesat atât de copiii cu dizabilități cât și de adulți. Documentele necesare sunt copie după buletinul și certificaul persoanei cu handicap și copie după buletinul însoțitorului. ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "g) serviciul de consiliere psihologică este dedicat părintelui care are un copil cu dizabilități, beneficiar al Fundației Mihai Neșu și copiilor care sunt în situația de a putea beneficia de consiliere.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "*) Descrierea serviciilor sociale acordate poate fi prevazuta cu acordul partilor in anexa la contract.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "3. Costurile serviciilor sociale acordate si contributia beneficiarului de servicii sociale",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "3.1. Costul total pe luna al serviciilor sociale prevazute la pct. 2 este de: ............. lei/luna.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "3.2. *) Costul pe luna pentru fiecare serviciu social acordat este dupa cum urmeaza:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "a) kinetoterapie costul pe luna este de ...............",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "b) psihopedagogie speciala costul pe luna este de ...............",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "c) logopedie costul pe luna este de ...............",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "d) hidroterapie costul pe luna este de ...............",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "e) Therasuit costul pe sesiune este de ...............",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "f) transport adaptat costul pe transport este de ...............",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "g) consiliere psihologica costul pe sesiune este de ..... .",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "h) hipoterapie costul pe lună este de..........",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "*) Costurile serviciilor sociale pot fi prevazute cu acordul partilor in anexa la contract.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "3.3.**) Contributia beneficiarului pentru serviciile sociale primite este dupa cum urmeaza:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },,
      {
        text: "a) kinetoterapie costul pe luna este de 20 lei/ședință",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "b) psihopedagogie speciala costul pe luna este de 20 lei/ședință",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "c) logopedie costul pe luna este de 20 lei/ședință",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "d) hidroterapie costul pe luna este de 25 lei/ședință",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "e) Therasuit costul pe sesiune este de 1800 lei/sesiune",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "f) transport adaptat costul pe transport este de 10 lei/un transport în Oradea + 15 km în împrejurimi ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "g) consiliere psihologica costul pe sesiune este de 20 lei/ședință",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "h) hipoterapie costul pe lună este de gratuit",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "**) Valoarea contributiei beneficiarului de servicii sociale este stabilita in urma procesului de evaluare complexa.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "3.4. Contributia beneficiarului de servicii sociale nu va influenta acordarea serviciilor sociale si nu va ingreuna posibilitatea acestuia de a iesi din starea de dificultate.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "4. Durata contractului",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "4.1. Durata contractului este:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "4.1.1de la data de ......... pana la data de ....... .",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "4.1.2 pe o perioadă nedeterminată",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "4.2. Durata contractului poate fi prelungita cu acordul partilor si numai dupa evaluarea rezultatelor serviciilor acordate beneficiarului de servicii sociale si, dupa caz, revizuirea planului individualizat de recuperare.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "5. Etapele procesului de acordare a serviciilor sociale:",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "5.1. implementarea masurilor prevazute in planul de interventie si in planul individualizat;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "5.2. reevaluarea periodica a situatiei beneficiarului de servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "5.3. revizuirea planului individualizat de recuperare in vederea adaptarii serviciilor sociale la nevoile beneficiarului.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "6. Drepturile furnizorului de servicii sociale:",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "6.1. de a verifica veridicitatea informatiilor primite de la beneficiarul de servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "6.2. de a sista acordarea serviciilor sociale catre beneficiar in cazul in care constata ca acesta i-a furnizat informatii eronate;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "6.3. de a utiliza, in conditiile legii, date denominalizate in scopul intocmirii de statistici pentru dezvoltarea serviciilor sociale.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7. Obligatiile furnizorului de servicii sociale:",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7.1. sa respecte drepturile si libertatile fundamentale ale beneficiarului in acordarea serviciilor sociale, precum si drepturile beneficiarului de servicii sociale, rezultate din prezentul contract;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7.2. sa acorde servicii sociale prevazute in planul individualizat de recuperare, cu respectarea acestuia si a standardelor minimale de calitate a serviciilor sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7.3. sa depuna toate diligentele pentru a asigura beneficiarul de continuitatea serviciilor sociale furnizate, in cazurile de incetare a prezentului contract prevazute la pct. 12.1 si 13.1 lit. a) si d); asigurarea continuitatii serviciilor sociale se va realiza si prin subcontractare si cesiune de servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7.4. sa fie receptiv si sa tina cont de toate eforturile beneficiarului de servicii sociale in indeplinirea obligatiilor contractuale si sa considere ca beneficiarul si-a indeplinit obligatiile contractuale in masura in care a depus toate eforturile;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7.5. sa informeze beneficiarul de servicii sociale asupra:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- continutului serviciilor sociale si conditiilor de acordare a acestora;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- oportunitatii acordarii altor servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- listei la nivel local cuprinzand furnizorii acreditati sa acorde servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- regulamentului de ordine interna;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- oricarei modificari de drept a contractului;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7.6. sa reevalueze periodic situatia beneficiarului de servicii sociale, si, dupa caz, sa completeze si/sau sa revizuiasca planul individualizat de recuperare exclusiv in interesul acestuia;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7.7. sa respecte, conform legii, confidentialitatea datelor si informatiilor referitoare la beneficiarul de servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7.8. sa ia in considerare dorintele si recomandarile obiective ale beneficiarului cu privire la acordarea serviciilor sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7.9. sa utilizeze contributia beneficiarului de servicii sociale exclusiv pentru acoperirea cheltuielilor legate de acordarea serviciilor sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "7.10. de a informa serviciul public de asistenta in a carui raza teritoriala locuieste beneficiarul asupra nevoilor identificate si serviciilor sociale propuse a fi acordate.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "8. Drepturile beneficiarului",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "8.1. In procesul de acordare a serviciilor sociale prevazute la pct. 2, furnizorul de servicii sociale va respecta drepturile si libertatile fundamentale ale beneficiarului de servicii sociale.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "8.2. Beneficiarul are urmatoarele drepturi contractuale:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "a) de a primi servicii sociale prevazute in planul individualizat de asistenta si ingrijire;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "b) de a i se asigura continuitatea serviciilor sociale atat timp cat se mentin conditiile care au generat situatia de dificultate;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "c) de a refuza, in conditii obiective, primirea serviciilor sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "d) de a fi informat, in timp util si in termeni accesibili, asupra:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- drepturilor sociale, masurilor legale de protectie si asupra situatiilor de risc;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- modificarilor intervenite in acordarea serviciilor sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- oportunitatii acordarii altor servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- listei la nivel local cuprinzand furnizorii acreditati sa acorde servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "- regulamentului de ordine interna;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "e) de a participa la evaluarea serviciilor sociale primite si la luarea deciziilor privind interventia sociala care i se aplica, putand alege variante de interventie, daca ele exista;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "f) dreptul de a avea acces la propriul dosar;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "g) de a-si exprima nemultumirea cu privire la acordarea serviciilor sociale.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.*) Obligatiile beneficiarului:",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.1. sa participe activ in procesul de furnizare a serviciilor sociale si la reevaluarea si revizuirea planului individualizat de recuperare;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.2. sa furnizeze informatii corecte cu privire la identitatea si situatia familiala, medicala, economica si sociala si sa permita furnizorului de servicii sociale verificarea veridicitatii acestora;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.3. sa respecte termenele si clauzele stabilite in cadrul planului individualizat de asistenta si ingrijire;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.4. sa contribuie la plata costurilor serviciilor sociale primite cu o cota-parte/contributie din costul total al serviciului/serviciilor acordat/acordate de furnizor, conform pct. 3.3 si 3.4;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.5. sa anunte orice modificare intervenita in legatura cu situatia sa personala pe parcursul acordarii serviciilor sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.6. sa respecte regulamentul de ordine interna al furnizorului de servicii sociale (reguli de comportament, program, persoanele de contact etc.).",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "*) Obligatiile beneficiarului de servicii sociale se vor completa, dupa caz, cu alte obligatii specifice tipurilor de servicii sociale prevazute in prezentul contract.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.7.  de a folosi un vocabular corespunzător, moral, decent pe perioada cât se află la Centru. Obligația de a se abține în a folosi un vocabular agresiv, cuvinte obscene, injurii.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.8. de a avea un comportament corespunzător, adecvat, deschis, bazat pe respect atât față de terapeuții angajați de Centru cât și față de persoanele care muncesc cu titlu voluntar pentru Centrul de Recuperare.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.9. de a cere acordul terapeutului sau conducerii Fundației Mihai Neșu pentru orice obiect luat din incinta Centrului sau pentru orice obiect adus în Centru de Recuperare.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.10. de a nu introduce în incinta Centrului obiecte ascuțite, periculoase, dăunătoare care ar putea pune în primejdie viața sau sănătatea copiilor care urmează programul de recuperare medicală. De asemenea de a nu introduce în incinta Centrului de Recuperare produsele, lichidele, substanțele strict interzise prin Regulamentul de Ordine Interioară. ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.11. de a intra în incinta Centrului doar cu papucii de plastic de unică folosință puși la dispoziție de Fundația Mihai Neșu sau cu papucii  personali de schimb.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.12. de a respecta normele de igienă prevăzute de Regulamentul de ordine Interioară a Centrului de Recuperare.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.13  de a folosi scaunele rulante doar în spațiile destinate acestora.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.14. de a nu utiliza echipamentele medicale decât în prezența personalului avizat și conform instrucțiunilor de folosire.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.15. de a-și supraghea copilul pe perioada cât se află la Centru, de a-l susține, de  a-l însoți pe rampa de deplasare, la toaleta sau în alte locuri din incintă.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.16.  de a respecta programul de parcare afișat în fața Centrului.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.17. de a nu intra în polemică cu vecinii de la blocurile de lângă Centru de Recuperare.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.18. de a aduce la cunoștința conducerii orice reclamație, neregularitate, nemulțumire  apărută pe parcursul derulării contractului, indiferent de natura acesteia.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.19. obligația de a manifesta seriozitate prin respectarea programului stabilit, a orelor de începere a ședințelor de recuperare precum și obligația de a fi prezenți cu cel puțin 10 minute înainte de ora stabilită. În caz de întârziere, se va scădea din timpul acordat copilului, ședința nu se va extinde peste programarea alocată altui copil. ",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.20. obligația de a semna condica de prezență după fiecare ședință la care au participat.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.21. de a anunța în timp util conducerea Fundației despre ivirea oricărui eveniment care ar putea pune copilul în imposibilitate de a se prezenta la ședințele de recuperare, aceasta pentru a da posibilitatea altor copii să beneficieze în acea perioadă de ședințele de kinetoterapie/psihopedagogie speciala/hidroterapie/hipoterapie",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.21. de a conveni cu terapeutul orice modificare a programului stabilit astfel încât să fie asigurate ședințele lunare. Dacă datorită unor motive temeinice părintele nu își poate aduce copilul la ședințele de terapie, acestea vor putea fi recuperate pe parcursul lunii în curs prin acordul comun al părintelui, terapeutului și conducerii Fundației.  Recuperarea unei ședințe pierdute poate fi făcută și în luna imediat următoare dacă programul terapeutului permite acest lucru. Recuperarea a două ședințe se poate face în luna imediat următoare doar dacă se creează o fereastră datorită retragerii sau absentării unui alt copil.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.22. obligația de achita în ultimele 15 zile ale fiecărei luni contribuția benevolă în cuantum cu titlu de sponsorizare. Suma va fi achitată in exclusivitate in numerar la sediul Fundatiei Mihai Nesu.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.22. obligația părinților de a se implica în evenimentele caritabile, de strângere de fonduri organizate de către Fundație în scopul susținerii financiare a Centrului de Recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.22. obligația  părinților de a coopera între ei, de a fi uniți, de a contribui împreună la bunul mers al Centrului de Recuperare.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.23. obligația părinților de a cere acordul scris al conducerii Fundației Mihai Neșu Foundation pentru orice menționare publică a numelui fundației.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.24. obligația de a cere acordul scris al oricărui angajat din cadrul Fundaței Mihai Neșu pentru a-l fotografia sau filma, indiferent cu ce scop dorește acest lucru, respectând astfel art. 74 lit. c) din Noul Cod Civil.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "9.25. de a se învoi prin cerere scrisă în perioada în care se află înscriși sau internați la altă instituție medicală din țară și de a înțelege că Fundația Mihai Neșu nu aprobă recuperarea în cadrul centrului, concomitent cu recuperarea în cadrul altui centru din țară , din respect pentru regulile administrative ale fiecărei insituții medicale și pentru eficiența terapiei executate în cadrul Centrului de Recuperare pentru copii cu dizabilități neuromotorii ”Sfântul Nectarie”",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "10.*) Solutionarea reclamațiilor",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "10.1. Beneficiarul are dreptul de a formula verbal si/sau in scris reclamatii cu privire la acordarea serviciilor sociale.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "10.2. Reclamatiile pot fi adresate furnizorului de servicii sociale direct sau prin intermediul oricarei persoane din cadrul echipei de implementare a planului individualizat.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "10.3. Furnizorul de servicii sociale are obligatia de a analiza continutul reclamatiilor, consultand atat beneficiarul de servicii sociale, cat si specialistii implicati in implementarea planului individualizat de recuperare si de a formula raspuns in termen de maximum 10 zile de la primirea reclamatiei.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "10.4. Daca beneficiarul de servicii sociale nu este multumit de solutionarea reclamatiei, acesta se poate adresa in scris Comisiei de mediere sociala de la nivelul judetului/sectorului ......, care va clarifica prin dialog divergentele dintre parti sau, dupa caz, instantei de judecata competente.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "*) Furnizorul de servicii sociale va avea inscrisa in regulamentul de ordine interioara o procedura privind plangerile formulate de beneficiarii de servicii sociale, care va respecta pct. 10.1-10.3.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "11. Litigii",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "11.1. Litigiile nascute in legatura cu incheierea, executarea, modificarea si incetarea ori alte pretentii decurgand din prezentul contract vor fi supuse unei proceduri prealabile de solutionare pe cale amiabila.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "11.2. Daca dupa 15 zile de la inceperea acestor proceduri neoficiale furnizorul de servicii sociale si beneficiarul de servicii sociale nu reusesc sa rezolve in mod amiabil o divergenta contractuala, fiecare poate solicita Comisiei de mediere sociala mijlocirea solutionarii divergentelor sau se poate adresa instantelor judecatoresti competente.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "12.*) Rezilierea contractului",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "*) In functie de natura seriviciilor sociale oferite de catre furnizorul de servicii sociale, partile contractuale pot conveni asupra daunelor care se vor plati de catre partile responsabile.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "12.1. Constituie motiv de reziliere a prezentului contract urmatoarele:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "a) refuzul obiectiv al beneficiarului de servicii sociale de a mai primi serviciile sociale, exprimat in mod direct sau prin reprezentant;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "b) nerespectarea in mod repetat de catre beneficiarul de servicii sociale a regulamentului de ordine interioara al furnizorului de servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "c) incalcarea de catre furnizorul de servicii sociale a prevederilor legale cu privire la serviciile sociale, daca este invocata de beneficiarul de servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "d) retragerea autorizatiei de functionare sau a acreditarii furnizorului de servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "e) limitarea domeniului de activitate pentru care furnizorul de servicii sociale a fost acreditat, in masura in care este afectata acordarea serviciilor catre beneficiarul de servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "f) schimbarea obiectului de activitate al furnizorului de servicii sociale, in masura in care este afectata acordarea serviciilor catre beneficiarul de servicii sociale.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "13. Declarații ",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Centrul de Recuperare Sfântul Nectarie a Fundației Mihai Neșu Foundation declară că își asumă responsabilitatea strict pentru modul de desfășurare a ședințelor de recuperare, neavând angajat un medic specialist în caz de urgențe. Orice eveniment medical intervenit cum ar fi de exemplu crizele de epilepsie vor fi anunțate la numărul de urgențe 112.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "14. Incetarea contractului",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "14.1. Constituie motiv de incetare a prezentului contract urmatoarele:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "a) expirarea duratei pentru care a fost incheiat contractul;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "b) acordul partilor privind incetarea contractului;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "c) scopul contractului a fost atins;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "d) forta majora, daca este invocata.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "15. Dispozitii finale",
        alignment: 'left',
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 20]
      },
      {
        text: "15.1. Partile contractante au dreptul, pe durata indeplinirii prezentului contract, de a conveni modificarea clauzelor acestuia prin act aditional numai in cazul aparitiei unor circumstante care lezeaza interesele legitime ale acestora si care nu au putut fi prevazute la data incheierii prezentului contract.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "15.2. Prevederile prezentului contract se vor completa cu prevederile legislatiei in vigoare in domeniu.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "15.3. Limba care guverneaza prezentul contract este limba romana.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "15.4. Prezentul contract va fi interpretat conform legilor din Romania.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "15.5. Furnizorul de servicii sociale realizeaza monitorizarea si evaluarea serviciilor sociale acordate.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "15.6. Masurile de implementare a planului individualizat de recuperare se comunica Directiei generale de asistenta sociala si protectia copilului si Serviciului public de asistenta sociala, conform legii.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "15.7. Pe baza raportului cu privire la rezultatele implementarii planului individualizat de asistenta si ingrijire, Directia generala de asistenta sociala si protectia copilului si Serviciul public de asistenta sociala vor monitoriza activitatea furnizorului de servicii sociale.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "*) Anexele la contract:",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "a) planul individualizat de recuperare;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "b) fisa de reevaluare a serviciilor sociale acordate beneficiarului de servicii sociale;",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "c) planul revizuit de recuperare.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "*) Partile contractante pot stabili de comun acord si alte tipuri de anexe.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "Prezentul contract de furnizare a serviciilor sociale a fost incheiat la sediul furnizorului de servicii sociale/domiciliul beneficiarului de servicii sociale in doua exemplare, cate unul pentru fiecare parte contractanta.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      {
        text: "O copie a prezentului contract va fi transmisa Directiei generale de asistenta sociala si protectia copilului si Serviciului public de asistenta sociala de catre furnizorul de servicii sociale.",
        alignment: 'left',
        fontSize: 14,
        margin: [0, 10, 0, 20]
      },
      // {
      //   stack: [
      //     "CONTRACT",
      //     "Nr. " +  this.returnDashIfUndefined( this.contract_masina.nr_contract ) + " din " + this.datePipe.transform(this.contract_masina.data_contract,'d.MM.yyyy'),
      //     "Contact sofer: 0786.733.344",
      //     "Contact beneficiar: " + this.contract_masina.contact_beneficiar
      //   ],
      //   bold: true,
      //   alignment: 'center',
      //   fontSize: 16,
      //   margin: [0, 50, 0, 80]
      // },
    ],styles: {
      textheader: {
        alignment: 'center',
        bold: true,
        fontSize: 14,
        // lineHeight: 2
      }
    }
    }
    return docDefinition

  }
}
