<h1 mat-dialog-title>Adauga un nomeclator nou</h1>
<div mat-dialog-content>
    <mat-form-field>
      <mat-label>Nume</mat-label>
      <input matInput type="text" [(ngModel)]="propertyname">
    </mat-form-field>
<br>
<h2>Adauga proprietiati</h2>
<br>
<app-dynamic-menu [dmenu]="ctype" (notify)="openpropertymodal($event)"></app-dynamic-menu>
    <!-- <ng-container *ngIf="this.nompropsService.testval3.length ">
        <dynamic-form [fields]="this.nompropsService.testval3" (submit)="submit($event)">
        </dynamic-form> 
    </ng-container> -->
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="warn"  mat-dialog-close >Cancel</button>
    <button mat-raised-button color="primary"  cdkFocusInitial>Ok</button>
</div>