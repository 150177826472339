import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FileElement } from 'src/app/file-manager/model/element';
import { FileService } from 'src/app/services/file.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FiledashboardComponent } from 'src/app/filedashboard/filedashboard.component';
import { FileElementCls, FileFolderCls } from 'src/app/models/fisier.model';
import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-dosarelectronic',
  templateUrl: './dosarelectronic.component.html',
  styleUrls: ['./dosarelectronic.component.css']
})
export class DosarelectronicComponent implements OnInit {
  public fileElements: Observable<FileElement[]>;
  public idPacient: number;
  public pacientFiles: FileElement[] = [];
  public pacientFilesList: FileElement[] = [];
  public pacientHasFilesFlag: boolean;
  currentRoot: FileElement;
  currentPath: string = 'root';
  canNavigateUp:boolean = false;
  constructor(
    public fileService: FileService, 
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<DosarelectronicComponent>,
    @Inject(MAT_DIALOG_DATA) private data:any, 
    public dashboarddialog: MatDialog) 
    {
    if(data){
      this.idPacient = this.data;
      // console.log("Dosar Electronic - idPacient: => ",this.data);
    }
    // this.pacientFiles = this.fileService.getuserfiles(this.idPacient, this.currentPath);
    // if (this.pacientFiles.length === 0){
    //   this.pacientHasFilesFlag = false;
    //   console.log("User has no files");
    // }
    // else {
    //   this.pacientHasFilesFlag = true;
    //   console.log("User has files: =>", this.pacientFiles);
    // }


    this.fileService.getPacientFilesFromServer(this.idPacient,this.currentRoot ? this.currentRoot.id : 'root')
    .subscribe((data) =>{
      if (data){
        console.log("Pacient files from server ==>> ", data)
        for (let i of data ){
          if (i.isFolder){
            this.pacientFiles.unshift(i)
          }
          else{
            this.pacientFiles.push(i)
          }
        }
        // this.pacientFiles = data
        // this.ordoneazaFoldereleFirst()
        this.saveFilesToPacientList()
        console.log("Pacient files from server ORDONATE ==>> ", this.pacientFiles)
      }
      else{
        console.log("No Pacient files from server")
      }
    })
    
  }

  ngOnInit() {
    this.dialogRef.updateSize('90%', '95%');
    // if(!this.fileElements){
    // const folderA = this.fileService.add({ name: 'Documente medicale', isFolder: true, parent: 'root', idPacient: 0 });
    // this.fileService.add({ name: 'Documente personale', isFolder: true, parent: 'root', idPacient: 0 });
    // this.fileService.add({ name: 'Incarcate de pacient', isFolder: true, parent: folderA.id, idPacient: 0 });
    // this.fileService.add({ name: 'Fisier Demo.pdf', isFolder: false, parent: 'root', idPacient: 0 });
    // this.fileService.add({ name: 'Test fisier.pdf', isFolder: false, parent: 'root', idPacient: 0 });

    // this.updateFileElementQuery();
    // }
  }
  saveFilesToPacientList(){
    if (this.currentPath === 'root'){
      this.pacientFilesList = this.pacientFiles
      // console.log("ROOT // PacientFilesList ==>> ", this.pacientFilesList)
    }
    else{
      // console.log(" NOT ROOT // PacientFilesList ==>> ", this.pacientFilesList)
    }
  }
  ordoneazaFoldereleFirst(){
    let intermediar = []
    for (let i of this.pacientFiles){
      if (i.isFolder){
        intermediar.unshift(i)
      }
      else{
        intermediar.push(i)
      }
    }
    this.pacientFiles = intermediar
  }

  updatefileslist(){
    if (this.currentPath === 'root'){
      this.pacientFiles = this.fileService.getuserfiles(this.idPacient, this.currentPath);
    }
    else{
      this.pacientFiles = this.fileService.getuserfiles(this.idPacient, this.popFromPath(this.currentPath));
    }
    console.log("updatefile list current path: =>",  this.currentPath)
    if (!this.pacientHasFilesFlag) this.pacientHasFilesFlag = true;
  }

  addFolder(folder: { name: string }) {
    console.log("add folder current root: => ", this.currentRoot)
    console.log("add folder current root 222 : => ", this.currentPath)
    // console.log("add folder current root id: => ", this.currentRoot.id)
    // this.fileService.add({ isFolder: true, name: folder.name, parent: this.currentRoot ? this.currentRoot.id : 'root', idPacient: this.idPacient, status:'neverificat' });
    // this.updateFileElementQuery();
    let intermediar = new FileFolderCls()
    intermediar.id = this.fileService.generateFileID();
    intermediar.isFolder = true;
    intermediar.name = folder.name;
    intermediar.parent = this.currentPath === 'root' ? 'root' : this.currentRoot.id
    intermediar.idPacient = this.idPacient
    intermediar.dateadden = this.datepipe.transform(new Date(), 'dd.MM.YYYY, HH:mm');
    intermediar.status = 'neverificat';
    this.fileService.addFileToServer(intermediar)
    .subscribe((data) => {
      if (data){
        console.log("data from add folder to server => ", data)
        this.updateFileElementQuery()
      }
      else{
        console.log("no data from add folder to server")
      }
    })
    // this.updatefileslist();

  }

  removeElement(element: FileElement) {
    this.fileService.deleteFromServer(element.id)
    .subscribe ( (data) => {
      if (data){
        console.log("Data from delete File from server ==>> ", data)
        this.updateFileElementQuery();
      }
      else{
        console.log("NO Data from delete File from server ==>> ")

      }
    })
  }

  navigateToFolder(element: FileElement) {
    // console.log("navigate to folder element: =>", element);
    this.currentRoot = element;
    // this.updateFileElementQuery();
    console.log("navigate to folder currentPath: =>", this.currentPath);
    console.log("navigate to folder element name: =>", element.name);
    this.currentPath = this.pushToPath(this.currentPath === 'root' ? 'Fisiere' : this.currentPath, element.name);
    console.log("navigate to folder currentPath after pushtopath: =>", this.currentPath);
    this.canNavigateUp = true;
    // console.log("navigat to folder popfrompath: =>", this.popFromPath(this.currentPath))
    this.updateFileElementQuery()
  }

  async navigateUp() {
    // if (this.currentRoot && this.currentRoot.parent === 'root') {
    //   this.currentRoot = null;
    //   this.canNavigateUp = false;
    //   this.currentPath = 'root';
    //   // this.updateFileElementQuery();
    // } else {
    // console.log("Current ROOT ==>> ", this.currentRoot )
    if (this.currentRoot.parent !== 'root'){
      this.currentRoot = await this.fileService.getPacientFileFromServerByFisierID(this.currentRoot.parent).toPromise();
      // console.log("Current ROOT // INTERMEDIAR  ==>> ", this.currentRoot )
      this.currentPath = this.popFromPath(this.currentPath);
      // this.updateFileElementQuery()
    }
    else{
      this.currentPath = 'root';
      this.currentRoot = null;
      this.canNavigateUp = false

    }
    this.updateFileElementQuery()

      // this.currentRoot = 
      // this.updateFileElementQuery();
    // }
    // this.updateFileElementQuery()
  }

  moveElement(event: { element: FileElement; moveTo: FileElement }) {
    // console.log(event);
    // this.fileService.update(event.element.id,event.moveTo.name);
    // this.updatefileslist();
    // this.fileService.update(event.element.id, { parent: event.moveTo.id });
    // this.updateFileElementQuery();
    // console.log("MoveTo ==>> ", event.moveTo)
    // console.log("Event ==>> ", event)
    event.element.parent = event.moveTo.id
    this.fileService.updateFileToServer(event.element)
    .subscribe( (data) => {
      if (data){
        console.log("Data from move file to server ==>> ", data)
        this.updateFileElementQuery()
      }
    })
  }

  renameElement(element: FileElement) {
    console.log(element);
    // this.fileService.update(element.id, { name: element.name });
    this.fileService.updateFileToServer(element)
    .subscribe( (data) => {
      if (data){
        console.log("Data from update file to server ==>> ", data)
        this.updateFileElementQuery()
      }
    })
    // this.updateFileElementQuery();
  }
  statuschanged(element: FileElement){
    // this.fileService.updatestatus(element.id,element.status)
    this.fileService.updateFileToServer(element)
    .subscribe( (data) => {
      if (data){
        console.log("Data from update Status file to server ==>> ", data)
        this.updateFileElementQuery()
      }
    })
    // this.updateFileElementQuery();
  }

  updateFileElementQuery() {
    // this.fileElements = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root');
    this.fileService.getPacientFilesFromServer(this.idPacient,this.currentRoot ? this.currentRoot.id : 'root')
    .subscribe((data) =>{
      if (data){
        console.log("Pacient files from server ==>> ", data)
        if (this.pacientFiles.length){
          this.pacientFiles = []
        }
        for (let i of data ){
          if (i.isFolder){
            this.pacientFiles.unshift(i)
          }
          else{
            this.pacientFiles.push(i)
          }
        }
        // this.pacientFiles = data
        this.saveFilesToPacientList()
      }
      else{
        console.log("No Pacient files from server")
        this.pacientFiles = []
      }
    })
  }

  pushToPath(path: string, folderName: string) {
    // console.log("pushToPath path: =>", path);
    // console.log("pushToPath folderName: =>", folderName);
    let p = path ? path : '';
    // console.log("pushToPath p: =>", p);
    p += `/${folderName}`;
    return p;
  }

  downloadfile(element: FileElement){
    console.log("ELEMENT DIN DOWNLOAD DIN DOSAR == >> ",element.name)
    // const base64 = element.path.toString();
    // const filename = element.name;
    // const fileBlob = this.dataURItoBlob(base64, element.type);
    // const finalFile = new File([fileBlob], filename, { type: element.type });

  }
  dataURItoBlob(dataURI, datatype) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: datatype });
    return blob;
 }
  popFromPath(path: string) {
    let splittwo = path.split('/');
    splittwo.splice(splittwo.length - 1, 1);
    return splittwo.join('/')
  }

  openDashboardDialog(){
    let dashboarddialogRef = this.dashboarddialog.open(FiledashboardComponent,{
      data: this.fileService.getalluserfiles(this.idPacient),
      width: '750px'
    });
    dashboarddialogRef.afterClosed().subscribe(res => {
      // if (res) {
      //   this.folderAdded.emit({ name: res });
      // }
    });
  }

}
