<mat-form-field >
   <input matInput [(ngModel)]="newcat.cod" placeholder="Cod Categorie">
</mat-form-field>
<mat-form-field style="padding-left: 20px; padding-right: 20px;">
    <input matInput [(ngModel)]="newcat.denumire" placeholder="Denumire Categorie">
 </mat-form-field>
 <!-- <br> -->

 <!-- <mat-checkbox [(ngModel)]="newcat.arevalabilitate" (change)="valabilitatestatuschange(newcat.arevalabilitate)" > Valabilitate: </mat-checkbox>

    <mat-form-field >
      <input #startDate matInput [matDatepicker]="picker" (dateInput)="startdateset(startDate.value)" placeholder="De la" [disabled]="!newcat.arevalabilitate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field >
      <mat-label>Pana la</mat-label>
      <input #endDate matInput [matDatepicker]="picker2" (dateInput)="enddateset(endDate.value)" [disabled]="!newcat.arevalabilitate">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field> -->

<!-- start = {{start}}
end = {{end}} -->

<!-- <button mat-raised-button (click)="AddCat()"> Adauga</button> -->
<button mat-raised-button (click)="addnewcategorie()" [disabled]="!newcat.denumire" style="padding-left: 20px;"> Adauga</button>
<!-- <button mat-raised-button (click)="categirie2afis()"> afiseaza</button> -->
<!-- <button mat-raised-button (click)="addnewcategorie()"> adauta prin api</button> -->
<!-- <button mat-raised-button (click)="getCatfromServicebyis()"> cauta prin api</button> -->
<!-- <button mat-raised-button (click)="updatedata()"> cauta prin api</button> -->


<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

    <!-- ID Column -->
    <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> ID </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="cod">
    <th mat-header-cell *matHeaderCellDef> Cod </th>
    <td mat-cell *matCellDef="let element"> {{element.cod}} </td>
    </ng-container>

    <ng-container matColumnDef="denumire">
        <th mat-header-cell *matHeaderCellDef> Denumire </th>
        <td mat-cell *matCellDef="let element"> {{element.denumire}} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>
        <!-- <button  mat-raised-button color="primary" (click)="sendvalues()" [disabled]="!date_personale.nume || !date_personale.prenume" mat-dialog-close>Ok</button> -->
      </th>
      <td mat-cell *matCellDef="let element">

        <button mat-raised-button class="save-button" (click)="openCategoriiServiciiEdit(element)">edit</button>

      </td>
  </ng-container>

  <ng-container matColumnDef="deleteselect">
    <th mat-header-cell *matHeaderCellDef>
      <button  mat-raised-button color="warn" [disabled]="todelete.length == 0" (click)="deleteitems()" > Sterge </button>
    </th>
    <td mat-cell *matCellDef="let element">

      <mat-checkbox (change)="marktodelete(element.id)"></mat-checkbox>

    </td>
  </ng-container>

    <ng-container matColumnDef="valabildela">
        <th mat-header-cell *matHeaderCellDef> Valabil de la </th>
        <td mat-cell *matCellDef="let element"> {{element.valabilitate.start | date:'shortDate'}} </td>
    </ng-container>

    <ng-container matColumnDef="valabilpanala">
        <th mat-header-cell *matHeaderCellDef> Valabil de la </th>
        <td mat-cell *matCellDef="let element"> {{element.valabilitate.end | date:'shortDate'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
<div mat-dialog-actions align='center'>
  <button mat-raised-button color="warn" mat-dialog-close >Inchide</button>
</div>
