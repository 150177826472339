import { Injectable } from '@angular/core';
import { specialitateMedici,Valabilitate, specialitateMediciCreate, specialitateMediciCreateFaraValabilitate  } from '../models/nomenclatoare.model';
import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {catchError} from 'rxjs/operators/catchError';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ValabilitateInterface, specialitateMediciCreateFaraValabilitateInterface, specialitateMediciInterface, specialitateMediciCreateInterface } from '../interfaces/nomenclatoare.interface';

import { IntervalService } from './interval.service'
import { serverurl } from './server.service';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

export interface CategorieServicii{
  categorie: string;
}


@Injectable({
  providedIn: 'root'
})
export class SpecialitateMedicService {
  public specialitatiMedic: specialitateMedici [] = []
  public localstorageflag = 0;
  // private server_url="api/specialitatimedic";
  // private server_url="http://localhost/api/v1/specializaremedici/";
  private server_url=serverurl+"/api/v1/specializaremedici/";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    public intervalService: IntervalService,
    private localStorageService: LocalStorageService
    ) {
      let data = this.localStorageService.get("SpecialitatiMedic");
      // console.log("On Initi DAta Local storage => ",data)
      if (data)
      {
        this.localstorageflag = 1;
        this.specialitatiMedic = data;
        this.specialitatiMedic.forEach(element=> {
          this.http.post<specialitateMedici>(this.server_url, element, cudOptions).pipe(
            catchError(this.handleError)
              )
            });
        }
      else {
        // console.log("PacientList nu este Local")
      }
    }

  getSpecialitatiMedic(){
    return this.specialitatiMedic
  }

  getfromserver() {
    return this.http.get<specialitateMediciInterface[]>(this.server_url)
  }
  deletefromserver(id){
    return this.http.delete(this.server_url+id)
  }
  updatetoserver(id,serviciu){
    return this.http.put(this.server_url+id, serviciu)
  }
  addSpecialitateMedicCuValabilitate(specialitateMedic: specialitateMediciCreateInterface){
    console.log("intru in add post request")
    console.log("categorie de servicii service -> addhero -> hero => ", specialitateMedic)
    let toate_detaliile: specialitateMediciCreateInterface = new specialitateMedici()
    return(this.http.post<any>(this.server_url,specialitateMedic,this.httpOptions))
  }

  addSpecialitateMedicFaraValabilitate(specialitateMedic: specialitateMediciCreateFaraValabilitateInterface){
    console.log("intru in add post request fara valabilitate")
    console.log("categorie de servicii service -> addspecialitateMedic fara valabilitate -> specialitateMedic => ", specialitateMedic)
    return (this.http.post<any>(this.server_url,specialitateMedic,this.httpOptions))
  }

  addSpecialitateMedic(categorie){
    this.specialitatiMedic.push(categorie);
    if (this.localstorageflag){
      this.localStorageService.remove("SpecialitatiMedic");
      this.addtolocalStorage(this.specialitatiMedic);
    }
    else {
      this.addtolocalStorage(this.specialitatiMedic);
    }
    console.log("Lista pacienti din pacienti service =>", this.specialitatiMedic);

    return this.http.post<CategorieServicii>(this.server_url, categorie, cudOptions).pipe(
      catchError(this.handleError)
    );
  }

  addtolocalStorage(PacientList){
    console.log("addtolocalstorage CategorieServicii: =>", PacientList);
    console.log("Addtolocalstorage",this.localStorageService.set("SpecialitatiMedic", PacientList));
  }
  private handleError(error: Response){
    console.log("Error from Pacient Service: => " + error)
    return Observable.throw("500 internal server error" )
  }
}
