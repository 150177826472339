<!-- <button mat-raised-button color="primary" (click)="adauganomenclator()">Adauga nomenclator </button>
<mat-divider></mat-divider> -->

<mat-selection-list>
    <mat-list-option role="listitem" (click)="openCategoriiServicii()">Categorii de servicii</mat-list-option>
    <!-- <mat-list-option role="listitem" (click)="openServicii()">Tip Servicii</mat-list-option> -->
    <mat-list-option role="listitem" (click)="openServicii()">Servicii</mat-list-option>
    <mat-list-option role="listitem" (click)="openMedici()">Terapeuti</mat-list-option>
    <mat-list-option role="listitem" (click)="openSpecialitati()">Specialitati</mat-list-option>
    <mat-list-option role="listitem" (click)="openTipDepartament()">Tip Departament</mat-list-option>
    <mat-list-option role="listitem" (click)="openDepartament()">Departament</mat-list-option>
    <mat-list-option role="listitem" (click)="openTipDiagnostic()">Tip Diagnostic</mat-list-option>
    <mat-list-option role="listitem" (click)="openDiagnostic()">Diagnostic</mat-list-option>
    <!-- <mat-list-option role="listitem" (click)="openFurnizorServiciiSociale()">Furnizor Servicii Sociale</mat-list-option>
    <mat-list-option role="listitem" (click)="openCaseDeAsigurari()">Case de Asigurari</mat-list-option> -->
    <!-- <mat-list-item role="listitem" (click)="openProduse()">Produse</mat-list-item> -->
</mat-selection-list>
