<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> Chestionar pentru depistarea deficientelor</h2></span>
<br>
<!-- <span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Nume: {{ ((fise_evaluare_mari.nume_pacient != "") ? fise_evaluare_mari.nume_pacient : "  Nespecificat  ") + "Prenume:"  + ((fise_evaluare_mari.prenume_pacient != "") ? fise_evaluare_mari.prenume_pacient : " Nespecificat ") }}  </span>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Data nasterii: {{ ((fise_evaluare_mari.data_nasterii_pacient != "") ? fise_evaluare_mari.data_nasterii_pacient : " Nespecificat ") }} </span>
<br>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Data completarii: {{ ((fise_evaluare_mari.data_completarii != "") ? fise_evaluare_mari.data_completarii : " Nespecificat ") }} </span>  -->

<table mat-table [dataSource]="datasourceComunicareSiLimbaja" style="width: 100%;">
    <ng-container matColumnDef="titlu">
        <th mat-header-cell *matHeaderCellDef> Comunicare si Limbaj
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.text}} </b>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.id }} </b>

        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>

    <ng-container matColumnDef="Slider">
        <th mat-header-cell *matHeaderCellDef> Niciodata - Cateodata - Frecvent
        </th>
		<td mat-cell *matCellDef="let element">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'redfundal': element.selected > 0, 'notallowed': element.selected == 0  }"
            (click) = "((element.selected > 0)?(element.selected = (numberedversion(element.selected) - 1)): undefined) ;refreshDS()"
            >remove</mat-icon>
            <input matInput type="text" style=" width:30%;text-align: center;vertical-align: middle;" [value]="categorii[element.selected]">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'greenfundal': element.selected < 2, 'notallowed': element.selected == 2  }"
            (click) = "((element.selected < 2)?(element.selected = (numberedversion(element.selected) + 1)): undefined) ;refreshDS()"
            >add</mat-icon>
            &nbsp;&nbsp;&nbsp;
            <mat-slider
            [max]="2" [(ngModel)]="element.selected" [min]="0" [thumbLabel]="true" [step]="1" [tickInterval]="1" (change)="changeslider($event)" >
            </mat-slider>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >

        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<table mat-table [dataSource]="datasourceComportamentMotor" style="width: 100%;">
    <ng-container matColumnDef="titlu">
        <th mat-header-cell *matHeaderCellDef> Comportament Motor
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.text}} </b>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.id }} </b>

        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>

    <ng-container matColumnDef="Slider">
        <th mat-header-cell *matHeaderCellDef> Niciodata - Cateodata - Frecvent
        </th>
		<td mat-cell *matCellDef="let element">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'redfundal': element.selected > 0, 'notallowed': element.selected == 0  }"
            (click) = "((element.selected > 0)?(element.selected = (numberedversion(element.selected) - 1)): undefined) ;refreshDS()"
            >remove</mat-icon>
            <input matInput type="text" style=" width:30%;text-align: center;vertical-align: middle;" [value]="categorii[element.selected]">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'greenfundal': element.selected < 2, 'notallowed': element.selected == 2  }"
            (click) = "((element.selected < 2)?(element.selected = (numberedversion(element.selected) + 1)): undefined) ;refreshDS()"
            >add</mat-icon>
            &nbsp;&nbsp;&nbsp;
            <mat-slider
            [max]="2" [(ngModel)]="element.selected" [min]="0" [thumbLabel]="true" [step]="1" [tickInterval]="1" (change)="changeslider($event)" >
            </mat-slider>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >

        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<table mat-table [dataSource]="datasourceTulburariDeDezvoltare" style="width: 100%;">
    <ng-container matColumnDef="titlu">
        <th mat-header-cell *matHeaderCellDef> Tulburari de dezvoltare
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.text}} </b>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.id }} </b>

        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >
        </td>
    </ng-container>

    <ng-container matColumnDef="Slider">
        <th mat-header-cell *matHeaderCellDef> Niciodata - Cateodata - Frecvent
        </th>
		<td mat-cell *matCellDef="let element">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'redfundal': element.selected > 0, 'notallowed': element.selected == 0  }"
            (click) = "((element.selected > 0)?(element.selected = (numberedversion(element.selected) - 1)): undefined) ;refreshDS()"
            >remove</mat-icon>
            <input matInput type="text" style=" width:30%;text-align: center;vertical-align: middle;" [value]="categorii[element.selected]">
            <mat-icon aria-hidden="false" aria-label="add" style="vertical-align: middle;"
            [ngClass]="{'greenfundal': element.selected < 2, 'notallowed': element.selected == 2  }"
            (click) = "((element.selected < 2)?(element.selected = (numberedversion(element.selected) + 1)): undefined) ;refreshDS()"
            >add</mat-icon>
            &nbsp;&nbsp;&nbsp;
            <mat-slider
            [max]="2" [(ngModel)]="element.selected" [min]="0" [thumbLabel]="true" [step]="1" [tickInterval]="1" (change)="changeslider($event)" >
            </mat-slider>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: right" >

        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-form-field style="width: 100%;" >
    <mat-label>{{ fise_evaluare_mari.anatomia_valului_palatin_omusorului_limbii_frenului_dintilor_buzelor.titlu + ": " }} </mat-label>
    <textarea matInput [(ngModel)]="fise_evaluare_mari.anatomia_valului_palatin_omusorului_limbii_frenului_dintilor_buzelor.continut" ></textarea>
</mat-form-field>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  {{ fise_evaluare_mari.capacitate_de_relaxare.titlu }}</span>

<ng-container *ngFor="let i of fise_evaluare_mari.capacitate_de_relaxare.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  {{ fise_evaluare_mari.respiratia.titlu }}</span>

<ng-container *ngFor="let i of fise_evaluare_mari.respiratia.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  {{ fise_evaluare_mari.imitatia_miscarilor_faciale.titlu }}</span>

<ng-container *ngFor="let i of fise_evaluare_mari.imitatia_miscarilor_faciale.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  {{ fise_evaluare_mari.miscari_ale_limbii_buzelor_obrajilor_maxilarelor.titlu }}</span>

<ng-container *ngFor="let i of fise_evaluare_mari.miscari_ale_limbii_buzelor_obrajilor_maxilarelor.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  {{ fise_evaluare_mari.aspectul_fonetic_si_fonologic.titlu }}</span>
<mat-form-field style="width: 100%;" >
    <mat-label>{{ fise_evaluare_mari.aspectul_fonetic_si_fonologic.subtitlu + ": " }} </mat-label>
    <textarea matInput [(ngModel)]="fise_evaluare_mari.aspectul_fonetic_si_fonologic.continut" ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>{{ fise_evaluare_mari.imitare_de_onomatopee.titlu + ": " }} </mat-label>
    <textarea matInput [(ngModel)]="fise_evaluare_mari.imitare_de_onomatopee.continut" ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>{{ fise_evaluare_mari.discriminare_fonologica.titlu + ": " }} </mat-label>
    <textarea matInput [(ngModel)]="fise_evaluare_mari.discriminare_fonologica.continut" ></textarea>
</mat-form-field>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  <h2>  {{ fise_evaluare_mari.aspectul_semantic.titlu }}</h2>
</span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">   {{ fise_evaluare_mari.aspectul_semantic.vocabular.titlu }}
</span>
<ng-container *ngFor="let i of fise_evaluare_mari.aspectul_semantic.vocabular.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">   {{ fise_evaluare_mari.aspectul_semantic.comenzi.titlu }}
</span>
<ng-container *ngFor="let i of fise_evaluare_mari.aspectul_semantic.comenzi.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">   {{ fise_evaluare_mari.aspectul_semantic.intelegerea_unui_text_audiat.titlu }}
</span>
<ng-container *ngFor="let i of fise_evaluare_mari.aspectul_semantic.intelegerea_unui_text_audiat.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">   {{ fise_evaluare_mari.aspectul_semantic.clasificari.titlu }}
</span>
<ng-container *ngFor="let i of fise_evaluare_mari.aspectul_semantic.clasificari.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  <h2>  {{ fise_evaluare_mari.aspectul_morfosintactic.titlu }}</h2>
</span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">   {{ fise_evaluare_mari.aspectul_morfosintactic.sintaxa.titlu }}
</span>
<ng-container *ngFor="let i of fise_evaluare_mari.aspectul_morfosintactic.sintaxa.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">   {{ fise_evaluare_mari.aspectul_morfosintactic.morfologie.titlu }}
</span>
<ng-container *ngFor="let i of fise_evaluare_mari.aspectul_morfosintactic.morfologie.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">   {{ fise_evaluare_mari.aspectul_morfosintactic.secventialitate_temporala.titlu }}
</span>
<ng-container *ngFor="let i of fise_evaluare_mari.aspectul_morfosintactic.secventialitate_temporala.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">  <h2>  {{ fise_evaluare_mari.aspect_pragmatic.titlu }}</h2>
</span>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">   {{ fise_evaluare_mari.aspect_pragmatic.intentionalitatea_comunicarii.titlu }}
</span>
<ng-container *ngFor="let i of fise_evaluare_mari.aspect_pragmatic.intentionalitatea_comunicarii.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">   {{ fise_evaluare_mari.aspect_pragmatic.modul_de_comunicare.titlu }}
</span>
<ng-container *ngFor="let i of fise_evaluare_mari.aspect_pragmatic.modul_de_comunicare.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>
<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
">   {{ fise_evaluare_mari.aspect_pragmatic.foloseste_limbajul_pentru_a.titlu }}
</span>
<ng-container *ngFor="let i of fise_evaluare_mari.aspect_pragmatic.foloseste_limbajul_pentru_a.subcategorii">
    <mat-form-field style="width: 100%;" >
        <mat-label>{{" - " + i.denumire + " : " }} </mat-label>
        <textarea matInput [(ngModel)]="i.continut" ></textarea>
    </mat-form-field>
</ng-container>

<!-- <button mat-raised-button (click)="generatePDF()" mat-dialog-close> Salveaza </button> -->
<ng-container *ngIf="caz == 1">

<button mat-raised-button (click)="semneazaRL()" > Semnatura Reprezentant Legal</button>
<button mat-raised-button (click)="semneazaTerapeut()"  > Semnatura Terapeut</button>
<button mat-raised-button (click)="openPDFPreviewComponent()" > Previzualizeaza </button>
</ng-container>
<ng-container *ngIf="caz == 2">
  <button mat-raised-button (click)="openPDFPreviewSsaveModificari()"  > Genereaza Document </button>
  <button mat-raised-button color="warn"  mat-dialog-close > Inchide </button>
</ng-container>

<!-- <button mat-raised-button > Salveaza</button> -->
