import { Component,Inject, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pacient, DateContact, DatePersonale, Adresa, SemnaturaRLCreate } from '../../../models/pacientdetails.model';
import { DatePipe } from '@angular/common';
import { FisaMonitorizarePPIInterface } from 'src/app/interfaces/documente.interface';
import { FisaMonitorizarePPI } from 'src/app/models/documente.models';
import { EvalserviceService } from 'src/app/services/evalservice.service';
import { logo_url_base64 } from 'src/app/models/logo-base64.model';
import { SemnaturiRLService } from 'src/app/services/semnaturirl.service';
import { SemnaturiTerapeutiFilesService } from 'src/app/services/semnaturiterapeutifiles.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { base_img_64 } from 'src/app/models/base-img64.model';
import { PinentryComponent } from 'src/app/pinentry/pinentry.component';
import { PdfpreviewerComponent } from 'src/app/pdfpreviewer/pdfpreviewer.component';
import { FileElementCreate } from 'src/app/file-manager/model/element';
import { FileElementCls } from 'src/app/models/fisier.model';
import { FileService } from 'src/app/services/file.service';
import { ConsultCreateInterface } from 'src/app/interfaces/consult.interface';
import { ConsultCreate } from 'src/app/models/consult.model';
import { ConsultatiiService } from 'src/app/services/consultatii.service';
import { SemnaturaRLCreateInterface } from 'src/app/interfaces/pacient.interface';

@Component({
  selector: 'app-fisa-monitorizare-ppi',
  templateUrl: './fisa-monitorizare-ppi.component.html',
  styleUrls: ['./fisa-monitorizare-ppi.component.css']
})
export class FisaMonitorizarePpiComponent implements OnInit {
  idFisier : any = null
  caz : number = 1
  semnatura_RL : any = null
  semnatura_Terapeut : any = null
  pacient = new Pacient();
  today = new Date()
  fisa_monitorizare_ppi:FisaMonitorizarePPIInterface= new FisaMonitorizarePPI()
  bkdataadaugare: any;

  structura:{
    sesiune_start:Date,
    sesiune_end?:Date,
    evaluare?:string,
    obiective?:string,
    metode_si_mijloace?:string,
    recomandari?:string
  }[] = []

  // displayedColumns = ['Sesiune','Evaluare', 'Obiective','Metode si mijloace de realizare','Recomandari']
  // dataSource = new MatTableDataSource(this.structura)
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FisaMonitorizarePpiComponent>,
    public evaluarefieldsService: EvalserviceService ,
    public fileService: FileService,
    private datePipe: DatePipe,
    public consultatiiService: ConsultatiiService,
    public semnaturirlService: SemnaturiRLService,
    public semnaturiTerapeutFilesService: SemnaturiTerapeutiFilesService,
    @Inject(MAT_DIALOG_DATA) private data:any ) {
      if(data){
        // if (data.rowz){
        //   this.pacient = data.rowz;
        // }
        // else{
        //   this.pacient = data
        // }
        // console.log("Data from component ==>> ",data);
        // console.log("FISA MONITORIZARE PPI ==>> ",this.fisa_monitorizare_ppi);
        if (data.caz == 1){
          this.caz = 1
          this.pacient = data.beneficiar
          if (this.pacient.id){
            console.log("Date Pacient Primite")
            this.fisa_monitorizare_ppi.nume_pacient = this.pacient.date_personale.nume
            this.fisa_monitorizare_ppi.prenume_pacient = this.pacient.date_personale.prenume
            this.fisa_monitorizare_ppi.data_monitorizare = this.datePipe.transform(this.today, 'dd.MM.yyyy');
          }
        }
        else if(data.caz == 2){
          this.idFisier = data.fieldsData.idFisier
          this.caz = 2
          console.log ( "FISA MONITORIZARE A PPI IN EDITARE")
        }
      }
    }
  ngOnInit(): void {
    if (this.caz == 2){
      this.semnaturirlService.getSemnaturaRLfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA RL FROM SERVER ==>> ", data)
          this.semnatura_RL = data.semnatura
        }
      })
      this.semnaturiTerapeutFilesService.getSemnaturaTerapeutFilesfromServerByFileID(this.idFisier)
      .subscribe( (data) => {
        if (data){
          console.log("DATA FROM GET SEMNATURA TERAPEUT FROM SERVER ==>> ", data)
          this.semnatura_Terapeut = data.semnatura
        }
      })
    }

  // FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI
  // let intermediar: FisaMonitorizarePPIInterface= new FisaMonitorizarePPI()
  // let testone = {
  //   "denumire" : "Fisa monitorizare a PPI",
  //   "props" : intermediar
  // }

  // this.evaluarefieldsService.addToServer(testone )
  // .subscribe( (data) =>{
  //   if (data){
  //     console.log("Data from add sectiunea A to server ==>> ", data)
  //   }
  //   else{
  //     console.log("NO !!! Data from add sectiunea A to server ==>> ")
  //   }
  // })

  // END FOLOSIT LA ADAUGAREA PE SERVER A CAMPURILOR DOCUMENTULUI

  }

  semneazaRL(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_RL = res
        console.log("Semnatura RL IMAGINE ESTE ==>> ",this.semnatura_RL)
      }
    })
  }
  semneazaTerapeut(){
    const dialogRef = this.dialog.open(PinentryComponent,{
      width: '650px',
      height: '550px'
    });
    dialogRef.afterClosed().subscribe( res => {
      if (res){
        this.semnatura_Terapeut = res
        console.log("Semnatura Terapeut IMAGINE ESTE ==>> ",this.semnatura_Terapeut)
      }
    })
  }
  openPDFPreviewComponent(){
    let docDefinition = this.generatePDF()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      const dialogRef = this.dialog.open(PdfpreviewerComponent, {
        width: '800px',
        height: '100%',
        data: data
      })
      dialogRef.afterClosed().subscribe( async res => {
        if (res){
          let fisier_to_upload : FileElementCreate = new FileElementCls()
          fisier_to_upload.id = this.fileService.generateFileID();
          fisier_to_upload.idPacient = this.pacient.id
          fisier_to_upload.isFolder = false
          fisier_to_upload.parent = 'root'
          fisier_to_upload.dateadden = this.datePipe.transform(new Date(), 'dd.MM.YYYY, HH:mm')
          this.bkdataadaugare = fisier_to_upload.dateadden
          fisier_to_upload.type = "application/pdf"
          fisier_to_upload.extension = "pdf"
          fisier_to_upload.path = "data:application/pdf;base64,"+data
          fisier_to_upload.status = 'neverificat';
          fisier_to_upload.name = "Fisa de monitorizare a PPI - "+this.datePipe.transform(new Date(), 'dd.MM.YYYY - HH:mm')
          let intermediar_from_server = await this.fileService.addFileToServerReturnFileID(fisier_to_upload).toPromise()
          this.addConsultToServer(intermediar_from_server)
          this.dialogRef.close(0)
        }
      })
    })
  }
  addConsultToServer(idFisier){
    let intermediar_consult : ConsultCreateInterface = new ConsultCreate()
    intermediar_consult.idPacient = this.pacient.id
    intermediar_consult.idFisier = idFisier
    intermediar_consult.dataAdaugare = this.bkdataadaugare
    intermediar_consult.idEvaluare = 37
    intermediar_consult.denumire = "Fisa de monitorizare a PPI"
    intermediar_consult.dataJSON = this.returnObjectForAddConsult()
    this.consultatiiService.addConsultToServer(intermediar_consult)
    .subscribe( (data) => {
      if (data){
        console.log("DATA DIN ADD CONSULT TO SERVER ==>> ", data)
      }
      let semnatura_noua_RL : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
      semnatura_noua_RL.idFisier = idFisier
      semnatura_noua_RL.semnatura = this.semnatura_RL
      this.semnaturirlService.addSemnaturaRLToServer(semnatura_noua_RL)
      .subscribe ( (data) => {
        if (data){
          console.log ("DATA DIN ADD SEMNATURA RL TO SERVER ==>> ", data)
        }
      })
      let semnatura_noua_TerapeutFiles : SemnaturaRLCreateInterface = new SemnaturaRLCreate()
      semnatura_noua_TerapeutFiles.idFisier = idFisier
      semnatura_noua_TerapeutFiles.semnatura = this.semnatura_Terapeut
      this.semnaturiTerapeutFilesService.addSemnaturaTerapeutFilesToServer(semnatura_noua_TerapeutFiles)
      .subscribe ( (data) => {
        if (data){
          console.log ("DATA DIN ADD SEMNATURA TERAPEUT FILES TO SERVER ==>> ", data)
        }
      })
    })
  }

  returnObjectForAddConsult(){
    let intermediar = {
      "dataJSON" : this.fisa_monitorizare_ppi
    }
    return intermediar
  }


  generatePDF(action = 'open') {
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
        height: 60,
        columns:
          [
            // {
            //   image: logo_url_base64,
            //   width: 50,
            //   alignment: 'center',
            // },
            {
              stack: [
                "Fundația Mihai Neșu Foundation",
                "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
              ],
              margin: 10,
              alignment: 'center',
              style: 'textheader'
            }
          ],
        margin: [0, 0, 0, 90]
      },
      content: [
        {
          canvas: [
            {
              type: 'line',
              x1: -100, y1: 0,
              x2: 600, y2: 0,
              lineWidth: 1,
              lineCap: 'square'
            },
            {
              type: 'line',
              x1: -100, y1: 4,
              x2: 600, y2: 4,
              lineWidth: 1,
              lineCap: 'square'
            }
          ]
        },
        {
          image: logo_url_base64,
          width: 200,
          alignment: 'center',
        },
        {
          text: "FIȘĂ DE MONITORIZARE A PPI",
          bold: true,
          alignment: 'center',
          fontSize: 16,
          margin: [0, 50, 0, 80]
        },
        {
          text: "Data: " + this.fisa_monitorizare_ppi.data_monitorizare,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Nume: " + this.fisa_monitorizare_ppi.nume_pacient,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Prenume: " + this.fisa_monitorizare_ppi.prenume_pacient,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "2.	Diagnostic: " + this.fisa_monitorizare_ppi.diagnostic_pacient,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "3.	Obiectivele actuale: " + this.fisa_monitorizare_ppi.obiectivele_actuale,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "4.	Grad de indeplinire: ",
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "•	Problema identificata:" + this.fisa_monitorizare_ppi.grad_de_indeplinire.problema_identificate.continut,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "•	Solutii: " + this.fisa_monitorizare_ppi.grad_de_indeplinire.solutii.continut,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "5.	Recomandare:  " + this.fisa_monitorizare_ppi.recomandare,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },
        {
          text: "6.	Rezultate obtinute:  " + this.fisa_monitorizare_ppi.rezultate_obtinute,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 10, 0, 20]
        },        {
          columns:[
            {
              stack: [
                {
                  text: "Semnatura terapeut, ",
                  bold: true,
                  alignment: 'left',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_Terapeut ? this.semnatura_Terapeut : base_img_64,
                  height: 40,
                  width: 140,
                  margin: [0, 0, 0, 20]
                }
              ]
            },
            {
              stack: [
                {
                  text: "Luat la cunoștință de părinte, ",
                  bold: true,
                  alignment: 'right',
                  fontSize: 12,
                  margin: [0, 0, 0, 20]
                },
                {
                  image: this.semnatura_RL ? this.semnatura_RL : base_img_64,
                  alignment: 'right',
                  height: 40,
                  width: 140,
                  margin: [0, 0, 0, 20]
                }
              ]
            }
          ]
        }
      ]
  }
  return docDefinition
}}
