
<!-- <ng-container *ngIf='!pacient.aprobat'>
  <button mat-raised-button color="primary" (click)="aprobapacient()" >Aproba beneficiar</button>
</ng-container>
<ng-container *ngIf='pacient.aprobat'>
  <span style="background-color: greenyellow;">Beneficiarul este aprobat</span>
</ng-container> -->


<div mat-dialog-content>

  <div style="text-align: center;" class="fixActionRow">
    <div class="headerSliderow-ish"  >
      <div class="headerRow"  >
        <div class="headerNonSlidersBorder">
          <h1 style="margin: 0px;">Date Beneficiar</h1>
        </div>
      </div>

      <div class="headerRow">
        <div class="headerSlidersBorder">
          <div class="flex-align-center">
            Status cont
          </div>
          <div class="separator-flex-align-padding">
            :
          </div>
          <div class="flex-align-center">
            <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectata': !datePersonaleDialogCardValues.aprobat}">
              Inactiv
            </div>
            <div class="flex-align-center wrapper-selector " >
              <mat-slide-toggle
                [(ngModel)]="datePersonaleDialogCardValues.aprobat"
                (change)="changedStatusCont()"
                class="slider-padding-left-set"
                [ngClass]="{'optiune-selectata-selector': datePersonaleDialogCardValues.aprobat  }"
                >
                Activ
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-tab-group mat-align-tabs="center">

      <!--  Pacient -- Date Personale -->

      <mat-tab label="Date Personale">

        <br>

        <mat-form-field  appearance="outline" style="min-width:120px">
          <mat-label>Nume</mat-label>
          <input matInput type="text" [ngModel]= "datePersonaleDialogCardValues?.nume " (ngModelChange)="datePersonaleDialogCardValues.nume=$event">
        </mat-form-field>

        <mat-form-field  appearance="outline" style="min-width:200px">
          <mat-label>Prenume</mat-label>
          <input matInput type="text" [ngModel]= "datePersonaleDialogCardValues?.prenume" (ngModelChange)="datePersonaleDialogCardValues.prenume=$event">
        </mat-form-field>

        <mat-form-field  appearance="outline" style="width:135px" >
          <mat-label>CNP</mat-label>
          <input matInput type="number" [ngModel]= "datePersonaleDialogCardValues?.cnp" (ngModelChange)="datePersonaleDialogCardValues.cnp=$event"   >
        </mat-form-field>

        <mat-form-field  appearance="outline" style="max-width: 100px;">
          <mat-label>Sex</mat-label>
          <mat-select [ngModel]="datePersonaleDialogCardValues?.sex" (ngModelChange)="datePersonaleDialogCardValues.sex=$event" >
            <mat-option value="masculin">Masculin</mat-option>
            <mat-option value="feminin">Feminin</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field  appearance="outline" style="max-width: 75px;">
          <mat-label>Greutate</mat-label>
          <input matInput type="text" [ngModel]= "datePersonaleDialogCardValues?.greutate" (ngModelChange)="datePersonaleDialogCardValues.greutate=$event" >
          <span matSuffix>kg</span>
        </mat-form-field>

        <mat-form-field  appearance="outline" style="max-width: 75px;">
          <mat-label>Inaltime</mat-label>
          <input matInput type="text" [ngModel]= "datePersonaleDialogCardValues?.inaltime" (ngModelChange)="datePersonaleDialogCardValues.inaltime=$event" >
          <span matSuffix>cm</span>
        </mat-form-field>

        <mat-form-field  appearance="outline"  style="max-width: 120px;" >
          <mat-label>Data Nasterii</mat-label>
          <input matInput [matDatepicker]="picker" [ngModel]= "datePersonaleDialogCardValues?.data_nasterii" (ngModelChange)="datePersonaleDialogCardValues.data_nasterii=$event" >
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field  appearance="outline" style="max-width: 120px;" >
          <mat-label>Locul Nasterii</mat-label>
          <input matInput type="text" [ngModel]= "datePersonaleDialogCardValues?.locul_nasterii" (ngModelChange)="datePersonaleDialogCardValues.locul_nasterii=$event" >
        </mat-form-field>

        <mat-form-field   appearance="outline" style="max-width: 120px;" *ngIf="datePersonaleDialogCardValues?.carte_identitate_check" >
          <mat-label>Serie CI</mat-label>
          <input matInput type="text" [ngModel]="datePersonaleDialogCardValues?.serie_carte_identitate" (ngModelChange)="datePersonaleDialogCardValues.serie_carte_identitate=$event" >
        </mat-form-field>

        <mat-form-field   appearance="outline" style="max-width: 120px;" *ngIf="datePersonaleDialogCardValues?.carte_identitate_check" >
          <mat-label>Numar CI</mat-label>
          <input matInput type="text" [ngModel]="datePersonaleDialogCardValues?.numar_carte_identitate" (ngModelChange)="datePersonaleDialogCardValues.numar_carte_identitate=$event" >
        </mat-form-field>
        <div class="topSliderow-ish">

          <div class="topSlideRow">
            <!-- <div class="TopSlidersBorder">
              <div class="flex-align-center">
                Status cont
              </div>
              <div class="separator-flex-align-padding">
                :
              </div>
              <div class="flex-align-center">
                <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectata': !pacienttwo.aprobat}">
                  Inactiv
                </div>
                <div class="flex-align-center wrapper-selector " >
                  <mat-slide-toggle
                    [(ngModel)]="pacienttwo.aprobat"
                    (change)="changedStatusCont()"
                    class="slider-padding-left-set"
                    [ngClass]="{'optiune-selectata-selector': pacienttwo.aprobat  }"
                    >
                    Activ
                  </mat-slide-toggle>
                </div>
              </div>
            </div> -->

            <div class="TopSlidersBorder">
              <div class="flex-align-center">
                Orar indisponibilitate
              </div>
              <div class="separator-flex-align-padding">
                :
              </div>
              <div class="flex-align-center">
                <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectata': !datePersonaleDialogCardValues?.orar_de_somn}">
                  NU
                </div>
                <div class="flex-align-center wrapper-selector " >
                  <mat-slide-toggle
                    [(ngModel)]="datePersonaleDialogCardValues.orar_de_somn"
                    (change)="changed_OrarIndisponibilitate()"
                    class="slider-padding-left-set"
                    [ngClass]="{'optiune-selectata-selector': datePersonaleDialogCardValues?.orar_de_somn  }"
                    >
                    DA
                  </mat-slide-toggle>
                </div>
              </div>
            </div>

            <div style="display: flex;" class="TopSlidersBorder">
              <div style="display: flex; align-items: center;">
                Carte de identitate
              </div>
              <div class="separator-flex-align-padding">
                :
              </div>
              <div class="flex-align-center">
                <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectata': !datePersonaleDialogCardValues.carte_identitate_check  }">
                  NU
                </div>
                <div class="flex-align-center wrapper-selector " >
                  <mat-slide-toggle
                    [(ngModel)]="datePersonaleDialogCardValues.carte_identitate_check"
                    (change)="changed()"
                    class="slider-padding-left-set"
                    [ngClass]="{'optiune-selectata-selector': datePersonaleDialogCardValues.carte_identitate_check  }"
                    >
                    DA
                  </mat-slide-toggle>
                </div>
              </div>
            </div>

            <div style="display: flex;" class="TopSlidersBorder">
              <div class="flex-align-center">
                Grad de dependenta
              </div>
              <div class="separator-flex-align-padding">
                :
              </div>
              <div class="flex-align-center ">
                <div class="flex-align-center" [ngClass]="{'optiune-selectata': !datePersonaleDialogCardValues.independent  }">
                  DEPENDENT
                </div>
                <div class="flex-align-center wrapper-selector ">
                  <mat-slide-toggle
                    [(ngModel)]="datePersonaleDialogCardValues.independent"
                    (change)="changed_dependenta()"
                    class="slider-padding-left-set"
                    [ngClass]="{'optiune-selectata-selector': datePersonaleDialogCardValues.independent }"
                    >
                    INDEPENDENT
                  </mat-slide-toggle>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="flex-align-center"  >
          <!-- <div style="display: flex;" class="slider-first-padding-font-set">
            <div style="display: flex; align-items: center;">
              Carte de identitate
            </div>
            <div class="separator-flex-align-padding">
              :
            </div>
            <div class="flex-align-center">
              <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectata': !pacientiService.getStatusCI()  }">
                NU
              </div>
              <div class="flex-align-center wrapper-selector " >
                <mat-slide-toggle
                  [(ngModel)]="carte_identitate_check"
                  (change)="changed()"
                  class="slider-padding-left-set"
                  [ngClass]="{'optiune-selectata-selector': pacientiService.getStatusCI()  }"
                  >
                  DA
                </mat-slide-toggle>
              </div>
            </div>
          </div> -->

          <!-- <div style="display: flex;" class="slider-padding-font-set">
            <div class="flex-align-center">
              Grad de dependenta
            </div>
            <div class="separator-flex-align-padding">
              :
            </div>
            <div class="flex-align-center ">
              <div class="flex-align-center" [ngClass]="{'optiune-selectata': !pacientiService.getStatusIndependenta()  }">
                DEPENDENT
              </div>
              <div class="flex-align-center wrapper-selector ">
                <mat-slide-toggle
                  [(ngModel)]="pacienttwo.independent"
                  (change)="changed_dependenta()"
                  class="slider-padding-left-set"
                  [ngClass]="{'optiune-selectata-selector': pacientiService.getStatusIndependenta()  }"
                  >
                  INDEPENDENT
                </mat-slide-toggle>
              </div>
            </div>
          </div> -->
        </div>

        <!-- <div class="flex-align-center grid-ish" *ngIf="carte_identitate_check" >
          <div class="row-ish-ci">
            <div class="row-ci"  >

              <mat-form-field style="display: flex;"  appearance="outline">
                <mat-label>Serie CI</mat-label>
                <input matInput type="text" [ngModel]="pacienttwo?.date_personale?.serie_carte_identitate" (ngModelChange)="pacienttwo.date_personale.serie_carte_identitate=$event" >
              </mat-form-field>

              <mat-form-field style="display: flex;align-items: center;"  appearance="outline" >
                <mat-label>Numar CI</mat-label>
                <input matInput type="text" [ngModel]="pacienttwo?.date_personale?.numar_carte_identitate" (ngModelChange)="pacienttwo.date_personale.numar_carte_identitate=$event" >
              </mat-form-field>

            </div>
          </div>
        </div> -->

      <div class="flex-align-center grid-ish" >
        <div class="row-ish" style="width:48%;margin:1%;height: 500px; overflow: scroll;" *ngIf="datePersonaleDialogCardValues.orar_de_somn">

          <div class="row" *ngIf="flag_show_detalii_interval">
            <div class= " flex-align-center panel-add-interval-indisponibil">
              <div class="flex-align-center" >
                <div class="column">
                  <span class="orardesomn" *ngIf="datePersonaleDialogCardValues.orar_de_somn"> De la: </span>
                </div>
                <ngx-mat-timepicker *ngIf="datePersonaleDialogCardValues.orar_de_somn" [(ngModel)]="interval_start"
                  [disabled]="!exista_orar_de_somn" [showSpinners]="true">
                </ngx-mat-timepicker>
              </div>

              <div class="flex-align-center">
                <div class="column">
                  <span class="orardesomn" *ngIf="datePersonaleDialogCardValues.orar_de_somn"> Pana la: </span>
                </div>
                <ngx-mat-timepicker *ngIf="datePersonaleDialogCardValues.orar_de_somn" [(ngModel)]="interval_end"
                  [disabled]="!exista_orar_de_somn" [showSpinners]="true">
                </ngx-mat-timepicker>
              </div>

              <div class="flex-align-center">
                <button mat-raised-button color="primary" *ngIf="datePersonaleDialogCardValues.orar_de_somn && !flag_camp_detalii_activ "
                  (click)="adaugaDetaliiIntervalIndisponibilitate()"> Adauga detalii </button>
              </div>

              <mat-form-field appearance="fill" *ngIf="flag_camp_detalii_activ" style="max-width: 450px; font-size: 15px;"  appearance="outline">
                <mat-label *ngIf="!flag_interval_in_editare">Adauga detalii</mat-label>
                <textarea [(ngModel)]="interval_indisponibilitate_nou.detalii" *ngIf="!flag_interval_in_editare" matInput placeholder="Detalii"></textarea>
                <textarea [(ngModel)]="intermediar_interval_edit_detalii" *ngIf="flag_interval_in_editare" matInput ></textarea>
              </mat-form-field>
            </div>

          </div>


          <div class="row" >
            <!-- <div class="slider-first-padding-font-set">
              <div class="flex-align-center">
                Orar indisponibilitate
              </div>
              <div class="separator-flex-align-padding">
                :
              </div>
              <div class="flex-align-center">
                <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectata': !pacienttwo.orar_de_somn}">
                  NU
                </div>
                <div class="flex-align-center wrapper-selector " >
                  <mat-slide-toggle
                    [(ngModel)]="pacienttwo.orar_de_somn"
                    (change)="changed_OrarIndisponibilitate()"
                    class="slider-padding-left-set"
                    [ngClass]="{'optiune-selectata-selector': pacienttwo.orar_de_somn  }"
                    >
                    DA
                  </mat-slide-toggle>
                </div>
              </div>
            </div> -->
            <div style="display: flex;  justify-content: center; max-height:60px">
              <button mat-raised-button class="save-button" *ngIf="flag_adauga_primul_interval"
              (click)="adaugaIntervaleIndisponibilitate()"> Adauga interval </button>
              <button mat-raised-button class="save-button" *ngIf="flag_adauga_interval_nou"
              (click)="adaugaIntervalNou()"> Adauga interval nou </button>
              <button mat-raised-button class="save-button" *ngIf="flag_salveaza_interval_nou"
              (click)="salveazaIntervalNou()"> Salveaza intervalul nou </button>
              <button mat-raised-button class="save-button" *ngIf="flag_interval_in_editare"
              (click)="salveazaIntervalulEditat()"> Salveaza intervalul editat </button>
              <button mat-raised-button color="warn" (click)="anuleazaAddIntervaleIndisponibilitate()" *ngIf="pacienttwo.orar_de_somn && flag_show_detalii_interval"> Anuleaza</button>
            </div>

          </div>



          <div class="row">
            <table class="mat-elevation-z8" mat-table [dataSource]="datePersonaleDialogCardValues.intervale_indisponibilitate"
            *ngIf="datePersonaleDialogCardValues.orar_de_somn">
           <!-- Ora start Column -->
           <ng-container matColumnDef="ora_start">
             <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Ora start </th>
             <td mat-cell *matCellDef="let element"> {{element.ora_start}} </td>
           </ng-container>

           <!-- ORa end Column -->
           <ng-container matColumnDef="ora_end">
             <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Ora end </th>
             <td mat-cell *matCellDef="let element"> {{element.ora_end}} </td>
           </ng-container>
           <!--  Column -->
           <ng-container matColumnDef="detalii">
             <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Detalii </th>
             <td mat-cell *matCellDef="let element"> {{element.detalii ? element.detalii : ' - ' }} </td>
           </ng-container>

           <ng-container matColumnDef="edit">
             <th mat-header-cell *matHeaderCellDef>
               <!-- <button  mat-raised-button color="primary" (click)="sendvalues()" [disabled]="!date_personale.nume || !date_personale.prenume" mat-dialog-close>Ok</button> -->
             </th>
             <td mat-cell *matCellDef="let element">

               <button mat-raised-button class="save-button" [disabled]="flag_show_detalii_interval"
                 (click)="editeazaIntervalIndisponibilitate(element)">editeaza</button>

             </td>
           </ng-container>

           <ng-container matColumnDef="deleteselect">
             <th mat-header-cell *matHeaderCellDef>
               <button mat-raised-button color="warn" [disabled]="intervaleindisponibilitatetodelete.length == 0" (click)="deleteInterval()"> Sterge
               </button>
             </th>
             <td mat-cell *matCellDef="let element">

               <mat-checkbox (change)="markintervaltodelete(element)" [checked]="returnStatusIntervalMarcat(element)"></mat-checkbox>

             </td>
           </ng-container>

           <tr mat-header-row *matHeaderRowDef="displayedColumnIntervaleIndisponibilitate"></tr>
           <tr mat-row *matRowDef="let row; columns: displayedColumnIntervaleIndisponibilitate;"></tr>

           <!-- Row shown when there is no matching data. -->
           <tr class="mat-row" *matNoDataRow>
             <td class="mat-cell" colspan="4">Nu am gasit nimic</td>
           </tr>
         </table>
          </div>
        </div>


        <!-- STATUS BENEFICIAR ROW -- DE REVENIT -- -->

        <!-- <div class="row-ish" style="width:48%;margin:1%;height: 500px;">
          <div class="row">
            <span> Status Beneficiar</span>
          </div>
          <div class="row">
            <span> Status Beneficiar</span>
          </div>
        </div> -->

        <!-- END -- STATUS BENEFICIAR ROW -- DE REVENIT -- -->
      </div>

      </mat-tab>

      <!--  END Pacient -- Date Personale -->

      <!--  Pacient -- Date de Contact -->

      <mat-tab label="Date de Contact" style="display: flex;">
        <div class="row-date-contact">
          <div class=" flex-align-center" [ngClass]="{'panel-add-telefon-email' : !flag_telefon_email_saved, 'panel-add-telefon-email-green' : flag_telefon_email_saved}">
            <div class="flex-align-center"
            [matTooltipDisabled]="!flag_telefon_email_saved"
            matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
            >
              <mat-form-field appearance="outline">
                <mat-label>Telefon</mat-label>
                <input matInput type="text" [maxLength]="10" [formControl]="telefon_beneficiar" [disabled]= "flag_telefon_email_saved"
                [ngClass]="{'input-telefon-email-valid' : (telefon_beneficiar.valid && (telefon_beneficiar.value != '')) }"
                >
                <!-- <input matInput type="number" [(ngModel)]="date_contact.telefon" [disabled]= flag_telefon_email_saved> -->
                <mat-hint align="end"> {{ telefon_beneficiar.value != ''  ? returnCaractereTelefon(telefon_beneficiar.value) : 0  }}/10</mat-hint>
                <mat-error class="eroare-email-invalid" *ngIf="telefon_beneficiar.invalid">Numarul de telefon nu este valid </mat-error>

              </mat-form-field>
            </div>
            <div class="flex-align-center"
              [matTooltipDisabled]="!flag_telefon_email_saved"
              matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
            >
            <mat-form-field appearance="outline" >
              <mat-label>Email</mat-label>
              <input matInput type="email" [formControl]="email_beneficiar" [disabled]= "flag_telefon_email_saved"
              [ngClass]="{'input-telefon-email-valid' : (email_beneficiar.valid && (email_beneficiar.value != '')) }"
               >
              <mat-error class="eroare-email-invalid" *ngIf="email_beneficiar.invalid">Adresa de email nu este valida. Introduceti un email de forma examplu @ domeniu . com </mat-error>
            </mat-form-field>
            </div>
            <div class="flex-align-center" *ngIf="!flag_telefon_email_saved" [matTooltipDisabled]="!((pacienttwo?.date_contact?.telefon === undefined) && (pacienttwo?.date_contact?.email === undefined))" matTooltip="Introdu un numar de telefon sau o adresa de email pentru a putea salva">
              <button mat-raised-button class="save-button" (click)="saveTelefonEmailBeneficiar()" [disabled]="!((email_beneficiar.valid && (email_beneficiar.value != '')) || (telefon_beneficiar.valid && (telefon_beneficiar.value != '')))">
                Salveaza
              </button>
            </div>
            <div class="flex-align-center" *ngIf="flag_telefon_email_saved">
              <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaTelefonEmailBeneficiar()">
                Editeaza
              </button>
            </div>
          </div>
        </div>
        <br>

        <div class="flex-align-center" style="justify-content: center;">
          <div *ngIf="!flag_adresa_beneficiar_add">
            <button mat-raised-button class="save-button" (click)="adaugaAdresaBeneficiar()" [disabled]="flag_beneficiar_fara_adresa">
              + Adauga adresa
            </button>
            <button mat-raised-button (click)="beneficiarFaraAdresa()" [ngClass]="{'save-button': flag_beneficiar_fara_adresa, 'edit-telefon-email-button': !flag_beneficiar_fara_adresa }">
              Fara adresa
            </button>
          </div>
        </div>

        <div class="row-date-contact" *ngIf="flag_adresa_beneficiar_add">
          <div class=" flex-align-center " [ngClass]="{'panel-add-adresa-green': flag_adresa_beneficiar_set, 'panel-add-adresa': !flag_adresa_beneficiar_set }">
            <div style="width: 100%;">
            </div>
            <mat-form-field style="max-width: 30%;" appearance="outline">
              <mat-label>Judet</mat-label>
              <input matInput type="text" [(ngModel)]="dateContactDialogCardValues.adresa.judet" required [disabled]="flag_adresa_beneficiar_set">
            </mat-form-field>

          <mat-form-field style="max-width: 30%;" appearance="outline">
            <mat-label>Localitate</mat-label>
            <input matInput type="text" [(ngModel)]="dateContactDialogCardValues.adresa.localitate" required [disabled]="flag_adresa_beneficiar_set">
          </mat-form-field>

          <mat-form-field style="max-width: 30%;" appearance="outline">
            <mat-label>Strada</mat-label>
            <input matInput type="text" [(ngModel)]="dateContactDialogCardValues.adresa.strada" required [disabled]="flag_adresa_beneficiar_set">
          </mat-form-field>

          <mat-form-field style="max-width: 80px;" appearance="outline">
            <mat-label>Numar</mat-label>
            <input matInput type="text" [(ngModel)]="dateContactDialogCardValues.adresa.numar" [disabled]="flag_adresa_beneficiar_set">
          </mat-form-field>

          <mat-form-field style="max-width: 100px;" appearance="outline">
            <mat-label>Bloc</mat-label>
            <input matInput type="text" [(ngModel)]="dateContactDialogCardValues.adresa.bloc" [disabled]="flag_adresa_beneficiar_set">
          </mat-form-field>

          <mat-form-field style="max-width: 80px;" appearance="outline">
            <mat-label>Etaj</mat-label>
            <input matInput type="text" [(ngModel)]="dateContactDialogCardValues.adresa.etaj" [disabled]="flag_adresa_beneficiar_set">
          </mat-form-field>

          <mat-form-field style="max-width: 120px;" appearance="outline">
            <mat-label>Apartament</mat-label>
            <input matInput type="text" [(ngModel)]="dateContactDialogCardValues.adresa.ap" [disabled]="flag_adresa_beneficiar_set">
          </mat-form-field>
          </div>

          <button mat-raised-button class="save-button" (click)="salveazaAdresaBeneficiar()" *ngIf="!flag_adresa_beneficiar_set" style="max-height: 40px;"> Salveaza Adresa</button>
          <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaAdresaBeneficiar()" *ngIf="flag_adresa_beneficiar_set" style="max-height: 40px;"> Editeaza Adresa</button>
          <button mat-raised-button color="warn" *ngIf="!flag_adresa_beneficiar_set" (click)="anuleazaAdresaBeneficiar()" style="max-height: 40px;"> Anuleaza</button>
          <button mat-raised-button color="warn" *ngIf="flag_adresa_beneficiar_set" (click)="stergeAdresaBeneficiar()" style="max-height: 40px;"> Sterge Adresa</button>


        </div>

      </mat-tab>

      <!-- END Pacient -- Date de Contact -->

      <!-- Reprezentant Legal -->

      <mat-tab label="Reprezentant legal">

        <br>
        <div id="TabelRL">

          <table *ngIf="dateRLCardValues.reprezentanti_legali.length > 0 && addnewperson == 1 " mat-table [dataSource]="dataSourceRL" style="width:95%" class="TabelReprezentantiLegali">

            <!-- Nume Column -->
            <ng-container matColumnDef="nume">
              <th mat-header-cell *matHeaderCellDef> Nume </th>
              <td mat-cell *matCellDef="let element"> {{textOrDash(element?.datepersonale?.nume)}}
              </td>
            </ng-container>


            <!-- Prenume Column -->
            <ng-container matColumnDef="prenume">
              <th mat-header-cell *matHeaderCellDef> Prenume </th>
              <td mat-cell *matCellDef="let element">
                {{ textOrDash(element?.datepersonale?.prenume) }} </td>
            </ng-container>

            <!-- Calitate Column -->
            <ng-container matColumnDef="calitate">
              <th mat-header-cell *matHeaderCellDef> Calitate </th>
              <!-- <td mat-cell *matCellDef="let element"> {{ element | json}}</td> -->
              <td mat-cell *matCellDef="let element"> {{getCalitateKey(element)}} </td>
            </ng-container>

            <!-- Telefon Column -->
            <ng-container matColumnDef="telefon">
              <th mat-header-cell *matHeaderCellDef> Telefon </th>
              <td mat-cell *matCellDef="let element"> {{textOrDash(element?.datecontact?.telefon)}}
              </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{textOrDash(element?.datecontact?.email)}}
              </td>
            </ng-container>

            <!-- Edit Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef>  </th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="edit-button" [disabled]="flag_RL_in_editare"
                (click)="editeazaReprezentantLegal(element)">editeaza</button>
              </td>
            </ng-container>

            <!-- Delete Column -->

            <ng-container matColumnDef="deleteselect">
              <th mat-header-cell *matHeaderCellDef>
                <button  mat-raised-button color="warn" [disabled]="todeleteRL.length == 0" (click)="deleteRL()" > Sterge </button>
              </th>
              <td mat-cell *matCellDef="let element">

                <mat-checkbox (change)="marktodeleteRL(element)" value="checkRLinListaDeSters(element)"></mat-checkbox>

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsRL"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsRL;" class="table-row" ></tr>

          </table>

        </div>

          <ng-container *ngIf='dateRLCardValues.reprezentanti_legali.length < 1 || addnewperson == 0'>
            <div *ngIf="flag_RL_gol">
              <span>
                Beneficiarul nu are niciun REPREZENTANT LEGAL atribuit
              </span>
              <button mat-raised-button color="primary" (click)="adaugaPrimulRL()" *ngIf="selectedTabRL < 1 && addnewperson == 0">Adauga RL</button>
            </div>
            <mat-tab-group *ngIf='(dateRLCardValues.reprezentanti_legali.length < 1 || addnewperson == 0) && !flag_RL_gol' mat-align-tabs="center" animationDuration="500ms" [(selectedIndex)]="selectedTabRL" id="ReprezentantLegal">

              <!-- Reprezentant Legal Non User -- Date personale -->

              <mat-tab label="Date Personale" id="DatePersonaleRL">

                <div id="datepersonaleRL">

                  <mat-form-field id="DatePersonaleNumeRL" appearance="outline">
                    <mat-label>Nume</mat-label>
                    <input matInput type="text" [ngModel]= "reprezentant_legal?.datepersonale?.nume" (ngModelChange)="reprezentant_legal.datepersonale.nume=$event" appearance="outline">
                  </mat-form-field>

                  <mat-form-field id="DatePersonalePrenumeRL" appearance="outline">
                    <mat-label>Prenume</mat-label>
                    <input matInput type="text" [ngModel]= "reprezentant_legal?.datepersonale?.prenume" (ngModelChange)="reprezentant_legal.datepersonale.prenume=$event" appearance="outline">
                  </mat-form-field>

                  <mat-form-field appearance="outline" id="DatePersonaleCNPRL">
                    <mat-label>CNP</mat-label>
                    <input matInput type="number" [ngModel]= "reprezentant_legal?.datepersonale?.cnp" (ngModelChange)="reprezentant_legal.datepersonale.cnp=$event" appearance="outline">
                  </mat-form-field>

                  <!-- <div class="flex-align-center"  > -->
                    <mat-form-field appearance="outline" id="DatePersonaleSexRL">
                      <mat-label>Sex</mat-label>
                      <mat-select [ngModel]="reprezentant_legal.datepersonale.sex" (ngModelChange)="reprezentant_legal.datepersonale.sex=$event" appearance="outline">
                        <mat-option value="masculin">Masculin</mat-option>
                        <mat-option value="feminin">Feminin</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" id="DatePersonaleDataNastereRL" appearance="outline">
                      <mat-label>Data Nasterii</mat-label>
                      <input matInput [matDatepicker]="picker2" [ngModel]= "reprezentant_legal?.datepersonale?.data_nasterii" (ngModelChange)="reprezentant_legal.datepersonale.data_nasterii=$event">
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" id="CalitateaRL" appearance="outline">
                      <mat-label>Calitatea</mat-label>
                      <mat-select [ngModel]="reprezentant_legal?.calitateid" (ngModelChange)="reprezentant_legal.calitateid=$event" >
                        <mat-option *ngFor="let calitatea of calitate" [value]="calitatea.id">
                          {{calitatea.calitate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="flag_reprezentant_legal_ci" appearance="outline" style="display: flex;align-items: center; font-size: 14px" appearance="outline">
                      <mat-label>Serie CI</mat-label>
                      <input matInput type="text" [ngModel]="reprezentant_legal?.datepersonale?.serie_carte_identitate" (ngModelChange)="reprezentant_legal.datepersonale.serie_carte_identitate=$event">
                    </mat-form-field>

                    <mat-form-field *ngIf="flag_reprezentant_legal_ci" appearance="outline" style="display: flex;align-items: center; font-size: 14px" appearance="outline">
                      <mat-label>Numar CI</mat-label>
                      <input matInput type="text" [ngModel]="reprezentant_legal?.datepersonale?.numar_carte_identitate" (ngModelChange)="reprezentant_legal.datepersonale.numar_carte_identitate=$event">
                    </mat-form-field>

                    <div style="display: flex;" class="slider-first-padding-font-set">
                      <div style="display: flex;align-items: center;">
                        Carte de identitate
                      </div>
                      <div class="separator-flex-align-padding">
                        :
                      </div>
                      <div class="flex-align-center">
                        <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectatatwo': !getStatusCIReprezentantLegal()  }">
                          NU
                        </div>
                        <div class="flex-align-center wrapper-selector " >
                          <mat-slide-toggle
                            [(ngModel)]="flag_reprezentant_legal_ci"
                            (change)="changedCIRL()"
                            class="slider-padding-left-set"
                            [ngClass]="{'optiune-selectata-selector': getStatusCIReprezentantLegal()  }"
                            >
                            DA
                          </mat-slide-toggle>
                        </div>
                      </div>
                    </div>
                  <!-- </div> -->

              </div>
              </mat-tab>

              <!-- END Reprezentant Legal -- Date personale -->

              <!-- Reprezentant Legal  -- Date de Contact -->

              <mat-tab label="Date de Contact" class="DateContactRL" >

                  <div class="row-date-contact" id="TelefonEmailRL">
                    <div class=" flex-align-center" [ngClass]="{'panel-add-telefon-emailtwo' : !flag_telefon_email_RL_saved, 'panel-add-telefon-email-greentwo' : flag_telefon_email_RL_saved}">

                      <div class="flex-align-center"
                      [matTooltipDisabled]="!flag_telefon_email_RL_saved"
                      matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
                      >
                        <mat-form-field appearance="outline">
                          <mat-label>Telefon</mat-label>
                          <input matInput type="text" [maxLength]="10" [formControl]="telefon_RL" [disabled]= "flag_telefon_email_RL_saved"
                          [ngClass]="{'input-telefon-email-valid' : (telefon_RL.valid && (telefon_RL.value != '')) }"
                          >
                          <mat-hint align="end"> {{ telefon_RL.value != ''  ? returnCaractereTelefon(telefon_RL.value) : 0  }}/10</mat-hint>
                          <mat-error class="eroare-email-invalid" *ngIf="telefon_RL.invalid">Numarul de telefon nu este valid </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="flex-align-center"
                      [matTooltipDisabled]="!flag_telefon_email_RL_saved"
                      matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
                      >
                        <mat-form-field appearance="outline">
                          <mat-label>Email</mat-label>
                          <input matInput type="email" [formControl]="email_RL" [disabled]= "flag_telefon_email_RL_saved"
                          [ngClass]="{'input-telefon-email-valid' : (email_RL.valid && (email_RL.value != '')) }"
                          >
                          <mat-error class="eroare-email-invalid" *ngIf="email_RL.invalid">Adresa de email nu este valida. Introduceti un email de forma examplu @ domeniu . com </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="flex-align-center" *ngIf="!flag_telefon_email_RL_saved && !this.flag_RL_in_editare" [matTooltipDisabled]="!((reprezentant_legal.datecontact?.telefon === undefined) && (reprezentant_legal.datecontact?.email === undefined))" matTooltip="Introdu un numar de telefon sau o adresa de email pentru a putea salva">
                        <button mat-raised-button class="save-button" (click)="saveTelefonEmailRL()" [disabled]="!((email_RL.valid && (email_RL.value != '')) || (telefon_RL.valid && (telefon_RL.value != '')))">
                          Salveaza
                        </button>
                      </div>
                      <div class="flex-align-center" *ngIf="!flag_telefon_email_RL_saved && this.flag_RL_in_editare" [matTooltipDisabled]="!((reprezentant_legal.datecontact?.telefon === undefined) && (reprezentant_legal.datecontact?.email === undefined))" matTooltip="Introdu un numar de telefon sau o adresa de email pentru a putea salva">
                        <button mat-raised-button class="save-button" (click)="saveTelefonEmailRLEditat()" [disabled]="!((email_RL.valid && (email_RL.value != '')) || (telefon_RL.valid && (telefon_RL.value != '')))">
                          Salveaza modificarile
                        </button>
                      </div>
                      <div class="flex-align-center" *ngIf="flag_telefon_email_RL_saved">
                        <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaTelefonEmailRL()">
                          Editeaza
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="flex-align-center" style="justify-content: center;">
                    <div *ngIf="!flag_adresa_RL_add">
                      <button mat-raised-button class="save-button" (click)="copiazaAdresaBeneficiarRL()" [disabled]="flag_RL_fara_adresa" *ngIf="flag_adresa_beneficiar_set">
                        + Foloseste adresa beneficiar
                      </button>
                      <button mat-raised-button class="save-button" (click)="adaugaAdresaRL()" [disabled]="flag_RL_fara_adresa">
                        + Adauga adresa
                      </button>
                      <button mat-raised-button (click)="RLFaraAdresa()" [ngClass]="{'save-button': flag_RL_fara_adresa, 'edit-telefon-email-button': !flag_RL_fara_adresa }">
                        Fara adresa
                      </button>
                    </div>
                  </div>

                  <div class="row-date-contact" style="margin-top: 20px;" *ngIf="flag_adresa_RL_add">
                    <div class=" flex-align-center " [ngClass]="{'panel-add-adresa-greentwo': flag_adresa_RL_set, 'panel-add-adresatwo': !flag_adresa_RL_set }">

                        <mat-form-field appearance="outline" style="max-width: 30%;">
                          <mat-label>Judet</mat-label>
                          <input matInput type="text" [ngModel]="adresaRL?.judet" (ngModelChange)="adresaRL.judet=$event" required [disabled]="flag_adresa_RL_set">
                        </mat-form-field>

                      <mat-form-field appearance="outline" style="max-width: 30%;">
                        <mat-label>Localitate</mat-label>
                        <input matInput type="text" [ngModel]="adresaRL?.localitate" (ngModelChange)="adresaRL.localitate=$event" required [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                      <mat-form-field appearance="outline" style="max-width: 30%;">
                        <mat-label>Strada</mat-label>
                        <input matInput type="text" [ngModel]="adresaRL?.strada" (ngModelChange)="adresaRL.strada=$event" required [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                      <mat-form-field appearance="outline" style="max-width: 80px;">
                        <mat-label>Numar</mat-label>
                        <input matInput type="text" [ngModel]="adresaRL?.numar" (ngModelChange)="adresaRL.numar=$event" [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                      <mat-form-field appearance="outline" style="max-width: 100px;">
                        <mat-label>Bloc</mat-label>
                        <input matInput type="text" [ngModel]="adresaRL?.bloc" (ngModelChange)="adresaRL.bloc=$event" [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                      <mat-form-field appearance="outline" style="max-width: 80px;">
                        <mat-label>Etaj</mat-label>
                        <input matInput type="text" [ngModel]="adresaRL?.etaj" (ngModelChange)="adresaRL.etaj=$event" [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                      <mat-form-field appearance="outline" style="max-width: 120px;">
                        <mat-label>Apartament</mat-label>
                        <input matInput type="text" [ngModel]="adresaRL?.ap" (ngModelChange)="adresaRL.ap=$event" [disabled]="flag_adresa_RL_set">
                      </mat-form-field>

                    </div>
                    <button mat-raised-button class="save-button" (click)="salveazaAdresaRL()" *ngIf="!flag_adresa_RL_set"> Salveaza Adresa</button>
                    <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaAdresaRL ()" *ngIf="flag_adresa_RL_set"> Editeaza Adresa</button>
                    <button mat-raised-button color="warn" *ngIf="!flag_adresa_RL_set" (click)="anuleazaAdresaRL()"> Anuleaza</button>
                    <button mat-raised-button color="warn" *ngIf="flag_adresa_RL_set" (click)="stergeAdresaRL()"> Sterge Adresa</button>
                  </div>

              </mat-tab>

              <!-- END Reprezentant Legal Non User -- Date de Contact -->

            </mat-tab-group>
          </ng-container>
          <!-- <ng-container *ngIf='lista.length > 0 && addnewperson == 1'> -->
            <button mat-raised-button color="primary" (click)="reInitRL()" *ngIf='this.dateRLCardValues.reprezentanti_legali.length > 0 && addnewperson == 1' >Adauga RL nou</button>
          <!-- </ng-container> -->

          <div id="Butoane RL" *ngIf="!flag_RL_gol">
            <button mat-raised-button color="warn" (click)="cancelRL()" *ngIf=" addnewperson == 0">Anuleaza</button>
            <button mat-raised-button color="primary" (click)="setReprezentantiLegalList()" *ngIf="selectedTabRL == 1 && addnewperson == 0 && !flag_RL_in_editare ">Adauga RL</button>
            <button mat-raised-button color="warn" (click)="anuleazaRLEditat()" *ngIf="addnewperson == 0 && flag_RL_in_editare ">Anuleaza modificarile</button>
            <button mat-raised-button class="save-button" (click)="salveazaRLEditat()" *ngIf="addnewperson == 0 && flag_RL_in_editare ">Salveaza Modificarile</button>
            <button mat-raised-button color="primary" (click)="goToNextTabRL()" *ngIf="selectedTabRL < 1 && addnewperson == 0">Adauga Date de contact</button>
            <!-- <button mat-raised-button color="primary" (click)="saveDatePersonaleRL()" *ngIf="selectedTabRL < 1 && addnewperson == 0">Salveaza Datele Personale</button> -->
            <br>
            <br>
            RL = Reprezentant Legal
            <br>
          </div>
        <!-- END Reprezentant Legal Non User  -->
      </mat-tab>

      <!-- END Reprezentant Legal -->

      <!-- Persoana de Contact -->

      <mat-tab label="Persoana de contact">

        <ng-container>
          <br>
          <br>

          <mat-slide-toggle (change)="changePCegalRLslide()" [(ngModel)]="datePCCardValues.reprezentant_legal_persoana_de_contact">Alege reprezentantul legal ca persoana de contact</mat-slide-toggle>

          <br>
          <br>
        </ng-container>
        <ng-container *ngIf='!datePCCardValues.reprezentant_legal_persoana_de_contact'>
          <!-- RL = PC ==>> {{datePCCardValues.reprezentant_legal_persoana_de_contact}} <br>
          PC LEN ==>> {{datePCCardValues.persoane_contact.length}} <br>
          addnewcontactperson ==>> {{addnewcontactperson}} <br> -->

          <ng-container *ngIf='datePCCardValues.persoane_contact.length < 1 || addnewcontactperson == 0'>
            <div *ngIf="flag_PC_gol">
              <span>
                Beneficiarul nu are nicio PERSOANA DE CONTACT atribuita
              </span>
              <button mat-raised-button color="primary" (click)="adaugaPrimulPC()" *ngIf="selectedTabPC < 1 && addnewcontactperson == 0">Adauga PC</button>
            </div>
            <mat-tab-group *ngIf='(datePCCardValues.persoane_contact.length < 1 || addnewcontactperson == 0) && !flag_PC_gol' mat-align-tabs="center" animationDuration="500ms" [(selectedIndex)]="selectedTabPC" id="PersoanaContact">

              <!-- Reprezentant Legal Non User -- Date personale -->

              <mat-tab label="Date Personale" id="DatePersonalePC">

                <div id="datepersonalePC">

                  <mat-form-field id="DatePersonaleNumePC" appearance="outline">
                    <mat-label>Nume</mat-label>
                    <input matInput type="text" [ngModel]= "persoana_contact?.datepersonale?.nume" (ngModelChange)="persoana_contact.datepersonale.nume=$event" appearance="outline">
                  </mat-form-field>

                  <mat-form-field id="DatePersonalePrenumePC" appearance="outline">
                    <mat-label>Prenume</mat-label>
                    <input matInput type="text" [ngModel]= "persoana_contact?.datepersonale?.prenume" (ngModelChange)="persoana_contact.datepersonale.prenume=$event" appearance="outline">
                  </mat-form-field>

                  <mat-form-field appearance="outline" id="DatePersonaleCNPPC">
                    <mat-label>CNP</mat-label>
                    <input matInput type="number" [ngModel]= "persoana_contact?.datepersonale?.cnp" (ngModelChange)="persoana_contact.datepersonale.cnp=$event" appearance="outline">
                  </mat-form-field>

                  <!-- <div class="flex-align-center"  > -->
                    <mat-form-field appearance="outline" id="DatePersonaleSexPC">
                      <mat-label>Sex</mat-label>
                      <mat-select [ngModel]="persoana_contact?.datepersonale?.sex" (ngModelChange)="persoana_contact.datepersonale.sex=$event" appearance="outline">
                        <mat-option value="masculin">Masculin</mat-option>
                        <mat-option value="feminin">Feminin</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" id="DatePersonaleDataNasterePC" appearance="outline">
                      <mat-label>Data Nasterii</mat-label>
                      <input matInput [matDatepicker]="picker2" [ngModel]= "persoana_contact?.datepersonale?.data_nasterii" (ngModelChange)="persoana_contact.datepersonale.data_nasterii=$event">
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" id="CalitateaPC" appearance="outline">
                      <mat-label>Calitatea</mat-label>
                      <mat-select [ngModel]="persoana_contact?.calitateid" (ngModelChange)="persoana_contact.calitateid=$event" >
                        <mat-option *ngFor="let calitatea of calitate" [value]="calitatea.id">
                          {{calitatea.calitate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="flag_persoana_contact_ci" appearance="outline" style="display: flex;align-items: center; font-size: 14px" appearance="outline">
                      <mat-label>Serie CI</mat-label>
                      <input matInput type="text" [ngModel]="persoana_contact?.datepersonale?.serie_carte_identitate" (ngModelChange)="persoana_contact.datepersonale.serie_carte_identitate=$event">
                    </mat-form-field>

                    <mat-form-field *ngIf="flag_persoana_contact_ci" appearance="outline" style="display: flex;align-items: center; font-size: 14px" appearance="outline">
                      <mat-label>Numar CI</mat-label>
                      <input matInput type="text" [ngModel]="persoana_contact?.datepersonale?.numar_carte_identitate" (ngModelChange)="persoana_contact.datepersonale.numar_carte_identitate=$event">
                    </mat-form-field>

                    <div style="display: flex;" class="slider-first-padding-font-set">
                      <div style="display: flex;align-items: center;">
                        Carte de identitate
                      </div>
                      <div class="separator-flex-align-padding">
                        :
                      </div>
                      <div class="flex-align-center">
                        <div class="flex-align-center" [ngClass]="{'optiune-negativa-selectatatwo': !getStatusCIPersoanaContact()  }">
                          NU
                        </div>
                        <div class="flex-align-center wrapper-selector " >
                          <mat-slide-toggle
                            [(ngModel)]="flag_persoana_contact_ci"
                            (change)="changedCIPC()"
                            class="slider-padding-left-set"
                            [ngClass]="{'optiune-selectata-selector': getStatusCIPersoanaContact()  }"
                            >
                            DA
                          </mat-slide-toggle>
                        </div>
                      </div>
                    </div>

                </div>
              </mat-tab>

              <mat-tab label="Date de Contact" class="DateContactPC" >

                <div class="row-date-contact" id="TelefonEmailPC">
                  <div class=" flex-align-center" [ngClass]="{'panel-add-telefon-emailtwo' : !flag_telefon_email_PC_saved, 'panel-add-telefon-email-greentwo' : flag_telefon_email_PC_saved}">

                    <div class="flex-align-center"
                    [matTooltipDisabled]="!flag_telefon_email_PC_saved"
                    matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
                    >
                      <mat-form-field appearance="outline">
                        <mat-label>Telefon</mat-label>
                        <input matInput type="text" [maxLength]="10" [formControl]="telefon_PC" [disabled]= "flag_telefon_email_PC_saved"
                        [ngClass]="{'input-telefon-email-valid' : (telefon_PC.valid && (telefon_PC.value != '')) }"
                        >
                        <mat-hint align="end"> {{ telefon_PC.value != ''  ? returnCaractereTelefon(telefon_PC.value) : 0  }}/10</mat-hint>
                        <mat-error class="eroare-email-invalid" *ngIf="telefon_PC.invalid">Numarul de telefon nu este valid </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="flex-align-center"
                    [matTooltipDisabled]="!flag_telefon_email_PC_saved"
                    matTooltip="Datele au fost salvate. Pentru a le modifica acceseaza butonul Editeaza"
                    >
                      <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" [formControl]="email_PC" [disabled]= "flag_telefon_email_PC_saved"
                        [ngClass]="{'input-telefon-email-valid' : (email_PC.valid && (email_PC.value != '')) }"
                        >
                        <mat-error class="eroare-email-invalid" *ngIf="email_PC.invalid">Adresa de email nu este valida. Introduceti un email de forma examplu @ domeniu . com </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="flex-align-center" *ngIf="!flag_telefon_email_PC_saved && !this.flag_PC_in_editare" [matTooltipDisabled]="!((persoana_contact.datecontact?.telefon === undefined) && (persoana_contact.datecontact?.email === undefined))" matTooltip="Introdu un numar de telefon sau o adresa de email pentru a putea salva">
                      <button mat-raised-button class="save-button" (click)="saveTelefonEmailPC()" [disabled]="!((email_PC.valid && (email_PC.value != '')) || (telefon_PC.valid && (telefon_PC.value != '')))">
                        Salveaza
                      </button>
                    </div>
                    <div class="flex-align-center" *ngIf="!flag_telefon_email_PC_saved && this.flag_PC_in_editare" [matTooltipDisabled]="!((persoana_contact.datecontact?.telefon === undefined) && (persoana_contact.datecontact?.email === undefined))" matTooltip="Introdu un numar de telefon sau o adresa de email pentru a putea salva">
                      <button mat-raised-button class="save-button" (click)="saveTelefonEmailPCEditat()" [disabled]="!((email_PC.valid && (email_PC.value != '')) || (telefon_PC.valid && (telefon_PC.value != '')))">
                        Salveaza modificarile
                      </button>
                    </div>
                    <div class="flex-align-center" *ngIf="flag_telefon_email_PC_saved">
                      <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaTelefonEmailPC()">
                        Editeaza
                      </button>
                    </div>
                  </div>
                </div>

                <div class="flex-align-center" style="justify-content: center;">
                  <div *ngIf="!flag_adresa_PC_add">
                    <button mat-raised-button class="save-button" (click)="copiazaAdresaBeneficiarPC()" [disabled]="flag_PC_fara_adresa" *ngIf="flag_adresa_beneficiar_set">
                      + Foloseste adresa beneficiar
                    </button>
                    <button mat-raised-button class="save-button" (click)="adaugaAdresaPC()" [disabled]="flag_PC_fara_adresa">
                      + Adauga adresa
                    </button>
                    <button mat-raised-button (click)="PCFaraAdresa()" [ngClass]="{'save-button': flag_PC_fara_adresa, 'edit-telefon-email-button': !flag_PC_fara_adresa }">
                      Fara adresa
                    </button>
                  </div>
                </div>

                <div class="row-date-contact" style="margin-top: 20px;" *ngIf="flag_adresa_PC_add">
                  <div class=" flex-align-center " [ngClass]="{'panel-add-adresa-greentwo': flag_adresa_PC_set, 'panel-add-adresatwo': !flag_adresa_PC_set }">

                      <mat-form-field appearance="outline" style="max-width: 30%;">
                        <mat-label>Judet</mat-label>
                        <input matInput type="text" [ngModel]="adresaPC?.judet" (ngModelChange)="adresaPC.judet=$event" required [disabled]="flag_adresa_PC_set">
                      </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 30%;">
                      <mat-label>Localitate</mat-label>
                      <input matInput type="text" [ngModel]="adresaPC?.localitate" (ngModelChange)="adresaPC.localitate=$event" required [disabled]="flag_adresa_PC_set">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 30%;">
                      <mat-label>Strada</mat-label>
                      <input matInput type="text" [ngModel]="adresaPC?.strada" (ngModelChange)="adresaPC.strada=$event" required [disabled]="flag_adresa_PC_set">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 80px;">
                      <mat-label>Numar</mat-label>
                      <input matInput type="text" [ngModel]="adresaPC?.numar" (ngModelChange)="adresaPC.numar=$event" [disabled]="flag_adresa_PC_set">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 100px;">
                      <mat-label>Bloc</mat-label>
                      <input matInput type="text" [ngModel]="adresaPC?.bloc" (ngModelChange)="adresaPC.bloc=$event" [disabled]="flag_adresa_PC_set">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 80px;">
                      <mat-label>Etaj</mat-label>
                      <input matInput type="text" [ngModel]="adresaPC?.etaj" (ngModelChange)="adresaPC.etaj=$event" [disabled]="flag_adresa_PC_set">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 120px;">
                      <mat-label>Apartament</mat-label>
                      <input matInput type="text" [ngModel]="adresaPC?.ap" (ngModelChange)="adresaPC.ap=$event" [disabled]="flag_adresa_PC_set">
                    </mat-form-field>

                  </div>
                  <button mat-raised-button class="save-button" (click)="salveazaAdresaPC()" *ngIf="!flag_adresa_PC_set"> Salveaza Adresa</button>
                  <button mat-raised-button class="edit-telefon-email-button" (click)="editeazaAdresaPC ()" *ngIf="flag_adresa_PC_set"> Editeaza Adresa</button>
                  <button mat-raised-button color="warn" *ngIf="!flag_adresa_PC_set" (click)="anuleazaAdresaPC()"> Anuleaza</button>
                  <button mat-raised-button color="warn" *ngIf="flag_adresa_PC_set" (click)="stergeAdresaPC()"> Sterge Adresa</button>
                </div>

              </mat-tab>
            </mat-tab-group>

          </ng-container>

          <button mat-raised-button color="primary" (click)="reInitPC()" *ngIf='this.datePCCardValues.persoane_contact.length > 0 && addnewcontactperson == 1' >Adauga PC nou </button>

          <div id="TabelPC">
            <table *ngIf="datePCCardValues.persoane_contact.length > 0 && addnewcontactperson == 1 " mat-table [dataSource]="dataSourcePC" style="width:95%" class="TabelReprezentantiLegali">

                          <!-- Nume Column -->
                          <ng-container matColumnDef="nume">
                            <th mat-header-cell *matHeaderCellDef> Nume </th>
                            <td mat-cell *matCellDef="let element"> {{textOrDash(element?.datepersonale?.nume)}}
                            </td>
                          </ng-container>

                          <!-- Prenume Column -->
                          <ng-container matColumnDef="prenume">
                            <th mat-header-cell *matHeaderCellDef> Prenume </th>
                            <td mat-cell *matCellDef="let element">
                              {{ textOrDash(element?.datepersonale?.prenume) }} </td>
                          </ng-container>

                          <!-- Calitate Column -->
                          <ng-container matColumnDef="calitate">
                            <th mat-header-cell *matHeaderCellDef> Calitate </th>
                            <!-- <td mat-cell *matCellDef="let element"> {{ element | json}}</td> -->
                            <td mat-cell *matCellDef="let element"> {{getCalitateKey(element)}} </td>
                          </ng-container>

                          <!-- Telefon Column -->
                          <ng-container matColumnDef="telefon">
                            <th mat-header-cell *matHeaderCellDef> Telefon </th>
                            <td mat-cell *matCellDef="let element"> {{textOrDash(element?.datecontact?.telefon)}}
                            </td>
                          </ng-container>

                          <!-- Email Column -->
                          <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Email </th>
                            <td mat-cell *matCellDef="let element"> {{textOrDash(element?.datecontact?.email)}}
                            </td>
                          </ng-container>

                          <!-- Edit Column -->
                          <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let element">
                              <button mat-raised-button class="edit-button" [disabled]="flag_PC_in_editare"
                              (click)="editeazaPersoanaContact(element)">editeaza</button>
                            </td>
                          </ng-container>

                          <!-- Delete Column -->

                          <ng-container matColumnDef="deleteselect">
                            <th mat-header-cell *matHeaderCellDef>
                              <button  mat-raised-button color="warn" [disabled]="todeletePC.length == 0" (click)="deletePC()" > Sterge </button>
                            </th>
                            <td mat-cell *matCellDef="let element">

                              <mat-checkbox (change)="marktodeletePC(element)" value="checkPCinListaDeSters(element)"></mat-checkbox>

                            </td>
                          </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsPC"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsPC;" class="table-row" ></tr>

            </table>
          </div>

          <div id="Butoane PC" *ngIf="!flag_PC_gol">
            <button mat-raised-button color="warn" (click)="cancelPC()" *ngIf=" addnewcontactperson == 0">Anuleaza</button>
            <button mat-raised-button color="primary" (click)="setPersoaneContactList()" *ngIf="selectedTabPC == 1 && addnewcontactperson == 0 && !flag_PC_in_editare ">Adauga PC</button>
            <button mat-raised-button color="warn" (click)="anuleazaPCEditat()" *ngIf="addnewcontactperson == 0 && flag_PC_in_editare ">Anuleaza modificarile</button>
            <button mat-raised-button class="save-button" (click)="salveazaPCEditat()" *ngIf="addnewcontactperson == 0 && flag_PC_in_editare ">Salveaza Modificarile</button>
            <button mat-raised-button color="primary" (click)="goToNextTabPC()" *ngIf="selectedTabPC < 1 && addnewcontactperson == 0">Adauga Date de contact</button>
            <!-- <button mat-raised-button color="primary" (click)="saveDatePersonaleRL()" *ngIf="selectedTabRL < 1 && addnewperson == 0">Salveaza Datele Personale</button> -->
            <br>
            <br>
            PC = Persoana de Contact
            <br>
          </div>
    </ng-container>

  <!-- END Reprezentant Legal Non User  -->
  </mat-tab>
    </mat-tab-group>

</div>
<div class="spacer"></div>

<div mat-dialog-actions style="display:flex; align-self: stretch;">
    <button mat-raised-button color="warn" mat-dialog-close >Cancel</button>
    <button mat-raised-button color="primary" (click)="sendvalues()" >Ok</button>
    <button mat-raised-button color="accent" (click)="dosar()" >Dosar Electronic</button>
    <button mat-raised-button color="accent" (click)="consultatii()" >Consultatii</button>
    <button mat-raised-button color="accent" (click)="openppi()" >PPI</button>
    <button mat-raised-button color="accent" (click)="opencistoricprogramari()" >Istoric Programari</button>
    <button mat-raised-button color="accent" (click)="opencontracte()" >Contracte</button>
    <button mat-raised-button color="accent" (click)="sendsms()" >Notificare sms</button>
</div>
