import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TipDepartament, TipDepartamentCreate, TipDepartamentCreateFaraValabilitate , Valabilitate, ValabilitateCreate } from 'src/app/models/nomenclatoare.model';
import { TipDepartamentService } from 'src/app/services/tipdepartament.service';
import { IntervalService } from '../../services/interval.service'
import { ValabilitateInterface, ValabilitateCreateInterface, TipDepartamentInterface, TipDepartamentCreateInterface, TipDepartamentCreateFaraValabilitateInterface} from 'src/app/interfaces/nomenclatoare.interface';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { DatePipe } from '@angular/common'
import { MatInput } from '@angular/material/input';
// import { type } from 'os';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TipdepartamenteditComponent } from '../tipdepartamentedit/tipdepartamentedit.component';


export const MY_FORMATS = {
  parse: {
    dateInput: 'd',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-tipdepartament',
  templateUrl: './tipdepartament.component.html',
  styleUrls: ['./tipdepartament.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class TipdepartamentComponent implements OnInit {
  tipDepartament: any[];
  public tipDepartamente: any[]
  displayedColumns: string[] = ['id', 'cod', 'denumire','edit','deleteselect'];
  dataSource: any
  newtipdepartament = new TipDepartamentCreate();
  interval:ValabilitateInterface
  intervalcreate: ValabilitateCreateInterface
  listaintervale:ValabilitateInterface[] = []
  start: Date
  end: Date
  startformated: string
  todelete: any[] = []
  endformated: string
  public IntervalList: ValabilitateInterface [] = []
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialog: MatDialog,public tipDepartamentService: TipDepartamentService, public intervalService: IntervalService,public datepipe: DatePipe) { }

  ngOnInit(): void {
    // this.tipDepartament = this.tipDepartamentService.getTipDepartament();
    // this.dataSource = new MatTableDataSource(this.tipDepartament);
    this.tipDepartamentService.getfromserver()
    .subscribe( (data:TipDepartamentInterface[]) => {
      if (data){
        this.tipDepartamente = data
        this.dataSource  =  new MatTableDataSource(this.tipDepartamente);
          this.dataSource.paginator = this.paginator;
          console.log("Specialitati Medici ==>> ",this.tipDepartamente);
      }
    })
  }
  openTipDepartamentEdit(departament):void{
    const dialogRef = this.dialog.open(TipdepartamenteditComponent, {
      width: '850px',
      height: '550px',
      data: departament
    });
    dialogRef.afterClosed().subscribe( result =>{
      this.updatedata()
    })
  }
  marktodelete(deleteid){
    if (this.todelete.includes(deleteid)){
      console.log("Id-ul deja selectat !! ")
      this.todelete.forEach( (value,index) =>{
        if (value == deleteid) this.todelete.splice(index,1)
      })
    }
    else{
      this.todelete.push(deleteid)
    }
    console.log("Id-uri de sers ==>> ",this.todelete)
  }
  deleteitems(){
    this.todelete.forEach( value =>{
      this.tipDepartamentService.deletefromserver(value).subscribe( (data) =>{
        if (data){
          console.log("Data from delete categorie de servicii ==>> ", data)
          this.updatedata()
        }
      })
    })
  }
  updatedata(){
    this.tipDepartamentService.getfromserver()
    .subscribe((data:TipDepartamentInterface[]) => {
      if (data){
        console.log("data din cat service subscribe ==>> ", data)
        this.tipDepartament = data
        this.dataSource = new MatTableDataSource(this.tipDepartament);
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  AddTipDepartament(){
    this.tipDepartamentService.addtipdepartament(this.newtipdepartament);
    this.tipDepartament = this.tipDepartamentService.getTipDepartament();
    this.dataSource = new MatTableDataSource(this.tipDepartament);
          this.dataSource.paginator = this.paginator;
          this.newtipdepartament = new TipDepartament();
  }

  valabilitatestatuschange(v){
    console.log("status v =>", v)
    this.newtipdepartament.arevalabilitate = v
  }

  startdateset(v) {
    let vd2 = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    let intermediar = this.datepipe.transform(new Date(vd2),"MM.dd.YYYY")
    this.start = new Date(intermediar)
  }
  enddateset(v) {
    let vd = v.substring(3,5)+"."+v.substring(0,2)+"."+v.substring(6,) + " 00:00:01"
    let intermediar = this.datepipe.transform(new Date(vd),"MM.dd.YYYY")
    this.end = new Date(intermediar)
    return vd
  }
  addnewtipdepartament(){
    console.log ("categoriiservicii component -> addnewcategorie() -> newcat inainte de orice  => ", this.newtipdepartament)
    if (this.newtipdepartament.arevalabilitate){ // check daca este bifata valabilitatea
      console.log ("categoriiservicii component -> addnewcategorie() -> are valabilitate TRUE  => ", this.newtipdepartament.arevalabilitate)
      this.intervalcreate = new ValabilitateCreate()
      this.intervalcreate.format_type = "MM.dd.YYYY"
      this.intervalcreate.start = this.start.toString()
      this.intervalcreate.end = this.end.toString()
      console.log("categoriiservicii component -> addnewcategorie() -> this.intervalcreate  => ",this.intervalcreate)
      console.log("categorii array 1 => -- !! -- ==> ",this.tipDepartamente)      //commented before
      this.intervalService.addIntervalToServer(this.intervalcreate)
        .subscribe((data)=>{
          if (data){
            console.log("data from add interval to server => ", data)
            this.newtipdepartament.valabilitateid = data
            console.log("newcat.valabilitateid from add interval to server from subscribe => ",this.newtipdepartament.valabilitateid)
            // console.log("type of newcat.valabilitateid from add interval to server from subscribe => ",typeof(this.newtipdepartament.valabilitateid))
            this.tipDepartamentService.addTipDepartamentCuValabilitate(this.newtipdepartament)
                .subscribe( data => {
                  console.log("data put id ==> ",data.id)
                  console.log("data keys ==> ",Object.keys(data))
                  console.log("data full object ==> ",data)
                  this.tipDepartamente.push(data)
                  console.log("categorii array => -- !! -- ==> ",this.tipDepartamente)      //commented before
                  this.refreshformafteradd()
                });
          }
        })
      console.log("categorii array 2 => -- !!!! -- ==> ",this.tipDepartamente)      //commented before
      console.log("newcat full object from add interval to server => ",this.newtipdepartament)
    }
    else{
      console.log ("categoriiservicii component -> addnewcategorie() -> are valabilitate FALSE  => ", this.newtipdepartament.arevalabilitate)
      console.log ("this.newtipdepartament full object daca nu are valabilitate  => ", this.newtipdepartament)
      this.tipDepartamentService.addTipDepartamentFaraValabilitate(this.newtipdepartament)
        .subscribe(data =>{
          if (data){
            console.log("data din addHeroFaraValabilitate ==>> ",data)
            this.tipDepartamente.push(data)
            this.refreshformafteradd()
          }
        })
    }
  }

  @ViewChild('startDate',{
    read:MatInput
  }) startDate:MatInput;
  @ViewChild('endDate',{
    read:MatInput
  }) endDate:MatInput;

  refreshformafteradd(){
    this.newtipdepartament = new TipDepartamentCreate();
    this.startDate.value='';
    this.endDate.value='';
    this.dataSource = new MatTableDataSource(this.tipDepartamente);
          this.dataSource.paginator = this.paginator;
          console.log("===>> REFRESH FORMAT <<===")
  }

}
