import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  OnDestroy,
  SimpleChanges
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { FieldConfig } from 'src/app/interfaces/field.interface';


@Component({
  exportAs: "dynamicForm",
  selector: "dynamic-form",
  template: `
  <br>
  <form class="dynamic-form" [formGroup]="form" (submit)="onSubmit($event)">
  <ng-container *ngFor="let field of fields;" dynamicField [field]="field" [group]="form">
  </ng-container>
  </form>
  `,
  styles: []
})
export class DynamicFormComponent implements OnInit {
  @Input() fields: FieldConfig[] = [];

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  get controls() { return this.fields.filter(({type}) => type !== 'button'); }
  get changes() { return this.form.valueChanges; }
  get valid() { return this.form.valid; }
  get value() { return this.form.value; }
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    console.log("dynamic form - on init // this= ", this)
    this.form = this.createControl();
  }


  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      this.submit.emit(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  createControl() {
    const group = this.fb.group({});
    console.log("from create control // this= ", this)
    console.log("from create control // this.fb= ", this.fb)
    this.fields.forEach(field => {
      console.log("dynamic-form // foreach field= ",field)
      if (field.type === "button") return;
      console.log("dynamic-form // foreach field.value= ",field.value)
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [] )
      );
      console.log("dynamic-form // foreach control= ",control)
      group.addControl(field.name, control);
      console.log("dynamic-form // foreach group= ",group)
    });
    return group;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
