import { Adresa, AdresaCreate } from '../models/pacientdetails.model';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of  } from 'rxjs';
import {catchError} from 'rxjs/operators/catchError';
import { AdresaCreateInterface, AdresaInterface, SemnaturaRLCreateInterface } from '../interfaces/pacient.interface';
import { serverurl } from './server.service';
const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
    providedIn: 'root'
})

export class SemnaturiTerapeutiFilesService{
  // private server_url="http://optimabk.ztools.ro/api/v1/adresa/"
  private server_url=serverurl+"/api/v1/semnaturiterapeutfiles/"

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
      private http: HttpClient
  ){}

  addSemnaturaTerapeutFilesToServer( semnatura_noua: SemnaturaRLCreateInterface ){
    return (this.http.post<any>(this.server_url,semnatura_noua, this.httpOptions))
  }

  getSemnaturaTerapeutFilesfromServerByFileID( idFisier:string){
    return (this.http.get<any>(this.server_url+"byfileid/"+idFisier))
  }


}

