import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { DatePersonaleCreateInterface } from '../interfaces/pacient.interface';
import { DatePersonaleCreate } from '../models/pacientdetails.model';
import { TimePickerComponent, ChangeEventArgs } from '@syncfusion/ej2-angular-calendars';
import { ChangeEventArgs as checkboxChange } from '@syncfusion/ej2-angular-buttons';
@Component({
  selector: 'app-form-date-personale',
  templateUrl: './form-date-personale.component.html',
  styleUrls: ['./form-date-personale.component.css']
})
export class FormDatePersonaleComponent implements OnInit {
  @Input() flag_date_personale_create: boolean = false;
  @Input() date_personale : DatePersonaleCreateInterface = new DatePersonaleCreate()
  @Input() date_personale_id : number

  @Output() date_personale_final = new EventEmitter<DatePersonaleCreate>();
  @Output() flag_modificat = new EventEmitter<boolean>(false);

  reactForm: FormGroup;

  public isRender: boolean = true;
  public flag_are_CI: boolean = false;
  public flag_date_personale_salvate: boolean = true
  // public flag_date_personale_modificate: boolean = false

  constructor() {
    // this.flag_modificat.emit(false)
    this.reactForm = new FormGroup({
      'nume': new FormControl('', [FormValidators.required]),
      'prenume': new FormControl('', [FormValidators.required]),
      'sex': new FormControl('', [FormValidators.required]),
      'data_nasterii': new FormControl('', [FormValidators.date]),
      'cnp': new FormControl('',[FormValidators.number]),
      'locul_nasterii': new FormControl(''),
      'serie_carte_identitate': new FormControl(''),
      'numar_carte_identitate': new FormControl(''),
    });
    this.reactForm.disable()
  }

  ngOnInit(): void {
    this.initFrom()
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.flag_date_personale_salvate){
      console.log("flag_date_personale_salvate !!! UPDATED ==>> ", this.flag_date_personale_salvate)

    }
    if(changes.flag_date_personale_create){
      console.log("flag_date_personale_create UPDATED ==>> ", this.flag_date_personale_create)
      if (this.flag_date_personale_create){
        this.flag_date_personale_salvate = false
      }
    }
    if(changes.date_personale.currentValue){
      console.log("date_personale UPDATED ==>> ", this.date_personale)
      this.initFormControlValues()
    }
    if(changes.date_personale_id.currentValue){
      console.log("date_personale_id UPDATED ==>> ", this.date_personale_id)
    }
  }
  editeazaDatePersonale(){
    this.flag_date_personale_salvate = false
    this.reactForm.enable()
  }
  initFormControlValues(){
    if (this.date_personale.nume){
      this.reactForm.patchValue({'nume': this.date_personale.nume})
    }
    if (this.date_personale.prenume){
      this.reactForm.patchValue({'prenume': this.date_personale.prenume})
    }
    if (this.date_personale.sex){
      this.reactForm.patchValue({'sex': this.date_personale.sex})
    }
    if (this.date_personale.data_nasterii){
      this.reactForm.patchValue({'data_nasterii': this.date_personale.data_nasterii})
    }
    if (this.date_personale.cnp){
      this.reactForm.patchValue({'cnp': this.date_personale.cnp})
    }
    if (this.date_personale.locul_nasterii){
      this.reactForm.patchValue({'locul_nasterii': this.date_personale.locul_nasterii})
    }
    if (this.date_personale.serie_carte_identitate){
      this.reactForm.patchValue({'serie_carte_identitate': this.date_personale.serie_carte_identitate})
    }
    if (this.date_personale.numar_carte_identitate){
      this.reactForm.patchValue({'numar_carte_identitate': this.date_personale.numar_carte_identitate})
    }
    if (this.date_personale.numar_carte_identitate == null && this.date_personale.serie_carte_identitate == null){
      this.flag_are_CI = false
    }
    else{
      this.flag_are_CI = true
    }
    console.log("STATUS CI ==>>> ", this.flag_are_CI)
  }
  get nume() { return this.reactForm.get('nume'); }
  get prenume() { return this.reactForm.get('prenume'); }
  get data_nasterii() { return this.reactForm.get('data_nasterii'); }
  get cnp() { return this.reactForm.get('cnp'); }
  get sex() { return this.reactForm.get('sex'); }
  get locul_nasterii() { return this.reactForm.get('locul_nasterii'); }
  get serie_carte_identitate() { return this.reactForm.get('serie_carte_identitate'); }
  get numar_carte_identitate() { return this.reactForm.get('numar_carte_identitate'); }

  public initFrom(){
      document
      .getElementById('formId')
      .addEventListener('submit', (e: Event) => {
        e.preventDefault();
        if (this.reactForm.valid) {
          // alert('Customer details added!');
          this.flag_date_personale_salvate = true
          this.reactForm.disable()
          this.flag_modificat.emit(true)
          this.date_personale_final.emit(this.date_personale)
          // this.reactForm.reset();
        } else {
          // validating whole form
          Object.keys(this.reactForm.controls).forEach((field) => {
            const control = this.reactForm.get(field);
            control.markAsTouched({ onlySelf: true });
          });
        }
      });
    this.isRender = false;
  }
  ngOnDestroy() {
    console.log('Form Date Personale :OnDestroy');
  }
  // Submit(){}
}
