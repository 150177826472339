import { Component, ElementRef, OnInit, ViewChild, ViewChildren, Inject, Renderer2, QueryList } from '@angular/core';
import { DepartamentService } from 'src/app/services/departament.service';
import { MedicprogramService } from 'src/app/services/medicprogram.service';
import { ServiciiService } from 'src/app/services/servicii.service';
import { SpecialitateMedicService } from 'src/app/services/servmedic.service';
import { specialitateMedici,Servicii,Departament, Medici, MediciCreate } from 'src/app/models/nomenclatoare.model';
import { MediciService } from 'src/app/services/medici.service';
import { MatTableDataSource } from '@angular/material/table';
import { ServiciiInterface, specialitateMediciInterface, IntervaleZilniceCreateInterface } from 'src/app/interfaces/nomenclatoare.interface';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MedicieditComponent } from '../mediciedit/mediciedit.component';

const waitUntil = async (untilTruthy: Function): Promise<boolean> => {
  while (!untilTruthy()) {
    await interval(25).pipe(take(1)).toPromise();
  }
  return Promise.resolve(true);
};

@Component({
  selector: 'app-medici',
  templateUrl: './medici.component.html',
  styleUrls: ['./medici.component.css']
})
export class MediciComponent implements OnInit {
  medici: Medici[];
  intervale_orare: IntervaleZilniceCreateInterface[] = []
  intervale_orare_todelete: IntervaleZilniceCreateInterface[] = []
  intervaleDataSource: any = new MatTableDataSource(this.intervale_orare)
  displayedColumnsIntervale: string[] = ["interval","edit","intervalselect"]
  departamente: Departament[];
  servicii: ServiciiInterface[];
  specialitateMedici: specialitateMediciInterface[];
  dataSource: any
  newMedic = new MediciCreate();
  interval_orar_start: Date
  interval_orar_end: Date
  displayedColumns: string[] = ['Nume', 'Prenume', 'Telefon', 'Email',"Servicii", "Edit"];
  displayedColumnsProgram: string[] = ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri','Edit'];
  programDataSource: any
  inaltime_tabel: number = 0
  inaltime_interval_orar_select:number = 0
  inaltime_card: number = 200
  numar_intervale_orare: number = this.intervale_orare.length
  interval_editat_index: number = -1
  max_width_intervale_card: number = 400
  min_width_intervale_card: number = 200
  inaltime_randuri_grid_program: number = 28
  row_span_grid_program: number = 1
  // flags
  flag_add_medic:boolean = false
  flag_add_program:boolean = false
  flag_fixed_program:boolean = false
  flag_add_intervale_orare:boolean = false
  flag_fixed_intervale_orare:boolean = false
  flag_form_errors:boolean = false
  flag_show_all_medici:boolean = true
  flag_interval_vizibil:boolean = false
  flag_separator_vizibil:boolean = false
  flag_set_width_tabel_intervale:boolean = false
  flag_editeaza_interval_orar: boolean = false
  flag_separatoare_time_picker_intervale: boolean = false
  flag_interval_din_program_defined: boolean = false
  flag_program_pe_zile_afisat: boolean = false
  flag_intervale_time_picker: boolean = false
  // Errors
  numeError : boolean = false
  prenumeError : boolean = false
  telefonError : boolean = false
  emailError : boolean = false
  sexError : boolean = false
  greutateError : boolean = false
  serviciiError : boolean = false

  variabilatest : any

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('intervalorastart') intervalorastart:ElementRef
  @ViewChild('adaugaintervalbutton') adaugaintervalbutton:ElementRef
  @ViewChild('separatortimepicker') separatortimepicker:ElementRef
  @ViewChild('tabelintervale') tabelintervale:ElementRef
  @ViewChild('listaadaugaintervale') listaadaugaintervale:ElementRef
  @ViewChild('programpezile') programpezile:ElementRef
  // @ViewChild('intervaldinprogram') intervaldinprogram:ElementRef
  // @ViewChild('zidinprogram') zidinprogram:ElementRef

  @ViewChildren('zidinprogram') ziledinprogram: QueryList<ElementRef>;
  @ViewChildren('separatortimepickerintervale') separatortimepickerintervale: QueryList<ElementRef>;
  @ViewChildren('intervaldinprogram') intervaldinprogram: QueryList<ElementRef>;
  // async setVarX(){
  //   console.log(" LOG DIN SET VAR X !!!! ")
  //   await waitUntil(() => !!this.intervalorastart); // wait until foo element is
  //   // on screen before doing loadValues
  //   this.testLoad(); //-> but actually this function should be called after Div in *ngIf="varX"
  // }

  ngAfterViewChecked() {

    // if (this.ziledinprogram != undefined ){
    //   console.log (" zi din program ==>> ", this.ziledinprogram)
    //   console.log (" zi din program 0 ==>> ", this.ziledinprogram["_results"][0])
    //   console.log (" zi din program 0 [ _element ] ==>> ", this.ziledinprogram["_results"][0]["_element"])
    //   console.log (" zi din program 0 [ _element ] children [ 0 ] -->> nativeElement !!!! <<>> ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement)
    //   console.log (" zi din program 0 [ _element ] children [ 0 ] ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement.children[0].nativeElement)
    //   console.log (" zi din program 0 [ _element ] children [ 0 ] || offsetHeight ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement.children[0].clientHeight)
    //   console.log (" zi din program 0 [ _element ] children [ 0 ] || style ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement.children[0].style)
    //   console.log (" zi din program 0 [ _element ] children [ 0 ] || nativeElement -->> style -->> position ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement.children[0].style.position)
    //   this.ziledinprogram["_results"][0]["_element"].nativeElement.children[0].style.position = "relative"
    //   console.log (" zi din program 0 [ _element ] children [ 0 ] || nativeElement -->> style -->> position 223344 ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement.children[0].style.position)
    //   console.log (" zi din program 0 [ _element ] style ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement.style)
    //   console.log (" zi din program 0 [ _element ] style  \\ paddingTop ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement.style.paddingTop)
    //   this.ziledinprogram["_results"][0]["_element"].nativeElement.style.paddingTop = ""
    //   console.log (" zi din program 0 [ _element ] style  \\ paddingTop 22 ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement.style.paddingTop)
    //   console.log (" zi din program 0 [ _element ] style  \\ position ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement.style.position)
    //   this.ziledinprogram["_results"][0]["_element"].nativeElement.style.position = "relative"
    //   console.log (" zi din program 0 [ _element ] style  \\ position ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement.style.position)
    //   console.log (" zi din program 0 [ _element ] children [ 0 ] || offsetHeight 222 ==>> ", this.ziledinprogram["_results"][0]["_element"].nativeElement.children[0].offsetHeight)
    // }

    if(this.programpezile === undefined && this.flag_program_pe_zile_afisat){
      this.flag_program_pe_zile_afisat = false
    }
    if (this.intervaldinprogram === undefined && this.flag_interval_din_program_defined ){
      this.flag_interval_din_program_defined = false
      this.flag_intervale_time_picker = false
      this.flag_separatoare_time_picker_intervale = false
    }

    if(this.programpezile != undefined && !this.flag_program_pe_zile_afisat){
      // console.log (" Log din program Pe Zile ==>> ", this.programpezile)

      if (this.intervaldinprogram != undefined && !this.flag_interval_din_program_defined ){
        // console.log (" Log din interval din program pe zile ==>> ", this.intervaldinprogram)
        // console.log (" Log din interval din program pe zile results ==>> ", this.intervaldinprogram["_results"])
        // console.log (" Log din interval din program pe zile results length ==>> ", this.intervaldinprogram["_results"].length)

        if (this.intervaldinprogram["_results"].length > 0){
          // console.log (" Log din interval results MAI MARE DECAT 0 ==>> ", this.intervaldinprogram["_results"].length)
          var intermediar_z: number = 0
          for (var i in this.intervaldinprogram["_results"]){
            // console.log(" Log din I in interval din program results ==>> ", this.intervaldinprogram["_results"][i])
            // console.log(" Log din I in interval din program results offset height ==>> ", this.intervaldinprogram["_results"][i].nativeElement.offsetHeight)
            if (this.intervaldinprogram["_results"][i].nativeElement.offsetHeight > intermediar_z){
              intermediar_z = this.intervaldinprogram["_results"][i].nativeElement.offsetHeight
            }
            let elements = this.elem.nativeElement.querySelectorAll('.ngx-mat-timepicker form');
            // console.log(" Elements din bla bla ==>> ", elements)
            if ( elements.length > 0 && !this.flag_intervale_time_picker ){
              // console.log ("Elements Length MAI MARE DECAT 0 ==>> ", elements.length)
              for (var t = 0; t < elements.length; t = t + 1){
                // console.log ( "Elements [ ", t, " ] ==>> ", elements[t]["style"] )
                // elements[t]["style"].minWidth = "20px"
                // if ( Number(t) >= 0 && Number(t) <= 9){
                  if( elements[t] != undefined ){
                    elements[t].style.minWidth = "20px"
                    elements[t].style["width"] = "100%"
                  }
                // }
                // elements[t].style["width"] = elements[t].firstChild.clientWidth.toString()+"px"
                // console.log ( "Elements [ ", t, " ] La final  ==>> ", elements[t] )
              }
              this.flag_intervale_time_picker = true
            }
          }

          // console.log ("intemediar Z la final ==>> ", intermediar_z)
          intermediar_z = (intermediar_z + 10) / 28
          // console.log ("intemediar Z la final ==>> ", intermediar_z)
          if ( intermediar_z > Math.round(intermediar_z) ){
            intermediar_z = Math.round(intermediar_z) + 1
          }
          else{
            if ( intermediar_z < Math.round(intermediar_z)){
              intermediar_z = Math.round(intermediar_z)
            }
          }
          this.row_span_grid_program = intermediar_z

          if (this.separatortimepickerintervale != undefined && !this.flag_separatoare_time_picker_intervale){
            // console.log("Separatorul nu mai este undefined ==>> ", this.separatortimepickerintervale)
            if ( this.separatortimepickerintervale["_results"].length > 0 && !this.flag_separatoare_time_picker_intervale ){
              // console.log ("Separatorul values MAI MARE decat 0 ==>> ",  this.separatortimepickerintervale["_results"].length)
              for (var p in this.separatortimepickerintervale["_results"]){
                // console.log (" Separatorul de P ==>> ", p," << == separator == >> ", this.separatortimepickerintervale["_results"][p])
                // console.log( "same as above + native element ==>> ", this.separatortimepickerintervale["_results"][p].nativeElement )
                this.separatortimepickerintervale["_results"][p].nativeElement.style["font-size"] = "35px"
                this.separatortimepickerintervale["_results"][p].nativeElement.style["padding-left"] = "10px"
                this.separatortimepickerintervale["_results"][p].nativeElement.style["padding-right"] = "10px"
              }
              this.flag_separatoare_time_picker_intervale = true
            }
          }
          this.flag_interval_din_program_defined = true
        }
      }
      this.flag_program_pe_zile_afisat = true
    }



    if (this.intervalorastart != undefined && !this.flag_interval_vizibil){
      this.flag_interval_vizibil = true
      // console.log(" View checked si interval orar ar fi ok ==>> ", this.intervalorastart.nativeElement.offsetHeight)
      this.inaltime_interval_orar_select = this.intervalorastart.nativeElement.offsetHeight
      let elements = this.elem.nativeElement.querySelectorAll('.ngx-mat-timepicker form');
      // console.log(" Elements ==>> ", elements)
      for (var i in elements){
        // console.log(" Elements [ ",i,"] ==>> ",elements[i])
        // console.log(" i DIN elements ==>> ", i["offsetWidth"])
        // console.log(" i DIN elements 33 min width ==>> ", i.style["min-width"])
        // console.log(" i DIN elements  ==>> ", i)
        // console.log(" i DIN elements  Style==>> ", i.style)
        // console.log(" i DIN elements  Style.minWidth==>> ", i.style.minWidth)
        elements[i].style.minWidth = "20px"
        // console.log(" i DIN elements  Style.minWidth 222 ==>> ", elements[i].style.minWidth)
        // minWidth: "20px"

        // console.log(" i DIN elements 333 min width ==>> ", elements[i].clientWidth)
        // console.log(" i DIN elements 444 style ==>> ", elements[i].style)
        // console.log(" i DIN elements 999 style min-width==>> ", elements[i].style["min-width"])
        // console.log(" i DIN elements 555 child ==>> ", elements[i].firstChild)
        // console.log(" i DIN elements 555 child clientWidth ==>> ", elements[i].firstChild.clientWidth)
        elements[i].style["width"] = elements[i].firstChild.clientWidth.toString()+"px"
        // console.log(" i DIN elements 333 min width dupa modificare ==>> ", elements[i].clientWidth)

        // console.log(" i DIN elements 33 min width ==>> ", i["min-width"])
        // console.log(" i DIN elements 44 min width ==>> ", elements[i].nativeElement)
        // console.log(" i DIN elements 22 ==>> ", elements[i].style["width"])
        // elements[i].offsetWidth = "100%"
        elements[i].style["width"] = "100%"

      }

    }
    // if (this.adaugaintervalbutton != undefined && !this.adaugaintervalbutton["_elementRef"].nativeElement.style["margin-top"]){
      if (this.adaugaintervalbutton != undefined ){
      // console.log(" LOG Din Afauga Interval Buton View Child == >> ", this.adaugaintervalbutton)
      // console.log(" LOG Din al doilea view child ==>> ", this.adaugaintervalbutton)
      // console.log(" LOG Din al doilea view child 22 ==>> ", this.adaugaintervalbutton["_elementRef"])
      // console.log(" LOG Din al doilea view child 33 ==>> ", this.adaugaintervalbutton["_elementRef"].nativeElement.offsetHeight)
      // console.log(" LOG Din al doilea view child 44 ==>> ", this.adaugaintervalbutton["_elementRef"].nativeElement.style)
      // console.log(" LOG Din al doilea view child 55 ==>> ", this.adaugaintervalbutton["_elementRef"].nativeElement.style["margin-top"])
      // this.adaugaintervalbutton["_elementRef"].nativeElement.style.margin_top = (this.intervalorastart.nativeElement.offsetHeight - this.adaugaintervalbutton["_elementRef"].nativeElement.offsetHeight) / 2
      this.adaugaintervalbutton["_elementRef"].nativeElement.style["margin-top"] = ((this.intervalorastart.nativeElement.offsetHeight - this.adaugaintervalbutton["_elementRef"].nativeElement.offsetHeight) / 2).toString()+"px"
      this.adaugaintervalbutton["_elementRef"].nativeElement.style["margin-left"] = "15px"
      // console.log(" LOG Din al doilea view child 66 ==>> ", this.adaugaintervalbutton["_elementRef"].nativeElement.style["margin-top"])
    }
    if(this.separatortimepicker != undefined && !this.flag_separator_vizibil){
      // console.log(" separatorul inaltimea fara native element ==>> ", this.separatortimepicker)
      // console.log(" separatorul inaltimea native element ==>> ", this.separatortimepicker.nativeElement)
      // console.log(" separatorul inaltimea ==>> ", this.separatortimepicker.nativeElement.offsetHeight)
      var intermdiar_height = this.separatortimepicker.nativeElement.offsetHeight
      // console.log(" separatorul inaltimea margin top ==>> ", this.separatortimepicker.nativeElement)
      // console.log(" separatorul inaltimea margin top 22 ==>> ", this.separatortimepicker.nativeElement.style)
      // console.log(" separatorul inaltimea margin top 33 interval start off height ==>> ", this.intervalorastart.nativeElement.offsetHeight)
      // console.log(" separatorul inaltimea margin top 44 separatortimepicker nativeElement offsetHeight ==>> ",  this.separatortimepicker.nativeElement.offsetHeight )
      // console.log(" separatorul inaltimea margin top 55 ==>> ", (this.intervalorastart.nativeElement.offsetHeight - this.separatortimepicker.nativeElement.offsetHeight) )

      // this.separatortimepicker.nativeElement.margin_top = ((this.intervalorastart.nativeElement.offsetHeight - this.separatortimepicker.nativeElement.offsetHeight) / 2).toString()+"px"
      this.separatortimepicker.nativeElement.style["padding-top"] = ((this.intervalorastart.nativeElement.offsetHeight - intermdiar_height) / 2).toString()+"px"
      this.separatortimepicker.nativeElement.style["font-size"] = "35px"
      this.separatortimepicker.nativeElement.style["padding-left"] = "10px"
      this.separatortimepicker.nativeElement.style["padding-right"] = "10px"
      this.flag_separator_vizibil = true
    }
    if (this.tabelintervale != undefined && !this.flag_set_width_tabel_intervale ){
      // console.log(" tabelintervale ==>> ",this.tabelintervale)
      // console.log(" tabelintervale element ref -+- native element ==>> ",this.tabelintervale["_elementRef"].nativeElement)
      // console.log(" tabelintervale element ref -+- native element ==>> ",this.tabelintervale["_elementRef"].nativeElement.style)
      // console.log(" tabelintervale element ref -+- native element // display ==>> ",this.tabelintervale["_elementRef"].nativeElement.style["display"])
      // this.tabelintervale["_elementRef"].nativeElement.style["display"] = "flex"
      // console.log(" tabelintervale element ref -+- native element // display  222 !!! ==>> ",this.tabelintervale["_elementRef"].nativeElement.style["display"])
      // console.log(" tabelintervale element ref -+- native element // align-self  ==>> ",this.tabelintervale["_elementRef"].nativeElement.style["align-self"])
      this.tabelintervale["_elementRef"].nativeElement.style["align-self"] = "flex-start"
      this.flag_set_width_tabel_intervale = true
      // console.log("inaltime tabel after view init ==>> ", this.tabelintervale["_elementRef"].nativeElement.offsetHeight)
      this.inaltime_tabel = this.tabelintervale["_elementRef"].nativeElement.offsetHeight
      // console.log("inaltime_tabel after view init ==>> ", this.inaltime_tabel)

      // console.log(" tabelintervale element ref -+- native element // align-self 22222 !!!! ==>> ",this.tabelintervale["_elementRef"].nativeElement.style["align-self"])
    }
    if (this.tabelintervale != undefined && this.intervale_orare.length ){
        // console.log("this.intervale_orare.length ==>> ", this.intervale_orare.length, " << == this.numar_intervale_orare == >> ", this.numar_intervale_orare)
        this.actualizeazaIntervaleDataSource()
        this.numar_intervale_orare = this.intervale_orare.length
    }
    if (this.listaadaugaintervale != undefined ){
      // console.log("Lista adauga intervale ==>> ", this.listaadaugaintervale)
      // console.log("Lista adauga intervale rowHeight ==>> ", this.listaadaugaintervale["height"])
      // console.log("inaltimea inaltime_interval_orar_select ==>> ", this.inaltime_interval_orar_select)
      // console.log("this.inaltime_tabel ==>> ", this.inaltime_tabel)
      // console.log("inaltimea maxima dintre cele doua ==>> ", Math.max(this.inaltime_interval_orar_select,this.inaltime_tabel))
      var intermediar_calcul_inaltime = Math.max(this.inaltime_interval_orar_select,this.inaltime_tabel)+30
      // console.log(" this.inaltime_interval_orar_select ==>> ", this.inaltime_interval_orar_select)
      // console.log(" this.inaltime_tabel  ==>> ", this.inaltime_tabel)
      // console.log(" intermediar calcul inaltime ==>> ", intermediar_calcul_inaltime)
      if (intermediar_calcul_inaltime != this.inaltime_card){
        if ( intermediar_calcul_inaltime >= this.min_width_intervale_card){
          if ( intermediar_calcul_inaltime <= this.max_width_intervale_card) {
            this.inaltime_card = intermediar_calcul_inaltime
            this.listaadaugaintervale["height"] = this.inaltime_card
          }
          else{
            this.inaltime_card = this.max_width_intervale_card
            this.listaadaugaintervale["height"] = this.inaltime_card
          }
        }
        else{
          this.inaltime_card = this.min_width_intervale_card
          this.listaadaugaintervale["height"] = this.inaltime_card
        }
      }

      // if (intermediar_calcul_inaltime != this.inaltime_card){
      //   if(intermediar_calcul_inaltime > this.inaltime_card){
      //     console.log("Calcul inaltime > inaltime card ==>> ", intermediar_calcul_inaltime, " << == inaltime card == >> ", this.inaltime_card)
      //     if ( intermediar_calcul_inaltime >= this.min_width_intervale_card){
      //       if ( intermediar_calcul_inaltime <= this.max_width_intervale_card) {
      //         this.inaltime_card = intermediar_calcul_inaltime
      //         this.listaadaugaintervale["height"] = this.inaltime_card
      //       }
      //       else{
      //         this.inaltime_card = 500
      //         this.listaadaugaintervale["height"] = this.inaltime_card
      //       }
      //     }
      //     else{
      //       this.inaltime_card = 200
      //       this.listaadaugaintervale["height"] = this.inaltime_card
      //     }
      //   }
      //   else{
      //     if (intermediar_calcul_inaltime < this.inaltime_card){
      //       console.log("Calcul inaltime < inaltime card ==>> ", intermediar_calcul_inaltime, " << == inaltime card == >> ", this.inaltime_card)
      //       if ( intermediar_calcul_inaltime >= this.min_width_intervale_card){
      //         if ( intermediar_calcul_inaltime <= this.max_width_intervale_card) {
      //           this.inaltime_card = intermediar_calcul_inaltime
      //           this.listaadaugaintervale["height"] = this.inaltime_card
      //         }
      //         else{
      //           this.inaltime_card = 500
      //           this.listaadaugaintervale["height"] = this.inaltime_card
      //         }
      //       }
      //       else{
      //         this.inaltime_card = 200
      //         this.listaadaugaintervale["height"] = this.inaltime_card
      //       }
      //     }
      //   }
      //   // if (intermediar_calcul_inaltime <= this.max_width_intervale_card){
      //   //   this.inaltime_card = intermediar_calcul_inaltime
      //   //   this.listaadaugaintervale["height"] = this.inaltime_card
      //   // }
      //   // console.log("Lista adauga intervale rowHeight 2222 ==>> ", this.listaadaugaintervale["height"])
      // }
    }

  }
  constructor(
    @Inject(DOCUMENT) document,
    public renderer: Renderer2,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public elem: ElementRef,
    public elem_two: ElementRef,
    public datePipe: DatePipe,
    public serviciiService: ServiciiService,
    public departamentService: DepartamentService,
    public specialitateMedicService: SpecialitateMedicService,
    public programMedicService: MedicprogramService,
    public mediciService: MediciService
    ){
    }

  ngOnInit(): void {
    // this.servicii= this.serviciiService.getServicii();
    this.serviciiService.getServiciiFromServer()
    .subscribe( (data) =>{
      if (data){
        this.servicii = data
      }
    })
    // this.departamente = this.departamentService.getDepartament();
    this.departamentService.getDepartamentFromServer()
    .subscribe( (data) => {
      if (data){
        this.departamente = data
      }
    })

    this.specialitateMedicService.getfromserver()
    .subscribe( (data) => {
      if (data){
        this.specialitateMedici = data
      }
    })
    // this.specialitateMedici = this.specialitateMedicService.getSpecialitatiMedic();
    // this.medici = this.mediciService.getMedici();
    this.mediciService.getMediciFromServer()
    .subscribe( (data) => {
      if (data){
        console.log("DATA FROM MEDICI FROM SERVER ==>> ", data)
        this.medici = data
        this.dataSource = new MatTableDataSource(this.medici)
        this.dataSource.paginator = this.paginator;
      }
    })
    // this.dataSource = new MatTableDataSource(this.medici)
  }

  AddMedic(){
    this.mediciService.getMediciFromServer()
    .subscribe( (data) => {
      if (data){
        this.medici = data
        this.dataSource = new MatTableDataSource(this.medici)
        this.dataSource.paginator = this.paginator;
        this.newMedic = new Medici();
      }
    })
    // this.dataSource = new MatTableDataSource(this.medici)
  }

  addNewMedic(){
    this.flag_add_medic = true
    if (this.flag_show_all_medici){
      this.flag_show_all_medici = false
    }
  }

  checkRequiredMedic(){
    if (this.newMedic.nume === undefined ){
      // console.log("Nume este gol !!! ")
      this.numeError = true
    }
    else {
      if (this.numeError){
        this.numeError = false
      }
      // console.log("Nume nu este gol ==>> ", this.newMedic.nume)
    }
    if (this.newMedic.prenume === undefined){
      this.prenumeError = true
    }
    else{
      if (this.prenumeError){
        this.prenumeError = false
      }
    }
    if(this.newMedic.telefon === undefined){
      this.telefonError = true
    }
    else{
      if(this.telefonError){
        this.telefonError = false
      }
    }
    if(this.newMedic.mail === undefined){
      this.emailError = true
    }
    else{
      if(this.emailError){
        this.emailError = false
      }
    }
    if(this.newMedic.sex === undefined){
      this.sexError = true
    }
    else{
      if (this.sexError){
        this.sexError = false
      }
    }
    if(this.newMedic.greutate === undefined){
      this.greutateError = true
    }
    else{
      if(this.greutateError){
        this.greutateError = false
      }
    }
    if(this.newMedic.servicii_id === undefined){
      this.serviciiError = true
    }
    else{
      if(this.serviciiError){
        this.serviciiError = false
      }
    }
    this.flag_form_errors = this.numeError || this.prenumeError || this.telefonError || this.emailError || this.sexError || this.greutateError || this.serviciiError
    return this.flag_form_errors
  }

  showNewProgram(){
    if(!this.checkRequiredMedic()){
      this.flag_add_program = !this.flag_add_program
    }
    else{
            // De comentat - verifica required

      // this.flag_add_program = !this.flag_add_program
      console.log("Sunt erori")
    }
  }

  showAllMedici(){
    this.flag_show_all_medici = true
  }

  addProgramToMedic(){
    // console.log ("checkRequiredMedic() ==>> ", this.checkRequiredMedic())
    var intermediar  = []
    // for (var i in this.newMedic.program){
    //   console.log(" i in for bla bla bla ==>> ", this.newMedic.program[i])
    //   intermediar.push(this.newMedic.program[i])
    //   console.log(" intermediar in for bla bla bla ==>> ", intermediar)

    // }
    intermediar.push(this.newMedic)
    var min_ora = this.sorteazaCeaMaiDevremeOraStart(this.newMedic.program).min_ora
    var min_minute = this.sorteazaCeaMaiDevremeOraStart(this.newMedic.program).min_minute
    var max_ora = this.sorteazaCeaMaiDevremeOraStart(this.newMedic.program).max_ora
    var max_minute = this.sorteazaCeaMaiDevremeOraStart(this.newMedic.program).max_minute
    // console.log (" min_ora ==>> ", min_ora, " min_minute ==>> ", min_minute, " max_ora ==>> ", max_ora, " max_minute ==>> ", max_minute)
    this.setIntervalStartEnd(new Date(new Date().setHours(min_ora,min_minute,0)), new Date(new Date().setHours(min_ora,min_minute,0) + (this.afla_durata_serviciului(this.newMedic.servicii_id) * 60 * 1000)) )
    // this.interval_orar_start = new Date(new Date().setHours(min_ora,min_minute,0))
    // console.log("Afla durata serviciului ==>> ", this.afla_durata_serviciului(this.newMedic.servicii_id) )
    // this.interval_orar_end = new Date(this.interval_orar_start.getTime() + (this.afla_durata_serviciului(this.newMedic.servicii_id) * 60 * 1000))
    this.programDataSource = new MatTableDataSource(intermediar)
    this.flag_fixed_program = true

  }

  openProgramEdit(test: any){
    // console.log("test ==>> ", test)
    this.flag_add_program = true
    this.flag_fixed_program = false
  }

  openMedicEdit(medic: Medici){
    console.log("Medic din open Medic Edit ==>> ", medic)

    const dialogRef = this.dialog.open(MedicieditComponent, {
      width: '95%',
      height: '95%',
      data: {
        'medic': medic,
        'specialitateMedici': this.specialitateMedici,
        'servicii': this.servicii
      }
    });
    dialogRef.afterClosed().subscribe( result =>{
      if (result){
        console.log("Rezultatul dupa Save Edited Medic ==>> ", result)
        console.log("Medic din open Medic Edit ORIGINAL ==>> ", medic)
      }
      this.updatemedici()
    })
  }
  updatemedici(){
    this.mediciService.getMediciFromServer()
    .subscribe( (data) => {
      if (data){
        this.medici = data
        this.dataSource = new MatTableDataSource(this.medici)
        this.dataSource.paginator = this.paginator;
      }
    })
  }
  marktodelete( interval_orar ){
    console.log("mark to delete ==>> ", interval_orar)
    this.intervale_orare_todelete.push(interval_orar)
  }
  deleteIntervaleOrare(){
    var intermediar: number[] = []
    // console.log(" this.intervale_orare initial ==>> ", this.intervale_orare)
    for ( var i in this.intervale_orare){
      for ( var y in this.intervale_orare_todelete ){
      // console.log(" Y in intervale_orare_todelete ==>> ", this.intervale_orare_todelete[y])
        if (this.intervale_orare[i] === this.intervale_orare_todelete[y]){
          // console.log("Intervalele sunt la fel I ==>> ", i)
          // console.log("Intervalele sunt la fel I == NUMBER ==>> ", Number(i))
          intermediar.push( Number(i))
          // console.log(" Intermediar dupa push ==>> ", intermediar)
        }
      }
    }
    // console.log(" this.intervale_orare inainte de stergere ==>> ", this.intervale_orare)
    if (intermediar.length > 0){
      var intermediar_two : IntervaleZilniceCreateInterface[] = []
      // console.log(" Intermediar mai mare ca 0 ==>> ", intermediar)
      for (var x in this.intervale_orare){
        var contor:boolean = false
        // console.log(" x IN INTERMEDIAR ==>> ", x)
        // console.log(" this.intervale_orare de x ==>> ", this.intervale_orare[x])

          for ( var j in intermediar){
          // console.log(" J IN INTERMEDIAR ==>> ", j)
          // console.log(" INTERMEDIAR [j] ==>> ", intermediar[j])
          // console.log("this.intervale_orare[itermediar[j]] ==>> ", this.intervale_orare[intermediar[j]])
          if ( Number(x) == intermediar[j]){
            // console.log("Valorile sunt egale x == intermediar[j]")
            contor = true
          }
          // this.intervale_orare.splice(intermediar[j],1)
        }
        if (!contor){
          // console.log("Nu este marcat pt sergere ==>> ", this.intervale_orare[x])
          intermediar_two.push(this.intervale_orare[x])
        }
      }
      this.intervale_orare = intermediar_two
      console.log(" intervale_orare dupa stergere ==>> ", this.intervale_orare)
      this.actualizeazaIntervaleDataSource()
      var intermediar_calcul_inaltime_two = Math.max(this.inaltime_interval_orar_select,this.inaltime_tabel)+30
      console.log(" intermediar_calcul_inaltime_two delete elements ==>> ", intermediar_calcul_inaltime_two)
      console.log(" this.inaltime_tabel delete elements ==>> ", this.inaltime_tabel)
      console.log(" this.listaadaugaintervale[height] delete elements) ==>> ", this.listaadaugaintervale["height"])
      if (intermediar_calcul_inaltime_two < this.listaadaugaintervale["height"]){
        console.log(" intermediar_calcul_inaltime_two < this.listaadaugaintervale[height] ==>> ", intermediar_calcul_inaltime_two, "<< == lista adauga intervale [height] == >> ", this.listaadaugaintervale["height"])
        if (intermediar_calcul_inaltime_two <= this.max_width_intervale_card){
          this.inaltime_card = intermediar_calcul_inaltime_two
          console.log("inaltime tabel dupa daos interva ==>> ", this.inaltime_tabel)
        }
      }
      // console.log(" this.intervale_orare dupa stergere ==>> ", this.intervale_orare)
    }
  }

  actualizeazaIntervaleDataSource(){
    this.intervaleDataSource = new MatTableDataSource(this.intervale_orare)
    this.actualizeazaInaltimeaTabelului()
  }

  setIntervalStartEnd(start: Date,end: Date){
    // console.log("Intru in set interval START ==>> ", start, " <<== END ==>> ", end)
    this.interval_orar_start = start
    this.interval_orar_end = end
  }

  sorteazaCeaMaiDevremeOraStart(
                              program: {
                                luni?:{
                                    OraStart?: Date,
                                    OraEnd?: Date
                                },
                                marti?:{
                                    OraStart?: Date,
                                    OraEnd?: Date
                                },
                                miercuri?:{
                                    OraStart?: Date,
                                    OraEnd?: Date
                                },
                                joi?:{
                                    OraStart?: Date,
                                    OraEnd?: Date
                                },
                                vineri?:{
                                    OraStart?: Date,
                                    OraEnd?: Date
                                }
                              }
                            ){
    var min_ora: number = 23
    var min_minute: number = 59
    var max_ora: number = 0
    var max_minute: number = 0
    // console.log(" MINIM ==>> ", minim)
    for ( var i in program ){
      // console.log(" I start ==>> ", this.datePipe.transform(program[i].OraStart,'HH:mm') )
      // console.log(" I start 2 ==>> ", program[i].OraStart.getHours() )
      // console.log(" I start minutes 2 ==>> ", program[i].OraStart.getMinutes() )
      // console.log(" I end ==>> ", this.datePipe.transform(program[i].OraEnd,'HH:mm') )
      // console.log(" I end 2 ==>> ", program[i].OraEnd.getHours() )
      // console.log(" I end minutes 2 ==>> ", program[i].OraEnd.getMinutes() )
      if (program[i].OraStart.getHours() < min_ora){
        min_ora = program[i].OraStart.getHours()
        // console.log ("New min_ora ==>> ", min_ora)
        min_minute = program[i].OraStart.getMinutes()
        // console.log ("New min_minute ==>> ", min_minute)
      }
      else{
        if (program[i].OraStart.getHours() == min_ora){
          // console.log("min_ora egal cu OraStart == Check minutes ")
          if( program[i].OraStart.getMinutes() < min_minute ){
            min_minute = program[i].OraStart.getMinutes()
            // console.log("min_minute nou ==>> ",min_minute)
          }
        }
      }
      if (program[i].OraEnd.getHours() > max_ora){
        max_ora = program[i].OraEnd.getHours()
        max_minute = program[i].OraEnd.getMinutes()
      }
      else{
        if (program[i].OraEnd.getHours() == max_ora ){
          // console.log("max_ora egal cu OraEnd == Check minutes ")
          if ( program[i].OraEnd.getMinutes() > max_minute ){
            max_minute = program[i].OraEnd.getMinutes()
          }
        }
      }
      // console.log (" min_ora si min_minute finale ==>> ", min_ora," minute ==>> ", min_minute)
      // console.log (" max_ora si max_minute finale ==>> ", max_ora," minute ==>> ", max_minute)
    }
    return (
        // {
        //   "min_ora" : min_ora,
        //   "min_minute" : min_minute,
        //   "max_ora" : max_ora,
        //   "max_minute" : max_minute
        // }
        {
           min_ora,
           min_minute,
           max_ora,
           max_minute
        }
      )
  }

  afla_durata_serviciului(id:number){
    for ( var i in this.servicii){
      if (this.servicii[i].id == id){
        return this.servicii[i].timp
      }
    }
    return 0
  }

  afla_numele_serviciului(id:number){
    for ( var i in this.servicii){
      if (this.servicii[i].id == id){
        return this.servicii[i].denumire
      }
    }
    return null
  }

  checkIntervaleOrare(start : Date ,end : Date ){
    if (start.getTime() > end.getTime())
    {
      return 0
    }
    return 1
  }

  testLoad(){
    console.log("Inner HTML DIN interval ora start 33 ==>> ",document.getElementById('intervalorastart'))
  }

  actualizeazaInaltimeaTabelului(){
    console.log("Inaltimea la inceputul functiei ==>> ", this.inaltime_tabel)
    console.log("Inaltimea tabel intervale offsetheight la inceputul functiei ==>> ", this.tabelintervale["_elementRef"].nativeElement.offsetHeight)
    this.inaltime_tabel = this.tabelintervale["_elementRef"].nativeElement.offsetHeight
  }

  addNewInterval(){
    // console.log(" interval orar start evaluare ==>> ",this.checkIntervaleOrare(this.interval_orar_start, this.interval_orar_end))
    // console.log("Inner HTML DIN interval ora start 22 ==>> ",document.getElementById('intervalorastart'))

    if (this.checkIntervaleOrare(this.interval_orar_start, this.interval_orar_end)){
      var intermediar : IntervaleZilniceCreateInterface ={
        start: this.datePipe.transform(this.interval_orar_start,'HH:mm'),
        end: this.datePipe.transform(this.interval_orar_end,'HH:mm')
      }

      this.setIntervalStartEnd(this.interval_orar_end, new Date(this.interval_orar_end.getTime() + (this.afla_durata_serviciului(this.newMedic.servicii_id) * 60 * 1000)))

      this.intervale_orare.push(intermediar)

      // this.interval_orar_start = new Date()
      // this.interval_orar_end = new Date()
      console.log("Lista cu intervalele Orare ==>> ", this.intervale_orare)
      // this.interval_orar_end = new Date()
      // this.interval_orar_start = new Date()
      this.actualizeazaIntervaleDataSource()
      // this.inaltime_tabel = this.tabelintervale["_elementRef"].nativeElement.offsetHeight
      var intermediar_calcul_inaltime_two = Math.max(this.inaltime_interval_orar_select,this.inaltime_tabel)+30
      if (intermediar_calcul_inaltime_two > this.listaadaugaintervale["height"]){
        if (intermediar_calcul_inaltime_two <= this.max_width_intervale_card){
          this.inaltime_card = intermediar_calcul_inaltime_two
          console.log("inaltime tabel dupa daos interva ==>> ", this.inaltime_tabel)
        }
      }
      if(!this.flag_fixed_intervale_orare){
        this.flag_fixed_intervale_orare = true
        // this.actualizeazaInaltimeaTabelului()
      }
    }
    else{
      console.log("Intervalul nu este valid. Verifica ca ora la care se incheie intervalul sa nu fie inaintea orei de start")
    }
  }

  obtineOraSiMinuteleSeparate(interval_orar: IntervaleZilniceCreateInterface){
    var start_ora: number
    var start_minute: number
    var end_ora: number
    var end_minute: number
    console.log("obtine ora si minute ==>> ", interval_orar)
    console.log("obtine ora si minute index-ul lui : ==>> ", interval_orar.start.indexOf(":"))
    start_ora = Number(interval_orar.start.substring(0,interval_orar.start.indexOf(":")))
    console.log("obtine ora start  ==>> ", start_ora )
    start_minute = Number(interval_orar.start.substring(interval_orar.start.indexOf(":")+1, interval_orar.start.length))
    console.log("obtine minute start  ==>> ", start_minute )
    end_ora = Number(interval_orar.end.substring(0,interval_orar.end.indexOf(":")))
    console.log("obtine ora end  ==>> ", end_ora )
    end_minute = Number(interval_orar.end.substring(interval_orar.end.indexOf(":")+1, interval_orar.end.length))
    console.log("obtine minute end  ==>> ", end_minute )
    return {start_ora,start_minute,end_ora,end_minute}
  }

  editeazaInterval(interval_orar: IntervaleZilniceCreateInterface){
    console.log("Editeaza interval ==>> ", interval_orar)
    this.interval_editat_index = this.intervale_orare.indexOf(interval_orar)
    console.log("Editeaza interval index or interval orar in this.intervale_orare ==>> ", this.interval_editat_index)
    var intermediar = this.obtineOraSiMinuteleSeparate(interval_orar)
    console.log(" Return de la Obtine ora si minutul == >>",intermediar)
    this.flag_editeaza_interval_orar = true
    // this.interval_orar_start = new Date(interval_orar.start)
    this.interval_orar_start = new Date(new Date().setHours(intermediar.start_ora,intermediar.start_minute))
    this.interval_orar_end = new Date(new Date().setHours(intermediar.end_ora,intermediar.end_minute))
    // console.log("interval_orar start ==>> ",  interval_orar.start)
    // console.log("Date interval_orarl ==>> ", new Date(interval_orar.start))


  }

  salveazaInterval(){
    // console.log("Din salveaza interval")
    // console.log("Index",this.interval_editat_index)
    // console.log("Intervale orare ",this.intervale_orare)
    // console.log("Intervale orare de index",this.intervale_orare[this.interval_editat_index])
    if (this.flag_editeaza_interval_orar){
      this.flag_editeaza_interval_orar = false
    }

    if (this.checkIntervaleOrare(this.interval_orar_start, this.interval_orar_end)){
      this.intervale_orare[this.interval_editat_index].start = this.datePipe.transform (this.interval_orar_start,"HH:mm")
      this.intervale_orare[this.interval_editat_index].end = this.datePipe.transform (this.interval_orar_end,"HH:mm")
      this.actualizeazaIntervaleDataSource()
      var intermediar_x = this.obtineOraSiMinuteleSeparate(this.intervale_orare[this.intervale_orare.length-1])
      this.interval_orar_start = new Date(new Date().setHours(intermediar_x.end_ora,intermediar_x.end_minute,0))
      this.interval_orar_end = new Date(this.interval_orar_start.getTime() + (this.afla_durata_serviciului(this.newMedic.servicii_id) * 60 * 1000))
    }

  }

  addIntervaleOrare(){
    this.flag_add_intervale_orare = true
    // while (document.getElementById('intervalorastart') === null){
    //   setTimeout(() =>{
    //     console.log("Astept o secunda")
    //   }, 1000)
    // }
    // console.log("Inner HTML DIN interval ora start ==>> ",document.getElementById('intervalorastart'))
  }
  openSnackBar(text: string) {
    this._snackBar.open(text,'inchide');
  }

  AddMedicToServer(){
    // for (let i of this.newMedic.departament){
    //   this.newMedic.departament_id = i.id
    // }
    // this.newMedic.departament_id = this.newMedic.departament.id

    // for (let j of this.newMedic.servicii){
    //   this.newMedic.servicii_id = j.id
    // }
    // for (let q of this.newMedic.specialitate){
    //   this.newMedic.specialitatea_id = q.id
    // }
    delete this.newMedic.departament
    // delete this.newMedic.program
    delete this.newMedic.specialitate
    delete this.newMedic.servicii
    delete this.newMedic.contract_CNAS
    // delete this.newMedic.id
    this.newMedic.intervale_zilnice = this.intervale_orare
    console.log("New medic is ==>> ",this.newMedic)
    this.mediciService.addMedicToServer(this.newMedic)
    .subscribe ( (data) => {
      if (data){
        console.log("Data from add Medic to Server ==>> ", data)
        this.flag_add_medic = this.flag_add_program = this.flag_fixed_program = this.flag_add_intervale_orare = this.flag_editeaza_interval_orar = false
        this.openSnackBar("Terapeut adaugat cu succes")
      }
    })
  }
}
