<!-- <mat-form-field>
  <input matInput [(ngModel)]="serviciu.cod" placeholder="Cod Serviciu Nou">
</mat-form-field> -->
<!-- <mat-form-field style="padding-left: 20px;"> -->
<mat-form-field style="">
  <input matInput [(ngModel)]="serviciu.denumire" placeholder="Nume Serviciu Nou">
</mat-form-field>
<br>
 <mat-form-field>
     <mat-label>Categorie</mat-label>
     <!-- <mat-select [(value)] = serviciu.tipServiciu> -->
      <mat-select [ngModel]="serviciu?.tipServiciu" (ngModelChange)="changeCategorie($event)">
         <mat-option *ngFor="let cat of tipuriDeServicii" [value]="cat">
             {{cat.denumire}}
         </mat-option>
     </mat-select>
 </mat-form-field>
 <mat-form-field style="padding-left: 20px;">
  <mat-label>Specialitate</mat-label>
  <mat-select [ngModel] = "serviciu.specialitate" (ngModelChange)="changeSpecialitate($event)" >
      <mat-option *ngFor="let specialitateMedic of specialitatiMedici" [value]="specialitateMedic">
          {{specialitateMedic.denumire}}
      </mat-option>
  </mat-select>
</mat-form-field>
<!-- <br> -->
<mat-form-field style="padding-left: 20px;">
  <mat-label>Durata</mat-label>
  <input matInput type="number" [(ngModel)]= "serviciu.timp">
  <span matSuffix>minute</span>
</mat-form-field>
<br>
<br>
<mat-form-field>
  <mat-label>Pret</mat-label>
  <input matInput type="number" [(ngModel)]= "serviciu.pret">
  <span matSuffix>RON  </span>
</mat-form-field>
<!-- <br> -->
<mat-checkbox style="padding-left: 20px;" [(ngModel)]="serviciu.tva"> Discount: </mat-checkbox>
<!-- <br> -->
<mat-form-field>
  <input matInput type="number" [disabled]="!serviciu.tva" [(ngModel)]= "serviciu.valoare_tva">
  <span matSuffix>%</span>
</mat-form-field>
<div mat-dialog-actions align='center'>
  <button mat-raised-button color="warn" mat-dialog-close >Inchide</button>
  <button mat-raised-button color="primary" (click)="updatevalues()" mat-dialog-close >Salveaza</button>

</div>
