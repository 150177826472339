import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { ConfirmationdialogComponent } from '../confirmationdialog/confirmationdialog.component';
import { PdfpreviewerComponent } from '../pdfpreviewer/pdfpreviewer.component';
import { ConsultatiiService } from '../services/consultatii.service';
import { PDFViewerDialogService } from '../services/pdfviewerdialog.service';

@Component({
  selector: 'app-stergeconsultpdfbutton',
  templateUrl: './stergeconsultpdfbutton.component.html',
  styleUrls: ['./stergeconsultpdfbutton.component.css']
})
export class StergeconsultpdfbuttonComponent implements OnInit {
  @Input() consultData: any;
  @Input() pacientData: any;
  @Output() deleted = new EventEmitter<boolean>();
  public base64 : string
  public caz : number = 1
  fileUrl;
  constructor(
    private sanitizer: DomSanitizer,
    private printService: NgxExtendedPdfViewerService,
    public consultservice: ConsultatiiService,
    public dialog: MatDialog,

  ) {
    // if (data){
    //   // console.log("Data in PDF PREVIEWER ==>> ", data)
    //   this.base64 = data
    // }
  }

  ngOnInit(): void {
  }

  stergePDFBtnClick(){
    // console.log("CONSULT DATA -->> DOWNLOAD BUTTON ==>> ", this.consultData)
    // console.log("PACIENT DATA -->> DOWNLOAD BUTTON ==>> ", this.pacientData)

    const dialogRef = this.dialog.open( ConfirmationdialogComponent, {
      // width: '100%',
      // height: '400px',
      data:{
        'mesaj': "Esti sigur ca vrei sa stergi aceasta consultatie?"
      }
    });
    dialogRef.afterClosed().subscribe ( result => {
      console.log("RESULT LA INCHIDEREA CONFIRMATION DIALOG ==>> ",result)
      if(result){
        this.consultservice.stergeConsultFromServerByFisierID(this.consultData.idFisier)
        .subscribe ( (data) => {
          if (data) {
            // console.log("DATA FROM DELETE CONSULT FROM SERVER ==>> ", data)
            this.deleted.emit(true)
          }
        })
      }
    })

  }
}
