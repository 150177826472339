import { Component, Inject, OnInit, LOCALE_ID } from '@angular/core';
import { DatePipe, DecimalPipe, formatNumber } from '@angular/common'
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiciiService } from 'src/app/services/servicii.service';
import { Validators} from '@angular/forms';
import { MediciService } from '../services/medici.service';
import { IntervalService,CustomDateAdapter, ZILELE_SAPTAMANII } from '../services/interval.service';
import { NgModule } from '@angular/core';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { IncasariService } from '../services/incasarecasierie.service';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'd',
  },
  display: {
    dateInput: 'd',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-casierieservicii',
  templateUrl: './casierieservicii.component.html',
  styleUrls: ['./casierieservicii.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.

    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    // },

    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class CasierieserviciiComponent implements OnInit {
  discount = new FormControl('',[Validators.max(100), Validators.min(0)])
  dataefectuarii: Date = new Date();
  stepMinute: number = 5
  serviciilist: any[]
  medicilist: any[]
  selectedservicii: any
  pret: any
  selectedmedic: any
  are_discount: boolean = false
  total: any
  discountaplicat: number = 0
  weekendsDatesFilter = (d: Date):boolean => {
    if (d != null){
      const day = d.getDay();
      return day !== 0 && day !== 6 ;
      }
    return false
    }
  // Flags
  flag_selected_date: boolean = false
  flag_selected_serviciu: boolean = false
  flag_selected_terapeut: boolean = false
  flag_init_data_efectuarii: boolean = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    @Inject(LOCALE_ID) public locale: string,
    public incasareService: IncasariService,
    public serviciiService: ServiciiService,
    private dialogRef: MatDialogRef<CasierieserviciiComponent>,
    private datePipe: DatePipe,
    private intervalService: IntervalService,
    private dateAdapter: DateAdapter<Date>,
    public medicService: MediciService
    ) {
      this.dateAdapter.setLocale('ro-RO');
      this.medicService.getMediciFromServer()
      .subscribe( (data) => {
        if (data){
          this.medicilist = data
          console.log("Medici Casierie Data ==>> ", this.medicilist)
        }
      })
      this.serviciiService.getServiciiFromServer()
      .subscribe ( (data) =>{
        if (data){
          this.serviciilist = data
          console.log("Servicii Casierie Data ==>> ", this.serviciilist)
        }
      })
    }

  ngOnInit(): void {

  }

  arevirgule(string_de_testat: string){
    console.log("Are virgule => String de testat ==>> ", string_de_testat)
    return ( string_de_testat.indexOf(","))
  }

  schimbavirgulainpunct(string_de_modificat: String, indexul_virgulei: number){
    var intermediar = [...string_de_modificat]
    intermediar[indexul_virgulei] = '.'
    console.log("Noul String este ==>> ", intermediar)
    return (intermediar.join(''))
  }

  calculeazatotalul(){
    var discountvalue: string = this.discount.value.toString()
    if (this.are_discount){
      console.log("Discount peste 0")
      console.log("Are virgule de discount value ==>> ",this.arevirgule(discountvalue))
      if (this.arevirgule(discountvalue) >= 0){
        console.log("Discount are virgule")
        console.log("Virgula este la ==>> ", this.arevirgule(this.discount.value.toString()))
        this.discountaplicat = Number(this.schimbavirgulainpunct(this.discount.value.toString(),this.arevirgule(this.discount.value.toString())).toString())
        console.log("Discount are virgule si noul discount este ==>> ", this.discountaplicat)
      }
      else{
        this.discountaplicat = Number(this.discount.value)
        console.log("Discount -- >> NU <<--are virgule si noul discount este ==>> ", this.discountaplicat)
      }
    }
    else{
      console.log("Discount 0, discount aplicat ar trebui sa fie 0 by default ==>> ", this.discountaplicat)
    }
    // Verifica valoarea lui discount
    console.log("Calculeaza procent ==>> ", this.discountaplicat)


    if (this.arevirgule(this.pret.toString()) >= 0){
      console.log("Pret are virgule")
      console.log("Virgula este la ==>> ", this.arevirgule(this.pret.toString()))
      this.pret = Number(this.schimbavirgulainpunct(this.pret.toString(),this.arevirgule( this.pret.toString() ) ) )
      console.log("Pret are virgule si noul pret este ==>> ", this.pret)
    }
    else{
      this.pret = Number(this.pret)
      console.log("Pret -- >> NU <<--are virgule si noul pret este ==>> ", this.pret)
    }

    this.total = ( 1 - (this.discountaplicat  / 100) )  * this.pret
    console.log("Total Pret ==>> ", formatNumber(this.total,this.locale,'1.0-2'))
  }

  init_data_efectuarii(){
    this.dataefectuarii = new Date()
    this.flag_init_data_efectuarii = true
  }

  selecteazaserviciu(serviciuselectat){
    console.log("Serviciu selectat ==>> ", serviciuselectat.value)
    this.pret = serviciuselectat.value.pret
    this.flag_selected_serviciu = true
    console.log("Pret selectat ==>> ", this.pret)
    this.calculeazatotalul()
    if (this.flag_selected_serviciu && this.flag_selected_terapeut){
      this.init_data_efectuarii()
    }
  }

  selecteazamedic(medicselectat){
    console.log("Medic selectat ==>> ", medicselectat.value)
    this.selectedmedic = medicselectat.value.id
    this.flag_selected_terapeut = true
    console.log("Selected Medic selectat ==>> ", this.selectedmedic)
    if (this.flag_selected_serviciu && this.flag_selected_terapeut){
      this.init_data_efectuarii()
    }
  }

  schimbadiscount(){
    console.log("Are discount la inceput ==> ", this.are_discount)
    this.are_discount = !this.are_discount
    console.log("Are discount la final ==> ", this.are_discount)
  }

  extragemedic(id){
    for (var i of this.medicilist){
      if(i.id === id){
        return i
      }
    }
    return null
  }

  schimbadata(event: any){
    this.flag_selected_date = true
    console.log(" Schimba Data ==>> ", event.value)
    this.dataefectuarii = new Date(event.value)
    console.log(" Data Zi ID ==>> ", this.dataefectuarii.getDay())

    console.log(" Medic program ", this.extragemedic(this.selectedmedic).program[ZILELE_SAPTAMANII[this.dataefectuarii.getDay()-1]].OraStart)
    // console.log(" Medic program ", this.extragemedic(this.selectedmedic).program[ZILELE_SAPTAMANII[this.dataefectuarii.getDay()]].OraStart)
    var data_efectuarii_intermediar_string = this.datePipe.transform(this.dataefectuarii, "y-MM-dd")
    var ora_efectuarii_intermediar_string =  this.datePipe.transform(this.extragemedic(this.selectedmedic).program[ZILELE_SAPTAMANII[this.dataefectuarii.getDay()-1]].OraStart,'HH:mm')
    console.log(" data_efectuarii_intermediar_string trecut prin pipe ==>> ", data_efectuarii_intermediar_string)
    console.log(" ora_efectuarii_intermediar_string trecut prin pipe ==>> ", ora_efectuarii_intermediar_string)
    this.dataefectuarii = this.incasareService.parse_uneste_data_cu_timo (data_efectuarii_intermediar_string+"-"+ora_efectuarii_intermediar_string)
    console.log(" dataefectuarii la final ==>> ", this.dataefectuarii)

    // console.log(" Ora start transfort to striung ", this.incasareService.parsetime( this.extragemedic(this.selectedmedic).program[ZILELE_SAPTAMANII[this.dataefectuarii.getDay()]].OraStart))
  }

  confirm(){
    console.log("Selected servicii inainte de close dialog ==>> ", this.selectedservicii)
    console.log("Pret inainte de close dialog ==>> ", this.pret)

    console.log("Are discount inainte de close dialog ==>> ", this.are_discount)
    console.log("Discount inainte de close dialog ==>> ", this.discount.value)

    console.log("Total inainte de close dialog ==>> ", this.total)

    console.log("Data efectuarii inainte de close dialog ==>> ", this.dataefectuarii)

    console.log("Selected medic id inainte de close dialog ==>> ", this.selectedmedic)
    this.dialogRef.close(
      {
        serviciuid: this.selectedservicii.id,
        medicid: this.selectedmedic,
        pret: this.pret,
        discount: this.discountaplicat,
        total: this.total,
        dataefectuarii: this.datePipe.transform(this.dataefectuarii,'y-MM-dd-HH:mm'),
        are_discount: this.are_discount
      }
    )
  }

}


