

<!-- <ejs-dashboardlayout>
  <e-panels>
   <e-panel>

   </e-panel>
   <e-panel></e-panel>
  </e-panels>
</ejs-dashboardlayout> -->

<div class="control-section">

  <!-- <ejs-dashboardlayout id='defaultLayout' [columns]='5' [cellSpacing]='cellSpacing' [panels]='panels' [cellAspectRatio]='cellAspectRatio'> -->
    <ejs-dashboardlayout id='default_dashboard' columns="3" #default_dashboard [allowDragging]="false" [cellSpacing]='cellSpacing' [cellAspectRatio]='cellAspectRatio' >
     <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="1" data-sizeY="1">
        <span id="close" class="e-template-icon e-close-icon" (click)="onCloseIconHandler($event)"></span>
        <div class="e-panel-container">
            <div class="text-align">0</div>
        </div>
    </div>
    <div id="two" class="e-panel" data-row="0" data-col="1" data-sizeX="1" data-sizeY="1">
        <span id="close" class="e-template-icon e-close-icon" (click)="onCloseIconHandler($event)"></span>
        <div class="e-panel-container">
            <div class="text-align">1</div>
        </div>
    </div>
    <div id="three" class="e-panel" data-row="0" data-col="2" data-sizeX="1" data-sizeY="1">
        <span id="close" class="e-template-icon e-close-icon" (click)="onCloseIconHandler($event)"></span>
        <div class="e-panel-container">
            <div class="text-align">2</div>
        </div>
    </div>
    <div id="four" class="e-panel" data-row="1" data-col="0" data-sizeX="3" data-sizeY="1">
        <!-- <span id="close" class="e-template-icon e-close-icon" (click)="onCloseIconHandler($event)"></span> -->
        <div class="e-panel-container">
            <!-- <div class="text-align">3</div> -->

            <ejs-grid
            #grid
            [dataSource]="lista_Beneficiari"
            (load)="load()"
            [allowFiltering]="true"
            [allowPaging]="true"
            [pageSettings]="pageSettings"
            width="100%"
            (rowSelected)='rowSelected($event)'
            (queryCellInfo)='customiseCell($event)'
            [allowSorting]='true'
            [allowResizing]="true"
            [allowReordering]="true"

          >
            <e-columns>
              <e-column
                field="id"
                headerText="ID"
                width="120"
                textAlign="center"
              ></e-column>
              <e-column
                field="date_personale.nume"
                headerText="Nume"
                autoFit="true"
                headerTextAlign="center"
              ></e-column>
              <e-column
                field="date_personale.prenume"
                headerText="Prenume"
                autoFit="true"
                headerTextAlign="center"
              ></e-column>
              <e-column
                field="date_personale.cnp"
                headerText="CNP"
                autoFit="true"
                headerTextAlign="center"
              ></e-column>
              <e-column
                field="date_contact.email"
                headerText="Email"
                autoFit="true"
                headerTextAlign="center"
              ></e-column>
              <e-column
                field="date_contact.telefon"
                headerText="Telefon"
                autoFit="true"
                headerTextAlign="center"
              ></e-column>
            </e-columns>
          </ejs-grid>

        </div>
    </div>

  </ejs-dashboardlayout>

  <div >
    <!-- <button class="e-control e-btn" style="position: absolute;" id="targetButton" (click)="onOpenDialog($event)">Open Dialog</button> -->
    <div #container class='root-container'>
      <ejs-dialog
      id='dialog'
      #ejDialog
      height= '90%'
      minHeight='90%'
      width="90%"
      isModal='true'
      [visible]='flag_dialog_pacient_open'
      (overlayClick)="onOverlayClick()"
      [position]='position'
      >
        <ng-template #header>
          <span>Choose a Dialog Position</span>
        </ng-template>
        <ng-template #content>
          <app-beneficiar-details-tab *ngIf="flag_dialog_pacient_can_open"
          [beneficiar] = "beneficiar"
          ></app-beneficiar-details-tab>
        </ng-template>
      </ejs-dialog>
    </div>
  </div>
</div>

