<h1 mat-dialog-title>Adauga o programare</h1>
<div mat-dialog-content>
    <!-- <mat-form-field>
        <mat-label>Nume</mat-label>
        <input matInput type="text" [(ngModel)]= "numeprogramare">
      </mat-form-field> -->


            <div style="display: flex; ">
                
      <!-- <mat-form-field appearance="fill">
        <mat-label>Selecteaza un serviciu</mat-label>
        <mat-select [(value)]="terapie">
          <mat-option *ngFor="let val of serviciilist" value="val.timp">{{val.denumire}} -  {{val.timp}} minute</mat-option>
        </mat-select>
      </mat-form-field> -->
      &nbsp; &nbsp;
        <mat-form-field>
            <mat-label>Data</mat-label>
            <input matInput [matDatepicker]="picker3" [value]="datadestart.value">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>

    <!-- <div style="display: flex;align-items: center;">
    <ngx-mat-timepicker 
    [(ngModel)]="start_time"
    [showSpinners]="true">
    </ngx-mat-timepicker>
    </div> -->
    

</div>
<div>
<div style="display: flex;align-items: center;">
    <!-- <p>Ora:</p> -->
    <span class="orardesomn"> De la:   </span>
    <!-- <ngx-timepicker-field [controlOnly]="true" style= "width: 250px" [format]="24" [defaultTime]="start_time"></ngx-timepicker-field> -->
    <ngx-mat-timepicker 
    [(ngModel)]="start_time"
    [showSpinners]="true">
    </ngx-mat-timepicker>
<mat-checkbox [(ngModel)]="programare_recurenta" > Programare Recurenta  &nbsp; &nbsp; </mat-checkbox>
<input matInput style="width:40px" type="number" [(ngModel)]="nr_recurenta">
<mat-form-field appearance="fill">
    <mat-label>Perioada</mat-label>
    <select matNativeControl  [(ngModel)]="perioada_recurenta" required>
      <option value="1">Zile</option>
      <option value="5">Saptamani</option>
      <option value="20">Luni</option>
    </select>
  </mat-form-field>
</div>

<br>
<!-- <div style= "width: 250px">

    <span>Se termina la:</span><ngx-timepicker-field [controlOnly]="true" style= "width: 250px" [format]="24" [defaultTime]="'23:11'"></ngx-timepicker-field>
</div> -->
<br>




</div>
<div mat-dialog-actions>
    <button mat-raised-button color="warn" mat-dialog-close >Cancel</button>
    <button mat-raised-button color="primary" (click)="sendvalues()" cdkFocusInitial>Ok</button>
</div>