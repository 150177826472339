<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> <h2> FIȘĂ EVALUARE INFS</h2></span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Nume: {{ (fisa_evaluare_infs_parinti.nume_pacient!="" ) ?  fisa_evaluare_infs_parinti.nume_pacient : "Nespecificat" }} </span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Prenume: {{ (fisa_evaluare_infs_parinti.prenume_pacient!="" ) ?  fisa_evaluare_infs_parinti.prenume_pacient : "Nespecificat" }}</span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Varsta: {{ (fisa_evaluare_infs_parinti.varsta_pacient!="" ) ?  fisa_evaluare_infs_parinti.varsta_pacient + ' ani' : "Nespecificat" }} </span>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Adresa: {{ (fisa_evaluare_infs_parinti.adresa_pacient!="" ) ?  fisa_evaluare_infs_parinti.adresa_pacient : "Nespecificat"}} </span>


<mat-form-field style="width: 100%;" >
    <mat-label>Diagnostic </mat-label>
    <textarea matInput [(ngModel)]="fisa_evaluare_infs_parinti.diagnostic_pacient" ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Istoric chirurgical: </mat-label>
    <textarea matInput [(ngModel)]="fisa_evaluare_infs_parinti.istoric_chirurgical"></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Istoric de botox (data): </mat-label>
    <textarea matInput [(ngModel)]="fisa_evaluare_infs_parinti.istoric_de_botox"  ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Istoric de fracturi, osteopenie, osteoporoză: </mat-label>
    <textarea matInput  [(ngModel)]="fisa_evaluare_infs_parinti.istoric_de_fracturi_osteopenie_osteoporoza"  ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Greutatea copilului (KG): </mat-label>
    <textarea matInput  [(ngModel)]="fisa_evaluare_infs_parinti.greutatea_copilului"   ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Înălțime: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.inaltime"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Convulsii (frecvență, data ultimei convulsii): </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.convulsii"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Probleme cardiace, pulmonare, renale: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.probleme_cardiace_pulmonare_renale"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Hidrocefalie/shunt: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.hidrocefalie_shunt"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Probleme metabolice/diabet: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.probleme_metabolice_diabet"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Comunicare ( verbală/non-verbală): </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.comunicare"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Atenție: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.antentie"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Abilități de îndeplinire a comenzilor: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.abilitati_de_indeplinire_a_comenzilor"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Abilități funcționale ale copilului (rotogolire, târâre, patrupedie, mers): </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.abilitati_functionale_ale_copilului"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Dureri: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.dureri"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Orteze (AFO/KAFO, etc): </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.orteze"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Lista echipamentelor pe care copilul le folosește (scaun rulant, cârje, triciclu, etc): </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.lista_echipamente_folosite"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Lista medicamentelor/suplimentelor: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.lista_medicamente_suplimente"
    ></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" >
    <mat-label>Somn (câte ore/noapte, ora la care adoarme seara): </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.somn"
    ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>Oboseală (câte de repede obosește): </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.oboseala"
    ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>Starea emoțională și comportamentală a copilului (anxietate, frică, depresie, fericire, cât de repede plânge): </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.stare_emotionala"
    ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>Școală/interacțiune socială: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.scoala_interactiune_sociala"
    ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>Tehnologia pe care o folosește copilul zilnic (TV, tabletă, telefon, jocuri video, cât timp/zi): </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.tehnologie_folosita_zilnic"
    ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>Terapie (ce fel de terapie/câte ore/zi): </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.terapie"
    ></textarea>
</mat-form-field>

<span style="
font-size: 18px;
display: flex;
padding: 10px;
left: 5px;
"> Nutriție: </span>

<mat-form-field style="width: 100%;" >
    <mat-label>Câte mese/zi: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.nutritie.cate_mese_zi"
    ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>Alergii alimentare: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.nutritie.alergii_alimentare"
    ></textarea>
</mat-form-field>
<mat-form-field style="width: 100%;" >
    <mat-label>lista cu exemple de alimente pe care le consumă copilul: </mat-label>
    <textarea matInput
    [(ngModel)]="fisa_evaluare_infs_parinti.nutritie.exemple_alimente_consumate"
    ></textarea>
</mat-form-field>
<ng-container *ngIf="caz == 1">

<button mat-raised-button (click)="semneazaRL()" > Semnatura Reprezentant Legal</button>
<button mat-raised-button (click)="semneazaTerapeut()"  > Semnatura Terapeut</button>
<button mat-raised-button (click)="openPDFPreviewComponent()" > Previzualizeaza </button>
</ng-container>
<ng-container *ngIf="caz == 2">
  <button mat-raised-button (click)="openPDFPreviewSsaveModificari()"  > Genereaza Document </button>
  <button mat-raised-button color="warn"  mat-dialog-close > Inchide </button>
</ng-container>
<!-- <button mat-raised-button (click)="saveData()"> Salveaza</button> -->
