import { Component, OnInit } from '@angular/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { logo_url_base64 } from 'src/app/models/logo-base64.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Pacient } from 'src/app/models/pacientdetails.model';
import { Inject } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-fisa-examinare-logopedica',
  templateUrl: './fisa-examinare-logopedica.component.html',
  styleUrls: ['./fisa-examinare-logopedica.component.css']
})
export class FisaExaminareLogopedicaComponent implements OnInit {
  pacient = new Pacient()
  fisa_examinare_logopedica: {
    nume_examinator: string,
    prenume_examinator: string,
    data_examinarii: string,
    date_generale: {
      personale: {
        nume_de_familie: string,
        prenumele: string,
        nascut_in_anul: string,
        nascut_in_luna: string,
        nascut_in_ziua: string,
        domiciliu: string,
        institutia: string
      },
      familiale: {
        nume_tata: string,
        prenume_tata: string,
        varsta_tata: string,
        nationalitate_tata: string,
        studii_tata: string,
        ocupatia_tata: string,
        locul_de_munca_tata: string,
        nume_mama: string,
        prenume_mama: string,
        varsta_mama: string,
        nationalitate_mama: string,
        studii_mama: string,
        ocupatia_mama: string
        locul_de_munca_mama: string
      },
      motivul_examinarii: {
        cu_ce_acuze_este_trimis: string
      }
    },
    anameza: {
      familiala: {
        structura_familiei: string,
        al_catelea_copil_este: string,
        starea_de_sanatate_a_membrilor_familiei: string,
        persoane_cu_tulburari_de_libaj_in_familie: string,
        relatiile_in_familie: {
          intre_parinti: string,
          fata_de_copil: string,
          atitudine_fata_de_defectul_de_pronuntie_al_copilului: string,
          daca_sunt_stangaci_sau_ambidextri_in_familie: string,
          conditiile_materiale_igienice_educative_ale_familiei: string,
        }
      },
      personala: {
        nasterea: string,
        nastere_in_luna: string,
        greutate_la_nastere: string,
        tulburari_psihice: string
      },
      date_asupra_auzului_si_limbajului: {
        ganguritul: string,
        ritmul_vorbirii: string,
        limba_materna_si_cea_a_mediului_inconjurator: string,
        intelegerea_vorbirii_persoanelor_din_anturaj: string,
        insusirea_unei_limbi_straine: string,
        insusirea_scrierii_si_a_citirii: string,
        cand_a_aparut_tulburarea_de_limbaj: string,
        cauza_reala_sau_presupusa: string,
        daca_a_beneficiat_de_tratament_logopedic: string
      }
    },
    examenul_somatic_si_functional: {
      aspecte_somatice: {
        vorbirea: string,
        cavitate_bucala: string,
        valul_palatin: string,
        limba: string,
        buzele: string,
        maxilarele: string,
        dantura: string
      }
      motricitatea: {
        mersul: string,
        coordonare_generala_a_miscarilor: string,
        lateralitatea: string,
        motricitatea_limbii: string,
        motricitatea_mimico_faciala: string,
        motricitatea_buzelor: string,
      },
      functia_auditiva: {
        atentia_auditiva: string,
        memoria_auditiva: string,
        capacitate_de_diferentiere_fonematica: string,
        auzul_muzical: string
      },
      functie_fonatorie: {
        intensitatea_vocii: string,
        calitatea_vocii: string
      },
      structuri_perceptiv_motrice: {
        schema_corporala: string,
        orientare_temporala: {
          momentele_zilei: string,
          ceasul: string,
          zilele_saptamanii: string,
          lunile_anului: string,
          anotimpurile: string
        },
        orientare_spatiala: string,
        culori: string,
        marimi: string,
        cantitati: string,
        forme_geometrice: string
      }
    },
    limbajul_oral_si_scris: {
      pronuntia_sunetelor_si_a_cuvintelor: {
        reproducerea_fenomenelor_pe_baza_de_auz: string,
        reproducerea_fenomenelor_pe_baza_de_citire: string,
        miscari_articulatorii_defectuase: string,
        miscari_asociate: string,
        omisiuni_de_sunete: string,
        confundarea_sunetelor: string,
        estomparea_terminatiei_cuvintelor: string
      },
      ritmul_si_fluenta_vorbirii: {
        fluenta: string,
        ritmul_vorbirii: string,
        poticniri_la_sunete: string,
        poticniri_la_silabe: string,
        poticniri_la_cuvinte: string,
        repetari_de_sunete: string,
        repetari_de_silabe: string,
        repetari_de_cuvinte: string,
        spasme_articulatorii_si_muschii_afectati: string,
        calitatea_vorbirii: {
          independenta: string,
          reflectata: string
        },
        calitatea_citirii: {
          independenta: string,
          reflectata: string
        },
        diverse_fobii: string,
        miscari_concomitente: string,
        vorbirea_in_diferite_situatii: string,
        balbaiala_in_cant_si_la_instrumente: string,
        balbaiala_in_scris: string
      },
      scrierea_si_citirea: {
        copierea: string,
        dictarea: string,
        compunerea: string,
        structura_grafica: string,
        structura_logica: string,
        citire: string
      },
      domeniu_socio_afectiv: {
        temperament: string,
        comportament: string,
        activitati_preferate: string,
        activitati_evitate: string,
        jocuri_preferate: string,
        personaje_indragite_din_desene_animate: string
      }
    },
    diagnosticul_tulburarilor_de_limbaj: {
      caracterizarea_limbajului_si_a_persoanei_examinate: {
        vorbirea: string,
        scrisul: string,
        cititul: string,
        succesul_scolar: string,
        conduita: string
      },
      cauzele_si_diagnosticul: {
        cauze_presupuse: string,
        cauze_confirmate: string,
        diagnosticul_initial: string,
        evolutia_diagnosticului: string,
        diagnosticul_final: string,
        alte_mentiuni: string
      }
    }
  } = {
      nume_examinator: "",
      prenume_examinator: "",
      data_examinarii: "",
      date_generale: {
        personale: {
          nume_de_familie: "",
          prenumele: "",
          nascut_in_anul: "",
          nascut_in_luna: "",
          nascut_in_ziua: "",
          domiciliu: "",
          institutia: ""
        },
        familiale: {
          nume_tata: "",
          prenume_tata: "",
          varsta_tata: "",
          nationalitate_tata: "",
          studii_tata: "",
          ocupatia_tata: "",
          locul_de_munca_tata: "",
          nume_mama: "",
          prenume_mama: "",
          varsta_mama: "",
          nationalitate_mama: "",
          studii_mama: "",
          ocupatia_mama: "",
          locul_de_munca_mama: ""
        },
        motivul_examinarii: {
          cu_ce_acuze_este_trimis: ""
        }
      },
      anameza: {
        familiala: {
          structura_familiei: "",
          al_catelea_copil_este: "",
          starea_de_sanatate_a_membrilor_familiei: "",
          persoane_cu_tulburari_de_libaj_in_familie: "",
          relatiile_in_familie: {
            intre_parinti: "",
            fata_de_copil: "",
            atitudine_fata_de_defectul_de_pronuntie_al_copilului: "",
            daca_sunt_stangaci_sau_ambidextri_in_familie: "",
            conditiile_materiale_igienice_educative_ale_familiei: "",
          }
        },
        personala: {
          nasterea: "",
          nastere_in_luna: "",
          greutate_la_nastere: "",
          tulburari_psihice: ""
        },
        date_asupra_auzului_si_limbajului: {
          ganguritul: "",
          ritmul_vorbirii: "",
          limba_materna_si_cea_a_mediului_inconjurator: "",
          intelegerea_vorbirii_persoanelor_din_anturaj: "",
          insusirea_unei_limbi_straine: "",
          insusirea_scrierii_si_a_citirii: "",
          cand_a_aparut_tulburarea_de_limbaj: "",
          cauza_reala_sau_presupusa: "",
          daca_a_beneficiat_de_tratament_logopedic: ""
        }
      },
      examenul_somatic_si_functional: {
        aspecte_somatice: {
          vorbirea: "",
          cavitate_bucala: "",
          valul_palatin: "",
          limba: "",
          buzele: "",
          maxilarele: "",
          dantura: ""
        },
        motricitatea: {
          mersul: "",
          coordonare_generala_a_miscarilor: "",
          lateralitatea: "",
          motricitatea_limbii: "",
          motricitatea_mimico_faciala: "",
          motricitatea_buzelor: "",
        },
        functia_auditiva: {
          atentia_auditiva: "",
          memoria_auditiva: "",
          capacitate_de_diferentiere_fonematica: "",
          auzul_muzical: ""
        },
        functie_fonatorie: {
          intensitatea_vocii: "",
          calitatea_vocii: ""
        },
        structuri_perceptiv_motrice: {
          schema_corporala: "",
          orientare_temporala: {
            momentele_zilei: "",
            ceasul: "",
            zilele_saptamanii: "",
            lunile_anului: "",
            anotimpurile: ""
          },
          orientare_spatiala: "",
          culori: "",
          marimi: "",
          cantitati: "",
          forme_geometrice: ""
        }
      },
      limbajul_oral_si_scris: {
        pronuntia_sunetelor_si_a_cuvintelor: {
          reproducerea_fenomenelor_pe_baza_de_auz: "",
          reproducerea_fenomenelor_pe_baza_de_citire: "",
          miscari_articulatorii_defectuase: "",
          miscari_asociate: "",
          omisiuni_de_sunete: "",
          confundarea_sunetelor: "",
          estomparea_terminatiei_cuvintelor: ""
        },
        ritmul_si_fluenta_vorbirii: {
          fluenta: "",
          ritmul_vorbirii: "",
          poticniri_la_sunete: "",
          poticniri_la_silabe: "",
          poticniri_la_cuvinte: "",
          repetari_de_sunete: "",
          repetari_de_silabe: "",
          repetari_de_cuvinte: "",
          spasme_articulatorii_si_muschii_afectati: "",
          calitatea_vorbirii: {
            independenta: "",
            reflectata: ""
          },
          calitatea_citirii: {
            independenta: "",
            reflectata: ""
          },
          diverse_fobii: "",
          miscari_concomitente: "",
          vorbirea_in_diferite_situatii: "",
          balbaiala_in_cant_si_la_instrumente: "",
          balbaiala_in_scris: ""
        },
        scrierea_si_citirea: {
          copierea: "",
          dictarea: "",
          compunerea: "",
          structura_grafica: "",
          structura_logica: "",
          citire: ""
        },
        domeniu_socio_afectiv: {
          temperament: "",
          comportament: "",
          activitati_preferate: "",
          activitati_evitate: "",
          jocuri_preferate: "",
          personaje_indragite_din_desene_animate: ""
        }
      },
      diagnosticul_tulburarilor_de_limbaj: {
        caracterizarea_limbajului_si_a_persoanei_examinate: {
          vorbirea: "",
          scrisul: "",
          cititul: "",
          succesul_scolar: "",
          conduita: ""
        },
        cauzele_si_diagnosticul: {
          cauze_presupuse: "",
          cauze_confirmate: "",
          diagnosticul_initial: "",
          evolutia_diagnosticului: "",
          diagnosticul_final: "",
          alte_mentiuni: ""
        }
      }
    }

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private datePipe: DatePipe) {
    if (data) {
      this.pacient = data;
      this.fisa_examinare_logopedica.data_examinarii = this.datePipe.transform(new Date(), 'dd.MM.yyyy')
      if (data.date_personale.nume) {
        this.fisa_examinare_logopedica.date_generale.personale.nume_de_familie = data.date_personale.nume;
      }
      if (data.date_personale.prenume) {
        this.fisa_examinare_logopedica.date_generale.personale.prenumele = data.date_personale.prenume;
      }
      if (data.date_personale.data_nasterii) {
        this.fisa_examinare_logopedica.date_generale.personale.nascut_in_anul = this.datePipe.transform(new Date(data.date_personale.data_nasterii), 'yyyy');
        this.fisa_examinare_logopedica.date_generale.personale.nascut_in_luna = this.datePipe.transform(new Date(data.date_personale.data_nasterii), 'MM');
        this.fisa_examinare_logopedica.date_generale.personale.nascut_in_ziua = this.datePipe.transform(new Date(data.date_personale.data_nasterii), 'dd');
      }

      // console.log("data_emanimarii => ",this.fisa_examinare_logopedica.data_examinarii)
    }
  }

  ngOnInit(): void {
  }

  isItSet(variabila) {
    if (variabila != "") {
      return variabila
    }
    return ' - '
  }

  generatePDF(action = 'open') {
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      header: {
        height: 60,
        columns:
          [
            // {
            //   image: logo_url_base64,
            //   width: 50,
            //   alignment: 'center',
            // },
            {
              stack: [
                "Fundația Mihai Neșu Foundation",
                "- Centrul de recuperare pentru copii cu dizabilități neuromotorii Sfântul Nectarie - "
              ],
              margin: 10,
              style: 'textheader'
            }
          ],
        margin: [0, 0, 0, 90]
      },
      content: [
        {
          canvas: [
            {
              type: 'line',
              x1: -100, y1: 0,
              x2: 600, y2: 0,
              lineWidth: 1,
              lineCap: 'square'
            },
            {
              type: 'line',
              x1: -100, y1: 4,
              x2: 600, y2: 4,
              lineWidth: 1,
              lineCap: 'square'
            }
          ]
        },
        {
          image: logo_url_base64,
          width: 200,
          alignment: 'center',
        },
        {
          text: "FISA DE EXAMINARE LOGOPEDICA",
          bold: true,
          alignment: 'center',
          fontSize: 18,
          margin: [0, 50, 0, 80]
        },
        {
          text: "1.	Date Generale:",
          bold: true,
          alignment: 'left',
          decoration: 'underline',
          fontSize: 18,
          margin: [0, 10, 0, 20]
        },
        {
          text: "1.1 Personale:",
          bold: true,
          alignment: 'left',
          decoration: 'underline',
          fontSize: 16,
          margin: [0, 10, 0, 20]
        },
        {
          text: "Numele de familie : " + this.isItSet(this.fisa_examinare_logopedica.date_generale.personale.nume_de_familie) + " prenumele " + this.isItSet(this.fisa_examinare_logopedica.date_generale.personale.prenumele) + " nascut in anul " +
            this.isItSet(this.fisa_examinare_logopedica.date_generale.personale.nascut_in_anul) + " luna " + this.isItSet(this.fisa_examinare_logopedica.date_generale.personale.nascut_in_luna) + " ziua " + this.isItSet(this.fisa_examinare_logopedica.date_generale.personale.nascut_in_ziua),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Domiciliat in: " + this.isItSet(this.fisa_examinare_logopedica.date_generale.personale.domiciliu),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          text: "Institutia: " + this.isItSet(this.fisa_examinare_logopedica.date_generale.personale.institutia),
          bold: true,
          alignment: 'left',
          fontSize: 12,
          margin: [0, 0, 0, 20]
        },
        {
          columns:[
            {
              text: "Semnatura terapeut, ",
              bold: true,
              alignment: 'left',
              fontSize: 12,
              margin: [0, 0, 0, 20]
            },
            {
              text: "Luat la cunoștință de părinte, ",
              bold: true,
              alignment: 'right',
              fontSize: 12,
              margin: [0, 0, 0, 20]
            }
          ]
        }

      ],
      styles: {
        textheader: {
          alignment: 'center',
          bold: true,
          fontSize: 14,
          // lineHeight: 2
        }
      }
    }
    if (action === 'download') {
      pdfMake.createPdf(docDefinition).download();
    } else if (action === 'print') {
      pdfMake.createPdf(docDefinition).print();
    } else {
      pdfMake.createPdf(docDefinition).open();
    }
  }
}
