import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatePipe } from '@angular/common'
import {ServiciiService} from '../../services/servicii.service';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import { MatInput } from '@angular/material/input';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Servicii } from 'src/app/models/nomenclatoare.model';
export const MY_FORMATS = {
  parse: {
    dateInput: 'd',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-serviciiedit',
  templateUrl: './serviciiedit.component.html',
  styleUrls: ['./serviciiedit.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class ServiciieditComponent implements OnInit {

  // serviciu = new Servicii()
  serviciu = null
  specialitatiMedici: any[];
  tipuriDeServicii: any[] = [];

  constructor(
    public serviciiService: ServiciiService,
    private _snackBar: MatSnackBar,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<ServiciieditComponent>,
    public dialg: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data:any ) {
      if(data) {
        console.log("Data ==>> ", data)
        if(data.specialitatiMedici){
          this.specialitatiMedici = data.specialitatiMedici
        }
        if(data.tipuriDeServicii){
          this.tipuriDeServicii = data.tipuriDeServicii
        }
        if(data.serviciu){
          this.serviciu = data.serviciu
          // console.log("SERVICIU DIN DATA ==>> ", this.serviciu)
          // console.log("SERVICIU ID DIN DATA ==>> ", data.serviciu.specialitateid)
          if (data.serviciu.tipserviciuid){
            this.serviciu.tipServiciu = this.findCategorieServiciu(data.serviciu.tipserviciuid)
          }
          else{
            this.serviciu.tipServiciu = null
          }
          if (data.serviciu.specialitateid){
            this.serviciu.specialitate = this.findDenumireSpecialitateMedic(data.serviciu.specialitateid)
          }
          else{
            this.serviciu.specialitate = null
          }
          // console.log("SERVICIU ID DIN DATA SEARCHED ==>> ", this.findCategorieServiciu(data.serviciu.tipserviciuid))
          // console.log("SERVICIU ID DIN DATA SEARCHED ==>> ", this.findDenumireSpecialitateMedic(data.serviciu.specialitateid))
          // console.log("SERVICIU DIN DATA 2 ==>> ", this.serviciu)
        }
       }
    }
    findDenumireSpecialitateMedic(id){
      return this.specialitatiMedici.find( x=> x.id == id)
    }

    findCategorieServiciu(id){
      return this.tipuriDeServicii.find( x=> x.id == id)
    }

  ngOnInit(): void {
  }
  changeCategorie(variabila){
    // console.log("Change Categorie ==>> ", variabila)
    // console.log("Change Categorie veche ==>> ", this.serviciu.tipServiciu)
    this.serviciu.tipServiciu = variabila
    this.serviciu.tipserviciuid = variabila.id
  }
  changeSpecialitate(variabila){
    // console.log("Change Specialitate ==>> ", variabila)
    // console.log("Change Specialitate veche ==>> ", this.serviciu.specialitate)
    this.serviciu.specialitate = variabila
    this.serviciu.specialitateid = variabila.id

  }
  updatevalues(){
    console.log("Serviciu ==>> ", this.serviciu)
    this.dialogRef.close(this.serviciu)
  }
}
