<mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]='false' hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
              Contracte
            </mat-panel-title>
            <mat-panel-description>
              Diferite Contracte
            </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-selection-list>
            <mat-list-option role="listitem" (click)="openContractFurnizorServiciiSocialeComponent()" >Contract furnizor servicii sociale</mat-list-option>
            <mat-list-option role="listitem" (click)="openContractMasinaComponent()" >Contract masina</mat-list-option>
            <mat-list-option role="listitem" (click)="openContractInchiriereComponent()" >Contract Inchiriere</mat-list-option>
        </mat-selection-list>
    </mat-expansion-panel>
</mat-accordion>
