import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PinentryComponent } from '../pinentry/pinentry.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-istoricprogramari',
  templateUrl: './istoricprogramari.component.html',
  styleUrls: ['./istoricprogramari.component.css']
})
export class IstoricprogramariComponent implements OnInit {
  displayedColumns: string[] = ['id','data', 'Ora_start', 'Ora_end','Semneaza'];
  programari = [
    {
      id: 1,
      data: "1.09.2020",
      ora_start: "10:00",
      ora_end: "10:50",
      confirmat: 0
    },
    {
      id: 2,
      data: "2.09.2020",
      ora_start: "10:00",
      ora_end: "10:50",
      confirmat: 0
    },
    {
      id: 3,
      data: "3.09.2020",
      ora_start: "10:00",
      ora_end: "10:50",
      confirmat: 0
    },
    {
      id: 4,
      data: "4.09.2020",
      ora_start: "10:00",
      ora_end: "10:50",
      confirmat: 0
    },
    {
      id: 5,
      data: "5.09.2020",
      ora_start: "10:00",
      ora_end: "10:50",
      confirmat: 0
    },
    {
      id: 6,
      data: "7.09.2020",
      ora_start: "10:00",
      ora_end: "10:50",
      confirmat: 0
    },
    {
      id: 7,
      data: "8.09.2020",
      ora_start: "10:00",
      ora_end: "10:50",
      confirmat: 0
    },
    {
      id: 8,
      data: "9.09.2020",
      ora_start: "10:00",
      ora_end: "10:50",
      confirmat: 0
    },
    {
      id: 9,
      data: "10.09.2020",
      ora_start: "10:00",
      ora_end: "10:50",
      confirmat: 0
    },
    {
      id: 10,
      data: "6.09.2020",
      ora_start: "10:00",
      ora_end: "10:50",
      confirmat: 0
    }
    ]
    constructor(
      public dialog: MatDialog
    ) { }

    ngOnInit(): void {
    }
    semneazacondica(id){
      const dialogRef = this.dialog.open(PinentryComponent,{
        width: '650px',
        height: '550px'
      });
      dialogRef.afterClosed().subscribe( result => {
        for (let i of this.programari){
          if (i.id == id){
            console.log("Am gasit i-ul ==>> ",i)
            i.confirmat = 1
          }
        }
      })
    }
}
