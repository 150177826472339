<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    
    <!-- Nume Column -->
    <ng-container matColumnDef="nume"> 
    <th mat-header-cell *matHeaderCellDef> Nume </th>
    <td mat-cell *matCellDef="let element"> {{element.nume}} </td>
    </ng-container>

    <!-- Prenume Column -->
    <ng-container matColumnDef="prenume">
    <th mat-header-cell *matHeaderCellDef> Prenume </th>
    <td mat-cell *matCellDef="let element"> {{element.prenume}} </td>
    </ng-container>

    <!-- Diagnostic Column -->
    <ng-container matColumnDef="diagnostic">
        <th mat-header-cell *matHeaderCellDef> Diagnostic </th>
        <td mat-cell *matCellDef="let element"> {{element.diagnostic}} </td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
        
    <!-- Telefon Column -->
    <ng-container matColumnDef="telefon">
        <th mat-header-cell *matHeaderCellDef> Telefon </th>
        <td mat-cell *matCellDef="let element"> {{element.telefon}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>

</table>