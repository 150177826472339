<!-- <mat-form-field>
  <mat-label>Alege serviciul</mat-label>
  <mat-select [(value)] = "serviciuselectat" multiple>
      <mat-option *ngFor="let serviciu of servicii" [value]="serviciu.denumire">
          {{serviciu.denumire}}
      </mat-option>
  </mat-select>
  </mat-form-field> -->

  <!-- <mat-form-field>
    <mat-label>Alege diagnostic</mat-label>
    <mat-select [(value)] = "diagnostic" multiple>
        <mat-option *ngFor="let diag of diagnostice" [value]="diag.denumire">
            {{diag.denumire}}
        </mat-option>
    </mat-select>
    </mat-form-field> -->

  <mat-accordion class="example-headers-align">

    <mat-expansion-panel [expanded]='false' hideToggle>
      <mat-expansion-panel-header>
          <mat-panel-title>
            Evaluari
          </mat-panel-title>
          <mat-panel-description>
            Documente de evaluare
            <!-- <mat-icon>account_circle</mat-icon> -->
          </mat-panel-description>
      </mat-expansion-panel-header>


      <mat-selection-list>
          <mat-list-option role="listitem" (click)="openEvaluareaFunctieiMotoriiGrosiereComponent()" >Evaluarea functiei motorii grosiere</mat-list-option>
          <mat-list-option role="listitem" (click)="openEvaluareaReflexelorPrimitiveComponent()" >Evaluarea reflexelor primitive</mat-list-option>
          <!-- <mat-list-option role="listitem" (click)="openFisaExaminareLogopedicaComponent()" >Fisa de examinare logopedica </mat-list-option> -->
          <mat-list-option role="listitem" (click)="openFisaEvaluareInfsParintiComponent()" >Fisă evaluare INFS părinți </mat-list-option>
          <mat-list-option role="listitem" (click)="openEvaluareMersComponent()" >Evaluare mers </mat-list-option>
          <mat-list-option role="listitem" (click)="openEvaluareIndependentaMnfComponent()" >Evaluare independenta mnf</mat-list-option>
          <mat-list-option role="listitem" (click)="openEvaluareMariComponent()" >Evaluare Mari</mat-list-option>
      </mat-selection-list>

      <!-- <mat-form-field>
          <mat-label>First name</mat-label>
          <input matInput>
      </mat-form-field>

      <mat-form-field>
          <mat-label>Age</mat-label>
          <input matInput type="number" min="1">
      </mat-form-field> -->

      <!-- #docregion action-bar -->
      <!-- <mat-action-row>
          <button mat-button color="primary" (click)="nextStep()">Next</button>
      </mat-action-row> -->
      <!-- #enddocregion action-bar -->

  </mat-expansion-panel>


  <mat-expansion-panel [expanded]='false' hideToggle>
      <mat-expansion-panel-header>
          <mat-panel-title>
            Chestionare
          </mat-panel-title>
          <mat-panel-description>
            Diferite chestionare
          </mat-panel-description>
      </mat-expansion-panel-header>


      <mat-selection-list>
          <mat-list-option role="listitem" (click)="openChestionarDeAlergiiComponent()" >Chestionar de alergii</mat-list-option>
      </mat-selection-list>
  </mat-expansion-panel>
  </mat-accordion>
  <br>

  <b>INVESTIGATII PARACLINICE RELEVANTE: </b>
<br>
<br>
<mat-form-field appearance="fill" style="width:100%">
    <textarea matInput [(ngModel)]="investigatii" ></textarea>
  </mat-form-field>

  <br>

  <b>RECOMANDARI: </b>
<br>
<br>
<mat-form-field appearance="fill" style="width:100%">
    <textarea matInput [(ngModel)]="recomandari" ></textarea>
  </mat-form-field>

  <b>TRATAMENT: </b>
<br>
<br>
<mat-form-field appearance="fill" style="width:100%">
    <textarea matInput [(ngModel)]="tratament" ></textarea>
  </mat-form-field>

  <table *ngIf="fisiere.length > 0" mat-table [dataSource]="dataSource" style="width:100%">

    <!-- ID Column -->
    <ng-container matColumnDef="nume">
    <th mat-header-cell *matHeaderCellDef> Nume </th>
    <td mat-cell *matCellDef="let element"> {{element.nume}} </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="descarca">
    <th mat-header-cell *matHeaderCellDef> Descarca </th>
    <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary"> Download </button> </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef> Edit</th>
      <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary" (click)="openEvaluareaFunctieiMotoriiGrosiereComponent()"> Editeaza </button> </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>

  </table>
  <div mat-dialog-actions align='center'>
    <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="sendvalues()" cdkFocusInitial>Ok</button>

</div>
