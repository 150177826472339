import { Component, ChangeDetectionStrategy, ViewChild,ViewEncapsulation, OnInit, ElementRef, SimpleChanges } from '@angular/core';
import { Pacient, DateContact, DatePersonale, Adresa, programareRecurenta } from '../models/pacientdetails.model';
import { PacientiService } from '../services/pacienti.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { PacientCreateInterface, PacientInterface } from '../interfaces/pacient.interface';
import { ProgramariService } from '../services/programari.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PacientdetailsComponent } from '../pacientdetails/pacientdetails.component';
import { specialitateMedici, Servicii, Departament, Medici, MediciDetaliiPeScurt, ServiciiPeScurt } from 'src/app/models/nomenclatoare.model';
import { MediciDetaliiPeScurtInterface, ProgramareCreateInterface, ServiciiInterface, ServiciiiPeScurtInterface } from '../interfaces/nomenclatoare.interface';
import { ProgramaredialogComponent } from '../programaredialog/programaredialog.component';
import { extend, isNullOrUndefined } from '@syncfusion/ej2-base';
import { MediciService } from 'src/app/services/medici.service';
import { Query } from '@syncfusion/ej2-data';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { ServiciiService } from 'src/app/services/servicii.service';
import { DatePipe } from '@angular/common';
import { ChangeEventArgs } from '@syncfusion/ej2-calendars';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Y } from '@angular/cdk/keycodes';
import { MatGridTile } from '@angular/material/grid-list';
import { L10n } from '@syncfusion/ej2-base'
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import {
  RecurrenceEditor, AgendaService, PopupOpenEventArgs, ScheduleComponent, EventSettingsModel, View, WorkHoursModel, DayService, DragAndDropService,
  WeekService, WorkWeekService, MonthService, EventRenderedArgs, TimelineViewsService, TimelineMonthService, ResizeService, ActionEventArgs, TimeScaleModel
} from '@syncfusion/ej2-angular-schedule';
import { RowSelectEventArgs, QueryCellInfoEventArgs, GridComponent, SortService, FilterService, PageService,ToolbarService, EditService} from '@syncfusion/ej2-angular-grids';
import {  ReorderService } from '@syncfusion/ej2-angular-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { loadCldr } from '@syncfusion/ej2-base';
import { RecurrenceEditorChangeEventArgs } from '@syncfusion/ej2-angular-schedule';
import { Internationalization } from '@syncfusion/ej2-base';
declare var require: any;

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/ro/ca-gregorian.json'),
  require('cldr-data/main/ro/numbers.json'),
  require('cldr-data/main/ro/timeZoneNames.json'));

L10n.load({
  'ro': {
      'schedule': {
          'day': 'Zi',
          'week': 'Saptamana',
          'workWeek': 'Saptamana lucratoare',
          'month': 'Luna',
          'today': 'Ziua curenta',
          'agenda': 'Agenda',
          'monthAgenda': 'Agenda Lunara',
          'repeat': 'Repeta',
          "recurrence": "Recurenta",
          "year" : "An"
      },
      "recurrenceeditor": {
        "none": "Nimic",
        "daily": "Zilnic",
        "weekly": "Saptamanal",
        "monthly": "Lunar",
        "month": "Luna",
        "yearly": "Anual",
        "never": "Niciodata",
        "until": "Pana la",
        "count": "Repeta de N ori",
        "first": "Primul",
        "second": "Al doilea",
        "third": "Al treilea",
        "fourth": "Al patrulea",
        "last": "Ultimul",
        "repeat": "Repeta",
        "repeatEvery": "Frecventa",
        "on": "Alege zilele",
        "end": "Sfarsit",
        "onDay": "Zi",
        "days": "Zile",
        "weeks": "Saptamani",
        "months": "Luni",
        "years": "Ani",
        "every": "La fiecare..",
        "summaryTimes": "ori",
        "summaryOn": "on",
        "summaryUntil": "pana cand",
        "summaryRepeat": "Recurenta",
        "summaryDay": "Zile",
        "summaryWeek": "Saptamani",
        "summaryMonth": "Luni",
        "summaryYear": "Ani",
        "repeatInterval": "Repeta Intervalul"
    }
  }
});

// To localize the scheduler words, import L10n from ej2-base package.
L10n.load({
  'ro': {
      'schedule': {
          'saveButton': 'Salveaza',
          'cancelButton': 'Inchide',
          'deleteButton': 'Sterge',
          'newEvent': 'Detalii Programare',

      },
  }
});






@Component({
  selector: 'app-terapiilemele',
  providers: [DayService, WeekService, WorkWeekService, TimelineViewsService, MonthService, AgendaService, FilterService, PageService,ToolbarService, EditService, SortService,ReorderService,ResizeService],
  templateUrl: './terapiilemele.component.html',
  styleUrls: ['./terapiilemele.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TerapiilemeleComponent implements OnInit {
  @ViewChild('grid') public Grid: GridComponent;
  @ViewChild('scheduleObj') public scheduleObj: ScheduleComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('tileterapeut') tileterapeut: MatGridTile;
  @ViewChild('tileservicii') tileservicii: MatGridTile;
  pacientList: Pacient[] = [];
  pacientdataSource = new MatTableDataSource();
  displayedColumns: string[] = ['id', 'nume', 'prenume', 'CNP'];
  selectedPacientId: number;
  selectedPacientDetails: Pacient
  varsta_cronologica: number
  terapeut_select_width: string = "100px"
  myControl = new FormControl('');
  filteredOptions: Observable<string[]>;
  filterTerapeuti = {
    nume: null,
    prenume: null
  }
  terapeutSelectat: Medici
  terapeutiFiltrati: Medici [] = []

  options: any[] = [];

  terapeutiList: any[] = []

  serviciuSelectat: ServiciiInterface
  serviciiList: ServiciiInterface[] = []
  serviciiFiltrate: ServiciiInterface[] = []
  searched_denumire_serviciu: string = null
  serviciiFormControl = new FormControl('')
  // flags
  programari_terapeut: any[] = []

  openCalendarFlag: boolean = false
  flag_pacient_selectat: boolean = false
  flag_width_terapeut_select_setat: boolean = false
  flag_content_init: boolean = false
  // teste filtrare
  idFilter = new FormControl('');
  numeFilter = new FormControl('');
  prenumeFilter = new FormControl('');
  cnpFilter = new FormControl('');
  filterValues = {
    date_personale: {
      nume:null,
      prenume:null,
      cnp:null
    },
    id:null,
  };
  dataSource: any
  PacientListtwo: Pacient[]
  detaliiMedicPeScurt : any[] = []
  serviciiPeScurt : any[] = []
  public instance: Internationalization = new Internationalization();
  //initiates the country DropDownList
  public cssClass: string = 'schedule-more-indicator';
  public timeScale: TimeScaleModel = { enable: true, interval: 60, slotCount: 6 };
  public fieldsServicii: Object = { value: 'id', text: 'denumire' }
  public placeholderServicii : string = "Selecteaza un serviciu"
  public filterPlaceholderServicii: string = 'Cauta';
  public allowFilteringServicii: true
  public onFilteringServicii: EmitType<FilteringEventArgs> =  (e: FilteringEventArgs) => {
    let query : Query = new Query();
    query = (e.text != "") ? query.where("denumire", "startswith", e.text, true) : query;
    e.updateData(this.serviciiPeScurt, query);
    }


  // maps the appropriate column to fields property
  public fields: Object = { text: 'nume', value: 'id', servicii_id: 'servicii_id'  };
  // set the height of the popup element
  public height: string = '220px';
  // set the placeholder to DropDownList input element
  public watermark: string = 'Selecteaza un terapeut';
  // set the placeholder to filter search box input element
  public filterPlaceholder: string = 'Cauta';
  // filtering event handler to filter a Country
  public onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
      let query: Query = new Query();
      //frame the query based on search string with filter type.
      query = (e.text !== '') ? query.where('nume', 'startswith', e.text, true) : query;
      //pass the filter data source, filter query to updateData method.
      e.updateData(this.detaliiMedicPeScurt, query);
  }



  public scheduleData : Record<string, any>[] = [
    {
      Id: 1,
      Subject: 'Surgery - Andrew',
      EventType: 'Confirmed',
      TerapeutSelectat : 6,
      StartTime: new Date(2021, 1, 15, 9, 0),
      EndTime: new Date(2021, 1, 15, 10, 0)
    }, {
      Id: 2,
      Subject: 'Consulting - John',
      EventType: 'Confirmed',
      TerapeutSelectat : 11,
      StartTime: new Date(2021, 1, 15, 10, 0),
      EndTime: new Date(2021, 1, 15, 11, 30)
    }, {
      Id: 3,
      Subject: 'Therapy - Robert',
      EventType: 'Requested',
      TerapeutSelectat : 11,
      StartTime: new Date(2021, 1, 15, 11, 30),
      EndTime: new Date(2021, 1, 15, 12, 30)
    }, {
      Id: 4,
      Subject: 'Observation - Steven',
      EventType: 'Confirmed',
      TerapeutSelectat : 6,
      StartTime: new Date(2021, 1, 15, 12, 30),
      EndTime: new Date(2021, 1, 15, 13, 30)
    }, {
      Id: 5,
      Subject: 'Extraction - Nancy',
      EventType: 'Confirmed',
      TerapeutSelectat : 6,
      StartTime: new Date(2021, 1, 15, 13, 30),
      EndTime: new Date(2021, 1, 15, 15, 0)
    }, {
      Id: 6,
      Subject: 'Surgery - Paul',
      EventType: 'New',
      TerapeutSelectat : 11,
      StartTime: new Date(2021, 1, 16, 9, 0),
      EndTime: new Date(2021, 1, 16, 10, 0)
    }, {
      Id: 7,
      Subject: 'Extraction - Josephs',
      EventType: 'Confirmed',
      TerapeutSelectat : 6,
      StartTime: new Date(2021, 1, 16, 10, 0),
      EndTime: new Date(2021, 1, 16, 11, 0)
    }, {
      Id: 8,
      Subject: 'Consulting - Mario',
      EventType: 'Confirmed',
      TerapeutSelectat : 11,
      StartTime: new Date(2021, 1, 16, 11, 0),
      EndTime: new Date(2021, 1, 16, 12, 0)
    }, {
      Id: 9,
      Subject: 'Therapy - Saveley',
      EventType: 'Requested',
      TerapeutSelectat : 6,
      StartTime: new Date(2021, 1, 16, 12, 0),
      EndTime: new Date(2021, 1, 16, 13, 30)
    }, {
      Id: 10,
      Subject: 'Observation - Cartrain',
      EventType: 'Confirmed',
      TerapeutSelectat : 6,
      StartTime: new Date(2021, 1, 16, 13, 30),
      EndTime: new Date(2021, 1, 16, 15, 30)
    }
  ];
  public serviciiObj: DropDownList
  public pageSettings: object;
  public startDate: Date;
  public endDate: Date;
  public workHours: WorkHoursModel = { start: '08:00', end: '20:00' };
  public startHour = '10:00';
  public endHour = '20:00';
  public selectedDate: Date = new Date();
  public showWeekend: boolean = false;
  public eventSettings: EventSettingsModel = { dataSource: extend([], this.scheduleData, null, true) as Record<string, any>[] };
  public showQuickInfo = false;
  public statusData: string[] = ['New', 'Requested', 'Confirmed'];
  public eventObject: EventSettingsModel = {
    dataSource: [{
      titlu: "Testing",
      ora_start: new Date(),
      ora_end:  new Date(),
    }],
    fields: {
      subject: { name: 'titlu'},
      startTime: { name: 'ora_start'},
      endTime: { name: 'ora_end'},
    }
  }
  constructor(
    public datePipe: DatePipe,
    public dialog: MatDialog,
    public pacientiService: PacientiService,
    public mediciService: MediciService,
    public serviciiService: ServiciiService,
    public programariService: ProgramariService

  ) {
    this.pacientiService.getPacientifromServer()
    .subscribe( (data) => {
      if (data){
        for (let i of data){
          this.pacientList.push(i)
        }
        this.PacientListtwo = this.pacientiService.orderPacientsListById(this.pacientList)
        this.load()
        this.pageSettings = { pageSizes: true, pageSize: 5 };
        this.pacientdataSource.data  = this.pacientList
        this.pacientdataSource.filterPredicate = this.createFilter();
        this.pacientdataSource.paginator = this.paginator;
      }
    })

    this.serviciiService.getServiciiFromServer()
    .subscribe ( (data) => {
      if (data){
        for ( let i of data){
          this.serviciiList.push(i)
          if(i.denumire != null){
            this.serviciiFiltrate.push(i)
          }
        }
        this.formatServiciiPeScurt()
      }
    })
    this.mediciService.getMediciFromServer()
    .subscribe ( (data) => {
      if (data){
        for ( let i of data){
          this.terapeutiList.push(i)
          if(i.nume != null){
            if(i.prenume != null){
              this.terapeutiFiltrati.push(i)
            }
          }
        }
        this.formatDetaliiMedicPeScurt()
      }
    })

  }
  onChangeRecurrence(args: RecurrenceEditorChangeEventArgs): void {
    // if (!isNullOrUndefined(args.value)) {
    //     let outputElement: HTMLElement = <HTMLElement>document.querySelector('#rule-output');
    //     if(args.value == "") {
    //         outputElement.innerText = 'Select Rule';
    //     } else {
    //         outputElement.innerText = args.value;
    //     }
    // }
      }
  onPopupOpen(args: PopupOpenEventArgs): void {
    // if (args.type == 'Editor') {
    //     (<any>this.scheduleObj.eventWindow).recurrenceEditor.frequencies = ['daily', 'weekly'];
    // }
    console.log("S-A DESCHIS MODALUL")
  }

  getMajorTime(date: Date): string {
    return this.instance.formatDate(date, { skeleton: 'Hm' });
}
getMinorTime(date: Date): string {
    return this.instance.formatDate(date, { skeleton: 'ms' }).replace(':00', '');
}

  formatServiciiPeScurt(){
    for (let i of this.serviciiList){
      let q : ServiciiiPeScurtInterface = new ServiciiPeScurt()
      if (i.id != undefined){
        q.id = i.id
      }
      if ( i.denumire != undefined){
        q.denumire = i.denumire
      }
      this.serviciiPeScurt.push(q)
    }
  }

  formatDetaliiMedicPeScurt (){
    for ( let i of this.terapeutiList ){
      let q : MediciDetaliiPeScurtInterface = new MediciDetaliiPeScurt()
      if (i.id != undefined){
        q.id = i.id
      }
      if(i.servicii_id != undefined){
        q.servicii_id = i.servicii_id
      }
      if(i.nume != undefined ){
        q.nume = i.nume
      }
      if(i.prenume != undefined){
        if (q.nume.length > 0){
          q.nume = q.nume+" "+i.prenume
        }
        else{
          q.nume = i.prenume
        }
      }
      this.detaliiMedicPeScurt.push(q)
    }
    // console.log("DETALII MEDICI PE SCURT == >> ", this.detaliiMedicPeScurt)
  }

  public startDateParser(data: string) {
    if (isNullOrUndefined(this.startDate) && !isNullOrUndefined(data)) {
      return new Date(data);
    } else if (!isNullOrUndefined(this.startDate)) {
      return new Date(this.startDate);
    }
    return new Date()
  }
  public endDateParser(data: string) {
    if (!isNullOrUndefined(data)){
      // console.log("data din END DATE PARSER NOT NULL == >> ", data)
      let intermediar:Date = new Date(data)
      intermediar.setMinutes(intermediar.getMinutes() + this.getDurataServiciu(this.serviciuSelectat))
      return intermediar
    }
    return null
  }
  public onDateChange(args: ChangeEventArgs): void {
    if (!isNullOrUndefined(args.event)) {
      if (args.element.id === "StartTime") {
        this.startDate = args.value;
      } else if (args.element.id === "EndTime") {
        this.endDate = args.value;
      }
    }
  }
  public onPopupClose() {
    this.startDate = null;
    this.endDate = null;
  }

  public onEventRendered(args: EventRenderedArgs): void {
    switch (args.data.EventType) {
      case 'Requested':
        (args.element as HTMLElement).style.backgroundColor = '#F57F17';
        break;
      case 'Confirmed':
        (args.element as HTMLElement).style.backgroundColor = '#7fa900';
        break;
      case 'New':
        (args.element as HTMLElement).style.backgroundColor = '#8e24aa';
        break;
    }
  }

  public onActionBegin(args: ActionEventArgs): void {
    if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
      const data: Record<string, any> = args.data instanceof Array ? args.data[0] : args.data;
      if (!this.scheduleObj.isSlotAvailable(data.StartTime as Date, data.EndTime as Date)) {
        args.cancel = true;
      }
    }
  }

  ngOnInit(): void {


    this.numeFilter.valueChanges
    .subscribe(
      nume => {
        this.filterValues.date_personale.nume = nume;
        this.pacientdataSource.filter = JSON.stringify(this.filterValues);
      }
    )
    this.idFilter.valueChanges
    .subscribe(
      id => {
        this.filterValues.id = id
        this.pacientdataSource.filter = JSON.stringify(this.filterValues)
      }
    )
    this.prenumeFilter.valueChanges
    .subscribe(
      prenume => {
        this.filterValues.date_personale.prenume = prenume
        this.pacientdataSource.filter = JSON.stringify(this.filterValues)
      }
    )
    this.cnpFilter.valueChanges
    .subscribe(
      cnp => {
        this.filterValues.date_personale.cnp = cnp
        this.pacientdataSource.filter = JSON.stringify(this.filterValues)
      }
    )

      this.serviciiFormControl.valueChanges
      .subscribe(
        serviciudenumire => {
          this.searched_denumire_serviciu = serviciudenumire
          if ( this.searched_denumire_serviciu != null ){
            for ( let n = 0; n < this.serviciiFiltrate.length; n = n+1){
              if (this.serviciiFiltrate[n].denumire.toString().toLowerCase().indexOf(this.searched_denumire_serviciu) == -1 ){
                this.serviciiFiltrate.splice(n,1)
                n = -1
              }
            }
            for ( let r of this.serviciiList ){
              if ( r.denumire != null){
                if (this.serviciiFiltrate.indexOf(r) == -1){
                  if (r.denumire.toString().toLowerCase().indexOf(this.searched_denumire_serviciu) !== -1 ){
                    this.serviciiFiltrate.push(r)
                  }
                }
              }
            }
          }
          else{
            this.serviciiFiltrate = []
            for ( let r of this.serviciiList ){
              if ( r.denumire != null){
                if (this.serviciiFiltrate.indexOf(r) == -1){
                    this.serviciiFiltrate.push(r)
                }
              }
            }

          }
      }
    )
    this.myControl.valueChanges
    .subscribe(
      terapeutnume =>{
        this.filterTerapeuti.nume = terapeutnume
        if(this.filterTerapeuti.nume == ''){
          this.terapeutiFiltrati = []
          for ( let z of this.terapeutiList ){
            if (z.nume != null){
              if (z.prenume != null){
                this.terapeutiFiltrati.push(z)
              }
            }
          }
        }
        else{
          this.terapeutiFiltrati.find( t => {
            if (this.filterTerapeuti.nume != null){
              if(this.filterTerapeuti.nume != undefined){

              }

              for ( let p of this.terapeutiFiltrati ){
                if ( p.nume.toString().toLowerCase().concat(' ',p.prenume.toString().toLowerCase()).indexOf(this.filterTerapeuti.nume) == -1 ){
                  this.terapeutiFiltrati.splice(this.terapeutiFiltrati.indexOf(p),1)
                }
                else{
                }
              }
              for (let s of this.terapeutiList){
                if (this.terapeutiFiltrati.indexOf(s) == -1){
                  if (s.nume != null){
                    if (s.nume.toString().toLowerCase().concat(' ',s.nume.toString().toLowerCase()).indexOf(this.filterTerapeuti.nume) != -1){
                      this.terapeutiFiltrati.push(s)
                    }
                  }
                }
              }
            }
            else {
              // console.log("Input-ul din nou este gol !! ")
            }
          })
        }
      }
    )
    // if (!this.flag_width_terapeut_select_setat){
      setTimeout(() => {
        this.checkTerapeutSelectTile()
      });
    // }
  }
  ngAfterViewChecked() {
    setTimeout(() => {
      if (!this.flag_width_terapeut_select_setat){
        this.checkTerapeutSelectTile()
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  ngAfterContentInit(){
    this.flag_content_init = true
    // while (!this.flag_width_terapeut_select_setat){
      this.checkTerapeutSelectTile()
    // }
  }
  private _filter(value: string): string[] {
    console.log(" Value din filter ==>> ", value)
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  createFilter(): (data: PacientInterface[], filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      console.log(" Search Tearms din Create filter ==>> ",searchTerms)
      console.log(" Search Tearms de 0 ==>> ",searchTerms[0])
      console.log(" Search Tearms de id ==>> ",searchTerms.id)
      // console.log(" rezultatul testului data.d_p.nume contine searchTerms.nume ")

      var flag_numeFilter: boolean = false
      var flag_idFilter: boolean = false
      var flag_prenumeFilter: boolean = false
      var flag_cnpFilter: boolean = false

      if (data.id != null){
        if (searchTerms.id == null){
          flag_idFilter = true
        }
        else{

          if(data.id.toString().toLowerCase().indexOf(searchTerms.id.toString().toLowerCase()) !== -1){
            flag_idFilter = true
          }
        }
    }
      if (data.date_personale.nume != null){
        if (searchTerms.date_personale.nume == null){
          flag_numeFilter = true
        }
        else{
          if(data.date_personale.nume.toLowerCase().indexOf(searchTerms.date_personale.nume.toLowerCase()) !== -1){
            flag_numeFilter = true
          }
        }
      }
      if (searchTerms.date_personale.prenume == null){
        flag_prenumeFilter = true
      }
      else{
        if (data.date_personale.prenume != null){

          if(data.date_personale.prenume.toLowerCase().indexOf(searchTerms.date_personale.prenume.toLowerCase()) !== -1){
            flag_prenumeFilter = true
          }
        }
      }
      if (searchTerms.date_personale.cnp == null){
        flag_cnpFilter = true
      }
      else{
        if (data.date_personale.cnp != null){
          if(data.date_personale.cnp.toString().toLowerCase().indexOf(searchTerms.date_personale.cnp.toString().toLowerCase()) !== -1){
            flag_cnpFilter = true
          }
        }
      }
      return flag_cnpFilter && flag_idFilter && flag_numeFilter && flag_prenumeFilter
    }
    return filterFunction;
  }

  checkTerapeutSelectTile(){
    if (!this.flag_width_terapeut_select_setat ){
      if(this.tileterapeut != undefined){
        let width_tile = this.tileterapeut["_element"].nativeElement.offsetWidth
        if (width_tile > Number(this.terapeut_select_width.substring(0,this.terapeut_select_width.indexOf("px")))){
          width_tile = width_tile * 0.8
          this.terapeut_select_width = width_tile.toString()+"px"
          this.flag_width_terapeut_select_setat = true
        }
        else{
        }
      }
    }
  }
  createTerapeutFilter(): ( data: Medici[], filterTerapeut:string) => boolean {
    let filterTerapeutFunction = function (data, filterTerapeut): boolean {
      let  searchedTerapeutTerms = JSON.parse (filterTerapeut)
       var flag_numeTerapeutFilter: boolean = false
       var flag_prenumeTerapeutFilter: boolean = false
       if (data.nume != null){
        if (searchedTerapeutTerms == null){
          flag_numeTerapeutFilter = true
        }
        else{
          if (data.nume.toString().toLowerCase().indexOf(searchedTerapeutTerms.nume.toString().toLowerCase()) !== -1){
            flag_numeTerapeutFilter = true
          }
        }
       }
       if (data.prenume != null){
         if (searchedTerapeutTerms.nume == null){
           flag_prenumeTerapeutFilter = true
         }
         else{
           if ( data.prenume.toLowerCase().indexOf(searchedTerapeutTerms.nume.toLowerCase()) !== -1){
             flag_prenumeTerapeutFilter = true
           }
         }
       }
       return flag_numeTerapeutFilter && flag_prenumeTerapeutFilter
    }
    return filterTerapeutFunction
  }
  selectPacient(entire_row) {
    console.log("Pacient SELECTAT == >> ", entire_row)
    this.selectedPacientId = entire_row.id;
    var intermediar_selectedpacient: any[] = []
    this.selectedPacientDetails = new Pacient()
    this.selectedPacientDetails = entire_row
    var intermediar = Math.abs(Date.now() - new Date(this.selectedPacientDetails.date_personale.data_nasterii).getTime());
    this.varsta_cronologica = Math.floor(intermediar / (1000 * 3600 * 24) / 365.25)
    intermediar_selectedpacient.push(entire_row)
    // this.pacientdataSource = new MatTableDataSource(intermediar_selectedpacient);
    // this.pacientdataSource.paginator = this.paginator;
    this.flag_pacient_selectat = true
  }

  resetPacient() {
    this.selectedPacientId = null
    this.selectedPacientDetails = null
    this.varsta_cronologica = null
    this.pacientdataSource = new MatTableDataSource ()
    this.pacientdataSource.data = this.pacientList
    // this.pacientdataSource.paginator = this.paginator;
    this.pacientdataSource.paginator = this.paginator;
    this.flag_pacient_selectat = false
  }

  openPacientDetailsComponent(){
    console.log(this.selectedPacientDetails)
    const dialogRef = this.dialog.open(PacientdetailsComponent, {
      width: '950px',
      height: '850px',
      data: this.selectedPacientDetails.id,
    });
  }
  setServiciuSelectat(serviciu_selectat){
    this.serviciuSelectat = serviciu_selectat
    console.log(" Serviciu selectat din open calendar ==>> ", this.serviciuSelectat)
  }
  workArounFunction(variabila):ServiciiInterface{
    return (variabila)
  }
  setTerapeutSelectat(terapeut_selectat){
    this.terapeutSelectat = terapeut_selectat

    this.serviciuSelectat = this.workArounFunction(this.terapeutSelectat.servicii)
    console.log(" servici medic selectat ==>>  ", this.serviciuSelectat)
    // this.serviciiFormControl.patchValue(this.serviciuSelectat)
    // this.setServiciuSelectat(this.terapeutSelectat.servicii)
    console.log(" Terapeut selectat din open calendar ==>> ", this.terapeutSelectat)
  }

  openCalendar(){
    this.openCalendarFlag = !this.openCalendarFlag
    // this.programariService.getProgramariFromServerByMedicIdInInterval(this.terapeutSelectat.id,)
    // get programari medic

    // this.terapeutSelectat = this.myControl.value
  }
  openProgramareDialogComponent(data_selectata, durata){
    console.log(" open programare component == >> ", data_selectata," === ", durata)
    const dialogRef = this.dialog.open(ProgramaredialogComponent, {
      width: '750px',
      height: '650px',
      // data: {
      //   'data': date,
      //   'durata': durata
      // }
      data: {
        data: data_selectata,
        durata: durata
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        console.log("after closed ", result);
        if (result[1]) {


        }
        else {
          var programare_noua:ProgramareCreateInterface = {
            title: this.selectedPacientDetails.date_personale.nume+" "+this.selectedPacientDetails.date_personale.prenume,
            pacientid: this.selectedPacientDetails.id,
            medicid: this.terapeutSelectat.id,
            servicii_id: this.terapeutSelectat.servicii_id,
            data: this.datePipe.transform(result[0],"dd.MM.YYYY-HH:mm"),
            durata: this.serviciuSelectat.timp
          }
          console.log( " programare_noua ==>> ", programare_noua)
          this.programariService.addProgramareToServer(programare_noua)
          .subscribe( (data) => {
            if (data){
              console.log(" Programare adaugata pe server ==>> ", data)
            }
          })
        }

        // this.addprogramare(result.value)
      }
      });
  }
  programarenoua(event){
    console.log("Event programare noua == >> ", event)
    // console.log(" Programare noua - medic intervale orare ==>> ", this.terapeutSelectat.intervale_zilnice)
    this.openProgramareDialogComponent(event,this.serviciuSelectat.timp)
  }
  rowSelected(args: RowSelectEventArgs) {
    // this.flag_dialog_pacient_open = true
    const selectedrecords: object[] = this.Grid.getSelectedRecords();  // Get the selected records.
    console.log( " SELECTED ROW RECORDS == >> ", selectedrecords)

    let intermediar_beneficiar = selectedrecords[0] as PacientCreateInterface
    // this.getRecord(intermediar_beneficiar)
    this.selectPacient(intermediar_beneficiar)
  }
  getRecord(rowz: any)
  {
    console.log("Pacient selectat: =>", rowz);
    const dialogRef = this.dialog.open(PacientdetailsComponent, {
      // width: this.screnwidthstring,
      // maxWidth:this.dialogMaxWidth,
      width: "95%",
      height: "95%",
      data: rowz.id,

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null){
        console.log("afterclosed pd comp",result);
        this.pacientiService.getPacientifromServer()
        .subscribe((data) => {
            this.pacientList = data
            this.dataSource  = new MatTableDataSource(this.pacientList);
          })
        console.log("Pacient List => ", this.pacientList);
        this.dataSource = new MatTableDataSource(this.pacientList);
      }
    });
  }


  load() {
    console.log("PACIENT LIST : => ",this.pacientList)
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = 10;   // initial page size
  }
  onChangeTerapeut(args){
    console.log("TERAPEUT A FOST MODIFICAT == >> ", args.value)
    this.terapeutSelectat = args.value
  }
  onChangeServicii(args){
    console.log("SERVICIILE AU FOST MODIFICATE == >> ", args.value)
    this.serviciuSelectat = args.value
  }
  getDurataServiciu(serviciu_id){
    for (let i of this.serviciiList){
      if (i.id == serviciu_id){
        return i.timp
      }
    }
    return 0
  }
}


