<h2>Date Personale</h2>
<mat-form-field>
  <mat-label>Nume</mat-label>
  <input matInput type="text" [(ngModel)]= "nume">
</mat-form-field>

<mat-form-field style="padding-left: 20px;">
<mat-label>Prenume</mat-label>
<input matInput type="text" [(ngModel)]= "prenume">
</mat-form-field>

<mat-form-field style="padding-left: 20px;">
<mat-label>Telefon</mat-label>
<input matInput type="text" [(ngModel)]= "telefon">
</mat-form-field>

<mat-form-field style="padding-left: 20px;">
<mat-label>Email</mat-label>
<input matInput type="text" [(ngModel)]= "email">
</mat-form-field>

<h2>Schimba parola</h2>

<mat-form-field style="padding-left: 20px;">
<mat-label>Parola Actuala</mat-label>
<input matInput type="password" [(ngModel)]= "parola">
</mat-form-field>

<mat-form-field style="padding-left: 20px;">
<mat-label>Parola Noua</mat-label>
<input matInput type="password" [(ngModel)]= "parolanoua">
</mat-form-field>

<mat-form-field style="padding-left: 20px;">
<mat-label>Repeta parola noua</mat-label>
<input matInput type="password" [(ngModel)]= "parolanouarepeat">
</mat-form-field>
<button mat-raised-button color="primary" >Reseteaza parola</button>



<div mat-dialog-actions align='center'>
  <button mat-raised-button color="warn" mat-dialog-close >Inchide</button>
  <button mat-raised-button color="primary" mat-dialog-close >Salveaza</button>
</div>
