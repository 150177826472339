import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of  } from 'rxjs';
import {catchError} from 'rxjs/operators/catchError';
import { DatePersonaleCreateInterface } from '../interfaces/pacient.interface';
import { serverurl } from './server.service';

const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
    providedIn: 'root'
})

export class DatePersonaleService{
  private server_url=serverurl+"/api/v1/datepersonale/"

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient
  ){}

  addDatePersonaleToServer(datepersonalenou: DatePersonaleCreateInterface){
      console.log("Add Date Personale to Server")
      return (this.http.post<any>(this.server_url, datepersonalenou, this.httpOptions))
  }
  updateDatePersonaleToServer(datepersonale: DatePersonaleCreateInterface, id ){
    return (this.http.put<any>(this.server_url+id, datepersonale, this.httpOptions))
}

}
