import { Component, ChangeDetectionStrategy, ViewChild, OnInit, ElementRef, Inject } from '@angular/core';
import { ServiciiService } from 'src/app/services/servicii.service';
import { Pacient, DateContact, DatePersonale, Adresa, programareRecurenta } from '../models/pacientdetails.model';
import { PacientiService } from '../services/pacienti.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { ServiciiInterface, CasierieDateDinModalAdaugaServiciuInterface, CasieriiServiciiInterface, TipplataInterface } from '../interfaces/nomenclatoare.interface';
import { ProgramariService } from '../services/programari.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CasierieserviciiComponent } from '../casierieservicii/casierieservicii.component';
import { CasierieDateDinModalAdaugaServiciu, CasieriiServicii } from '../models/nomenclatoare.model';
import { CasierieService } from '../services/casierie.service';
import { MediciService } from '../services/medici.service';
import { IncasaricreateInterface } from '../interfaces/nomenclatoare.interface';
import { IncasariService } from '../services/incasarecasierie.service'
import { Incasaricreate } from '../models/nomenclatoare.model';
import { TipPlataService } from '../services/tipplata.service';
import { IncasarePartialaCompletaService } from '../services/incasarepartialacompleta.service';
@Component({
  selector: 'app-incasarecasierie',
  templateUrl: './incasarecasierie.component.html',
  styleUrls: ['./incasarecasierie.component.css']
})
export class IncasarecasierieComponent implements OnInit {
  serviciiidlist: any[] =[]
  tip_incasareid: number
  serviciiincasate: any[] = []
  serviciidetailslist: any[] =[]
  serviciidenumirilist: any[] = []
  serviciilistfromserver: any[] = []
  mediclist: any
  sumaincasata: number

  total_de_plata: number = 0

  plata_partiala: number = 0
  tipplatalist: TipplataInterface[] = []
  tipplataid: number = -1
  intermediar_incasare: IncasaricreateInterface = new Incasaricreate()
  displayedColumnstwo: string[] = ['data','ora', 'terapeut', 'denumire', 'pret', 'discount', 'total', "platit", "sold"];
  serviciidatasource: any
  denumiri_servicii_medicale: any []

  // flags
  tipplataselectedflag: boolean = false
  incasareintegralaflag: boolean = true
  confirmareplatapartialaflag: boolean = false
  confirmareplataintegralaflag: boolean = false
  confirmareplataflag: boolean = false


  constructor(
    private datePipe: DatePipe,
    public programariService: ProgramariService,
    public casierieService: CasierieService,
    public pacientiService: PacientiService,
    public serviciiService: ServiciiService,
    public mediciService: MediciService,
    public dialog: MatDialog,
    public incasareService: IncasariService,
    public tipplataService: TipPlataService,
    public incasarecompletapartialaService: IncasarePartialaCompletaService,
    @Inject(MAT_DIALOG_DATA) private data:any
  ) {
    // this.serviciiService.getServiciiFromServer()
    // .subscribe ( (data) => {
    //   if (data){
    //     console.log("Data din get servicii from server -> incasare casierie ==>> ", data)
    //     for (var q of data){
    //       console.log("Q of data ==>> ", q)
    //       this.serviciilistfromserver.push(q)
    //       for (var j in this.serviciiidlist){
    //         if ( q.id == this.serviciiidlist[j]){
    //           if (this.serviciidenumirilist.find( x => x.id == q.id)){
    //             console.log(" Deja se afla id-ul in denumirei ==>> ", q.id, " servicii denumiri list ==>> ", this.serviciidenumirilist)
    //           }
    //           this.serviciidenumirilist.push({
    //             'id':q.id,
    //             'denumire': q.denumire
    //           })
    //         }
    //       }
    //     }
    //     for (var i of this.serviciilistfromserver){
    //       console.log(" Match i == ", i, " i.id == ", i.id)
    //       for (var j in this.serviciiidlist){
    //         console.log(" j == ", j, " this.serviciiidlist [j] == ", this.serviciiidlist[j])
    //         if (i.id == this.serviciiidlist[j]){
    //           console.log(" Denumire == ", i.denumire)
    //         }
    //       }
    //     }
    //   }
    // } )

    if(data){
      this.serviciiidlist = data.serviciilist;
      this.serviciidetailslist = data.serviciidetails
      this.denumiri_servicii_medicale = data.denumiri_servicii_medicale
      this.serviciidatasource = new MatTableDataSource<any>(this.serviciidetailslist)

      console.log("Servicii Medicale list ==>> ",this.serviciiidlist)
      console.log("Servicii Details list ==>> ", this.serviciidetailslist)
      console.log("Denumire servicii medicale list ==>> ", this.denumiri_servicii_medicale)

      // Calculeaza totalul
      for (var i in this.serviciidetailslist){
        this.total_de_plata = this.total_de_plata + this.serviciidetailslist[i].total
        console.log("Total de plata din constructor ==>> ", this.total_de_plata)

          // Poate ma inspir mai incolo
          // this.intermediar_incasare.achitatflag = true
          // this.intermediar_incasare.casierieserviciuid = this.serviciidetailslist[i].id
          // this.intermediar_incasare.plata_integrala = false
          // this.intermediar_incasare.plata_partiala = true
          // this.intermediar_incasare.platit = this.serviciidetailslist[i].total
          // this.intermediar_incasare.dataefectuarii = this.datePipe.transform(new Date(),'y-MM-dd-HH:mm')

          // console.log("intermediar din constructor ==>> ", this.intermediar_incasare)
      }
    }

    this.mediciService.getMediciFromServer()
    .subscribe ( (data) => {
      if (data){
        console.log("Data from Incasare Servicii -> get medici from server ==>> ", data)
        this.mediclist = data
      }
    })
    this.tipplataService.getTipplatafromserver()
    .subscribe( (data) => {
      if (data){
        console.log("Data from tip plata from server ==>> ", data)
        this.tipplatalist.push(data)
      }
    })
    // this.total_de_plata = this.calculeazatotal()
  }

  // getdenumiriservicii(list_ids: any[]){
  //   var intermediar_denumiri_servicii: any[] = []
  //   for (var i in list_ids){
  //     for (var j of this.serviciidetailslist){
  //       console.log(" i == ", i, " list_ids [ i ] == ", list_ids[i])
  //       console.log(" j == ", j, " this.serviciilistfromserver [ j ] ")
  //     }
  //   }
  // }
  resetplata(){
    this.tipplataselectedflag = false
  }
  getplatit (id:number){
    this.incasareService.getPlatifromServer(id)
    .subscribe ( (data) => {
      if (data){
        console.log("Data from get plati ==>> ", data)
      }
    })
  }

  // returndenumireserviciu(serviciuid){
  //   for (var i of this.serviciilistfromserver)
  //   {
  //     if ( i.id == serviciuid){
  //       console.log("Id = serviciuid ==>> ", i.id, " i.denumire ==> ", i.denumire)
  //       if (this.serviciidenumirilist.findIndex(x => x.id === i.id) < 0) {
  //         console.log("findIndex de ==>> ", this.serviciidenumirilist.findIndex(x => x.id === i.id), " i.id == ", i.id," servicii denumiri list ==>> ", this.serviciidenumirilist)
  //         return (i.denumire)
  //         this.serviciidenumirilist.push({
  //           'id':i.id,
  //           'denumire': i.denumire
  //         })
  //       }
  //       // return (i.denumire)
  //     }
  //     // console.log("i = ===>>> ", i)
  //   }
  //   console.log(" Denumire servicii list la final ==>> ", this.serviciidenumirilist)
  //   return ("")
  // }

  returndenumiremedici(terapeutid){
    for (var i in this.mediclist)
    {
      if ( this.mediclist[i].id == terapeutid){
        return (this.mediclist[i].nume + " " +this.mediclist[i].prenume)
      }
      // console.log("i = ===>>> ", i)
    }
    return ("")
  }

  settipplata(id){
    this.tipplataselectedflag = true
    this.tip_incasareid = id
    console.log("tip_incasareid din set plata ==>> ", this.tip_incasareid)
  }

  parsedata(value: any): Date | null {
    if ((typeof value === 'string') && (value.includes('-')) && (value.includes(':'))) {
      const str = value.split('-');
      const tims = str[3].split(':')
      const year = Number(str[0]);
      const month = Number(str[1]) - 1;
      const date = Number(str[2]);

      const hour = Number (tims[0])
      const minute = Number (tims[1])

      console.log(" Date din Parse Data --> year = ", year, " month = ", month, " date = ", date, " hour = ", hour, " munite = ", minute," data initiala =", value)
      return new Date(year, month, date);
    } else if((typeof value === 'string') && value === '') {
      return new Date();
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  // platacard(){
  //   this.intermediar_incasare.tip_incasareid = 2
  //   this.tipplataselectedflag = true
  //   console.log("intermediar din platacard ==>> ", this.intermediar_incasare)
  // }

  ngOnInit(): void {
  }
  plataintegrala(){
    // this.incasareintegralaflag = !this.incasareintegralaflag
    console.log("Plata integrala status ==>> ", this.incasareintegralaflag)
  }

  // calculeazatotal(){
  //   for (var i in this.serviciidetailslist){
  //     console.log("i ==>> ", i)
  //     console.log("this.serviciidetailslist[i] ==>> ", this.serviciidetailslist[i])
  //     console.log("this.serviciidetailslist[i].total ==>> ", this.serviciidetailslist[i].total)
  //     console.log("Total de plata pe i==>> ", this.serviciidetailslist[i].total)
  //     this.total_de_plata = this.total_de_plata + this.serviciidetailslist[i].total
  //     console.log("Total de plata ==>> ", this.total_de_plata)
  //   }
  //   return (this.total_de_plata)
  // }

  confirmaplata(){

    // Verificam daca Flag-ul Incasare Integrala este true
    if ( this.incasareintegralaflag ){
      // Cazul 1 -> Incasare Integrala Flag = True -> Incasarea se efectueaza Integral
      console.log (" Incasare Integrala !! ")
      // Daca Flag-ul Incasare Integrala este true, setam Flag-ul Confirmare Plata = true
      this.confirmareplataflag = true
        // Creem o incasare totala partiala si ii folosim id-ul
        this.incasarecompletapartialaService.addincasarepartialacompleta()
        .subscribe( (data) => {
          if (data){
            // Daca s-a adaugat incasarea totala partiala si s-a returnat id-ul ei
            console.log(" =====>>>>>> Data din incasare completa partiala =====>>>> ", data)
            // Iteram prin servicii details list si adaugam cate o plata pentru fiecare serviciu
            // creem un obiect numit plata noua
            for (var i of this.serviciidetailslist){
              console.log (" Incasare Integrala -> i din servicii details list ==> ", i)
              // Creem o variabila Plata Noua de tip-ul Incasari Create
              var plata_noua: IncasaricreateInterface = new Incasaricreate()
              // Adaugam parametrii
              // Fiind plata integrala flag-ul "Achitat" este setat true
              plata_noua.achitatflag = true;
              // Id-ul serviciului de casierie
              plata_noua.casierieserviciuid = i.id;
              // Flag-ul plata_integrala este setat true pentru ca este o plata integrala
              plata_noua.plata_integrala = true;
              // Flag-ul plata_partiala este false true pentru ca este o plata integrala
              plata_noua.plata_partiala = false;
              // Valoarea "Platit" este egala tu totalul pentru ca plata integrala
              plata_noua.platit = i.total;
              // Id-ul platii complete
              plata_noua.plata_partiala_completaid = data.id;
              // Id-ul tipului de incasare
              plata_noua.tip_incasareid = this.tip_incasareid
              // Data efectuarii
              plata_noua.dataefectuarii = this.datePipe.transform(new Date(),'y-MM-dd-HH:mm')
              console.log(" Plata noua din for i ==>> ", plata_noua)
              this.incasareService.addplataincasierie(plata_noua)
              .subscribe( (data) =>{
                if (data){
                  console.log ("Data din add plata noua -->> Plata integrala ==>> ", data)
                }
              })
            }
          }
        })
      }
      else{
        if ( !this.incasareintegralaflag ){
          // Cazul 2 -> Incasare Integrala Flag = Fals -> Incasarea se efectueaza partial
          console.log(" Incasare Partiala !!! ====>>> !!! ")
          var intermediar_suma_de_plata = this.plata_partiala
        }
      }

    if ( this.incasareintegralaflag ) {
      // Cazul 1 -> incasare integrala

      // plata_noua.achitatflag
      // plata_noua.casierieserviciuid
      // plata_noua.dataefectuarii
      // plata_noua.plata_integrala
      // plata_noua.plata_partiala
      // plata_noua.plata_partiala_completaid
      // plata_noua.platit
      // plata_noua.tip_incasareid

    }
    // var plata_noua = new Incasaricreate()
    // if ( this.tipplataselectedflag ){
    //   plata_noua.tip_incasareid = this.tip_incasareid
    // }

  }
  confirmpartial(){
    console.log(" Plata partiala ==>> ", this.plata_partiala)
    this.confirmareplatapartialaflag = true
    this.incasarecompletapartialaService.addincasarepartialacompleta()
    .subscribe( (data) => {
      if (data){
        console.log("Data din add incasare partiala to server ==>> ", data)
        var intermediar_plata_partiala_completaid = data.id
        this.intermediar_incasare.plata_partiala_completaid = data.id
        console.log("intermediar din Confirm partial ==>> ", this.intermediar_incasare)
        var intermediar_plata_partiala = this.plata_partiala
        for (var i in this.serviciidetailslist){
          if (intermediar_plata_partiala > this.serviciidetailslist[i].total){
            this.intermediar_incasare.platit = this.serviciidetailslist[i].total
            this.intermediar_incasare.achitatflag = true
            this.intermediar_incasare.casierieserviciuid = this.serviciidetailslist[i].id
            this.serviciiincasate.push(this.serviciidetailslist[i].id)
            this.intermediar_incasare.plata_integrala = false
            this.intermediar_incasare.plata_partiala = true
            intermediar_plata_partiala = intermediar_plata_partiala - this.serviciidetailslist[i].total
            console.log("intermediar din if partial > total ==>> ", this.intermediar_incasare)
            this.incasareService.addplataincasierie(this.intermediar_incasare)
            .subscribe ( (data) => {
              if (data){
                console.log("Data din Incasare Service ==>> ", data)
              }
            })
            console.log ("plata partiala > serviciu total ==>> ", this.serviciidetailslist[i].total )
          }
        }
        if (intermediar_plata_partiala > 0){
          console.log("Intermediar plata partiala dupa if >0 ==>> ", intermediar_plata_partiala)
          this.intermediar_incasare = new Incasaricreate()
          for (var j in this.serviciidetailslist){
            if (!this.serviciiincasate.includes(this.serviciidetailslist[j].id && intermediar_plata_partiala>0)){
              //nu a fost deja incasat serviciul
              if (intermediar_plata_partiala < this.serviciidetailslist[j].total){
                this.intermediar_incasare.achitatflag = false
                this.intermediar_incasare.casierieserviciuid = this.serviciidetailslist[j].id
                this.intermediar_incasare.dataefectuarii = this.datePipe.transform(new Date(),'y-MM-dd-HH:mm')
                this.intermediar_incasare.plata_integrala = false
                this.intermediar_incasare.plata_partiala = true
                this.intermediar_incasare.plata_partiala_completaid = intermediar_plata_partiala_completaid
                this.intermediar_incasare.platit = intermediar_plata_partiala
                this.intermediar_incasare.tip_incasareid = this.tipplataid
                intermediar_plata_partiala = 0
                this.incasareService.addplataincasierie(this.intermediar_incasare)
                .subscribe ( (data) => {
                  if (data){
                    console.log("Data din incasare service ==>> ", data)
                  }
                })
              }
            }
          }
          this.intermediar_incasare.platit = intermediar_plata_partiala
        }

      }
    })
  }

  confirmintegral(){
    console.log( " Plata intagrala ==>> ", this.total_de_plata)
  }

  schimbaincasare(flag: boolean){

  }
}
