<mat-form-field>
  <input matInput [(ngModel)]="tipdepartament.cod" placeholder="Cod Tip Departament">
</mat-form-field>

<mat-form-field>
  <input matInput [(ngModel)]="tipdepartament.denumire" placeholder="Denumire Tip Departament">
</mat-form-field>
<br>

<mat-checkbox [(ngModel)]="tipdepartament.arevalabilitate" (change)="valabilitatestatuschange(tipdepartament.arevalabilitate)" > Valabilitate: </mat-checkbox>

<mat-form-field >
  <input #startDate matInput [matDatepicker]="picker" (dateInput)="startdateset(startDate.value)" [(ngModel)] ="start" placeholder="De la" [disabled]="!tipdepartament.arevalabilitate">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<mat-form-field >
  <mat-label>Pana la</mat-label>
  <input #endDate matInput [matDatepicker]="picker2" (dateInput)="enddateset(endDate.value)" [(ngModel)] ="end" [disabled]="!tipdepartament.arevalabilitate">
  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
  <mat-datepicker #picker2></mat-datepicker>
</mat-form-field>

<div mat-dialog-actions align='center'>
  <button mat-raised-button color="warn" mat-dialog-close >Inchide</button>
  <button mat-raised-button color="primary" (click)="updatevalues()" mat-dialog-close >Salveaza</button>

</div>
