Semneaza cu degetul mai jos:
<br>

<!-- <mat-divider></mat-divider> -->
<div style="border-style: solid; border-width: thin; width: 600px;height: 300px;">
  <signature-pad
    [options]="signaturePadOptions"
    (onBeginEvent)="drawStart()"
    (onEndEvent)="drawComplete()">
  </signature-pad>
</div>

<button mat-raised-button color='primary' (click)="returnSemnatura()" >Semneaza</button>
