import { InitPacientDetailsInterface } from "../interfaces/initpacientdetails.interface";

export class InitPacientDetails implements InitPacientDetailsInterface{
  constructor(
    public flag_orar_de_somn_status : boolean = false,
    public flag_carte_identitate_check : boolean = false,
    public flag_adauga_primul_interval : boolean = false,
    public flag_adauga_interval_nou : boolean = false,
    public flag_salveaza_interval_nou : boolean = false,
    public flag_interval_in_editare : boolean = false,
    public flag_show_detalii_interval : boolean = false,
    public pacientDetailInitialized : boolean = false
  ){}
}


