import { Component, Inject, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AddconsultComponent } from '../addconsult/addconsult.component';
import { ConsultatiiService } from '../services/consultatii.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ServiciiService } from 'src/app/services/servicii.service';
import { Servicii } from '../models/nomenclatoare.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { TipDiagnostic, Diagnostic } from 'src/app/models/nomenclatoare.model';
import { DiagnosticInterface, ServiciiInterface } from '../interfaces/nomenclatoare.interface';
import { DatePipe } from '@angular/common';
import { RowSelectEventArgs, QueryCellInfoEventArgs, GridComponent, SortService, FilterService, PageService,ToolbarService, EditService} from '@syncfusion/ej2-angular-grids';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import {  ReorderService, ResizeService, CommandColumn,  parentsUntil,  SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DetailRowService, AddEventArgs, GridModel } from '@syncfusion/ej2-angular-grids';
import { ConsultLightInterface } from '../interfaces/consult.interface';

@Component({
  selector: 'app-consult',
  templateUrl: './consult.component.html',
  styleUrls: ['./consult.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ToolbarService, EditService, PageService]
})

export class ConsultComponent implements OnInit {
  @ViewChild('grid') public Grid: GridComponent;

  pacientid: number
  consultatii: any
  displayedColumns: string[] = ['dataconsult','investigatii', 'recomandari', 'diagnostic', 'tratament' ];
  dataSource: any;
  servicii: ServiciiInterface[];
  diagnostice: DiagnosticInterface[] = [];
  pacient: any
  public pageSettings: object;
  public editColumnName:any;
  public vizualizeazaPDFColumnName:any;
  public descarcaPDFColumnName:any;
  public stergeConsultColumnName:any;
  public flag_exista_consultatii: boolean = true
  constructor(
    public datePipe: DatePipe,
    public diagnosticService: DiagnosticService,
    public serviciiService: ServiciiService,
    private _snackBar: MatSnackBar,
    public consultservice: ConsultatiiService,
    public dialogRef: MatDialogRef<ConsultComponent>,
    public dialg: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data:any ) {
    if(data){
      this.pacient = data
      this.pacientid = data.id
      console.log("OPEN CONSULT COMPONENT ==>> PACIENT DATA ==>> ", this.pacient)
    }
   }

  ngOnInit(): void {

    this.getConsultListOfPacient()
    this.pageSettings = { pageSizes: true, pageSize: 5 };
    this.editColumnName = [
      {
        field: 'edit',
        headerText: 'Editeaza',
      }
    ]
    this.vizualizeazaPDFColumnName = [
      {
        field: 'edit',
        headerText: 'Vizualizeaza PDF',
      }
    ]
    this.descarcaPDFColumnName = [
      {
        field: 'edit',
        headerText: 'Descarca PDF',
      }
    ]
    this.stergeConsultColumnName = [
      {
        field: 'edit',
        headerText: 'Sterge',
      }
    ]
    // this.dataSource = new MatTableDataSource(this.consultatii);

  }

  load() {
    console.log("CONSULTATII LIST : => ",this.consultatii)
    const rowHeight: number = this.Grid.getRowHeight();  // height of the each row
    const gridHeight: any = this.Grid.height;  // grid height
    const pageSize: number = 10;   // initial page size
}

getConsultListOfPacient(){
  this.consultservice.getConsultFromServerByPacientID(this.pacientid)
  .subscribe( (data) => {
    if (data){
      console.log( "DATA FROM GET CONSULT BY PACIENT ID ==>> ", data)
      this.consultatii = data
      if(data.length == 0){
        console.log("Data gol")
        if (this.flag_exista_consultatii){
          this.flag_exista_consultatii = false
        }
      }
      else{
        if(!this.flag_exista_consultatii){
          this.flag_exista_consultatii = true
        }
      }
    }
  })
}
addconsult()
{
  // this._snackBar.open('Critical Error - Interface Missing','close');
    const dialogRef =this.dialg.open(AddconsultComponent,{
      width: '750px',
      height: '750px',
      data:this.pacient
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.consultatii = this.consultservice.getConsult();
      if (result){
        this.getConsultListOfPacient()
        this.openSnackBar("Consultatia a fost adaugata cu succes","Inchide")
      }
      // this.dataSource = new MatTableDataSource(this.consultatii);
    })
  }
  rowSelected(args: RowSelectEventArgs) {
    // this.flag_dialog_pacient_open = true
    const selectedrecords: object[] = this.Grid.getSelectedRecords();  // Get the selected records.
    let intermediar_consult = selectedrecords[0] as ConsultLightInterface
  }
  refreshTableDataAfterDelete (deleted_flag : boolean){
    if (deleted_flag){
      // console.log("DELETED FLAG TRUE")
      this.getConsultListOfPacient()
      this.openSnackBar("Consultatia a fost stearsa cu succes","Inchide")
    }
    else {
      // console.log("DELETED FLAG FALSE")
    }
  }
  refreshTableDataAfterEdit (edited_flag : boolean){
    if (edited_flag){
      // console.log("DELETED FLAG TRUE")
      this.getConsultListOfPacient()
      this.openSnackBar("Consultatia a fost editata cu succes","Inchide")
    }
    else {
      // console.log("DELETED FLAG FALSE")
    }
  }
  openSnackBar(message: string, action: string){
    this._snackBar.open(message,action)
  }
}
