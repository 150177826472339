import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CalendarEvent } from 'angular-calendar';
import { ConsultComponent } from '../consult/consult.component';
import { PacientInterface } from '../interfaces/pacient.interface';
import { Pacient } from '../models/pacientdetails.model';
import { PacientiService } from '../services/pacienti.service';
PacientiService
@Component({
  selector: 'app-editprogramare',
  templateUrl: './editprogramare.component.html',
  styleUrls: ['./editprogramare.component.css']
})
export class EditprogramareComponent implements OnInit {
  programare: CalendarEvent
  pacient: PacientInterface
  duratainsecunde: number = 0
  opencountdown: number = 0
  constructor(@Inject(MAT_DIALOG_DATA) private data:any,public dialog: MatDialog,public pacientiService: PacientiService) {
    if(data){
      this.programare = data;
      this.duratainsecunde = (this.programare.durata * 60)
      console.log("Programare  ==>> ",data)
      // console.log("durata in secunde ==>> ",this.programare.durata * 60)
      // console.log("Durata ==> ",this.programare.durata)
      // this.duratainsecunde = new Date(this.duratainsecunde)
      this.pacientiService.getPacientifromServerbyId(this.programare.pacientid).subscribe ( (data) => {
        if (data){
          this.pacient = data
          console.log("Pacient id ==>> ",this.pacient);
        }
      })
      console.log("Programare ==>> ",this.programare);
    }
   }
   openconsult(){
    const dialogRef = this.dialog.open(ConsultComponent,{
      width: '750px',
      height: '650px',
      data: this.programare.pacientid,
    });
   }
   startprocedura(){
     this.opencountdown = 1
   }

  ngOnInit(): void {
  }

}
