import { Adresa, AdresaCreate } from '../models/pacientdetails.model';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of  } from 'rxjs';
import {catchError} from 'rxjs/operators/catchError';
import { AdresaCreateInterface, AdresaInterface, SemnaturaRLCreateInterface } from '../interfaces/pacient.interface';
import { serverurl } from './server.service';
const cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
    providedIn: 'root'
})

export class PDFViewerDialogService{
  // private server_url="http://optimabk.ztools.ro/api/v1/adresa/"
  public viewer_open_from_tabel = 0
  // private server_url=serverurl+"/api/v1/semnaturirl/"

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
      private http: HttpClient
  ){}

  openViewerFromTabel(){
    this.viewer_open_from_tabel = 1
  }

  closeViewerFromTabel(){
    this.viewer_open_from_tabel = 0
  }

  getStatusViewerFromTabel(){
    return this.viewer_open_from_tabel
  }

}

