<mat-sidenav-container class="sidenav-container" >
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false" *ngIf="loggedIn">
    <mat-toolbar >
      <div class="image-container">
        <img src="/assets/img/logo.png" width="85%">
      </div>
    </mat-toolbar>
    <mat-nav-list style="padding-top: 55%;">
      <a mat-list-item routerLink="pacienti"><b>Beneficiari</b> </a>
      <!-- <a mat-list-item routerLink="programari"><b>Programare noua</b>  </a> -->
      <a mat-list-item routerLink="nomenclatoare"><b>Nomenclatoare</b> </a>
      <a mat-list-item routerLink="casierie"><b>Casierie</b> </a>
      <a mat-list-item routerLink="terapiilemele"><b>Terapii</b> </a>
      <!-- <a mat-list-item routerLink="casierie"><b>Programare noua</b> </a> -->
      <!-- <a mat-list-item routerLink="casierie"><b>Calendar</b> </a> -->
      <!-- <a mat-list-item routerLink="templatedocumente"><b>Template Documente</b> </a> -->
      <!-- <a mat-list-item routerLink="lista-asteptare"><b>Lista de asteptare</b> </a> -->
      <!-- <a mat-list-item routerLink="inscrieri"><div matBadge="2"><b>Inscrieri de pe site</b></div> </a> -->
      </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content >
    <mat-toolbar color="primary" *ngIf="loggedIn">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="spacer"></span>
      <!-- <button
        (click)="opencontulmeu()"
      > -->
        <span (click)="opencontulmeu()" style="cursor: pointer">Contul meu</span>
      <!-- </button> -->
    </mat-toolbar>
    <!-- Add Content Here -->
    <div style="padding: 10px;">
      <ng-content >
      </ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


